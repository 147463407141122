import { FC } from "react";
import { usePopup } from "contexts/PopupProvider";
import { useInsuranceProcess } from "../InsuranceProcessProvider";
import keyBy from "lodash/keyBy";
import { USD_CURRENCY, parameterReadOnlyFormat } from "./ParameterInput";
import {
    getRawValueAsStringFromJoValue,
    getUserOrRaterValue,
} from "utils/jo-types-and-values";
import { useBranding } from "contexts/BrandingProvider";
import { useAuthUser } from "react-auth-kit";
import { User, UserAccountRole } from "@joshuins/system";
import { formatDatetimeString } from "utils/datetime";
import MenuPopover, { MenuItem } from "components/MenuPopover";
import urlJoin from "url-join";
import { isBinder, isPolicy } from "utils/policies";

const DetailsCancellation: FC = () => {
    const { openPopup } = usePopup();
    const {
        itemGetters: { getInsuranceProcess },
    } = useInsuranceProcess();
    const { quote, quoteCodesAndValues } = getInsuranceProcess();
    const quoteCodesAndValuesByCode = keyBy(quoteCodesAndValues, "code");
    const premiumCode = quoteCodesAndValuesByCode["policy.premium_diff"]
        ? "policy.premium_diff"
        : "prm.premium";
    let premiumValue = parameterReadOnlyFormat(
        "Currency",
        getRawValueAsStringFromJoValue(
            getUserOrRaterValue(quoteCodesAndValuesByCode[premiumCode])
        )
    );
    if (premiumValue.indexOf("-") === -1) {
        premiumValue = premiumValue.replace(USD_CURRENCY, `${USD_CURRENCY}-`);
    }

    const authUser = useAuthUser();
    const currentUser = authUser() as User;

    const { storeUrlName, generateUrl } = useBranding();
    const inStore = storeUrlName !== undefined;

    const menuItems: MenuItem[] = [];
    if (quote) {
        menuItems.push({
            label: "Application",
            key: `${quote.id}-app`,
            icon: "chevron-right",
            linkTo: generateUrl(
                urlJoin(
                    "/",
                    "submissions",
                    quote.submission_id.toString(),
                    "application"
                )
            ),
            hasSeparator: !inStore && quote.rater_file_id ? true : false,
        });
    }

    if (!inStore) {
        menuItems.push({
            label: "Condition Values",
            key: `${quote.id}-conditions`,
            icon: "cog",
            onClick: () => {
                openPopup("view-conditions-popup");
            },
            hasSeparator: true,
        });
    }

    if (!inStore) {
        menuItems.push({
            label: "Document Inputs",
            key: `${quote.id}-documents`,
            icon: "document",
            onClick: () => {
                openPopup("quote-document-inputs");
            },
        });
    }

    if (
        currentUser.role === UserAccountRole.Admin &&
        (isPolicy(quote) || isBinder(quote))
    ) {
        menuItems.push({
            label: "Policy Sync",
            key: `${quote.id}-psync`,
            icon: "css",
            linkTo: urlJoin(
                "/",
                "admin",
                "system",
                "policy-sync",
                quote.id.toString()
            ),
        });
    }

    return (
        <div>
            <h2>
                <i aria-hidden="true" className="icon-shield" /> Policy Details
                <span>
                    <ul
                        className="list-plain box"
                        style={{ marginBottom: "-8px", marginTop: "-20px" }}
                    >
                        <MenuPopover
                            key={`${quote.id}-menu`}
                            menuItems={menuItems}
                            component="li"
                        >
                            {({ ToggleButton, Menu }) => {
                                return (
                                    <>
                                        {ToggleButton}
                                        {Menu}
                                    </>
                                );
                            }}
                        </MenuPopover>
                    </ul>
                </span>
            </h2>
            {quoteCodesAndValuesByCode && (
                <table className="table-a table-fixed">
                    <tbody>
                        <tr>
                            <th key="returned_premium">Changed Premium</th>
                            <th key="effective_date">
                                Transaction Effective Date
                            </th>
                            {/* <th key="expiration_date">Expiration Date</th> */}
                        </tr>
                        <tr>
                            <td
                                key="returned_premium"
                                className="parameter-data strong premium-parameter"
                                style={{ color: "red" }}
                            >
                                <span className="th">Changed Premium</span>
                                {premiumValue}
                            </td>
                            {/* <td key="effective_date">
                                <span className="th">Effective Date</span>
                                {quote.policy_effective_at &&
                                    formatDatetimeString(
                                        quote.policy_effective_at,
                                        "numericDateOnly"
                                    )}
                            </td> */}
                            <td key="expiration_date">
                                <span className="th">Expiration Date</span>
                                {quote.policy_expires_at &&
                                    formatDatetimeString(
                                        quote.policy_expires_at,
                                        "numericDateOnly"
                                    )}
                            </td>
                        </tr>
                    </tbody>
                </table>
            )}
        </div>
    );
};

export { DetailsCancellation };
