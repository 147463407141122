import { useCallback, useEffect, useState } from "react";
import { useApplication } from "./components/ApplicationProvider";
import { useApi } from "contexts/ApiProvider";
import { useBranding } from "contexts/BrandingProvider";
import { useAuthUser } from "react-auth-kit";
import { usePage } from "components/Page";
import { usePopup } from "contexts/PopupProvider";
import { User, UserAccountRole } from "@joshuins/auth";
import { Brokerage } from "@joshuins/system";
import {
    Submission,
    SubmissionStatus,
    SubmissionFlow,
    SectionV1,
} from "@joshuins/insurance";
import ExtraPane from "components/extra-panes/ExtraPane";
import urlJoin from "url-join";
import classNames from "classnames";
import { BROKER_INFO_POPUP } from "./components/BrokerInformationPopup";
import { ASSIGN_BROKER_POPUP } from "./components/AssignBrokerPopup";
import { IMPORT_SUBMISSION_POPUP } from "./components/ImportSubmission";
import { DUPLICATE_SUBMISSION_POPUP } from "./components/DuplicateSubmission";
import { UNLOCK_SUBMISSION_POPUP } from "./components/UnlockSubmission";
import { CHANGE_PRODUCT_VERSION_POPUP } from "./components/ChangeProductVersion";
import NotAnchor from "components/NotAnchor";
import { Link } from "components/DevAwareRoutingLink";
import keyBy from "lodash/keyBy";
import { getApplicationSections } from "utils/product-version";
import { isStoreUser } from "pages/components/util";

const SectionNavigation = () => {
    const {
        itemGetters: {
            getProductVersion,
            getInsured,
            getSubmissionUser,
            getSubmissionDataStatus,
            shownSection: shownSection_,
            generateSectionUrl,
        },
    } = useApplication();
    const { openPopup } = usePopup();
    const { sdkSystem } = useApi();
    const { storeUrlName, generateUrl } = useBranding();
    const authUser = useAuthUser();
    const currentUser = authUser() as User;
    const { element } = usePage();
    const [brokerage, setBrokerage] = useState<Brokerage | undefined>();
    const submission = element as unknown as Submission | undefined;
    const productVersion = getProductVersion();
    const insured = getInsured();
    const shownSection = shownSection_();
    const inStore = storeUrlName !== undefined;
    const submissionUser = getSubmissionUser();
    const submissionBroker =
        submissionUser?.role === UserAccountRole.Broker
            ? getSubmissionUser()
            : null;

    const sections = getApplicationSections(productVersion);
    const sectionsLookup = keyBy(getSubmissionDataStatus().sections, "code");

    useEffect(() => {
        const getBrokerage = async () => {
            if (currentUser.role === UserAccountRole.Admin) {
                setBrokerage(
                    submissionUser?.brokerage_id
                        ? await sdkSystem.getBrokerage({
                              id: submissionUser?.brokerage_id,
                          })
                        : undefined
                );
            }
        };
        getBrokerage();
    }, [currentUser.role, inStore, sdkSystem, submissionUser?.brokerage_id]);

    const showSectionInNavigation = useCallback(
        (section: SectionV1) => {
            if (
                currentUser.role === UserAccountRole.Broker ||
                currentUser.role === UserAccountRole.Insured
            ) {
                const uw_questions = section.items.filter(
                    (datapoint) =>
                        "Datapoint" in datapoint &&
                        datapoint.Datapoint.source === "Underwriter"
                );
                return section.items.length !== uw_questions.length;
            }
            return true;
        },
        [currentUser]
    );

    if (!submission || !sectionsLookup) {
        return <></>;
    }

    return (
        <ExtraPane>
            <header className="sub sub-parent">
                {insured && (
                    <Link
                        to={generateUrl(
                            urlJoin("/", "insureds", insured.id.toString())
                        )}
                    >
                        <h2>{insured.name}</h2>
                    </Link>
                )}
                <ul className="list-sub">
                    <li>
                        <a href="./">
                            <i className="icon-trash" /> Delete Submission
                        </a>
                    </li>
                </ul>
            </header>
            <p>
                {currentUser.role === UserAccountRole.Admin ? (
                    <Link
                        to={urlJoin(
                            "/",
                            "admin",
                            "products",
                            productVersion.id.toString()
                        )}
                        target="_blank"
                    >
                        {productVersion.schema.metadata.name} (
                        {productVersion.schema.metadata.internal_version_name})
                    </Link>
                ) : (
                    <>
                        {productVersion.schema.metadata.name}
                        {currentUser.role === UserAccountRole.Underwriter && (
                            <>
                                {
                                    productVersion.schema.metadata
                                        .internal_version_name
                                }
                            </>
                        )}
                    </>
                )}
            </p>
            <ul className="list-progress">
                {sections.map((section) => {
                    const sectionData = sectionsLookup[section.code];
                    if (
                        sectionData.condition_met !== false &&
                        showSectionInNavigation(section)
                    ) {
                        return (
                            <li
                                key={section.code}
                                className={classNames({
                                    active:
                                        shownSection &&
                                        shownSection.code === section.code,
                                    done:
                                        sectionsLookup[section.code].counters
                                            .completed ===
                                        sectionsLookup[section.code].counters
                                            .total,
                                })}
                            >
                                <Link
                                    to={generateSectionUrl(section.code) ?? ""}
                                >
                                    {"title" in section
                                        ? section.title
                                        : section.title_plural}
                                    <span>
                                        {
                                            sectionsLookup[section.code]
                                                .counters.completed
                                        }
                                        /
                                        {
                                            sectionsLookup[section.code]
                                                .counters.total
                                        }
                                    </span>
                                </Link>
                            </li>
                        );
                    }
                })}
            </ul>
            <div className="spacer"></div>
            {submissionBroker && brokerage && (
                <>
                    <p
                        className="link size-14"
                        style={{
                            borderTop: "1px solid var(--black_10)",
                            paddingTop: "14px",
                        }}
                    >
                        <NotAnchor
                            onClick={() => {
                                openPopup(BROKER_INFO_POPUP, {
                                    broker: submissionBroker,
                                    brokerage: brokerage,
                                });
                            }}
                        >
                            <i className="icon-user-circle" />{" "}
                            {submissionBroker.first_name}{" "}
                            {submissionBroker.last_name}
                        </NotAnchor>
                        <br />
                        <span
                            style={{
                                paddingLeft: "20px",
                            }}
                        >
                            {brokerage?.name}
                        </span>
                    </p>
                </>
            )}
            {!inStore && (
                <>
                    {submission.flow === SubmissionFlow.New && (
                        <p className="link size-14">
                            <NotAnchor
                                onClick={() => {
                                    openPopup(ASSIGN_BROKER_POPUP);
                                }}
                            >
                                <i className="icon-user" />{" "}
                                {!submissionBroker ? "Assign" : "Reassign"}{" "}
                                <span className="mobile-hide">Broker</span>
                            </NotAnchor>
                        </p>
                    )}
                    {submission.status !== SubmissionStatus.Incomplete && (
                        <p
                            className="link size-14"
                            style={{
                                borderTop: "1px solid var(--black_10)",
                                paddingTop: "14px",
                            }}
                        >
                            <NotAnchor
                                onClick={() => {
                                    openPopup(UNLOCK_SUBMISSION_POPUP);
                                }}
                            >
                                <i className="icon-pencil" />
                                Edit{" "}
                                <span className="mobile-hide">Submission</span>
                            </NotAnchor>
                        </p>
                    )}
                    {submission.status === SubmissionStatus.Incomplete && (
                        <>
                            <p
                                className="link size-14"
                                style={{
                                    borderTop: "1px solid var(--black_10)",
                                    paddingTop: "14px",
                                }}
                            >
                                <NotAnchor
                                    onClick={() => {
                                        openPopup(IMPORT_SUBMISSION_POPUP);
                                    }}
                                >
                                    <i className="icon-upload3" /> Import{" "}
                                    <span className="mobile-hide">
                                        Submission
                                    </span>
                                </NotAnchor>
                            </p>
                            {submission.flow === SubmissionFlow.New && (
                                <p className="link size-14">
                                    <NotAnchor
                                        onClick={() => {
                                            openPopup("decline-submission");
                                        }}
                                    >
                                        <i className="icon-x-circle" />
                                        Decline{" "}
                                        <span className="mobile-hide">
                                            Submission
                                        </span>
                                    </NotAnchor>
                                </p>
                            )}
                        </>
                    )}
                    {submission.flow === SubmissionFlow.New && (
                        <p className="link size-14">
                            <NotAnchor
                                onClick={() => {
                                    openPopup(DUPLICATE_SUBMISSION_POPUP);
                                }}
                            >
                                <i className="icon-copy" />
                                Duplicate{" "}
                                <span className="mobile-hide">Submission</span>
                            </NotAnchor>
                        </p>
                    )}
                    {((!isStoreUser(currentUser) &&
                        submission.status === SubmissionStatus.Incomplete) ||
                        submission.status === SubmissionStatus.Pending) && (
                        <p
                            className="link size-14 border-bottom"
                            style={{
                                borderTop: "1px solid var(--black_10)",
                                paddingTop: "14px",
                            }}
                        >
                            <NotAnchor
                                onClick={() => {
                                    openPopup(CHANGE_PRODUCT_VERSION_POPUP, {
                                        productId: productVersion.product_id,
                                    });
                                }}
                            >
                                <i className="icon-list" />
                                Change{" "}
                                <span className="mobile-hide">
                                    Product Version
                                </span>
                            </NotAnchor>
                        </p>
                    )}
                </>
            )}
        </ExtraPane>
    );
};

export default SectionNavigation;
