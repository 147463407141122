import { FC, createRef, useState } from "react";
import { ReportTemplate } from "@joshuins/system";
import { useApi } from "contexts/ApiProvider";
import { usePopup } from "contexts/PopupProvider";
import { useFileDownload } from "components/FileDownload";
import { ConfirmationPopup } from "components/Popup";
import { hideLoader, showLoader } from "paul/native-dom-manipulation";
import { retry } from "utils/retry";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { formatDate } from "utils/datetime";
import { AlertCategory, usePage } from "components/Page";

const ExportReportPopup: FC = () => {
    const { popupData } = usePopup();
    const { sdkSystem } = useApi();
    const { addAlertMessages } = usePage();

    const reportTemplate = popupData?.reportTemplate as
        | ReportTemplate
        | undefined;
    const [rangeStart, setRangeStart] = useState<Date | null>();
    const [rangeEnd, setRangeEnd] = useState<Date | null>(new Date());
    const [production, setProduction] = useState<boolean>(true);

    const generateReportId = async () => {
        if (!reportTemplate || !rangeStart || !rangeEnd) {
            return [];
        }
        const report = await sdkSystem.createReport({
            CreateReport: {
                template_id: reportTemplate.id,
                range_start: `${formatDate(rangeStart, "apiDate")}T00:00:00Z`,
                range_end: `${formatDate(rangeEnd, "apiDate")}T00:00:00Z`,
                production,
            },
        });
        showLoader("Generating...");
        const getReportWhenReady = (reportId: string) =>
            retry(
                () =>
                    sdkSystem.getReport({
                        id: reportId,
                    }),
                (newReport) =>
                    newReport.status === "Done" || newReport.status === "Error",
                2000
            );
        const [newReportPromise] = getReportWhenReady(report.id);
        const newReport = await newReportPromise;
        hideLoader();
        if (!newReport || newReport.status === "Error") {
            addAlertMessages({
                message: newReport?.error ?? "Error",
                category: AlertCategory.ALERT,
            });
            return [];
        } else {
            return newReport && newReport.file_id ? [newReport.file_id] : [];
        }
    };

    const downloadFile = useFileDownload(generateReportId);

    return (
        <ConfirmationPopup
            name="export-bordereau"
            onSubmit={downloadFile}
            submitText="Export"
        >
            <header>
                <h2>Export Bordereau</h2>
                <p className="size-14">
                    We&apos;ll save your template Excel file with the filtered
                    data:
                </p>
            </header>
            <div className="cols">
                <div className="div-as-p c50">
                    <span className="label">From Date (inclusive)</span>
                    <span className="input">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <MuiDatePicker
                                name="rangeStart"
                                value={undefined}
                                ref={createRef}
                                onChange={(value) => {
                                    setRangeStart(value);
                                }}
                                slotProps={{
                                    inputAdornment: {
                                        position: "start",
                                    },
                                }}
                            />
                        </LocalizationProvider>
                    </span>
                </div>
                <div className="div-as-p c50">
                    <span className="label">To Date (exclusive)</span>
                    <span className="input">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <MuiDatePicker
                                name="rangeEnd"
                                defaultValue={rangeEnd}
                                ref={createRef}
                                onChange={(value) => {
                                    setRangeEnd(value);
                                }}
                                slotProps={{
                                    inputAdornment: {
                                        position: "start",
                                    },
                                }}
                            />
                        </LocalizationProvider>
                    </span>
                </div>
                <p className="check font-medium color-primary m10">
                    <input
                        type="checkbox"
                        id="zprd"
                        name="production"
                        checked={production}
                        onChange={(e) => {
                            setProduction(e.target.checked);
                        }}
                    />
                    <label htmlFor="zprd" className="small">
                        Get Production Data
                    </label>
                </p>
            </div>
        </ConfirmationPopup>
    );
};

export default ExportReportPopup;
