import { User } from "@joshuins/auth";
import { ProductVersionView, Submission } from "@joshuins/insurance";
import { usePage } from "components/Page";
import { ConfirmationPopup } from "components/Popup";
import { unpaginate } from "components/sdk";
import { useApi } from "contexts/ApiProvider";
import { useBranding } from "contexts/BrandingProvider";
import { usePopup } from "contexts/PopupProvider";
import { isStoreUser } from "pages/components/util";
import { FC, useCallback, useEffect, useState } from "react";
import { useAuthUser } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import urlJoin from "url-join";

const CHANGE_PRODUCT_VERSION_POPUP = "change-product-version" as const;

const ChangeProductVersionPopup: FC<{ product_id?: number }> = () => {
    const { sdkInsurance } = useApi();
    const { isPopupOpen, popupData } = usePopup();
    const product_id = popupData?.productId as number;
    const { generateUrl } = useBranding();
    const navigate = useNavigate();
    const authUser = useAuthUser();
    const currentUser = authUser() as User;
    const { element, addSuccessMessage, tryCatchAndRaiseError } = usePage();
    const submission = element as unknown as Submission | undefined;
    const [productVersions, setProductVersions] =
        useState<ProductVersionView[]>();
    const [productVersionId, setProductVersionId] = useState<number>();

    useEffect(() => {
        if (!isPopupOpen(CHANGE_PRODUCT_VERSION_POPUP)) {
            return;
        }
        const getProductVersions = async () => {
            if (!isStoreUser(currentUser) && product_id) {
                const productVersions_ = await unpaginate(
                    sdkInsurance.allProductVersions,
                    {
                        product_id,
                    }
                );
                setProductVersions(productVersions_);
                setProductVersionId(submission?.product_version_id);
            }
        };
        getProductVersions();
    }, [
        currentUser,
        isPopupOpen,
        product_id,
        sdkInsurance,
        submission?.product_version_id,
    ]);

    const handleChange = useCallback<
        React.ChangeEventHandler<HTMLInputElement>
    >((event) => {
        setProductVersionId(parseInt(event.currentTarget.value));
    }, []);

    const onSubmit = async () => {
        if (
            !submission ||
            !productVersionId ||
            (productVersions && productVersions?.length < 2)
        ) {
            return null;
        }

        tryCatchAndRaiseError(async () => {
            await sdkInsurance.updateSubmission({
                id: submission.id,
                UpdateSubmission: { product_version_id: productVersionId },
            });
            addSuccessMessage("Product version updated successfully");
            navigate(
                generateUrl(
                    urlJoin("/", "submissions", submission.id.toString())
                )
            );
        });
    };

    if (!submission || !productVersions) {
        return null;
    }

    if (productVersions.length === 1) {
        return (
            <ConfirmationPopup
                name={CHANGE_PRODUCT_VERSION_POPUP}
                onSubmit={onSubmit}
                submitText="Ok"
                mobileSubmitText="Ok"
            >
                <header>
                    <h2>No Product Version</h2>
                    <p>The are no other product versions available.</p>
                </header>
            </ConfirmationPopup>
        );
    }

    return (
        <ConfirmationPopup
            name={CHANGE_PRODUCT_VERSION_POPUP}
            submitText="Update Version"
            mobileSubmitText="Update"
            onSubmit={onSubmit}
        >
            <header>
                <h2>Are you sure?</h2>
                <p className="size-14">
                    This will change the product version of this submission. The
                    submission&lsquo;s data will remain intact.
                </p>
            </header>
            <>
                <label htmlFor="product_version_name">Select Product</label>
                <ul
                    className="check hr font-medium"
                    style={{ maxHeight: "400px", overflowY: "auto" }}
                >
                    {productVersions.map((productVersion) => (
                        <li key={productVersion.id}>
                            <input
                                type="radio"
                                name="product_version_id"
                                id={`tda-${productVersion.id}`}
                                onChange={handleChange}
                                defaultChecked={
                                    productVersion.id ===
                                    submission.product_version_id
                                }
                                disabled={
                                    productVersion.id ===
                                    submission.product_version_id
                                }
                                value={productVersion.id}
                            />
                            <label htmlFor={`tda-${productVersion.id}`}>
                                {productVersion.name}
                                {productVersion.internal_version_name &&
                                    ` | ${productVersion.internal_version_name}`}
                            </label>
                            <span
                                className={`scheme-box color-${
                                    productVersion.is_published
                                        ? "pear"
                                        : "gold"
                                }`}
                            >
                                {productVersion.is_published
                                    ? "Published"
                                    : "Draft"}
                            </span>
                        </li>
                    ))}
                </ul>
            </>
        </ConfirmationPopup>
    );
};

export { ChangeProductVersionPopup, CHANGE_PRODUCT_VERSION_POPUP };
