import { FC } from "react";
import { PolicyStatus } from "@joshuins/insurance";
import { Page } from "components/Page";
import ExtraPane from "components/extra-panes/ExtraPane";
import PoliciesList from "./components/PoliciesList";
import { useAvailableProductVersions } from "pages/components/use-create-submission-wizard";

const POLICIES_STATUSES = [
    PolicyStatus.Active,
    PolicyStatus.Canceled,
    PolicyStatus.Declined,
    PolicyStatus.Expired,
    PolicyStatus.Future,
];

const ListPoliciesExtraPane = () => (
    <ExtraPane>
        <h3>
            <i aria-hidden="true" className="icon-help" /> Policies
        </h3>
        <p className="size-14">
            We will notify the insured by email when the policy is up for
            renewal.
        </p>
    </ExtraPane>
);

const UnderwritersPoliciesPage: FC = () => {
    const availableProducts = useAvailableProductVersions();
    return (
        <Page>
            <PoliciesList
                title="Policies"
                inStatus={POLICIES_STATUSES}
                availableProducts={availableProducts}
            />
            <ListPoliciesExtraPane />
        </Page>
    );
};

export default UnderwritersPoliciesPage;
