const splitMultipleLines = (str: string, removeEmpty = true) => {
    // https://stackoverflow.com/a/21712066
    let splitStrings = str.split(/\r?\n|\r|\n/g);

    if (removeEmpty) {
        splitStrings = splitStrings.filter(
            (eachString) => eachString.length > 0
        );
    }

    return splitStrings;
};

const chooseRandomCharacter = (characters: string) =>
    characters.charAt(Math.floor(Math.random() * characters.length));

const UPPERCASE_CHARACTERS = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
const LOWERCASE_CHARACTERS = "abcdefghijklmnopqrstuvwxyz";
const DIGITS = "0123456789";

const RANDOM_STRING_DEFAULT_OPTIONS = {
    lowercaseCharacters: true,
    digits: true,
    firstLetterCharacter: false,
};

// https://stackoverflow.com/a/1349426
const randomString = (
    length: number,
    options: {
        lowercaseCharacters?: boolean;
        digits?: boolean;
        firstLetterCharacter?: boolean;
    } = RANDOM_STRING_DEFAULT_OPTIONS
) => {
    const options_ = { ...RANDOM_STRING_DEFAULT_OPTIONS, ...options };

    let result = "";
    let characters = UPPERCASE_CHARACTERS;
    if (options_.lowercaseCharacters) {
        characters += LOWERCASE_CHARACTERS;
    }
    if (options_.digits) {
        characters += DIGITS;
    }
    if (length < 1) {
        return "";
    }

    let counter = 0;

    if (options_.firstLetterCharacter) {
        let firstCharCharacters = UPPERCASE_CHARACTERS;
        if (options_.lowercaseCharacters) {
            firstCharCharacters += LOWERCASE_CHARACTERS;
        }
        result += chooseRandomCharacter(firstCharCharacters);
    }

    while (counter < length) {
        result += chooseRandomCharacter(characters);
        counter += 1;
    }
    return result;
};

const setCodePrefix = (
    prefix: string,
    element: React.ChangeEvent<HTMLInputElement>
) => {
    const input = element.target.value;
    element.target.value = input.startsWith(prefix)
        ? `${prefix}.${input.slice(prefix.length + 1)}`
        : `${prefix}.${input}`;
};

export { splitMultipleLines, randomString, setCodePrefix };
