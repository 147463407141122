import {
    Insured,
    ParsedGoogleAddress,
    Quote,
    Submission,
    SubmissionStatus,
} from "@joshuins/insurance";
import { Industry, User } from "@joshuins/system";
import { Footer, MainPane } from "components/MainPane";
import { Page, usePage } from "components/Page";
import { unpaginate } from "components/sdk";
import ExtraPane from "components/extra-panes/ExtraPane";
import { useApi } from "contexts/ApiProvider";
import { usePopup } from "contexts/PopupProvider";
import { FC, useEffect, useState } from "react";
import urlJoin from "url-join";
import { isBinder, isPolicy, isQuote } from "utils/policies";
import NotAnchor from "components/NotAnchor";
import {
    useAvailableProductVersions,
    useCreateSubmissionWizard,
} from "./use-create-submission-wizard";
import {
    quoteStatusToText,
    quoteStatusToColor,
    submissionStatusTimeDescription,
} from "./util";
import { RoleAwareLink } from "contexts/BrandingProvider";
import { getLocationFromJoPlainValue } from "utils/jo-types-and-values";
import EditInsuredPopup, { EDIT_INSURED } from "./EditInsuredPopup";
import groupBy from "lodash/groupBy";
import classNames from "classnames";
import { useAuthUser } from "react-auth-kit";

const InsuredSubmissions: FC<{
    submissions: Submission[] | undefined;
    insured: Insured;
}> = ({ insured, submissions }) => (
    <>
        <h2 className="m8">
            <i aria-hidden="true" className="icon-edit" /> Submissions
        </h2>
        <ul className="list-plain box">
            {submissions && submissions.length > 0 ? (
                submissions.map((submission) => (
                    <li key={submission.id} className="no-img">
                        <i className="icon-edit" />
                        <RoleAwareLink
                            to={urlJoin(
                                "/",
                                "submissions",
                                submission.id.toString(),
                                "application"
                            )}
                        >
                            {insured.name}
                        </RoleAwareLink>
                        <ul className="list-inline a">
                            <li>
                                {submissionStatusTimeDescription(submission)}
                            </li>
                            <li className="overlay color-wine">
                                <span>{submission.status}</span>
                            </li>
                        </ul>
                    </li>
                ))
            ) : (
                <li className="no-img color-black-40">No submissions</li>
            )}
        </ul>
    </>
);

const InsuredQuotes: FC<{
    insured: Insured;
    quotes: Quote[] | undefined;
}> = ({ insured, quotes }) => {
    if (insured === undefined) {
        return <></>;
    }

    return (
        <>
            <h2 className="m8">
                <i aria-hidden="true" className="icon-documents" /> Quotes
            </h2>
            <ul className="list-plain box">
                {quotes && quotes.length > 0 ? (
                    quotes.map((quote) => (
                        <li key={quote.id} className="no-img">
                            <i className="icon-documents" />
                            <RoleAwareLink
                                to={urlJoin("/", "quotes", quote.id.toString())}
                            >
                                {insured.name}
                            </RoleAwareLink>
                            <ul className="list-inline a">
                                {/* <li>{quoteStatusTimeDescription(quote)}</li> */}
                                <li
                                    className={`overlay ${quoteStatusToColor(
                                        quote.status
                                    )}`}
                                >
                                    <span>
                                        {quoteStatusToText[quote.status]}
                                    </span>
                                </li>
                            </ul>
                        </li>
                    ))
                ) : (
                    <li className="no-img color-black-40">No quotes</li>
                )}
            </ul>
        </>
    );
};

const InsuredBindersAndPolicies: FC<{
    insured: Insured;
    policies: Quote[] | undefined;
    which: "Binder" | "Policy";
}> = ({ insured, policies, which }) => {
    if (insured === undefined) {
        return <></>;
    }

    return (
        <>
            <h2 className="m8">
                <i aria-hidden="true" className="icon-shield" />
                {which === "Binder" ? "Binders" : "Policies"}
            </h2>
            <ul className="list-plain box">
                {policies && policies.length > 0 ? (
                    policies.map((policy) => (
                        <li key={policy.id} className="no-img">
                            <i className="icon-shield" />
                            <RoleAwareLink
                                to={urlJoin(
                                    "/",
                                    "policies",
                                    policy.id.toString()
                                )}
                            >
                                {insured.name}
                            </RoleAwareLink>
                            <ul className="list-inline a">
                                {/* <li>{quoteStatusTimeDescription(policy)}</li> */}
                                <li
                                    className={`overlay ${quoteStatusToColor(
                                        policy.status
                                    )}`}
                                >
                                    <span>
                                        {quoteStatusToText[policy.status]}
                                    </span>
                                </li>
                            </ul>
                        </li>
                    ))
                ) : (
                    <li className="no-img color-black-40">
                        No {which === "Binder" ? "binders" : "policies"}
                    </li>
                )}
            </ul>
        </>
    );
};

const Main: FC = () => {
    const { openPopup } = usePopup();
    const { element } = usePage();
    const insured = element as unknown as Insured | undefined;
    const availableProducts = useAvailableProductVersions();
    const newSubmissionWizardProceed = useCreateSubmissionWizard();
    const [industry, setindustry] = useState<Industry>();
    const [location, setLocation] = useState<ParsedGoogleAddress | string>();
    const { sdkInsurance, sdkSystem } = useApi();
    const authUser = useAuthUser();
    const currentUser = authUser() as User;

    const [insuredSubmissions, setSubmissions] = useState<Submission[]>();
    const [insuredQuotes, setQuotes] = useState<Quote[]>();
    const [insuredPolicies, setPolicies] = useState<Quote[]>();
    const [insuredBinders, setBinders] = useState<Quote[]>();

    useEffect(() => {
        const getInsuredSubmissionsQuotesPolicies = async () => {
            if (!insured) {
                return;
            }
            const insuredPolicies_ = await sdkInsurance.allPolicies({
                insured_id: insured.id,
            });
            const insuredSubmissionsResponse = await Promise.all(
                insuredPolicies_.items.map((policy) =>
                    unpaginate(sdkInsurance.allSubmissions, {
                        policy_id: policy.id,
                    })
                )
            );
            const insuredSubmissions = insuredSubmissionsResponse.flat();

            // get insured quotes
            const insuredQuotesResponse = await Promise.all(
                insuredSubmissions.map((submission) =>
                    submission.status !== SubmissionStatus.Incomplete
                        ? unpaginate(sdkInsurance.allQuotes, {
                              submission_id: submission.id,
                          })
                        : []
                )
            );
            const insuredQuotes = insuredQuotesResponse.flat();

            const insuredPoliciesAndBinders = insuredQuotes.filter(
                (quote) => !isQuote(quote)
            );

            const submissionsWithQuotes = groupBy(
                insuredQuotes,
                "submission_id"
            );
            const quoteWithPolicies = groupBy(insuredPoliciesAndBinders, "id");

            // submissions without quotes
            const openSubmissions = insuredSubmissions.filter(
                (submission) =>
                    !Object.keys(submissionsWithQuotes).includes(
                        submission.id.toString()
                    ) || submission.status === SubmissionStatus.Incomplete
            );
            // quotes without policies
            const openQuotes = insuredQuotes.filter(
                (quote) =>
                    !Object.keys(quoteWithPolicies).includes(
                        quote.id.toString()
                    )
            );
            const insuredBinders = insuredPoliciesAndBinders.filter(isBinder);
            const insuredPolicies = insuredPoliciesAndBinders.filter((policy) =>
                isPolicy(policy)
            );
            setSubmissions(openSubmissions);
            setQuotes(openQuotes);
            setBinders(insuredBinders);
            setPolicies(insuredPolicies);
        };
        getInsuredSubmissionsQuotesPolicies();
    }, [sdkInsurance, sdkSystem, insured, currentUser]);

    useEffect(() => {
        const getIndustryAndLocation = async () => {
            if (!insured) {
                return;
            }
            const insuredData = await sdkInsurance.getInsuredData({
                id: insured.id,
            });
            const locationData = insuredData.find(
                (item) => item.code === "insured.location"
            );
            if (locationData && "Plain" in locationData.value.V1) {
                const parsedAddress = getLocationFromJoPlainValue(
                    locationData.value.V1.Plain
                );
                if (typeof parsedAddress === "string") {
                    setLocation(parsedAddress);
                } else {
                    setLocation(parsedAddress as ParsedGoogleAddress);
                }
            }
            if (insured.industry_id) {
                const industry = await sdkSystem.getIndustry({
                    id: insured.industry_id,
                });
                setindustry(industry);
            }
        };
        getIndustryAndLocation();
    }, [sdkInsurance, sdkSystem, insured]);

    return (
        <MainPane title={insured ? insured.name : ""}>
            {insured && (
                <>
                    <div className="module-quote">
                        <InsuredSubmissions
                            insured={insured}
                            submissions={insuredSubmissions}
                        />
                        <InsuredQuotes
                            insured={insured}
                            quotes={insuredQuotes}
                        />
                        <InsuredBindersAndPolicies
                            insured={insured}
                            policies={insuredBinders}
                            which="Binder"
                        />
                        <InsuredBindersAndPolicies
                            insured={insured}
                            policies={insuredPolicies}
                            which="Policy"
                        />
                        {availableProducts && (
                            <Footer>
                                <p className="link-btn has-inline">
                                    <NotAnchor
                                        className={classNames("inline", {
                                            disabled:
                                                (insuredBinders &&
                                                    insuredBinders.length >
                                                        0) ||
                                                (insuredPolicies &&
                                                    insuredPolicies.length > 0),
                                        })}
                                        onClick={() => {
                                            openPopup(EDIT_INSURED, {
                                                insured,
                                                location,
                                                industry,
                                            });
                                        }}
                                    >
                                        <i className="icon-city" />
                                        Edit Details
                                    </NotAnchor>
                                </p>
                                <p className="link-btn">
                                    <button
                                        onClick={() => {
                                            newSubmissionWizardProceed(
                                                "newInsured",
                                                {
                                                    insuredId: insured.id,
                                                }
                                            );
                                        }}
                                        className="submit-form submit-btn"
                                        style={{ marginRight: "24px" }}
                                    >
                                        <i className="icon-check-circle-outline" />
                                        New
                                        <span className="mobile-hide">
                                            Submission
                                        </span>
                                    </button>
                                </p>
                            </Footer>
                        )}
                    </div>
                </>
            )}
            <ExtraPane>
                <div className="module-details">
                    <h3>
                        <i className="icon-city" />{" "}
                        {insured ? insured.name : ""}
                    </h3>

                    {location && (
                        <p>
                            {typeof location === "string"
                                ? location
                                : location.formatted_address}
                        </p>
                    )}
                    {industry && (
                        <>
                            <h3>NAICS Code</h3>
                            <p>
                                {industry.code}: {industry.title}
                            </p>
                        </>
                    )}
                </div>
            </ExtraPane>
        </MainPane>
    );
};

const InsuredPage: FC = () => (
    <Page>
        <Main />
        <EditInsuredPopup />
    </Page>
);

export default InsuredPage;
