import { AlertCategory, usePage } from "components/Page";
import { createFormPopup } from "components/Popup";
import { usePopup } from "contexts/PopupProvider";
import { FC, useCallback, useEffect } from "react";
import { z } from "zod";
import { useIntegrations } from "../IntegrationsProvider";
import { ProductIntegrationV1 } from "@joshuins/builder";
import { Integration } from "@joshuins/system";
import cloneDeep from "lodash/cloneDeep";
import classNames from "classnames";
import NotAnchor from "components/NotAnchor";

const HAZARDHUB_SETUP = "hazardhub-setup-form" as const;

const hazardHubSetupFormSchema = z.object({
    locationTag: z.string().min(1, "This field is required"),
});
type HazardHubSetupFormType = z.infer<typeof hazardHubSetupFormSchema>;

const {
    FormPopup: HazardHubSetupFormPopup,
    useFormReturnRef: useHazardHubSetupFormReturnRef,
} = createFormPopup(hazardHubSetupFormSchema);

const HazardHubSetupPopup: FC = () => {
    const {
        formReturn: { reset },
        formReturnRefCallback,
    } = useHazardHubSetupFormReturnRef();
    const { addAlertMessages } = usePage();
    const { popupData, isPopupOpen } = usePopup();
    const {
        accessors: { allIntegrationTypes },
        mutators: { addProductIntegration, updateProductIntegration },
    } = useIntegrations();

    const integration = popupData?.integration as Integration | undefined;
    const productIntegration = popupData?.productIntegration as
        | ProductIntegrationV1
        | undefined;
    const integrationTypes = allIntegrationTypes();
    const integrationType =
        integration && integrationTypes
            ? integrationTypes[integration.type_]
            : undefined;
    const is_published = popupData?.is_published as boolean | undefined;

    useEffect(() => {
        const getProductIntegration = async () => {
            if (
                !productIntegration ||
                !isPopupOpen(HAZARDHUB_SETUP) ||
                !reset
            ) {
                return;
            }

            reset({
                locationTag: productIntegration.config.hazard_location_code,
            });
        };
        getProductIntegration();
    }, [productIntegration, isPopupOpen, reset]);

    const onSubmit = useCallback(
        async (data: HazardHubSetupFormType) => {
            if (!integration || !integrationType) {
                return;
            }
            if (productIntegration) {
                const updateProductIntegratopn = cloneDeep(productIntegration);
                updateProductIntegratopn.config = {
                    hazard_location_code: data.locationTag,
                };
                await updateProductIntegration(
                    productIntegration.integration_id,
                    updateProductIntegratopn
                );
                addAlertMessages({
                    message: `${integrationType.display_name} integration was updated succesfully`,
                    category: AlertCategory.SUCCESS,
                });
            } else {
                const newProductIntegration: ProductIntegrationV1 = {
                    enabled: true,
                    integration_id: integration.id,
                    config: {
                        hazard_location_code: data.locationTag,
                    },
                };
                await addProductIntegration(newProductIntegration);
                addAlertMessages({
                    message: `${integrationType.display_name} integration was created succesfully`,
                    category: AlertCategory.SUCCESS,
                });
            }
        },
        [
            addAlertMessages,
            addProductIntegration,
            integration,
            integrationType,
            productIntegration,
            updateProductIntegration,
        ]
    );

    if (!integrationType) {
        return <></>;
    }

    return (
        <HazardHubSetupFormPopup
            name={HAZARDHUB_SETUP}
            defaultValues={{
                locationTag: "",
            }}
            submitText="Save"
            formReturnRefCallback={formReturnRefCallback}
            onSubmit={onSubmit}
            disabled={is_published}
            overlayPopups={[
                <>
                    <h3>Location Tag</h3>
                    <p>
                        Choose a reference tag from your application questions
                        that asks for a location.
                    </p>
                </>,
            ]}
        >
            {({ register, openOverlayPopup, formState: { errors } }) => (
                <>
                    <header>
                        <h2>{integrationType.display_name}</h2>
                    </header>
                    <p
                        className={classNames({
                            "has-error": errors.locationTag,
                        })}
                    >
                        <label htmlFor="pct-2">
                            Location Tag{" "}
                            <NotAnchor
                                onClick={() => {
                                    openOverlayPopup(0);
                                }}
                                className="text-right"
                            >
                                <i className="icon-help" />{" "}
                                <span className="hidden">Data Input</span>
                            </NotAnchor>
                        </label>
                        <input
                            type="text"
                            id="pct-2"
                            {...register("locationTag")}
                        />
                        {errors.locationTag && (
                            <label
                                id="pct-2-error"
                                className="error"
                                htmlFor="pct-2"
                            >
                                {errors.locationTag.message}
                            </label>
                        )}
                    </p>
                </>
            )}
        </HazardHubSetupFormPopup>
    );
};

export default HazardHubSetupPopup;
