import { useEffect, useRef } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useApi } from "contexts/ApiProvider";
import { useUnhidePage } from "contexts/UnhidePageProvider";
import { FullPageMessage1 } from "components/FullPageMessage";

const SsoCallbackPage = () => {
    const { apiAuthProcess } = useApi();
    const [searchParams] = useSearchParams();
    const { blockUnhide } = useUnhidePage();
    const { providerSlug } = useParams();
    const state = searchParams.get("state");
    const code = searchParams.get("code");
    const wasPreviouslyMounted = useRef(false);

    useEffect(() => {
        blockUnhide();

        const ssoLogin = async () => {
            if (!providerSlug || wasPreviouslyMounted.current) {
                return;
            }
            if (state && code) {
                apiAuthProcess.ssoLogin(providerSlug, state, code);
            }
        };
        ssoLogin();
        return () => {
            wasPreviouslyMounted.current = true;
        };
    }, [state, code, blockUnhide, apiAuthProcess, providerSlug]);

    return apiAuthProcess.authProcessErrorMessage ? (
        <main id="content">
            <FullPageMessage1
                title={`There was a problem during the ${
                    providerSlug === "google" ? "Google" : "OpenID Connect"
                } sign in`}
                message={apiAuthProcess.authProcessErrorMessage}
                iconType="triangle"
            />
        </main>
    ) : (
        <></>
    );
};

export default SsoCallbackPage;
