import { Quote, QuoteStatus, Submission } from "@joshuins/insurance";
import { Page } from "components/Page";
import InsuranceObjectList from "./components/InsuranceObjectList";
import ExtraPane from "components/extra-panes/ExtraPane";
import { useApi } from "contexts/ApiProvider";
import { FC, useEffect, useState } from "react";

const BINDERS_STATUSES = [
    QuoteStatus.BinderPublished,
    QuoteStatus.BinderDeclined,
];

const Main = () => {
    const { sdkInsurance } = useApi();
    const [binders, setBinders] =
        useState<{ submission: Submission; quote: Quote }[]>();

    useEffect(() => {
        const getBinders = async () => {
            setBinders(await sdkInsurance.getAllPolicies(BINDERS_STATUSES));
        };
        getBinders();
    }, [sdkInsurance]);

    if (!binders) {
        return <></>;
    }

    return (
        <InsuranceObjectList
            title="Binders"
            insuranceObjects={binders}
            textWhenEmpty="Issued or Declined binders will appear here."
            icon="icon-shield"
        />
    );
};

const ListBindersExtraPane = () => (
    <ExtraPane>
        <h3>
            <i aria-hidden="true" className="icon-help" /> Binders
        </h3>
        <p className="size-14">
            All of the declined, published and pending binders will be presented
            here.
        </p>
    </ExtraPane>
);

const UnderwritersBindersPage: FC = () => (
    <Page>
        <Main />
        <ListBindersExtraPane />
    </Page>
);

export default UnderwritersBindersPage;
