import { FC, useCallback, useEffect, useRef } from "react";
import { usePage } from "./Page";
import NotAnchor from "./NotAnchor";

const ALERT_TIMEOUT = 5_000 as const;

const useInterval = (callback: () => void, delay: number) => {
    const savedCallback = useRef(() => {});

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        const id = setInterval(tick, delay);
        return () => clearInterval(id);
    }, [delay]);
};

const AlertMessageFlash: FC = () => {
    const { alertMessagesMap, removeAlertMessages } = usePage();

    const clearAlerts = useCallback(() => {
        const currentTime = new Date().getTime();
        alertMessagesMap((item, key) => {
            if (item.created) {
                if (currentTime - item.created.getTime() > ALERT_TIMEOUT) {
                    removeAlertMessages(key);
                }
            }
        });
    }, [alertMessagesMap, removeAlertMessages]);

    useInterval(clearAlerts, 1000);

    return (
        <ul className="list-alert">
            {alertMessagesMap(({ message, category }, key) => (
                <li
                    key={key}
                    style={{
                        visibility: "visible",
                        opacity: 100,
                        color: "var(--white)",
                        fontWeight: 500,
                    }}
                >
                    <i className={`icon-${category}-circle-outline`} />
                    {message}
                    <NotAnchor
                        className="close"
                        aria-selected="true"
                        onClick={() => {
                            removeAlertMessages(key);
                        }}
                    />
                </li>
            ))}
        </ul>
    );
};

export default AlertMessageFlash;
