import { FC } from "react";
import {
    DocumentPacketTypeV1,
    QuoteStatus,
    SubmissionFlow,
} from "@joshuins/insurance";
import { useInsuranceProcess } from "./components/InsuranceProcessProvider";
import { DocumentsSection } from "./components/quote-and-policy-sections/DocumentsSection";
import { NotesSection } from "./components/quote-and-policy-sections/NotesSection";
import { useBranding } from "contexts/BrandingProvider";
import { StoreBlockedQuotePage } from "pages/store/StoreBlockedQuotePage";
import classNames from "classnames";
import { StoreDocumentsSection } from "pages/store/StoreDocumentsSection";
import { UnderwritersBlockedQuotePage } from "./UnderwritersBlockedQuotePage";
import { PolicyFooter } from "./PolicyFooter";
import { PolicyHeader } from "./PolicyHeader";
import GeneralErrorPage from "./components/quote-and-policy-sections/GeneralErrorPage";
import { Footer } from "components/MainPane";
import { isBinder } from "utils/policies";
import sortBy from "lodash/sortBy";
import { Link } from "react-router-dom";
import urlJoin from "url-join";
import { DetailsCancellation } from "./components/quote-and-policy-sections/DetailsCancellation";
import { usePage } from "components/Page";

const PolicyTabs: FC = () => {
    const {
        itemGetters: { getInsuranceProcess },
    } = useInsuranceProcess();
    const { quote, binderVariations } = getInsuranceProcess();
    const { generateUrl } = useBranding();

    if (!quote) {
        return <></>;
    }

    return (
        <>
            <ul className="list-tabs is-tabs" role="tablist">
                {binderVariations &&
                binderVariations.length > 0 &&
                isBinder(quote) ? (
                    sortBy(binderVariations, "id").map(
                        (binderVariation, index) => (
                            <li
                                className={
                                    quote.id === binderVariation.id
                                        ? "active"
                                        : ""
                                }
                                key={binderVariation.id}
                            >
                                <Link
                                    to={generateUrl(
                                        urlJoin(
                                            "/",
                                            "policies",
                                            binderVariation.id.toString()
                                        )
                                    )}
                                >
                                    Binder {index + 1}
                                </Link>
                            </li>
                        )
                    )
                ) : (
                    <li>Policy</li>
                )}
            </ul>
        </>
    );
};

const QuoteOrPolicyCancellation: FC = () => {
    const {
        itemGetters: { getInsuranceProcess },
        insuranceProcessState: { dirtyFields: dirtyFields },
        insuranceProcessState,
        insuranceHistory,
        pageIds,
    } = useInsuranceProcess();
    const { submission, quote } = getInsuranceProcess();
    const { storeUrlName } = useBranding();
    const inStore = storeUrlName !== undefined;
    const { tabs, activeTab } = usePage();
    const activeTab_ = activeTab();

    const shouldShowBlockedQuoteStorePage =
        inStore &&
        (quote.status === QuoteStatus.QuotePending ||
            quote.status === QuoteStatus.QuoteDeclined ||
            quote.status === QuoteStatus.Error ||
            quote.status === QuoteStatus.QuoteClosed);

    const shouldShowBlockedQuoteUnderwritersPage =
        !inStore &&
        quote.status === QuoteStatus.QuoteStoreEdit &&
        submission.flow === SubmissionFlow.Endorsement;

    if (
        !tabs ||
        !activeTab_ ||
        !insuranceProcessState.insuranceProcess ||
        !dirtyFields ||
        !insuranceHistory ||
        !pageIds ||
        !insuranceProcessState.insuranceProcess.quoteCodesAndValues
    ) {
        return <GeneralErrorPage />;
    }

    return (
        <div
            id="wrap"
            className={classNames("module-tabs static is-tabs", {
                "text-center":
                    shouldShowBlockedQuoteStorePage ||
                    shouldShowBlockedQuoteUnderwritersPage,
            })}
        >
            <PolicyTabs />
            <PolicyHeader tabs={tabs} />
            <div className="module-tabs-content policy-details">
                {activeTab_.path === "details" && (
                    <>
                        {shouldShowBlockedQuoteStorePage ? (
                            <StoreBlockedQuotePage
                                quoteStatus={quote.status}
                                submission={submission}
                            />
                        ) : shouldShowBlockedQuoteUnderwritersPage ? (
                            <UnderwritersBlockedQuotePage
                                status={quote.status}
                            />
                        ) : (
                            <div className="module-quote">
                                <DetailsCancellation />
                                {inStore ? (
                                    <StoreDocumentsSection
                                        documentType={
                                            DocumentPacketTypeV1.Cancellation
                                        }
                                    />
                                ) : (
                                    <>
                                        <DocumentsSection
                                            documentType={
                                                DocumentPacketTypeV1.Cancellation
                                            }
                                        />
                                        <NotesSection />
                                    </>
                                )}
                                <Footer>
                                    <PolicyFooter />
                                </Footer>
                                <div style={{ paddingBottom: "200px" }} />
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export { QuoteOrPolicyCancellation };
