import { FC, useCallback, useEffect, useState } from "react";
import { Office } from "@joshuins/system";
import { AlertCategory, usePage } from "components/Page";
import { ConfirmationPopup } from "components/Popup";
import { unpaginate } from "components/sdk";
import { useApi } from "contexts/ApiProvider";
import { usePopup } from "contexts/PopupProvider";

const DeleteOfficePopup: FC = () => {
    const { sdkSystem } = useApi();
    const { addAlertMessages, refreshState, tryCatchAndRaiseError } = usePage();
    const { popupData, isPopupOpen } = usePopup();
    const [officeToBeDeleted, setofficeToBeDeleted] = useState<Office>();
    const officeIdToBeDeleted = popupData?.officeIdToBeDeleted as number;

    useEffect(() => {
        const getUser = async () => {
            if (!isPopupOpen("delete-office") || !officeIdToBeDeleted) {
                return;
            }

            const office_ = await sdkSystem.getOffice({
                id: officeIdToBeDeleted,
            });
            setofficeToBeDeleted(office_);
        };
        getUser();
    }, [sdkSystem, officeIdToBeDeleted, isPopupOpen]);

    const onSubmit = useCallback(async () => {
        if (!officeIdToBeDeleted || !officeToBeDeleted) {
            return;
        }
        const officeUsers = await unpaginate(sdkSystem.allUsers, {
            office_id: officeIdToBeDeleted,
        });
        if (officeUsers.length === 0) {
            tryCatchAndRaiseError(async () => {
                await sdkSystem.deleteOffice({ id: officeIdToBeDeleted });
                addAlertMessages({
                    message: `${officeToBeDeleted.name} office has been deleted`,
                    category: AlertCategory.SUCCESS,
                });
            }, refreshState);
        } else {
            addAlertMessages({
                message:
                    "This office has brokers assigned to it and cannot be deleted. Reassign people to another office, or contact Joshu support for further assistance.",
                category: AlertCategory.SUCCESS,
            });
        }
    }, [
        officeIdToBeDeleted,
        officeToBeDeleted,
        sdkSystem,
        tryCatchAndRaiseError,
        refreshState,
        addAlertMessages,
    ]);

    return (
        <ConfirmationPopup
            name="delete-office"
            onSubmit={onSubmit}
            submitText="Delete Office"
            mobileSubmitText="Delete"
        >
            {officeToBeDeleted && (
                <>
                    <header>
                        <h2>Are you sure?</h2>
                        <p className="size-14">
                            This office will be deleted, and cannot be undone.
                        </p>
                    </header>
                </>
            )}
        </ConfirmationPopup>
    );
};

export default DeleteOfficePopup;
