import NotAnchor from "components/NotAnchor";
import { usePopup } from "contexts/PopupProvider";
import { FC, useCallback } from "react";
import { useInsuranceProcess } from "../InsuranceProcessProvider";
import { useApi } from "contexts/ApiProvider";
import { usePage } from "components/Page";
import { Quote } from "@joshuins/insurance";
import { ConfirmationPopup } from "components/Popup";
import { waitForProcessingInsuranceObject } from "../../util";

const SendQuoteForReviewLink: FC = () => {
    const { openPopup } = usePopup();

    return (
        <NotAnchor
            onClick={() => {
                openPopup("send-quote-for-review");
            }}
            aria-controls="send-quote-for-review"
            aria-haspopup="true"
        >
            Send <span className="mobile-hide">For Review </span>
            <span className="scheme-accesskey">
                <span>Shift</span> <span>q</span>
            </span>
        </NotAnchor>
    );
};

const SendQuoteForReviewPopup: FC = () => {
    const { sdkInsurance } = useApi();
    const { tryCatchAndRaiseError } = usePage();
    const {
        itemGetters: { getInsuranceProcess },
        insuranceProcessDispatch,
    } = useInsuranceProcess();
    const { quote } = getInsuranceProcess();

    const onSubmit = useCallback(async () => {
        if (!quote) {
            return;
        }
        tryCatchAndRaiseError(async () => {
            await sdkInsurance.updateQuote({
                id: quote.id,
                UpdateQuote: { status: "ReCalculate" },
            });

            const updatedQuote = (await waitForProcessingInsuranceObject(
                sdkInsurance,
                quote,
                "Sending..."
            )) as Quote;

            if (updatedQuote) {
                insuranceProcessDispatch({
                    action: "SetQuote",
                    quote: updatedQuote,
                });
            }
        });
    }, [insuranceProcessDispatch, quote, sdkInsurance, tryCatchAndRaiseError]);

    return (
        <ConfirmationPopup
            name="send-quote-for-review"
            onSubmit={onSubmit}
            submitText="Send For Review"
        >
            <header>
                <h2>Are you sure?</h2>
                <p className="size-14">
                    This quote will be sent to the appropriate underwriter.
                </p>
            </header>
        </ConfirmationPopup>
    );
};

export { SendQuoteForReviewPopup, SendQuoteForReviewLink };
