import {
    GlobalParameterSchemaV1,
    JoPlainTypeV1OneOf2Number,
    JoTypeV1,
    LineItemParameterSchemaV1,
    ParameterPermissionV1,
    QuoteCodeAndValue,
    QuoteStatus,
} from "@joshuins/insurance";
import { FC, createRef, useCallback } from "react";
import { useInsuranceProcess } from "../InsuranceProcessProvider";
import { NumericFormat } from "react-number-format";
import { MenuItem, Select } from "components/Select";
import {
    createPlainJoValue,
    getJoTypeFromParamCode,
    getRawValueAsDateFromJoValue,
    getRawValueAsStringFromJoValue,
    getUserOrRaterValue,
    setJoValue,
} from "utils/jo-types-and-values";
import { User } from "@joshuins/system";
import { UserAccountRole } from "@joshuins/auth";
import { useAuthUser } from "react-auth-kit";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { isStoreUser } from "pages/components/util";
import { formatDate } from "date-fns";

const USD_CURRENCY = "$" as const;

type ParameterData = {
    schema: GlobalParameterSchemaV1 | LineItemParameterSchemaV1;
    value: QuoteCodeAndValue;
};

const ParameterTypes = {
    Boolean: "Boolean",
    Currency: "Currency",
    CurrencyInteger: "CurrencyInteger",
    Date: "Date",
    Integer: "Integer",
    Number: "Number",
    Text: "Text",
} as const;

const isIntegerType = (numericType: JoPlainTypeV1OneOf2Number) => {
    return numericType.format.decimal_places === 0;
};

const parameterTypeToStringAndOptions = (
    joType: JoTypeV1
): {
    parameterType: keyof typeof ParameterTypes | undefined;
    options: string[];
} => {
    if ("Number" in joType) {
        return {
            parameterType: isIntegerType(joType.Number)
                ? ParameterTypes.Integer
                : ParameterTypes.Number,
            options: joType.Number.options.map((option) => option.value),
        };
    } else if ("Boolean" in joType) {
        return { parameterType: ParameterTypes.Boolean, options: [] };
    } else if ("Date" in joType) {
        return { parameterType: ParameterTypes.Date, options: [] };
    } else if ("Text" in joType) {
        return {
            parameterType: ParameterTypes.Text,
            options: joType.Text.options.map((option) => option.value),
        };
    } else if ("Monetary" in joType) {
        return {
            parameterType: isIntegerType(joType.Monetary)
                ? ParameterTypes.CurrencyInteger
                : ParameterTypes.Currency,
            options: joType.Monetary.options.map((option) => option.value),
        };
    } else {
        return {
            parameterType: undefined,
            options: [],
        };
    }
};

const parameterReadOnlyFormat = (type: string | undefined, value: string) => {
    // This function is to be used for non-editable parameters.
    // For editable parameters we use 'react-number-format',
    // but we need to implement the same formatting without an input element.

    switch (type) {
        case ParameterTypes.Currency: {
            return parseFloat(value)
                ? USD_CURRENCY +
                      parseFloat(value).toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                      })
                : "";
        }
        case ParameterTypes.CurrencyInteger: {
            return parseInt(value)
                ? USD_CURRENCY + parseInt(value).toLocaleString()
                : "";
        }
        case ParameterTypes.Number: {
            return parseFloat(value)
                ? parseFloat(value).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                  })
                : "";
        }
        case ParameterTypes.Integer: {
            return parseInt(value) ? parseInt(value).toLocaleString() : "";
        }
        case ParameterTypes.Boolean: {
            return value === "true" ? "Yes" : "No";
        }
        default: {
            return value;
        }
    }
};

const ParameterInput: FC<{
    parameterData: ParameterData;
}> = ({ parameterData }) => {
    const {
        itemGetters: { getInsuranceProcess },
        insuranceProcessDispatch,
    } = useInsuranceProcess();
    const authUser = useAuthUser();
    const currentUser = authUser() as User;
    const { quote, productVersion } = getInsuranceProcess();

    const authorityLevel = quote.authority_level ?? 1;
    let isEditable = false;
    if (
        // Broker - only if in store and authority allowed
        isStoreUser(currentUser) &&
        quote.status === QuoteStatus.QuoteStoreEdit &&
        authorityLevel === 0 &&
        parameterData.schema.customizability === ParameterPermissionV1.Users
    ) {
        isEditable = true;
    }
    if (
        // UW or Admin
        (currentUser.role === UserAccountRole.Underwriter ||
            currentUser.role === UserAccountRole.Admin) &&
        (quote.status === QuoteStatus.QuoteStoreEdit ||
            quote.status === QuoteStatus.QuotePending) &&
        parameterData.schema.customizability !== ParameterPermissionV1.Nobody
    ) {
        isEditable = true;
    }

    const { parameterType, options } = parameterTypeToStringAndOptions(
        parameterData.schema.type
    );

    const parameterChanged = useCallback(
        (parameterData: ParameterData, newValue: string) => {
            // if newValue is an empty string, and current value is NULL,
            // we do not want to set a user_value,
            // so that we do not override the rater value
            if (
                newValue === "" &&
                getRawValueAsStringFromJoValue(
                    getUserOrRaterValue(parameterData.value)
                ) === ""
            ) {
                return;
            }
            // On parameter change, we need to infer its type from the product schema and create the new JoValue for it.
            const paramType = getJoTypeFromParamCode(
                productVersion,
                parameterData.value.code
            );
            if (!paramType) {
                return;
            }
            const newjoValue = createPlainJoValue(paramType, newValue);
            const newQuoteData = {
                code: parameterData.value.code,
                user_value: setJoValue(newjoValue, newValue),
                rater_value: parameterData.value.rater_value,
                rank: parameterData.value.rank,
            };

            insuranceProcessDispatch({
                action: "SetDirtyFields",
                dirtyFields: [newQuoteData],
            });
            insuranceProcessDispatch({
                action: "UpdateQuoteCodesAndValues",
                quoteCodesAndValues: [newQuoteData],
            });
        },
        [insuranceProcessDispatch, productVersion]
    );

    if (isEditable) {
        let input;
        if (options && options.length > 0) {
            input = (
                <Select
                    id={parameterData.value.code}
                    name={parameterData.value.code}
                    value={getRawValueAsStringFromJoValue(
                        getUserOrRaterValue(parameterData.value)
                    )}
                    onChange={(e) => {
                        parameterChanged(
                            parameterData,
                            (e.target as HTMLInputElement).value
                        );
                    }}
                    fullWidth
                >
                    {options.map((option) => (
                        <MenuItem key={option} value={option}>
                            {parameterReadOnlyFormat(parameterType, option)}
                        </MenuItem>
                    ))}
                </Select>
            );
        } else if (parameterType === ParameterTypes.Date) {
            input = (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MuiDatePicker
                        name={parameterData.value.code}
                        value={getRawValueAsDateFromJoValue(
                            getUserOrRaterValue(parameterData.value)
                        )}
                        ref={createRef}
                        onChange={(value) => {
                            let formattedValue;
                            try {
                                formattedValue = value
                                    ? formatDate(value, "MM/dd/yyyy")
                                    : undefined;
                            } catch (error) {
                                formattedValue = undefined;
                            }
                            parameterChanged(
                                parameterData,
                                formattedValue ?? ""
                            );
                        }}
                        slotProps={{
                            inputAdornment: {
                                position: "start",
                            },
                        }}
                    />
                </LocalizationProvider>
            );
        } else if (parameterType == ParameterTypes.Boolean) {
            input = (
                <Select
                    id={parameterData.value.code}
                    name={parameterData.value.code}
                    value={getRawValueAsStringFromJoValue(
                        getUserOrRaterValue(parameterData.value)
                    )}
                    onChange={(e) => {
                        parameterChanged(
                            parameterData,
                            (e.target as HTMLInputElement).value
                        );
                    }}
                    fullWidth
                >
                    <MenuItem key="yes" value="true">
                        Yes
                    </MenuItem>
                    <MenuItem key="no" value="false">
                        No
                    </MenuItem>
                </Select>
            );
        } else if (parameterType === ParameterTypes.Number) {
            input = (
                <NumericFormat
                    value={getRawValueAsStringFromJoValue(
                        getUserOrRaterValue(parameterData.value)
                    )}
                    name={parameterData.value.code}
                    displayType="input"
                    thousandSeparator=","
                    allowLeadingZeros={false}
                    decimalScale={2}
                    fixedDecimalScale
                    onBlur={(e) => {
                        parameterChanged(
                            parameterData,
                            (e.target as HTMLInputElement).value
                        );
                    }}
                />
            );
        } else if (parameterType === ParameterTypes.Integer) {
            input = (
                <NumericFormat
                    value={getRawValueAsStringFromJoValue(
                        getUserOrRaterValue(parameterData.value)
                    )}
                    name={parameterData.value.code}
                    displayType="input"
                    thousandSeparator=","
                    allowLeadingZeros={false}
                    onBlur={(e) => {
                        parameterChanged(
                            parameterData,
                            (e.target as HTMLInputElement).value
                        );
                    }}
                />
            );
        } else if (parameterType === ParameterTypes.Currency) {
            input = (
                <NumericFormat
                    value={getRawValueAsStringFromJoValue(
                        getUserOrRaterValue(parameterData.value)
                    )}
                    name={parameterData.value.code}
                    displayType="input"
                    thousandSeparator=","
                    allowLeadingZeros={false}
                    decimalScale={2}
                    fixedDecimalScale
                    prefix={USD_CURRENCY}
                    onBlur={(e) => {
                        parameterChanged(
                            parameterData,
                            (e.target as HTMLInputElement).value
                        );
                    }}
                />
            );
        } else if (parameterType === ParameterTypes.CurrencyInteger) {
            input = (
                <NumericFormat
                    value={getRawValueAsStringFromJoValue(
                        getUserOrRaterValue(parameterData.value)
                    )}
                    name={parameterData.value.code}
                    displayType="input"
                    thousandSeparator=","
                    allowLeadingZeros={false}
                    prefix={USD_CURRENCY}
                    onBlur={(e) => {
                        parameterChanged(
                            parameterData,
                            (e.target as HTMLInputElement).value
                        );
                    }}
                />
            );
        } else {
            input = (
                <input
                    className={`parameter-data ${parameterType}`}
                    type="text"
                    id={parameterData.value.code}
                    defaultValue={getRawValueAsStringFromJoValue(
                        getUserOrRaterValue(parameterData.value)
                    )}
                    onBlur={(e) => {
                        parameterChanged(
                            parameterData,
                            (e.target as HTMLInputElement).value
                        );
                    }}
                    onChange={() => {}}
                />
            );
        }
        return input;
    }
    return parameterReadOnlyFormat(
        parameterType,
        getRawValueAsStringFromJoValue(getUserOrRaterValue(parameterData.value))
    );
};

export default ParameterInput;
export type { ParameterData };
export {
    parameterTypeToStringAndOptions,
    parameterReadOnlyFormat,
    USD_CURRENCY,
};
