import { FC, PropsWithChildren } from "react";
import { Page, TabInterface, usePage } from "components/Page";
import { Link } from "components/DevAwareRoutingLink";
import urlJoin from "url-join";
import { isBinder, isPolicy } from "utils/policies";
import {
    DocumentPacketTypeV1,
    QuoteStatus,
    SubmissionFlow,
} from "@joshuins/insurance";
import {
    InsuranceProcessProvider,
    useInsuranceProcess,
} from "./components/InsuranceProcessProvider";
import {
    DetailsSection,
    ConditionValuesPopup,
    ResetPremiumPopup,
} from "./components/quote-and-policy-sections/DetailsSection";
import {
    ChangeCoveragesPopup,
    CoverageSection,
} from "./components/quote-and-policy-sections/CoverageSection";
import {
    SubjectivitiesSection,
    AddSubjectivityPopup,
    RequiredSubjectivitiesPopup,
} from "./components/quote-and-policy-sections/SubjectivitiesSection";
import { DocumentsSection } from "./components/quote-and-policy-sections/DocumentsSection";
import { DeclineBindRequestPopup } from "./components/insurance-process-popups/DeclineBindRequestPopup";
import { PublishBinderPopup } from "./components/insurance-process-popups/PublishBinderPopup";
import { IssuePolicyPopup } from "./components/insurance-process-popups/IssuePolicyPopup";
import { CloseQuotePopup } from "./components/insurance-process-popups/CloseQuotePopup";
import { DeclineSubmissionPopup } from "./components/insurance-process-popups/DeclineSubmissionPopup";
import { PublishQuotePopup } from "./components/insurance-process-popups/PublishQuotePopup";
import { BindQuotePopup } from "./components/insurance-process-popups/BindQuotePopup";
import { EditBinderPopup } from "./components/insurance-process-popups/EditBinderPopup";
import {
    ManagePolicyPopup,
    ManagePolicyStorePopup,
} from "./components/insurance-process-popups/ManagePolicyPopup";
import QuoteOrPolicyExtraPane from "./components/QuoteOrPolicyExtraPane";
import { IssueEndorsementPopup } from "./components/insurance-process-popups/IssueEndorsementPopup";
import { ApplicationDetailsSection } from "./components/quote-and-policy-sections/ApplicationDetailsSection";
import { NotesSection } from "./components/quote-and-policy-sections/NotesSection";
import sortBy from "lodash/sortBy";
import { useBranding } from "contexts/BrandingProvider";
import { StoreBlockedQuotePage } from "pages/store/StoreBlockedQuotePage";
import classNames from "classnames";
import { StoreDocumentsSection } from "pages/store/StoreDocumentsSection";
import { SendQuoteForReviewPopup } from "./components/insurance-process-popups/SendForReviewPopup";
import { UnderwritersBlockedQuotePage } from "./UnderwritersBlockedQuotePage";
import { QuoteFooter } from "./QuoteFooter";
import { QuoteHeader } from "./QuoteHeader";
import { PolicyFooter } from "./PolicyFooter";
import { PolicyHeader } from "./PolicyHeader";
import { BindQuestionProvider } from "../submissions/components/BindQuestionProvider";
import { BindQuestions } from "./components/BindQuestionsComponents";
import GeneralErrorPage from "./components/quote-and-policy-sections/GeneralErrorPage";
import AlertMessageFlash from "components/AlertMessageFlash";
import { UploadManuscriptDocumentPopup } from "./components/quote-and-policy-sections/UploadManuscriptDocumentPopup";
import { UploadManuscriptPartPopup } from "./components/quote-and-policy-sections/UploadManuscriptPartPopup";
import { AddAllDocumentTypesPopup } from "./components/quote-and-policy-sections/AddDocumentPopup";
import { DocumentInputsPopup } from "./components/quote-and-policy-sections/DocumentInputs";
import { DocumentErrorPopup } from "./components/quote-and-policy-sections/DocumentErrorPopup";
import { QuoteOrPolicyCancellation } from "./QuoteOrPolicyCancellation";
import { CancelPolicyPopup } from "./components/insurance-process-popups/CancelPolicy";
import { DeclineCancellationPopup } from "./components/insurance-process-popups/DeclineCancellationPopup";

interface QuoteOrPolicyInterface {
    tabs?: TabInterface[];
}

const Footer: FC<PropsWithChildren> = ({ children }) => (
    <footer className="module-fixed sub sub-parent">{children}</footer>
);

const PolicyTabs: FC = () => {
    const {
        itemGetters: { getInsuranceProcess },
    } = useInsuranceProcess();
    const { quote, binderVariations } = getInsuranceProcess();
    const { generateUrl } = useBranding();

    if (!quote) {
        return <></>;
    }

    return (
        <>
            <ul className="list-tabs is-tabs" role="tablist">
                {binderVariations &&
                binderVariations.length > 0 &&
                isBinder(quote) ? (
                    sortBy(binderVariations, "id").map(
                        (binderVariation, index) => (
                            <li
                                className={
                                    quote.id === binderVariation.id
                                        ? "active"
                                        : ""
                                }
                                key={binderVariation.id}
                            >
                                <Link
                                    to={generateUrl(
                                        urlJoin(
                                            "/",
                                            "policies",
                                            binderVariation.id.toString()
                                        )
                                    )}
                                >
                                    Binder {index + 1}
                                </Link>
                            </li>
                        )
                    )
                ) : (
                    <li>Policy</li>
                )}
            </ul>
        </>
    );
};

const QuoteTabs: FC = () => {
    const {
        itemGetters: { getInsuranceProcess },
    } = useInsuranceProcess();
    const { quote, quoteVariations, submission } = getInsuranceProcess();
    const { generateUrl } = useBranding();

    return (
        <ul className="list-tabs is-tabs">
            <>
                {quoteVariations.map((quoteVariation, index) => (
                    <li
                        className={
                            quote.id === quoteVariation.id ? "active" : ""
                        }
                        key={quoteVariation.id}
                    >
                        <Link
                            to={generateUrl(
                                urlJoin(
                                    "/",
                                    "quotes",
                                    quoteVariation.id.toString()
                                )
                            )}
                        >
                            {isPolicy(quoteVariation) ? (
                                <>Policy</>
                            ) : submission.flow ===
                              SubmissionFlow.Endorsement ? (
                                <>Endorsement Quote {index + 1} </>
                            ) : (
                                <>Quote {index + 1} </>
                            )}
                        </Link>
                    </li>
                ))}
            </>
        </ul>
    );
};

const Main: FC = () => {
    const {
        itemGetters: { getInsuranceProcess },
        pageIds: { quoteId: pageQuoteId },
        insuranceProcessState: { dirtyFields: dirtyFields },
        insuranceProcessState,
        insuranceHistory,
        pageIds,
    } = useInsuranceProcess();
    const { submission, quote } = getInsuranceProcess();
    const { storeUrlName } = useBranding();
    const inStore = storeUrlName !== undefined;
    const { tabs, activeTab } = usePage();
    const activeTab_ = activeTab();

    const shouldShowApplicationSection =
        submission.flow === SubmissionFlow.Endorsement && pageQuoteId;

    const shouldShowSubjectivitiesSection =
        submission.flow !== SubmissionFlow.Endorsement &&
        quote.status !== QuoteStatus.QuoteStoreEdit;

    const shouldShowBlockedQuoteStorePage =
        inStore &&
        (quote.status === QuoteStatus.QuotePending ||
            quote.status === QuoteStatus.QuoteDeclined ||
            quote.status === QuoteStatus.Error ||
            quote.status === QuoteStatus.QuoteClosed ||
            quote.status === QuoteStatus.BinderPending);

    const shouldShowBlockedQuoteUnderwritersPage =
        !inStore &&
        quote.status === QuoteStatus.QuoteStoreEdit &&
        submission.flow === SubmissionFlow.Endorsement;

    if (
        !tabs ||
        !activeTab_ ||
        !insuranceProcessState.insuranceProcess ||
        !dirtyFields ||
        !insuranceHistory ||
        !pageIds ||
        !insuranceProcessState.insuranceProcess.quoteCodesAndValues
    ) {
        return <GeneralErrorPage />;
    }

    if (submission.flow === SubmissionFlow.Cancellation) {
        return <QuoteOrPolicyCancellation />;
    }
    return (
        <div
            id="wrap"
            className={classNames("module-tabs static is-tabs", {
                "text-center":
                    shouldShowBlockedQuoteStorePage ||
                    shouldShowBlockedQuoteUnderwritersPage,
            })}
        >
            {pageQuoteId ? <QuoteTabs /> : <PolicyTabs />}
            {pageQuoteId ? (
                <QuoteHeader tabs={tabs} />
            ) : (
                <PolicyHeader tabs={tabs} />
            )}
            <div className="module-tabs-content policy-details">
                {activeTab_.path === "details" && (
                    <>
                        {shouldShowBlockedQuoteStorePage ? (
                            <StoreBlockedQuotePage
                                quoteStatus={quote.status}
                                submission={submission}
                            />
                        ) : shouldShowBlockedQuoteUnderwritersPage ? (
                            <UnderwritersBlockedQuotePage
                                status={quote.status}
                            />
                        ) : (
                            <div className="module-quote">
                                <DetailsSection />
                                <CoverageSection />
                                {shouldShowApplicationSection && (
                                    <ApplicationDetailsSection />
                                )}
                                {shouldShowSubjectivitiesSection && (
                                    <SubjectivitiesSection />
                                )}
                                {inStore ? (
                                    <>
                                        {pageQuoteId ? (
                                            <StoreDocumentsSection
                                                documentType={
                                                    DocumentPacketTypeV1.NewQuote
                                                }
                                            />
                                        ) : quote.status ===
                                          QuoteStatus.BinderPublished ? (
                                            <StoreDocumentsSection
                                                documentType={
                                                    DocumentPacketTypeV1.Binder
                                                }
                                            />
                                        ) : (
                                            isPolicy(quote) && (
                                                <StoreDocumentsSection
                                                    documentType={
                                                        DocumentPacketTypeV1.Policy
                                                    }
                                                />
                                            )
                                        )}
                                        {submission.flow ===
                                            SubmissionFlow.Endorsement && (
                                            <StoreDocumentsSection
                                                documentType={
                                                    DocumentPacketTypeV1.EndorsementQuote
                                                }
                                            />
                                        )}
                                        {submission.flow ===
                                            SubmissionFlow.Renewal && (
                                            <StoreDocumentsSection
                                                documentType={
                                                    DocumentPacketTypeV1.RenewalQuote
                                                }
                                            />
                                        )}
                                        <DocumentsSection
                                            documentType={
                                                DocumentPacketTypeV1.Application
                                            }
                                        />
                                    </>
                                ) : (
                                    <>
                                        <DocumentsSection
                                            documentType={
                                                DocumentPacketTypeV1.NewQuote
                                            }
                                        />
                                        <DocumentsSection
                                            documentType={
                                                DocumentPacketTypeV1.Binder
                                            }
                                        />
                                        <DocumentsSection
                                            documentType={
                                                DocumentPacketTypeV1.Policy
                                            }
                                        />
                                        {submission.flow ===
                                            SubmissionFlow.Endorsement && (
                                            <DocumentsSection
                                                documentType={
                                                    DocumentPacketTypeV1.EndorsementQuote
                                                }
                                            />
                                        )}
                                        {submission.flow ===
                                            SubmissionFlow.Renewal && (
                                            <DocumentsSection
                                                documentType={
                                                    DocumentPacketTypeV1.RenewalQuote
                                                }
                                            />
                                        )}
                                        <DocumentsSection
                                            documentType={
                                                DocumentPacketTypeV1.Application
                                            }
                                        />
                                    </>
                                )}
                                <NotesSection />
                                <Footer>
                                    {pageQuoteId ? (
                                        <QuoteFooter />
                                    ) : (
                                        <PolicyFooter />
                                    )}
                                </Footer>
                                <div style={{ paddingBottom: "200px" }} />
                            </div>
                        )}
                    </>
                )}
                {activeTab_.path === "bind_requirements" && (
                    <>
                        <BindQuestionProvider>
                            <BindQuestions />
                        </BindQuestionProvider>
                        <div style={{ paddingBottom: "200px" }} />
                    </>
                )}
            </div>
        </div>
    );
};

const QuoteOrPolicyPage: FC<QuoteOrPolicyInterface> = ({ tabs }) => (
    <Page tabs={tabs}>
        <InsuranceProcessProvider>
            <Main />
            <AlertMessageFlash />
            <QuoteOrPolicyExtraPane />
            <ConditionValuesPopup />
            <DocumentInputsPopup />
            <DocumentErrorPopup />
            <ChangeCoveragesPopup />
            <PublishQuotePopup />
            <BindQuotePopup />
            <CloseQuotePopup />
            <DeclineSubmissionPopup />
            <RequiredSubjectivitiesPopup />
            <AddSubjectivityPopup />
            <DeclineBindRequestPopup />
            <DeclineCancellationPopup />
            <EditBinderPopup />
            <PublishBinderPopup />
            <IssuePolicyPopup />
            <ManagePolicyPopup />
            <ManagePolicyStorePopup />
            <CancelPolicyPopup />
            <AddAllDocumentTypesPopup />
            <IssueEndorsementPopup />
            <ResetPremiumPopup />
            <SendQuoteForReviewPopup />
            <UploadManuscriptPartPopup />
            <UploadManuscriptDocumentPopup />
        </InsuranceProcessProvider>
    </Page>
);

export { QuoteOrPolicyPage };
