import {
    createContext,
    FC,
    PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";

import { ParametersExceptFirst } from "types";
import type { ProductIntegrationV1, ProductVersion } from "@joshuins/builder";
import { Integration, IntegrationTypeInfo } from "@joshuins/system";
import { useApi } from "contexts/ApiProvider";
import { usePage } from "components/Page";
import { unpaginate } from "components/sdk";
import keyBy from "lodash/keyBy";
import cloneDeep from "lodash/cloneDeep";

interface IntegrationsProviderInterface {
    allowEditing: boolean;
    accessors: {
        productIntegrations: ProductIntegrationV1[] | undefined;
        allIntegrations: () =>
            | Record<Integration["id"], Integration>
            | undefined;
        getIntegration: (
            inegrationId: Integration["id"]
        ) => Integration | undefined;
        allIntegrationTypes: () =>
            | Record<IntegrationTypeInfo["name"], IntegrationTypeInfo>
            | undefined;
        getIntegrationType: (
            integrationType: IntegrationTypeInfo["name"]
        ) => IntegrationTypeInfo | undefined;
        getProductIntegrations: () => ProductIntegrationV1[] | undefined;
        getProductIntegration: (
            integrationId: string
        ) => ProductIntegrationV1 | undefined;
        hasProductIntegration: (integration: Integration) => boolean;
    };
    mutators: {
        setProductIntegrations: (
            productIntegrations: ProductIntegrationV1[]
        ) => void;
        addProductIntegration: (
            productIntegration: ProductIntegrationV1
        ) => Promise<void>;
        updateProductIntegration: (
            oldIntegrationId: ProductIntegrationV1["integration_id"],
            productIntegration: ProductIntegrationV1
        ) => Promise<void>;
        deleteProductIntegration: (
            integrationId: ProductIntegrationV1["integration_id"]
        ) => Promise<void>;
    };
}

const IntegrationsContext = createContext<
    IntegrationsProviderInterface | undefined
>(undefined);

const useIntegrations = () => {
    const context = useContext(IntegrationsContext);

    if (context === undefined) {
        throw Error(
            "useIntegrations must be used inside a IntegrationsProvider context"
        );
    }

    return context;
};

const IntegrationsProvider: FC<PropsWithChildren> = ({ children }) => {
    const { sdkSystem, sdkBuilder } = useApi();
    const { element, tryCatchAndRaiseError } = usePage();
    const productVersion = element as unknown as ProductVersion | undefined;
    const [integrations, setIntegrations_] =
        useState<Record<Integration["id"], Integration>>();
    const [integrationTypes, setIntegrationTypes_] =
        useState<Record<IntegrationTypeInfo["name"], IntegrationTypeInfo>>();
    const [productIntegrations, setProductIntegrations_] =
        useState<ProductIntegrationV1[]>();

    useEffect(() => {
        const getIntegrationData = async () => {
            const integrations = await unpaginate(
                sdkSystem.allIntegrations,
                {}
            );
            setIntegrations_(keyBy(integrations, "id"));
            const integrationTypes = await sdkSystem.getIntegrationTypes();
            setIntegrationTypes_(keyBy(integrationTypes, "name"));
        };
        getIntegrationData();
    }, [sdkSystem]);

    const draft = useCallback(
        () => cloneDeep(productIntegrations),
        [productIntegrations]
    );

    const setProductIntegrations = useCallback(
        (productIntegrations: ProductIntegrationV1[]) => {
            setProductIntegrations_(productIntegrations);
        },
        []
    );

    const addProductIntegration = useCallback(
        (
            productIntegrationsDraft: ProductIntegrationV1[],
            productIntegration: ProductIntegrationV1
        ) => {
            productIntegrationsDraft.push(productIntegration);
        },
        []
    );

    const updateProductIntegration = useCallback(
        (
            ProductIntegrationsDraft: ProductIntegrationV1[],
            oldIntegrationId: ProductIntegrationV1["integration_id"],
            ProductIntegration: ProductIntegrationV1
        ) => {
            const ProductIntegrationIndex = ProductIntegrationsDraft.findIndex(
                (ProductIntegration_) =>
                    ProductIntegration_.integration_id === oldIntegrationId
            );
            if (ProductIntegrationIndex !== -1) {
                ProductIntegrationsDraft[ProductIntegrationIndex] =
                    ProductIntegration;
            }
        },
        []
    );

    const deleteProductIntegration = useCallback(
        (
            ProductIntegrationsDraft: ProductIntegrationV1[],
            integrationId: ProductIntegrationV1["integration_id"]
        ) => {
            const ProductIntegrationIndex = ProductIntegrationsDraft.findIndex(
                (ProductIntegration) =>
                    ProductIntegration.integration_id === integrationId
            );

            if (ProductIntegrationIndex !== -1) {
                ProductIntegrationsDraft.splice(ProductIntegrationIndex, 1);
            }
        },
        []
    );

    const allIntegrations = useCallback(() => integrations, [integrations]);

    const getIntegration = useCallback(
        (integrationId: Integration["id"]) =>
            integrations && integrations[integrationId],
        [integrations]
    );

    const allIntegrationTypes = useCallback(
        () => integrationTypes,
        [integrationTypes]
    );

    const getProductIntegrations = useCallback(
        () => productIntegrations,
        [productIntegrations]
    );

    const getProductIntegration = useCallback(
        (integrationId: ProductIntegrationV1["integration_id"]) => {
            if (productIntegrations) {
                const index = productIntegrations.findIndex(
                    (integration) =>
                        integration.integration_id === integrationId
                );
                if (index !== -1) {
                    return productIntegrations[index];
                }
            }
            return undefined;
        },
        [productIntegrations]
    );

    const getIntegrationType = useCallback(
        (integrationType: string) => {
            if (integrationTypes) {
                return integrationTypes[integrationType];
            }
            return undefined;
        },
        [integrationTypes]
    );

    const hasProductIntegration = useCallback(
        (integration: Integration) => {
            if (productIntegrations) {
                const index = productIntegrations.findIndex(
                    (productIntegration) =>
                        productIntegration.integration_id === integration.id
                );
                return index !== -1;
            }
            return false;
        },
        [productIntegrations]
    );

    const wrapMutator_ = <
        MutatorFunction extends (
            IntegrationsDraft: ProductIntegrationV1[],
            ...rest: never[]
        ) => void,
    >(
        mutator: MutatorFunction
    ): ((...rest: ParametersExceptFirst<MutatorFunction>) => Promise<void>) => {
        const wrappedFunction = async (
            ...rest: ParametersExceptFirst<MutatorFunction>
        ) => {
            if (!productVersion) {
                return;
            }
            const productIntegrationsDraft = draft();
            if (!productIntegrationsDraft) {
                return;
            }
            mutator(productIntegrationsDraft, ...rest);
            tryCatchAndRaiseError(async () => {
                productVersion.schema.spec.integrations =
                    productIntegrationsDraft;
                await sdkBuilder.updateProductVersion({
                    id: productVersion.id,
                    UpdateProductVersion: { schema: productVersion.schema },
                });
                setProductIntegrations(productVersion.schema.spec.integrations);
            });
        };
        return wrappedFunction;
    };
    const wrapMutator = useCallback(wrapMutator_, [
        draft,
        productVersion,
        sdkBuilder,
        setProductIntegrations,
        tryCatchAndRaiseError,
    ]);

    const mutators = useMemo(() => {
        return {
            setProductIntegrations,
            addProductIntegration: wrapMutator(addProductIntegration),
            updateProductIntegration: wrapMutator(updateProductIntegration),
            deleteProductIntegration: wrapMutator(deleteProductIntegration),
        };
    }, [
        addProductIntegration,
        deleteProductIntegration,
        setProductIntegrations,
        updateProductIntegration,
        wrapMutator,
    ]);

    if (!productVersion) {
        return <></>;
    }

    return (
        <IntegrationsContext.Provider
            value={{
                allowEditing: !productVersion.is_published,
                mutators,
                accessors: {
                    productIntegrations,
                    allIntegrations,
                    getIntegration,
                    allIntegrationTypes,
                    getIntegrationType,
                    getProductIntegrations,
                    getProductIntegration,
                    hasProductIntegration,
                },
            }}
        >
            {children}
        </IntegrationsContext.Provider>
    );
};

export { IntegrationsProvider, useIntegrations };
