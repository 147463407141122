import { FC } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Brokerage, Office } from "@joshuins/system";
import { usePopup } from "contexts/PopupProvider";
import MenuPopover from "components/MenuPopover";
import NotAnchor from "components/NotAnchor";

const OfficeListItem: FC<{
    brokerage: Brokerage;
    office: Office;
    offices: Office[];
    totalRows: number;
    row: number;
}> = ({ brokerage, office, totalRows, row }) => {
    const { openPopup } = usePopup();
    const { attributes, listeners, setNodeRef, transform, transition } =
        useSortable({ id: office.id });

    return (
        <>
            <MenuPopover
                style={{
                    zIndex: totalRows - row,
                    transform: CSS.Transform.toString(transform),
                    transition,
                }}
                ref={setNodeRef}
                menuItems={[
                    {
                        key: `delete-office-${office.id}`,
                        label: "Delete Office",
                        icon: "trash",
                        onClick: () => {
                            openPopup("delete-office", {
                                officeIdToBeDeleted: office.id,
                            });
                        },
                    },
                    {
                        key: `office-data-${office.id}`,
                        label: "Office Data",
                        icon: "list",
                        onClick: () => {
                            openPopup("office-data", {
                                officeId: office.id,
                            });
                        },
                    },
                ]}
            >
                {({ ToggleButton, Menu }) => (
                    <>
                        <NotAnchor
                            onClick={() => {
                                openPopup("add-or-edit-office", {
                                    office: office,
                                    brokerageId: brokerage.id,
                                });
                            }}
                        >
                            {office.name}
                        </NotAnchor>
                        {!office.is_default && (
                            <>
                                {ToggleButton}
                                {Menu}
                            </>
                        )}
                        <div
                            className="handle ui-sortable-handle"
                            {...attributes}
                            {...listeners}
                        ></div>
                    </>
                )}
            </MenuPopover>
        </>
    );
};

export default OfficeListItem;
