import classNames from "classnames";
import { usePopup } from "contexts/PopupProvider";
import {
    DetailedHTMLProps,
    FC,
    forwardRef,
    HTMLAttributes,
    MouseEventHandler,
    PropsWithChildren,
    ReactElement,
    ReactNode,
    useEffect,
    useState,
} from "react";
import { Link, NavLink } from "components/DevAwareRoutingLink";
import NotAnchor from "./NotAnchor";
import { TabInterface, usePage } from "./Page";
import MenuPopover, { MenuItem } from "./MenuPopover";
import {
    addDocumentClass,
    removeDocumentClass,
} from "paul/native-dom-manipulation";
import { useMountedPath, useMountedPathAsString } from "utils/use-mounted-path";
import { MenuItem as SelectMenuItem, Select } from "components/Select";
import { SelectChangeEvent } from "@mui/material/Select";
import ListItemText from "@mui/material/ListItemText";
import { useApi } from "contexts/ApiProvider";
import { Integration, IntegrationTypeInfo } from "@joshuins/system";
import keyBy from "lodash/keyBy";
import { ProductVersion, Store } from "@joshuins/builder";
import { Insured, Quote, QuoteStatus, Submission } from "@joshuins/insurance";
import { quoteToShowFromVariations } from "utils/quote";
import { unpaginate } from "./sdk";
import AlertMessageFlash from "./AlertMessageFlash";
import urlJoin from "url-join";

const LEVEL1_BREADCRUMB_LOOKUP: { readonly [key: string]: string } = {
    stores: "Stores",
    products: "Products",
    insureds: "Insureds",
};

const LEVEL3_BREADCRUMB_LOOKUP: {
    readonly [key: string]: { readonly [key: string]: string };
} = {
    stores: {
        theme: "Theme",
        access: "Access",
        settings: "Settings",
    },
    products: {
        application: "Application",
        rater: "Rater",
        documents: "Documents",
        integrations: "Integrations",
        "bind-requirements": "Bind Requirements",
        settings: "Settings",
    },
    insureds: {
        details: "Details",
        application: "Application",
    },
};

interface MainPaneInterface {
    title?: string;
    titleIcon?: string;
    titlePill?: {
        color: "pear" | "gold" | "wine";
        text: string;
    };
    cta?:
        | {
              buttonLabel: string;
              popupName: string;
          }
        | {
              buttonLabel: string;
              url: string;
          }
        | {
              buttonLabel: string;
              callback: () => void;
          };
    layoutConfig?: {
        mainLayout?: null | "center" | "wide" | "centerAndWide";
        headerBottomMargin?: boolean;
        headerPosition?: "above-content" | "inside-content";
        headerClass?: null | "double" | "product";
    };
    headerMenu?: {
        items: MenuItem[];
        toggleButtonLabel?: string;
    };
    subHeader?: {
        text?: string;
        items?: (string | null)[];
        pill?: {
            color: "pear" | "gold" | "wine";
            text: string;
        };
        warning?: {
            onClick: MouseEventHandler<HTMLAnchorElement>;
        };
        error?: {
            onClick: MouseEventHandler<HTMLAnchorElement>;
        };
        rightAlignedElement?: ReactElement;
    };
    hiddenTabPaths?: string[];
    headerActions?: {
        text: string;
        mobileText?: string;
        onClick: () => void;
        icon: string;
    }[];
    headerSelect?: {
        label: string;
        selectedValue: string | number;
        onChange: (event: SelectChangeEvent<Event | string | number>) => void;
        options: {
            value: string | number;
            text: string;
            extra?: ReactElement;
        }[];
    };
    headerActionsOnSameLineAsTitle?: boolean;
}

const Breadcrumbs: FC = () => {
    const { element } = usePage();
    const mountedPath = useMountedPath();
    const level0MountedPathAsString = useMountedPathAsString({ upTo: 0 });
    const level1MountedPathAsString = useMountedPathAsString({ upTo: 1 });
    const level2MountedPathAsString = useMountedPathAsString({ upTo: 2 });
    const { sdkSystem, sdkInsurance } = useApi();
    const [integrationTypes, setIntegrationTypes] =
        useState<Record<IntegrationTypeInfo["name"], IntegrationTypeInfo>>();
    const [insuredFromSubmission, setInsuredFromSubmission] =
        useState<Insured>();
    const [submissionQuote, setSubmissionQuote] = useState<Quote | undefined>();
    const inStore = mountedPath[0] === "store";
    const storeUrlName = inStore ? mountedPath[1] : undefined;

    useEffect(() => {
        const getIntegrationTypes = async () => {
            if (mountedPath[2] === "integrations" && mountedPath[3]) {
                const integrationTypes = await sdkSystem.getIntegrationTypes();
                setIntegrationTypes(keyBy(integrationTypes, "name"));
            }
        };
        getIntegrationTypes();
    }, [sdkSystem, mountedPath]);

    useEffect(() => {
        const getInsuredFromSubmission = async () => {
            if (
                ((!inStore && mountedPath[1] === "submissions") ||
                    (inStore && mountedPath[2] === "submissions")) &&
                mountedPath[3]
            ) {
                const policyId = (element as Submission).policy_id;
                if (!policyId) {
                    return;
                }
                const policy = await sdkInsurance.getPolicy({ id: policyId });
                const insuredId = policy.insured_id;
                if (!insuredId) {
                    return;
                }
                const insuredFromSubmission_ = await sdkInsurance.getInsured({
                    id: insuredId,
                });
                setInsuredFromSubmission(insuredFromSubmission_);
            }
        };
        getInsuredFromSubmission();
    }, [element, inStore, mountedPath, sdkInsurance]);

    useEffect(() => {
        const getSubmissionQuote = async () => {
            if (
                ((!inStore && mountedPath[1] === "submissions") ||
                    (inStore && mountedPath[2] === "submissions")) &&
                mountedPath[3]
            ) {
                const sumissionId = (element as Submission).id;
                const allQuoteVariations = await unpaginate(
                    sdkInsurance.allQuotes,
                    {
                        submission_id: sumissionId,
                    }
                );
                setSubmissionQuote(
                    quoteToShowFromVariations(
                        allQuoteVariations.filter(
                            (quote) =>
                                quote.status !== QuoteStatus.QuoteIndication
                        )
                    )
                );
            }
        };
        getSubmissionQuote();
    }, [element, inStore, mountedPath, sdkInsurance]);

    const crumbs: (ReactNode | string)[] = [];

    if (element) {
        if (mountedPath[3] === "home") {
            crumbs.push(
                <NavLink to={level1MountedPathAsString}>
                    {LEVEL1_BREADCRUMB_LOOKUP[mountedPath[1]]}
                </NavLink>
            );
            crumbs.push(
                (element as Store).name ||
                    (element as ProductVersion).schema.metadata.name
            );
        } else if (mountedPath[2] === "integrations" && mountedPath[3]) {
            crumbs.push(
                <NavLink to={level2MountedPathAsString}>Integrations</NavLink>
            );
            if (integrationTypes) {
                crumbs.push(
                    integrationTypes[(element as Integration).type_]
                        .display_name
                );
            }
        } else if (mountedPath[1] === "insureds" && mountedPath[3]) {
            crumbs.push(
                <NavLink to={level1MountedPathAsString}>Insureds</NavLink>
            );
            crumbs.push((element as Insured).name);
        } else if (
            (!inStore && mountedPath[1] === "submissions" && mountedPath[3]) ||
            (inStore && mountedPath[2] === "submissions" && mountedPath[4])
        ) {
            if (submissionQuote) {
                const link = inStore
                    ? `${level0MountedPathAsString}/${storeUrlName}/quotes/${submissionQuote.id}`
                    : `${level0MountedPathAsString}/quotes/${submissionQuote.id}`;
                crumbs.push(<NavLink to={link}>Quote</NavLink>);
            } else if (insuredFromSubmission) {
                const link = inStore
                    ? `${level0MountedPathAsString}/${storeUrlName}/insureds/${insuredFromSubmission.id}`
                    : `${level0MountedPathAsString}/insureds/${insuredFromSubmission.id}`;
                crumbs.push(
                    <NavLink to={link}>{insuredFromSubmission.name}</NavLink>
                );
            } else {
                return;
            }
            crumbs.push("Application");
        } else if (mountedPath[3]) {
            crumbs.push(
                <NavLink to={level2MountedPathAsString}>
                    {(element as Store).name ||
                        (element as ProductVersion).schema.metadata.name}
                </NavLink>
            );
            if (mountedPath[1] in LEVEL3_BREADCRUMB_LOOKUP) {
                crumbs.push(
                    LEVEL3_BREADCRUMB_LOOKUP[mountedPath[1]][mountedPath[3]]
                );
            } else if (mountedPath[2] in LEVEL3_BREADCRUMB_LOOKUP) {
                crumbs.push(
                    LEVEL3_BREADCRUMB_LOOKUP[mountedPath[2]][mountedPath[3]]
                );
            }
        }
    }

    if (crumbs.length < 2) {
        return <></>;
    } else {
        return (
            <ul className="list-breadcrumbs mobile-compact">
                <li className="strong">{crumbs[0]}</li>
                <li>{crumbs[1]}</li>
                {crumbs[2] && <li>{crumbs[2]}</li>}
            </ul>
        );
    }
};

const Footer = forwardRef<
    HTMLElement,
    DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>
>(({ children, className, ...rest }, ref) => {
    useEffect(() => {
        addDocumentClass("has-module-fixed");
        return () => {
            removeDocumentClass("has-module-fixed");
        };
    }, []);

    return (
        <footer
            ref={ref}
            className={classNames(className, "module-fixed")}
            {...rest}
        >
            {children}
        </footer>
    );
});
Footer.displayName = "Footer";

const MainPane: FC<PropsWithChildren<MainPaneInterface>> = ({
    children,
    layoutConfig,
    headerMenu,
    title,
    titleIcon,
    titlePill,
    cta,
    subHeader,
    hiddenTabPaths,
    headerActions,
    headerSelect,
    headerActionsOnSameLineAsTitle,
}) => {
    const { tabs, activeTab, hasTabs: hasTabs_ } = usePage();

    const { openPopup } = usePopup();
    const mountedPath = useMountedPath().slice(0, -1);
    const hasTabs = hasTabs_();
    const tabs_ = (hasTabs ? tabs : []) as TabInterface[];
    const tabsToDisplay = hiddenTabPaths
        ? tabs_.filter(
              (tab) =>
                  !hiddenTabPaths.some((hiddenTab) => hiddenTab === tab.path)
          )
        : tabs_;

    const header = (title || cta || hasTabs) && (
        <header
            className={classNames(
                layoutConfig?.headerClass === null
                    ? ""
                    : `header-${layoutConfig?.headerClass ?? "double"}`,
                {
                    a: cta || headerSelect,
                    m30: !!layoutConfig?.headerBottomMargin,
                }
            )}
        >
            <h1>
                {titleIcon && (
                    <>
                        <i className={`icon-${titleIcon}`} />{" "}
                    </>
                )}
                {title}
            </h1>
            {titlePill && (
                <ul className="list-inline a">
                    <li className={`overlay color-${titlePill.color}`}>
                        <span>{titlePill.text}</span>
                    </li>
                </ul>
            )}
            {cta && (
                <ul className="list-inline">
                    <li className="link-btn">
                        {"popupName" in cta ? (
                            <NotAnchor
                                onClick={() => {
                                    openPopup(cta.popupName);
                                }}
                                className="mobile-hide"
                            >
                                <i
                                    aria-hidden="true"
                                    className="icon-plus-circle"
                                />{" "}
                                {cta.buttonLabel}
                            </NotAnchor>
                        ) : "callback" in cta ? (
                            <NotAnchor
                                onClick={() => {
                                    cta.callback();
                                }}
                                className="mobile-hide"
                            >
                                <i
                                    aria-hidden="true"
                                    className="icon-plus-circle"
                                />{" "}
                                {cta.buttonLabel}
                            </NotAnchor>
                        ) : (
                            <Link to={cta.url} className="mobile-hide">
                                <i
                                    aria-hidden="true"
                                    className="icon-plus-circle"
                                />
                                {cta.buttonLabel}
                            </Link>
                        )}
                    </li>
                </ul>
            )}
            {headerSelect && headerSelect.options.length > 0 && (
                <form action="./" method="post">
                    <div className="select-small text-right m0">
                        <span className="label hidden">
                            {headerSelect.label}
                        </span>
                        <Select
                            autoWidth={true}
                            name="header-select"
                            id="header-select"
                            defaultValue={headerSelect.selectedValue}
                            onChange={headerSelect.onChange}
                        >
                            {headerSelect.options.map((option) => (
                                <SelectMenuItem
                                    key={option.value}
                                    value={option.value}
                                >
                                    <ListItemText>{option.text}</ListItemText>
                                </SelectMenuItem>
                            ))}
                        </Select>
                    </div>
                </form>
            )}
            {headerActions && headerActions.length > 0 && (
                <ul
                    className={classNames("list-inline", {
                        a: !headerActionsOnSameLineAsTitle,
                    })}
                >
                    {headerActions.map(
                        ({ text, mobileText, onClick, icon }, index) => (
                            <li
                                className={classNames({
                                    "text-right": index === 0,
                                })}
                                style={{ color: "var(--main_color)" }}
                                key={text}
                            >
                                <NotAnchor
                                    className="all-sections"
                                    onClick={() => {
                                        onClick();
                                    }}
                                >
                                    <i className={`icon-${icon}`} />{" "}
                                    <span className="mobile-hide">{text}</span>
                                    {mobileText && (
                                        <span className="mobile-only">
                                            {mobileText}
                                        </span>
                                    )}
                                </NotAnchor>
                            </li>
                        )
                    )}
                </ul>
            )}
            {subHeader &&
                (subHeader.items || subHeader.pill || subHeader.text) && (
                    <>
                        <ul className="list-inline a">
                            {subHeader.items?.map(
                                (subHeaderItem) =>
                                    subHeaderItem && (
                                        <li key={subHeaderItem}>
                                            {subHeaderItem}
                                        </li>
                                    )
                            )}
                            {subHeader.pill && (
                                <li
                                    className={`overlay color-${subHeader.pill.color}`}
                                >
                                    <span>{subHeader.pill.text}</span>
                                </li>
                            )}
                            {subHeader.error && (
                                <li>
                                    <NotAnchor
                                        onClick={subHeader.error.onClick}
                                    >
                                        <i
                                            aria-hidden="true"
                                            className="icon-error color-wine"
                                        />
                                    </NotAnchor>
                                </li>
                            )}
                            {subHeader.warning && (
                                <li>
                                    <NotAnchor
                                        onClick={subHeader.warning.onClick}
                                    >
                                        <i
                                            aria-hidden="true"
                                            className="icon-error color-gold"
                                        />
                                    </NotAnchor>
                                </li>
                            )}
                            {subHeader.rightAlignedElement && (
                                <li className="text-right">
                                    {subHeader.rightAlignedElement}
                                </li>
                            )}
                        </ul>
                        {subHeader.text && (
                            <h2 className="m0 color-primary">
                                {subHeader.text}
                            </h2>
                        )}
                    </>
                )}
            {/* only dislay tabs if there are at least two non-hidden tabs */}
            {tabsToDisplay.length > 0 && (
                <ul className="list-inline tabs strong module-tabs-nav is-tabs">
                    {tabs_.map((tab) => (
                        <li
                            key={tab.path}
                            className={classNames({
                                active: tab === activeTab(),
                            })}
                        >
                            <Link to={urlJoin("/", ...mountedPath, tab.path)}>
                                {tab.title}
                            </Link>
                        </li>
                    ))}
                </ul>
            )}
            {headerMenu && (
                <ul className="list-inline wide">
                    <MenuPopover
                        additionalClasses={["text-right"]}
                        menuItems={headerMenu.items}
                        toggleButtonLabel={headerMenu.toggleButtonLabel}
                    >
                        {({ ToggleButton, Menu }) => (
                            <>
                                {ToggleButton}
                                {Menu}
                            </>
                        )}
                    </MenuPopover>
                </ul>
            )}
        </header>
    );

    return (
        <>
            <div
                id="wrap"
                className={classNames({
                    "module-tabs static": hasTabs,
                    wide:
                        layoutConfig?.mainLayout === undefined
                            ? true
                            : (
                                  ["wide", "centerAndWide"] as (string | null)[]
                              ).includes(layoutConfig.mainLayout),
                    "text-center":
                        layoutConfig?.mainLayout === undefined
                            ? false
                            : (
                                  ["center", "centerAndWide"] as (
                                      | string
                                      | null
                                  )[]
                              ).includes(layoutConfig.mainLayout),
                })}
            >
                <Breadcrumbs />
                {(!layoutConfig?.headerPosition ||
                    layoutConfig?.headerPosition === "above-content") &&
                    header}
                <main
                    id="content"
                    className={classNames({
                        wide:
                            layoutConfig?.mainLayout === undefined
                                ? true
                                : (
                                      ["wide", "centerAndWide"] as (
                                          | string
                                          | null
                                      )[]
                                  ).includes(layoutConfig.mainLayout),
                        "text-center":
                            layoutConfig?.mainLayout === undefined
                                ? false
                                : (
                                      ["center", "centerAndWide"] as (
                                          | string
                                          | null
                                      )[]
                                  ).includes(layoutConfig.mainLayout),
                    })}
                >
                    {layoutConfig?.headerPosition === "inside-content" &&
                        header}
                    {children}
                </main>
            </div>
            {cta && (
                <p className="link-btn mobile-sticky">
                    {"popupName" in cta ? (
                        <NotAnchor
                            onClick={() => {
                                openPopup(cta.popupName);
                            }}
                        >
                            <i className="icon-plus-circle" />
                            {cta.buttonLabel}
                        </NotAnchor>
                    ) : "callback" in cta ? (
                        <NotAnchor
                            onClick={() => {
                                cta.callback();
                            }}
                        >
                            <i className="icon-plus-circle" />
                            {cta.buttonLabel}
                        </NotAnchor>
                    ) : (
                        <Link to={cta.url}>
                            <i className="icon-plus-circle" />
                            {cta.buttonLabel}
                        </Link>
                    )}
                </p>
            )}
            <AlertMessageFlash />
        </>
    );
};

export { MainPane, Footer };
export type { MainPaneInterface };
