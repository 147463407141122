const BUILDER_PATH = "admin",
    // TODO? MANAGER_PATH = "manager",
    UNDERWRITER_PATH = "underwriters",
    STORE_PATH = "store";

const INSURED_OPTIONAL_SETTINGS_APPEARANCE_OPTIONS = [
    "Hidden",
    "Optional",
    "Required",
    "Clearance",
] as const;

export {
    BUILDER_PATH,
    UNDERWRITER_PATH,
    // TODO? MANAGER_PATH,
    STORE_PATH,
    INSURED_OPTIONAL_SETTINGS_APPEARANCE_OPTIONS,
};
