import { FC, useCallback } from "react";
import { QuoteStatus } from "@joshuins/insurance";
import { createFormPopup } from "components/Popup";
import { useApi } from "contexts/ApiProvider";
import { useInsuranceProcess } from "../InsuranceProcessProvider";
import { usePopup } from "contexts/PopupProvider";
import { usePage } from "components/Page";
import NotAnchor from "components/NotAnchor";
import { waitForProcessingInsuranceObject } from "../../util";
import { z } from "zod";
import { SubmissionFlow } from "@joshuins/builder";

const DECLINE_CANCELLATION = "decline-cancellation";

const DeclineCancellationLink: FC = () => {
    const { openPopup } = usePopup();
    return (
        <NotAnchor
            onClick={() => {
                openPopup(DECLINE_CANCELLATION);
            }}
            className="inline"
        >
            <i className="icon-x-circle" /> Decline{" "}
            <span className="mobile-hide">Cancellation</span>
        </NotAnchor>
    );
};

const createDeclineCancellationSchema = z.object({
    additional_details: z.string(),
});

type DeclineCancellationType = z.infer<typeof createDeclineCancellationSchema>;

const DeclineCancellationPopup: FC = () => {
    const { FormPopup } = createFormPopup(createDeclineCancellationSchema);
    const { sdkInsurance } = useApi();
    const { tryCatchAndRaiseError, refreshElement } = usePage();
    const {
        itemGetters: { getInsuranceProcess },
    } = useInsuranceProcess();

    const { quote, submission } = getInsuranceProcess();

    const onSubmit = useCallback(
        async (data: DeclineCancellationType) => {
            if (!quote || submission.flow !== SubmissionFlow.Cancellation) {
                return <></>;
            }
            tryCatchAndRaiseError(async () => {
                await sdkInsurance.updateQuote({
                    id: quote.id,
                    UpdateQuote: {
                        status: QuoteStatus.QuoteDeclined,
                        additional_details: data.additional_details,
                    },
                });
                await waitForProcessingInsuranceObject(
                    sdkInsurance,
                    quote,
                    "Declining Cancellation..."
                );
            }, refreshElement);
        },
        [
            quote,
            submission.flow,
            tryCatchAndRaiseError,
            refreshElement,
            sdkInsurance,
        ]
    );

    return (
        <FormPopup
            name={DECLINE_CANCELLATION}
            defaultValues={{}}
            onSubmit={onSubmit}
            submitText="Decline"
        >
            {({ register }) => (
                <>
                    <header>
                        <h2>Are you sure?</h2>
                    </header>
                    <p className="size-14 m15">
                        Please choose a reason to help the broker understand why
                        the cancellation was declined
                    </p>
                    <p>
                        <label htmlFor="faidec">
                            Additional Details (optional)
                        </label>
                        <input
                            {...register("additional_details")}
                            type="additional_details"
                            id="faidec"
                        />
                    </p>
                </>
            )}
        </FormPopup>
    );
};

export {
    DECLINE_CANCELLATION,
    DeclineCancellationLink,
    DeclineCancellationPopup,
};
