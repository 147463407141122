import { FC } from "react";
import { QuoteCodeAndUserValue, QuoteStatus } from "@joshuins/insurance";
import NotAnchor from "components/NotAnchor";
import {
    getRawValueAsStringFromJoValue,
    getUserOrRaterValue,
    setJoValue,
} from "utils/jo-types-and-values";
import { useInsuranceProcess } from "../InsuranceProcessProvider";

const SaveChangesLink: FC = () => {
    const {
        itemGetters: { getInsuranceProcess },
        recalculateQuote,
        insuranceProcessState: { dirtyFields: dirtyFields },
    } = useInsuranceProcess();

    const { quote } = getInsuranceProcess();

    const submitChanges = async () => {
        const updatedValues: QuoteCodeAndUserValue[] = [];
        if (
            quote.status === QuoteStatus.QuotePending ||
            quote.status === QuoteStatus.BinderPending
        ) {
            dirtyFields.map((item) => {
                updatedValues.push({
                    code: item.code,
                    user_value: setJoValue(
                        getUserOrRaterValue(item),
                        getRawValueAsStringFromJoValue(item.user_value)
                    ),
                    rank: item.rank,
                });
            });
        }

        recalculateQuote(updatedValues);
    };

    return (
        <NotAnchor
            onClick={() => {
                submitChanges();
            }}
        >
            <i aria-hidden="true" className="icon-check-circle-outline" /> Save{" "}
            <span className="mobile-hide">Changes </span>
        </NotAnchor>
    );
};

export { SaveChangesLink };
