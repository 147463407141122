import { ReactElement, useCallback } from "react";
import { ProductVersion, Store } from "@joshuins/builder";
import { AlertCategory, usePage } from "components/Page";
import { ConfirmationPopup } from "components/Popup";
import { useApi } from "contexts/ApiProvider";
import { usePopup } from "contexts/PopupProvider";

const ARCHIVE_DRAFT_VERSION =
    "archive-draft-or-republish-product-version" as const;

const ArchiveDraftOrRepublishProductVersionPopup = () => {
    const { sdkBuilder } = useApi();
    const { addAlertMessages, element, refreshElement, tryCatchAndRaiseError } =
        usePage();
    const productVersion = element as unknown as ProductVersion | undefined;
    const { popupData } = usePopup();
    const stores = popupData?.stores as Store[] | undefined;

    const onSubmit = useCallback(async () => {
        if (!productVersion || !stores) {
            return;
        }
        tryCatchAndRaiseError(async () => {
            let alertAction = "archived";
            if (productVersion.is_archived) {
                if (productVersion.is_published) {
                    alertAction = "published";
                } else {
                    alertAction = "moved to drafts";
                }
            } else if (stores && stores.length > 0) {
                for (const store of stores) {
                    await sdkBuilder.deleteStoreProduct({
                        product_id: productVersion.product_id,
                        store_id: store.id,
                    });
                }
            }

            await sdkBuilder.updateProductVersion({
                id: productVersion.id,
                UpdateProductVersion: {
                    is_archived: !productVersion.is_archived,
                },
            });

            addAlertMessages({
                message: `${productVersion.schema.metadata.name} product successfully ${alertAction}`,
                category: AlertCategory.SUCCESS,
            });
        }, refreshElement);
    }, [
        productVersion,
        stores,
        tryCatchAndRaiseError,
        refreshElement,
        sdkBuilder,
        addAlertMessages,
    ]);

    if (!productVersion || !stores) {
        return <></>;
    }

    let content: {
        submitButtonText: string;
        iUnderstandText: string;
        listItems: ReactElement[];
    };

    if (productVersion.is_archived) {
        if (productVersion.is_published) {
            content = {
                submitButtonText: "Publish",
                iUnderstandText:
                    "I understand this will publish and lock the product",
                listItems: [
                    <>This version of the product will be republished</>,
                    <>
                        This version of the product will be marked as{" "}
                        <span className="strong">Published</span>
                    </>,
                ],
            };
        } else {
            content = {
                submitButtonText: "Draft",
                iUnderstandText:
                    "I understand this will draft this product version",
                listItems: [
                    <>
                        This product version will be moved back to the{" "}
                        <span className="strong">Draft Products</span> tab
                    </>,
                ],
            };
        }
    } else {
        content = {
            submitButtonText: "Archive",
            iUnderstandText:
                "I understand this will archive this product version",
            listItems: [
                <>
                    This version of the product will be removed from all{" "}
                    <span className="strong">Stores</span> and will not be
                    available for new submissions
                </>,
                <>
                    Existing quotes, binders, and policies attached to this
                    version will <span className="strong">not</span> be affected
                </>,
                <>
                    If you need it later, you can find this version within the
                    product marked with an{" "}
                    <span className="strong">Archived</span> tag
                </>,
            ],
        };
    }

    return (
        <ConfirmationPopup
            name={ARCHIVE_DRAFT_VERSION}
            onSubmit={onSubmit}
            submitText={content.submitButtonText}
            needsIUnderstandCheckbox
            iUnderstandCheckboxId="confirm-archive-product-checkbox"
        >
            {({ iUnderstandCheckbox }) => (
                <>
                    <header>
                        <h2>Are you sure?</h2>
                    </header>
                    <ul className="list-static a">
                        {content.listItems.map((listItem, index) => (
                            <li key={index.toString()}>{listItem}</li>
                        ))}
                    </ul>
                    <p className="check box">
                        {iUnderstandCheckbox}
                        <label htmlFor="confirm-archive-product-checkbox">
                            {content.iUnderstandText}
                        </label>
                    </p>
                </>
            )}
        </ConfirmationPopup>
    );
};

export default ArchiveDraftOrRepublishProductVersionPopup;
export { ARCHIVE_DRAFT_VERSION };
