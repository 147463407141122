import { Page } from "components/Page";
import InsuranceObjectList from "./components/InsuranceObjectList";
import { useApi } from "contexts/ApiProvider";
import { FC, useEffect, useState } from "react";
import ExtraPane from "components/extra-panes/ExtraPane";
import { InsuranceListObject } from "./util";
import { QuoteStatus } from "@joshuins/insurance";
import { hideLoader, showLoader } from "paul/native-dom-manipulation";

const QUOTES_STATUSES = [
    QuoteStatus.QuotePublished,
    QuoteStatus.QuoteClosed,
    QuoteStatus.QuoteDeclined,
    QuoteStatus.Error,
];

const Main = () => {
    const { sdkInsurance } = useApi();
    const [insuranceObjects, setInsuranceObjects] =
        useState<InsuranceListObject[]>();

    useEffect(() => {
        const getQuotes = async () => {
            showLoader("Loading...");
            const quotes =
                await sdkInsurance.getAllQuotesWithoutPolicies(QUOTES_STATUSES);
            setInsuranceObjects(quotes);
            hideLoader();
        };

        getQuotes();
    }, [sdkInsurance]);

    if (!insuranceObjects) {
        return <></>;
    }

    return (
        <InsuranceObjectList
            title="Quotes"
            insuranceObjects={insuranceObjects}
            textWhenEmpty="When submission are approved the published quotes will appear here."
            icon="icon-documents"
        />
    );
};

const ListQuotesExtraPane = () => (
    <ExtraPane>
        <h3>
            <i aria-hidden="true" className="icon-help" /> Quotes
        </h3>
        <p className="size-14">
            Pending quotes will always appear first in your &quot;In
            Review&quot; tab. Once they are published, they appear here in
            Quotes with the appropriate status.
        </p>
        <p className="size-14">
            To issue a policy, select &quot;Bind Quote.&quot; This quote will be
            moved to &quot;In Review&quot; as pending binder. From there, you
            can review any outstanding requirements and either release the
            binder, or issue a policy.
        </p>
    </ExtraPane>
);

const UnderwritersQuotesPage: FC = () => (
    <Page>
        <Main />
        <ListQuotesExtraPane />
    </Page>
);

export default UnderwritersQuotesPage;
