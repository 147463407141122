import { useNavigate } from "components/DevAwareRoutingLink";
import { BUILDER_PATH } from "globals";
import urlJoin from "url-join";
import { ProductVersionView } from "@joshuins/builder";

const useNavigateToProductVersion = () => {
    const navigate = useNavigate();

    return (productVersion: ProductVersionView) => {
        navigate(
            urlJoin("/", BUILDER_PATH, "products", productVersion.id.toString())
        );
    };
};

export default useNavigateToProductVersion;
