/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { FC, useEffect } from "react";
import { Page } from "components/Page";
import { MainPane } from "components/MainPane";
import ExtraPane from "components/extra-panes/ExtraPane";
import { DataGrid } from "@mui/x-data-grid/DataGrid";
import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import Box from "@mui/material/Box";
import { useImmer } from "use-immer";
import { useApi } from "contexts/ApiProvider";
import type { Notification } from "@joshuins/system";
import { formatDatetimeString } from "utils/datetime";
import capitalize from "lodash/capitalize";
import groupBy from "lodash/groupBy";
import keyBy from "lodash/keyBy";
import { asyncMap } from "modern-async";

const dateFormatter = (dateValue: string) => {
    try {
        return formatDatetimeString(dateValue, "withTime");
    } catch (error) {
        return "";
    }
};

const columns: GridColDef[] = [
    {
        field: "to_email_address",
        headerName: "To",
        width: 200,
        sortable: false,
    },
    {
        field: "template_name",
        headerName: "Template Name",
        width: 250,
        sortable: false,
        valueFormatter: (value: string) =>
            capitalize(value.split("/").join(":")),
    },
    {
        field: "status",
        headerName: "Status",
        width: 100,
        sortable: false,
    },
    {
        field: "created_at",
        headerName: "Created At",
        width: 150,
        sortable: false,
        valueFormatter: (value: string) => dateFormatter(value),
    },
    {
        field: "sent_at",
        headerName: "Sent At",
        width: 150,
        sortable: false,
        valueFormatter: (value: string) => dateFormatter(value),
    },
    {
        field: "delivered_at",
        headerName: "Delivered At",
        width: 150,
        sortable: false,
        valueFormatter: (value: string) => dateFormatter(value),
    },
    {
        field: "opened_at",
        headerName: "Opened At",
        width: 150,
        sortable: false,
        valueFormatter: (value: string) => dateFormatter(value),
    },
];

const pageSizeOptions = [10, 20, 50];

const Main = () => {
    const [dataGridState, setDataGridState] = useImmer<{
        page: number;
        pageSize: number;
        rows: Notification[];
        rowCount: number;
    }>({
        page: 0,
        pageSize: 20,
        rows: [],
        rowCount: 0,
    });

    const { sdkSystem } = useApi();

    useEffect(() => {
        const getNotifications = async () => {
            const notificationsPage = await sdkSystem.allNotifications({
                _page: dataGridState.page + 1,
                _per_page: dataGridState.pageSize,
            });
            const userIds = groupBy(
                notificationsPage.items.filter(
                    (notification) => notification.to_user_id !== null
                ),
                "to_user_id"
            );
            const users_ = await asyncMap(
                Object.keys(userIds),
                async (id) => {
                    return await sdkSystem.getUser({ id: parseInt(id) });
                },
                Number.POSITIVE_INFINITY
            );
            const users = keyBy(users_, "id");
            for (const notification of notificationsPage.items) {
                if (notification.to_user_id) {
                    notification.to_email_address =
                        users[notification.to_user_id].email;
                }
            }

            setDataGridState((draft) => {
                draft.rows = notificationsPage.items;
                draft.rowCount = notificationsPage.total_items;
            });
        };
        getNotifications();
    }, [
        sdkSystem,
        dataGridState.page,
        dataGridState.pageSize,
        setDataGridState,
    ]);

    return (
        <MainPane title="Notifications">
            <Box
                sx={{ width: "100%" }}
                css={css`
                    & p {
                        margin-bottom: 0px !important;
                    }
                `}
            >
                <DataGrid
                    className="styled-select-menu"
                    style={{
                        fontFamily: "Red Hat Display",
                    }}
                    {...dataGridState}
                    initialState={{
                        pagination: { paginationModel: { pageSize: 20 } },
                    }}
                    pagination
                    paginationMode="server"
                    columns={columns}
                    pageSizeOptions={pageSizeOptions}
                    onPaginationModelChange={({ page, pageSize }) => {
                        setDataGridState((draft) => {
                            draft.page = page;
                            draft.pageSize = pageSize;
                        });
                    }}
                />
            </Box>
        </MainPane>
    );
};

const NotificationsExtraPane = () => (
    <ExtraPane>
        <ul className="list-icon b">
            <li>
                <i className="icon-envelope-refresh" /> Notifications{" "}
                <span>Browse all the notifications sent by Joshu.</span>
            </li>
        </ul>
    </ExtraPane>
);

const NotificationsPage: FC = () => (
    <Page>
        <Main />
        <NotificationsExtraPane />
    </Page>
);

export default NotificationsPage;
