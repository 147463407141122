import { FC, useCallback, useEffect } from "react";
import { ProductIntegrationV1 } from "@joshuins/builder";
import { Integration } from "@joshuins/system";
import { AlertCategory, usePage } from "components/Page";
import { usePopup } from "contexts/PopupProvider";
import { useIntegrations } from "../IntegrationsProvider";
import cloneDeep from "lodash/cloneDeep";
import { ConfirmationPopup } from "components/Popup";

const NoConfigurationPopup: FC = () => {
    const { addAlertMessages } = usePage();
    const { popupData, isPopupOpen } = usePopup();
    const {
        accessors: { allIntegrationTypes },
        mutators: { addProductIntegration, updateProductIntegration },
    } = useIntegrations();

    const integration = popupData?.integration as Integration | undefined;
    const productIntegration = popupData?.productIntegration as
        | ProductIntegrationV1
        | undefined;
    const integrationTypes = allIntegrationTypes();
    const integrationType =
        integration && integrationTypes
            ? integrationTypes[integration.type_]
            : undefined;
    const is_published = popupData?.is_published as boolean | undefined;

    useEffect(() => {
        const getProductIntegration = async () => {
            if (!productIntegration) {
                return;
            }
        };
        getProductIntegration();
    }, [productIntegration, isPopupOpen]);

    const onSubmit = useCallback(async () => {
        if (!integration || !integrationType) {
            return;
        }
        if (productIntegration) {
            const updatedProductIntegration = cloneDeep(productIntegration);
            await updateProductIntegration(
                productIntegration.integration_id,
                updatedProductIntegration
            );
            addAlertMessages({
                message: `${integrationType.display_name} integration was updated succesfully`,
                category: AlertCategory.SUCCESS,
            });
        } else {
            const newProductIntegration: ProductIntegrationV1 = {
                enabled: true,
                integration_id: integration.id,
                config: {},
            };
            await addProductIntegration(newProductIntegration);
            addAlertMessages({
                message: `${integrationType.display_name} integration was created succesfully`,
                category: AlertCategory.SUCCESS,
            });
        }
    }, [
        addAlertMessages,
        addProductIntegration,
        integration,
        integrationType,
        productIntegration,
        updateProductIntegration,
    ]);

    if (!integrationType) {
        return <></>;
    }

    return (
        <ConfirmationPopup
            name="trade-gov-ofac-setup-form"
            submitText="Save"
            onSubmit={onSubmit}
            disabled={is_published}
        >
            <header>
                <h2>{integrationType.display_name}</h2>
            </header>
            <p>This integration does not require any configuration</p>
        </ConfirmationPopup>
    );
};

export default NoConfigurationPopup;
