import { FC, useCallback, useEffect } from "react";
import axios from "axios";
import pick from "lodash/pick";
import { z } from "zod";
import { createFormPopup } from "components/Popup";
import { AlertCategory, usePage } from "components/Page";
import { useApi } from "contexts/ApiProvider";
import { usePopup } from "contexts/PopupProvider";
import { getMessageFromAxiosError } from "utils/axios-extras";

const AddOrEditBrokerageSchema = z.object({
    name: z.string().min(1, "This field is required"),
    domain: z.string(),
    commission: z
        .string()
        .regex(/^(?:[1-9]\d*|0)?(?:\.\d+)?$/, "Must be a valid decimal number")
        .refine(
            (val) => {
                const floatVal = parseFloat(val);
                return floatVal >= 0 && floatVal <= 50;
            },
            {
                message: "Value should be between 0 and 50",
            }
        ),
});

type AddOrEditBrokerageType = z.infer<typeof AddOrEditBrokerageSchema>;

interface BrokerageDataInterface {
    brokerageId?: number;
}

const {
    FormPopup: AddOrEditBrokerageFormPopup,
    useFormReturnRef: useAddOrEditBrokerageFormReturnRef,
} = createFormPopup(AddOrEditBrokerageSchema);

const AddOrEditBrokeragePopup: FC = () => {
    const { sdkSystem } = useApi();
    const { addAlertMessages, refreshState } = usePage();
    const { popupData, isPopupOpen } = usePopup();
    const {
        formReturn: { reset },
        formReturnRefCallback,
    } = useAddOrEditBrokerageFormReturnRef();

    const popupData_ = popupData as unknown as BrokerageDataInterface;

    useEffect(() => {
        const getBrokerage = async () => {
            if (
                !isPopupOpen("add-or-edit-brokerage") ||
                !popupData_?.brokerageId ||
                !reset
            ) {
                return;
            }
            const brokerage = await sdkSystem.getBrokerage({
                id: popupData_.brokerageId,
            });
            reset({
                ...pick(brokerage, ["name", "domain"]),
                commission:
                    brokerage.commission === null ? "" : brokerage.commission,
            });
        };
        getBrokerage();
    }, [sdkSystem, popupData_, isPopupOpen, reset]);

    const onSubmit = useCallback(
        async (data: AddOrEditBrokerageType) => {
            // the if is for inviting a new brokerage
            if (!popupData_?.brokerageId) {
                try {
                    const brokerage = await sdkSystem.createBrokerage({
                        NewBrokerage: data,
                    });

                    addAlertMessages({
                        message: `${brokerage.name} has been added.`,
                        category: AlertCategory.SUCCESS,
                    });
                } catch (error) {
                    if (axios.isAxiosError(error)) {
                        addAlertMessages({
                            message: getMessageFromAxiosError(error),
                            category: AlertCategory.ALERT,
                        });
                    } else {
                        throw error;
                    }
                } finally {
                    refreshState();
                }
            } else {
                // the else is for updating a brokerage
                try {
                    await sdkSystem.updateBrokerage({
                        id: popupData_.brokerageId,
                        UpdateBrokerage: data,
                    });
                    addAlertMessages({
                        message: `${data.name} has been updated`,
                        category: AlertCategory.SUCCESS,
                    });
                } catch (error) {
                    if (axios.isAxiosError(error)) {
                        addAlertMessages({
                            message: getMessageFromAxiosError(error),
                            category: AlertCategory.ALERT,
                        });
                    } else {
                        throw error;
                    }
                } finally {
                    refreshState();
                }
            }
        },
        [popupData_, sdkSystem, addAlertMessages, refreshState]
    );

    return (
        <AddOrEditBrokerageFormPopup
            name="add-or-edit-brokerage"
            defaultValues={{
                name: "",
                domain: "",
                commission: "",
            }}
            onSubmit={onSubmit}
            submitText="Save"
            mobileSubmitText="Save"
            formReturnRefCallback={formReturnRefCallback}
        >
            {({ register, formState: { errors } }) => (
                <>
                    <header>
                        <h2>Brokerage</h2>
                    </header>
                    <p className="c50">
                        <label htmlFor="ppb">Brokerage Name</label>
                        <input
                            type="text"
                            id="ppb"
                            {...register("name")}
                            disabled={false}
                        />
                        {errors.name && (
                            <label
                                id="ppb-error"
                                className="error"
                                htmlFor="ppb"
                            >
                                {errors.name.message}
                            </label>
                        )}
                    </p>
                    <p className="c50">
                        <label htmlFor="ppc">Email Domain</label>
                        <input
                            type="text"
                            id="ppc"
                            {...register("domain")}
                            disabled={popupData_?.brokerageId ? true : false}
                        />
                        {errors.domain && (
                            <label
                                id="ppc-error"
                                className="error"
                                htmlFor="ppc"
                            >
                                {errors.domain.message}
                            </label>
                        )}
                    </p>
                    <p>
                        <label htmlFor="ppd">Commission</label>
                        <input
                            type="text"
                            id="ppd"
                            {...register("commission")}
                            disabled={false}
                        />
                        {errors.commission && (
                            <label
                                id="ppd-error"
                                className="error"
                                htmlFor="ppd"
                            >
                                {errors.commission.message}
                            </label>
                        )}
                    </p>
                </>
            )}
        </AddOrEditBrokerageFormPopup>
    );
};

export default AddOrEditBrokeragePopup;
