import { AlertCategory, Page, usePage } from "components/Page";
import { FC, useEffect, useMemo, useState } from "react";
import { Footer } from "components/MainPane";
import ChecklistExtraPane from "components/extra-panes/ChecklistExtraPane";
import { Link, useNavigate } from "components/DevAwareRoutingLink";
import NotAnchor from "components/NotAnchor";
import { Popup } from "components/Popup";
import { usePopup } from "contexts/PopupProvider";
import { useApi } from "contexts/ApiProvider";
import classNames from "classnames";
import MenuPopover from "components/MenuPopover";
import urlJoin from "url-join";
import { BUILDER_PATH } from "globals";
import { asyncMap } from "modern-async";
import type {
    ProductVersion,
    ProductVersionView,
    Store,
} from "@joshuins/builder";
import type { User } from "@joshuins/system";
import type { MenuItem as MenuPopoverMenuItem } from "components/MenuPopover";
import {
    productVersionStatusColor,
    productVersionStatusText,
} from "./components/util";
import useNavigateToProductVersion from "./components/use-navigate-to-product-version";
import keyBy from "lodash/keyBy";
import { unpaginate } from "components/sdk";
import ProductMainPane from "./components/ProductMainPane";
import { fullName } from "utils/user";
import { hideLoader, showLoader } from "paul/native-dom-manipulation";
import FileDownload, { useFileDownload } from "components/FileDownload";
import {
    getApplicationDatapoints,
    getBindQuestionDatapoints,
} from "utils/product-version";
import {
    IssuesWarningPopup,
    ISSUES_WARNING,
} from "./components/IssuesWarningPopup";
import ArchiveDraftOrRepublishProductVersionPopup, {
    ARCHIVE_DRAFT_VERSION,
} from "./components/ArchiveDraftOrRepublishProductVersionPopup";
import NewAndUpdateUnderwriterPopup, {
    ADD_OR_EDIT_UW,
} from "./components/AddOrEditUnderwriterPopup";
import AddToStorePopup, { ADD_TO_STORE } from "./components/AddToStorePopup";
import RemoveStoreFromProductPopup, {
    REMOVE_STORE_PRODUCT,
} from "./components/RemoveStoreFromProductPopup";
import {
    PublishProductVersionConfirmationPopup,
    PublishProductVersionPopup,
} from "./components/PublishPoductVersionPopup";
import {
    NEW_VERSION_FIRST_STEP,
    NewProductVersionConfirmationPopup,
    NewProductVersionFirstStepPopup,
    NewProductVersionOfCurrentPopup,
    NewProductVersionOfNewProductPopup,
} from "./components/NewProductVersionPopups";

const HelpOverlay = () => {
    const { closePopup } = usePopup();
    const { sdkBuilder } = useApi();

    const onClick = async () => {
        await sdkBuilder.createUserChecklist({
            tag: "EnteredProductPage",
        });
        closePopup("help-overlay");
    };

    return (
        <Popup name="help-overlay" black={false} fullscreen>
            <h2>
                <i aria-hidden="true" className="icon-forward" /> Checklist
                Guides
            </h2>
            <p>
                New here? Follow the steps in the right panel to get up to
                speed.
            </p>
            <p className="link-btn last-child">
                <NotAnchor onClick={onClick}>Ok</NotAnchor>
            </p>
        </Popup>
    );
};

interface ProductVersionSummary {
    numDatapointsForAnswers: number;
    numDocuments: number;
    numErrorDocumentTemplates: number;
    numProcessingDocumentTemplates: number;
    numEnabledProductIntegrations: number;
    numDatapointsInSubjectivitiesSections: number;
    numDatapointsInBindQuestionSections: number;
}

const UnderwritersPopup = () => (
    <Popup name="underwriters-help">
        <h2 className="m25 color-primary">Underwriters</h2>
        <p>
            You can assign underwriters to individual products, even across
            multiple stores. When a quote for this product requires review, the
            appropriate underwriter will be notified.
        </p>
        <p>
            Note: the admin who created the product is added by default so they
            can test the product and invite others.
        </p>
    </Popup>
);

const StoresPopup = () => (
    <Popup name="stores-help">
        <h2 className="m25 color-primary">Stores</h2>
        <p>
            Joshu stores are the channels that you use to sell your digital
            insurance products. Once a store is set up, invite brokers to come
            create an account and start generating quotes.
        </p>
        <p>
            You can have one main store with all products, or create different
            stores for different channels. Each store can even have its own
            theme!
        </p>
        <p>
            Only published products can be added to stores. If the &quot;Add to
            Store&quot; button is disabled, your product has not been published.
        </p>
    </Popup>
);

const Main = () => {
    const {
        element,
        stateId,
        addAlertMessages,
        refreshState,
        tryCatchAndRaiseError,
    } = usePage();
    const productVersion = element
        ? (element as unknown as ProductVersion)
        : undefined;
    const [productVersions, setProductVersions] =
        useState<ProductVersionView[]>();
    const [users, setUsers] = useState<User[]>();
    const [stores, setStores] = useState<Store[]>();
    const [productVersionSummary, setProductVersionSummary] =
        useState<ProductVersionSummary>();

    const [hasChildProductVersions, setHasChildProductVersions] =
        useState<boolean>();

    const { sdkBuilder, sdkSystem } = useApi();
    const navigate = useNavigate();
    const { openPopup } = usePopup();
    const navigateToProductVersion = useNavigateToProductVersion();
    const [productVersionCanBePublished, setProductVersionCanBePublished] =
        useState<boolean>();
    const groupedProductVersions = useMemo(
        () => keyBy(productVersions, "id"),
        [productVersions]
    );

    const getExportFileId = async () => {
        if (!productVersion) {
            return [];
        }
        const productExport = await sdkBuilder.createProductExport({
            CreateProductExport: {
                Export: { product_version_id: productVersion.id },
            },
        });

        showLoader("Exporting...");
        const [productExportPromise] = sdkBuilder.getProductExportWhenReady(
            productExport?.id
        );
        const newProductExport = await productExportPromise;
        hideLoader();

        if (!newProductExport || !newProductExport.file_id) {
            return [];
        }
        return newProductExport.file_id ? [newProductExport.file_id] : [];
    };
    const downloadFile = useFileDownload(getExportFileId);

    useEffect(() => {
        const getProductVersionCanBePublished = async () => {
            setProductVersionCanBePublished(
                productVersion
                    ? await sdkBuilder.canProductVersionBePublished(
                          productVersion
                      )
                    : false
            );
        };
        getProductVersionCanBePublished();
    }, [productVersion, sdkBuilder]);

    useEffect(() => {
        const getHasChildProductVersions = async () => {
            if (!productVersion) {
                return;
            }
            const numChildProductVersions = (
                await sdkBuilder.allProductVersions({
                    originating_version_id: productVersion.id,
                    _per_page: 1,
                })
            ).total_items;
            setHasChildProductVersions(numChildProductVersions > 0);
        };
        getHasChildProductVersions();
    }, [sdkBuilder, productVersion, stateId]);

    useEffect(() => {
        const getProductVersions = async () => {
            if (!productVersion) {
                return;
            }
            const productVersions =
                await sdkBuilder.getProductVersionsOfProduct(
                    productVersion.product_id
                );
            setProductVersions(productVersions);
        };
        getProductVersions();
    }, [sdkBuilder, productVersion, stateId]);

    useEffect(() => {
        const getProductUsers = async () => {
            if (!productVersion) {
                return;
            }
            const productUsers = await unpaginate(sdkBuilder.allProductUsers, {
                product_id: productVersion.product_id,
            });
            const users_ = await asyncMap(
                productUsers,
                async (productUser) =>
                    await sdkSystem.getUser({ id: productUser.user_id }),
                Number.POSITIVE_INFINITY
            );
            setUsers(users_);
        };
        getProductUsers();
    }, [sdkBuilder, productVersion, stateId, sdkSystem]);

    useEffect(() => {
        const getStoreProducts = async () => {
            if (!productVersion) {
                return;
            }

            const storeProducts = await unpaginate(
                sdkBuilder.allStoreProducts,
                {
                    product_id: productVersion.product_id,
                }
            );

            const stores_ = await asyncMap(
                storeProducts,
                async (storeProduct) =>
                    await sdkBuilder.getStore({ id: storeProduct.store_id }),
                Number.POSITIVE_INFINITY
            );
            setStores(stores_);
        };
        getStoreProducts();
    }, [sdkBuilder, productVersion, stateId]);

    useEffect(() => {
        const getProductVersionSummary = async () => {
            if (!productVersion) {
                return;
            }
            const numEnabledProductIntegrations =
                productVersion.schema.spec.integrations.filter(
                    (productIntegration) => productIntegration.enabled
                ).length;
            const numDatapointsForAnswers =
                getApplicationDatapoints(productVersion).length;
            const numDatapointsInSubjectivitiesSections =
                productVersion.schema.spec.subjectivities.length;
            const numDatapointsInBindQuestionSections =
                getBindQuestionDatapoints(productVersion).length;

            let numDocuments = 0;
            for (const packet of Object.values(
                productVersion.schema.spec.documents.packets
            )) {
                numDocuments += packet.documents.length;
            }
            const numErrorDocumentTemplates = 0; // @TODO: replace with errors in documents schema!
            const numProcessingDocumentTemplates = 0; // @TODO: replace with errors in documents schema!
            setProductVersionSummary({
                numDatapointsForAnswers,
                numDocuments,
                numErrorDocumentTemplates,
                numProcessingDocumentTemplates,
                numEnabledProductIntegrations,
                numDatapointsInSubjectivitiesSections,
                numDatapointsInBindQuestionSections,
            });
        };
        getProductVersionSummary();
    }, [sdkBuilder, productVersion, stateId]);

    useEffect(() => {
        const doOpenHelpOverlay = async () => {
            const enteredProductPage = (
                await sdkBuilder.allUserChecklists()
            ).filter(
                (userChecklist) => userChecklist.tag === "EnteredProductPage"
            );
            if (enteredProductPage.length === 0) {
                openPopup("help-overlay");
            }
        };
        doOpenHelpOverlay();
    }, [openPopup, sdkBuilder]);

    const removeUnderwriter = async (user: User) => {
        if (!productVersion) {
            return;
        }
        tryCatchAndRaiseError(async () => {
            await sdkBuilder.deleteProductUser({
                product_id: productVersion.product_id,
                user_id: user.id,
            });
            addAlertMessages({
                message: `${fullName(
                    user
                )} was successfully removed from being an underwriter for ${
                    productVersion.schema.metadata.name
                } product`,
                category: AlertCategory.SUCCESS,
            });
        }, refreshState);
    };

    const links = useMemo(() => {
        if (productVersionSummary === undefined) {
            return [];
        }
        return [
            {
                subpath: "application",
                icon: "doc-edit",
                label: "Application",
                subtext: `${productVersionSummary.numDatapointsForAnswers} Questions`,
                lockedOnPublished: true,
            },
            {
                subpath: "rater",
                icon: "rating",
                label: "Rater",
                subtext: productVersion
                    ? `${
                          !productVersion.schema.spec.rater ? "Not " : ""
                      }Connected`
                    : "",
                lockedOnPublished: true,
            },
            {
                subpath: "documents",
                icon: "documents",
                label: "Documents",
                subtext: `${productVersionSummary.numDocuments} Documents${
                    productVersionSummary.numErrorDocumentTemplates > 0
                        ? " (Error)"
                        : productVersionSummary.numProcessingDocumentTemplates >
                            0
                          ? "(Processing)"
                          : ""
                }`,
                lockedOnPublished: true,
            },
            {
                subpath: "integrations",
                icon: "integrate",
                label: "Integrations",
                subtext: `${productVersionSummary.numEnabledProductIntegrations} Integrations`,
                lockedOnPublished: false,
            },
            {
                subpath: "bind-requirements/subjectivities",
                icon: "check-circle-outline",
                label: "Bind Requirements",
                subtext: `${productVersionSummary.numDatapointsInSubjectivitiesSections} Subjectivities / ${productVersionSummary.numDatapointsInBindQuestionSections} Questions`,
                lockedOnPublished: true,
            },
            {
                subpath: "settings",
                icon: "cog",
                label: "Settings",
                subtext: "Default",
                lockedOnPublished: true,
            },
        ];
    }, [productVersionSummary, productVersion]);

    if (
        !productVersion ||
        productVersions === undefined ||
        productVersionSummary === undefined
    ) {
        return <></>;
    }

    const menuItems: MenuPopoverMenuItem[] = [];
    if (productVersion.is_archived) {
        if (!hasChildProductVersions) {
            menuItems.push({
                key: "move-to",
                label: `Move to ${
                    productVersion.is_published ? "Published" : "Drafts"
                }`,
                icon: "chevron-left",
                iconStyle: {
                    fontSize: "10px",
                },
                onClick: () => {
                    openPopup(ARCHIVE_DRAFT_VERSION, {
                        stores,
                    });
                },
            });
        }
    } else {
        menuItems.push({
            key: "archive",
            label: "Archive",
            icon: "box",
            onClick: () => {
                openPopup(ARCHIVE_DRAFT_VERSION, {
                    stores,
                });
            },
        });
    }

    const publishProduct = async () => {
        const validatedProductVersion = await sdkBuilder.updateProductVersion({
            id: productVersion.id,
            UpdateProductVersion: {
                schema: productVersion.schema,
            },
        });
        validatedProductVersion.issues &&
        validatedProductVersion.issues.length > 0
            ? openPopup(ISSUES_WARNING)
            : openPopup("publish-product-version");
    };

    if (!productVersion.is_published) {
        menuItems.push({
            key: "new-version",
            label: "New Version",
            icon: "copy",
            onClick: () => {
                openPopup(NEW_VERSION_FIRST_STEP);
            },
        });
    }

    menuItems.push({
        key: "export",
        label: "Export",
        icon: "download-cloud",
        onClick: () => {
            downloadFile();
        },
    });

    return (
        <ProductMainPane
            title={productVersion?.schema.metadata.name || ""}
            layoutConfig={{
                headerPosition: "inside-content",
            }}
            headerSelect={{
                label: "Version",
                selectedValue: productVersion.id,
                options: productVersions.map((productVersion) => ({
                    value: productVersion.id,
                    text:
                        productVersion.internal_version_name ||
                        "(no version name)",
                    extra: (
                        <span className="cols">
                            <span
                                className={`scheme-box color-${productVersionStatusColor(
                                    productVersion
                                )}`}
                            >
                                {productVersionStatusText(productVersion)}
                            </span>
                            {/* {productVersion.is_published &&
                                !productVersion.is_archived &&
                                productVersion.effective_date && (
                                    <span className="color-black-60">
                                        Effective{" "}
                                        {formatDatetimeString(
                                            productVersion.effective_date,
                                            "numericDateOnlyShortYear"
                                        )}
                                    </span>
                                )} */}
                        </span>
                    ),
                })),
                onChange: (event) => {
                    navigateToProductVersion(
                        groupedProductVersions[
                            parseInt(event.target.value.toString())
                        ]
                    );
                },
            }}
            subHeader={{
                itemsBefore: productVersion && [
                    productVersion.schema.metadata.internal_version_name ||
                        null,
                    productVersion.schema.metadata.product_type || null,
                ],
            }}
        >
            <ul className="list-products compact">
                {productVersion &&
                    links.map(
                        ({
                            subpath,
                            icon,
                            label,
                            subtext,
                            lockedOnPublished,
                        }) => (
                            <li key={subpath}>
                                <Link
                                    to={urlJoin(
                                        "/",
                                        BUILDER_PATH,
                                        "products",
                                        productVersion.id.toString(),
                                        subpath
                                    )}
                                >
                                    <i
                                        aria-hidden="true"
                                        className={`icon-${icon}`}
                                    />{" "}
                                    {label} <span>{subtext}</span>
                                    <i
                                        aria-hidden="true"
                                        className={classNames({
                                            "icon-lock text-right":
                                                lockedOnPublished &&
                                                productVersion.is_published,
                                        })}
                                    />
                                </Link>
                            </li>
                        )
                    )}
            </ul>
            <div className="double a b16">
                <div>
                    <h3 className="font-medium">
                        Underwriters{" "}
                        <NotAnchor
                            onClick={() => {
                                openPopup("underwriters-help");
                            }}
                            className="text-right"
                        >
                            <i aria-hidden="true" className="icon-help" />{" "}
                            <span className="hidden">Read more</span>
                        </NotAnchor>
                    </h3>
                    <ul className="list-box">
                        {users?.map((user, index) => {
                            const { id, first_name, last_name } = user;
                            return (
                                <MenuPopover
                                    key={id}
                                    menuItems={[
                                        {
                                            key: "remove-underwriter",
                                            label: "Remove Underwriter",
                                            icon: "x-circle",
                                            onClick: () => {
                                                removeUnderwriter(user);
                                            },
                                        },
                                    ]}
                                    style={{
                                        // this 3 is here because the "link-strong" class used below has a z-index of 2. Without this 3, that link overlaps the menu.
                                        // PAUL!!!!!
                                        zIndex: 3 + users.length - index,
                                    }}
                                >
                                    {({ ToggleButton, Menu }) => (
                                        <>
                                            <NotAnchor
                                                onClick={() => {
                                                    openPopup(ADD_OR_EDIT_UW, {
                                                        userId: id,
                                                    });
                                                }}
                                            >
                                                {first_name} {last_name}
                                            </NotAnchor>
                                            {Menu}
                                            {ToggleButton}
                                        </>
                                    )}
                                </MenuPopover>
                            );
                        })}
                    </ul>
                    <p className="link-strong">
                        <NotAnchor
                            onClick={() => {
                                openPopup(ADD_OR_EDIT_UW);
                            }}
                        >
                            <i
                                aria-hidden="true"
                                className="icon-plus-circle"
                            />{" "}
                            Add Underwriter
                        </NotAnchor>
                    </p>
                </div>
                <div>
                    <h3 className="font-medium">
                        Stores{" "}
                        <NotAnchor
                            className="text-right"
                            onClick={() => {
                                openPopup("stores-help");
                            }}
                        >
                            <i aria-hidden="true" className="icon-help" />{" "}
                            <span className="hidden">Read more</span>
                        </NotAnchor>
                    </h3>
                    <ul className="list-box">
                        {stores && stores.length > 0 ? (
                            stores.map(({ id, name }, index) => (
                                <MenuPopover
                                    key={id}
                                    menuItems={[
                                        {
                                            key: "remove-from-store",
                                            label: "Remove from Store",
                                            icon: "x-circle",
                                            onClick: () => {
                                                openPopup(
                                                    REMOVE_STORE_PRODUCT,
                                                    {
                                                        storeId: id,
                                                    }
                                                );
                                            },
                                        },
                                        {
                                            key: "edit-store",
                                            label: "Edit Store",
                                            icon: "pencil",
                                            onClick: () => {
                                                navigate(
                                                    urlJoin(
                                                        "/",
                                                        BUILDER_PATH,
                                                        "stores",
                                                        id.toString(),
                                                        "settings"
                                                    )
                                                );
                                            },
                                        },
                                    ]}
                                    style={{
                                        // this 3 is here because the "link-strong" class used below has a z-index of 2. Without this 3, that link overlaps the menu.
                                        // PAUL!!!!!
                                        zIndex: 3 + stores.length - index,
                                    }}
                                >
                                    {({ ToggleButton, Menu }) => (
                                        <>
                                            <Link
                                                to={urlJoin(
                                                    "/",
                                                    BUILDER_PATH,
                                                    "stores",
                                                    id.toString()
                                                )}
                                            >
                                                {name}
                                            </Link>
                                            {Menu}
                                            {ToggleButton}
                                        </>
                                    )}
                                </MenuPopover>
                            ))
                        ) : (
                            <li className="inbox-text">
                                {productVersion.is_published &&
                                !productVersion.is_archived
                                    ? "Not added to any stores yet"
                                    : productVersionCanBePublished &&
                                        !productVersion.is_archived
                                      ? "Add draft to a store to test"
                                      : "See Help for requirements to enable Stores"}
                            </li>
                        )}
                    </ul>
                    <p className="link-strong">
                        <NotAnchor
                            className={classNames({
                                disabled:
                                    productVersion.is_archived ||
                                    (!productVersion.is_published &&
                                        !productVersionCanBePublished),
                            })}
                            onClick={() => {
                                openPopup(ADD_TO_STORE);
                            }}
                            data-popup={ADD_TO_STORE}
                        >
                            <i className="icon-plus-circle" /> Add to Store
                        </NotAnchor>
                    </p>
                </div>
            </div>
            <MenuPopover component={Footer} menuItems={menuItems}>
                {({ ToggleButton, Menu }) => (
                    <>
                        <p className="link-btn has-inline">
                            <>
                                {productVersion.is_archived ? (
                                    !hasChildProductVersions && (
                                        <NotAnchor
                                            className="inline"
                                            onClick={() => {
                                                openPopup(
                                                    ARCHIVE_DRAFT_VERSION,
                                                    {
                                                        stores,
                                                    }
                                                );
                                            }}
                                        >
                                            <i
                                                className="icon-chevron-left"
                                                style={{
                                                    fontSize: "10px",
                                                    top: "-0.05em",
                                                }}
                                            />
                                            Move to{" "}
                                            {productVersion.is_published
                                                ? "Published"
                                                : "Drafts"}
                                        </NotAnchor>
                                    )
                                ) : (
                                    <NotAnchor
                                        className="inline"
                                        onClick={() => {
                                            openPopup(ARCHIVE_DRAFT_VERSION, {
                                                stores,
                                            });
                                        }}
                                    >
                                        <i className="icon-box" />
                                        Archive
                                    </NotAnchor>
                                )}
                                {!productVersion.is_published && (
                                    <>
                                        <NotAnchor
                                            className="inline"
                                            onClick={() => {
                                                openPopup(
                                                    NEW_VERSION_FIRST_STEP
                                                );
                                            }}
                                        >
                                            <i className="icon-copy" /> New
                                            Version
                                        </NotAnchor>
                                    </>
                                )}
                            </>
                            <FileDownload
                                className="inline"
                                fileIds={getExportFileId}
                            >
                                <i className="icon-download-cloud" /> Export
                            </FileDownload>
                        </p>
                        <p className="link-btn">
                            {productVersion &&
                                (productVersion.is_published ? (
                                    <NotAnchor
                                        onClick={() => {
                                            openPopup(NEW_VERSION_FIRST_STEP);
                                        }}
                                    >
                                        <i className="icon-copy" /> New Version
                                    </NotAnchor>
                                ) : (
                                    <NotAnchor
                                        className={classNames({
                                            disabled:
                                                !productVersionCanBePublished,
                                        })}
                                        onClick={publishProduct}
                                    >
                                        <i
                                            aria-hidden="true"
                                            className="icon-check-circle-outline"
                                        />{" "}
                                        Publish
                                    </NotAnchor>
                                ))}
                        </p>
                        {ToggleButton}
                        {Menu}
                    </>
                )}
            </MenuPopover>
        </ProductMainPane>
    );
};

const ProductVersionCheckList = () => {
    const { element } = usePage();
    const productVersion = element as unknown as ProductVersion | undefined;

    if (!productVersion) {
        return <></>;
    }

    return (
        <ChecklistExtraPane
            associatedId={productVersion.id}
            checklistType="product"
            title="Product Setup Checklist"
            description={
                "Follow these steps to configure your product. " +
                'Click "Help Center" for videos and articles to learn more. ' +
                "When you are finished, check the step as complete."
            }
            tags={[
                {
                    tag: "CreateApplication",
                    title: "Create Application",
                    url: "https://help.joshu.insure/en/articles/6396039-product-create-application",
                },
                {
                    tag: "DefineRater",
                    title: "Define Rater",
                    url: "https://help.joshu.insure/en/articles/6396045-product-define-rater",
                },
                {
                    tag: "FormatDocuments",
                    title: "Format Documents",
                    url: "https://help.joshu.insure/en/articles/6396049-product-format-documents",
                },
                {
                    tag: "AddBindRequirements",
                    title: "Add Bind Requirements",
                    url: "https://help.joshu.insure/en/articles/6396052-product-add-bind-requirements",
                },
                {
                    tag: "AddUnderwriters",
                    title: "Add Underwriters",
                    url: "https://help.joshu.insure/en/articles/6396053-product-add-underwriters",
                },
                {
                    tag: "AddDraftToStore",
                    title: "Add Draft to Store",
                    url: "https://help.joshu.insure/en/articles/6396054-product-add-draft-to-store",
                },
                {
                    tag: "PublishProduct",
                    title: "Publish Product",
                    url: "https://help.joshu.insure/en/articles/6396055-product-publish-product",
                },
            ]}
        />
    );
};

const HomePage: FC = () => (
    <Page>
        <Main />
        <ProductVersionCheckList />
        <UnderwritersPopup />
        <StoresPopup />
        <NewAndUpdateUnderwriterPopup />
        <AddToStorePopup />
        <RemoveStoreFromProductPopup />
        <ArchiveDraftOrRepublishProductVersionPopup />
        <NewProductVersionFirstStepPopup />
        <NewProductVersionOfCurrentPopup />
        <NewProductVersionOfNewProductPopup />
        <NewProductVersionConfirmationPopup />
        <PublishProductVersionPopup />
        <PublishProductVersionConfirmationPopup />
        <IssuesWarningPopup />
        <HelpOverlay />
    </Page>
);

export default HomePage;
