import classNames from "classnames";
import NotAnchor from "components/NotAnchor";
import { usePopup } from "contexts/PopupProvider";
import { FC, useCallback } from "react";
import { useInsuranceProcess } from "../InsuranceProcessProvider";
import { useApi } from "contexts/ApiProvider";
import { usePage } from "components/Page";
import { Quote, QuoteStatus } from "@joshuins/insurance";
import { ConfirmationPopup } from "components/Popup";
import { waitForProcessingInsuranceObject } from "../../util";
import { unpaginateWithMultiParams } from "components/sdk";

const PublishEndorsementQuoteLink: FC = () => {
    const { openPopup } = usePopup();

    return (
        <NotAnchor
            onClick={() => {
                openPopup("publish-quote");
            }}
            className="inline"
            aria-controls="publish-quote"
            aria-haspopup="true"
        >
            <i aria-hidden="true" className="icon-check-circle-outline" />
            Send For Review
        </NotAnchor>
    );
};

const PublishQuoteLink: FC = () => {
    const { openPopup } = usePopup();
    const {
        insuranceProcessState: { dirtyFields: dirtyFields },
    } = useInsuranceProcess();

    return (
        <NotAnchor
            onClick={() => {
                openPopup("publish-quote");
            }}
            className={classNames({
                hidden: dirtyFields.length !== 0,
            })}
            aria-controls="publish-quote"
            aria-haspopup="true"
        >
            Publish <span className="mobile-hide">Quote </span>
            <span className="scheme-accesskey">
                <span>Shift</span> <span>q</span>
            </span>
        </NotAnchor>
    );
};

const PublishQuotePopup: FC = () => {
    const { sdkInsurance } = useApi();
    const { tryCatchAndRaiseError } = usePage();
    const {
        itemGetters: { getInsuranceProcess },
        insuranceProcessDispatch,
    } = useInsuranceProcess();
    const { quote } = getInsuranceProcess();

    const onSubmit = useCallback(async () => {
        if (!quote) {
            return;
        }

        tryCatchAndRaiseError(async () => {
            await sdkInsurance.updateQuote({
                id: quote.id,
                UpdateQuote: { status: QuoteStatus.QuotePublished },
            });

            const updatedQuote = (await waitForProcessingInsuranceObject(
                sdkInsurance,
                quote,
                "Publishing Quote..."
            )) as Quote;

            if (updatedQuote) {
                insuranceProcessDispatch({
                    action: "SetQuote",
                    quote: updatedQuote,
                });
                const newDocuments = await unpaginateWithMultiParams(
                    sdkInsurance.allDocuments,
                    {
                        quote_id: updatedQuote.id,
                    }
                );
                insuranceProcessDispatch({
                    action: "SetDocuments",
                    documents: newDocuments,
                });
            }
        });
    }, [insuranceProcessDispatch, quote, sdkInsurance, tryCatchAndRaiseError]);

    return (
        <ConfirmationPopup
            name="publish-quote"
            onSubmit={onSubmit}
            submitText="Publish Quote"
        >
            <header>
                <h2>Are you sure?</h2>
                <p className="size-14">
                    This quote will be published. This action cannot be undone.
                </p>
            </header>
        </ConfirmationPopup>
    );
};

export { PublishQuoteLink, PublishEndorsementQuoteLink, PublishQuotePopup };
