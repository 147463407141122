import {
    createContext,
    Dispatch,
    FC,
    PropsWithChildren,
    useCallback,
    useContext,
} from "react";
import { useImmerReducer } from "use-immer";

import {
    ExportEncryptionKey,
    ExportRule,
    ExportTarget,
} from "@joshuins/system";

type DataExportAction =
    | {
          action: "SetTargets";
          targets: {
              target: ExportTarget;
              rules: ExportRule[];
          }[];
      }
    | { action: "AddTarget"; target: ExportTarget }
    | { action: "AddKey"; encryptionKey: ExportEncryptionKey }
    | { action: "UpdateTarget"; target: ExportTarget }
    | { action: "RemoveTarget"; targetId: string }
    | {
          action: "AddRule";
          targetId: string;
          rule: ExportRule;
      }
    | {
          action: "UpdateRule";
          targetId: string;
          rule: ExportRule;
      }
    | {
          action: "RemoveRule";
          targetId: string;
          rule: ExportRule;
      }
    | {
          action: "SetKeys";
          encryptionKeys: ExportEncryptionKey[];
      }
    | {
          action: "RemoveKey";
          keyId: string;
      };

interface DataExportState {
    targets:
        | {
              target: ExportTarget;
              rules: ExportRule[];
          }[]
        | undefined;
    encryptionKeys: ExportEncryptionKey[] | undefined;
}

interface DataExportProviderInterface {
    dataExportSate: DataExportState;
    dataExportDispatch: Dispatch<DataExportAction>;

    itemGetters: {
        allTargets: () =>
            | {
                  target: ExportTarget;
                  rules: ExportRule[];
              }[]
            | undefined;
        targetAndIndex: (targetId: string) =>
            | {
                  target: {
                      target: ExportTarget;
                      rules: ExportRule[];
                  };
                  index: number;
              }
            | { target: undefined; index: undefined };
        allRules: () => ExportRule[] | undefined;
        ruleAndIndex: (
            targetId: string,
            ruleId: string
        ) =>
            | { rule: ExportRule; index: number }
            | { rule: undefined; index: undefined };
        allKeys: () => ExportEncryptionKey[] | undefined;
    };
}

const DataExportContext = createContext<
    DataExportProviderInterface | undefined
>(undefined);

const useDataExport = () => {
    const context = useContext(DataExportContext);

    if (context === undefined) {
        throw Error(
            "useDataExport must be used inside a DataExportProvider context"
        );
    }

    return context;
};

const DataExportProvider: FC<PropsWithChildren> = ({ children }) => {
    const getTargets = (draft: DataExportState) => {
        if (draft.targets === undefined) {
            draft.targets = [];
        }
        return draft.targets;
    };

    const getEncryptionKeys = (draft: DataExportState) => {
        if (draft.encryptionKeys === undefined) {
            draft.encryptionKeys = [];
        }
        return draft.encryptionKeys;
    };

    const reducer = (draft: DataExportState, action: DataExportAction) => {
        switch (action.action) {
            case "SetTargets": {
                draft.targets = action.targets;
                break;
            }
            case "AddTarget": {
                getTargets(draft).push({
                    target: action.target,
                    rules: [],
                });
                break;
            }
            case "UpdateTarget": {
                const targets = getTargets(draft);
                const targetIndex = targets.findIndex(
                    (target) => target.target.id === action.target.id
                );
                if (targetIndex !== -1) {
                    targets[targetIndex].target = action.target;
                }
                break;
            }
            case "RemoveTarget": {
                const targets = getTargets(draft);
                const targetIndex = targets.findIndex(
                    (target) => target.target.id === action.targetId
                );

                if (targetIndex !== -1) {
                    targets.splice(targetIndex, 1);
                }

                break;
            }
            case "AddRule": {
                const targets = getTargets(draft);
                const target = targets.find(
                    (target) => target.target.id === action.targetId
                );
                if (target) {
                    target.rules.push(action.rule);
                }

                break;
            }
            case "UpdateRule": {
                const target = getTargets(draft).find(
                    (target) => target.target.id === action.targetId
                );

                if (target) {
                    const ruleIndex = target.rules.findIndex(
                        (rule) => rule.id === action.rule.id
                    );
                    if (ruleIndex !== -1) {
                        target.rules[ruleIndex] = action.rule;
                    }
                }
                break;
            }
            case "RemoveRule": {
                const targets = getTargets(draft);
                const target = targets.find(
                    (target) => target.target.id === action.targetId
                );

                if (target) {
                    const ruleIndex = target.rules.findIndex(
                        (rule) => rule.id === action.rule.id
                    );
                    if (ruleIndex !== -1) {
                        target.rules.splice(ruleIndex, 1);
                    }
                }
                break;
            }
            case "AddKey": {
                getEncryptionKeys(draft).push(action.encryptionKey);
                break;
            }
            case "RemoveKey": {
                const keys = getEncryptionKeys(draft);
                const key = keys.find((key) => key.id === action.keyId);

                if (key) {
                    const keyIndex = keys.findIndex(
                        (key) => key.id === action.keyId
                    );
                    if (keyIndex !== -1) {
                        keys.splice(keyIndex, 1);
                    }
                }
                break;
            }
        }
    };

    const [dataExportState, dataExportDispatch] = useImmerReducer<
        DataExportState,
        DataExportAction
    >(reducer, {
        targets: undefined,
        encryptionKeys: undefined,
    });

    const getTargetAndIndex = useCallback(
        (targetId: string) => {
            if (dataExportState.targets) {
                const index = dataExportState.targets.findIndex(
                    (target) => target.target.id === targetId
                );
                if (index !== -1) {
                    return {
                        target: dataExportState.targets[index],
                        index,
                    };
                }
            }
            return {
                target: undefined,
                index: undefined,
            };
        },
        [dataExportState]
    );

    const getAllTargets = useCallback(
        () => dataExportState.targets,
        [dataExportState.targets]
    );

    const getRuleAndIndex = useCallback(
        (targetId: string, ruleId: string) => {
            const { target } = getTargetAndIndex(targetId);
            if (!target) {
                return {
                    rule: undefined,
                    index: undefined,
                };
            }

            const index = target.rules.findIndex((rule) => rule.id === ruleId);
            if (index !== -1) {
                return {
                    rule: target.rules[index],
                    index,
                };
            } else {
                return {
                    rule: undefined,
                    index: undefined,
                };
            }
        },
        [getTargetAndIndex]
    );

    const getAllRules = useCallback(
        () =>
            getAllTargets()?.reduce((previousValue, target) => {
                previousValue.push(...target.rules);
                return previousValue;
            }, [] as ExportRule[]),
        [getAllTargets]
    );

    const getAllKeys = useCallback(
        () => dataExportState.encryptionKeys,
        [dataExportState.encryptionKeys]
    );

    return (
        <DataExportContext.Provider
            value={{
                dataExportSate: dataExportState,
                dataExportDispatch,
                itemGetters: {
                    allTargets: getAllTargets,
                    targetAndIndex: getTargetAndIndex,
                    allRules: getAllRules,
                    ruleAndIndex: getRuleAndIndex,
                    allKeys: getAllKeys,
                },
            }}
        >
            {children}
        </DataExportContext.Provider>
    );
};

export { DataExportProvider, useDataExport };
