import { FC, useCallback, useEffect, useState } from "react";
import { Footer } from "components/MainPane";
import { AlertCategory, Page, usePage } from "components/Page";
import ChecklistExtraPane from "components/extra-panes/ChecklistExtraPane";
import {
    DndContext,
    closestCenter,
    PointerSensor,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import {
    arrayMove,
    useSortable,
    SortableContext,
    verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import NotAnchor from "components/NotAnchor";
import { usePopup } from "contexts/PopupProvider";
import MenuPopover, { MenuItem } from "components/MenuPopover";
import { useApi } from "contexts/ApiProvider";
import classNames from "classnames";
import cloneDeep from "lodash/cloneDeep";
import pick from "lodash/pick";
import upperFirst from "lodash/upperFirst";
import { ConfirmationPopup, Popup, createFormPopup } from "components/Popup";
import { z } from "zod";
import SharedAddOrEditQuestionDatapointPopup from "./components/AddOrEditQuestionDatapointPopup";
import SharedDeleteDatapointPopup from "./components/DeleteDatapointPopup";
import TextareaAutosize from "react-textarea-autosize";

import {
    ApplicationSectionItemV1,
    ApplicationSectionV1,
    AssetSectionItemV1,
    AssetSectionV1,
    BindSectionItemV1,
    ProductVersion,
    SectionV1,
} from "@joshuins/builder";
import type { DragEndEvent } from "@dnd-kit/core";
import {
    ApplicationProvider,
    CodePrefixes,
    useApplication,
} from "./components/ApplicationProvider";
import { FormulaSuggestionInput } from "components/ReactHookFormUncontrolledComponents";
import { setCodePrefix, randomString } from "utils/string";
import ProductMainPane from "./components/ProductMainPane";
import { includes } from "utils/array";
import {
    findSectionIndex,
    findIndexesBySectionItemCode,
    getItemCode,
    getItemTitle,
    setItemCode,
    getApplicationDatapoints,
    getApplicationSections,
    orderSections,
} from "utils/product-version";
import { findIndexesByParagraphCode } from "utils/product-version";
import { findIndexesByHeadingCode } from "utils/product-version";

const ApplicationCheckList = () => {
    const { element } = usePage();
    const productVersion = element as unknown as ProductVersion | undefined;

    if (!productVersion) {
        return <></>;
    }

    return (
        <ChecklistExtraPane
            associatedId={productVersion.id}
            checklistType="product"
            title="Application Checklist"
            description={
                "Follow these steps to set up the application. " +
                'Click "Help Center" for videos and articles to learn more. ' +
                "When you are finished, check the step as complete."
            }
            tags={[
                {
                    tag: "CreateSections",
                    title: "Create Sections",
                    url: "https://help.joshu.insure/en/articles/6355325-application-sections",
                },
                {
                    tag: "AddQuestions",
                    title: "Add Questions",
                    url: "https://help.joshu.insure/en/articles/6355326-application-questions",
                },
                {
                    tag: "ReviewQuestionOptions",
                    title: "Review Question Options",
                    url: "https://help.joshu.insure/en/articles/6355329-application-question-options",
                },
                {
                    tag: "AddOptionalLists",
                    title: "Add Optional Lists",
                    url: "https://help.joshu.insure/en/articles/6355328-application-lists",
                },
                {
                    tag: "AddOptionalSubheads",
                    title: "Add Optional Subheads & Text",
                    url: "https://help.joshu.insure/en/articles/6355330-application-subheads-text",
                },
                {
                    tag: "PreviewAndTest",
                    title: "Preview & Test",
                    url: "https://help.joshu.insure/en/articles/6355331-application-preview-test",
                },
            ]}
        />
    );
};

const DeleteSectionPopup: FC = () => {
    const { popupData } = usePopup();
    const { sdkBuilder } = useApi();
    const { addAlertMessages, tryCatchAndRaiseError } = usePage();
    const sectionCode = popupData?.sectionCode as string | undefined;
    const {
        applicationDispatch,
        applicationState: { productVersion },
    } = useApplication();

    const onSubmit = useCallback(async () => {
        if (!productVersion || !sectionCode) {
            return;
        }
        const updateProdcutVersion = cloneDeep(productVersion);
        tryCatchAndRaiseError(async () => {
            const sectionIndex = findSectionIndex(productVersion, sectionCode);
            if (sectionIndex >= 0) {
                const sections = updateProdcutVersion.schema.spec.sections;
                sections.splice(sectionIndex, 1);
                updateProdcutVersion.schema.spec.sections = sections;
                await sdkBuilder.updateProductVersion({
                    id: updateProdcutVersion.id,
                    UpdateProductVersion: {
                        schema: updateProdcutVersion.schema,
                    },
                });
                applicationDispatch({
                    action: "SetProductVersion",
                    productVersion: updateProdcutVersion,
                });
                addAlertMessages({
                    message: "Section deleted successfully",
                    category: AlertCategory.SUCCESS,
                });
            }
        });
    }, [
        productVersion,
        sectionCode,
        tryCatchAndRaiseError,
        sdkBuilder,
        applicationDispatch,
        addAlertMessages,
    ]);

    return (
        <ConfirmationPopup
            name="delete-section"
            submitText="Delete Section"
            mobileSubmitText="Delete"
            onSubmit={onSubmit}
        >
            <header>
                <h2>Delete Section?</h2>
                <p className="size-14">
                    This section and any existing questions within the section
                    will be deleted, and cannot be undone.
                </p>
            </header>
        </ConfirmationPopup>
    );
};

const MoveItemSectionPopup: FC = () => {
    const { popupData } = usePopup();
    const { sdkBuilder } = useApi();
    const {
        applicationDispatch,
        applicationState: { productVersion },
    } = useApplication();
    const { tryCatchAndRaiseError } = usePage();
    const newSectionCode = popupData?.newSectionCode as string | undefined;
    const itemCode = popupData?.itemCode as string | undefined;

    const onSubmit = useCallback(async () => {
        if (!productVersion || !newSectionCode || !itemCode) {
            return;
        }
        tryCatchAndRaiseError(async () => {
            const updateProdcutVersion = cloneDeep(productVersion);
            const sections = updateProdcutVersion.schema.spec.sections;
            const { sectionIndex: oldSectionIndex, itemIndex } =
                findIndexesBySectionItemCode(productVersion, itemCode);
            const newSectionIndex = findSectionIndex(
                productVersion,
                newSectionCode
            );
            const item = sections[oldSectionIndex].items[itemIndex];
            if (
                item &&
                newSectionIndex >= 0 &&
                oldSectionIndex !== undefined &&
                itemIndex !== undefined
            ) {
                const newSection = sections[newSectionIndex];
                if (includes(["Application", "Asset"], newSection.type)) {
                    newSection.items.push(item);
                    sections[oldSectionIndex].items.splice(itemIndex, 1);
                }
            }
            await sdkBuilder.updateProductVersion({
                id: updateProdcutVersion.id,
                UpdateProductVersion: {
                    schema: updateProdcutVersion.schema,
                },
            });
            applicationDispatch({
                action: "SetProductVersion",
                productVersion: updateProdcutVersion,
            });
            applicationDispatch({
                action: "ToggleOpenCloseSection",
                code: newSectionCode,
                status: true,
            });
        });
    }, [
        productVersion,
        newSectionCode,
        itemCode,
        tryCatchAndRaiseError,
        sdkBuilder,
        applicationDispatch,
    ]);

    return (
        <ConfirmationPopup
            name="move-item"
            submitText="Move"
            onSubmit={onSubmit}
        >
            <header>
                <h2>Are you sure?</h2>
                <p className="size-14">
                    Data point will be removed from current section and moved to
                    the new section.
                </p>
            </header>
        </ConfirmationPopup>
    );
};

const addOrEditStandardSectionSchema = z.object({
    title: z.string().min(1, "This field is required"),
    code: z
        .object({
            value: z.string().min(1, "This field is required"),
            context: z.object({
                existingSectionCodeToUpdate: z.union([
                    z.string(),
                    z.null(),
                    z.undefined(),
                ]),
                productVersion: z.union([z.any(), z.null()]),
            }),
        })
        .refine(
            ({ value, context }) => {
                const { existingSectionCodeToUpdate, productVersion } = context;
                if (existingSectionCodeToUpdate === value) {
                    return true;
                } else if (productVersion) {
                    const datapoints = getApplicationDatapoints(productVersion);
                    const sections = getApplicationSections(productVersion);
                    const existingDatapoint = datapoints.findIndex(
                        (datapoint) => datapoint.code === value
                    );
                    const existingSection = sections.findIndex(
                        (section) => section.code === value
                    );
                    return existingDatapoint === -1 && existingSection === -1;
                }
            },
            {
                message: "Reference tag already in use",
                path: ["value"],
            }
        ),
    condition: z
        .object({
            enabled: z.boolean(),
            text: z.string(),
        })
        .refine((value) => !value.enabled || value.text.length > 0, {
            message: "You must supply a condition",
            path: ["text"],
        }),
    is_indication: z.boolean(),
});
type AddOrEditStandardSectionType = z.infer<
    typeof addOrEditStandardSectionSchema
>;

const {
    FormPopup: AddOrEditStandardSectionFormPopup,
    useFormReturnRef: useAddOrEditStandardSectionFormReturnRef,
} = createFormPopup(addOrEditStandardSectionSchema);

const AddOrEditStandardSectionPopup: FC = () => {
    const { sdkBuilder } = useApi();
    const { popupData, isPopupOpen } = usePopup();
    const { addAlertMessages, tryCatchAndRaiseError } = usePage();
    const {
        allowEditing,
        applicationDispatch,
        applicationState: { productVersion },
    } = useApplication();
    const {
        formReturn: { reset },
        formReturnRefCallback,
    } = useAddOrEditStandardSectionFormReturnRef();
    const existingSection = popupData?.section as
        | ApplicationSectionV1
        | undefined;

    const transformFormDataToAppSection = useCallback(
        (
            formData: AddOrEditStandardSectionType
        ): ApplicationSectionV1 | undefined => {
            if (!productVersion) {
                return;
            }
            return {
                ...pick(formData, ["title", "is_indication"]),
                code: formData.code.value,
                condition:
                    formData.condition.enabled && formData.condition.text
                        ? formData.condition.text
                        : null,
                items: [],
            };
        },
        [productVersion]
    );

    const transformAppSectionToFormData = useCallback(
        (section: ApplicationSectionV1): AddOrEditStandardSectionType => {
            return {
                ...pick(section, ["is_indication"]),
                title: section.title,
                code: {
                    value: section.code,
                    context: {
                        existingSectionCodeToUpdate: section.code,
                        productVersion,
                    },
                },
                condition: {
                    enabled: !!section.condition,
                    text: section.condition ?? "",
                },
            };
        },
        [productVersion]
    );

    useEffect(() => {
        const getSection = () => {
            if (!isPopupOpen("add-or-edit-standard-section") || !reset) {
                return;
            }
            if (existingSection) {
                reset(transformAppSectionToFormData(existingSection));
            } else {
                reset({
                    code: {
                        value: `app.${randomString(8)}`,
                        context: { productVersion },
                    },
                });
            }
        };
        getSection();
    }, [
        sdkBuilder,
        existingSection,
        transformAppSectionToFormData,
        isPopupOpen,
        reset,
        productVersion,
    ]);

    const prefix = CodePrefixes.APPLICATION_PREFIX;

    const onSubmit = useCallback(
        async (data: AddOrEditStandardSectionType) => {
            if (!productVersion) {
                return;
            }
            const updateProdcutVersion = cloneDeep(productVersion);
            tryCatchAndRaiseError(async () => {
                const sections = updateProdcutVersion.schema.spec.sections;
                let code: string;

                if (existingSection) {
                    const sectionIndex = sections.findIndex(
                        (item) => item.code === existingSection.code
                    );
                    if (sectionIndex >= 0) {
                        const apiData = transformFormDataToAppSection(data);
                        sections[sectionIndex] = {
                            ...existingSection,
                            ...apiData,
                            items: existingSection.items,
                            type: "Application",
                        };
                    }
                    code = sections[sectionIndex].code;
                } else {
                    const apiData = transformFormDataToAppSection(data);
                    if (!apiData) {
                        return;
                    }
                    code = apiData.code;
                    sections.push({
                        ...apiData,
                        type: "Application",
                    });
                }
                updateProdcutVersion.schema.spec.sections =
                    orderSections(sections);
                await sdkBuilder.updateProductVersion({
                    id: updateProdcutVersion.id,
                    UpdateProductVersion: {
                        schema: updateProdcutVersion.schema,
                    },
                });
                applicationDispatch({
                    action: "SetProductVersion",
                    productVersion: updateProdcutVersion,
                });
                applicationDispatch({
                    action: "ToggleOpenCloseSection",
                    code,
                    status: true,
                });
                addAlertMessages({
                    message: `Section ${
                        existingSection ? "updated" : "created"
                    } successfully`,
                    category: AlertCategory.SUCCESS,
                });
            });
        },
        [
            productVersion,
            tryCatchAndRaiseError,
            existingSection,
            sdkBuilder,
            applicationDispatch,
            addAlertMessages,
            transformFormDataToAppSection,
        ]
    );

    return (
        <AddOrEditStandardSectionFormPopup
            name="add-or-edit-standard-section"
            defaultValues={{
                title: "",
                condition: {
                    enabled: false,
                    text: "",
                },
                code: {
                    value: `app.${randomString(8)}`,
                    context: {
                        productVersion,
                    },
                },
                is_indication: false,
            }}
            submitText="Save"
            formReturnRefCallback={formReturnRefCallback}
            onSubmit={onSubmit}
            disabled={!allowEditing}
            overlayPopups={[
                <>
                    <h3>Reference Tag</h3>
                    <p>
                        We have generated a random one for you which you can
                        modify to something that you can understand
                    </p>
                </>,
            ]}
        >
            {({
                register,
                watch,
                control,
                openOverlayPopup,
                formState: { errors },
            }) => {
                return (
                    <>
                        <h2>Section</h2>
                        <p className="color-black-100 has-error">
                            <label htmlFor="ifr-1402">Title</label>
                            <input
                                {...register("title")}
                                type="text"
                                id="ifr-1402"
                            />
                            {errors.title && (
                                <label
                                    id="ifr-1402-error"
                                    className="error"
                                    htmlFor="ifr-1402"
                                >
                                    {errors.title.message}
                                </label>
                            )}
                        </p>
                        <p>
                            <label htmlFor="faj-1412">
                                Reference Tag{" "}
                                <NotAnchor
                                    onClick={() => {
                                        openOverlayPopup(0);
                                    }}
                                    className="text-right"
                                    data-overlay="v6"
                                >
                                    <i className="icon-help" />{" "}
                                    <span className="hidden">More info</span>
                                </NotAnchor>
                            </label>
                            <input
                                {...register("code.value", {
                                    onChange: (e) => setCodePrefix(prefix, e),
                                })}
                                type="text"
                                id="faj-1534"
                                maxLength={30}
                            />
                            {errors.code?.value && (
                                <label
                                    id="faj-1534-error"
                                    className="error"
                                    htmlFor="faj-1534"
                                >
                                    {errors.code?.value.message}
                                </label>
                            )}
                        </p>
                        <ul className="check overlay">
                            <li className="section-question-number section-question-date">
                                <input
                                    {...register("condition.enabled")}
                                    type="checkbox"
                                    id="cond-1402"
                                />
                                <label htmlFor="cond-1402">Conditional</label>
                                <div
                                    className={classNames("cols", {
                                        hidden: !watch("condition.enabled"),
                                    })}
                                >
                                    <p>
                                        <label htmlFor="ihy-1402">
                                            Only display this section if...
                                        </label>
                                        <FormulaSuggestionInput
                                            name="condition.text"
                                            control={control}
                                            id="ihy-1402"
                                            suggestions={[
                                                "ref_code=1",
                                                "AND(ref_code1=0,ref_code2>1000)",
                                                "MAX(ref_code1,ref_code2,ref_code3)=1",
                                            ]}
                                        />
                                        {errors.condition?.text && (
                                            <label
                                                id="ihy-1402-error"
                                                className="error"
                                                htmlFor="ihy-1402"
                                            >
                                                {errors.condition.text.message}
                                            </label>
                                        )}
                                        <span className="scheme-info">
                                            Use Excel formulas to evaluate
                                            answers, display if true
                                        </span>
                                    </p>
                                </div>
                            </li>
                            <li className="section-question-number section-question-date">
                                <input
                                    {...register("is_indication")}
                                    type="checkbox"
                                    id="indication-1402"
                                />
                                <label htmlFor="indication-1402">
                                    Indication
                                </label>
                            </li>
                        </ul>
                    </>
                );
            }}
        </AddOrEditStandardSectionFormPopup>
    );
};

const addOrEditListSectionSchema = z.object({
    title_plural: z.string().min(1, "This field is required"),
    title_singular: z.string().min(1, "This field is required"),
    code: z
        .object({
            value: z.string().min(1, "This field is required"),
            context: z.object({
                existingSectionCodeToUpdate: z.union([
                    z.string(),
                    z.null(),
                    z.undefined(),
                ]),
                productVersion: z.union([z.any(), z.null()]),
            }),
        })
        .refine(
            ({ value, context }) => {
                const { existingSectionCodeToUpdate, productVersion } = context;
                if (existingSectionCodeToUpdate === value) {
                    return true;
                } else if (productVersion) {
                    const datapoints = getApplicationDatapoints(productVersion);
                    const sections = getApplicationSections(productVersion);
                    const existingDatapoint = datapoints.findIndex(
                        (datapoint) => datapoint.code === value
                    );
                    const existingSection = sections.findIndex(
                        (section) => section.code === value
                    );
                    return existingDatapoint === -1 && existingSection === -1;
                }
            },
            {
                message: "Reference tag already in use",
                path: ["value"],
            }
        ),
    condition: z
        .object({
            enabled: z.boolean(),
            text: z.string(),
        })
        .refine((value) => !value.enabled || value.text.length > 0, {
            message: "You must supply a condition",
            path: ["text"],
        }),
    range: z
        .object({
            enabled: z.boolean(),
            min: z
                .string()
                .regex(
                    /^\d+$/,
                    "Must be a valid integer, equal to or greater than 0"
                )
                .or(z.string().max(0)),
            max: z
                .string()
                .regex(
                    /^\d+$/,
                    "Must be a valid integer, equal to or greater than 0"
                )
                .or(z.string().max(0)),
        })
        .refine(
            (value) => {
                return !value.enabled || value.min.length > 0;
            },
            {
                message: "You must supply a minimum for the range",
                path: ["min"],
            }
        )
        .refine((value) => !value.enabled || value.max.length > 0, {
            message: "You must supply a maximum for the range",
            path: ["max"],
        }),
});
type AddOrEditListSectionType = z.infer<typeof addOrEditListSectionSchema>;

const {
    FormPopup: AddOrEditListSectionFormPopup,
    useFormReturnRef: useAddOrEditListSectionFormReturnRef,
} = createFormPopup(addOrEditListSectionSchema);

const AddOrEditListSectionPopup: FC = () => {
    const { sdkBuilder } = useApi();
    const { popupData, isPopupOpen } = usePopup();
    const { addAlertMessages, tryCatchAndRaiseError } = usePage();
    const {
        applicationDispatch,
        allowEditing,
        applicationState: { productVersion },
    } = useApplication();
    const {
        formReturn: { reset },
        formReturnRefCallback,
    } = useAddOrEditListSectionFormReturnRef();
    const existingSection = popupData?.section as AssetSectionV1 | undefined;

    const transformFormDataToAssetSection = useCallback(
        (formData: AddOrEditListSectionType): AssetSectionV1 | undefined => {
            if (!productVersion) {
                return;
            }

            return {
                ...pick(formData, ["title_plural", "title_singular"]),
                condition:
                    formData.condition.enabled && formData.condition.text
                        ? formData.condition.text
                        : null,
                min_count: formData.range.enabled
                    ? parseInt(formData.range.min)
                    : null,
                max_count: formData.range.enabled
                    ? parseInt(formData.range.max)
                    : null,
                code: formData.code.value,
                items: [],
            };
        },
        [productVersion]
    );

    const transformAssetToFormData = useCallback(
        (section: AssetSectionV1): AddOrEditListSectionType => {
            return {
                title_plural: section.title_plural,
                title_singular: section.title_singular,
                code: {
                    value: section.code,
                    context: {
                        existingSectionCodeToUpdate: section.code,
                        productVersion,
                    },
                },
                range: {
                    enabled: !!section.min_count || !!section.max_count,
                    min: section.min_count?.toString() ?? "",
                    max: section.max_count?.toString() ?? "",
                },
                condition: {
                    enabled: !!section.condition,
                    text: section.condition ?? "",
                },
            };
        },
        [productVersion]
    );

    useEffect(() => {
        const getSection = () => {
            if (!isPopupOpen("add-or-edit-list-section") || !reset) {
                return;
            }
            if (existingSection) {
                reset(transformAssetToFormData(existingSection));
            } else {
                reset({
                    code: {
                        value: `app.${randomString(8)}`,
                        context: { productVersion },
                    },
                });
            }
        };
        getSection();
    }, [
        sdkBuilder,
        existingSection,
        transformAssetToFormData,
        isPopupOpen,
        reset,
        productVersion,
    ]);

    const prefix = CodePrefixes.APPLICATION_PREFIX;

    const onSubmit = useCallback(
        async (data: AddOrEditListSectionType) => {
            if (!productVersion) {
                return;
            }
            const updateProdcutVersion = cloneDeep(productVersion);
            tryCatchAndRaiseError(async () => {
                const sections = updateProdcutVersion.schema.spec.sections;
                if (existingSection) {
                    const sectionIndex = sections.findIndex(
                        (item) => item.code === existingSection.code
                    );
                    if (sectionIndex >= 0) {
                        const apiData = transformFormDataToAssetSection(data);
                        sections[sectionIndex] = {
                            ...existingSection,
                            ...apiData,
                            items: existingSection.items,
                            type: "Asset",
                        };
                    }
                } else {
                    const apiData = transformFormDataToAssetSection(data);
                    if (!apiData) {
                        return;
                    }
                    sections.push({
                        ...apiData,
                        type: "Asset",
                    });
                }
                updateProdcutVersion.schema.spec.sections =
                    orderSections(sections);
                await sdkBuilder.updateProductVersion({
                    id: updateProdcutVersion.id,
                    UpdateProductVersion: {
                        schema: updateProdcutVersion.schema,
                    },
                });
                applicationDispatch({
                    action: "SetProductVersion",
                    productVersion: updateProdcutVersion,
                });
                addAlertMessages({
                    message: `Section ${
                        existingSection ? "updated" : "created"
                    } successfully`,
                    category: AlertCategory.SUCCESS,
                });
            });
        },
        [
            productVersion,
            tryCatchAndRaiseError,
            existingSection,
            sdkBuilder,
            applicationDispatch,
            addAlertMessages,
            transformFormDataToAssetSection,
        ]
    );

    return (
        <AddOrEditListSectionFormPopup
            name="add-or-edit-list-section"
            defaultValues={{
                title_plural: "",
                title_singular: "",
                code: {
                    value: `app.${randomString(8)}`,
                    context: {
                        productVersion,
                    },
                },
                range: {
                    enabled: false,
                    min: "",
                    max: "",
                },
                condition: {
                    enabled: false,
                    text: "",
                },
            }}
            submitText={`${existingSection ? "Update" : "Add"} Section`}
            formReturnRefCallback={formReturnRefCallback}
            onSubmit={onSubmit}
            disabled={!allowEditing}
            overlayPopups={[
                <>
                    <h3>Title Plural</h3>
                    <p>
                        This is the main title for all the assets. If you are
                        asking about all of the vehicles to be insured, a good
                        title would be &quot;Vehicles.&qout; For real estate,
                        maybe &quot;Locations&quot; or &quot;Properties.&quot;
                    </p>
                </>,
                <>
                    <h3>Title Singular</h3>
                    <p>
                        You must add both a plural and singular title becase we
                        sometimes refer to list items as a group (Vehicles, or
                        Locations), and sometimes individually (Add Vehicle, Add
                        Location).
                    </p>
                </>,
                <>
                    <h3>Reference Tag</h3>
                    <p>
                        The reference tag is required to refer to items when
                        running the rater. Use a short version of your section
                        title, all lower case with underscores. A section for
                        Vechicles might use &quot;vehicles&quot; or
                        &quot;vehicle_type&quot; as the reference tag.
                    </p>
                </>,
            ]}
        >
            {({
                register,
                watch,
                openOverlayPopup,
                control,
                formState: { errors },
            }) => {
                return (
                    <>
                        <header className="m30">
                            <h2>List Section</h2>
                        </header>
                        <p>
                            <label htmlFor="fah-1412">
                                Title Plural{" "}
                                <span className="text-right">
                                    <NotAnchor
                                        onClick={() => {
                                            openOverlayPopup(0);
                                        }}
                                    >
                                        <i className="icon-help" />{" "}
                                        <span className="hidden">
                                            More info
                                        </span>
                                    </NotAnchor>
                                </span>
                            </label>
                            <input
                                {...register("title_plural")}
                                type="text"
                                id="fah-1412"
                                placeholder="What type of things will be recorded..."
                                maxLength={75}
                            />
                            {errors.title_plural && (
                                <label
                                    id="fah-1412-error"
                                    className="error"
                                    htmlFor="fah-1412"
                                >
                                    {errors.title_plural.message}
                                </label>
                            )}
                        </p>
                        <p>
                            <label htmlFor="fai-1412">
                                Title Singular{" "}
                                <span className="text-right">
                                    <NotAnchor
                                        onClick={() => {
                                            openOverlayPopup(1);
                                        }}
                                    >
                                        <i className="icon-help" />{" "}
                                        <span className="hidden">
                                            More info
                                        </span>
                                    </NotAnchor>
                                </span>
                            </label>
                            <input
                                {...register("title_singular")}
                                type="text"
                                id="fai-1412"
                                maxLength={75}
                            />
                            {errors.title_singular && (
                                <label
                                    id="fai-1412-error"
                                    className="error"
                                    htmlFor="fai-1412"
                                >
                                    {errors.title_singular.message}
                                </label>
                            )}
                        </p>
                        <p>
                            <label htmlFor="faj-1412">
                                Reference Tag{" "}
                                <NotAnchor
                                    onClick={() => {
                                        openOverlayPopup(2);
                                    }}
                                    className="text-right"
                                    data-overlay="v6"
                                >
                                    <i className="icon-help" />{" "}
                                    <span className="hidden">More info</span>
                                </NotAnchor>
                            </label>
                            <input
                                {...register("code.value", {
                                    onChange: (e) => setCodePrefix(prefix, e),
                                })}
                                type="text"
                                id="faj-1412"
                                maxLength={30}
                            />
                            {errors.code?.value && (
                                <label
                                    id="faj-1412-error"
                                    className="error"
                                    htmlFor="faj-1412"
                                >
                                    {errors.code?.value.message}
                                </label>
                            )}
                        </p>
                        <ul className="check overlay">
                            <li className="section-question-number section-question-date">
                                <input
                                    {...register("condition.enabled")}
                                    type="checkbox"
                                    id="assetcond-1412"
                                />{" "}
                                <label htmlFor="assetcond-1412">
                                    Conditional
                                </label>
                                <div
                                    className={classNames("cols", {
                                        hidden: !watch("condition.enabled"),
                                    })}
                                >
                                    <p>
                                        <label htmlFor="ihy-1412">
                                            Only display this section if...
                                        </label>
                                        <FormulaSuggestionInput
                                            name="condition.text"
                                            control={control}
                                            id="ihy-1412"
                                            suggestions={[
                                                "ref_code=1",
                                                "AND(ref_code1=0,ref_code2>1000)",
                                                "MAX(ref_code1,ref_code2,ref_code3)=1",
                                            ]}
                                        />
                                        {errors.condition?.text && (
                                            <label
                                                id="ihy-1412-error"
                                                className="error"
                                                htmlFor="ihy-1412"
                                            >
                                                {errors.condition.text.message}
                                            </label>
                                        )}
                                        <span className="scheme-info">
                                            Use Excel formulas to evaluate
                                            answers, display if true
                                        </span>
                                    </p>
                                </div>
                            </li>
                            <li className="section-question-number section-question-date">
                                <input
                                    {...register("range.enabled")}
                                    type="checkbox"
                                    id="fak-1412"
                                />{" "}
                                <label htmlFor="fak-1412">Range</label>
                                <div
                                    className={classNames("cols", {
                                        hidden: !watch("range.enabled"),
                                    })}
                                >
                                    <p className="c50">
                                        <label htmlFor="fal-1412">
                                            Minimum
                                        </label>
                                        <input
                                            {...register("range.min")}
                                            type="number"
                                            id="fal-1412"
                                        />
                                    </p>
                                    <p className="c50">
                                        <label htmlFor="fam-1412">
                                            Maximum
                                        </label>
                                        <input
                                            {...register("range.max")}
                                            type="number"
                                            id="fam-1412"
                                        />
                                    </p>
                                    {errors.range && (
                                        <label
                                            id="fak-1412-error"
                                            className="error"
                                            htmlFor="fak-1412"
                                        >
                                            {errors.range.min
                                                ? errors.range.min.message
                                                : errors.range.max
                                                  ? errors.range.max.message
                                                  : ""}
                                        </label>
                                    )}
                                </div>
                            </li>
                        </ul>
                    </>
                );
            }}
        </AddOrEditListSectionFormPopup>
    );
};

const addSectionFirstStepSchema = z.object({
    sectionType: z
        .enum(["Standard", "List", ""], {
            errorMap: () => ({ message: "Please choose a section type" }),
        })
        .refine((value) => value !== "", {
            message: "Please choose a section type",
        }),
});
type AddSectionFirstStepType = z.infer<typeof addSectionFirstStepSchema>;

const { FormPopup: AddOrEditFirstStepFormPopup } = createFormPopup(
    addSectionFirstStepSchema
);

const AddSectionFirstStepPopup: FC = () => {
    const { openPopup } = usePopup();
    const { allowEditing } = useApplication();

    const onSubmit = useCallback(
        async (data: AddSectionFirstStepType) => {
            let popupName: string | undefined;
            switch (data.sectionType) {
                case "Standard":
                    popupName = "add-or-edit-standard-section";
                    break;
                case "List":
                    popupName = "add-or-edit-list-section";
                    break;
            }

            if (!popupName) {
                return;
            }
            openPopup(popupName);
        },
        [openPopup]
    );

    return (
        <AddOrEditFirstStepFormPopup
            name="add-section-first-step"
            defaultValues={{
                sectionType: "",
            }}
            submitText="Next"
            onSubmit={onSubmit}
            disabled={!allowEditing}
            overlayPopups={[
                <>
                    <h3>Standard Section</h3>
                    <p>
                        Joshu online applications are designed to be
                        user-friendly for your customers. The easiest way to
                        make applications more friendly is to limit the number
                        of questions, and break it up into smaller sections.
                    </p>
                    <p>
                        We require at least one section, but you can add as many
                        as you need. They will appear in the navigation on the
                        right side of the application and help users keep track
                        of their progress.
                    </p>
                </>,
                <>
                    <h3>List Section</h3>
                    <p>
                        Part of your application might be to collect a list of
                        individual—but similar—things like vehicles, websites,
                        or people. For that, you should create a List Section.
                    </p>
                    <p>
                        When a user adds items to the list, they answer the same
                        question or set of questions for each one. For example,
                        the question set for vehicles might be make, model,
                        year, and VIN number. For a simple list, the question
                        might just name, or website URL.
                    </p>
                </>,
            ]}
        >
            {({ register, openOverlayPopup, formState: { errors } }) => (
                <>
                    <header className="m30">
                        <h2>What type of section?</h2>
                    </header>
                    <ul className="check strong">
                        <li>
                            <input
                                {...register("sectionType")}
                                type="radio"
                                id="fae"
                                value="Standard"
                            />{" "}
                            <label htmlFor="fae">
                                Standard Section{" "}
                                <span>
                                    A general-purpose section for all types of
                                    questions
                                </span>{" "}
                                <NotAnchor
                                    className="text-right"
                                    onClick={() => {
                                        openOverlayPopup(0);
                                    }}
                                >
                                    <i className="icon-help" />{" "}
                                    <span className="hidden">More info</span>
                                </NotAnchor>
                            </label>
                        </li>
                        <li>
                            <input
                                {...register("sectionType")}
                                type="radio"
                                id="faf"
                                value="List"
                            />{" "}
                            <label htmlFor="faf">
                                List Section{" "}
                                <span>
                                    A special type of section to collect a list
                                    of things
                                </span>{" "}
                                <NotAnchor
                                    className="text-right"
                                    onClick={() => {
                                        openOverlayPopup(1);
                                    }}
                                >
                                    <i className="icon-help" />{" "}
                                    <span className="hidden">More info</span>
                                </NotAnchor>
                            </label>
                        </li>
                    </ul>
                    {errors.sectionType && (
                        <label id="fae" className="error" htmlFor="fae">
                            {errors.sectionType.message}
                        </label>
                    )}
                </>
            )}
        </AddOrEditFirstStepFormPopup>
    );
};

const SectionActionsInfoPopup: FC = () => (
    <Popup name="section-actions-info">
        <header>
            <h2 className="color-primary font-medium">Section Actions</h2>
        </header>
        <ul className="list-static last-child">
            <li>
                <span className="strong">Add Question</span> adds a new question
                within this section (questions can be dragged to other sections
                later if needed)
            </li>
            <li>
                <span className="strong">Add Subhead</span> adds a bold subhead
                to denote a subsection within the existing section (drag subhead
                row to the desired position after adding it)
            </li>
            <li>
                <span className="strong">Add Text</span> adds a blank text areas
                for instructions or explanatory text (drag text row to the
                desired position after adding it)
            </li>
        </ul>
    </Popup>
);

const addOrEditParagraphDatapointSchema = z.object({
    text: z.string().min(1, "This field is required"),
});
type AddOrEditParagraphDatapointType = z.infer<
    typeof addOrEditParagraphDatapointSchema
>;

const {
    FormPopup: AddOrEditParagraphFormPopup,
    useFormReturnRef: useAddOrEditSubheadDatapointFormReturnRef,
} = createFormPopup(addOrEditParagraphDatapointSchema);

const AddOrEditParagraphPopup: FC = () => {
    const { sdkBuilder } = useApi();
    const { popupData, isPopupOpen } = usePopup();
    const { addAlertMessages, tryCatchAndRaiseError } = usePage();
    const {
        allowEditing,
        applicationDispatch,
        applicationState: { productVersion },
    } = useApplication();
    const {
        formReturn: { reset },
        formReturnRefCallback,
    } = useAddOrEditSubheadDatapointFormReturnRef();
    const sectionCode = popupData?.sectionCode as string | undefined;
    const paragraphCode = popupData?.datapointCode as string | undefined;

    useEffect(() => {
        if (
            !productVersion ||
            !paragraphCode ||
            !isPopupOpen("add-or-edit-text-datapoint") ||
            !reset
        ) {
            return;
        }
        if (paragraphCode) {
            const { paragraph: existingParagraph } = findIndexesByParagraphCode(
                productVersion,
                paragraphCode
            );
            if (existingParagraph) {
                reset({ text: existingParagraph.text });
            }
        } else {
            reset({
                text: "",
            });
        }
    }, [sdkBuilder, paragraphCode, isPopupOpen, reset, productVersion]);

    const onSubmit = useCallback(
        async (data: AddOrEditParagraphDatapointType) => {
            if (!productVersion) {
                return;
            }
            const updateProdcutVersion = cloneDeep(productVersion);
            tryCatchAndRaiseError(async () => {
                const sections = updateProdcutVersion.schema.spec.sections;
                if (paragraphCode) {
                    const { sectionIndex, paragraphIndex, paragraph } =
                        findIndexesByParagraphCode(
                            updateProdcutVersion,
                            paragraphCode
                        );
                    if (paragraph) {
                        const updatedSection = sections[sectionIndex];
                        updatedSection.items[paragraphIndex] = {
                            Paragraph: { ...paragraph, ...data },
                        };
                        updateProdcutVersion.schema.spec.sections = sections;
                        await sdkBuilder.updateProductVersion({
                            id: updateProdcutVersion.id,
                            UpdateProductVersion: {
                                schema: updateProdcutVersion.schema,
                            },
                        });
                        applicationDispatch({
                            action: "SetProductVersion",
                            productVersion: updateProdcutVersion,
                        });
                    }
                } else {
                    if (sectionCode) {
                        const updatedSectionIndex = findSectionIndex(
                            updateProdcutVersion,
                            sectionCode
                        );
                        sections[updatedSectionIndex].items.push({
                            Paragraph: {
                                code: `app.${randomString(10)}`,
                                ...data,
                            },
                        });
                        updateProdcutVersion.schema.spec.sections = sections;
                        await sdkBuilder.updateProductVersion({
                            id: updateProdcutVersion.id,
                            UpdateProductVersion: {
                                schema: updateProdcutVersion.schema,
                            },
                        });
                        applicationDispatch({
                            action: "SetProductVersion",
                            productVersion: updateProdcutVersion,
                        });
                    }
                }
                addAlertMessages({
                    message: `Subhead ${
                        paragraphCode ? "updated" : "created"
                    } successfully`,
                    category: AlertCategory.SUCCESS,
                });
            });
        },
        [
            productVersion,
            tryCatchAndRaiseError,
            paragraphCode,
            addAlertMessages,
            sdkBuilder,
            applicationDispatch,
            sectionCode,
        ]
    );

    if (!productVersion) {
        return <></>;
    }

    return (
        <AddOrEditParagraphFormPopup
            name="add-or-edit-text-datapoint"
            defaultValues={{
                text: "",
            }}
            submitText="Save"
            formReturnRefCallback={formReturnRefCallback}
            onSubmit={onSubmit}
            disabled={!allowEditing}
            overlayPopups={[
                <>
                    <h3>Section Text</h3>
                    <p>
                        {" "}
                        This can be any length of text, from a short one-line
                        description to a paragraph of help. Most likely used
                        under a subhead. For Example, a &quot;Project
                        Details&quot; section might have text below it
                        explaining what kind of information you expect.
                    </p>
                    <p>
                        We recommend the default styling as much as possible,
                        but if you need to create bold / italic words or links,
                        follow the Markdown syntax below.
                    </p>
                    <table className="table-f">
                        <tbody>
                            <tr>
                                <th>Element</th>
                                <th>Markdown Syntax</th>
                            </tr>
                            <tr>
                                <td>Bold</td>
                                <td>**bold text**</td>
                            </tr>
                            <tr>
                                <td>Italic</td>
                                <td>*italic text*</td>
                            </tr>
                            <tr>
                                <td>Link</td>
                                <td>Link is [here](https://example.com)</td>
                            </tr>
                        </tbody>
                    </table>
                </>,
            ]}
        >
            {({ register, openOverlayPopup, formState: { errors } }) => (
                <>
                    <header>
                        <h2>Section Text</h2>
                    </header>
                    <p
                        className={classNames({
                            "has-error": !!errors.text,
                        })}
                    >
                        <label htmlFor="ife">
                            {" "}
                            Instructions or explanatory text
                            <span className="text-right">
                                <NotAnchor
                                    onClick={() => {
                                        openOverlayPopup(0);
                                    }}
                                >
                                    <i
                                        aria-hidden="true"
                                        className="icon-help"
                                    />{" "}
                                    <span className="hidden">More info</span>
                                </NotAnchor>
                            </span>
                        </label>
                        <TextareaAutosize
                            {...register("text")}
                            id="ife"
                            aria-invalid="false"
                        />
                        {errors.text && (
                            <label
                                id="ife-error"
                                className="error"
                                htmlFor="ife"
                            >
                                {errors.text.message}
                            </label>
                        )}
                    </p>
                </>
            )}
        </AddOrEditParagraphFormPopup>
    );
};

const addOrEditHeadingSchema = z.object({
    text: z.string().min(1, "This field is required"),
});
type AddOrEditHeadingType = z.infer<typeof addOrEditHeadingSchema>;

const {
    FormPopup: AddOrEditHeadingFormPopup,
    useFormReturnRef: useAddOrEditHeadingFormReturnRef,
} = createFormPopup(addOrEditHeadingSchema);

const AddOrEditHeadingPopup: FC = () => {
    const { sdkBuilder } = useApi();
    const { popupData, isPopupOpen } = usePopup();
    const { addAlertMessages, tryCatchAndRaiseError } = usePage();
    const {
        allowEditing,
        applicationDispatch,
        applicationState: { productVersion },
    } = useApplication();
    const {
        formReturn: { reset },
        formReturnRefCallback,
    } = useAddOrEditHeadingFormReturnRef();
    const sectionCode = popupData?.sectionCode as string | undefined;
    const headingCode = popupData?.datapointCode as string | undefined;

    useEffect(() => {
        if (
            !productVersion ||
            !headingCode ||
            !isPopupOpen("add-or-edit-subhead-datapoint") ||
            !reset
        ) {
            return;
        }
        if (headingCode) {
            const { heading: existingHeading } = findIndexesByHeadingCode(
                productVersion,
                headingCode
            );
            if (existingHeading) {
                reset({ text: existingHeading.text });
            }
        } else {
            reset({
                text: "",
            });
        }
    }, [sdkBuilder, headingCode, isPopupOpen, reset, productVersion]);

    const onSubmit = useCallback(
        async (data: AddOrEditHeadingType) => {
            if (!productVersion) {
                return;
            }
            tryCatchAndRaiseError(async () => {
                const updateProdcutVersion = cloneDeep(productVersion);
                const sections = updateProdcutVersion.schema.spec.sections;
                if (headingCode) {
                    const { sectionIndex, headingIndex, heading } =
                        findIndexesByHeadingCode(
                            updateProdcutVersion,
                            headingCode
                        );
                    if (heading) {
                        const updatedSection = sections[sectionIndex];
                        updatedSection.items[headingIndex] = {
                            Heading: { ...heading, ...data },
                        };
                        updateProdcutVersion.schema.spec.sections = sections;
                        await sdkBuilder.updateProductVersion({
                            id: updateProdcutVersion.id,
                            UpdateProductVersion: {
                                schema: updateProdcutVersion.schema,
                            },
                        });
                        applicationDispatch({
                            action: "SetProductVersion",
                            productVersion: updateProdcutVersion,
                        });
                    }
                } else {
                    if (sectionCode) {
                        const updatedSectionIndex = findSectionIndex(
                            updateProdcutVersion,
                            sectionCode
                        );
                        sections[updatedSectionIndex].items.push({
                            Heading: {
                                code: `app.${randomString(10)}`,
                                ...data,
                            },
                        });
                        updateProdcutVersion.schema.spec.sections = sections;
                        await sdkBuilder.updateProductVersion({
                            id: updateProdcutVersion.id,
                            UpdateProductVersion: {
                                schema: updateProdcutVersion.schema,
                            },
                        });
                        applicationDispatch({
                            action: "SetProductVersion",
                            productVersion: updateProdcutVersion,
                        });
                    }
                }
                addAlertMessages({
                    message: `Subhead ${
                        headingCode ? "updated" : "created"
                    } successfully`,
                    category: AlertCategory.SUCCESS,
                });
            });
        },
        [
            productVersion,
            tryCatchAndRaiseError,
            headingCode,
            addAlertMessages,
            sdkBuilder,
            applicationDispatch,
            sectionCode,
        ]
    );

    if (!productVersion) {
        return <></>;
    }
    return (
        <AddOrEditHeadingFormPopup
            name="add-or-edit-subhead-datapoint"
            defaultValues={{
                text: "",
            }}
            submitText="Save"
            formReturnRefCallback={formReturnRefCallback}
            onSubmit={onSubmit}
            disabled={!allowEditing}
            overlayPopups={[
                <>
                    <h3>Section Subhead</h3>
                    <p>
                        {" "}
                        This is a short word or phrase used to break up your
                        section into smaller subsections. For example, a section
                        called &quot;Company Information&quot; might have two
                        subheads of &quot;Company Details&quot; &amp;
                        &quot;Contact Details&quot; with corresponding questions
                        under each.
                    </p>
                    <p>
                        We recommend the default styling as much as possible,
                        but if you need to create bold / italic words or links,
                        follow the Markdown syntax below.
                    </p>
                    <table className="table-f">
                        <tbody>
                            <tr>
                                <th>Element</th>
                                <th>Markdown Syntax</th>
                            </tr>
                            <tr>
                                <td>Bold</td>
                                <td>**bold text**</td>
                            </tr>
                            <tr>
                                <td>Italic</td>
                                <td>*italic text*</td>
                            </tr>
                            <tr>
                                <td>Link</td>
                                <td>Link is [here](https://example.com)</td>
                            </tr>
                        </tbody>
                    </table>
                </>,
            ]}
        >
            {({ register, openOverlayPopup, formState: { errors } }) => (
                <>
                    <header>
                        <h2>Section Subhead</h2>
                    </header>
                    <p
                        className={classNames({
                            "has-error": !!errors.text,
                        })}
                    >
                        <label htmlFor="ifs">
                            {" "}
                            Subhead
                            <span className="text-right">
                                {" "}
                                100 Character Limit
                                <NotAnchor
                                    onClick={() => {
                                        openOverlayPopup(0);
                                    }}
                                >
                                    <i
                                        aria-hidden="true"
                                        className="icon-help"
                                    />{" "}
                                    <span className="hidden">More info</span>
                                </NotAnchor>
                            </span>
                        </label>
                        <input
                            {...register("text")}
                            type="text"
                            id="ifs"
                            aria-invalid="false"
                            maxLength={100}
                        />
                        {errors.text && (
                            <label
                                id="ifs-error"
                                className="error"
                                htmlFor="ifs"
                            >
                                {errors.text.message}
                            </label>
                        )}
                    </p>
                </>
            )}
        </AddOrEditHeadingFormPopup>
    );
};

const DeleteDatapointPopup: FC = () => {
    const { popupData } = usePopup();
    // const { applicationDispatch } = useApplication();
    const valueTypeLabel = popupData?.valueTypeLabel as unknown as
        | string
        | undefined;
    const sectionCode = popupData?.sectionCode as number | undefined;
    const indication = popupData?.indication as boolean | undefined;

    if (!valueTypeLabel || !sectionCode || indication === undefined) {
        return <></>;
    }

    return (
        <SharedDeleteDatapointPopup
            valueTypeLabel={valueTypeLabel}
            popupName="delete-datapoint"
        />
    );
};

const DatapointListItem: FC<{
    indication: boolean;
    itemCode: string;
}> = ({ indication, itemCode }) => {
    const { openPopup } = usePopup();
    const { element } = usePage();
    const { sdkBuilder } = useApi();
    const { attributes, listeners, setNodeRef, transform, transition } =
        useSortable({ id: itemCode });
    const {
        allowEditing,
        applicationDispatch,
        applicationState: { productVersion },
    } = useApplication();

    const [item, setItem] = useState<
        ApplicationSectionItemV1 | AssetSectionItemV1 | BindSectionItemV1
    >();
    const [itemIndex, setItemIndex] = useState<number>();
    const [section, setSection] = useState<SectionV1>();

    useEffect(() => {
        const getItem = async () => {
            if (!productVersion || !itemCode) {
                return;
            }
            const { sectionIndex, itemIndex } = findIndexesBySectionItemCode(
                productVersion,
                itemCode
            );
            if (itemIndex >= 0) {
                setItem(
                    productVersion.schema.spec.sections[sectionIndex].items[
                        itemIndex
                    ]
                );
                setItemIndex(itemIndex);
                setSection(productVersion.schema.spec.sections[sectionIndex]);
            }
        };
        getItem();
    }, [element, item, itemCode, productVersion]);

    const duplicateDatapoint = useCallback(async () => {
        if (!productVersion || !item || itemIndex === undefined || !section) {
            return;
        }
        const updateProdcutVersion = cloneDeep(productVersion);
        const sectionIndex =
            updateProdcutVersion.schema.spec.sections.findIndex(
                (aSection) => aSection.code === section.code
            );

        const duplicatedItem = cloneDeep(item);
        const newCode =
            `${getItemCode(item)?.substring(0, 15)}_copy_${randomString(10, {
                digits: false,
            })}` || randomString(10);
        setItemCode(duplicatedItem, newCode);

        updateProdcutVersion.schema.spec.sections[sectionIndex].items.splice(
            itemIndex + 1,
            0,
            duplicatedItem
        );
        await sdkBuilder.updateProductVersion({
            id: productVersion.id,
            UpdateProductVersion: {
                schema: updateProdcutVersion.schema,
            },
        });
        applicationDispatch({
            action: "SetProductVersion",
            productVersion: updateProdcutVersion,
        });
    }, [
        applicationDispatch,
        item,
        itemIndex,
        productVersion,
        sdkBuilder,
        section,
    ]);

    if (!productVersion || !item || itemIndex === undefined || !section) {
        return <></>;
    }

    const valueTypeLabel =
        "Heading" in item
            ? "subhead"
            : "Paragraph" in item
              ? "text"
              : "question";

    const menuItems: MenuItem[] = [
        {
            key: `${getItemCode(item)}-duplicate`,
            label: `Duplicate ${upperFirst(valueTypeLabel)}`,
            icon: "copy",
            onClick: () => {
                duplicateDatapoint();
            },
        },
        {
            key: `${getItemCode(item)}-delete`,
            label: `Delete ${upperFirst(valueTypeLabel)}`,
            icon: "trash",
            hasSeparator: true,
            onClick: () => {
                openPopup("delete-datapoint", {
                    datapointCode: getItemCode(item),
                    indication,
                    sectionCode: section.code,
                    valueTypeLabel,
                });
            },
        },
    ];

    const otherSections = getApplicationSections(productVersion).filter(
        (aSection) => aSection.code !== section.code
    );
    if (otherSections.length > 0) {
        menuItems.push({
            key: `${getItemCode(item)}-move`,
            label: `Move ${valueTypeLabel} to...`,
            hasSeparator: true,
            children: otherSections.map((otherSection) => ({
                key: otherSection.code,
                label:
                    otherSection.type === "Asset"
                        ? otherSection.title_plural
                        : otherSection.title,
                icon: "list",
                onClick: () => {
                    openPopup("move-item", {
                        newSectionCode: otherSection.code,
                        itemCode: getItemCode(item),
                    });
                },
            })),
        });
    }

    return (
        <MenuPopover
            ref={setNodeRef}
            additionalClasses={classNames({
                strong: "Heading" in item,
            })}
            style={{
                zIndex: section.items.length - itemIndex,
                transform: CSS.Transform.toString(transform),
                transition,
            }}
            menuItems={menuItems}
            toggleButtonLabel={`Toggle ${getItemTitle(item)}`}
        >
            {({ ToggleButton, Menu }) => (
                <>
                    <NotAnchor
                        onClick={() => {
                            openPopup(
                                `add-or-edit-${valueTypeLabel}-datapoint`,
                                {
                                    indication,
                                    sectionCode: section.code,
                                    datapointCode: getItemCode(item),
                                }
                            );
                        }}
                        className="toggle-sub"
                    >
                        {getItemTitle(item)}
                    </NotAnchor>
                    {"Datapoint" in item &&
                        item.Datapoint.source === "Underwriter" && (
                            <span className="text-right hover">
                                <span className="scheme-box small color-black-40">
                                    U
                                </span>
                            </span>
                        )}

                    {allowEditing && (
                        <>
                            {ToggleButton}
                            {Menu}
                        </>
                    )}
                    <div
                        className="handle ui-sortable-handle"
                        {...attributes}
                        {...listeners}
                    />
                </>
            )}
        </MenuPopover>
    );
};

const DatapointList: FC<{
    section: SectionV1;
}> = ({ section }) => {
    const sensors = useSensors(useSensor(PointerSensor));
    const { sdkBuilder } = useApi();
    const {
        applicationDispatch,
        allowEditing,
        applicationState: { productVersion },
    } = useApplication();

    const handleDragEnd = async (event: DragEndEvent) => {
        const { active, over } = event;

        if (!productVersion || !over || !active || active.id === over.id) {
            return;
        }
        const updateProdcutVersion = cloneDeep(productVersion);

        const { itemIndex: oldIndex } = findIndexesBySectionItemCode(
            updateProdcutVersion,
            active.id.toString()
        );
        const { itemIndex: newIndex } = findIndexesBySectionItemCode(
            updateProdcutVersion,
            over.id.toString()
        );

        section.items = arrayMove(section.items, oldIndex, newIndex);
        const sections = updateProdcutVersion.schema.spec.sections;
        const sectionIndex = sections.findIndex(
            (aSection) => aSection.code === section.code
        );
        sections[sectionIndex] = section;
        updateProdcutVersion.schema.spec.sections = sections;
        await sdkBuilder.updateProductVersion({
            id: updateProdcutVersion.id,
            UpdateProductVersion: {
                schema: updateProdcutVersion.schema,
            },
        });
        applicationDispatch({
            action: "SetProductVersion",
            productVersion: updateProdcutVersion,
        });
    };

    if (!section.items || section.items.length === 0) {
        return <></>;
    }

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
        >
            <ul className="list-drag ui-sortable">
                <SortableContext
                    disabled={!allowEditing}
                    items={section.items.map((item, index) => {
                        const code = getItemCode(item);
                        return {
                            id: code || index,
                        };
                    })}
                    strategy={verticalListSortingStrategy}
                >
                    {section.items.map((item, index) => {
                        return (
                            <DatapointListItem
                                key={index}
                                indication={false}
                                itemCode={getItemCode(item) || index.toString()}
                            />
                        );
                    })}
                </SortableContext>
            </ul>
        </DndContext>
    );
};

const SectionListItem: FC<{
    indication: boolean;
    sectionCode: string;
}> = ({ indication, sectionCode }) => {
    const { openPopup } = usePopup();
    const { addAlertMessages } = usePage();
    const { sdkBuilder } = useApi();
    const {
        allowEditing,
        applicationDispatch,
        applicationState: { productVersion, toggle },
    } = useApplication();

    const { attributes, listeners, setNodeRef, transform, transition } =
        useSortable({ id: sectionCode });

    const [section, setSection] = useState<SectionV1>();
    const [sectionIndex, setSectionIndex] = useState<number>();

    useEffect(() => {
        const getSection = async () => {
            if (!productVersion || !sectionCode) {
                return;
            }
            const section = productVersion.schema.spec.sections.find(
                (section) => section.code === sectionCode
            );
            const sectionIndex = productVersion.schema.spec.sections.findIndex(
                (section) => section.code === sectionCode
            );
            if (section) {
                const section_ = cloneDeep(section);
                setSection(section_);
                setSectionIndex(sectionIndex);
            }
        };
        getSection();
    }, [sectionCode, productVersion]);

    const duplicateSection = useCallback(async () => {
        if (!productVersion || !section) {
            return;
        }
        const updateProdcutVersion = cloneDeep(productVersion);
        const sections = updateProdcutVersion.schema.spec.sections;
        const sectionIndex = sections.findIndex(
            (aSection) => aSection.code === section.code
        );
        const duplicatedSection = {
            ...cloneDeep(section),
            code: `${section.code}_${randomString(3)}`,
        };
        duplicatedSection.items.forEach((item) => {
            if ("Datapoint" in item) {
                item.Datapoint.code = `${item.Datapoint.code}_${randomString(
                    3
                )}`;
            }
        });
        sections.splice(sectionIndex, 0, duplicatedSection);
        updateProdcutVersion.schema.spec.sections = sections;
        await sdkBuilder.updateProductVersion({
            id: updateProdcutVersion.id,
            UpdateProductVersion: {
                schema: updateProdcutVersion.schema,
            },
        });
        applicationDispatch({
            action: "SetProductVersion",
            productVersion: updateProdcutVersion,
        });
        addAlertMessages({
            message: "Section deleted successfully",
            category: AlertCategory.SUCCESS,
        });
    }, [
        productVersion,
        section,
        sdkBuilder,
        applicationDispatch,
        addAlertMessages,
    ]);

    // prevents horizontal movement
    if (transform) {
        transform.x = 0;
    }

    if (!productVersion || !section || sectionIndex === undefined) {
        return <></>;
    }

    return (
        <MenuPopover
            ref={setNodeRef}
            additionalClasses={classNames("has-sub", {
                open: toggle[section.code],
            })}
            style={{
                zIndex:
                    productVersion.schema.spec.sections.length - sectionIndex,
                transform: CSS.Transform.toString(transform),
                transition,
            }}
            menuItems={[
                {
                    label: "Duplicate Section",
                    key: `${sectionCode}-duplicate`,
                    icon: "copy",
                    onClick: () => {
                        duplicateSection();
                    },
                },
                {
                    label: "Delete Section",
                    key: `${sectionCode}-delete`,
                    icon: "trash",
                    hasSeparator: true,
                    onClick: () => {
                        openPopup("delete-section", {
                            indication,
                            sectionCode,
                        });
                    },
                },
            ]}
            toggleButtonLabel={`Toggle ${
                section.type === "Asset" ? section.title_plural : section.title
            }`}
        >
            {({ ToggleButton, Menu }) => (
                <>
                    <NotAnchor
                        onClick={() => {
                            openPopup(
                                `add-or-edit-${
                                    section.type === "Asset"
                                        ? "list"
                                        : "standard"
                                }-section`,
                                {
                                    section,
                                    indication,
                                }
                            );
                        }}
                        className="strong toggle-sub"
                    >
                        {section.type === "Asset"
                            ? section.title_plural
                            : section.title}
                    </NotAnchor>
                    {allowEditing && ToggleButton}
                    <NotAnchor
                        onClick={() => {
                            applicationDispatch({
                                action: "ToggleOpenCloseSection",
                                code: section.code,
                                status: undefined,
                            });
                        }}
                        role="tab"
                        className="open-close-menu"
                    />
                    <ul className="list-inline">
                        {section.type === "Asset" && <li>List</li>}
                        {"is_indication" in section &&
                            section.is_indication && <li>Indication</li>}
                        <li>{section.items.length}</li>
                    </ul>

                    {allowEditing && Menu}

                    <DatapointList section={section} />
                    {allowEditing && (
                        <p className="link-strong">
                            <NotAnchor
                                onClick={() => {
                                    openPopup(
                                        "add-or-edit-question-datapoint",
                                        {
                                            indication,
                                            sectionCode,
                                        }
                                    );
                                }}
                            >
                                <i className="icon-plus-circle" /> Add Question
                            </NotAnchor>
                            <NotAnchor
                                onClick={() => {
                                    openPopup("add-or-edit-subhead-datapoint", {
                                        indication,
                                        sectionCode,
                                    });
                                }}
                            >
                                <i className="icon-plus-circle" /> Add Subhead
                            </NotAnchor>
                            <NotAnchor
                                onClick={() => {
                                    openPopup("add-or-edit-text-datapoint", {
                                        indication,
                                        sectionCode,
                                    });
                                }}
                            >
                                <i className="icon-plus-circle" /> Add Text
                            </NotAnchor>
                            <span className="text-right">
                                <NotAnchor
                                    onClick={() => {
                                        openPopup("section-actions-info");
                                    }}
                                >
                                    <i
                                        aria-hidden="true"
                                        className="icon-help"
                                    />{" "}
                                    <span className="hidden">More info</span>
                                </NotAnchor>
                            </span>
                        </p>
                    )}
                    <div
                        className="handle ui-sortable-handle"
                        {...attributes}
                        {...listeners}
                    />
                </>
            )}
        </MenuPopover>
    );
};

const SectionList: FC<{
    indication: boolean;
    extraStyles?: {
        borderBottom?: boolean;
        negativeMargin?: boolean;
        zIndex?: number;
    };
}> = ({ indication, extraStyles }) => {
    const sensors = useSensors(useSensor(PointerSensor));
    const { sdkBuilder } = useApi();
    const {
        applicationDispatch,
        allowEditing,
        applicationState: { productVersion },
    } = useApplication();

    const handleDragEnd = async (event: DragEndEvent) => {
        const { active, over } = event;
        if (!productVersion || !over || !active || active.id === over.id) {
            return;
        }
        const updateProdcutVersion = cloneDeep(productVersion);
        const oldIndex = updateProdcutVersion.schema.spec.sections.findIndex(
            (section) => section.code === active.id
        );
        const newIndex = updateProdcutVersion.schema.spec.sections.findIndex(
            (section) => section.code === over.id
        );
        const sections = updateProdcutVersion.schema.spec.sections;
        updateProdcutVersion.schema.spec.sections = orderSections(
            arrayMove(sections, oldIndex, newIndex)
        );
        await sdkBuilder.updateProductVersion({
            id: updateProdcutVersion.id,
            UpdateProductVersion: {
                schema: updateProdcutVersion.schema,
            },
        });
        applicationDispatch({
            action: "SetProductVersion",
            productVersion: updateProdcutVersion,
        });
    };

    if (!productVersion) {
        return <></>;
    }

    const sections = productVersion.schema.spec.sections.filter((section) =>
        includes(["Application", "Asset"], section.type)
    );

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
        >
            <ul
                className="list-drag static draggable color-primary"
                style={{
                    ...(extraStyles?.borderBottom && {
                        borderBottom: "1px solid var(--black_10)",
                    }),
                    ...(extraStyles?.negativeMargin && {
                        marginTop: "-19px",
                    }),
                    ...(extraStyles?.zIndex !== undefined && {
                        zIndex: extraStyles.zIndex,
                    }),
                }}
            >
                <SortableContext
                    items={sections.map((section) => ({
                        id: section.code,
                    }))}
                    strategy={verticalListSortingStrategy}
                    disabled={!allowEditing}
                >
                    {sections.map((section) => (
                        <SectionListItem
                            key={section.code}
                            sectionCode={section.code}
                            indication={indication}
                        />
                    ))}
                </SortableContext>
            </ul>
        </DndContext>
    );
};

const Main: FC = () => {
    const { openPopup } = usePopup();
    const {
        applicationDispatch,
        allowEditing,
        applicationState: { productVersion },
    } = useApplication();

    if (!productVersion) {
        return <></>;
    }

    const hasSections = productVersion.schema.spec.sections.length > 0;
    const indicationSections = productVersion.schema.spec.sections.filter(
        (section) => "is_indication" in section && section.is_indication
    );

    return (
        <ProductMainPane
            title="Application"
            titleIcon={!hasSections && !allowEditing ? "lock-thin" : undefined}
            layoutConfig={{
                mainLayout: !hasSections ? "center" : "wide",
                headerPosition: hasSections
                    ? "inside-content"
                    : "above-content",
            }}
            headerActions={
                hasSections
                    ? [
                          {
                              text: "Open All",
                              mobileText: "All",
                              onClick: () => {
                                  applicationDispatch({
                                      action: "OpenAllSections",
                                  });
                              },
                              icon: "chevron-square-down",
                          },
                          {
                              text: "Close All",
                              mobileText: "All",
                              onClick: () => {
                                  applicationDispatch({
                                      action: "CloseAllSections",
                                  });
                              },
                              icon: "chevron-square-up",
                          },
                      ]
                    : undefined
            }
            headerActionsOnSameLineAsTitle
        >
            {hasSections ? (
                <>
                    <SectionList
                        indication={true}
                        extraStyles={{
                            borderBottom: indicationSections.length > 0,
                            zIndex: 1,
                        }}
                    />
                    <div style={{ paddingBottom: "100px" }} />
                </>
            ) : (
                <div className="module-success inline">
                    <h2>
                        <i className="icon-list" /> No Sections Yet
                    </h2>
                    <p>
                        Application questions must be added inside at least one
                        section. Tip: break long applications into sections so
                        they feel easier to fill out.
                    </p>
                </div>
            )}
            {allowEditing && (
                <Footer>
                    <p className="link-btn">
                        <NotAnchor
                            onClick={() => {
                                openPopup("add-section-first-step");
                            }}
                        >
                            <i className="icon-plus-circle" /> Add Section
                        </NotAnchor>
                    </p>
                </Footer>
            )}
        </ProductMainPane>
    );
};

const AddOrEditQuestionDatapointPopup = () => {
    const { popupData } = usePopup();
    const sectionCode = popupData?.sectionCode as string | undefined;
    const [section, setSection] = useState<SectionV1>();
    const {
        applicationState: { productVersion },
    } = useApplication();

    useEffect(() => {
        const getSection = async () => {
            if (!productVersion || !popupData || !sectionCode) {
                return;
            }
            const existingSection = productVersion.schema.spec.sections.find(
                (section) => section.code === sectionCode
            );
            const existingSection_ = cloneDeep(existingSection);
            setSection(existingSection_);
        };
        getSection();
    }, [sectionCode, popupData, productVersion]);

    if (!section) {
        return;
    }

    return (
        <SharedAddOrEditQuestionDatapointPopup
            name="add-or-edit-question-datapoint"
            section={section}
        />
    );
};

const ApplicationBuilderPage: FC = () => (
    <Page>
        <ApplicationProvider>
            <Main />
            <AddSectionFirstStepPopup />
            <AddOrEditStandardSectionPopup />
            <DeleteSectionPopup />
            <ApplicationCheckList />
            <AddOrEditListSectionPopup />
            <SectionActionsInfoPopup />
            <AddOrEditQuestionDatapointPopup />
            <AddOrEditParagraphPopup />
            <AddOrEditHeadingPopup />
            <DeleteDatapointPopup />
            <MoveItemSectionPopup />
        </ApplicationProvider>
    </Page>
);

export default ApplicationBuilderPage;
