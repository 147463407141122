import {
    AnchorHTMLAttributes,
    DetailedHTMLProps,
    FC,
    MouseEventHandler,
    useCallback,
    MouseEvent,
} from "react";

interface NotAnchorInterface
    extends Omit<
        DetailedHTMLProps<
            AnchorHTMLAttributes<HTMLAnchorElement>,
            HTMLAnchorElement
        >,
        "href"
    > {
    onClick: MouseEventHandler<HTMLAnchorElement>;
}

/*Paul uses <a> where <button> is actually what he should use. He does so by setting the href to something generic like href="./"
and using the onClick (and event.preventDefault()) so that the <a> works like a button. Because of this, this is a common TypeScript
error that is received when trying to use Paul's code:

"The href attribute is required for an anchor to be keyboard accessible. Provide a valid, navigable address as the href value.
If you cannot provide an href, but still need the element to resemble a link, use a button and change it with appropriate styles.
Learn more: https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/HEAD/docs/rules/anchor-is-valid.md  jsx-a11y/anchor-is-valid"

Since we can't change all of Paul's <a>'s to <button>'s due to css styling, instead we created this custom <NotAnchor> component that
transparently inserts in the href="./" and calls event.preventDefault(). It also makes onClick required.
*/
const NotAnchor: FC<NotAnchorInterface> = ({ onClick, children, ...rest }) => {
    const onClick_ = useCallback(
        (e: MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault();
            return onClick(e);
        },
        [onClick]
    );

    return (
        <a onClick={onClick_} href="./" {...rest}>
            {children}
        </a>
    );
};

export default NotAnchor;
