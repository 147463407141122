import { AlertCategory, Page, usePage } from "components/Page";
import { FC, useCallback, useEffect, useState } from "react";
import StoreChecklistExtraPane from "./components/StoreChecklistExtraPane";
import { Footer, MainPane } from "components/MainPane";
import { useApi } from "contexts/ApiProvider";
import {
    storeSchema,
    StoreType,
    initialStoreDefaultValues,
    updateableStoreFields,
    UpdateableStoreType,
} from "./StoreForm";
import NotAnchor from "components/NotAnchor";
import pick from "lodash/pick";
import urlJoin from "url-join";
import CopyLinkButton from "components/CopyLinkButton";
import { Popup } from "components/Popup";
import { usePopup } from "contexts/PopupProvider";

import type { Store } from "@joshuins/builder";
import { createForm } from "components/Form";
import EditAuthSettingsPopup, {
    EDIT_AUTH_SETTINGS,
} from "../system/EditAuthSettingsPopup";

const { Form, useFormReturnRef } = createForm(storeSchema);

const StoreNameInfoPopup = () => (
    <Popup name="store-name-info">
        <header>
            <h2 className="color-primary font-medium">Store Name</h2>
        </header>
        <p className="last-child">
            This is how you will see your store in various places in Joshu. It
            is also used in when you bookmark a page.
        </p>
    </Popup>
);

const Main = () => {
    const { sdkBuilder, sdkSystem } = useApi();
    const { addAlertMessages, element, tryCatchAndRaiseError } = usePage();
    const [serverUrl, setServerUrl] = useState<string>();
    const { openPopup } = usePopup();

    const store = element as unknown as Store | undefined;
    const {
        formReturn: { reset, watch },
        formReturnRefCallback,
    } = useFormReturnRef();

    useEffect(() => {
        if (store === undefined || !reset) {
            return;
        }
        reset(store as StoreType);
    }, [store, reset]);

    useEffect(() => {
        const getServerUrl = async () => {
            const { server_url } = await sdkSystem.getSystemInfo();
            setServerUrl(server_url);
        };
        getServerUrl();
    }, [sdkSystem]);

    const onSubmit = useCallback(
        async (data: StoreType) => {
            if (!store) {
                return;
            }
            tryCatchAndRaiseError(async () => {
                await sdkBuilder.updateStore({
                    id: store.id,
                    UpdateStore: pick<UpdateableStoreType>(
                        data,
                        updateableStoreFields
                    ) as UpdateableStoreType,
                });

                addAlertMessages({
                    message: "Store settings have been saved!",
                    category: AlertCategory.SUCCESS,
                });
            });
        },
        [store, tryCatchAndRaiseError, sdkBuilder, addAlertMessages]
    );

    if (store === undefined || serverUrl === undefined) {
        return <></>;
    }

    return (
        <MainPane title="Settings">
            <Form
                onSubmit={onSubmit}
                className="form-overlay"
                defaultValues={initialStoreDefaultValues}
                formReturnRefCallback={formReturnRefCallback}
            >
                {({ register, formState: { errors } }) => (
                    <>
                        <section>
                            <div className="cols">
                                <p className="c50">
                                    <label htmlFor="sta">Channel </label>
                                    <input
                                        type="text"
                                        id="sta"
                                        className="input-lock"
                                        disabled
                                        {...register("channel")}
                                    />
                                </p>
                            </div>
                            <div className="cols">
                                <p className="c100">
                                    <label htmlFor="stb">
                                        Store Name{" "}
                                        <NotAnchor
                                            onClick={() => {
                                                openPopup("store-name-info");
                                            }}
                                            className="text-right"
                                        >
                                            <i
                                                aria-hidden="true"
                                                className="icon-help"
                                            />{" "}
                                            <span className="hidden">
                                                More info
                                            </span>
                                        </NotAnchor>
                                    </label>
                                    <input
                                        type="text"
                                        id="stb"
                                        style={{ width: "100%" }}
                                        {...register("name")}
                                    />
                                    {errors.name && (
                                        <label
                                            id="stb-error"
                                            className="error"
                                            htmlFor="stb"
                                        >
                                            {errors.name.message}
                                        </label>
                                    )}
                                </p>
                            </div>
                            <p>
                                <span className="cols">
                                    <label
                                        htmlFor="stc"
                                        style={{ color: "var(--black_80)" }}
                                    >
                                        Short Name for URL{" "}
                                    </label>
                                </span>
                                <span className="cols align-center">
                                    <span
                                        className="c50"
                                        style={{ width: "100%" }}
                                    >
                                        <input
                                            type="text"
                                            id="stc"
                                            className="input-lock"
                                            disabled
                                            {...register("url_slug")}
                                        />
                                    </span>
                                </span>
                                {serverUrl.length > 0 && watch && (
                                    <>
                                        <span
                                            style={{
                                                color: "var(--black_60)",
                                                fontSize: "12px",
                                            }}
                                        >
                                            Full path will be:{" "}
                                            {urlJoin(
                                                serverUrl,
                                                "store",
                                                watch("url_slug") ||
                                                    "<shortname>"
                                            )}
                                        </span>
                                        <span className="link-strong">
                                            <CopyLinkButton
                                                link={urlJoin(
                                                    serverUrl,
                                                    "store",
                                                    store.url_slug,
                                                    "auth",
                                                    "login"
                                                )}
                                                onClick={() => {
                                                    addAlertMessages({
                                                        message:
                                                            "Link copied to clipboard!",
                                                        category:
                                                            AlertCategory.SUCCESS,
                                                    });
                                                }}
                                            >
                                                <i className="icon-paperclips" />{" "}
                                                Copy Link
                                            </CopyLinkButton>
                                        </span>
                                    </>
                                )}
                            </p>
                        </section>
                        <Footer>
                            <p className="link-btn">
                                <NotAnchor
                                    className="inline"
                                    onClick={() =>
                                        openPopup(EDIT_AUTH_SETTINGS, {
                                            id: store.id.toString(),
                                        })
                                    }
                                >
                                    <i
                                        aria-hidden="true"
                                        className="icon-cog"
                                    />
                                    Configure Login Settings
                                </NotAnchor>
                            </p>
                            <p className="link-btn">
                                <button type="submit">
                                    <i
                                        aria-hidden="true"
                                        className="icon-check-circle-outline"
                                    />
                                    Save
                                    <span className="mobile-hide">
                                        {" "}
                                        Settings
                                    </span>
                                </button>
                            </p>
                        </Footer>
                    </>
                )}
            </Form>
        </MainPane>
    );
};

const StoreSettingsPage: FC = () => (
    <Page>
        <Main />
        <StoreChecklistExtraPane />
        <StoreNameInfoPopup />
        <EditAuthSettingsPopup />
    </Page>
);

export default StoreSettingsPage;
