import { FC } from "react";
import { QuoteStatus } from "@joshuins/insurance";

const UnderwritersBlockedQuotePage: FC<{
    status: QuoteStatus;
}> = ({ status }) => {
    let content;
    switch (status) {
        case QuoteStatus.QuoteStoreEdit: {
            content = (
                <>
                    <h1>
                        <i className="icon-edit"></i> Endorsement In Progress
                    </h1>
                    <p>
                        The broker is creating an endoresement request. <br />
                        Once they are done, the request will appear here.
                    </p>
                </>
            );
            break;
        }
    }
    return (
        <main id="content" className="wide text-center">
            <div className="module-success inline">{content}</div>
        </main>
    );
};

export { UnderwritersBlockedQuotePage };
