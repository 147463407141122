import { FC, useEffect, useState } from "react";
import { useInsuranceProcess } from "../InsuranceProcessProvider";
import { User } from "@joshuins/system";
import { useApi } from "contexts/ApiProvider";
import { fullName } from "utils/user";
import { getUserOrRaterValueByCode } from "utils/jo-types-and-values";
import keyBy from "lodash/keyBy";
import { useAuthUser } from "react-auth-kit";
import { UserAccountRole } from "@joshuins/auth";
import { useBranding } from "contexts/BrandingProvider";

const NotesSection: FC = () => {
    const { sdkSystem } = useApi();
    const {
        itemGetters: { getInsuranceProcess },
    } = useInsuranceProcess();
    const { quote: policy, quoteCodesAndValues } = getInsuranceProcess();
    const quoteCodesAndValuesByCode = keyBy(quoteCodesAndValues, "code");
    const reason = getUserOrRaterValueByCode(
        "status.CoverageActive.reason",
        quoteCodesAndValuesByCode
    );
    const [cancelingUser, setCancelingUser] = useState<User>();
    const authUser = useAuthUser();
    const currentUser = authUser() as User;
    const { storeUrlName } = useBranding();
    const inStore = storeUrlName !== undefined;

    useEffect(() => {
        const getCancelingUser = async () => {
            if (
                inStore ||
                !policy ||
                !policy.user_id ||
                (currentUser.role !== UserAccountRole.Admin &&
                    currentUser.role !== UserAccountRole.Underwriter)
            ) {
                return;
            }
            const cancelingUser_ = await sdkSystem.getUser({
                id: policy.user_id,
            });
            setCancelingUser(cancelingUser_);
        };
        getCancelingUser();
    }, [currentUser, inStore, policy, sdkSystem]);

    return (
        <>
            {policy && reason && (
                <>
                    <h2 className="m15">
                        <i aria-hidden="true" className="icon-paper" /> Notes
                    </h2>
                    <p className="size-14 color-black-100">
                        {cancelingUser && (
                            <>
                                <span className="color-black-60">
                                    Canceled by{" "}
                                </span>
                                {fullName(cancelingUser)}
                                <br />
                            </>
                        )}
                        <span className="color-black-60">
                            Reason for Cancellation:{" "}
                        </span>
                        {reason}
                        {policy.additional_details && (
                            <>
                                <br />
                                Additional details: {policy.additional_details}
                            </>
                        )}
                    </p>
                </>
            )}
        </>
    );
};

export { NotesSection };
