import { FC, useEffect, useState } from "react";
import { ReportTemplate } from "@joshuins/system";
import { Footer, MainPane } from "components/MainPane";
import MenuPopover from "components/MenuPopover";
import NotAnchor from "components/NotAnchor";
import { Page, usePage } from "components/Page";
import ExtraPane from "components/extra-panes/ExtraPane";
import { useApi } from "contexts/ApiProvider";
import { usePopup } from "contexts/PopupProvider";
import keyBy from "lodash/keyBy";
import maxBy from "lodash/maxBy";
import sortBy from "lodash/sortBy";
import { formatDatetimeString } from "utils/datetime";
import { unpaginate } from "components/sdk";
import AddOrEditReportTemplatePopup, {
    ADD_EDIT_REPORT_TEMPLATE,
} from "./AddOrEditReportTemplatePopup";
import DeleteReportTemplatePopup from "./DeleteReportTemplatePopup";
import ExportReportPopup from "./ExportReportPopup";

const ReportsExtraPane: FC = () => (
    <ExtraPane>
        <h3>
            <i className="icon-help" />
            Reports
        </h3>
        <p className="size-14">
            See your products&apos; and stores&apos; performance. Choose the
            time period, products, and stores you want to include and export
            data to bordereaus or other Excel spreadsheets.
        </p>
        <ul className="list-icon b">
            <li>
                <i className="icon-sheet" /> Export{" "}
                <span>
                    Send a bordereau to your reinsurer, or export to a
                    spreadsheet using custom templates to analyze your data
                    elsewhere
                </span>
            </li>
        </ul>
    </ExtraPane>
);

const TemplateList: FC<{
    reportTemplates: {
        reportTemplate: ReportTemplate;
        filename: string;
    }[];
}> = ({ reportTemplates }) => {
    const { openPopup } = usePopup();

    return (
        <ul className="list-strong">
            {reportTemplates.map(({ reportTemplate, filename }, index) => (
                <li
                    key={reportTemplate.id}
                    style={{ zIndex: reportTemplates.length - index }}
                >
                    <h2>
                        <i aria-hidden="true" className="icon-sheet" />
                        <NotAnchor
                            onClick={() => {
                                openPopup(ADD_EDIT_REPORT_TEMPLATE, {
                                    reportTemplateId: reportTemplate.id,
                                });
                            }}
                        >
                            {reportTemplate.name}
                        </NotAnchor>
                    </h2>
                    <ul className="list-inline text-right">
                        <li>
                            <NotAnchor
                                onClick={() => {
                                    openPopup("export-bordereau", {
                                        reportTemplate,
                                    });
                                }}
                            >
                                <i className="icon-download3" /> Export
                            </NotAnchor>
                        </li>
                        <MenuPopover
                            menuItems={[
                                {
                                    key: "download-template-file",
                                    label: "Download Template File",
                                    icon: "download3",
                                    downloadFileId: reportTemplate.file_id,
                                },
                                {
                                    key: "delete-file",
                                    label: "Delete File",
                                    icon: "trash",
                                    onClick: () => {
                                        openPopup("delete-report-template", {
                                            reportTemplate,
                                        });
                                    },
                                },
                            ]}
                        >
                            {({ ToggleButton, Menu }) => (
                                <>
                                    {Menu}
                                    {ToggleButton}
                                </>
                            )}
                        </MenuPopover>
                    </ul>
                    <ul className="list-inline wide">
                        <li>{filename}</li>
                        <li>{reportTemplate.type_}</li>
                        <li>
                            Modified{" "}
                            {formatDatetimeString(
                                reportTemplate.modified_at,
                                "numericDateOnly"
                            )}
                        </li>
                    </ul>
                </li>
            ))}
        </ul>
    );
};

const NoTemplatesMessage: FC = () => {
    return (
        <div className="module-success inline">
            <h2>
                <i aria-hidden="true" className="icon-sheet" /> No Templates Yet
            </h2>
            <p>
                Upload an Excel file with appropriate reference tags. Not sure
                where to start? Download our example templates!
            </p>
            <p className="link-strong text-center">
                {/* TODO: Connect up this link */}
                <a href="/oldadmin/reports/export/template_examples/download">
                    <i className="icon-download3" /> Download Examples
                </a>
            </p>
        </div>
    );
};

const Main = () => {
    const { sdkSystem } = useApi();
    const { openPopup } = usePopup();
    const { stateId } = usePage();
    const { sdkBuilder } = useApi();
    const [reportTemplates, setReportTemplates] = useState<
        {
            reportTemplate: ReportTemplate;
            filename: string;
        }[]
    >();

    useEffect(() => {
        const getReportTemplates = async () => {
            const reportTemplates_ = sortBy(
                await unpaginate(sdkSystem.allReportTemplates, {}),
                "display_order"
            );
            const getFileResponses = keyBy(
                await Promise.all(
                    reportTemplates_.map((reportTemplate) =>
                        sdkBuilder.getFile({ id: reportTemplate.file_id })
                    )
                ),
                "id"
            );
            setReportTemplates(
                reportTemplates_.map((reportTemplate) => ({
                    reportTemplate,
                    filename: getFileResponses[reportTemplate.file_id].filename,
                }))
            );
        };
        getReportTemplates();
    }, [sdkSystem, stateId, sdkBuilder]);

    if (reportTemplates === undefined) {
        return <></>;
    }

    return (
        <MainPane
            title="Reports"
            subHeader={{ text: "Export" }}
            layoutConfig={{
                mainLayout:
                    reportTemplates && reportTemplates.length > 0
                        ? "wide"
                        : "centerAndWide",
            }}
        >
            {reportTemplates && reportTemplates.length > 0 ? (
                <TemplateList reportTemplates={reportTemplates} />
            ) : (
                <NoTemplatesMessage />
            )}
            <Footer>
                <p className="link-btn">
                    <NotAnchor
                        onClick={() => {
                            const maxDisplayOrder = maxBy(
                                reportTemplates,
                                ({ reportTemplate }) =>
                                    reportTemplate.display_order
                            )?.reportTemplate.display_order;

                            openPopup(ADD_EDIT_REPORT_TEMPLATE, {
                                nextDisplayOrder: maxDisplayOrder
                                    ? maxDisplayOrder + 1
                                    : 1,
                            });
                        }}
                    >
                        <span className="mobile-hide">
                            <i className="icon-plus-circle" /> Add
                        </span>{" "}
                        Template
                    </NotAnchor>
                </p>
            </Footer>
        </MainPane>
    );
};

const ReportsPage: FC = () => (
    <Page>
        <Main />
        <ReportsExtraPane />
        <AddOrEditReportTemplatePopup />
        <ExportReportPopup />
        <DeleteReportTemplatePopup />
    </Page>
);

export default ReportsPage;
