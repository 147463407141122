import { RoleAwareLink } from "contexts/BrandingProvider";

const PasswordCheckEmailPage = () => (
    <main id="content">
        <form className="form-aside is-success">
            <div className="form-success">
                <h3>
                    <i aria-hidden="true" className="icon-envelope" />
                    Check your email!
                </h3>
                <p>We just sent you a link to reset your password.</p>
                <p>
                    <RoleAwareLink to="/auth/forgot-password">
                        <i aria-hidden="true" className="icon-refresh" /> Resend
                        Email
                    </RoleAwareLink>
                </p>
            </div>
        </form>
    </main>
);

export default PasswordCheckEmailPage;
