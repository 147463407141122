import { RouteObject } from "react-router-dom";
import SsoCallbackPage from "./SsoCallbackPage";
import AcceptInvitationPage from "./AcceptInvitationPage";
import LoginPage from "./LoginPage";
import LogoutPage from "./LogoutPage";
import PasswordCheckEmailPage from "./PasswordCheckEmailPage";
import PasswordForgotPage from "./PasswordForgotPage";
import PasswordResetPage from "./PasswordResetPage";
import RegistrationPage from "./RegistrationPage";
import RegistrationCheckEmailPage from "./RegistrationCheckEmailPage";
import RegistrationFinishPage from "./RegistrationFinishPage";
import AuthBase from "./AuthBase";
import { FC, useEffect } from "react";
import { useBranding } from "contexts/BrandingProvider";
import { useNavigate } from "components/DevAwareRoutingLink";

const AuthIndexRedirect: FC<{ redirectUrl?: string }> = ({ redirectUrl }) => {
    const { generateUrl, storeUrlName } = useBranding();
    const navigate = useNavigate();

    useEffect(() => {
        if (redirectUrl) {
            navigate(redirectUrl);
        } else if (storeUrlName !== undefined) {
            navigate(generateUrl("/auth/login"));
        }
    }, [storeUrlName, generateUrl, navigate, redirectUrl]);

    return <></>;
};

const authRoutes = ({
    includeRegisterRoutes = false,
    indexRedirectUrl,
}: {
    includeRegisterRoutes?: boolean;
    indexRedirectUrl?: string;
}): RouteObject[] => [
    {
        element: <AuthBase />,
        children: [
            {
                index: true,
                element: <AuthIndexRedirect redirectUrl={indexRedirectUrl} />,
            },
            {
                path: "login",
                element: <LoginPage registerLink={includeRegisterRoutes} />,
            },
            {
                path: "logout",
                element: <LogoutPage />,
            },
            {
                path: "accept-invitation",
                element: <AcceptInvitationPage />,
            },
            {
                path: "forgot-password",
                element: <PasswordForgotPage />,
            },
            {
                path: "password-check-email",
                element: <PasswordCheckEmailPage />,
            },
            {
                path: "reset-password",
                element: <PasswordResetPage />,
            },
            {
                path: "sso/:providerSlug/callback",
                element: <SsoCallbackPage />,
            },
            ...(includeRegisterRoutes
                ? [
                      /* The following 3 routes are not currently in use */
                      { path: "register", element: <RegistrationPage /> },
                      {
                          path: "register-check-email",
                          element: <RegistrationCheckEmailPage />,
                      },
                      {
                          path: "register/finish",
                          element: <RegistrationFinishPage />,
                      },
                  ]
                : []),
        ],
    },
];

export default authRoutes;
