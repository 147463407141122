import { FC, useCallback, useEffect, useState } from "react";
import { z } from "zod";
import pick from "lodash/pick";
import omit from "lodash/omit";
import { User } from "@joshuins/auth";
import { ProductVersion } from "@joshuins/builder";
import { fullName } from "utils/user";
import { createFormPopup } from "components/Popup";
import { useApi } from "contexts/ApiProvider";
import { usePopup } from "contexts/PopupProvider";
import { unpaginate } from "components/sdk";
import { usePage } from "components/Page";
import NotAnchor from "components/NotAnchor";
import { Select } from "components/ReactHookFormUncontrolledComponents";
import { MenuItem } from "components/Select";
import range from "lodash/range";

const ADD_OR_EDIT_UW = "add-or-update-underwriter" as const;

const newOrUpdateUnderwriterSchema = z.object({
    first_name: z.string().min(1, "This field is required"),
    last_name: z.string().min(1, "This field is required"),
    email: z.string().email(),
    authority_level: z.string().regex(/^\d+$/),
});

type NewOrUpdateUnderwriterType = z.infer<typeof newOrUpdateUnderwriterSchema>;

const {
    FormPopup: NewOrUpdateUnderwriterFormPopup,
    useFormReturnRef: useNewOrUpdateUnderwriterFormReturnRef,
} = createFormPopup(newOrUpdateUnderwriterSchema);

const NewAndUpdateUnderwriterPopup: FC = () => {
    const { sdkBuilder, sdkSystem } = useApi();
    const {
        formReturn: { reset },
        formReturnRefCallback,
    } = useNewOrUpdateUnderwriterFormReturnRef();
    const { popupData, isPopupOpen } = usePopup();
    const existingUserIdToUpdate = popupData?.userId as number;
    const [inputsDisabled, setInputsDisabled] = useState<boolean>(false);
    const {
        element,
        refreshState,
        addErrorMessage,
        addSuccessMessage,
        tryCatchAndRaiseError,
    } = usePage();
    const productVersion = element as unknown as ProductVersion | undefined;

    useEffect(() => {
        const getUser = async () => {
            if (!productVersion || !reset) {
                return;
            }

            if (!isPopupOpen(ADD_OR_EDIT_UW) || !existingUserIdToUpdate) {
                setInputsDisabled(false);
                return;
            }

            setInputsDisabled(true);

            const user = await sdkSystem.getUser({
                id: existingUserIdToUpdate,
            });
            const productUser = await sdkBuilder.getProductUser({
                product_id: productVersion.product_id,
                user_id: existingUserIdToUpdate,
            });
            reset({
                ...pick(user, ["email"]),
                first_name: user.first_name === null ? "" : user.first_name,
                last_name: user.last_name === null ? "" : user.last_name,
                authority_level: productUser.authority_level.toString(),
            });
        };
        getUser();
    }, [
        sdkBuilder,
        existingUserIdToUpdate,
        isPopupOpen,
        productVersion,
        sdkSystem,
        reset,
    ]);

    const onSubmit = useCallback<
        (data: NewOrUpdateUnderwriterType) => Promise<void>
    >(
        async (data) => {
            if (!productVersion) {
                return;
            }
            tryCatchAndRaiseError(async () => {
                if (existingUserIdToUpdate) {
                    await sdkSystem.updateUser({
                        id: existingUserIdToUpdate,
                        UpdateUser: omit(data, ["email", "authority_level"]),
                    });
                    await sdkBuilder.createProductUser({
                        NewProductUser: {
                            product_id: productVersion.product_id,
                            user_id: existingUserIdToUpdate,
                            authority_level: parseInt(data.authority_level),
                        },
                    });
                } else {
                    const existingUsers = await unpaginate(sdkSystem.allUsers, {
                        email: data.email,
                    });

                    let user: User;
                    if (existingUsers.length === 0) {
                        user = await sdkSystem.createUser({
                            CreateUser: {
                                ...omit(data, ["authority_level"]),
                                role: "Underwriter",
                                account_status: "Inactive",
                            },
                        });
                    } else {
                        user = existingUsers[0];
                        if (user.role === "Admin") {
                            addErrorMessage(
                                "This user is an Admin. Admins have permission to underwrite every product"
                            );
                            return;
                        } else if (user.role !== "Underwriter") {
                            addErrorMessage(
                                `${fullName(
                                    user
                                )} is not an underwriter and therefore cannot be assigned to products`
                            );
                            return;
                        }
                        await sdkSystem.updateUser({
                            id: user.id,
                            UpdateUser: omit(data, [
                                "email",
                                "authority_level",
                            ]),
                        });
                    }

                    const existingProductsUsers = await unpaginate(
                        sdkBuilder.allProductUsers,
                        {
                            product_id: productVersion.product_id,
                            user_id: user.id,
                        }
                    );

                    if (existingProductsUsers.length > 0) {
                        addErrorMessage(
                            "This user is already assigned as an underwriter to this product."
                        );
                        return;
                    } else {
                        await sdkBuilder.createProductUser({
                            NewProductUser: {
                                authority_level: parseInt(data.authority_level),
                                user_id: user.id,
                                product_id: productVersion.product_id,
                            },
                        });
                        await sdkSystem.updateUser({
                            id: user.id,
                            UpdateUser: {
                                account_status: "Pending",
                            },
                        });
                    }
                }
                addSuccessMessage(
                    `${
                        existingUserIdToUpdate ? "Saved" : "Added"
                    } underwriter for ${
                        productVersion.schema.metadata.name
                    } product with authority level ${data.authority_level}`
                );
            }, refreshState);
        },
        [
            productVersion,
            tryCatchAndRaiseError,
            refreshState,
            existingUserIdToUpdate,
            addErrorMessage,
            addSuccessMessage,
            sdkSystem,
            sdkBuilder,
        ]
    );

    return (
        <NewOrUpdateUnderwriterFormPopup
            name={ADD_OR_EDIT_UW}
            defaultValues={{
                first_name: "",
                last_name: "",
                email: "",
                authority_level: "",
            }}
            onSubmit={onSubmit}
            formReturnRefCallback={formReturnRefCallback}
            submitText="Save"
            overlayPopups={[
                <>
                    <h3>Authority Level</h3>
                    <p>
                        This setting helps you automate quotes. In your rater
                        XLS, you add the &quot;authority&quot; reference tag to
                        return a number from your rating calculations. If that
                        number is 0, the quote is displayed instantly. Less than
                        0, the quote will be automatically declined. If the
                        number is 1-5, it will be sent to the appropriate
                        underwriter.
                    </p>
                    <p>
                        For example, say the business revenue is a large factor
                        in pricing risk. If the revenue is $1-2M,
                        &quot;authority&quot; would be 0, and you would quote
                        instantly. If the revenue is less than $1M,
                        &quot;authority&quot; would be -1, and you would
                        decline. If it was $3-5M, &quot;authority&quot; would be
                        1, and would be automatically sent to underwriters with
                        authority of 1. And so on up to level 5.
                    </p>
                </>,
            ]}
        >
            {({ register, openOverlayPopup, control }) => (
                <>
                    <header>
                        <h2>Product Underwriter</h2>
                    </header>
                    <div className="cols">
                        <p className="c50">
                            <label htmlFor="ifk-156">First Name</label>
                            <input
                                type="text"
                                id="ifk-156"
                                aria-invalid="false"
                                {...register("first_name")}
                            />
                        </p>
                        <p className="c50">
                            <label htmlFor="ifl-156">Last Name</label>
                            <input
                                type="text"
                                id="ifl-156"
                                {...register("last_name")}
                            />
                        </p>
                    </div>
                    <p>
                        <label htmlFor="ifn-156">Email Address</label>
                        <input
                            type="email"
                            id="ifn-156"
                            disabled={inputsDisabled}
                            readOnly={inputsDisabled}
                            {...register("email")}
                        />
                    </p>
                    <div className="div-as-p">
                        <span className="label m15">
                            Authority Level{" "}
                            <NotAnchor
                                className="text-right"
                                onClick={() => openOverlayPopup(0)}
                            >
                                <i className="icon-help" />
                            </NotAnchor>
                        </span>
                        <Select
                            labelId="product-type-select-label"
                            id="ifv"
                            name="authority_level"
                            control={control}
                        >
                            {range(1, 6).map((level) => (
                                <MenuItem key={level} value={level.toString()}>
                                    {level}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                </>
            )}
        </NewOrUpdateUnderwriterFormPopup>
    );
};

export default NewAndUpdateUnderwriterPopup;
export { ADD_OR_EDIT_UW };
