import { AlertCategory, usePage } from "components/Page";
import { FC, PropsWithChildren, useCallback, useEffect } from "react";
import NotAnchor from "components/NotAnchor";
import { useApi } from "contexts/ApiProvider";
import { useRater } from "./RaterProvider";
import cloneDeep from "lodash/cloneDeep";
import pick from "lodash/pick";
import { createFormPopup } from "components/Popup";
import { z } from "zod";
import { usePopup } from "contexts/PopupProvider";
import { useWatch } from "react-hook-form";
import {
    ParameterPermissionV1,
    type LineItemSchemaV1,
} from "@joshuins/builder";
import { findLineItemIndex } from "utils/product-version";
import { CodePrefixes } from "./ApplicationProvider";
import { setCodePrefix } from "utils/string";

const addOrEditLineItemSchema = z.object({
    name: z.string().min(1, "This field is required"),
    code: z
        .object({
            value: z.string().min(1, "This field is required"),
            context: z.object({
                existingLineItemCodeToUpdate: z.union([
                    z.string(),
                    z.null(),
                    z.undefined(),
                ]),
                productVersion: z.union([z.any(), z.null()]),
            }),
        })
        .refine(
            ({ value, context }) => {
                const { existingLineItemCodeToUpdate, productVersion } =
                    context;
                if (existingLineItemCodeToUpdate === value) {
                    return true;
                } else if (productVersion) {
                    return findLineItemIndex(productVersion, value) < 0;
                }
            },
            {
                message: "Reference tag already in use",
                path: ["value"],
            }
        ),
    readWrite: z
        .object({
            underwriterVisible: z.boolean(),
            underwriterCustomizable: z.boolean(),
            storeUserVisible: z.boolean(),
            storeUserCustomizable: z.boolean(),
        })
        .refine(
            ({ underwriterVisible, underwriterCustomizable }) =>
                underwriterVisible || !underwriterCustomizable,
            {
                message:
                    "The line item must be visible to underwriters if it is editable by underwriters",
                path: ["underwriterVisible"],
            }
        )
        .refine(
            ({ storeUserVisible, storeUserCustomizable }) =>
                storeUserVisible || !storeUserCustomizable,
            {
                message:
                    "The line item must be visible to store users if it is editable by store users",
                path: ["storeUserVisible"],
            }
        )
        .refine(
            ({ storeUserVisible, underwriterVisible }) =>
                underwriterVisible || !storeUserVisible,
            {
                message:
                    "The line item must be visible to underwriters if it is visible store users",
                path: ["underwriterVisible"],
            }
        )
        .refine(
            ({ storeUserCustomizable, underwriterCustomizable }) =>
                underwriterCustomizable || !storeUserCustomizable,
            {
                message:
                    "The line item must be editable by underwriters if it is editable by store users",
                path: ["storeUserVisible"],
            }
        ),
});

type AddOrEditLineItemType = z.infer<typeof addOrEditLineItemSchema>;

const { FormPopup, useFormPopupReturn, useFormReturnRef } = createFormPopup(
    addOrEditLineItemSchema
);

const ReadWrite: FC<PropsWithChildren> = ({ children }) => {
    const { register, control, setValue } = useFormPopupReturn();

    const storeUserCustomizable = useWatch({
        control,
        name: "readWrite.storeUserCustomizable",
    });
    const storeUserVisible = useWatch({
        control,
        name: "readWrite.storeUserVisible",
    });
    const underwriterCustomizable = useWatch({
        control,
        name: "readWrite.underwriterCustomizable",
    });
    const underwriterVisible = useWatch({
        control,
        name: "readWrite.underwriterVisible",
    });

    useEffect(() => {
        if (underwriterCustomizable) {
            setValue("readWrite.underwriterVisible", true);
        } else {
            setValue("readWrite.storeUserCustomizable", false);
        }
    }, [underwriterCustomizable, setValue]);

    useEffect(() => {
        if (underwriterVisible) {
            setValue("readWrite.underwriterVisible", true);
        } else {
            setValue("readWrite.storeUserVisible", false);
            setValue("readWrite.underwriterCustomizable", false);
        }
    }, [underwriterVisible, setValue]);

    useEffect(() => {
        if (storeUserCustomizable) {
            setValue("readWrite.storeUserVisible", true);
            setValue("readWrite.underwriterCustomizable", true);
        }
    }, [storeUserCustomizable, setValue]);

    useEffect(() => {
        if (storeUserVisible) {
            setValue("readWrite.underwriterVisible", true);
        } else {
            setValue("readWrite.storeUserCustomizable", false);
        }
    }, [storeUserVisible, setValue]);

    return (
        <table className="check">
            <tbody>
                <tr>
                    <th></th>
                    <th>Visible To</th>
                    <th>Can Edit {children}</th>
                </tr>
                <tr>
                    <td>Underwriters</td>
                    <td>
                        <input
                            {...register("readWrite.underwriterVisible")}
                            type="checkbox"
                            id="iiiz-0"
                        />
                        <label htmlFor="iiiz-0">
                            <span className="hidden">Label text</span>
                        </label>
                    </td>
                    <td>
                        <input
                            {...register("readWrite.underwriterCustomizable")}
                            type="checkbox"
                            id="iijx-0"
                        />
                        <label htmlFor="iijx-0">
                            <span className="hidden">Label text</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td>Store Users</td>
                    <td>
                        <input
                            {...register("readWrite.storeUserVisible")}
                            type="checkbox"
                            id="iiiay-0"
                        />
                        <label htmlFor="iiiay-0">
                            <span className="hidden">Label text</span>
                        </label>
                    </td>
                    <td>
                        <input
                            {...register("readWrite.storeUserCustomizable")}
                            type="checkbox"
                            id="iijaw-0"
                        />
                        <label htmlFor="iijaw-0">
                            <span className="hidden">Label text</span>
                        </label>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

const AddOrEditLineItemPopup: FC = () => {
    const apiProviderContext = useApi();
    const { sdkBuilder } = apiProviderContext;
    const { popupData, isPopupOpen } = usePopup();
    const { addAlertMessages, tryCatchAndRaiseError } = usePage();
    const {
        formReturn: { reset },
        formReturnRefCallback,
    } = useFormReturnRef();
    const {
        allowEditing,
        raterDispatch,
        raterState: { productVersion },
    } = useRater();
    const lineItemToUpdate = popupData?.lineItem as
        | LineItemSchemaV1
        | undefined;

    const transformFormDataToApiData = useCallback(
        (formData: AddOrEditLineItemType): Omit<LineItemSchemaV1, "params"> => {
            const visibility = formData.readWrite.storeUserVisible
                ? ParameterPermissionV1.Users
                : formData.readWrite.underwriterVisible
                  ? ParameterPermissionV1.Underwriters
                  : ParameterPermissionV1.Nobody;
            const customizability = formData.readWrite.storeUserCustomizable
                ? ParameterPermissionV1.Users
                : formData.readWrite.underwriterCustomizable
                  ? ParameterPermissionV1.Underwriters
                  : ParameterPermissionV1.Nobody;
            return {
                ...pick(formData, ["name"]),
                code: formData.code.value,
                visibility,
                customizability,
            };
        },
        []
    );

    const transformApiDataToFormData = useCallback(
        (lineItem: LineItemSchemaV1): AddOrEditLineItemType | undefined => {
            if (!productVersion) {
                return;
            }

            return {
                code: {
                    value: lineItem.code,
                    context: {
                        existingLineItemCodeToUpdate: lineItem.code,
                        productVersion,
                    },
                },
                name: lineItem.name ?? "",
                readWrite: {
                    storeUserVisible:
                        lineItem.visibility === ParameterPermissionV1.Users,
                    storeUserCustomizable:
                        lineItem.customizability ===
                        ParameterPermissionV1.Users,
                    underwriterVisible:
                        lineItem.visibility !== ParameterPermissionV1.Nobody,
                    underwriterCustomizable:
                        lineItem.customizability !==
                        ParameterPermissionV1.Nobody,
                },
            };
        },
        [productVersion]
    );

    useEffect(() => {
        const getLineItem = async () => {
            if (
                !isPopupOpen("add-or-edit-line-item") ||
                !reset ||
                !productVersion
            ) {
                return;
            }
            if (lineItemToUpdate) {
                const formData = transformApiDataToFormData(lineItemToUpdate);
                if (formData) {
                    reset(formData);
                }
            } else {
                reset({
                    code: {
                        context: {
                            productVersion,
                        },
                    },
                });
            }
        };
        getLineItem();
    }, [
        sdkBuilder,
        transformApiDataToFormData,
        isPopupOpen,
        apiProviderContext,
        productVersion,
        reset,
        lineItemToUpdate,
    ]);

    const prefix = CodePrefixes.LINE_ITEM_PREFIX;

    const onSubmit = useCallback(
        async (data: AddOrEditLineItemType) => {
            tryCatchAndRaiseError(async () => {
                if (!productVersion) {
                    return;
                }
                const updateProdcutVersion = cloneDeep(productVersion);
                const lineItemData = transformFormDataToApiData(data);
                const lineItems = updateProdcutVersion.schema.spec.line_items;
                if (lineItemToUpdate) {
                    const index = findLineItemIndex(
                        updateProdcutVersion,
                        lineItemToUpdate.code
                    );
                    lineItems[index] = { ...lineItemToUpdate, ...lineItemData };
                } else {
                    const lineItem = {
                        ...lineItemData,
                        params: [],
                    };
                    lineItems.push(lineItem);
                }
                updateProdcutVersion.schema.spec.line_items = lineItems;
                await sdkBuilder.updateProductVersion({
                    id: updateProdcutVersion.id,
                    UpdateProductVersion: {
                        schema: updateProdcutVersion.schema,
                    },
                });
                raterDispatch({
                    action: "SetProductVersion",
                    productVersion: updateProdcutVersion,
                });
                raterDispatch({
                    action: "SetLineItems",
                    lineItems: lineItems.map((lineItem) => ({
                        lineItem,
                        parameters: lineItem.params,
                        open: true,
                    })),
                });
                addAlertMessages({
                    message: `Line item ${
                        lineItemToUpdate ? "updated" : "created"
                    } successfully`,
                    category: AlertCategory.SUCCESS,
                });
            });
        },
        [
            addAlertMessages,
            lineItemToUpdate,
            productVersion,
            raterDispatch,
            sdkBuilder,
            transformFormDataToApiData,
            tryCatchAndRaiseError,
        ]
    );

    return (
        <FormPopup
            name="add-or-edit-line-item"
            defaultValues={{
                name: "",
                code: {
                    value: "",
                    context: {
                        productVersion,
                    },
                },
                readWrite: {
                    underwriterVisible: false,
                    underwriterCustomizable: false,
                    storeUserVisible: false,
                    storeUserCustomizable: false,
                },
            }}
            submitText="Save"
            formReturnRefCallback={formReturnRefCallback}
            onSubmit={onSubmit}
            disabled={!allowEditing}
            overlayPopups={[
                // 0
                <>
                    <h3>Name</h3>
                    <p>
                        The way the line item will be shown in to store users or
                        product underwriters.
                    </p>
                </>,
                // 1
                <>
                    <h3>Reference Tag</h3>
                    <p>
                        The way the line item will be referred to internally
                        when connecting to the rater, etc.
                    </p>
                    <p>Letters, numbers, and _ allowed.</p>
                </>,
                // 2
                <>
                    <h3>Visible To / Can Edit</h3>
                    <p>
                        Visibility determines who can see this line item in
                        submissions, quotes, and reports. For example, you may
                        want extra fields that only underwriters can see for
                        additional underwriting questions.
                    </p>
                    <p>
                        Edit allows the selected role to change the value. For
                        example, if you want to allow brokers to request a
                        higher aggregate limit, you would check &quot;Can
                        Edit&quot; for Store Users.
                    </p>
                </>,
            ]}
        >
            {({ register, openOverlayPopup, formState: { errors } }) => (
                <>
                    <header>
                        <h2>Line Item</h2>
                    </header>
                    <p>
                        <label htmlFor="iik-0">
                            Name{" "}
                            <NotAnchor
                                className="text-right"
                                onClick={() => {
                                    openOverlayPopup(0);
                                }}
                            >
                                <i className="icon-help" />{" "}
                                <span className="hidden">More info</span>
                            </NotAnchor>
                        </label>
                        <input {...register("name")} type="text" id="iik-0" />
                        {errors.name && (
                            <label
                                id="iik-0-error"
                                className="error"
                                htmlFor="iik-0"
                            >
                                {errors.name.message as string}
                            </label>
                        )}
                    </p>
                    <div className="cols">
                        <p className="c50">
                            <label htmlFor="iil-0">
                                Reference Tag{" "}
                                <NotAnchor
                                    className="text-right"
                                    onClick={() => {
                                        openOverlayPopup(1);
                                    }}
                                >
                                    <i className="icon-help" />{" "}
                                    <span className="hidden">More info</span>
                                </NotAnchor>
                            </label>
                            <input
                                {...register("code.value", {
                                    onChange: (e) => setCodePrefix(prefix, e),
                                })}
                                type="text"
                                id="iil-0"
                                maxLength={30}
                            />
                            {errors.code?.value && (
                                <label
                                    id="iil-0-error"
                                    className="error"
                                    htmlFor="iil-0"
                                >
                                    {errors.code?.value.message as string}
                                </label>
                            )}
                        </p>
                    </div>
                    <ReadWrite>
                        <span className="text-right">
                            <NotAnchor
                                onClick={() => {
                                    openOverlayPopup(2);
                                }}
                            >
                                <i aria-hidden="true" className="icon-help" />{" "}
                                <span className="hidden">More info</span>
                            </NotAnchor>
                        </span>
                    </ReadWrite>
                </>
            )}
        </FormPopup>
    );
};

export { AddOrEditLineItemPopup };
