import classNames from "classnames";
import { useApi } from "contexts/ApiProvider";
import { useAuthUser } from "react-auth-kit";
import { FC, useEffect } from "react";
import { z } from "zod";
import type { User } from "@joshuins/system";
import { createFormPopup } from "./Popup";
import { usePopup } from "contexts/PopupProvider";

const updateMyUserSchema = z.object({
    first_name: z.string().min(1, "This field is required"),
    last_name: z.string().min(1, "This field is required"),
    email: z.string().email(),
});

type UpdateMyUserType = z.infer<typeof updateMyUserSchema>;

const { FormPopup, useFormReturnRef } = createFormPopup(updateMyUserSchema);

const MyAccountSettingsPopup: FC = () => {
    const { sdkSystem, sdkAuth } = useApi();
    const {
        formReturn: { reset },
        formReturnRefCallback,
    } = useFormReturnRef();
    const { isPopupOpen } = usePopup();
    const authUser = useAuthUser();
    const user = authUser() as User;

    useEffect(() => {
        const getUser = async () => {
            if (!reset || !isPopupOpen("account-settings")) {
                return;
            }
            const me = (await sdkAuth.me()).identity.User.user;
            reset({
                ...me,
                first_name: me.first_name === null ? "" : me.first_name,
                last_name: me.last_name === null ? "" : me.last_name,
            });
        };
        getUser();
    }, [sdkAuth, reset, isPopupOpen]);

    const onSubmit = async (data: Omit<UpdateMyUserType, "email">) => {
        await sdkSystem.updateUser({ id: user.id, UpdateUser: data });
    };

    return (
        <FormPopup
            name="account-settings"
            defaultValues={{
                first_name: "",
                last_name: "",
                email: "",
            }}
            onSubmit={onSubmit}
            formReturnRefCallback={formReturnRefCallback}
        >
            {({ register, formState: { errors } }) => (
                <>
                    <header>
                        <h2>Account Settings</h2>
                    </header>
                    <div className="cols">
                        <p
                            className={classNames("c50", {
                                "has-error": errors.first_name,
                            })}
                        >
                            <label htmlFor="sta">First Name</label>
                            <input
                                {...register("first_name")}
                                type="text"
                                id="sta"
                                required
                                autoComplete="given-name"
                            />
                            {errors.first_name && (
                                <label
                                    id="sta-error"
                                    className="error"
                                    htmlFor="sta"
                                >
                                    {errors.first_name.message}
                                </label>
                            )}
                        </p>
                        <p
                            className={classNames("c50", {
                                "has-error": errors.last_name,
                            })}
                        >
                            <label htmlFor="stb">Last Name</label>
                            <input
                                {...register("last_name")}
                                type="text"
                                id="stb"
                                required
                            />
                            {errors.last_name && (
                                <label
                                    id="stb-error"
                                    className="error"
                                    htmlFor="stb"
                                >
                                    {errors.last_name.message}
                                </label>
                            )}
                        </p>
                    </div>
                    <p>
                        <label htmlFor="stc">Email Address</label>
                        <input
                            {...register("email")}
                            type="text"
                            id="stc"
                            disabled
                            autoComplete="email"
                        />
                        <span className="scheme-info">
                            Emails are locked for security. To change it, please
                            contact us.
                        </span>
                    </p>
                </>
            )}
        </FormPopup>
    );
};

export default MyAccountSettingsPopup;
