import { Integration, IntegrationTypeInfo } from "@joshuins/system";
import { Link } from "components/DevAwareRoutingLink";
import { Footer, MainPane } from "components/MainPane";
import NotAnchor from "components/NotAnchor";
import { Page } from "components/Page";
import ExtraPane from "components/extra-panes/ExtraPane";
import { unpaginate } from "components/sdk";
import { useApi } from "contexts/ApiProvider";
import { usePopup } from "contexts/PopupProvider";
import keyBy from "lodash/keyBy";
import { FC, useEffect, useState } from "react";
import AddIntgerationPopup, {
    ADD_INTEGRATION_POPUP,
} from "./components/integrations/AddIntegrationPopup";
import SetupIntgerationPopup from "./components/integrations/SetupIntegrationPopup";

const IntegrationsExtraPane: FC = () => (
    <ExtraPane>
        <ul className="list-icon b">
            <li>
                <i className="icon-download-cloud" /> Integrations{" "}
                <span>
                    With Joshu, it&apos;s easy to integrate third-party data
                    APIs to enrich rating or pre-fill application questions.
                    With powerful data sources, insurers can get great data for
                    underwriting with fewer application questions, and deliver a
                    much simpler customer experience.
                </span>
            </li>
        </ul>
    </ExtraPane>
);

const Main: FC = () => {
    const { sdkSystem, sdkBuilder } = useApi();
    const { openPopup } = usePopup();
    const [integrationTypes, setIntegrationTypes] =
        useState<Record<IntegrationTypeInfo["name"], IntegrationTypeInfo>>();
    const [integrations, setIntegrations] = useState<Integration[]>();
    const [availableIntegrations, setAvailableIntegrations] = useState<
        IntegrationTypeInfo[]
    >([]);

    useEffect(() => {
        const getIntegrations = async () => {
            const integrations_ = await unpaginate(
                sdkSystem.allIntegrations,
                {}
            );
            setIntegrations(integrations_);

            const integrationsByType = keyBy(integrations_, "type_");
            const integrationTypes_ = await sdkSystem.getIntegrationTypes();
            setIntegrationTypes(keyBy(integrationTypes_, "name"));
            setAvailableIntegrations(
                integrationTypes_.filter(
                    (integrationType) =>
                        integrationsByType[integrationType.name] === undefined
                )
            );
        };
        getIntegrations();
    }, [sdkBuilder, sdkSystem]);

    if (!integrations || !integrationTypes) {
        return <></>;
    }

    return (
        <MainPane
            title="Integrations"
            {...(integrations.length === 0 && {
                layoutConfig: { mainLayout: "centerAndWide" },
            })}
        >
            {integrations.length > 0 ? (
                <ul className="list-products b">
                    {integrations.map((integration) => {
                        const integrationType =
                            integrationTypes[integration.type_];
                        return (
                            <li key={integration.id}>
                                <Link to={integration.id}>
                                    {integration.type_ === "gras" ? (
                                        <i
                                            aria-hidden="true"
                                            className="icon-icon"
                                        />
                                    ) : integration.type_ === "hazardhub" ? (
                                        <span className="img">
                                            <img
                                                src="../../static/images/content/hazardhub-logo.png"
                                                alt="Placeholder"
                                                width="40"
                                                height="40"
                                            />
                                        </span>
                                    ) : (
                                        <></>
                                    )}
                                    {integrationType.display_name}{" "}
                                    <span className="scheme-box color-apple">
                                        {" "}
                                        Connected
                                    </span>{" "}
                                    <span>{integrationType.description}</span>
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            ) : (
                <div className="module-success inline">
                    <h2>
                        <i aria-hidden="true" className="icon-upload-cloud" />
                        No Integrations Yet
                    </h2>
                </div>
            )}
            {availableIntegrations && (
                <Footer>
                    <p className="link-btn">
                        <NotAnchor
                            onClick={() => {
                                openPopup(ADD_INTEGRATION_POPUP, {
                                    availableIntegrations,
                                });
                            }}
                        >
                            <i
                                aria-hidden="true"
                                className="icon-plus-circle"
                            />
                            Add Integration
                        </NotAnchor>
                    </p>
                </Footer>
            )}
        </MainPane>
    );
};

const SystemIntegrationsPage: FC = () => (
    <Page>
        <Main />
        <IntegrationsExtraPane />
        <AddIntgerationPopup />
        <SetupIntgerationPopup />
    </Page>
);

export default SystemIntegrationsPage;
