import { FC, useEffect, useState } from "react";
import { Integration, IntegrationTypeInfo } from "@joshuins/system";
import { MainPane } from "components/MainPane";
import NotAnchor from "components/NotAnchor";
import { Page, usePage } from "components/Page";
import ExtraPane from "components/extra-panes/ExtraPane";
import { useApi } from "contexts/ApiProvider";
import { usePopup } from "contexts/PopupProvider";
import keyBy from "lodash/keyBy";
import SetupIntgerationPopup, {
    SETUP_INTEGRATION_POPUP,
} from "./components/integrations/SetupIntegrationPopup";

const SingleIntegrationExtraPane: FC = () => {
    return (
        <ExtraPane>
            <h3>
                <i aria-hidden="true" className="icon-help" /> Integrations
            </h3>
            <p>
                With Joshu, it&apos;s easy to integrate third-party data APIs to
                enrich rating or pre-fill application questions. With powerful
                integrations, insurers can get great data for underwriting with
                fewer application questions, and deliver a much simpler customer
                experience.
            </p>
        </ExtraPane>
    );
};

const Main: FC = () => {
    const { element } = usePage();
    const { openPopup } = usePopup();
    const integration = element as unknown as Integration | undefined;
    const { sdkSystem } = useApi();
    const [integrationTypes, setIntegrationTypes] =
        useState<Record<IntegrationTypeInfo["name"], IntegrationTypeInfo>>();

    useEffect(() => {
        const getIntegrationTypes = async () => {
            const integrationTypes_ = await sdkSystem.getIntegrationTypes();
            setIntegrationTypes(keyBy(integrationTypes_, "name"));
        };
        getIntegrationTypes();
    }, [sdkSystem]);

    if (!integration || !integrationTypes) {
        return <></>;
    }

    return (
        <MainPane
            title={integrationTypes[integration.type_].display_name}
            layoutConfig={{
                mainLayout: "center",
            }}
            subHeader={{
                pill: { text: "Connected", color: "pear" },
                rightAlignedElement: (
                    <NotAnchor
                        onClick={() => {
                            openPopup(SETUP_INTEGRATION_POPUP, {
                                integration: integration,
                                integrationType:
                                    integrationTypes[integration.type_],
                            });
                        }}
                    >
                        <i aria-hidden="true" className="icon-cog" /> Settings
                    </NotAnchor>
                ),
            }}
        >
            <div className="module-success inline">
                <p>Documentation / Examples Here</p>
            </div>
        </MainPane>
    );
};

const SingleIntegrationPage: FC = () => (
    <Page>
        <Main />
        <SingleIntegrationExtraPane />
        <SetupIntgerationPopup />
    </Page>
);

export default SingleIntegrationPage;
