import { useCallback, useEffect, useState } from "react";
import { Industry, Insured } from "@joshuins/insurance";
import { useApi } from "contexts/ApiProvider";
import { usePopup } from "contexts/PopupProvider";
import { Popup } from "components/Popup";
import { InsuredDetailsForm, NewInsuredType } from "./NewInsuredPage";
import { usePage } from "components/Page";
import { useAuthUser } from "react-auth-kit";
import { User, UserAccountRole } from "@joshuins/auth";

const EDIT_INSURED = "edit-insured" as const;

const EditInsuredPopup = () => {
    const { sdkInsurance } = useApi();
    const { tryCatchAndRaiseError } = usePage();
    const authUser = useAuthUser();
    const currentUser = authUser() as User;
    const { isPopupOpen, popupData } = usePopup();
    const popupData_ = popupData as unknown as {
        insured: Insured;
        location: string;
        industry: Industry;
    };
    const [insured, setInsured] = useState<Insured>();
    const [location, setLocation] = useState<string>();
    const [industry, setIndustry] = useState<Industry>();

    useEffect(() => {
        if (!isPopupOpen(EDIT_INSURED) || !popupData_ || !popupData_.insured) {
            return;
        }
        setInsured(popupData_.insured);
        setLocation(popupData_.location);
        setIndustry(popupData_.industry);
    }, [isPopupOpen, popupData_]);

    const onSubmit = useCallback(
        async (data: NewInsuredType) => {
            tryCatchAndRaiseError(async () => {
                if (insured && data) {
                    const updatedInsured = await sdkInsurance.updateInsured({
                        id: insured.id,
                        UpdateInsuredDetails: {
                            name: data.name,
                            website: data.website.value,
                        },
                    });
                    if (typeof data.address === "string") {
                        try {
                            const location = JSON.parse(data.address);
                            await sdkInsurance.updateInsuredData({
                                id: insured.id,
                                CreateInsuredData: {
                                    data: [
                                        {
                                            code: "insured.location",
                                            value: {
                                                V1: {
                                                    Plain: {
                                                        Location: location,
                                                    },
                                                },
                                            },
                                        },
                                    ],
                                },
                            });
                        } catch (error) {
                            /* empty */
                        }
                    }
                    setInsured(updatedInsured);
                    window.location.reload();
                }
            });
        },
        [insured, sdkInsurance, tryCatchAndRaiseError]
    );

    if (!insured) {
        return;
    }

    return (
        <Popup name={EDIT_INSURED}>
            <header
                style={{
                    borderBottom: "1px solid var(--black_10)",
                    paddingBottom: "8px ",
                }}
            >
                <h2>Insured Details</h2>
            </header>
            {currentUser.role === UserAccountRole.Admin && (
                <InsuredDetailsForm
                    insured={insured}
                    location={location}
                    industry={industry}
                    modalFooter={true}
                    submit={onSubmit}
                />
            )}
            {currentUser.role !== UserAccountRole.Admin && (
                <>
                    <p>Only administrators can update insured details.</p>
                    <p>Please contact your administraor.</p>
                </>
            )}
        </Popup>
    );
};

export default EditInsuredPopup;
export { EDIT_INSURED };
