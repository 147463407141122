import { FC, useCallback } from "react";
import keyBy from "lodash/keyBy";
import { z } from "zod";
import { IntegrationTypeInfo } from "@joshuins/system";
import { createFormPopup } from "components/Popup";
import { usePopup } from "contexts/PopupProvider";
import { SETUP_INTEGRATION_POPUP } from "./SetupIntegrationPopup";

const ADD_INTEGRATION_POPUP = "add-integration" as const;

const addIntegrationFormSchema = z.object({
    name: z.string().trim().min(1, "This field is required"),
});
type AddIntegrationType = z.infer<typeof addIntegrationFormSchema>;

const {
    FormPopup: SelectIntegrationFormType,
    useFormReturnRef: useDrcSetupFormReturnRef,
} = createFormPopup(addIntegrationFormSchema);

const AddIntgerationPopup: FC = () => {
    const { openPopup, popupData } = usePopup();
    const { formReturnRefCallback } = useDrcSetupFormReturnRef();
    const availableIntegrations = popupData?.availableIntegrations as
        | IntegrationTypeInfo[]
        | undefined;

    const onSubmit = useCallback(
        async (data: AddIntegrationType) => {
            if (!data.name) {
                return;
            }
            const integrationTypesByName = keyBy(availableIntegrations, "name");
            const integrationType = integrationTypesByName[data.name];
            openPopup(SETUP_INTEGRATION_POPUP, {
                integrationType,
            });
        },
        [availableIntegrations, openPopup]
    );

    if (!availableIntegrations) {
        return <></>;
    }

    return (
        <SelectIntegrationFormType
            name={ADD_INTEGRATION_POPUP}
            defaultValues={{
                name: "",
            }}
            submitText="Next"
            formReturnRefCallback={formReturnRefCallback}
            onSubmit={onSubmit}
            overlayPopups={[]}
        >
            {({ register }) => (
                <>
                    <header>
                        <h2>Select Integration </h2>
                        <p className="size-14">
                            Who do you want to receive data from?
                        </p>
                    </header>
                    <ul className="check strong">
                        {availableIntegrations.map((integrationType) => {
                            return (
                                <li key={integrationType.name}>
                                    <input
                                        {...register("name")}
                                        value={integrationType.name}
                                        type="radio"
                                        id={`fae-${integrationType.name}`}
                                    />
                                    <label
                                        htmlFor={`fae-${integrationType.name}`}
                                    >
                                        {integrationType.display_name}
                                        <span>
                                            {integrationType.description}
                                        </span>
                                    </label>
                                </li>
                            );
                        })}
                    </ul>
                </>
            )}
        </SelectIntegrationFormType>
    );
};

export default AddIntgerationPopup;
export { ADD_INTEGRATION_POPUP };
