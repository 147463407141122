import axios from "axios";
import omitBy from "lodash/omitBy";
import { fileHash } from "utils/file";
import groupBy from "lodash/groupBy";
import orderBy from "lodash/orderBy";
import { unpaginate } from ".";

import {
    BuilderApi as BuilderApi_,
    FileDesignation,
    NewStore,
    NewStoreTheme,
    ProductVersion,
    ProductVersionView,
    Store,
} from "@joshuins/builder";
import { MIN_DATE, parseISO8601 } from "utils/datetime";
import { includes } from "utils/array";
import { retry } from "utils/retry";
import { hideLoader, showLoader } from "paul/native-dom-manipulation";

const APPLICATION_SECTION_TYPES = ["Application", "Asset"] as const;

class BuilderApi extends BuilderApi_ {
    async uploadFile(
        file: File,
        designation: FileDesignation
    ): Promise<string> {
        const checksum = await fileHash(file);
        showLoader("Uploading...");

        const { id, upload_url } = await this.createFile({
            CreateFileRequest: {
                designation: designation,
                filename: file.name,
                mimetype: file.type,
                signature: {
                    Base64Sha256: checksum,
                },
            },
        });

        await axios.put(upload_url, file, {
            headers: {
                "content-type": file.type,
                "x-amz-checksum-sha256": checksum,
            },
        });
        hideLoader();
        return id;
    }

    public getProductExportWhenReady = (productExportId: string) =>
        retry(
            () =>
                this.getProductExport({
                    id: productExportId,
                }),
            (productExport) =>
                productExport.status === "Done" ||
                productExport.status === "Error",
            2000
        );

    async duplicateStore(
        originalStoreId: number,
        newStoreProps: NewStore
    ): Promise<Store> {
        /////////////////////////////////////////
        // get data needed to duplicate the store
        /////////////////////////////////////////

        const originalStore = await this.getStore({ id: originalStoreId });

        const originalStoreTheme = originalStore.theme_id
            ? await this.getStoreTheme({ id: originalStore.theme_id })
            : null;
        const originalStoreProducts = await unpaginate(this.allStoreProducts, {
            store_id: originalStore.id,
        });
        const originalStoreUsers = await unpaginate(this.allStoreUsers, {
            store_id: originalStore.id,
        });

        ///////////////////////
        // create the new store
        ///////////////////////

        const newStore = await this.createStore({ NewStore: newStoreProps });

        //////////////////////
        // duplicate the theme
        //////////////////////

        if (originalStoreTheme) {
            const newStoreThemeData: NewStoreTheme = omitBy(
                originalStoreTheme,
                (val, key) => key === "id"
            );

            const newStoreTheme = await this.createStoreTheme({
                NewStoreTheme: newStoreThemeData,
            });

            await this.updateStore({
                id: newStore.id,
                UpdateStore: { theme_id: newStoreTheme.id },
            });
        }

        /////////////////////////
        // duplicate the products
        /////////////////////////

        for (const originalStoreProduct of originalStoreProducts) {
            await this.createStoreProduct({
                NewStoreProduct: {
                    product_id: originalStoreProduct.product_id,
                    store_id: newStore.id,
                },
            });

            await this.createStoreProduct({
                NewStoreProduct: {
                    product_id: originalStoreProduct.product_id,
                    store_id: newStore.id,
                },
            });
        }

        ////////////////
        //copy the users
        ////////////////

        // NB! This will send emails to the exisitng users of the original store inviting
        // them to the new store
        for (const originalStoreUser of originalStoreUsers) {
            await this.createStoreUser({
                NewStoreUser: {
                    store_id: newStore.id,
                    user_id: originalStoreUser.user_id,
                    status: originalStoreUser.status,
                },
            });
        }

        return newStore;
    }

    async getProductVersions(query?: Partial<ProductVersionView>) {
        const groupedProductVersions = groupBy(
            await unpaginate(this.allProductVersions, query),
            "product_id"
        );

        const groupedAndSortedProductVersions = Object.values(
            groupedProductVersions
        ).reduce((previousValue, productVersions) => {
            previousValue.push(
                orderBy(
                    productVersions,
                    [
                        (productVersion) => {
                            const dateString = productVersion.last_updated;
                            return dateString
                                ? parseISO8601(dateString)
                                : MIN_DATE;
                        },
                        "id",
                    ],
                    ["desc", "desc"]
                )
            );

            return previousValue;
        }, [] as ProductVersionView[][]);

        const groupedAndEvenMoreSortedProductVersions = orderBy(
            groupedAndSortedProductVersions,
            [
                (productVersions) => {
                    const dateString = productVersions[0].last_updated;
                    return dateString ? parseISO8601(dateString) : MIN_DATE;
                },
                (productVersions) => productVersions[0].id,
            ],
            ["desc", "desc"]
        );

        return groupedAndEvenMoreSortedProductVersions;
    }

    async getLatestProductVersions(query?: Partial<ProductVersion>) {
        const productVersionsByGroup = await this.getProductVersions(query);
        return productVersionsByGroup.map((productVersions) => {
            const publishedindex = productVersions.findIndex(
                (productVersion) =>
                    productVersion.is_published === true &&
                    productVersion.is_archived === false
            );
            return productVersions[publishedindex !== -1 ? publishedindex : 0];
        });
    }

    async getProductVersionsOfProduct(productId: number) {
        const productVersions = await this.getProductVersions({
            product_id: productId,
        });
        return productVersions.length > 0 ? productVersions[0] : undefined;
    }

    async getLatestProductVersionOfProduct(productId: number) {
        const productVersions =
            await this.getProductVersionsOfProduct(productId);
        return productVersions && productVersions.length > 0
            ? productVersions[0]
            : undefined;
    }

    async canProductVersionBePublished(productVersion: ProductVersion) {
        if (!productVersion.schema.spec.rater) {
            return false;
        }

        let datapointCounter = 0;
        for (const section of this.getAllApplicationSections(productVersion)) {
            datapointCounter += section.items.length;
        }
        if (datapointCounter === 0) {
            return false;
        }

        const packets = productVersion.schema.spec.documents.packets;

        if (Object.keys(packets).length === 0) {
            // if this product version doesn't have any documents then we don't need to check
            // if it there are any non-ready (Error or Processing) document templates below, so we
            // can immediately return true since there's nothing to check
            return true;
        }

        const nonReadyDocumentTemplates = []; // TODO replace with errors in documents schema!
        if (nonReadyDocumentTemplates.length > 0) {
            return false;
        }

        return true;
    }

    async createDocumentTemplateFromFile(file: File) {
        const id = await this.uploadFile(file, "DocumentTemplate");
        return await this.createDocumentTemplate({
            NewDocumentTemplate: {
                file_id: id,
            },
        });
    }

    async getFileResponseFromDocumentTemplateId(documentTemplateId: string) {
        const documentTemplate = await this.getDocumentTemplate({
            id: documentTemplateId,
        });

        return await this.getFile({
            id: documentTemplate.file_id,
        });
    }

    getAllApplicationSections = (productVersion: ProductVersion) => {
        // filtering out Subjecticvities and BindQuestions
        return productVersion.schema.spec.sections.filter((section) =>
            includes(APPLICATION_SECTION_TYPES, section.type)
        );
    };
}

export default BuilderApi;
