import { usePopup } from "contexts/PopupProvider";
import { FC, Fragment, useEffect, useState } from "react";
import { useInsuranceProcess } from "../InsuranceProcessProvider";
import keyBy from "lodash/keyBy";
import urlJoin from "url-join";
import NotAnchor from "components/NotAnchor";
import classNames from "classnames";
import chunk from "lodash/chunk";
import ParameterInput, { ParameterData } from "./ParameterInput";
import { useApi } from "contexts/ApiProvider";
import { ConfirmationPopup, Popup } from "components/Popup";
import {
    FormulaValueV1,
    QuoteStatus,
    QuoteCodeAndUserValue,
    SubmissionFlow,
} from "@joshuins/insurance";
import { getModifiedParametersCodes, isParameterModified } from "./util";
import {
    getRawValueAsStringFromJoValue,
    wrapAsJoValue,
} from "utils/jo-types-and-values";
import { useBranding } from "contexts/BrandingProvider";
import { LexoRank } from "lexorank";
import sortByLexorank from "utils/lexorank";
import { useAuthUser } from "react-auth-kit";
import { User, UserAccountRole } from "@joshuins/system";
import { isBinder, isPolicy } from "utils/policies";
import MenuPopover, { MenuItem } from "components/MenuPopover";

const ResetPremiumPopup: FC = () => {
    const {
        itemGetters: { getInsuranceProcess },
        recalculateQuote,
    } = useInsuranceProcess();
    const { quoteCodesAndValues } = getInsuranceProcess();
    const quoteCodesAndValuesByCode = keyBy(quoteCodesAndValues, "code");
    const premium = quoteCodesAndValuesByCode["prm.premium"];

    const onSubmit = async () => {
        const resetPremium: QuoteCodeAndUserValue[] = [
            {
                code: "prm.premium",
                user_value: wrapAsJoValue({ Null: {} }),
                rank: LexoRank.parse(
                    sortByLexorank([...quoteCodesAndValues]).slice(-1)[0].rank
                )
                    .genNext()
                    .toString(),
            },
        ];
        recalculateQuote(resetPremium);
    };

    if (!premium) {
        return <></>;
    }

    return (
        <ConfirmationPopup
            name="restore-premium"
            submitText="Reset Premium"
            onSubmit={onSubmit}
        >
            <header>
                <h2>Are you sure?</h2>
                <p className="size-14">
                    This will reset the premium to{" "}
                    {getRawValueAsStringFromJoValue(premium.rater_value)}$, the
                    value calculated by the rater.
                </p>
            </header>
        </ConfirmationPopup>
    );
};

const ConditionValuesPopup: FC = () => {
    const apiProviderContext = useApi();
    const { sdkInsurance } = apiProviderContext;
    const { isPopupOpen } = usePopup();
    const {
        itemGetters: { getInsuranceProcess },
    } = useInsuranceProcess();
    const { quote } = getInsuranceProcess();
    const [conditionValues, setConditionValues] = useState<{
        [key: string]: FormulaValueV1;
    }>();

    useEffect(() => {
        const getConditionValues = async () => {
            if (!isPopupOpen("view-conditions-popup")) {
                return;
            }
            if (quote.id) {
                const quoteConditionValues =
                    await sdkInsurance.quoteConditionValues({
                        id: quote.id,
                    });
                setConditionValues(quoteConditionValues);
            }
        };
        getConditionValues();
    }, [quote, isPopupOpen, sdkInsurance]);

    return (
        <Popup name="view-conditions-popup">
            <pre id="condition-values-json" style={{ whiteSpace: "pre-wrap" }}>
                {JSON.stringify(conditionValues, undefined, 2)}
            </pre>
        </Popup>
    );
};

const DetailsSection: FC = () => {
    const { openPopup } = usePopup();
    const {
        itemGetters: {
            getInsuranceProcess,
            getPreviousQuoteCodesAndValues: getPreviousQuoteCodesAndValues,
        },
        pageIds: { quoteId: pageQuoteId },
        insuranceProcessState: { dirtyFields: dirtyFields },
    } = useInsuranceProcess();
    const { quote, productVersion, quoteCodesAndValues, submission } =
        getInsuranceProcess();
    const [globalParameters, setGlobalParameters] = useState<ParameterData[]>();
    const [modifiedParametersCodes, setModifiedParametersCodes] = useState<
        string[]
    >([]);
    const authUser = useAuthUser();
    const currentUser = authUser() as User;

    const { storeUrlName, generateUrl } = useBranding();
    const inStore = storeUrlName !== undefined;

    useEffect(() => {
        const getGlobalParameters = async () => {
            const quoteCodesAndValuesByCode = keyBy(
                quoteCodesAndValues,
                "code"
            );
            const productSchemaParameters =
                productVersion.schema.spec.global_params || [];

            const globalParameters_: ParameterData[] = [];
            for (const parameter of productSchemaParameters) {
                const matchingObj = quoteCodesAndValuesByCode[parameter.code];
                if (
                    matchingObj &&
                    matchingObj.code.split(".")[1] !== "authority"
                ) {
                    globalParameters_.push({
                        schema: parameter,
                        value: matchingObj,
                    });
                }
            }
            setGlobalParameters(globalParameters_);

            if (pageQuoteId && submission.flow === SubmissionFlow.Endorsement) {
                const prevQuoteData = await getPreviousQuoteCodesAndValues();
                setModifiedParametersCodes(
                    getModifiedParametersCodes(
                        quoteCodesAndValues,
                        prevQuoteData
                    )
                );
            }
        };
        getGlobalParameters();
    }, [
        getPreviousQuoteCodesAndValues,
        pageQuoteId,
        productVersion.schema.spec.global_params,
        quoteCodesAndValues,
        submission,
    ]);

    function showResetPremium(parameter: QuoteCodeAndUserValue) {
        return (
            quote.status === QuoteStatus.QuotePending &&
            parameter.code === "prm.premium" &&
            getRawValueAsStringFromJoValue(parameter.user_value)
        );
    }

    const menuItems: MenuItem[] = [];
    if (!inStore && quote.rater_file_id) {
        menuItems.push({
            label: "Rating",
            key: `${quote.id}-rating`,
            icon: "download3",
            downloadFileId: quote.rater_file_id,
            downloadFileName: `${productVersion.schema.metadata.name}_quote_${quote.id}.xlsx`,
        });
    }

    if (quote) {
        menuItems.push({
            label: "Application",
            key: `${quote.id}-app`,
            icon: "chevron-right",
            linkTo: generateUrl(
                urlJoin(
                    "/",
                    "submissions",
                    quote.submission_id.toString(),
                    "application"
                )
            ),
            hasSeparator: !inStore && quote.rater_file_id ? true : false,
        });
    }

    if (!inStore) {
        menuItems.push({
            label: "Condition Values",
            key: `${quote.id}-conditions`,
            icon: "cog",
            onClick: () => {
                openPopup("view-conditions-popup");
            },
            hasSeparator: true,
        });
    }

    if (!inStore) {
        menuItems.push({
            label: "Document Inputs",
            key: `${quote.id}-documents`,
            icon: "document",
            onClick: () => {
                openPopup("quote-document-inputs");
            },
        });
    }

    if (
        currentUser.role === UserAccountRole.Admin &&
        (isPolicy(quote) || isBinder(quote))
    ) {
        menuItems.push({
            label: "Policy Sync",
            key: `${quote.id}-psync`,
            icon: "css",
            linkTo: urlJoin(
                "/",
                "admin",
                "system",
                "policy-sync",
                quote.id.toString()
            ),
        });
    }

    return (
        <>
            <h2>
                <i aria-hidden="true" className="icon-shield" /> Policy Details
                <span>
                    <ul
                        className="list-plain box"
                        style={{ marginTop: "-20px" }}
                    >
                        <MenuPopover
                            key={`${quote.id}-menu`}
                            menuItems={menuItems}
                            component="li"
                        >
                            {({ ToggleButton, Menu }) => {
                                return (
                                    <>
                                        {ToggleButton}
                                        {Menu}
                                    </>
                                );
                            }}
                        </MenuPopover>
                    </ul>
                </span>
            </h2>

            {globalParameters && (
                <table className="table-a table-fixed">
                    <tbody>
                        {chunk(globalParameters, 4).map((chunk) => {
                            return (
                                <Fragment key={chunk[0].value.code}>
                                    <tr>
                                        {chunk.map((parameter) => (
                                            <th key={parameter.value.code}>
                                                {parameter.schema.name}
                                                {submission.flow ===
                                                    SubmissionFlow.Endorsement &&
                                                    isParameterModified(
                                                        dirtyFields,
                                                        parameter.value,
                                                        modifiedParametersCodes
                                                    ) && (
                                                        <span className="font-medium color-wine">
                                                            {" "}
                                                            Modified
                                                        </span>
                                                    )}
                                                {showResetPremium(
                                                    parameter.value
                                                ) && (
                                                    <span>
                                                        <NotAnchor
                                                            onClick={() =>
                                                                openPopup(
                                                                    "restore-premium"
                                                                )
                                                            }
                                                        >
                                                            {" "}
                                                            &middot; reset
                                                            <span className="mobile-hide">
                                                                {" "}
                                                                to{" "}
                                                                {getRawValueAsStringFromJoValue(
                                                                    parameter
                                                                        .value
                                                                        .rater_value
                                                                )}
                                                                $
                                                            </span>
                                                        </NotAnchor>
                                                    </span>
                                                )}
                                            </th>
                                        ))}
                                    </tr>
                                    <tr>
                                        {chunk.map((parameter) => (
                                            <td
                                                key={parameter.value.code}
                                                className={classNames(
                                                    "parameter-data",
                                                    {
                                                        "strong premium-parameter":
                                                            parameter.value
                                                                .code ===
                                                            "prm.premium",
                                                    }
                                                )}
                                            >
                                                <span className="th">
                                                    {parameter.schema.name}
                                                </span>
                                                <ParameterInput
                                                    key={parameter.value.code}
                                                    parameterData={parameter}
                                                />
                                            </td>
                                        ))}
                                    </tr>
                                </Fragment>
                            );
                        })}
                    </tbody>
                </table>
            )}
        </>
    );
};

export { DetailsSection, ConditionValuesPopup, ResetPremiumPopup };
