import { FC, useCallback, useEffect, useState } from "react";
import { useApi } from "contexts/ApiProvider";
import { usePopup } from "contexts/PopupProvider";
import { AlertCategory, usePage } from "components/Page";
import { ConfirmationPopup } from "components/Popup";
import { Brokerage } from "@joshuins/system";
import { unpaginate } from "components/sdk";

const DeleteAllOfficesPopup: FC = () => {
    const { sdkSystem } = useApi();
    const { addAlertMessages, refreshState, tryCatchAndRaiseError } = usePage();
    const { popupData, isPopupOpen } = usePopup();
    const [brokerageToDeleteOffices, setBrokerageToDeleteOffices] =
        useState<Brokerage>();
    const brokerageIdToDeleteOffices =
        popupData?.brokerageIdToDeleteOffices as number;

    useEffect(() => {
        const getUser = async () => {
            if (
                !isPopupOpen("delete-all-offices") ||
                !brokerageIdToDeleteOffices
            ) {
                return;
            }
            const brokerage_ = await sdkSystem.getBrokerage({
                id: brokerageIdToDeleteOffices,
            });
            setBrokerageToDeleteOffices(brokerage_);
        };
        getUser();
    }, [sdkSystem, brokerageIdToDeleteOffices, isPopupOpen]);

    const onSubmit = useCallback(async () => {
        if (!brokerageIdToDeleteOffices || !brokerageToDeleteOffices) {
            return;
        }
        const brokerageUsers = await unpaginate(sdkSystem.allUsers, {
            brokerage_id: brokerageIdToDeleteOffices,
        });
        const occupiedOfficeIds = brokerageUsers.map((user) => user.office_id);
        const offices = await unpaginate(sdkSystem.allOffices, {
            brokerage_id: brokerageIdToDeleteOffices,
        });

        for (const [index, office] of offices.entries()) {
            if (!occupiedOfficeIds.includes(office.id) && !office.is_default) {
                tryCatchAndRaiseError(async () => {
                    await sdkSystem.deleteOffice({ id: office.id });
                    addAlertMessages({
                        message: `${office.name} office has been deleted`,
                        category: AlertCategory.SUCCESS,
                    });
                });
            } else if (
                occupiedOfficeIds.includes(office.id) &&
                // alert only once
                index === 0 &&
                !office.is_default
            ) {
                addAlertMessages({
                    message:
                        "At least one of the associated offices has brokers assigned to it and cannot be deleted. Reassign people to another office, or contact Joshu support for further assistance",
                    category: AlertCategory.SUCCESS,
                });
            }
        }
        refreshState();
    }, [
        brokerageIdToDeleteOffices,
        brokerageToDeleteOffices,
        sdkSystem,
        refreshState,
        tryCatchAndRaiseError,
        addAlertMessages,
    ]);

    return (
        <ConfirmationPopup
            name="delete-all-offices"
            onSubmit={onSubmit}
            submitText="Delete Offices"
            mobileSubmitText="Delete"
        >
            {brokerageToDeleteOffices && (
                <>
                    <header>
                        <h2>Are you sure?</h2>
                        <p className="size-14">
                            All offices will be deleted, and cannot be undone.
                            The brokerage will not be affected, only offices are
                            deleted.
                        </p>
                    </header>
                </>
            )}
        </ConfirmationPopup>
    );
};

export default DeleteAllOfficesPopup;
