import {
    QuoteCodeAndValue,
    SubmissionCodeValueAndRank,
} from "@joshuins/insurance";
import { LexoRank } from "lexorank";

function sortByLexorank(
    array: QuoteCodeAndValue[] | SubmissionCodeValueAndRank[]
): QuoteCodeAndValue[] | SubmissionCodeValueAndRank[] {
    // Use array.sort() with a custom comparator function
    return array.sort((a, b) => {
        // Compare the lexorank property (rank in this case)
        if (LexoRank.parse(a.rank).compareTo(LexoRank.parse(b.rank)) < 0) {
            return -1; // a should come before b
        }
        if (LexoRank.parse(b.rank).compareTo(LexoRank.parse(a.rank)) < 0) {
            return 1; // b should come before a
        }
        return 0; // ranks are equal
    });
}

export default sortByLexorank;
