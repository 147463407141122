import { FC, useCallback, useEffect, useState } from "react";
import NotAnchor from "components/NotAnchor";
import { useApi } from "contexts/ApiProvider";
import classNames from "classnames";
import { usePopup } from "contexts/PopupProvider";
import { AlertCategory, usePage } from "components/Page";
import {
    PolicyNumberPool,
    ProductPolicyIdV1,
    ProductPolicyIdsV1,
    ProductVersion,
} from "@joshuins/builder";
import MenuPopover from "components/MenuPopover";
import { PolicyNumberAllocationV1 } from "@joshuins/system";
import { z } from "zod";
import { ConfirmationPopup, createFormPopup } from "components/Popup";
import axios from "axios";
import keyBy from "lodash/keyBy";
import { unpaginate } from "components/sdk";
import FormControl from "@mui/material/FormControl";
import { Select } from "components/ReactHookFormUncontrolledComponents";
import { MenuItem } from "components/Select";
import ListItemIcon from "@mui/material/ListItemIcon";
import { CodePrefixes } from "./components/ApplicationProvider";
import omit from "lodash/omit";
import { setCodePrefix } from "utils/string";
import { getMessageFromAxiosError } from "utils/axios-extras";

const DeletePolicyIdPopup: FC = () => {
    const { sdkBuilder } = useApi();
    const { element } = usePage();
    const { addAlertMessages, refreshElement } = usePage();
    const { popupData } = usePopup();
    const productVersion = element as unknown as ProductVersion | undefined;

    const policyId = popupData?.policyId as PolicyNumberAllocationV1;

    const onSubmit = useCallback(async () => {
        if (
            !policyId ||
            !productVersion ||
            !productVersion.schema.spec.policy_ids
        ) {
            return;
        }
        try {
            const policyIdsLookup = keyBy(
                productVersion.schema.spec.policy_ids?.Internal.ids,
                "code"
            );
            const deletedPolicyIdsLookup = omit(policyIdsLookup, [
                policyId.code,
            ]);
            productVersion.schema.spec.policy_ids.Internal.ids = Object.values(
                deletedPolicyIdsLookup
            );
            await sdkBuilder.updateProductVersion({
                id: productVersion.id,
                UpdateProductVersion: {
                    schema: productVersion.schema,
                },
            });
            addAlertMessages({
                message: `${policyId.code} policy id has been deleted`,
                category: AlertCategory.SUCCESS,
            });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                addAlertMessages({
                    message: getMessageFromAxiosError(error),
                    category: AlertCategory.ALERT,
                });
            } else {
                throw error;
            }
        } finally {
            refreshElement();
        }
    }, [
        policyId,
        productVersion,
        sdkBuilder,
        addAlertMessages,
        refreshElement,
    ]);

    return (
        <ConfirmationPopup
            name="delete-policy-id"
            onSubmit={onSubmit}
            submitText="Delete Policy Id"
            mobileSubmitText="Delete"
        >
            {policyId && (
                <>
                    <header>
                        <h2>Are you sure?</h2>
                        <p className="size-14">
                            This policy id will be deleted, and cannot be
                            undone.
                        </p>
                    </header>
                </>
            )}
        </ConfirmationPopup>
    );
};

const DeleteAllocationPopup: FC = () => {
    const { sdkBuilder } = useApi();
    const { element } = usePage();
    const { addAlertMessages, refreshElement } = usePage();
    const { popupData } = usePopup();
    const productVersion = element as unknown as ProductVersion | undefined;

    const allocation = popupData?.allocation as PolicyNumberAllocationV1;

    const onSubmit = useCallback(async () => {
        if (
            !allocation ||
            !productVersion ||
            !productVersion.schema.spec.policy_ids
        ) {
            return;
        }
        try {
            const allocationLookup = keyBy(
                productVersion.schema.spec.policy_ids?.Internal.allocations,
                "code"
            );
            const deletedAllocationLookup = omit(allocationLookup, [
                allocation.code,
            ]);
            productVersion.schema.spec.policy_ids.Internal.allocations =
                Object.values(deletedAllocationLookup);
            await sdkBuilder.updateProductVersion({
                id: productVersion.id,
                UpdateProductVersion: {
                    schema: productVersion.schema,
                },
            });
            addAlertMessages({
                message: `${allocation.code} allocation has been deleted`,
                category: AlertCategory.SUCCESS,
            });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                addAlertMessages({
                    message: getMessageFromAxiosError(error),
                    category: AlertCategory.ALERT,
                });
            } else {
                throw error;
            }
        } finally {
            refreshElement();
        }
    }, [
        allocation,
        productVersion,
        sdkBuilder,
        addAlertMessages,
        refreshElement,
    ]);

    return (
        <ConfirmationPopup
            name="delete-allocation"
            onSubmit={onSubmit}
            submitText="Delete Allocation"
            mobileSubmitText="Delete"
        >
            {allocation && (
                <>
                    <header>
                        <h2>Are you sure?</h2>
                        <p className="size-14">
                            This allocation will be deleted, and cannot be
                            undone.
                        </p>
                    </header>
                </>
            )}
        </ConfirmationPopup>
    );
};

const AddOrEditPolicyIdSchema = z.object({
    code: z.string().min(1, "This field is required"),
    condition: z.string().optional(),
    format: z.string().min(1, "This field is required"),
});

const {
    FormPopup: AddOrEditPolicyIdFormPopup,
    useFormReturnRef: useAddOrEditPolicyIdFormReturnRef,
} = createFormPopup(AddOrEditPolicyIdSchema);

interface PolicyIdDataInterface {
    policyId: ProductPolicyIdV1;
}
type AddOrEditPolicyIdType = z.infer<typeof AddOrEditPolicyIdSchema>;

const AddOrEditPolicyIdPopup: FC = () => {
    const { sdkSystem, sdkBuilder } = useApi();
    const { addAlertMessages, refreshElement, element } = usePage();
    const { popupData, isPopupOpen } = usePopup();
    const {
        formReturn: { reset },
        formReturnRefCallback,
    } = useAddOrEditPolicyIdFormReturnRef();
    const productVersion = element as unknown as ProductVersion | undefined;
    const popupData_ = popupData as unknown as PolicyIdDataInterface;

    useEffect(() => {
        const resetPolicyId = () => {
            if (
                !isPopupOpen("add-or-edit-policy-id") ||
                !popupData_?.policyId ||
                !reset
            ) {
                return;
            }
            const policyId = popupData_.policyId;
            reset({
                code: policyId.code,
                condition: policyId.condition || undefined,
                format: policyId.format,
            });
        };
        resetPolicyId();
    }, [
        sdkSystem,
        popupData_,
        isPopupOpen,
        reset,
        sdkBuilder.allPolicyNumberPools,
        addAlertMessages,
    ]);

    const onSubmit = useCallback(
        async (data: AddOrEditPolicyIdType) => {
            if (!productVersion) {
                return;
            }
            //  new policyId
            if (!popupData_?.policyId) {
                try {
                    // if policy_ids is null, i.e its the first allocation
                    if (!productVersion.schema.spec.policy_ids) {
                        const policyIds = {
                            Internal: {
                                ids: [
                                    {
                                        code: data.code,
                                        condition: data.condition || undefined,
                                        format: data.format,
                                    },
                                ],
                                allocations: [],
                            },
                        }; //as ProductPolicyIdsV1;
                        productVersion.schema.spec.policy_ids = policyIds;
                    } else {
                        productVersion.schema.spec.policy_ids?.Internal.ids.push(
                            {
                                code: data.code,
                                condition: data.condition || undefined,
                                format: data.format,
                            }
                        );
                    }
                    await sdkBuilder.updateProductVersion({
                        id: productVersion.id,
                        UpdateProductVersion: {
                            schema: productVersion.schema,
                        },
                    });

                    addAlertMessages({
                        message: `${data.code} has been added.`,
                        category: AlertCategory.SUCCESS,
                    });
                } catch (error) {
                    if (axios.isAxiosError(error)) {
                        addAlertMessages({
                            message: getMessageFromAxiosError(error),
                            category: AlertCategory.ALERT,
                        });
                    } else {
                        throw error;
                    }
                } finally {
                    refreshElement();
                }
            } else {
                // update allocation
                const policyId = popupData_.policyId;

                if (!productVersion.schema.spec.policy_ids) {
                    return;
                }
                try {
                    const policyIdLookup = keyBy(
                        productVersion.schema.spec.policy_ids?.Internal.ids,
                        "code"
                    );
                    policyIdLookup[policyId.code] = {
                        code: data.code,
                        condition: data.condition || undefined,
                        format: data.format,
                    };
                    productVersion.schema.spec.policy_ids.Internal.ids =
                        Object.values(policyIdLookup);
                    await sdkBuilder.updateProductVersion({
                        id: productVersion.id,
                        UpdateProductVersion: {
                            schema: productVersion.schema,
                        },
                    });
                    addAlertMessages({
                        message: `${policyId.code} has been updated`,
                        category: AlertCategory.SUCCESS,
                    });
                } catch (error) {
                    if (axios.isAxiosError(error)) {
                        addAlertMessages({
                            message: getMessageFromAxiosError(error),
                            category: AlertCategory.ALERT,
                        });
                    } else {
                        throw error;
                    }
                } finally {
                    refreshElement();
                }
            }
        },
        [
            productVersion,
            popupData_,
            sdkBuilder,
            addAlertMessages,
            refreshElement,
        ]
    );

    const policyIdPrefix = CodePrefixes.POLICY_ID;

    return (
        <AddOrEditPolicyIdFormPopup
            name="add-or-edit-policy-id"
            defaultValues={{
                code: "",
                condition: "",
                format: "",
            }}
            onSubmit={onSubmit}
            submitText="Save"
            mobileSubmitText="Save"
            formReturnRefCallback={formReturnRefCallback}
            disabled={productVersion?.is_published}
        >
            {({ register, formState: { errors } }) => (
                <>
                    <header>
                        <h2>Policy Id</h2>
                    </header>
                    <div className={"div-as-p"}>
                        <label htmlFor="ppb">Code</label>
                        <input
                            type="text"
                            id="ppb"
                            {...register("code")}
                            onChange={(e) => setCodePrefix(policyIdPrefix, e)}
                        />
                        {errors.code && (
                            <label
                                id="ppb-error"
                                className="error"
                                htmlFor="ppb"
                            >
                                {errors.code.message}
                            </label>
                        )}
                    </div>
                    <div className={"div-as-p"}>
                        <label htmlFor="ppc">Condition (Optional)</label>
                        <input
                            type="text"
                            id="ppc"
                            {...register("condition")}
                        />
                        {errors.condition && (
                            <label
                                id="ppc-error"
                                className="error"
                                htmlFor="ppc"
                            >
                                {errors.condition.message}
                            </label>
                        )}
                    </div>
                    <div className={"div-as-p"}>
                        <label htmlFor="ppf">Format</label>
                        <input type="text" id="ppf" {...register("format")} />
                        {errors.format && (
                            <label
                                id="ppf-input-error"
                                className="error"
                                htmlFor="ihl-0"
                            >
                                {errors.format.message as string}
                            </label>
                        )}
                    </div>
                </>
            )}
        </AddOrEditPolicyIdFormPopup>
    );
};

const AddOrEditAllocationSchema = z.object({
    code: z.string().min(1, "This field is required"),
    condition: z.string().optional(),
    test_number_pool: z.string().min(1, "This field is required"),
    prod_number_pool: z.string().min(1, "This field is required"),
});

const {
    FormPopup: AddOrEditAllocationFormPopup,
    useFormReturnRef: useAddOrEditAllocationFormReturnRef,
} = createFormPopup(AddOrEditAllocationSchema);

interface AllocationDataInterface {
    allocation: PolicyNumberAllocationV1;
}
type AddOrEditAllocationType = z.infer<typeof AddOrEditAllocationSchema>;

const AddOrEditAllocationPopup: FC = () => {
    const { sdkSystem, sdkBuilder } = useApi();
    const { addAlertMessages, refreshElement, element, tryCatchAndRaiseError } =
        usePage();
    const { popupData, isPopupOpen } = usePopup();
    const {
        formReturn: { reset },
        formReturnRefCallback,
    } = useAddOrEditAllocationFormReturnRef();
    const productVersion = element as unknown as ProductVersion | undefined;
    const [numberPools, setNumberPools] = useState<PolicyNumberPool[]>();

    const popupData_ = popupData as unknown as AllocationDataInterface;

    useEffect(() => {
        const resetAllocation = () => {
            if (
                !isPopupOpen("add-or-edit-allocation") ||
                !popupData_?.allocation ||
                !reset
            ) {
                return;
            }
            const allocation = popupData_.allocation;
            reset({
                code: allocation.code,
                condition: allocation.condition || undefined,
                test_number_pool: allocation.test_number_pool,
                prod_number_pool: allocation.prod_number_pool,
            });
        };
        resetAllocation();
        const getNumberPools = async () => {
            tryCatchAndRaiseError(async () => {
                const numberPools_ = await unpaginate(
                    sdkBuilder.allPolicyNumberPools,
                    {}
                );
                setNumberPools(numberPools_);
            });
        };
        getNumberPools();
    }, [
        sdkSystem,
        popupData_,
        isPopupOpen,
        reset,
        sdkBuilder.allPolicyNumberPools,
        addAlertMessages,
        tryCatchAndRaiseError,
    ]);

    const onSubmit = useCallback(
        async (data: AddOrEditAllocationType) => {
            if (!productVersion) {
                return;
            }
            //  new allocation
            if (!popupData_?.allocation) {
                try {
                    // if policy_ids is null, i.e its the first allocation / id
                    if (!productVersion.schema.spec.policy_ids) {
                        const policyIds = {
                            Internal: {
                                allocations: [
                                    {
                                        code: data.code,
                                        condition: data.condition || undefined,
                                        test_number_pool: data.test_number_pool,
                                        prod_number_pool: data.prod_number_pool,
                                    },
                                ],
                                ids: [],
                            },
                        } as ProductPolicyIdsV1;
                        productVersion.schema.spec.policy_ids = policyIds;
                    } else {
                        productVersion.schema.spec.policy_ids?.Internal.allocations.push(
                            {
                                code: data.code,
                                condition: data.condition || undefined,
                                test_number_pool: data.test_number_pool,
                                prod_number_pool: data.prod_number_pool,
                            }
                        );
                    }
                    await sdkBuilder.updateProductVersion({
                        id: productVersion.id,
                        UpdateProductVersion: {
                            schema: productVersion.schema,
                        },
                    });

                    addAlertMessages({
                        message: `${data.code} has been added.`,
                        category: AlertCategory.SUCCESS,
                    });
                } catch (error) {
                    if (axios.isAxiosError(error)) {
                        addAlertMessages({
                            message: getMessageFromAxiosError(error),
                            category: AlertCategory.ALERT,
                        });
                    } else {
                        throw error;
                    }
                } finally {
                    refreshElement();
                }
            } else {
                // update allocation
                const allocation = popupData_.allocation;

                if (!productVersion.schema.spec.policy_ids) {
                    return;
                }
                try {
                    const allocationLookup = keyBy(
                        productVersion.schema.spec.policy_ids?.Internal
                            .allocations,
                        "code"
                    );
                    allocationLookup[allocation.code] = {
                        code: data.code,
                        condition: data.condition || undefined,
                        test_number_pool: data.test_number_pool,
                        prod_number_pool: data.prod_number_pool,
                    };
                    productVersion.schema.spec.policy_ids.Internal.allocations =
                        Object.values(allocationLookup);
                    await sdkBuilder.updateProductVersion({
                        id: productVersion.id,
                        UpdateProductVersion: {
                            schema: productVersion.schema,
                        },
                    });
                    addAlertMessages({
                        message: `${allocation.code} has been updated`,
                        category: AlertCategory.SUCCESS,
                    });
                } catch (error) {
                    if (axios.isAxiosError(error)) {
                        addAlertMessages({
                            message: getMessageFromAxiosError(error),
                            category: AlertCategory.ALERT,
                        });
                    } else {
                        throw error;
                    }
                } finally {
                    refreshElement();
                }
            }
        },
        [
            productVersion,
            popupData_,
            sdkBuilder,
            addAlertMessages,
            refreshElement,
        ]
    );

    if (!numberPools || numberPools.length === 0) {
        return (
            <ConfirmationPopup
                name={"add-or-edit-allocation"}
                onSubmit={() => {}}
                submitText="Ok"
                mobileSubmitText="Ok"
            >
                <header>
                    <h2>No Number Pools To Add</h2>
                    <p>
                        In oreder to add allocations, first configure number
                        pools in the system settings
                    </p>
                </header>
            </ConfirmationPopup>
        );
    }
    const allocationPrefix = CodePrefixes.POLICY_ALLOCATION;

    return (
        <AddOrEditAllocationFormPopup
            name="add-or-edit-allocation"
            defaultValues={{
                code: "",
                condition: "",
                test_number_pool: "",
                prod_number_pool: "",
            }}
            onSubmit={onSubmit}
            submitText="Save"
            mobileSubmitText="Save"
            formReturnRefCallback={formReturnRefCallback}
            disabled={productVersion?.is_published}
        >
            {({ register, control, formState: { errors } }) => (
                <>
                    <header>
                        <h2>Allocation</h2>
                    </header>
                    <div className={"div-as-p"}>
                        <label htmlFor="ppb">Code</label>
                        <input
                            type="text"
                            id="ppb"
                            {...register("code")}
                            onChange={(e) => setCodePrefix(allocationPrefix, e)}
                        />
                        {errors.code && (
                            <label
                                id="ppb-error"
                                className="error"
                                htmlFor="ppb"
                            >
                                {errors.code.message}
                            </label>
                        )}
                    </div>
                    <div className={"div-as-p"}>
                        <label htmlFor="ppf">Condition (Optional)</label>
                        <input
                            type="text"
                            id="ppc"
                            {...register("condition")}
                        />
                        {errors.condition && (
                            <label
                                id="ppc-error"
                                className="error"
                                htmlFor="ppc"
                            >
                                {errors.condition.message}
                            </label>
                        )}
                    </div>
                    <div className={"div-as-p"}>
                        <label htmlFor="ppc">Test Number Pool</label>
                        <FormControl
                            fullWidth
                            error={!!errors.test_number_pool}
                            className={classNames({
                                "has-error": !!errors.test_number_pool,
                            })}
                        >
                            <Select
                                labelId="test-number-pool-select-label"
                                id="ihl-0"
                                name="test_number_pool"
                                control={control}
                            >
                                {numberPools.map((numberPool) => (
                                    <MenuItem
                                        key={numberPool.id}
                                        value={numberPool.id}
                                    >
                                        <ListItemIcon>
                                            <i
                                                aria-hidden="true"
                                                className="icon-bricks"
                                            />
                                        </ListItemIcon>
                                        {numberPool.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {errors.test_number_pool && (
                            <label
                                id="type_-input-error"
                                className="error"
                                htmlFor="ihl-0"
                            >
                                {errors.test_number_pool.message as string}
                            </label>
                        )}
                    </div>
                    <div className={"div-as-p"}>
                        <span className="label">Production Number Pool</span>
                        <FormControl
                            fullWidth
                            error={!!errors.prod_number_pool}
                            className={classNames({
                                "has-error": !!errors.prod_number_pool,
                            })}
                        >
                            <Select
                                labelId="test-number-pool-select-label"
                                id="ihl-0"
                                name="prod_number_pool"
                                control={control}
                            >
                                {numberPools.map((numberPool) => (
                                    <MenuItem
                                        key={numberPool.id}
                                        value={numberPool.id}
                                    >
                                        <ListItemIcon>
                                            <i
                                                aria-hidden="true"
                                                className="icon-bricks"
                                            />
                                        </ListItemIcon>
                                        {numberPool.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {errors.prod_number_pool && (
                            <label
                                id="ppd-error"
                                className="error"
                                htmlFor="ppd"
                            >
                                {errors.prod_number_pool.message}
                            </label>
                        )}
                    </div>
                </>
            )}
        </AddOrEditAllocationFormPopup>
    );
};

const PolicyId: FC<{
    policyId: ProductPolicyIdV1;
    index: number;
    zIndexOffset?: number;
}> = ({ policyId, index, zIndexOffset }) => {
    const { openPopup } = usePopup();
    const { element } = usePage();
    const productVersion = element as unknown as ProductVersion | undefined;

    if (
        !productVersion ||
        !productVersion.schema.spec.policy_ids?.Internal.ids
    ) {
        return;
    }

    return (
        <MenuPopover
            style={{
                ...(zIndexOffset !== undefined &&
                    productVersion.schema.spec.policy_ids.Internal.ids.length >
                        0 &&
                    index !== undefined && {
                        zIndex:
                            zIndexOffset +
                            productVersion.schema.spec.policy_ids.Internal.ids
                                .length -
                            index,
                    }),
            }}
            menuItems={[
                {
                    key: "delete-policy-id",
                    label: "Delete Policy Id",
                    icon: "trash",
                    className: productVersion.is_published ? "disabled" : "",
                    onClick: () => {
                        openPopup("delete-policy-id", {
                            policyId: policyId,
                        });
                    },
                },
            ]}
            toggleButtonLabel={`Toggle ${policyId.code}`}
        >
            {({ ToggleButton, Menu }) => (
                <>
                    <NotAnchor
                        className="toggle-sub"
                        onClick={() => {
                            openPopup("add-or-edit-policy-id", {
                                policyId: policyId,
                            });
                        }}
                    >
                        {policyId.code}
                    </NotAnchor>
                    {ToggleButton}
                    {Menu}
                </>
            )}
        </MenuPopover>
    );
};

const Allocation: FC<{
    allocation: PolicyNumberAllocationV1;
    index: number;
    zIndexOffset?: number;
}> = ({ allocation, index, zIndexOffset }) => {
    const { openPopup } = usePopup();
    const { element } = usePage();
    const productVersion = element as unknown as ProductVersion | undefined;

    if (
        !productVersion ||
        !productVersion.schema.spec.policy_ids?.Internal.allocations
    ) {
        return;
    }

    return (
        <MenuPopover
            style={{
                ...(zIndexOffset &&
                    productVersion.schema.spec.policy_ids.Internal.allocations
                        .length > 0 && {
                        zIndex:
                            zIndexOffset +
                            productVersion.schema.spec.policy_ids.Internal
                                .allocations.length -
                            index,
                    }),
            }}
            menuItems={[
                {
                    key: "delete-allocation",
                    label: "Delete Allocation",
                    icon: "trash",
                    className: productVersion.is_published ? "disabled" : "",
                    onClick: () => {
                        openPopup("delete-allocation", {
                            allocation: allocation,
                        });
                    },
                },
            ]}
            toggleButtonLabel={`Toggle ${allocation.code}`}
        >
            {({ ToggleButton, Menu }) => (
                <>
                    <NotAnchor
                        className="toggle-sub"
                        onClick={() => {
                            openPopup("add-or-edit-allocation", {
                                allocation: allocation,
                            });
                        }}
                    >
                        {allocation.code}
                    </NotAnchor>
                    {ToggleButton}
                    {Menu}
                </>
            )}
        </MenuPopover>
    );
};

const PolicyAllocationPage: FC = () => {
    const { element } = usePage();
    const productVersion = element as unknown as ProductVersion | undefined;
    const { openPopup } = usePopup();
    if (!productVersion) {
        return <></>;
    }

    return (
        <>
            <ul
                className="list-drag color-primary ui-sortable"
                role="tablist"
                style={{ marginTop: "-8px" }}
            >
                <li
                    className={classNames("strong has-sub", {
                        open: true,
                    })}
                    {...{
                        style: {
                            zIndex: productVersion.schema.spec.policy_ids
                                ?.Internal.allocations
                                ? productVersion.schema.spec.policy_ids.Internal
                                      .allocations.length + 1
                                : 1,
                        },
                    }}
                >
                    <NotAnchor role="tab" onClick={() => {}}>
                        Allocations
                    </NotAnchor>

                    <ul className="list-drag ui-sortable">
                        {productVersion.schema.spec.policy_ids?.Internal.allocations.map(
                            (allocation, index) => (
                                <Allocation
                                    key={allocation.code}
                                    allocation={allocation}
                                    index={index}
                                    zIndexOffset={
                                        productVersion.schema.spec.policy_ids
                                            ? productVersion.schema.spec
                                                  .policy_ids?.Internal
                                                  .allocations.length + 1
                                            : undefined
                                    }
                                />
                            )
                        )}
                    </ul>
                    <p className="link-strong">
                        <NotAnchor
                            onClick={() => {
                                openPopup("add-or-edit-allocation", {
                                    allocation: undefined,
                                });
                            }}
                            className={
                                productVersion.is_published ? "disabled" : ""
                            }
                        >
                            <i
                                aria-hidden="true"
                                className="icon-plus-circle"
                            />{" "}
                            Add Allocation
                        </NotAnchor>
                    </p>
                </li>
                <li
                    className={classNames("strong has-sub", {
                        open: true,
                    })}
                    {...{
                        style: {
                            zIndex: productVersion.schema.spec.policy_ids
                                ?.Internal.ids
                                ? productVersion.schema.spec.policy_ids.Internal
                                      .ids.length + 1
                                : 1,
                        },
                    }}
                >
                    <NotAnchor role="tab" onClick={() => {}}>
                        Ids
                    </NotAnchor>

                    <ul className="list-drag   ui-sortable">
                        {productVersion.schema.spec.policy_ids?.Internal.ids.map(
                            (policyId, index) => (
                                <PolicyId
                                    key={policyId.code}
                                    policyId={policyId}
                                    index={index}
                                    zIndexOffset={
                                        productVersion.schema.spec.policy_ids
                                            ? productVersion.schema.spec
                                                  .policy_ids?.Internal.ids
                                                  .length + 1
                                            : undefined
                                    }
                                />
                            )
                        )}
                    </ul>
                    <p className="link-strong">
                        <NotAnchor
                            onClick={() => {
                                openPopup("add-or-edit-policy-id", {
                                    PolicyId: undefined,
                                });
                            }}
                            className={
                                productVersion.is_published ? "disabled" : ""
                            }
                        >
                            <i
                                aria-hidden="true"
                                className="icon-plus-circle"
                            />{" "}
                            Add Policy Id
                        </NotAnchor>
                    </p>
                </li>
            </ul>
        </>
    );
};

export default PolicyAllocationPage;
export {
    AddOrEditAllocationPopup,
    AddOrEditPolicyIdPopup,
    DeleteAllocationPopup,
    DeletePolicyIdPopup,
};
