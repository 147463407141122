import axios from "axios";
import { FC, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useApi } from "contexts/ApiProvider";
import { BUILDER_PATH } from "globals";
import { FileUpload } from "components/FileUpload";
import NotAnchor from "components/NotAnchor";
import { AlertCategory, usePage } from "components/Page";
import { createFormPopup } from "components/Popup";
import { hideLoader, showLoader } from "paul/native-dom-manipulation";
import { ProductExportStatus, ProductVersionView } from "@joshuins/builder";
import urlJoin from "url-join";
import { z } from "zod";
import { usePopup } from "contexts/PopupProvider";
import { Select } from "components/ReactHookFormUncontrolledComponents";
import { MenuItem } from "components/Select";
import { FormControl } from "@mui/material";
import { getMessageFromAxiosError } from "utils/axios-extras";

const importProductVersionSchema = z.object({
    file: z.instanceof(File, { message: "No file selected" }),
    product_id: z.number().optional(),
});

type ImportProductVersionType = z.infer<typeof importProductVersionSchema>;

const {
    FormPopup: ImportProductVersionFormPopup,
    useFormReturnRef: useImportProductVersionFormReturnRef,
} = createFormPopup(importProductVersionSchema);

interface ImportProductInterface {
    productVersions: ProductVersionView[];
}

const ImportProductVersionPopup: FC = () => {
    const { sdkBuilder } = useApi();
    const { addAlertMessages } = usePage();
    const { formReturnRefCallback } = useImportProductVersionFormReturnRef();
    const navigate = useNavigate();
    const { popupData } = usePopup();
    const popupData_ = popupData as unknown as ImportProductInterface;
    const [productVersions, setProductVersions] = useState<
        ProductVersionView[]
    >([]);

    useEffect(() => {
        if (popupData_) {
            setProductVersions(popupData_.productVersions);
        }
    }, [popupData_]);

    const onSubmit = useCallback(
        async (formData: ImportProductVersionType) => {
            if (!(formData.file instanceof File)) {
                return;
            }
            try {
                const file_id = await sdkBuilder.uploadFile(
                    formData.file,
                    "ProductExport"
                );
                showLoader("Importing...");
                const productExport = await sdkBuilder.createProductExport({
                    CreateProductExport: {
                        Import: { file_id },
                    },
                });
                const [productExportPromise] =
                    sdkBuilder.getProductExportWhenReady(productExport?.id);
                const newProductExport = await productExportPromise;
                if (!newProductExport || !newProductExport.file_id) {
                    return;
                }

                if (
                    newProductExport.status === ProductExportStatus.Error &&
                    newProductExport.error
                ) {
                    addAlertMessages({
                        message: newProductExport.error,
                        category: AlertCategory.ALERT,
                    });
                    return;
                }
                if (
                    Object.keys(
                        newProductExport.schema_replacements.integrations
                    ).length > 0 ||
                    Object.keys(
                        newProductExport.schema_replacements.policy_number_pools
                    ).length > 0
                ) {
                    navigate({
                        pathname: urlJoin(
                            "/",
                            BUILDER_PATH,
                            "system",
                            "product-imports",
                            newProductExport.id.toString()
                        ),
                        search: `?product-id=${
                            formData.product_id
                                ? formData.product_id.toString()
                                : ""
                        }`,
                    });
                    return;
                }
                let product_id = formData.product_id;
                if (!product_id) {
                    const { id: productId } = await sdkBuilder.createProduct({
                        body: {},
                    });
                    product_id = productId;
                }

                const productVersion = await sdkBuilder.createProductVersion({
                    CreateProductVersion: {
                        Import: {
                            product_id,
                            product_export_id: newProductExport.id,
                            replacements: {
                                integrations: {},
                                policy_number_pools: {},
                            },
                        },
                    },
                });
                hideLoader();
                navigate(
                    urlJoin(
                        "/",
                        BUILDER_PATH,
                        "products",
                        productVersion.id.toString()
                    )
                );
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    addAlertMessages({
                        message: getMessageFromAxiosError(error),
                        category: AlertCategory.ALERT,
                    });
                } else {
                    throw error;
                }
            } finally {
                hideLoader();
            }
        },
        [addAlertMessages, navigate, sdkBuilder]
    );

    return (
        <ImportProductVersionFormPopup
            name="import-product-version"
            defaultValues={{}}
            submitText="Import"
            formReturnRefCallback={formReturnRefCallback}
            onSubmit={onSubmit}
            overlayPopups={[
                <>
                    <h3>File</h3>
                    <p>A product version export file</p>
                </>,
            ]}
        >
            {({ control, openOverlayPopup, formState: { errors } }) => {
                return (
                    <>
                        <header className="m30">
                            <h2>Import Product</h2>
                        </header>
                        <FileUpload
                            id="import-product-version-file"
                            name="file"
                            control={control}
                            fileDesignation="ProductExport"
                            error={errors.file?.message}
                            Label={
                                <span className="label">
                                    File{" "}
                                    <span className="text-right">
                                        10MB Limit{" "}
                                        <NotAnchor
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                openOverlayPopup(0);
                                            }}
                                        >
                                            <i
                                                aria-hidden="true"
                                                className="icon-help"
                                            />{" "}
                                            <span className="hidden">
                                                More info
                                            </span>
                                        </NotAnchor>
                                    </span>
                                </span>
                            }
                        />
                        {productVersions.length > 0 && (
                            <FormControl fullWidth>
                                <Select
                                    name="product_id"
                                    id="product_id"
                                    displayEmpty={true}
                                    control={control}
                                >
                                    <MenuItem key={"new"} value="">
                                        Create New Product
                                    </MenuItem>
                                    {productVersions.map((productVersion) => (
                                        <MenuItem
                                            key={productVersion.id}
                                            value={productVersion.product_id}
                                        >
                                            {productVersion.name}{" "}
                                            {
                                                productVersion.internal_version_name
                                            }
                                        </MenuItem>
                                    ))}
                                </Select>
                                {errors.product_id && (
                                    <label className="error" htmlFor="ifv">
                                        {errors.product_id.message}
                                    </label>
                                )}
                            </FormControl>
                        )}
                    </>
                );
            }}
        </ImportProductVersionFormPopup>
    );
};
export default ImportProductVersionPopup;
