/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import NotAnchor from "components/NotAnchor";
import { createFormPopup } from "components/Popup";
import { usePopup } from "contexts/PopupProvider";
import { FC, useCallback, useEffect } from "react";
import { Control, Controller } from "react-hook-form";
import { z } from "zod";

const addOrEditIndustrySchema = z.object({
    id: z.number(),
    code: z.string().min(1, "This field is required"),
    title: z.string().min(1, "This field is required"),
});
type AddOrEditIndustryType = z.infer<typeof addOrEditIndustrySchema>;

const { FormPopup, useFormReturnRef } = createFormPopup(
    addOrEditIndustrySchema
);

const AddOrEditIndustryPopup = () => {
    const { popupData } = usePopup();
    const {
        formReturn: { reset },
        formReturnRefCallback,
    } = useFormReturnRef();
    const onChange = popupData?.onChange as
        | ((...event: unknown[]) => void)
        | undefined;
    const industries = popupData?.industries as
        | AddOrEditIndustryType[]
        | undefined;
    const existingIndustryToUpdate = popupData?.industry as
        | AddOrEditIndustryType
        | undefined;

    useEffect(() => {
        const getIndustry = () => {
            if (!existingIndustryToUpdate || !reset) {
                return;
            }
            reset(existingIndustryToUpdate);
        };
        getIndustry();
    }, [existingIndustryToUpdate, reset]);

    const onSubmit = useCallback(
        async (data: AddOrEditIndustryType) => {
            if (!industries || !onChange) {
                return;
            }

            const industriesCopy = industries.slice(0);

            if (existingIndustryToUpdate) {
                const index = industries.findIndex(
                    (industry) => existingIndustryToUpdate.id === industry.id
                );
                if (index === -1) {
                    return;
                }

                industriesCopy[index] = data;
            } else {
                industriesCopy.push(data);
            }
            onChange(industriesCopy);
        },
        [existingIndustryToUpdate, industries, onChange]
    );

    return (
        <FormPopup
            name="add-or-edit-industry"
            defaultValues={{
                id: Math.random(),
                code: "",
                title: "",
            }}
            submitText="Save"
            onSubmit={onSubmit}
            formReturnRefCallback={formReturnRefCallback}
        >
            {({ register }) => (
                <>
                    <h2 className="m25">Class Of Business</h2>
                    <div className="cols">
                        <p className="c25">
                            <label htmlFor="ifw">Code</label>
                            <input
                                {...register("code")}
                                type="text"
                                id="ifw"
                                placeholder="0000..."
                            />
                        </p>
                        <p className="c75">
                            <label htmlFor="ifq">Label</label>
                            <input
                                {...register("title")}
                                type="text"
                                id="ifq"
                                placeholder="Class of Business..."
                            />
                        </p>
                    </div>
                </>
            )}
        </FormPopup>
    );
};

const IndustriesList: FC<{
    name: string;
    control: Control;
}> = ({ name, control }) => {
    const { openPopup } = usePopup();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { value, onChange } }) => {
                return (
                    <>
                        <table className="table-f m10">
                            <tbody>
                                <tr>
                                    <th>Code</th>
                                    <th>Label</th>
                                </tr>
                                {value.map(
                                    (industry: AddOrEditIndustryType) => (
                                        <tr
                                            key={industry.id}
                                            onClick={() => {
                                                openPopup(
                                                    "add-or-edit-industry",
                                                    {
                                                        onChange,
                                                        industries: value,
                                                        industry,
                                                    }
                                                );
                                            }}
                                            css={css`
                                                cursor: pointer;
                                                &:hover {
                                                    text-decoration: underline;
                                                }
                                            `}
                                        >
                                            <td>{industry.code}</td>
                                            <td>{industry.title}</td>
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </table>
                        <div>
                            <p className="link-strong">
                                <NotAnchor
                                    onClick={() => {
                                        openPopup("add-or-edit-industry", {
                                            onChange,
                                            industries: value,
                                        });
                                    }}
                                >
                                    <i
                                        aria-hidden="true"
                                        className="icon-plus-circle"
                                    />{" "}
                                    Add New Class
                                </NotAnchor>
                            </p>
                        </div>
                    </>
                );
            }}
        />
    );
};

export { IndustriesList, AddOrEditIndustryPopup };
