import { Page, TabInterface, usePage } from "components/Page";
import { CSSProperties, FC, useCallback, useEffect, useState } from "react";
import ExtraPane from "components/extra-panes/ExtraPane";
import NotAnchor from "components/NotAnchor";
import {
    DndContext,
    closestCenter,
    PointerSensor,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import {
    arrayMove,
    useSortable,
    SortableContext,
    verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import MenuPopover from "components/MenuPopover";
import { ConfirmationPopup, createFormPopup } from "components/Popup";
import { usePopup } from "contexts/PopupProvider";
import { z } from "zod";
import pick from "lodash/pick";
import startCase from "lodash/startCase";
import AddOrEditQuestionDatapointPopup from "./components/AddOrEditQuestionDatapointPopup";
import SharedDeleteDatapointPopup from "./components/DeleteDatapointPopup";

const BIND_QUESTION = "BindQuestion" as const;
const SUBJECTIVITY = "Subjectivity" as const;
const SUBJECTIVITY_CODE_LENGTH = 10 as const;

import type { SectionV1, SubjectivityV1 } from "@joshuins/builder";
import type { DragEndEvent } from "@dnd-kit/core";
import ProductMainPane from "./components/ProductMainPane";
import { randomString } from "utils/string";
import {
    findSectionIndex,
    getBindQuestionSections,
} from "utils/product-version";
import cloneDeep from "lodash/cloneDeep";
import { useApi } from "contexts/ApiProvider";
import {
    ApplicationProvider,
    useApplication,
} from "./components/ApplicationProvider";

interface BindRequirementsPageInterface {
    tabs?: TabInterface[];
}

interface BindRequirementsTabInterface {
    sectionType: string;
    noDatapointsText: string;
    addDatapointButtonText: string;
    addOrEditPopupName: string;
    deletePopupName: string;
    datapointMenuName: string;
}

const DatapointListItem: FC<{
    code: string;
    title: string;
    required: boolean;
    style?: CSSProperties;
    addOrEditPopupName: string;
    deletePopupName: string;
    datapointMenuName: string;
}> = ({
    code,
    title,
    required,
    style,
    addOrEditPopupName,
    deletePopupName,
    datapointMenuName,
}) => {
    const { openPopup } = usePopup();
    const {
        applicationState: { productVersion },
    } = useApplication();
    const { attributes, listeners, setNodeRef, transform, transition } =
        useSortable({ id: code });

    // prevents horizontal movement
    if (transform) {
        transform.x = 0;
    }

    if (!productVersion) {
        return <></>;
    }

    return (
        <MenuPopover
            key={code}
            ref={setNodeRef}
            style={{
                position: "relative",
                left: "0px",
                top: "0px",
                transform: CSS.Transform.toString(transform),
                transition,
                ...style,
            }}
            menuItems={[
                {
                    key: "edit",
                    label: `Edit ${startCase(datapointMenuName)}`,
                    icon: "edit",
                    onClick: () => {
                        openPopup(addOrEditPopupName, {
                            datapointCode: code,
                        });
                    },
                },
                {
                    key: "delete",
                    label: `Delete ${startCase(datapointMenuName)}`,
                    icon: "trash",
                    hasSeparator: true,
                    onClick: () => {
                        openPopup(deletePopupName, { datapointCode: code });
                    },
                },
            ]}
            toggleButtonLabel={`Toggle ${title}`}
        >
            {({ ToggleButton, Menu }) => (
                <>
                    <NotAnchor
                        className="toggle-sub"
                        onClick={() => {
                            openPopup(addOrEditPopupName, {
                                datapointCode: code,
                            });
                        }}
                    >
                        {title}
                        {required && (
                            <span className="text-right">Required</span>
                        )}
                    </NotAnchor>
                    {required}
                    {!productVersion.is_published && (
                        <>
                            {ToggleButton}
                            {Menu}
                        </>
                    )}
                    <div
                        className="handle ui-sortable-handle"
                        {...attributes}
                        {...listeners}
                    />
                </>
            )}
        </MenuPopover>
    );
};

const DeleteDatapointPopup: FC<{
    valueTypeLabel: string;
    popupName: string;
}> = ({ valueTypeLabel, popupName }) => {
    const { refreshState } = usePage();

    return (
        <SharedDeleteDatapointPopup
            valueTypeLabel={valueTypeLabel}
            popupName={popupName}
            onSubmitFinally={() => refreshState()}
        />
    );
};

const DeleteSubjectivityPopup = () => {
    const { popupData } = usePopup();
    const { sdkBuilder } = useApi();
    const { tryCatchAndRaiseError } = usePage();
    const subjectivityCode = popupData?.datapointCode as string;
    const {
        applicationDispatch,
        allowEditing,
        applicationState: { productVersion },
    } = useApplication();

    const onSubmit = useCallback(async () => {
        if (!productVersion || !popupData || !allowEditing) {
            return;
        }
        const updateProdcutVersion = cloneDeep(productVersion);
        tryCatchAndRaiseError(async () => {
            const subjectivityIndex =
                updateProdcutVersion.schema.spec.subjectivities.findIndex(
                    (subjectivity) => subjectivity.code === subjectivityCode
                );
            if (subjectivityIndex >= 0) {
                updateProdcutVersion.schema.spec.subjectivities.splice(
                    subjectivityIndex,
                    1
                );
                await sdkBuilder.updateProductVersion({
                    id: updateProdcutVersion.id,
                    UpdateProductVersion: {
                        schema: updateProdcutVersion.schema,
                    },
                });
                applicationDispatch({
                    action: "SetProductVersion",
                    productVersion: updateProdcutVersion,
                });
            }
        });
    }, [
        allowEditing,
        applicationDispatch,
        popupData,
        productVersion,
        sdkBuilder,
        subjectivityCode,
        tryCatchAndRaiseError,
    ]);

    return (
        <ConfirmationPopup
            name="delete-subjectivity"
            submitText="Delete Subjectivity"
            mobileSubmitText="Delete"
            onSubmit={onSubmit}
        >
            <header>
                <h2>Are you sure?</h2>
                <p className="size-14">
                    This Subjectivity will be deleted permanently. This action
                    cannot be undone.
                </p>
            </header>
        </ConfirmationPopup>
    );
};

const DeleteBindQuestionPopup: FC = () => (
    <DeleteDatapointPopup
        valueTypeLabel="question"
        popupName="delete-bind-question"
    />
);

const addOrEditSubjectivitySchema = z.object({
    title: z.string().min(1, "This field is required"),
    required: z.boolean(),
});
type AddOrEditSubjectivityType = z.infer<typeof addOrEditSubjectivitySchema>;

const { FormPopup, useFormReturnRef } = createFormPopup(
    addOrEditSubjectivitySchema
);

const AddOrEditSubjectivityPopup = () => {
    const { popupData } = usePopup();
    const { sdkBuilder } = useApi();
    const { tryCatchAndRaiseError } = usePage();
    const {
        formReturn: { reset },
        formReturnRefCallback,
    } = useFormReturnRef();
    const subjectivityCode = popupData?.datapointCode as string;
    const [subjectivity, setSubjectivity] = useState<SubjectivityV1>();
    const {
        applicationDispatch,
        applicationState: { productVersion },
    } = useApplication();

    useEffect(() => {
        const getDatapoint = async () => {
            if (!productVersion || !popupData || !reset) {
                return;
            }
            if (subjectivityCode) {
                const existingSubjectivity =
                    productVersion.schema.spec.subjectivities.find(
                        (subjectivity) => subjectivity.code === subjectivityCode
                    );
                if (existingSubjectivity) {
                    setSubjectivity(cloneDeep(existingSubjectivity));
                    reset(pick(existingSubjectivity, ["title", "required"]));
                }
            } else {
                setSubjectivity(undefined);
                reset({ title: "", required: true });
            }
        };
        getDatapoint();
    }, [subjectivityCode, popupData, productVersion, reset]);

    const onSubmit = useCallback(
        async (data: AddOrEditSubjectivityType) => {
            if (!productVersion) {
                return;
            }
            const updateProdcutVersion = cloneDeep(productVersion);
            tryCatchAndRaiseError(async () => {
                if (subjectivity) {
                    subjectivity.title = data.title;
                    subjectivity.required = data.required;
                    const subjectivityIndex =
                        updateProdcutVersion.schema.spec.subjectivities.findIndex(
                            (aSubjectivity) =>
                                aSubjectivity.code === subjectivity.code
                        );
                    updateProdcutVersion.schema.spec.subjectivities[
                        subjectivityIndex
                    ] = subjectivity;
                } else {
                    // Note that the source_type is set through the API in the database as "Subjectivity" automatically since the section is
                    // a "Subjectivity". See the insert() implementation of NewDatapoint in the API
                    updateProdcutVersion.schema.spec.subjectivities.push({
                        title: data.title,
                        code: `subj.${randomString(SUBJECTIVITY_CODE_LENGTH)}`,
                        required: true,
                    });
                }
                await sdkBuilder.updateProductVersion({
                    id: updateProdcutVersion.id,
                    UpdateProductVersion: {
                        schema: updateProdcutVersion.schema,
                    },
                });
                applicationDispatch({
                    action: "SetProductVersion",
                    productVersion: updateProdcutVersion,
                });
            });
        },
        [
            applicationDispatch,
            productVersion,
            sdkBuilder,
            subjectivity,
            tryCatchAndRaiseError,
        ]
    );

    if (!productVersion) {
        return <></>;
    }

    return (
        <FormPopup
            name="add-or-edit-subjectivity"
            defaultValues={{
                title: "",
                required: true,
            }}
            submitText="Save"
            formReturnRefCallback={formReturnRefCallback}
            onSubmit={onSubmit}
            disabled={productVersion.is_published}
        >
            {({ register }) => (
                <>
                    <h2 className="m25">Subjectivity</h2>
                    <p className="m25">
                        <input type="text" {...register("title")} />
                    </p>
                    <p className="check font-medium color-primary m10">
                        <input
                            id="subj-req"
                            type="checkbox"
                            {...register("required")}
                        />
                        <label htmlFor="subj-req" className="small">
                            Required
                        </label>
                    </p>
                </>
            )}
        </FormPopup>
    );
};

const BindRequirementsTab: FC<BindRequirementsTabInterface> = ({
    sectionType,
    noDatapointsText,
    addDatapointButtonText,
    addOrEditPopupName,
    deletePopupName,
    datapointMenuName,
}) => {
    const { openPopup } = usePopup();
    const { sdkBuilder } = useApi();
    const {
        applicationDispatch,
        applicationState: { productVersion },
    } = useApplication();
    const sensors = useSensors(useSensor(PointerSensor));

    const handleDragEnd = async (event: DragEndEvent) => {
        const { active, over } = event;

        if (
            !over ||
            !active ||
            active.id === over.id ||
            !productVersion ||
            !sectionType
        ) {
            return;
        }
        const updateProdcutVersion = cloneDeep(productVersion);
        if (sectionType === BIND_QUESTION) {
            const sections = getBindQuestionSections(updateProdcutVersion);
            if (sections.length === 0) {
                return;
            }
            const section = cloneDeep(sections[0]);
            const sectionIndex = findSectionIndex(
                updateProdcutVersion,
                section.code
            );

            const oldIndex = section.items.findIndex(
                (datapoint) =>
                    "Datapoint" in datapoint &&
                    datapoint.Datapoint.code === active.id
            );
            const newIndex = section.items.findIndex(
                (datapoint) =>
                    "Datapoint" in datapoint &&
                    datapoint.Datapoint.code === over.id
            );
            section.items = arrayMove(section.items, oldIndex, newIndex);
            updateProdcutVersion.schema.spec.sections[sectionIndex] = section;
            await sdkBuilder.updateProductVersion({
                id: updateProdcutVersion.id,
                UpdateProductVersion: {
                    schema: updateProdcutVersion.schema,
                },
            });
            applicationDispatch({
                action: "SetProductVersion",
                productVersion: updateProdcutVersion,
            });
        } else {
            const subjectivities =
                updateProdcutVersion.schema.spec.subjectivities;
            const oldIndex = subjectivities.findIndex(
                (subjectivity) => subjectivity.code === active.id
            );
            const newIndex = subjectivities.findIndex(
                (subjectivity) => subjectivity.code === over.id
            );
            updateProdcutVersion.schema.spec.subjectivities = arrayMove(
                subjectivities,
                oldIndex,
                newIndex
            );
        }
        await sdkBuilder.updateProductVersion({
            id: updateProdcutVersion.id,
            UpdateProductVersion: {
                schema: updateProdcutVersion.schema,
            },
        });
        applicationDispatch({
            action: "SetProductVersion",
            productVersion: updateProdcutVersion,
        });
    };

    if (!productVersion || !sectionType) {
        return <></>;
    }
    let codesAndTitles: { code: string; title: string; required: boolean }[] =
        [];

    if (sectionType === BIND_QUESTION) {
        const section = getBindQuestionSections(productVersion)[0];
        if (!section) {
            return;
        }

        codesAndTitles = section.items.map((item, index) => ({
            code: "Datapoint" in item ? item.Datapoint.code : `code_${index}`,
            title: "Datapoint" in item ? item.Datapoint.title : "No title",
            required: "Datapoint" in item ? item.Datapoint.required : true,
        }));
    } else {
        codesAndTitles = productVersion.schema.spec.subjectivities.map(
            (datapoint) => ({
                code: datapoint.code,
                title: datapoint.title,
                required: datapoint.required,
            })
        );
    }

    return (
        <div className="module-tabs-content">
            <div>
                {codesAndTitles.length > 0 ? (
                    <DndContext
                        sensors={sensors}
                        collisionDetection={closestCenter}
                        onDragEnd={handleDragEnd}
                    >
                        <ul className="list-drag">
                            <SortableContext
                                items={codesAndTitles.map((datapoint) => ({
                                    id: datapoint.code,
                                }))}
                                strategy={verticalListSortingStrategy}
                                disabled={productVersion.is_published}
                            >
                                {codesAndTitles.map(
                                    ({ code, title, required }, index) => (
                                        <DatapointListItem
                                            key={code}
                                            code={code}
                                            required={required}
                                            title={title}
                                            style={{
                                                zIndex:
                                                    codesAndTitles.length -
                                                    index,
                                            }}
                                            addOrEditPopupName={
                                                addOrEditPopupName
                                            }
                                            deletePopupName={deletePopupName}
                                            datapointMenuName={
                                                datapointMenuName
                                            }
                                        />
                                    )
                                )}
                            </SortableContext>
                        </ul>
                    </DndContext>
                ) : (
                    <p className="text-center size-14 color-black-40">
                        {noDatapointsText}
                    </p>
                )}
                {!productVersion.is_published && (
                    <p className="link-strong hr">
                        <NotAnchor
                            onClick={() => {
                                openPopup(addOrEditPopupName, {});
                            }}
                        >
                            <i
                                aria-hidden="true"
                                className="icon-plus-circle"
                            />{" "}
                            {addDatapointButtonText}
                        </NotAnchor>
                    </p>
                )}
            </div>
        </div>
    );
};

const Main = () => {
    const { activeTab } = usePage();
    const activeTab_ = activeTab();
    const {
        applicationState: { productVersion },
    } = useApplication();

    if (!activeTab_ || !productVersion) {
        return <></>;
    }

    return (
        <ProductMainPane title="Bind Requirements">
            {(() => {
                switch (activeTab_.path) {
                    case "subjectivities":
                        return (
                            <BindRequirementsTab
                                sectionType={SUBJECTIVITY}
                                noDatapointsText="No subjectivities yet"
                                addDatapointButtonText="Add Subjectivity"
                                deletePopupName="delete-subjectivity"
                                addOrEditPopupName="add-or-edit-subjectivity"
                                datapointMenuName="subjectivity"
                            />
                        );
                    case "supporting-information":
                        return (
                            <BindRequirementsTab
                                sectionType={BIND_QUESTION}
                                noDatapointsText="No questions yet"
                                addDatapointButtonText="Add Question"
                                addOrEditPopupName="add-or-edit-bind-question"
                                deletePopupName="delete-bind-question"
                                datapointMenuName="question"
                            />
                        );
                    default:
                        return <></>;
                }
            })()}
        </ProductMainPane>
    );
};

const BindRequirementsHelp = () => (
    <ExtraPane>
        <h3>
            <i aria-hidden="true" className="icon-help" /> Bind Requirements
        </h3>
        <p className="size-14">
            Use these settings to stop a policy from being automatically issued.
        </p>
        <ul className="list-icon b color-primary">
            <li>
                <i aria-hidden="true" className="icon-list" /> Subjectivities{" "}
                <span>
                    Items that need to be completed before a policy can be
                    issued. These are displayed for all parties, but only the
                    underwriter can mark them as complete.
                </span>
            </li>
            <li>
                <i aria-hidden="true" className="icon-doc-edit" /> Supporting
                Information{" "}
                <span>
                    Gather additonal information that wasn&apos;t required in
                    the application for rating, but may still be required or
                    useful for the policy. These are the same question format as
                    used in the application.
                </span>
            </li>
        </ul>
    </ExtraPane>
);

const AddOrEditBindQuestionPopup = () => {
    const {
        applicationState: { productVersion },
    } = useApplication();

    const getOrCreateBindQuestionSection = useCallback(() => {
        if (!productVersion) {
            return;
        }
        const bindQuestionSections = getBindQuestionSections(productVersion);
        if (bindQuestionSections.length > 0) {
            return bindQuestionSections[0];
        }
        const newSection: SectionV1 = {
            title: "Bind Questions",
            type: BIND_QUESTION,
            code: `bind.${randomString(10, {
                lowercaseCharacters: true,
                digits: false,
            })}`,
            items: [],
        };
        productVersion.schema.spec.sections.push(newSection);
    }, [productVersion]);

    const section = getOrCreateBindQuestionSection();
    if (!section) {
        return;
    }
    return (
        <AddOrEditQuestionDatapointPopup
            name="add-or-edit-bind-question"
            section={section}
        />
    );
};

const BindRequirementsPage: FC<BindRequirementsPageInterface> = ({ tabs }) => (
    <Page tabs={tabs}>
        <ApplicationProvider>
            <Main />
            <BindRequirementsHelp />
            <AddOrEditSubjectivityPopup />
            <AddOrEditBindQuestionPopup />
            <DeleteSubjectivityPopup />
            <DeleteBindQuestionPopup />
        </ApplicationProvider>
    </Page>
);

export default BindRequirementsPage;
