import noop from "lodash/noop";
import { asyncSleepCancellable } from "modern-async";

const retry = <T>(
    callback: () => Promise<T> | T,
    stopConditionCallback: (returnedValue: T) => boolean,
    sleepTimeMs: number
) => {
    let firstCallbackReturn: T | undefined = undefined;

    const runCallback = () => {
        // https://stackoverflow.com/a/27746324
        // use Promise.resolve to treat a function as async whether it is or isn't
        return Promise.resolve(callback());
    };

    const willRunOnlyOncePromise = (async () => {
        firstCallbackReturn = await runCallback();
        const willRunOnlyOnce = stopConditionCallback(firstCallbackReturn);

        return willRunOnlyOnce;
    })();

    const cancelled: {
        cancelled: boolean;
        sleepCancel: (() => boolean) | undefined;
    } = {
        cancelled: false,
        sleepCancel: undefined,
    };

    const cancelRetry = () => {
        cancelled.cancelled = true;
        if (cancelled.sleepCancel) {
            cancelled.sleepCancel();
        }
    };

    const doSleep = () => {
        const [sleepPromise, sleepCancel] = asyncSleepCancellable(sleepTimeMs);
        cancelled.sleepCancel = sleepCancel;
        return sleepPromise;
    };

    const doRetry = async () => {
        let shouldStop = await willRunOnlyOncePromise;

        let callbackReturn: T | undefined = firstCallbackReturn;

        if (!shouldStop && !cancelled.cancelled) {
            try {
                await doSleep();
            } catch {
                noop();
            }
        }

        while (!shouldStop && !cancelled.cancelled) {
            callbackReturn = await runCallback();

            shouldStop = stopConditionCallback(callbackReturn);

            if (!shouldStop) {
                try {
                    await doSleep();
                } catch {
                    noop();
                }
            }
        }

        return cancelled.cancelled ? undefined : callbackReturn;
    };

    return [doRetry(), cancelRetry, willRunOnlyOncePromise] as const;
};

export { retry };
