import { AlertCategory, usePage } from "components/Page";
import { useApi } from "contexts/ApiProvider";
import { usePopup } from "contexts/PopupProvider";
import { useCallback, useEffect, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import pick from "lodash/pick";
import { splitMultipleLines } from "utils/string";
import { z } from "zod";
import { createFormPopup } from "components/Popup";
import FormControl from "@mui/material/FormControl";
import {
    FormulaSuggestionInput,
    Select,
} from "components/ReactHookFormUncontrolledComponents";
import { MenuItem } from "components/Select";
import ListItemIcon from "@mui/material/ListItemIcon";
import classNames from "classnames";
import NotAnchor from "components/NotAnchor";

import {
    DatapointSourceV1,
    type ApplicationDatapointV1,
    type JoTypeV1,
    type SectionV1,
    ApplicationSectionItemV1,
    FileProcessingV1,
    TextOptionV1,
} from "@joshuins/builder";
import type { FC } from "react";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import {
    findIndexesByDatapointCode,
    getApplicationDatapoints,
    getApplicationSections,
} from "utils/product-version";
import { CodePrefixes, useApplication } from "./ApplicationProvider";
import { setCodePrefix } from "utils/string";

const VALUE_TYPE_SELECT_OPTIONS = [
    {
        value: "LongText",
        icon: "doc-edit",
        title: "Text (Long Answer)",
    },
    {
        value: "Text",
        icon: "doc-edit2",
        title: "Text (Short Answer)",
    },
    {
        value: "Number",
        icon: "hash",
        title: "Number",
    },
    {
        value: "Boolean",
        icon: "choice",
        title: "Yes/No",
    },
    {
        value: "MultipleOptions",
        icon: "list",
        title: "Multiple Choice",
    },
    {
        value: "File",
        icon: "upload",
        title: "Upload File",
    },
    {
        value: "Date",
        icon: "calendar",
        title: "Date",
    },
    {
        value: "Location",
        icon: "address",
        title: "Address",
    },
    {
        value: "Email",
        icon: "envelope",
        title: "Email",
    },
    {
        value: "Website",
        icon: "website",
        title: "Website",
    },
    {
        value: "PhoneNumber",
        icon: "phone",
        title: "Phone",
    },
] as const;

enum ValueTypes {
    LongText = "LongText",
    Text = "Text",
    Number = "Number",
    Boolean = "Boolean",
    MultipleOptions = "MultipleOptions",
    File = "File",
    Date = "Date",
    Location = "Location",
    Email = "Email",
    Website = "Website",
    PhoneNumber = "PhoneNumber",
}
const VALUE_TYPES_FOR_SELECT = Object.keys(ValueTypes) as [
    keyof typeof ValueTypes,
];

const formTypeToDatapointType = (
    formData: AddOrEditQuestionDatapointType
): JoTypeV1 | undefined => {
    const typeName = formData.value_type.main;
    const options: TextOptionV1[] = [];
    splitMultipleLines(formData.value_type.options).map((option) =>
        options.push({
            value: option,
            display: null,
        })
    );

    if (typeName === "Text" || typeName === "LongText") {
        return { Text: { options } };
    }
    if (typeName === "Number") {
        return {
            Number: { format: { rounding: "MidpointAwayFromZero" }, options },
        };
    }
    if (typeName === "Boolean") {
        return { Boolean: {} };
    }
    if (typeName === "File") {
        let processing: FileProcessingV1 | null = null;
        if (formData.processing && formData.processing.enabled) {
            processing = {
                ExcelToRaterData: {
                    sheets: [
                        {
                            from: formData.processing.from_sheet,
                            to: formData.processing.to_sheet,
                        },
                    ],
                },
            };
        }
        return { File: { processing } };
    }
    if (typeName === "Date") {
        return { Date: { format: "MonthDayYear" } };
    }
    if (typeName === "Location") {
        return { Location: {} };
    }
    if (typeName === "Email") {
        return { Text: { format: "EmailAddress", options } };
    }
    if (typeName === "Website") {
        return { Text: { format: "WebsiteAddress", options } };
    }
    if (typeName === "PhoneNumber") {
        return { Text: { format: "PhoneNumber", options } };
    }
    if (typeName === "MultipleOptions") {
        const itemType = { Text: { options } };
        if (formData.multiple_answers) {
            return { Array: { type: itemType } };
        }
        return itemType;
    }
};

const valueTypeToStringAndOptions = (
    joType: JoTypeV1
): {
    valueType: keyof typeof ValueTypes;
    options: string[];
    allow_multiple: boolean;
} => {
    if ("Array" in joType) {
        const arrayType = joType.Array;
        return {
            valueType: ValueTypes.MultipleOptions,
            options:
                "Text" in arrayType.type
                    ? arrayType.type.Text.options.map((option) => option.value)
                    : [],
            allow_multiple: true,
        }; //TODO
    }
    if ("Number" in joType) {
        return {
            valueType: ValueTypes.Number,
            options: joType.Number.options.map((option) => option.value),
            allow_multiple: false,
        };
    }
    if ("Boolean" in joType) {
        return {
            valueType: ValueTypes.Boolean,
            options: [],
            allow_multiple: false,
        };
    }
    if ("Date" in joType) {
        return {
            valueType: ValueTypes.Date,
            options: [],
            allow_multiple: false,
        };
    }
    if ("Text" in joType) {
        if (joType.Text.format === "WebsiteAddress") {
            return {
                valueType: ValueTypes.Website,
                options: joType.Text.options.map((option) => option.value),
                allow_multiple: false,
            };
        }
        if (joType.Text.format === "PhoneNumber") {
            return {
                valueType: ValueTypes.PhoneNumber,
                options: joType.Text.options.map((option) => option.value),
                allow_multiple: false,
            };
        }
        if (joType.Text.format === "EmailAddress") {
            return {
                valueType: ValueTypes.Email,
                options: joType.Text.options.map((option) => option.value),
                allow_multiple: false,
            };
        }
        return {
            valueType: ValueTypes.Text,
            options: joType.Text.options.map((option) => option.value),
            allow_multiple: false,
        };
    }
    if ("Monetary" in joType) {
        return {
            valueType: ValueTypes.Number,
            options: joType.Monetary.options.map((option) => option.value),
            allow_multiple: false,
        };
    }
    if ("Location" in joType) {
        return {
            valueType: ValueTypes.Location,
            options: [],
            allow_multiple: false,
        };
    }
    if ("File" in joType) {
        return {
            valueType: ValueTypes.File,
            options: [],
            allow_multiple: false,
        };
    }
    return {
        valueType: ValueTypes.Text,
        options: [],
        allow_multiple: false,
    };
};

const addOrEditQuestionDatapointSchema = z.object({
    title: z.string().min(1, "This field is required"),
    code: z
        .object({
            value: z.string().min(1, "This field is required"),
            context: z.object({
                existingDatapointCodeToUpdate: z.union([
                    z.string(),
                    z.null(),
                    z.undefined(),
                ]),
                productVersion: z.union([z.any(), z.null()]),
            }),
        })
        .refine(
            ({ value, context }) => {
                const { existingDatapointCodeToUpdate, productVersion } =
                    context;
                if (existingDatapointCodeToUpdate === value) {
                    return true;
                } else if (productVersion) {
                    const datapoints = getApplicationDatapoints(productVersion);
                    const sections = getApplicationSections(productVersion);
                    const existingDatapoint = datapoints.findIndex(
                        (datapoint) => datapoint.code === value
                    );
                    const existingSection = sections.findIndex(
                        (section) => section.code === value
                    );
                    return existingDatapoint < 0 && existingSection < 0;
                }
            },
            {
                message: "Reference tag already in use",
                path: ["value"],
            }
        ),

    value_type: z
        .object({
            main: z.enum(VALUE_TYPES_FOR_SELECT, {
                required_error: "This field is required",
            }),
            options: z.string(),
        })
        .refine(
            (value) =>
                value.main !== "MultipleOptions" ||
                splitMultipleLines(value.options).length >= 2,
            {
                message: "You must supply at least 2 choices",
                path: ["options"],
            }
        ),
    required: z.boolean(),
    multiple_answers: z.boolean(),
    condition: z
        .object({
            enabled: z.boolean(),
            text: z.string(),
        })
        .refine((value) => !value.enabled || value.text.length > 0, {
            message: "You must supply a condition",
            path: ["text"],
        }),
    help_text: z
        .object({
            enabled: z.boolean(),
            text: z.string(),
        })
        .refine((value) => !value.enabled || value.text.length > 0, {
            message: "You must supply help text",
            path: ["text"],
        }),
    underwriter_only: z.boolean(),
    processing: z
        .object({
            enabled: z.boolean(),
            from_sheet: z.string(),
            to_sheet: z.string(),
        })
        .refine(
            (value) =>
                !value.enabled ||
                (value.from_sheet.length > 0 && value.to_sheet.length > 0),
            {
                message: "You must supply sheet names",
                path: ["to_sheet"],
            }
        )
        .optional(),
});
type AddOrEditQuestionDatapointType = z.infer<
    typeof addOrEditQuestionDatapointSchema
>;

const { FormPopup, useFormReturnRef } = createFormPopup(
    addOrEditQuestionDatapointSchema
);

const AddOrEditQuestionDatapointPopup: FC<{
    name: string;
    section: SectionV1;
}> = ({ name, section }) => {
    const apiProviderContext = useApi();
    const { sdkBuilder } = apiProviderContext;
    const { popupData, isPopupOpen } = usePopup();
    const { addAlertMessages, tryCatchAndRaiseError } = usePage();
    const {
        formReturn: { reset },
        formReturnRefCallback,
    } = useFormReturnRef();
    const [datapoint, setDatapoint] = useState<ApplicationDatapointV1>();
    const [extraFieldsOpen, setExtraFieldsOpen] = useState(false);
    const datapointCode = popupData?.datapointCode as string | undefined;
    const {
        applicationDispatch,
        applicationState: { productVersion },
    } = useApplication();

    const transformFormDataToDatapoint = useCallback(
        (
            formData: AddOrEditQuestionDatapointType
        ): ApplicationSectionItemV1 => ({
            Datapoint: {
                ...pick(formData, ["title", "required"]),
                code: formData.code.value,
                type: formTypeToDatapointType(formData) || { Boolean: {} }, // TODO
                condition: formData.condition.enabled
                    ? formData.condition.text
                    : "",
                display: {
                    lines: formData.value_type.main == "LongText" ? 5 : 1,
                    help_text: formData.help_text.enabled
                        ? formData.help_text.text
                        : undefined,
                },
                source: formData.underwriter_only
                    ? DatapointSourceV1.Underwriter
                    : DatapointSourceV1.User,
            },
        }),
        []
    );

    const transformApiDataToFormData = useCallback(
        (
            datapoint: ApplicationDatapointV1
        ): AddOrEditQuestionDatapointType | undefined => {
            if (!productVersion) {
                return;
            }
            const {
                valueType: suggestedValueType,
                options,
                allow_multiple,
            } = valueTypeToStringAndOptions(datapoint.type);
            let valueType = suggestedValueType;
            if (valueType === "Text" && options && options.length > 0) {
                valueType = "MultipleOptions";
            }
            if (
                valueType === "Text" &&
                datapoint.display?.lines &&
                datapoint.display.lines > 1
            ) {
                valueType = "LongText";
            }
            let processing = undefined;
            if (
                "File" in datapoint.type &&
                datapoint.type.File.processing &&
                datapoint.type.File.processing.ExcelToRaterData.sheets.length >
                    0
            ) {
                const sheet =
                    datapoint.type.File.processing.ExcelToRaterData.sheets[0];
                processing = {
                    enabled: true,
                    from_sheet: sheet.from,
                    to_sheet: sheet.to,
                };
            }
            return {
                ...pick(datapoint, ["title", "required"]),
                code: {
                    value: datapoint.code,
                    context: {
                        existingDatapointCodeToUpdate: datapoint.code,
                        productVersion,
                    },
                },
                value_type: {
                    main: valueType,
                    options: options ? options.join("\r\n") : "",
                },
                multiple_answers: allow_multiple,
                condition: {
                    enabled: !!datapoint.condition,
                    text: datapoint.condition ? datapoint.condition : "",
                },
                help_text: {
                    enabled: !!datapoint.display.help_text,
                    text: datapoint.display.help_text
                        ? datapoint.display.help_text
                        : "",
                },
                underwriter_only: datapoint.source === "Underwriter",
                processing,
            };
        },
        [productVersion]
    );

    useEffect(() => {
        if (!productVersion || !isPopupOpen(name) || !reset) {
            return;
        }
        setDatapoint(undefined);
        if (datapointCode) {
            const {
                sectionIndex,
                datapointIndex,
                datapoint: existingDatapoint,
            } = findIndexesByDatapointCode(productVersion, datapointCode);
            if (
                sectionIndex != undefined &&
                datapointIndex != undefined &&
                existingDatapoint
            ) {
                setDatapoint(existingDatapoint);
                const formData = transformApiDataToFormData(existingDatapoint);
                if (formData) {
                    reset(transformApiDataToFormData(existingDatapoint));
                }
                if (existingDatapoint) {
                    setExtraFieldsOpen(
                        !isEmpty(existingDatapoint.condition) ||
                            !isEmpty(existingDatapoint.display.help_text) ||
                            existingDatapoint.source === "Underwriter"
                    );
                }
            }
        } else {
            reset({
                code: {
                    context: {
                        productVersion,
                    },
                },
            });
        }
    }, [
        sdkBuilder,
        transformApiDataToFormData,
        isPopupOpen,
        name,
        apiProviderContext,
        productVersion,
        reset,
        datapointCode,
        section.code,
    ]);

    const onSubmit = useCallback(
        async (data: AddOrEditQuestionDatapointType) => {
            if (!productVersion) {
                return;
            }
            const updateProdcutVersion = cloneDeep(productVersion);
            tryCatchAndRaiseError(async () => {
                const sections = updateProdcutVersion.schema.spec.sections;
                const sectionIndex = sections.findIndex(
                    (item) => item.code === section.code
                );
                const updatedSection = sections[sectionIndex];
                if (datapoint) {
                    const datapointIndex = updatedSection.items.findIndex(
                        (item) =>
                            "Datapoint" in item &&
                            item.Datapoint.code === datapoint.code
                    );
                    if (datapointIndex >= 0) {
                        updatedSection.items[datapointIndex] =
                            transformFormDataToDatapoint(data);
                    }
                } else {
                    updatedSection.items.push(
                        transformFormDataToDatapoint(data)
                    );
                }
                sections[sectionIndex] = updatedSection;
                updateProdcutVersion.schema.spec.sections = sections;
                await sdkBuilder.updateProductVersion({
                    id: updateProdcutVersion.id,
                    UpdateProductVersion: {
                        schema: updateProdcutVersion.schema,
                    },
                });
                applicationDispatch({
                    action: "SetProductVersion",
                    productVersion: updateProdcutVersion,
                });
                addAlertMessages({
                    message: `Question ${
                        datapoint ? "updated" : "created"
                    } successfully`,
                    category: AlertCategory.SUCCESS,
                });
            });
        },
        [
            addAlertMessages,
            applicationDispatch,
            datapoint,
            productVersion,
            sdkBuilder,
            section.code,
            transformFormDataToDatapoint,
            tryCatchAndRaiseError,
        ]
    );

    if (!productVersion) {
        return <></>;
    }

    let prefix: string;
    switch (section.type) {
        case "Asset":
        case "Application": {
            prefix = CodePrefixes.APPLICATION_PREFIX;
            break;
        }
        case "BindQuestion": {
            prefix = CodePrefixes.BIND_PREFIX;
            break;
        }
    }

    return (
        <FormPopup
            name={name}
            defaultValues={{
                title: "",
                code: {
                    value: "",
                    context: {
                        productVersion,
                    },
                },
                value_type: {
                    main: undefined,
                    options: "",
                },
                required: true,
                multiple_answers: false,
                condition: {
                    enabled: false,
                    text: "",
                },
                help_text: {
                    enabled: false,
                    text: "",
                },
                underwriter_only: false,
            }}
            submitText="Save"
            formReturnRefCallback={formReturnRefCallback}
            onSubmit={onSubmit}
            onClose={() => setExtraFieldsOpen(false)}
            disabled={productVersion.is_published}
            overlayPopups={[
                // 0
                <>
                    <h3>Choices</h3>
                    <p>
                        All the items you want listed as choices. Must be at
                        least two items. Add each item on its own line. Keep the
                        list as short as possible.{" "}
                    </p>
                </>,
                // 1
                <>
                    <h3>Question Text</h3>
                    <p>
                        This is small text added under the question to give
                        hints about the answer. Keep it as short and direct as
                        possible. We recommend the default styling as much as
                        possible, but if you need to create bold / italic words
                        or links, follow the Markdown syntax below.
                    </p>
                    <table className="table-f">
                        <tbody>
                            <tr>
                                <th>Element</th>
                                <th>Markdown Syntax</th>
                            </tr>
                            <tr>
                                <td>Bold</td>
                                <td>**bold text**</td>
                            </tr>
                            <tr>
                                <td>Italic</td>
                                <td>*italic text*</td>
                            </tr>
                            <tr>
                                <td>Link</td>
                                <td>Link is [here](https://example.com)</td>
                            </tr>
                        </tbody>
                    </table>
                </>,
                // 2
                <>
                    <h3>Reference Tag</h3>
                    <p>
                        Reference Tags are short codes that will be used to
                        refer back to questions in your Excel rater and
                        documents. For example, if you ask for Annual Revenue, a
                        good reference tag would be &quot;annual_revenue&quot;
                        (best practice is all lower case with underscores).
                    </p>
                    <h4>
                        Create reference Tags for each question in your
                        application:
                    </h4>
                    <p className="scheme-sample">
                        <span>annual_revenue</span> Expected Annual Revenue?
                    </p>
                    <h4>
                        Add the code to your Excel rater and Word documents as
                        variables:
                    </h4>
                    <table className="scheme-sample">
                        <tbody>
                            <tr>
                                <th className="empty"></th>
                                <th>A</th>
                                <th>B</th>
                            </tr>
                            <tr>
                                <th>1</th>
                                <td>Annual Revenue</td>
                                <td className="color-primary">
                                    {"{annual_revenue}"}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <h4>
                        The rater and documents pull the final value from the
                        application:
                    </h4>
                    <p className="scheme-sample">
                        <label htmlFor="iic">Expected Annual Revenue?</label>
                        <span className="input-prefix">
                            <input
                                type="text"
                                id="iic"
                                name="iic"
                                className="input-money color-primary done"
                                value="100000000"
                                readOnly
                            />
                            <span className="color-black-100">$</span>
                        </span>
                    </p>
                </>,
                // 3
                <>
                    <h3>Question Type</h3>
                    <p>
                        Use Long Answer for multi-line answers or long
                        descriptions. Short Answer is a small text field, good
                        for things like figures, phone numbers and ZIP codes.
                    </p>
                </>,
                // 4
                <>
                    <h3>Help Text</h3>
                    <p>
                        This is small text added under the question to give
                        hints about the answer. Keep it as short and direct as
                        possible. We recommend the default styling as much as
                        possible, but if you need to create bold / italic words
                        or links, follow the Markdown syntax below.
                    </p>
                    <table className="table-f">
                        <tbody>
                            <tr>
                                <th>Element</th>
                                <th>Markdown Syntax</th>
                            </tr>
                            <tr>
                                <td>Bold</td>
                                <td>**bold text**</td>
                            </tr>
                            <tr>
                                <td>Italic</td>
                                <td>*italic text*</td>
                            </tr>
                            <tr>
                                <td>Link</td>
                                <td>Link is [here](https://example.com)</td>
                            </tr>
                        </tbody>
                    </table>
                </>,
                // 5
                <>
                    <h3>Underwriter Only Question</h3>
                    <p>
                        These questions will only be seen by underwriters. They
                        can be required or not. If they are required, the
                        Underwriter will not be able to publish a quote until
                        they are answered.
                    </p>
                </>,
                // 6
                <>
                    <h3>Conditional</h3>
                    <p>
                        This question will be{" "}
                        <strong style={{ fontWeight: 500 }}>hidden </strong>
                        unless the condition is true. For example, if
                        &quot;Previous Claims&quot; answered yes you want to
                        display a{" "}
                        <strong style={{ fontWeight: 500 }}>
                            followup question{" "}
                        </strong>{" "}
                        like &quot;Please describe previous claims.&quot; To
                        make this work, you set the followup question as
                        conditional, and refer to the original question with its
                        reference tag. So the condition for the followup
                        question becomes &quot;
                        <strong style={{ fontWeight: 500 }}>
                            previous_claims=1
                        </strong>
                        .&quot; (Note: Use 1 for Yes or True, 0 for No or
                        False.)
                    </p>
                </>,
                // 7
                <>
                    <h3>Use file in Excel</h3>
                    <p>
                        When uploading an Excel file, a sheet from the file can
                        be imported into the Rater.
                    </p>
                </>,
            ]}
        >
            {({
                register,
                openOverlayPopup,
                control,
                watch,
                formState: { errors },
            }) => (
                <>
                    <header>
                        <h2>Question</h2>
                    </header>
                    <p
                        className={classNames({
                            "has-error": !!errors.title,
                        })}
                    >
                        <label htmlFor="ihj-1332-0">
                            {" "}
                            Question Text
                            <NotAnchor
                                className="text-right"
                                onClick={() => openOverlayPopup(1)}
                            >
                                <i aria-hidden="true" className="icon-help" />{" "}
                                <span className="hidden">More info</span>
                            </NotAnchor>
                        </label>
                        <TextareaAutosize
                            {...register("title")}
                            id="ihj-1332-0"
                            className="compact"
                        />
                        {errors.title && (
                            <label
                                id="ihj-1332-0-error"
                                className="error"
                                htmlFor="ihj-1332-0"
                            >
                                {errors.title.message}
                            </label>
                        )}
                    </p>
                    <p
                        className={classNames({
                            "has-error": !!errors.code,
                        })}
                    >
                        <label htmlFor="ihk-1332-0">
                            Reference Tag{" "}
                            <span className="text-right">
                                30 Character Limit{" "}
                                <NotAnchor onClick={() => openOverlayPopup(2)}>
                                    <i
                                        aria-hidden="true"
                                        className="icon-help"
                                    />{" "}
                                    <span className="hidden">More info</span>
                                </NotAnchor>
                            </span>
                        </label>
                        <input
                            {...register("code.value", {
                                onChange: (e) => setCodePrefix(prefix, e),
                            })}
                            type="text"
                            id="ihk-1332-0"
                            maxLength={30}
                        />
                        {errors.code?.value && (
                            <label
                                id="ihk-1332-0-error"
                                className="error"
                                htmlFor="ihk-1332-0"
                            >
                                {errors.code?.value.message}
                            </label>
                        )}
                    </p>
                    <div
                        className={classNames("c50 div-as-p", {
                            "has-error-cont": !!errors.code,
                        })}
                    >
                        <span className="label">
                            Question Type{" "}
                            <NotAnchor
                                className="text-right"
                                onClick={() => openOverlayPopup(3)}
                            >
                                <i aria-hidden="true" className="icon-help" />{" "}
                                <span className="hidden">More info</span>
                            </NotAnchor>
                        </span>
                        <FormControl
                            fullWidth
                            error={!!errors.value_type}
                            className={classNames({
                                "has-error": !!errors.value_type,
                            })}
                        >
                            <Select
                                labelId="product-type-select-label"
                                id="ihl-0"
                                name="value_type.main"
                                control={control}
                            >
                                {VALUE_TYPE_SELECT_OPTIONS.map(
                                    ({ value, icon, title }) => (
                                        <MenuItem key={value} value={value}>
                                            <ListItemIcon>
                                                <i
                                                    aria-hidden="true"
                                                    className={`icon-${icon}`}
                                                />
                                            </ListItemIcon>
                                            {title}
                                        </MenuItem>
                                    )
                                )}
                            </Select>
                        </FormControl>
                        {errors.value_type?.main && (
                            <label
                                id="ihl-0-error"
                                className="error"
                                htmlFor="ihl-0"
                            >
                                {errors.value_type.main.message}
                            </label>
                        )}
                    </div>
                    <p
                        className={classNames("section-question-options", {
                            hidden:
                                watch("value_type.main") !== "MultipleOptions",
                        })}
                    >
                        <label htmlFor="ihp-1332-0">
                            Choices{" "}
                            <NotAnchor
                                className="text-right"
                                onClick={() => openOverlayPopup(0)}
                            >
                                <i className="icon-help" />{" "}
                                <span className="hidden">More info</span>
                            </NotAnchor>
                        </label>
                        <TextareaAutosize
                            {...register("value_type.options")}
                            id="ihp-1332-0"
                            className="compact"
                        />
                        {errors.value_type?.options && (
                            <label
                                id="ihp-1332-0-error"
                                className="error"
                                htmlFor="ihp-1332-0"
                            >
                                {errors.value_type.options.message}
                            </label>
                        )}
                    </p>
                    <div className="cols m10">
                        <p className="link-strong">
                            <NotAnchor
                                className={classNames({
                                    clicked: extraFieldsOpen,
                                })}
                                onClick={() =>
                                    setExtraFieldsOpen(!extraFieldsOpen)
                                }
                            >
                                <i className="icon-chevron-down" /> Question
                                Options
                            </NotAnchor>
                        </p>
                        <p className="check font-medium color-primary m10">
                            <input
                                {...register("required")}
                                type="checkbox"
                                id="ihr-1332-0"
                            />
                            <label htmlFor="ihr-1332-0" className="small">
                                Required
                            </label>
                        </p>
                    </div>
                    <ul
                        className={classNames("check overlay section-popup-z", {
                            hidden: !extraFieldsOpen,
                        })}
                    >
                        <li
                            className={classNames("section-question-options", {
                                hidden:
                                    watch("value_type.main") !==
                                    "MultipleOptions",
                            })}
                        >
                            <input
                                {...register("multiple_answers")}
                                type="checkbox"
                                id="ihs-0"
                            />{" "}
                            <label htmlFor="ihs-0">
                                Allow Multiple Answers
                            </label>
                        </li>
                        <li>
                            <input
                                {...register("condition.enabled")}
                                type="checkbox"
                                id="ihx-1332-0"
                            />
                            <label htmlFor="ihx-1332-0">Conditional</label>
                            <NotAnchor
                                className="text-right"
                                onClick={() => openOverlayPopup(6)}
                            >
                                <i aria-hidden="true" className="icon-help" />
                                <span className="hidden">More info</span>
                            </NotAnchor>
                            <div
                                className={classNames({
                                    hidden: !watch("condition.enabled"),
                                })}
                            >
                                <p>
                                    <label htmlFor="ihy-1332-0">
                                        Only display this question if...
                                    </label>
                                    <FormulaSuggestionInput
                                        name="condition.text"
                                        control={control}
                                        id="ihy-1332-0"
                                        suggestions={[
                                            "ref_code=1",
                                            "AND(ref_code1=0,ref_code2>1000)",
                                            "MAX(ref_code1,ref_code2,ref_code3)=1",
                                        ]}
                                    />
                                    {errors.condition?.text && (
                                        <label
                                            id="ihy-1332-0-error"
                                            className="error"
                                            htmlFor="ihy-1332-0"
                                        >
                                            {errors.condition.text.message}
                                        </label>
                                    )}
                                    <span className="scheme-info">
                                        Use Excel formulas to evaluate answers,
                                        display if true
                                    </span>
                                </p>
                            </div>
                        </li>
                        <li>
                            <input
                                {...register("help_text.enabled")}
                                type="checkbox"
                                id="ihz-1332-0"
                            />
                            <label htmlFor="ihz-1332-0">Help Text</label>
                            <NotAnchor
                                className="text-right"
                                onClick={() => openOverlayPopup(4)}
                            >
                                <i aria-hidden="true" className="icon-help" />
                                <span className="hidden">More info</span>
                            </NotAnchor>
                            <div
                                className={classNames({
                                    hidden: !watch("help_text.enabled"),
                                })}
                            >
                                <p>
                                    <label htmlFor="iia-1332-0">
                                        Add your help text here
                                    </label>
                                    <TextareaAutosize
                                        {...register("help_text.text")}
                                        id="iia-1332-0"
                                        className="compact resize"
                                    />
                                </p>
                            </div>
                        </li>
                        <li>
                            <input
                                {...register("underwriter_only")}
                                type="checkbox"
                                id="iib-1332-0"
                            />
                            <label htmlFor="iib-1332-0">
                                Underwriter Only Question
                                <NotAnchor
                                    onClick={() => openOverlayPopup(5)}
                                    className="text-right"
                                >
                                    <i
                                        aria-hidden="true"
                                        className="icon-help"
                                    />
                                    <span className="hidden">More info</span>
                                </NotAnchor>
                            </label>
                        </li>
                        <li
                            className={classNames({
                                hidden: watch("value_type.main") !== "File",
                            })}
                        >
                            <input
                                {...register("processing.enabled")}
                                type="checkbox"
                                id="ihz-xx-h"
                            />
                            <label htmlFor="ihz-xx-h">Send to Rater</label>
                            <NotAnchor
                                className="text-right"
                                onClick={() => openOverlayPopup(7)}
                            >
                                <i aria-hidden="true" className="icon-help" />
                                <span className="hidden">More info</span>
                            </NotAnchor>
                            <div
                                className={classNames({
                                    hidden: !watch("processing.enabled"),
                                })}
                            >
                                <p>
                                    <label htmlFor="iia-xx-z">
                                        Name of Excel sheet in file
                                    </label>
                                    <input
                                        {...register("processing.from_sheet")}
                                        type="text"
                                        id="iia-xx-z"
                                        maxLength={50}
                                    />
                                    <label htmlFor="iia-xx-z1">
                                        Name of Excel sheet in rater
                                    </label>
                                    <input
                                        {...register("processing.to_sheet")}
                                        type="text"
                                        id="iia-xx-z1"
                                        maxLength={50}
                                    />
                                </p>
                                {errors.processing?.to_sheet && (
                                    <label
                                        id="iia-xx-z1"
                                        className="error"
                                        htmlFor="iia-xx-z1"
                                    >
                                        {errors.processing.to_sheet.message}
                                    </label>
                                )}
                            </div>
                        </li>
                    </ul>
                </>
            )}
        </FormPopup>
    );
};

export default AddOrEditQuestionDatapointPopup;
