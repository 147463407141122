import { Controller, FieldValues } from "react-hook-form";
import { DropzoneOptions, useDropzone } from "react-dropzone";
import { FC, SyntheticEvent } from "react";
import classNames from "classnames";
import NotAnchor from "./NotAnchor";
import { useFieldSet } from "./Form";
import { FileDesignation, GetFileResponse } from "@joshuins/builder";
import mapValues from "lodash/mapValues";
import {
    ControllerProps,
    customField,
} from "./ReactHookFormUncontrolledComponents";

const EXTENSION_TO_MIMETYPE = {
    doc: "application/msword",
    docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    gif: "image/gif",
    jpeg: "image/jpeg",
    jpg: "image/jpeg",
    pdf: "application/pdf",
    png: "image/png",
    xls: "application/vnd.ms-excel",
    xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    xlsb: "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
    xlsm: "application/vnd.ms-excel.sheet.macroEnabled.12",
    yaml: "text/x-yaml",
    zip: "application/zip",
} as const;

const FILE_DESIGNATION_TO_EXTENSION: {
    readonly [x in
        | Exclude<FileDesignation, "ExportDump" | "Report">
        // ApplicationDocumentTemplate is not an actual FileDesignation recognised by the API but it is explicitly included
        // here because the extensions accepted for DocumentTemplate differ in diffent parts of the frontend. To understand:
        // see where DocumentTemplate is used and where ApplicationDocument is used and compare their allowed extensions
        | "ApplicationDocumentTemplate"]: readonly (keyof typeof EXTENSION_TO_MIMETYPE)[];
} = {
    AnswerFile: [
        "doc",
        "docx",
        "gif",
        "jpeg",
        "jpg",
        "pdf",
        "png",
        "xls",
        "xlsx",
        "zip",
    ],
    Document: ["doc", "docx", "pdf"], // I guessed this list of extesions for now: but it's never going to be used anyway since Documents are generated by the backend
    DocumentTemplate: ["pdf", "doc", "docx"],
    ManualDocument: ["pdf", "doc", "docx"],
    ApplicationDocumentTemplate: ["doc", "docx"],
    ProductRater: ["xls", "xlsx", "xlsb", "xlsm"],
    QuoteRater: ["xls", "xlsx", "xlsb", "xlsm"],
    ReportTemplate: ["xls", "xlsx", "xlsb", "xlsm"],
    StoreLogo: ["png", "jpg", "jpeg"],
    ProductExport: ["yaml"],
} as const;

const FILE_DESIGNATION_TO_ACCEPT = mapValues(
    FILE_DESIGNATION_TO_EXTENSION,
    (extensions) =>
        extensions.reduce(
            (currentValue, extension) => {
                currentValue[EXTENSION_TO_MIMETYPE[extension]] = [
                    `.${extension}`,
                ];
                return currentValue;
            },
            {} as {
                [x in (typeof EXTENSION_TO_MIMETYPE)[keyof typeof EXTENSION_TO_MIMETYPE]]?: `.${keyof typeof EXTENSION_TO_MIMETYPE}`[];
            }
        )
);

interface DropzoneProps extends DropzoneOptions {
    id: string;
    onNewFile?: <T extends File>(acceptedFile: T | undefined) => void;
    Label?: JSX.Element;
    value: File | string;
    fileDesignation?: keyof typeof FILE_DESIGNATION_TO_ACCEPT;
    error?: string;
    labelPosition?: "inside-p" | "outside-p";
}

const isGetFileResponse = (value: object): value is GetFileResponse => {
    return [
        "id",
        "designation",
        "download_url",
        "filename",
        "created_at",
    ].every((k) => k in value);
};

const Dropzone: FC<DropzoneProps> = ({
    id,
    onNewFile,
    Label,
    value,
    fileDesignation,
    error,
    labelPosition = "inside-p",
    disabled,
    ...rest
}) => {
    const fieldset = useFieldSet();
    const { getRootProps, getInputProps, open, inputRef } = useDropzone({
        ...(fileDesignation && {
            accept: FILE_DESIGNATION_TO_ACCEPT[fileDesignation],
        }),
        ...rest,
        ...(fieldset && fieldset.disabled && { disabled: true }),
        ...(onNewFile && {
            onDrop: (acceptedFiles) => onNewFile(acceptedFiles[0]),
        }),
    });

    const fileValueToName = (value: string | File | object) => {
        if (value instanceof File) {
            return value.name;
        } else if (typeof value === "object" && isGetFileResponse(value)) {
            return value.filename;
        } else if (typeof value === "string") {
            return value;
        } else {
            throw new Error("Invalid value representing a file");
        }
    };

    return (
        <>
            {labelPosition === "outside-p" && Label}
            <p
                {...getRootProps({
                    className: classNames("dropzone input-file", {
                        done: value,
                    }),
                })}
                onClick={(event: SyntheticEvent) => {
                    event.stopPropagation();
                    // DEV-3968
                    // THIS IS REQUIRED TO ALLOW SELECTING THE SAME FILE IN CHROME
                    if (inputRef.current) {
                        inputRef.current.value = "";
                    }
                }}
            >
                {labelPosition === "inside-p" && Label}
                <span className="files">
                    {value &&
                        (disabled ? (
                            <>{fileValueToName(value)}</>
                        ) : (
                            <NotAnchor
                                onClick={(event: SyntheticEvent) => {
                                    event.stopPropagation();
                                    open();
                                }}
                            >
                                {fileValueToName(value)}
                            </NotAnchor>
                        ))}
                </span>
                {disabled !== true && (
                    <>
                        <input {...getInputProps({ id })} />
                        <label htmlFor={id}>
                            <i
                                aria-hidden="true"
                                className="icon-plus-circle"
                            />{" "}
                            Select File
                        </label>
                    </>
                )}
                {error && (
                    <label id={`${id}-error`} className="error" htmlFor={id}>
                        {error}
                    </label>
                )}
            </p>
        </>
    );
};

interface FileUploadProps extends Omit<DropzoneProps, "value"> {
    id: string;
    onChangeHook?: (...event: never[]) => unknown;
}

const FileUpload = <T extends FieldValues>({
    id,
    name,
    control,
    onChangeHook,
    ...rest
}: FileUploadProps & ControllerProps<T>) => {
    return (
        <Controller
            render={({ field }) => {
                const { onChange, value } = customField(field, {
                    onChange: onChangeHook,
                });
                return (
                    <Dropzone
                        id={id}
                        onNewFile={onChange}
                        noClick={true}
                        value={value}
                        multiple={false}
                        {...rest}
                    />
                );
            }}
            name={name}
            control={control}
            defaultValue={undefined}
        />
    );
};

export { FileUpload, Dropzone };
