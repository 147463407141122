import { FC } from "react";
import { PolicyStatus } from "@joshuins/insurance";
import { Page } from "components/Page";
import ExtraPane from "components/extra-panes/ExtraPane";
import PoliciesList from "pages/underwriter/quotes_and_policies/components/PoliciesList";

const POLICIES_STATUSES = [
    PolicyStatus.Active,
    PolicyStatus.Canceled,
    PolicyStatus.Declined,
    PolicyStatus.Expired,
    PolicyStatus.Future,
];

const PoliciesPane = () => (
    <ExtraPane>
        <h3>
            <i aria-hidden="true" className="icon-help" /> Policies
        </h3>
        <p>
            We will notify the insured by email when the policy is up for
            renewal. In addition, the &quot;Renew&quot; button will become
            active 30 days before the renewal date for either you or the insured
            to renew.
        </p>
    </ExtraPane>
);

const StorePoliciesPage: FC = () => (
    <Page>
        <PoliciesList title="Policies" inStatus={POLICIES_STATUSES} />
        <PoliciesPane />
    </Page>
);

export default StorePoliciesPage;
