import { ProductVersion, ProductVersionView } from "@joshuins/builder";

const MAX_PRODUCT_NAME_LENGTH = 100;
const MAX_PRODUCT_DESCRIPTION_LENGTH = 120;
const MAX_WATERMARK_LENGTH = 20;

const PRODUCT_VERSION_TYPES = [
    {
        value: "property",
        icon: "property",
        title: "Property",
        codes: ["01.0"],
    },
    {
        value: "crop",
        icon: "crop",
        title: "Crop",
        codes: ["02.1"],
    },
    {
        value: "flood",
        icon: "flood",
        title: "Flood",
        codes: ["02.3"],
    },
    {
        value: "personal-farmowners",
        icon: "personal-farmowners",
        title: "Personal Farmowners",
        codes: ["03.0"],
    },
    {
        value: "homeowners",
        icon: "homeowners",
        title: "Homeowners",
        codes: ["04.0"],
    },
    {
        value: "cmp-liability-non-liability",
        icon: "cmp-liability-non-liability",
        title: "CMP Liability Non-Liability",
        codes: ["05.0"],
    },
    {
        value: "inland-marine",
        icon: "inland-marine",
        title: "Inland Marine",
        codes: ["09.0"],
    },
    {
        value: "financial-guaranty",
        icon: "financial-guaranty",
        title: "Financial Guaranty",
        codes: ["10.0"],
    },
    {
        value: "medical-malpractice",
        icon: "medical-malpractice",
        title: "Medical Malpractice",
        codes: ["11.0"],
    },
    {
        value: "earthquake",
        icon: "earthquake",
        title: "Earthquake",
        codes: ["12.0"],
    },
    {
        value: "workers-compensation",
        icon: "workers-compensation",
        title: "Workers Compensation",
        codes: ["16.0"],
    },
    {
        value: "other-liability-occ-claims-made",
        icon: "other-liability-occ-claims-made",
        title: "Other Liability OCC Claims",
        codes: ["17.0", "17.1", "17.2"],
    },
    {
        value: "product-liability",
        icon: "product-liability",
        title: "Product Liability",
        codes: ["18.0"],
    },
    {
        value: "personal-auto",
        icon: "personal-auto",
        title: "Personal Auto",
        codes: ["19.0", "19.1", "21.4"],
    },
    {
        value: "commercial-auto",
        icon: "commercial-auto",
        title: "Commercial Auto",
        codes: ["19.2", "20.0", "21.4"],
    },
    {
        value: "motor-vehicle-service-contracts",
        icon: "motor-vehicle-service-contracts",
        title: "Motor Vehicle Service Contracts",
        codes: ["21.2"],
    },
    {
        value: "aircraft",
        icon: "aircraft",
        title: "Aircraft",
        codes: ["22.0"],
    },
    {
        value: "fidelity",
        icon: "fidelity",
        title: "Fidelity",
        codes: ["23.0"],
    },
    {
        value: "surety",
        icon: "surety",
        title: "Surety",
        codes: ["24.0"],
    },
    {
        value: "burglary-and-theft",
        icon: "burglary-and-theft",
        title: "Burglary and Theft",
        codes: ["26.0"],
    },
    {
        value: "boiler-and-machinery",
        icon: "boiler-and-machinery",
        title: "Boiler and Machinery",
        codes: ["27.0"],
    },
    {
        value: "credit-credit-default",
        icon: "credit-credit-default",
        title: "Credit Credit-Default",
        codes: ["28.1"],
    },
    {
        value: "other-lines-of-business",
        icon: "other-lines-of-business",
        title: "Other Lines of Business",
        codes: ["33.0"],
    },
] as const;

const productVersionStatusTextAndColor = (
    productVersion: ProductVersionView | ProductVersion
) =>
    productVersion.is_archived
        ? {
              text: "ARCHIVED" as const,
              color: "wine" as const,
          }
        : productVersion.is_published
          ? {
                text: "PUBLISHED" as const,
                color: "pear" as const,
            }
          : { text: "DRAFT" as const, color: "gold" as const };

const productVersionStatusText = (productVersion: ProductVersionView) =>
    productVersionStatusTextAndColor(productVersion).text;

const productVersionStatusColor = (productVersion: ProductVersionView) =>
    productVersionStatusTextAndColor(productVersion).color;

export {
    MAX_PRODUCT_DESCRIPTION_LENGTH,
    MAX_PRODUCT_NAME_LENGTH,
    MAX_WATERMARK_LENGTH,
    PRODUCT_VERSION_TYPES,
    productVersionStatusTextAndColor,
    productVersionStatusColor,
    productVersionStatusText,
};
