import { AxiosRequestConfig } from "axios";
import range from "lodash/range";
import { asyncMap } from "modern-async";
import { PartialAndList } from "types";
import { productObj } from "utils/permutations";
import { uniqObjectsByKeys } from "utils/unique";

const MAX_PER_PAGE = 50;

interface PaginatedResponse<T> {
    page: number;
    per_page: number;
    total_items: number;
    total_pages: number;
    items: Array<T>;
}

type PaginatedFunction<T, P> = (
    requestParameters: P,
    options?: AxiosRequestConfig
) => Promise<PaginatedResponse<T>>;

const generateUnpaginatePromises = async <T, P>(
    paginatedFunction: PaginatedFunction<T, P>,
    requestParameters: P,
    options?: AxiosRequestConfig
): Promise<Array<Promise<PaginatedResponse<T>>>> => {
    const firstResultPromise = paginatedFunction(
        { ...requestParameters, _page: 1, _per_page: MAX_PER_PAGE },
        options
    );

    const firstResult = await firstResultPromise;

    const remainingResultPromises = range(
        2,
        firstResult.total_pages + 1,
        1
    ).map((page) =>
        paginatedFunction(
            { ...requestParameters, _page: page, _per_page: MAX_PER_PAGE },
            options
        )
    );

    return [firstResultPromise, ...remainingResultPromises];
};

const unpaginate = async <T, P>(
    paginatedFunction: PaginatedFunction<T, P>,
    requestParameters: P,
    options?: AxiosRequestConfig
): Promise<Array<T>> => {
    const results = await Promise.all(
        await generateUnpaginatePromises(
            paginatedFunction,
            requestParameters,
            options
        )
    );

    return results.map((result) => result.items).flat();
};

const unpaginateWithMultiParams = async <T, P>(
    paginatedFunction: PaginatedFunction<T, P>,
    requestParameters?: PartialAndList<P>,
    uniqueByKeys?: (keyof T)[],
    options?: AxiosRequestConfig
): Promise<Array<T>> => {
    if (requestParameters) {
        const items = (
            await Promise.all(
                (
                    await asyncMap(
                        productObj(requestParameters),
                        async (individualRequestParameters) =>
                            await generateUnpaginatePromises(
                                paginatedFunction,
                                individualRequestParameters as P
                            ),
                        Number.POSITIVE_INFINITY
                    )
                ).flat()
            )
        )
            .map((response) => response.items)
            .flat();

        if (items.length > 0 && uniqueByKeys && uniqueByKeys.length > 0) {
            return uniqObjectsByKeys(items, uniqueByKeys);
        } else {
            return items;
        }
    } else {
        return unpaginate(paginatedFunction, {} as P, options);
    }
};

export { unpaginate, unpaginateWithMultiParams };
