import { ProductVersion } from "@joshuins/builder";
import { usePage } from "components/Page";
import { Popup } from "components/Popup";

const ISSUES_WARNING = "issues-warning" as const;

const IssuesWarningPopup = () => {
    const { element } = usePage();
    const productVersion = element as unknown as ProductVersion | undefined;

    if (!productVersion) {
        return <></>;
    }

    return (
        <Popup name={ISSUES_WARNING}>
            {productVersion.issues.length === 0 && (
                <h2 className="m25 color-primary">
                    All issues are fixed, this product can be published.
                </h2>
            )}
            {productVersion.issues.length > 0 && (
                <>
                    <h2 className="m25 color-primary">
                        This version has some issues
                    </h2>
                    <p>
                        There are some issues that require your attention before
                        this product can be published:
                    </p>
                    <ul className="list-plain box">
                        {productVersion.issues.map((issue, index) => (
                            <li key={index}>
                                <span>
                                    <b>
                                        {issue.issue_kind
                                            .replace(/([A-Z])/g, " $1")
                                            .trim()}
                                    </b>{" "}
                                    ({issue.code})
                                </span>
                                {issue.description}
                            </li>
                        ))}
                    </ul>
                </>
            )}
        </Popup>
    );
};

export { IssuesWarningPopup, ISSUES_WARNING };
