import { FC, PropsWithChildren } from "react";
import { RoleAwareLink } from "contexts/BrandingProvider";
import NotAnchor from "./NotAnchor";
import { toggleDocumentClass } from "paul/native-dom-manipulation";

const HeaderTop: FC<PropsWithChildren> = ({ children }) => (
    <header id="top">
        <p id="logo">
            <RoleAwareLink to="/auth/login">Joshu</RoleAwareLink>
            <NotAnchor onClick={() => toggleDocumentClass("menu-active")} />
        </p>
        {children ? (
            children
        ) : (
            <nav id="skip">
                <ul>
                    <li>
                        <a href="#content">Skip to content (c)</a>
                    </li>
                </ul>
            </nav>
        )}
    </header>
);

export default HeaderTop;
