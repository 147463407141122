import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useApi } from "contexts/ApiProvider";

import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";

import { FullPageMessage2 } from "components/FullPageMessage";
import PasswordInput, { PASSWORD_REGEX } from "components/PasswordInput";

import axios from "axios";
import { hideLoader, showLoader } from "paul/native-dom-manipulation";
import { useBranding } from "contexts/BrandingProvider";
import { getMessageFromAxiosError } from "utils/axios-extras";

const passwordResetSchema = z.object({
    password: z.string().regex(PASSWORD_REGEX),
});
type PasswordResetType = z.infer<typeof passwordResetSchema>;

const DEFAULT_ERROR_TITLE = "There is a problem with the reset link";

const PasswordResetPage = () => {
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");
    const [emailFromToken, setEmailFromToken] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<{
        type: "full-page" | "label";
        title?: string;
        message: string;
    }>();
    const { sdkAuthForProcess, apiAuthProcess } = useApi();
    const { register, handleSubmit } = useForm<PasswordResetType>({
        resolver: zodResolver(passwordResetSchema),
        defaultValues: {
            password: "",
        },
    });
    const { generateNextUrl } = useBranding();
    const nextUrl = generateNextUrl();

    useEffect(() => {
        const verifyToken = async () => {
            let errorMessage_: string | undefined;

            // sdkAuthForProcess changes when the user logs in so the useEffect runs
            // again. So we check also if the emailFromToken has already been set so
            // that the sdkAuthForProcess method call doesn't happen again.
            if (token && !emailFromToken) {
                try {
                    const { email } =
                        await sdkAuthForProcess.passwordResetVerifyToken({
                            VerifyPasswordResetTokenRequest: {
                                token,
                            },
                        });
                    setEmailFromToken(email);
                } catch (error) {
                    if (axios.isAxiosError(error)) {
                        setEmailFromToken("");
                        errorMessage_ = getMessageFromAxiosError(error);
                    } else {
                        throw error;
                    }
                }
            } else if (!emailFromToken) {
                errorMessage_ = "Error";
            }

            setErrorMessage(
                errorMessage_
                    ? {
                          type: "full-page",
                          title: DEFAULT_ERROR_TITLE,
                          message: errorMessage_,
                      }
                    : undefined
            );
        };

        verifyToken();
    }, [sdkAuthForProcess, token, emailFromToken]);

    const onSubmit = async (data: PasswordResetType) => {
        showLoader();
        const newPassword = data.password;

        let errorMessage_: string | undefined;

        if (token) {
            try {
                await sdkAuthForProcess.passwordResetFinish({
                    FinishPasswordResetRequest: {
                        token,
                        new_password: newPassword,
                    },
                });
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    errorMessage_ = getMessageFromAxiosError(error);
                } else {
                    throw Error;
                }
            }
        } else {
            errorMessage_ = "Error";
        }

        if (errorMessage_) {
            setErrorMessage({
                type: "label",
                message: errorMessage_,
            });
            hideLoader();
        } else {
            setErrorMessage(undefined);
            apiAuthProcess.login(emailFromToken, newPassword, nextUrl);
        }
    };

    if (apiAuthProcess.authProcessErrorMessage) {
        hideLoader();
    }

    let determinedErrorMessage;
    if (errorMessage) {
        determinedErrorMessage = errorMessage;
        if (
            determinedErrorMessage.message ===
            "This link is invalid: ExpiredSignature"
        ) {
            determinedErrorMessage.title = "Link Expired";
            determinedErrorMessage.message =
                "Sorry, that link has expired. Please request a new link to sign in.";
        }
    } else if (apiAuthProcess.authProcessErrorMessage) {
        determinedErrorMessage = {
            type: "label",
            message: apiAuthProcess.authProcessErrorMessage,
        };
    }

    return (
        <main id="content">
            {determinedErrorMessage?.type === "full-page" ? (
                <FullPageMessage2
                    title={determinedErrorMessage.title || DEFAULT_ERROR_TITLE}
                    message={determinedErrorMessage.message}
                    iconType="triangle"
                    button={{
                        linkTo: "/auth/forgot-password",
                        text: "Try Again",
                    }}
                />
            ) : (
                <form className="form-aside" onSubmit={handleSubmit(onSubmit)}>
                    <header>
                        <h2>Set your new password</h2>
                    </header>
                    <PasswordInput {...register("password")} id="faj" />
                    <p className="submit">
                        {determinedErrorMessage?.message && (
                            <label id="failed" className="error">
                                {determinedErrorMessage.message}
                            </label>
                        )}
                        <button type="submit">Sign In</button>
                    </p>
                </form>
            )}
        </main>
    );
};

export default PasswordResetPage;
