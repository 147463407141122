import {
    createContext,
    FC,
    PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { TabInterface, usePage } from "components/Page";
import reject from "lodash/reject";
import { rejectFirstMatch } from "utils/array";
import {
    DocumentPacketTypeV1,
    DocumentPartV1,
    DocumentSchemaV1,
    DocumentTemplate,
    ProductDocumentsV1,
    ProductVersion,
} from "@joshuins/builder";
import camelCase from "lodash/camelCase";
import { useApi } from "contexts/ApiProvider";
import cloneDeep from "lodash/cloneDeep";
import { ParametersExceptFirst } from "types";
import { useImmer } from "use-immer";
import upperFirst from "lodash/upperFirst";
import difference from "lodash/difference";
import { asyncMap } from "modern-async";
import { randomString } from "utils/string";

interface DocumentsProviderInterface {
    allowEditing: boolean;
    accessors: {
        productDocuments: ProductDocumentsV1 | undefined;
        usedCodes: { part: string[]; document: string[] } | undefined;
        generateUniqueRandomCode: (
            for_: "part" | "document"
        ) => string | undefined;
        documentTemplates: Record<string, DocumentTemplate>;
        documentAndIndex: (documentCode: DocumentSchemaV1["code"]) =>
            | {
                  document: DocumentSchemaV1;
                  index: number;
              }
            | { document: undefined; index: undefined };
        allDocuments: () => DocumentSchemaV1[] | undefined;
        allParts: DocumentPartV1[] | undefined;
        partAndIndex: (
            documentCode: DocumentSchemaV1["code"],
            partCode: DocumentPartV1["code"]
        ) =>
            | { part: DocumentPartV1; index: number }
            | { part: undefined; index: undefined };
        documentHasSinglePart: (
            documentCode: DocumentSchemaV1["code"]
        ) => boolean;
        parts: (
            documentCode: DocumentSchemaV1["code"]
        ) => DocumentPartV1[] | undefined;
        otherDocumentsWithSingleParts: (
            documentCode: DocumentSchemaV1["code"]
        ) => DocumentSchemaV1[] | undefined;
        otherDocumentsWithZeroTwoOrMoreParts: (
            documentCode: DocumentSchemaV1["code"]
        ) => DocumentSchemaV1[] | undefined;
        documentTemplate: (documentTemplateId: string) => DocumentTemplate;
    };
    mutators: {
        setProductDocuments: (productDocuments: ProductDocumentsV1) => void;
        setDocuments: (documents: DocumentSchemaV1[]) => Promise<void>;
        addDocument: (document: DocumentSchemaV1) => Promise<void>;
        updateDocument: (
            oldCode: DocumentPartV1["code"],
            document: DocumentSchemaV1
        ) => Promise<void>;
        removeDocument: (code: DocumentSchemaV1["code"]) => Promise<void>;
        setParts: (
            documentCode: DocumentSchemaV1["code"],
            parts: DocumentPartV1[]
        ) => Promise<void>;
        addPartToExistingDocument: (
            documentCode: DocumentSchemaV1["code"],
            part: DocumentPartV1
        ) => Promise<void>;
        addPartToNewDocument: (part: DocumentPartV1) => Promise<void>;
        updatePart: (
            documentCode: DocumentSchemaV1["code"],
            oldPartCode: DocumentPartV1["code"],
            part: DocumentPartV1
        ) => Promise<void>;
        removePart: (
            documentCode: DocumentSchemaV1["code"],
            partCode: DocumentPartV1["code"]
        ) => Promise<void>;
        movePart: (
            oldDocumentCode: DocumentSchemaV1["code"],
            newDocumentCode: DocumentSchemaV1["code"],
            part: DocumentPartV1
        ) => Promise<void>;
        updateDocumentTemplate: (
            documentTemplateId: string,
            documentTemplate: DocumentTemplate
        ) => void;
    };
}

const tabToPacketType = (tab: TabInterface) => {
    return DocumentPacketTypeV1[
        upperFirst(camelCase(tab.path)) as keyof typeof DocumentPacketTypeV1
    ];
};

const getDocumentsForTab = (
    productDocuments: ProductDocumentsV1,
    tab: TabInterface,
    createIfNotExist = false
) => {
    const packet = productDocuments.packets[tabToPacketType(tab)];
    if (packet) {
        return packet.documents;
    } else if (createIfNotExist) {
        const documents: DocumentSchemaV1[] = [];
        productDocuments.packets[tabToPacketType(tab)] = {
            documents,
        };
        return documents;
    }
};

const DocumentsContext = createContext<DocumentsProviderInterface | undefined>(
    undefined
);

const useDocuments = () => {
    const context = useContext(DocumentsContext);

    if (context === undefined) {
        throw Error(
            "useDocuments must be used inside a DocumentsProvider context"
        );
    }

    return context;
};

const DocumentsProvider: FC<PropsWithChildren> = ({ children }) => {
    const {
        element,
        activeTab: getActiveTab,
        tryCatchAndRaiseError,
    } = usePage();
    const { sdkBuilder } = useApi();
    const activeTab = getActiveTab();
    const productVersion = element as unknown as ProductVersion | undefined;
    const [productDocuments, setProductDocuments_] =
        useState<ProductDocumentsV1>();
    const [usedCodes, setUsedCodes] = useState<{
        part: string[];
        document: string[];
    }>();
    const [documentTemplates, setDocumentTemplates] = useImmer<
        Record<string, DocumentTemplate>
    >({});

    const documents =
        productDocuments && activeTab
            ? getDocumentsForTab(productDocuments, activeTab)
            : undefined;

    const documentAndIndex = useCallback(
        (documentCode: DocumentSchemaV1["code"]) => {
            if (documents) {
                const index = documents.findIndex(
                    (document) => document.code === documentCode
                );
                if (index !== -1) {
                    return {
                        document: documents[index],
                        index,
                    };
                }
            }
            return {
                document: undefined,
                index: undefined,
            };
        },
        [documents]
    );

    const allDocuments = useCallback(() => documents, [documents]);

    const partAndIndex = useCallback(
        (
            documentCode: DocumentSchemaV1["code"],
            partCode: DocumentPartV1["code"]
        ) => {
            const { document } = documentAndIndex(documentCode);
            if (!document) {
                return {
                    part: undefined,
                    index: undefined,
                };
            }

            const index = document.parts.findIndex(
                (part) => part.code === partCode
            );
            if (index !== -1) {
                return {
                    part: document.parts[index],
                    index,
                };
            } else {
                return {
                    part: undefined,
                    index: undefined,
                };
            }
        },
        [documentAndIndex]
    );

    const documentHasSinglePart = useCallback(
        (documentCode: DocumentSchemaV1["code"]) => {
            const { document } = documentAndIndex(documentCode);
            return document ? document.parts.length === 1 : false;
        },
        [documentAndIndex]
    );

    const documentsWithSingleParts = useMemo(
        () => allDocuments()?.filter((document) => document.parts.length === 1),
        [allDocuments]
    );

    const documentsWithZeroTwoOrMoreParts = useMemo(() => {
        const allDocuments_ = allDocuments();
        if (allDocuments_ === undefined) {
            return;
        }
        return reject(allDocuments_, (document) => document.parts.length === 1);
    }, [allDocuments]);

    const otherDocumentsWithSingleParts = useCallback(
        (documentCode: DocumentSchemaV1["code"]) => {
            if (documentsWithSingleParts === undefined) {
                return undefined;
            }
            const rejected = rejectFirstMatch(
                documentsWithSingleParts,
                (document) => document.code === documentCode
            );
            return rejected;
        },
        [documentsWithSingleParts]
    );

    const otherDocumentsWithZeroTwoOrMoreParts = useCallback(
        (documentCode: DocumentSchemaV1["code"]) => {
            if (documentsWithZeroTwoOrMoreParts === undefined) {
                return undefined;
            }

            return rejectFirstMatch(
                documentsWithZeroTwoOrMoreParts,
                (document) => document.code === documentCode
            );
        },
        [documentsWithZeroTwoOrMoreParts]
    );

    const parts = useCallback(
        (documentCode: DocumentSchemaV1["code"]) => {
            const { document } = documentAndIndex(documentCode);
            return document?.parts;
        },
        [documentAndIndex]
    );

    const allParts = useMemo(
        () =>
            allDocuments()?.reduce((previousValue, document) => {
                previousValue.push(...document.parts);
                return previousValue;
            }, [] as DocumentPartV1[]),
        [allDocuments]
    );

    const documentTemplate = useCallback(
        (documentTemplateId: string) => documentTemplates[documentTemplateId],
        [documentTemplates]
    );

    const draft = useCallback(
        () => cloneDeep(productDocuments),
        [productDocuments]
    );

    const regenerateUsedCodes = useCallback(
        (productDocuments: ProductDocumentsV1) => {
            const [documentCodes, partCodes] = Object.entries(
                productDocuments.packets
            ).reduce(
                (
                    [documentCodeAccumulator, partCodeAccumulator],
                    [, packet]
                ) => {
                    const [documentCodeAccumulator_, partCodeAccumulator_] =
                        packet.documents.reduce(
                            (
                                [documentCodeAccumulator, partCodeAccumulator],
                                document
                            ) => {
                                const partCodeAccumulator_ = document.parts.map(
                                    (part) => part.code
                                );

                                return [
                                    [...documentCodeAccumulator, document.code],
                                    [
                                        ...partCodeAccumulator,
                                        ...partCodeAccumulator_,
                                    ],
                                ];
                            },
                            [[], []] as [string[], string[]]
                        );
                    return [
                        [
                            ...documentCodeAccumulator,
                            ...documentCodeAccumulator_,
                        ],
                        [...partCodeAccumulator, ...partCodeAccumulator_],
                    ];
                },
                [[], []] as [string[], string[]]
            );

            setUsedCodes({
                document: documentCodes,
                part: partCodes,
            });
        },
        []
    );

    const regenerateDocumentTemplates = useCallback(
        async (documents: DocumentSchemaV1[]) => {
            const currentDocumentTemplateIds = Object.keys(documentTemplates);
            const newDocumentTemplateIds = documents.reduce(
                (accumulator, document) => {
                    return [
                        ...accumulator,
                        ...document.parts.map((part) => part.template_id),
                    ];
                },
                [] as string[]
            );
            const documentTemplateIdsToDelete = difference(
                currentDocumentTemplateIds,
                newDocumentTemplateIds
            );
            const newDocumentTemplateIdsToAdd = difference(
                newDocumentTemplateIds,
                currentDocumentTemplateIds
            );
            const newDocumentTemplatesToAdd = await asyncMap(
                newDocumentTemplateIdsToAdd,
                (documentTemplateId) =>
                    sdkBuilder.getDocumentTemplate({ id: documentTemplateId }),
                Number.POSITIVE_INFINITY
            );

            setDocumentTemplates((draft) => {
                for (const documentTempateId of documentTemplateIdsToDelete) {
                    delete draft[documentTempateId];
                }
                for (const documentTemplate of newDocumentTemplatesToAdd) {
                    draft[documentTemplate.id] = documentTemplate;
                }
            });
        },
        [documentTemplates, sdkBuilder, setDocumentTemplates]
    );

    const generateUniqueRandomCode = (
        for_: keyof NonNullable<typeof usedCodes>
    ) => {
        if (!usedCodes) {
            return;
        }
        let randomCode: string | undefined;
        while (!randomCode || usedCodes[for_].includes(randomCode)) {
            randomCode = randomString(10, {
                lowercaseCharacters: false,
                firstLetterCharacter: true,
            });
        }
        return `doc.${randomCode}`;
    };

    useEffect(() => {
        if (!documents || documents.length === 0) {
            return;
        }
        regenerateDocumentTemplates(documents);
    }, [documents, regenerateDocumentTemplates]);

    const setProductDocuments = useCallback(
        (productDocuments: ProductDocumentsV1) => {
            setProductDocuments_(productDocuments);
            regenerateUsedCodes(productDocuments);
        },
        [regenerateUsedCodes]
    );

    // Typing for this function: https://stackoverflow.com/a/61212868
    // Takes a function with the DocumentSchemaV1 as the first parameter
    // and returns an async function without that first parameter with
    // only the remaining parameters. The idea is that the mutator function
    // gets wrapped with logic that:
    // 1) clones the existing DocumentSchemaV1 into a draft
    // 2) runs the mutator on the draft by passing in the new draft
    //    DocumentSchemaV1 as the first parameter
    // 3) Updated the mutated DocumentSchemaV1 in the API
    // 4) Updates the state
    const wrapMutator_ = <
        MutatorFunction extends (
            documentsDraft: DocumentSchemaV1[],
            ...rest: never[]
        ) => void,
    >(
        mutator: MutatorFunction
    ): ((...rest: ParametersExceptFirst<MutatorFunction>) => Promise<void>) => {
        const wrappedFunction = async (
            ...rest: ParametersExceptFirst<MutatorFunction>
        ) => {
            if (!productVersion || !activeTab) {
                return;
            }
            const productDocumentsDraft = draft();
            if (!productDocumentsDraft) {
                return;
            }
            const draftDocuments = getDocumentsForTab(
                productDocumentsDraft,
                activeTab,
                true
            );
            if (!draftDocuments) {
                return;
            }
            mutator(draftDocuments, ...rest);
            tryCatchAndRaiseError(async () => {
                await regenerateDocumentTemplates(draftDocuments);
                productVersion.schema.spec.documents = productDocumentsDraft;
                await sdkBuilder.updateProductVersion({
                    id: productVersion.id,
                    UpdateProductVersion: { schema: productVersion.schema },
                });
                setProductDocuments(productVersion.schema.spec.documents);
            });
        };
        return wrappedFunction;
    };
    const wrapMutator = useCallback(wrapMutator_, [
        activeTab,
        draft,
        productVersion,
        regenerateDocumentTemplates,
        sdkBuilder,
        setProductDocuments,
        tryCatchAndRaiseError,
    ]);

    const setDocuments = useCallback(
        (documentsDraft: DocumentSchemaV1[], documents: DocumentSchemaV1[]) => {
            // "Setting length to a value smaller than the current length truncates the
            // array — elements beyond the new length are deleted."
            // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/length#description
            documentsDraft.length = 0;
            documentsDraft.push(...documents);
        },
        []
    );

    const addDocument = useCallback(
        (documentsDraft: DocumentSchemaV1[], document: DocumentSchemaV1) => {
            documentsDraft.push(document);
        },
        []
    );

    const updateDocument = useCallback(
        (
            documentsDraft: DocumentSchemaV1[],
            oldCode: DocumentSchemaV1["code"],
            document: DocumentSchemaV1
        ) => {
            const documentIndex = documentsDraft.findIndex(
                (document_) => document_.code === oldCode
            );
            if (documentIndex !== -1) {
                documentsDraft[documentIndex] = document;
            }
        },
        []
    );

    const removeDocument = useCallback(
        (
            documentsDraft: DocumentSchemaV1[],
            code: DocumentSchemaV1["code"]
        ) => {
            const documentIndex = documentsDraft.findIndex(
                (document) => document.code === code
            );

            if (documentIndex !== -1) {
                documentsDraft.splice(documentIndex, 1);
            }
        },
        []
    );

    const setParts = useCallback(
        (
            documentsDraft: DocumentSchemaV1[],
            documentCode: DocumentSchemaV1["code"],
            parts: DocumentPartV1[]
        ) => {
            const document = documentsDraft.find(
                (document) => document.code === documentCode
            );

            if (document) {
                document.parts = parts;
            }
        },
        []
    );

    const addPartToExistingDocument = useCallback(
        (
            documentsDraft: DocumentSchemaV1[],
            documentCode: DocumentSchemaV1["code"],
            part: DocumentPartV1
        ) => {
            const document = documentsDraft.find(
                (document) => document.code === documentCode
            );
            if (document) {
                document.parts.push(part);
            }
        },
        []
    );

    const addPartToNewDocument = useCallback(
        (documentsDraft: DocumentSchemaV1[], part: DocumentPartV1) => {
            documentsDraft.push({
                name: part.name ?? "(no name)",
                // @TODO: This code shouldn't be autogenerated - rather, if the
                // user doesn't choose a containing document in the New Part
                // popup, they should be presented with another field for
                // the code of the document
                code: `${part.code}-document`,
                include: {
                    Always: {
                        removable: false,
                    },
                },
                parts: [part],
            });
        },
        []
    );

    const updatePart = useCallback(
        (
            documentsDraft: DocumentSchemaV1[],
            documentCode: DocumentSchemaV1["code"],
            oldPartCode: DocumentPartV1["code"],
            part: DocumentPartV1
        ) => {
            const document = documentsDraft.find(
                (document) => document.code === documentCode
            );

            if (document) {
                const partIndex = document.parts.findIndex(
                    (part_) => part_.code === oldPartCode
                );
                if (partIndex !== -1) {
                    document.parts[partIndex] = part;
                }
            }
        },
        []
    );

    const removePart = useCallback(
        (
            documentsDraft: DocumentSchemaV1[],
            documentCode: DocumentSchemaV1["code"],
            partCode: DocumentPartV1["code"]
        ) => {
            const document = documentsDraft.find(
                (document) => document.code === documentCode
            );

            if (document) {
                if (document.parts.length === 1) {
                    const documentIndex = documentsDraft.findIndex(
                        (document) => document.code === documentCode
                    );

                    if (documentIndex !== -1) {
                        documentsDraft.splice(documentIndex, 1);
                    }
                } else {
                    const partIndex = document.parts.findIndex(
                        (part) => part.code === partCode
                    );
                    if (partIndex !== -1) {
                        document.parts.splice(partIndex, 1);
                    }
                }
            }
        },
        []
    );

    const movePart = useCallback(
        (
            documentsDraft: DocumentSchemaV1[],
            oldDocumentCode: DocumentSchemaV1["code"],
            newDocumentCode: DocumentSchemaV1["code"],
            part: DocumentPartV1
        ) => {
            const oldDocument = documentsDraft.find(
                (document) => document.code === oldDocumentCode
            );

            const newDocument = documentsDraft.find(
                (document) => document.code === newDocumentCode
            );

            if (!oldDocument || !newDocument) {
                return;
            }

            const oldPartIndex = oldDocument.parts.findIndex(
                (part_) => part_.code === part.code
            );
            if (oldPartIndex !== -1) {
                oldDocument.parts.splice(oldPartIndex, 1);
                newDocument.parts.push(part);
            }
        },
        []
    );

    const updateDocumentTemplate = useCallback(
        (documentTemplateId: string, documentTemplate: DocumentTemplate) => {
            setDocumentTemplates((draft) => {
                draft[documentTemplateId] = documentTemplate;
            });
        },
        [setDocumentTemplates]
    );

    const mutators = useMemo(() => {
        return {
            setProductDocuments,
            updateDocumentTemplate,
            setDocuments: wrapMutator(setDocuments),
            addDocument: wrapMutator(addDocument),
            updateDocument: wrapMutator(updateDocument),
            removeDocument: wrapMutator(removeDocument),
            setParts: wrapMutator(setParts),
            addPartToExistingDocument: wrapMutator(addPartToExistingDocument),
            addPartToNewDocument: wrapMutator(addPartToNewDocument),
            updatePart: wrapMutator(updatePart),
            removePart: wrapMutator(removePart),
            movePart: wrapMutator(movePart),
        };
    }, [
        addDocument,
        addPartToExistingDocument,
        addPartToNewDocument,
        movePart,
        removeDocument,
        removePart,
        setDocuments,
        setParts,
        setProductDocuments,
        updateDocument,
        updateDocumentTemplate,
        updatePart,
        wrapMutator,
    ]);

    if (!productVersion) {
        return <></>;
    }

    return (
        <DocumentsContext.Provider
            value={{
                allowEditing: !productVersion.is_published,
                mutators,
                accessors: {
                    productDocuments,
                    usedCodes,
                    generateUniqueRandomCode,
                    documentTemplates,
                    allDocuments,
                    documentAndIndex,
                    allParts,
                    parts,
                    partAndIndex,
                    documentHasSinglePart,
                    otherDocumentsWithSingleParts,
                    otherDocumentsWithZeroTwoOrMoreParts,
                    documentTemplate,
                },
            }}
        >
            {children}
        </DocumentsContext.Provider>
    );
};

export { DocumentsProvider, useDocuments, tabToPacketType };
