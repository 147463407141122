/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { FC, useCallback, useEffect } from "react";
import {
    ExportFormatVersion,
    ExportRule,
    ExportRuleInterval,
    ExportTarget,
    SshIdentityKeyType,
} from "@joshuins/system";
import { useApi } from "contexts/ApiProvider";
import { usePopup } from "contexts/PopupProvider";
import { unpaginate, unpaginateWithMultiParams } from "components/sdk";
import { AlertCategory, usePage } from "components/Page";
import { ConfirmationPopup, createFormPopup } from "components/Popup";
import axios from "axios";
import classNames from "classnames";
import { z } from "zod";
import { Select } from "components/ReactHookFormUncontrolledComponents";
import { MenuItem as SelectMenuItem } from "components/Select";
import FormControl from "@mui/material/FormControl";
import NotAnchor from "components/NotAnchor";
import { useDataExport } from "./DataExportProvider";
import { SortableContext } from "@dnd-kit/sortable";
import MenuPopover, { MenuItem } from "components/MenuPopover";
import groupBy from "lodash/groupBy";
import { Footer } from "components/MainPane";
import { textCenterEmptyTab } from "paul/native-dom-manipulation";
import { getMessageFromAxiosError } from "utils/axios-extras";

const AddTargetSchema = z.object({
    hostname: z.string().min(1, "This field is required"),
    port: z.string().regex(/^\d+$/).optional(),
    username: z.string(),
    path: z.string().optional(),
    key_type: z
        .enum([
            SshIdentityKeyType.EcdsaSha2NistP256,
            SshIdentityKeyType.EcdsaSha2NistP384,
            SshIdentityKeyType.Ed25519,
            SshIdentityKeyType.Rsa2048,
            SshIdentityKeyType.Rsa4096,
        ])
        .optional(),
});

type AddTargetType = z.infer<typeof AddTargetSchema>;

const {
    FormPopup: AddTargetFormPopup,
    useFormReturnRef: useAddTargetFormReturnRef,
} = createFormPopup(AddTargetSchema);

const AddTargetPopup: FC = () => {
    const { sdkSystem } = useApi();
    const { tryCatchAndRaiseError } = usePage();
    const { popupData, isPopupOpen } = usePopup();
    const { dataExportDispatch } = useDataExport();
    const {
        formReturnRefCallback,
        formReturn: { reset },
    } = useAddTargetFormReturnRef();
    const existingTargetIdToUpdate = popupData?.targetId as string | undefined;

    useEffect(() => {
        const getTarget = async () => {
            if (
                !existingTargetIdToUpdate ||
                !isPopupOpen("add-edit-target") ||
                !reset
            ) {
                return;
            }
            tryCatchAndRaiseError(async () => {
                const exsitingTarget = await sdkSystem.getExportTarget({
                    id: existingTargetIdToUpdate,
                });
                reset({
                    hostname: exsitingTarget.config.Sftp.hostname,
                    port:
                        exsitingTarget.config.Sftp.port?.toString() ??
                        undefined,
                    path: exsitingTarget.config.Sftp.path ?? undefined,
                    username: exsitingTarget.config.Sftp.username ?? undefined,
                });
            });
        };
        getTarget();
    }, [
        existingTargetIdToUpdate,
        isPopupOpen,
        reset,
        sdkSystem,
        tryCatchAndRaiseError,
    ]);

    const onSubmit = useCallback(
        async (data: AddTargetType) => {
            tryCatchAndRaiseError(async () => {
                if (existingTargetIdToUpdate) {
                    const updatedTarget = await sdkSystem.updateExportTarget({
                        id: existingTargetIdToUpdate,
                        UpdateExportTarget: {
                            config: {
                                Sftp: {
                                    ...data,
                                    port: data.port
                                        ? parseInt(data.port)
                                        : undefined,
                                },
                            },
                        },
                    });
                    dataExportDispatch({
                        action: "UpdateTarget",
                        target: updatedTarget,
                    });
                } else {
                    if (!data.key_type) {
                        return;
                    }
                    const newTarget = await sdkSystem.createExportTarget({
                        CreateExportTarget: {
                            Sftp: {
                                config: {
                                    ...data,
                                    port: data.port
                                        ? parseInt(data.port)
                                        : undefined,
                                },
                                key_type: data.key_type,
                            },
                        },
                    });
                    dataExportDispatch({
                        action: "AddTarget",
                        target: newTarget,
                    });
                }
            });
        },
        [
            tryCatchAndRaiseError,
            existingTargetIdToUpdate,
            sdkSystem,
            dataExportDispatch,
        ]
    );

    return (
        <AddTargetFormPopup
            name="add-edit-target"
            defaultValues={{
                hostname: "",
                port: "",
                username: "",
                path: "",
                key_type: SshIdentityKeyType.Ed25519,
            }}
            onSubmit={onSubmit}
            submitText="Save"
            mobileSubmitText="Save"
            formReturnRefCallback={formReturnRefCallback}
        >
            {({ register, control, formState: { errors } }) => (
                <>
                    <header>
                        <h2>Export Target</h2>
                    </header>
                    <p className="c50">
                        <label htmlFor="ppb">Hostname</label>
                        <input
                            type="text"
                            id="ppb"
                            {...register("hostname")}
                            disabled={false}
                        />
                        {errors.hostname && (
                            <label
                                id="ppb-error"
                                className="error"
                                htmlFor="ppb"
                            >
                                {errors.hostname.message}
                            </label>
                        )}
                    </p>
                    <p className="c50">
                        <label htmlFor="ppc">Port</label>
                        <input type="number" id="ppc" {...register("port")} />
                        {errors.port && (
                            <label
                                id="ppc-error"
                                className="error"
                                htmlFor="ppc"
                            >
                                {errors.port.message}
                            </label>
                        )}
                    </p>
                    <p>
                        <label htmlFor="ppd">Path</label>
                        <input type="text" id="ppd" {...register("path")} />
                        {errors.path && (
                            <label
                                id="ppd-error"
                                className="error"
                                htmlFor="ppd"
                            >
                                {errors.path.message}
                            </label>
                        )}
                    </p>
                    <p>
                        <label htmlFor="ppe">Username</label>
                        <input type="text" id="ppe" {...register("username")} />
                        {errors.username && (
                            <label
                                id="ppe-error"
                                className="error"
                                htmlFor="ppe"
                            >
                                {errors.username.message}
                            </label>
                        )}
                    </p>
                    {!existingTargetIdToUpdate && (
                        <FormControl
                            fullWidth
                            error={!!errors.key_type}
                            className={classNames({
                                "has-error": !!errors.key_type,
                            })}
                        >
                            <span className="label">Key Type</span>
                            <Select
                                labelId="key-type-select-label"
                                id="zpde"
                                name="key_type"
                                control={control}
                            >
                                {Object.values(SshIdentityKeyType).map(
                                    (value) => (
                                        <SelectMenuItem
                                            key={value}
                                            value={value}
                                        >
                                            {value}
                                        </SelectMenuItem>
                                    )
                                )}
                            </Select>
                            {errors.key_type && (
                                <label
                                    id="zpde-error"
                                    className="error"
                                    htmlFor="zpde"
                                >
                                    {errors.key_type.message}
                                </label>
                            )}
                        </FormControl>
                    )}
                </>
            )}
        </AddTargetFormPopup>
    );
};

const DeleteTargetPopup: FC = () => {
    const { popupData } = usePopup();
    const { dataExportDispatch } = useDataExport();
    const { tryCatchAndRaiseError } = usePage();
    const { sdkSystem } = useApi();
    const target = popupData?.exportTarget as ExportTarget | undefined;

    const onSubmit = async () => {
        if (!target) {
            return;
        }
        tryCatchAndRaiseError(async () => {
            const rules = await unpaginate(sdkSystem.allExportRules, {
                // target_id: target.id,
            });
            const groupedExportRules = groupBy(rules, "target_id");

            if (groupedExportRules[target.id]) {
                await Promise.allSettled(
                    groupedExportRules[target.id].map((rule) =>
                        sdkSystem.deleteExportRule({
                            id: rule.id,
                        })
                    )
                );
            }
            await sdkSystem.deleteExportTarget({ id: target.id });

            dataExportDispatch({
                action: "RemoveTarget",
                targetId: target.id,
            });
        });
    };

    return (
        <ConfirmationPopup
            name="delete-target"
            submitText="Delete"
            onSubmit={onSubmit}
        >
            <header>
                <h2>Are you sure?</h2>
                <p className="size-14">
                    This export target and all its rules will be deleted.
                </p>
            </header>
        </ConfirmationPopup>
    );
};

const AddRuleSchema = z.object({
    enabled: z.boolean(),
    interval: z.enum([ExportRuleInterval.Hourly, ExportRuleInterval.Daily]),
    next_trigger_at: z.string(),
    format_version: z.enum([ExportFormatVersion.V1]),
    production: z.boolean(),
});

type AddRuleType = z.infer<typeof AddRuleSchema>;
type AddEditRulePopupData = { targetId: string; ruleId: string | undefined };

const {
    FormPopup: AddRuleFormPopup,
    useFormReturnRef: useAddRuleFormReturnRef,
} = createFormPopup(AddRuleSchema);

const AddRulePopup: FC = () => {
    const { sdkSystem } = useApi();
    const { addAlertMessages } = usePage();
    const {
        formReturnRefCallback,
        formReturn: { reset },
    } = useAddRuleFormReturnRef();
    const { dataExportDispatch } = useDataExport();
    const { popupData, isPopupOpen } = usePopup();
    const targetAndRule = popupData?.targetAndRule as
        | AddEditRulePopupData
        | undefined;

    useEffect(() => {
        const getTarget = async () => {
            if (
                !targetAndRule?.ruleId ||
                !isPopupOpen("add-edit-rule") ||
                !reset
            ) {
                return;
            }

            try {
                const exisitingRule = await sdkSystem.getExportRule({
                    id: targetAndRule?.ruleId,
                });
                if (exisitingRule)
                    reset({
                        enabled: exisitingRule.enabled,
                        interval: exisitingRule.interval,
                        next_trigger_at:
                            exisitingRule.next_trigger_at || undefined,
                        production: exisitingRule.production,
                    });
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    addAlertMessages({
                        message: getMessageFromAxiosError(error),
                        category: AlertCategory.ALERT,
                    });
                } else {
                    throw error;
                }
            }
        };
        getTarget();
    }, [addAlertMessages, isPopupOpen, reset, sdkSystem, targetAndRule]);

    const onSubmit = useCallback(
        async (data: AddRuleType) => {
            if (!targetAndRule?.targetId) {
                return;
            }
            try {
                if (targetAndRule.ruleId) {
                    const updatedRule = await sdkSystem.updateExportRule({
                        id: targetAndRule.ruleId,
                        UpdateExportRule: {
                            ...data,
                        },
                    });
                    dataExportDispatch({
                        action: "UpdateRule",
                        targetId: updatedRule.target_id,
                        rule: updatedRule,
                    });
                } else {
                    const rule = await sdkSystem.createExportRule({
                        CreateExportRule: {
                            ...data,
                            next_trigger_at: data.next_trigger_at || undefined,
                            target_id: targetAndRule?.targetId,
                            format_version: ExportFormatVersion.V1,
                            production: data.production,
                        },
                    });
                    dataExportDispatch({
                        action: "AddRule",
                        targetId: rule.target_id,
                        rule: rule,
                    });
                }
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    addAlertMessages({
                        message: getMessageFromAxiosError(error),
                        category: AlertCategory.ALERT,
                    });
                } else {
                    throw error;
                }
            }
        },
        [targetAndRule, sdkSystem, dataExportDispatch, addAlertMessages]
    );

    return (
        <AddRuleFormPopup
            name="add-edit-rule"
            defaultValues={{
                enabled: true,
                interval: ExportRuleInterval.Daily,
                next_trigger_at: undefined,
                format_version: ExportFormatVersion.V1,
                production: true,
            }}
            onSubmit={onSubmit}
            submitText="Save"
            mobileSubmitText="Save"
            formReturnRefCallback={formReturnRefCallback}
        >
            {({ register, control, formState: { errors } }) => (
                <>
                    <header>
                        <h2>Export Rule</h2>
                    </header>
                    <p className="check font-medium color-primary m10">
                        <input
                            type="checkbox"
                            id="zpd"
                            {...register("enabled")}
                        />
                        <label htmlFor="zpd" className="small">
                            Enabled
                        </label>
                        {errors.enabled && (
                            <label
                                id="zpd-error"
                                className="error"
                                htmlFor="zpd"
                            >
                                {errors.enabled.message}
                            </label>
                        )}
                    </p>
                    <div className="div-as-p">
                        <FormControl
                            fullWidth
                            error={!!errors.interval}
                            className={classNames({
                                "has-error": !!errors.interval,
                            })}
                        >
                            <span className="label">Interval</span>
                            <Select
                                labelId="interval-type-select-label"
                                id="zpe"
                                name="interval"
                                control={control}
                            >
                                {[
                                    ExportRuleInterval.Hourly,
                                    ExportRuleInterval.Daily,
                                ].map((value) => (
                                    <SelectMenuItem key={value} value={value}>
                                        {value}
                                    </SelectMenuItem>
                                ))}
                            </Select>
                            {errors.interval && (
                                <label
                                    id="zpe-error"
                                    className="error"
                                    htmlFor="zpe"
                                >
                                    {errors.interval.message}
                                </label>
                            )}
                        </FormControl>
                    </div>
                    <p>
                        <label htmlFor="zpd" className="small">
                            Next Trigger At
                        </label>
                        <input
                            type="text"
                            id="zpf"
                            {...register("next_trigger_at")}
                        />
                        {errors.next_trigger_at && (
                            <label
                                id="zpf-error"
                                className="error"
                                htmlFor="zpf"
                            >
                                {errors.next_trigger_at.message}
                            </label>
                        )}
                    </p>
                    <p className="check font-medium color-primary">
                        <input
                            type="checkbox"
                            id="zprd"
                            {...register("production")}
                        />
                        <label htmlFor="zprd" className="small">
                            Use Production Data
                        </label>
                        {errors.production && (
                            <label
                                id="zprd-error"
                                className="error"
                                htmlFor="zprd"
                            >
                                {errors.production.message}
                            </label>
                        )}
                    </p>
                    <FormControl
                        fullWidth
                        error={!!errors.format_version}
                        className={classNames({
                            "has-error": !!errors.format_version,
                        })}
                    >
                        <span className="label">Format Version</span>
                        <Select
                            labelId="format-version-select-label"
                            id="zpeb"
                            name="format_version"
                            control={control}
                        >
                            {[ExportFormatVersion.V1].map((value) => (
                                <SelectMenuItem key={value} value={value}>
                                    {value}
                                </SelectMenuItem>
                            ))}
                        </Select>
                        {errors.format_version && (
                            <label
                                id="zpeb-error"
                                className="error"
                                htmlFor="zpeb"
                            >
                                {errors.format_version.message}
                            </label>
                        )}
                    </FormControl>
                </>
            )}
        </AddRuleFormPopup>
    );
};

const DeleteRulePopup: FC = () => {
    const { popupData } = usePopup();
    const { tryCatchAndRaiseError } = usePage();
    const { dataExportDispatch } = useDataExport();
    const { sdkSystem } = useApi();
    const rule = popupData?.rule as ExportRule | undefined;

    const onSubmit = async () => {
        if (!rule) {
            return;
        }
        tryCatchAndRaiseError(async () => {
            await sdkSystem.deleteExportRule({
                id: rule.id,
            });

            dataExportDispatch({
                action: "RemoveRule",
                targetId: rule.target_id,
                rule: rule,
            });
        });
    };

    return (
        <ConfirmationPopup
            name="delete-rule"
            submitText="Delete"
            onSubmit={onSubmit}
        >
            <header>
                <h2>Are you sure?</h2>
                <p className="size-14">This export rule will be deleted.</p>
            </header>
        </ConfirmationPopup>
    );
};

const ExportRuleRow: FC<{
    targetId: string;
    ruleId: string;
}> = ({ targetId, ruleId }) => {
    const { openPopup } = usePopup();
    const {
        itemGetters: { ruleAndIndex, targetAndIndex },
    } = useDataExport();
    const { rule, index: ruleIndex } = ruleAndIndex(targetId, ruleId);
    const { target } = targetAndIndex(targetId);

    if (!rule) {
        return <></>;
    }

    const menuItems: MenuItem[] = [];
    const numRules = target?.rules?.length;
    menuItems.push({
        key: `${ruleId}-delete-rule`,
        label: "Delete Export Rule",
        icon: "trash",
        onClick: () => {
            openPopup("delete-rule", {
                rule,
            });
        },
    });

    return (
        <MenuPopover
            menuItems={menuItems}
            toggleButtonLabel={`Toggle${rule.id ? ` ${rule.id}` : ""}`}
            style={{
                ...(numRules !== undefined && {
                    zIndex: numRules - ruleIndex,
                }),
            }}
        >
            {({ ToggleButton, Menu }) => (
                <>
                    <NotAnchor
                        className="toggle-sub"
                        onClick={() => {
                            openPopup("add-edit-rule", {
                                targetAndRule: {
                                    targetId: rule.target_id,
                                    ruleId: rule.id,
                                },
                            });
                        }}
                    >
                        {rule.interval}, next trigger at {rule.next_trigger_at}.
                    </NotAnchor>
                    {ToggleButton}
                    {Menu}
                </>
            )}
        </MenuPopover>
    );
};

const ExportTargetRow: FC<{
    targetId: string;
}> = ({ targetId }) => {
    const {
        itemGetters: {
            allTargets: getAllTargets,
            targetAndIndex: getTargetAndIndex,
        },
    } = useDataExport();
    const { openPopup } = usePopup();

    const { target, index: targetIndex } = getTargetAndIndex(targetId);

    if (!target) {
        return <></>;
    }
    const exportTarget = target.target;
    const config = exportTarget.config;
    const numTargets = getAllTargets()?.length;

    return (
        <MenuPopover
            additionalClasses="open strong has-sub"
            menuItems={[
                {
                    key: `${targetId}-add-edit-rule`,
                    label: "Add Rule",
                    icon: "plus",
                    onClick: () => {
                        openPopup("add-edit-rule", {
                            targetAndRule: { targetId },
                        });
                    },
                },
                {
                    key: `${targetId}-delete-target`,
                    label: "Delete Target",
                    icon: "trash",
                    onClick: () => {
                        openPopup("delete-target", {
                            exportTarget,
                        });
                    },
                },
            ]}
            toggleButtonLabel={`Toggle ${config.Sftp.hostname}`}
            style={{
                ...(numTargets !== undefined && {
                    zIndex: numTargets - targetIndex,
                }),
            }}
        >
            {({ ToggleButton, Menu }) => (
                <>
                    <NotAnchor
                        className="toggle-sub"
                        onClick={() => {
                            openPopup("add-edit-target", {
                                targetId,
                            });
                        }}
                    >
                        {config.Sftp.hostname}
                    </NotAnchor>
                    {ToggleButton}
                    <NotAnchor
                        role="tab"
                        className="open-close-menu"
                        onClick={() => {
                            return;
                        }}
                    />
                    {Menu}
                    <ul className="list-inline">
                        <li>
                            {Object.keys(exportTarget.config)[0].toUpperCase()}
                        </li>
                    </ul>
                    <div
                        css={css`
                            margin-top: 0.5em;
                            flex-basis: 100%;
                            color: var(--black_40);
                            font-family: var(--font_content);
                            font-weight: 500;
                            font-size: 0.8em;
                            line-height: 1.5rem;
                        `}
                    >
                        Please add this key to the authorized_keys file of the
                        target host:
                    </div>
                    <div
                        css={css`
                            flex-basis: 100%;
                            font-family: "Courier New";
                            font-weight: 600;
                            font-size: 0.75em;
                            line-height: 1.5rem;
                        `}
                    >
                        {exportTarget.sender_public_identity.Ssh.public_key}
                    </div>

                    {target.rules.length > 0 && (
                        <ul className="list-drag ui-sortable">
                            <SortableContext items={target.rules}>
                                {target.rules.map((rule) => (
                                    <ExportRuleRow
                                        key={rule.id}
                                        targetId={targetId}
                                        ruleId={rule.id}
                                    />
                                ))}
                            </SortableContext>
                        </ul>
                    )}
                </>
            )}
        </MenuPopover>
    );
};

const Main: FC = () => {
    const { sdkSystem } = useApi();
    const { openPopup } = usePopup();

    const {
        dataExportDispatch,
        itemGetters: { allTargets: getAllTargets },
    } = useDataExport();

    const allTargets = getAllTargets();

    useEffect(() => {
        const getExportTargets = async () => {
            const exportTargets = await unpaginate(
                sdkSystem.allExportTargets,
                {}
            );

            const exportRules = await unpaginateWithMultiParams(
                sdkSystem.allExportRules
            );
            const groupedExportRules = groupBy(exportRules, "target_id");

            dataExportDispatch({
                action: "SetTargets",
                targets: exportTargets.map((target) => ({
                    target,
                    rules: groupedExportRules[target.id] ?? [],
                })),
            });
        };

        getExportTargets();
    }, [dataExportDispatch, sdkSystem]);

    useEffect(() => {
        textCenterEmptyTab(!allTargets || allTargets.length === 0);
    }, [allTargets]);

    return (
        <div className="module-tabs-content">
            <div>
                {allTargets && allTargets.length > 0 ? (
                    <ul className="list-drag draggable ui-sortable">
                        {allTargets.map((exportTarget) => {
                            const target = exportTarget.target;
                            return (
                                <ExportTargetRow
                                    key={target.id}
                                    targetId={target.id}
                                />
                            );
                        })}
                    </ul>
                ) : (
                    <div className="module-success inline">
                        <h2>
                            <i aria-hidden="true" className="icon-download" />
                            No Export Targets Yet
                        </h2>
                    </div>
                )}
            </div>
            <Footer>
                <p className="link-btn">
                    <NotAnchor
                        onClick={() => {
                            openPopup("add-edit-target");
                        }}
                    >
                        <i aria-hidden="true" className="icon-plus-circle" />
                        Add Target
                    </NotAnchor>
                </p>
            </Footer>
        </div>
    );
};

const DataExportTargetsTab: FC = () => {
    return <Main />;
};

export {
    DataExportTargetsTab,
    AddTargetPopup,
    DeleteTargetPopup,
    AddRulePopup,
    DeleteRulePopup,
};
