import { FC, useCallback, useEffect } from "react";
import classNames from "classnames";
import urlJoin from "url-join";
import pick from "lodash/pick";
import { z } from "zod";
import { useNavigate } from "react-router-dom";
import { DuplicateProductVersion, ProductVersion } from "@joshuins/builder";
import NotAnchor from "components/NotAnchor";
import { AlertCategory, AlertMessageInterface, usePage } from "components/Page";
import { ConfirmationPopup, createFormPopup } from "components/Popup";
import { useApi } from "contexts/ApiProvider";
import { usePopup } from "contexts/PopupProvider";
import FormControl from "@mui/material/FormControl/FormControl";
import ListItemIcon from "@mui/material/ListItemIcon/ListItemIcon";
import { Select } from "components/ReactHookFormUncontrolledComponents";
import { MenuItem } from "components/Select";
import { BUILDER_PATH } from "globals";
import {
    MAX_PRODUCT_DESCRIPTION_LENGTH,
    MAX_PRODUCT_NAME_LENGTH,
    PRODUCT_VERSION_TYPES,
} from "./util";

const NEW_VERSION_FIRST_STEP = "new-product-version-first-step" as const;
const NEW_VERSION_OF_CURRENT =
    "new-product-version-of-current-product" as const;
const NEW_VERSION_AS_NEW = "new-product-version-of-new-product" as const;
const NEW_VERSION_CONFIRM = "new-product-version-confirmation" as const;

const newProductVersionFirstStepSchema = z.object({
    productToCreateWith: z
        .enum(["new", "current", ""], {
            errorMap: () => ({ message: "Please make a selection" }),
        })
        .refine((value) => value !== "", {
            message: "Please make a selection",
        }),
});
type NewProductVersionFirstStepType = z.infer<
    typeof newProductVersionFirstStepSchema
>;

const { FormPopup: NewProductVersionFirstStepFormPopup } = createFormPopup(
    newProductVersionFirstStepSchema
);

const NewProductVersionFirstStepPopup: FC = () => {
    const { openPopup } = usePopup();

    const onSubmit = useCallback(
        (data: NewProductVersionFirstStepType) => {
            if (data.productToCreateWith === "") {
                return;
            }

            switch (data.productToCreateWith) {
                case "current":
                    openPopup(NEW_VERSION_OF_CURRENT);
                    break;
                case "new":
                    openPopup(NEW_VERSION_AS_NEW);
                    break;
            }
        },
        [openPopup]
    );

    return (
        <NewProductVersionFirstStepFormPopup
            name={NEW_VERSION_FIRST_STEP}
            defaultValues={{
                productToCreateWith: "",
            }}
            submitText="Next"
            onSubmit={onSubmit}
        >
            {({ register, formState: { errors } }) => (
                <>
                    <header className="m30">
                        <h2>Add to Current or New Product?</h2>
                    </header>
                    <ul className="check strong">
                        <li>
                            <input
                                {...register("productToCreateWith")}
                                type="radio"
                                id="faf"
                                value="current"
                            />{" "}
                            <label htmlFor="faf">
                                Current Product{" "}
                                <span>
                                    This will be the next version of the current
                                    product
                                </span>
                            </label>
                        </li>
                        <li>
                            <input
                                {...register("productToCreateWith")}
                                type="radio"
                                id="fae"
                                value="new"
                            />{" "}
                            <label htmlFor="fae">
                                New Product{" "}
                                <span>
                                    This will be the first version of a brand
                                    new product
                                </span>
                            </label>
                        </li>
                    </ul>
                    {errors.productToCreateWith && (
                        <label id="fae-error" className="error" htmlFor="fae">
                            {errors.productToCreateWith.message}
                        </label>
                    )}
                </>
            )}
        </NewProductVersionFirstStepFormPopup>
    );
};

const newProductVersionOfCurrentVersionSchema = z.object({
    internal_version_name: z
        .string()
        .min(1, "This field is required")
        .max(
            MAX_PRODUCT_NAME_LENGTH,
            `${MAX_PRODUCT_NAME_LENGTH} character limit`
        ),
});

type NewProductVersionOfCurrentProductType = z.infer<
    typeof newProductVersionOfCurrentVersionSchema
>;

const { FormPopup: NewProductVersionOfCurrentProductVersionFormPopup } =
    createFormPopup(newProductVersionOfCurrentVersionSchema);

const NewProductVersionOfCurrentPopup: FC = () => {
    const { openPopup } = usePopup();
    const { element } = usePage();

    const productVersion = element as unknown as ProductVersion | undefined;

    const onSubmit = useCallback(
        (data: NewProductVersionOfCurrentProductType) => {
            openPopup(NEW_VERSION_CONFIRM, {
                newProductVersionData: {
                    data,
                    productToCreateWith: "current",
                } as NewProductVersionType,
            });
        },
        [openPopup]
    );

    return (
        <NewProductVersionOfCurrentProductVersionFormPopup
            name={NEW_VERSION_OF_CURRENT}
            defaultValues={{
                internal_version_name: "",
            }}
            onSubmit={onSubmit}
            submitText="Next"
            overlayPopups={[
                <>
                    <h3>Internal Version Name</h3>
                    <p>
                        Internal version names are used to note differences
                        between products. For example, if you modify your
                        product you may want to add a version number or date.
                    </p>
                    <p>
                        The internal version name will display as part of the
                        meta data below the product name in Joshu and is not
                        visible in stores.
                    </p>
                </>,
            ]}
        >
            {({ openOverlayPopup, register, formState: { errors } }) => (
                <>
                    <header>
                        <h2>Add to Current Product</h2>
                    </header>
                    <p
                        className={classNames({
                            "has-error": errors.internal_version_name,
                        })}
                    >
                        <label htmlFor="ifi">
                            Internal Version Name
                            <span className="text-right">
                                {MAX_PRODUCT_NAME_LENGTH} Character Limit
                                <NotAnchor
                                    onClick={() => openOverlayPopup(0)}
                                    className="text-right"
                                    data-overlay="ns2"
                                >
                                    <i
                                        aria-hidden="true"
                                        className="icon-help"
                                    />
                                    <span className="hidden">More info</span>
                                </NotAnchor>
                            </span>
                        </label>
                        <input
                            id="ifi"
                            type="text"
                            placeholder='For example,"1.0," "v1," or "Q1 2021"...'
                            {...register("internal_version_name")}
                        />
                        {productVersion &&
                            productVersion.schema.metadata
                                .internal_version_name &&
                            productVersion.schema.metadata.internal_version_name
                                .length > 0 && (
                                <span
                                    className="label"
                                    style={{ fontWeight: "normal" }}
                                >
                                    (current version name:{" "}
                                    {
                                        productVersion.schema.metadata
                                            .internal_version_name
                                    }
                                    )
                                </span>
                            )}
                    </p>
                </>
            )}
        </NewProductVersionOfCurrentProductVersionFormPopup>
    );
};

const newProductVersionOfNewProductSchema = z.object({
    name: z
        .string()
        .min(1, "This field is required")
        .max(
            MAX_PRODUCT_NAME_LENGTH,
            `${MAX_PRODUCT_NAME_LENGTH} character limit`
        ),
    internal_version_name: z
        .string()
        .min(1, "This field is required")
        .max(
            MAX_PRODUCT_NAME_LENGTH,
            `${MAX_PRODUCT_NAME_LENGTH} character limit`
        ),
    description: z
        .string()
        .max(
            MAX_PRODUCT_DESCRIPTION_LENGTH,
            `${MAX_PRODUCT_DESCRIPTION_LENGTH} character limit`
        ),
    product_type: z.string(),
});

type NewProductVersionOfNewProductType = z.infer<
    typeof newProductVersionOfNewProductSchema
>;

const {
    FormPopup: NewProductVersionOfNewProductFormPopup,
    useFormReturnRef: useNewProductVersionOfNewProductFormReturnRef,
} = createFormPopup(newProductVersionOfNewProductSchema);

const NewProductVersionOfNewProductPopup: FC = () => {
    const {
        formReturn: { reset },
        formReturnRefCallback,
    } = useNewProductVersionOfNewProductFormReturnRef();
    const { openPopup, isPopupOpen } = usePopup();
    const { element } = usePage();
    const productVersion = element as unknown as ProductVersion | undefined;

    useEffect(() => {
        if (!productVersion || !isPopupOpen(NEW_VERSION_AS_NEW) || !reset) {
            return;
        }

        reset({
            ...pick(productVersion, ["name"]),
            description: productVersion.schema.metadata.description ?? "",
            product_type: productVersion.schema.metadata.product_type ?? "",
        });
    }, [productVersion, isPopupOpen, reset]);

    const onSubmit = useCallback(
        (data: NewProductVersionOfNewProductType) => {
            openPopup(NEW_VERSION_CONFIRM, {
                newProductVersionData: {
                    data,
                    productToCreateWith: "new",
                } as NewProductVersionType,
            });
        },
        [openPopup]
    );

    return (
        <NewProductVersionOfNewProductFormPopup
            name={NEW_VERSION_AS_NEW}
            defaultValues={{
                name: "",
                internal_version_name: "",
                description: "",
                product_type: "",
            }}
            onSubmit={onSubmit}
            submitText="Next"
            formReturnRefCallback={formReturnRefCallback}
            overlayPopups={[
                <>
                    <h3>Product Name</h3>
                    <p>
                        This is how the product will be listed in stores,
                        quotes, and documents. Keep it as short as possible.
                    </p>
                </>,
                <>
                    <h3>First Internal Version Name</h3>
                    <p>
                        Internal version names are used to note differences
                        between products. For example, if you modify your
                        product you may want to add a version number or date.
                        This field denotes first version name of your new
                        product.
                    </p>
                    <p>
                        The internal version name will display as part of the
                        meta data below the product name in Joshu and is not
                        visible in stores.
                    </p>
                </>,
                <>
                    <h3>Store Description</h3>
                    <p>
                        Enter a short line of text to be displayed with the
                        product name when beginning a submission. For example,
                        for a commercial property product, the one line
                        description might be &quot;Commercial property
                        protection for small business&quot;
                    </p>
                </>,
                <>
                    <h3>Product Type</h3>
                    <p>
                        Optional classification for the product type. Displays
                        an icon to help differentiate products, but doesn&quot;t
                        affect anything else.
                    </p>
                </>,
            ]}
        >
            {({
                openOverlayPopup,
                register,
                control,
                formState: { errors },
            }) => (
                <>
                    <header>
                        <h2>New Product</h2>
                    </header>
                    <p
                        className={classNames("color-black-100", {
                            "has-error": errors.name,
                        })}
                    >
                        <label htmlFor="ift">
                            Product Name
                            <NotAnchor
                                onClick={() => openOverlayPopup(0)}
                                className="text-right"
                                data-overlay="ns1"
                            >
                                <i aria-hidden="true" className="icon-help" />
                                <span className="hidden">More info</span>
                            </NotAnchor>
                        </label>
                        <input
                            id="ift"
                            placeholder='For example, "PropertyShield"...'
                            type="text"
                            {...register("name")}
                        />
                        {errors.name && (
                            <label
                                id="ift-error"
                                className="error"
                                htmlFor="ift"
                            >
                                {errors.name.message}
                            </label>
                        )}
                    </p>
                    <p
                        className={classNames({
                            "has-error": errors.internal_version_name,
                        })}
                    >
                        <label htmlFor="ifib">
                            First Internal Version Name
                            <span className="text-right">
                                {MAX_PRODUCT_NAME_LENGTH} Character Limit
                                <NotAnchor
                                    onClick={() => openOverlayPopup(1)}
                                    className="text-right"
                                    data-overlay="ns2"
                                >
                                    <i
                                        aria-hidden="true"
                                        className="icon-help"
                                    />
                                    <span className="hidden">More info</span>
                                </NotAnchor>
                            </span>
                        </label>
                        <input
                            id="ifib"
                            type="text"
                            placeholder='For example,"1.0," "v1," or "Q1 2021"...'
                            {...register("internal_version_name")}
                        />
                        {errors.internal_version_name && (
                            <label
                                id="ifib-error"
                                className="error"
                                htmlFor="ifib"
                            >
                                {errors.internal_version_name.message}
                            </label>
                        )}
                    </p>
                    <p
                        className={classNames({
                            "has-error": errors.description,
                        })}
                    >
                        <label htmlFor="ifj">
                            Store Description (Optional)
                            <span className="text-right">
                                {MAX_PRODUCT_DESCRIPTION_LENGTH} Character Limit
                                <NotAnchor
                                    onClick={() => openOverlayPopup(2)}
                                    className="text-right"
                                    data-overlay="ns3"
                                >
                                    <i
                                        aria-hidden="true"
                                        className="icon-help"
                                    />
                                    <span className="hidden">More info</span>
                                </NotAnchor>
                            </span>
                        </label>
                        <input
                            id="ifj"
                            type="text"
                            placeholder='For example, "Property protection for small business"...'
                            {...register("description")}
                        />
                    </p>
                    <div>
                        <span className="label">
                            Product Type (Optional)
                            <span className="text-right">
                                <NotAnchor
                                    onClick={() => openOverlayPopup(3)}
                                    className="text-right"
                                    data-overlay="ns4"
                                >
                                    <i
                                        aria-hidden="true"
                                        className="icon-help"
                                    />
                                    <span className="hidden">More info</span>
                                </NotAnchor>
                            </span>
                        </span>
                        <FormControl fullWidth error={!!errors.product_type}>
                            <Select
                                labelId="product-type-select-label"
                                id="ifv"
                                name="product_type"
                                control={control}
                            >
                                {PRODUCT_VERSION_TYPES.map((item) => (
                                    <MenuItem
                                        key={item.value}
                                        value={item.value}
                                    >
                                        <ListItemIcon>
                                            <i
                                                aria-hidden="true"
                                                className={`icon-${item.icon}`}
                                            />
                                        </ListItemIcon>
                                        {item.title}
                                    </MenuItem>
                                ))}
                            </Select>
                            {errors.product_type && (
                                <label className="error" htmlFor="ifv">
                                    {errors.product_type.message}
                                </label>
                            )}
                        </FormControl>
                    </div>
                </>
            )}
        </NewProductVersionOfNewProductFormPopup>
    );
};

type NewProductVersionType =
    | {
          data: NewProductVersionOfCurrentProductType;
          productToCreateWith: "current";
      }
    | { data: NewProductVersionOfNewProductType; productToCreateWith: "new" };

const NewProductVersionConfirmationPopup = () => {
    const { sdkBuilder } = useApi();
    const { element, tryCatchAndRaiseError } = usePage();
    const { popupData } = usePopup();
    const productVersion = element as unknown as ProductVersion | undefined;
    const navigate = useNavigate();
    const newProductVersionData = popupData?.newProductVersionData as
        | NewProductVersionType
        | undefined;

    const onSubmit = useCallback(async () => {
        if (!productVersion || !newProductVersionData) {
            return;
        }
        tryCatchAndRaiseError(async () => {
            let duplicateProductVersionData: DuplicateProductVersion;
            switch (newProductVersionData.productToCreateWith) {
                case "current": {
                    duplicateProductVersionData = { product_id: null };
                    break;
                }
                case "new": {
                    const product = await sdkBuilder.createProduct({
                        body: {},
                    });
                    duplicateProductVersionData = { product_id: product.id };
                    break;
                }
            }
            let newProductVersion = await sdkBuilder.duplicateProductVersion({
                id: productVersion.id,
                DuplicateProductVersion: duplicateProductVersionData ?? {},
            });
            newProductVersion.schema.metadata = {
                ...newProductVersion.schema.metadata,
                ...newProductVersionData.data,
            };
            newProductVersion = await sdkBuilder.updateProductVersion({
                id: newProductVersion.id,
                UpdateProductVersion: { schema: newProductVersion.schema },
            });

            const alertMessage: AlertMessageInterface = {
                message: `A new version of the ${newProductVersion.schema.metadata.name} product was successfully created`,
                category: AlertCategory.SUCCESS,
            };
            navigate(
                urlJoin(
                    "/",
                    BUILDER_PATH,
                    "products",
                    newProductVersion.id.toString()
                ),
                {
                    state: {
                        alertMessages: alertMessage,
                    },
                }
            );
        });
    }, [
        productVersion,
        newProductVersionData,
        tryCatchAndRaiseError,
        sdkBuilder,
        navigate,
    ]);

    if (!productVersion) {
        return <></>;
    }

    return (
        <ConfirmationPopup
            name={NEW_VERSION_CONFIRM}
            onSubmit={onSubmit}
            submitText="Create New Version"
            needsIUnderstandCheckbox
            iUnderstandCheckboxId="confirm-new-product-version-checkbox"
        >
            {({ iUnderstandCheckbox }) => (
                <>
                    <header>
                        <h2>Are you sure?</h2>
                    </header>
                    <ul className="list-static a">
                        <li>A new version of this product will be created.</li>
                        <li>
                            The new version will contain a copy of the{" "}
                            <span className="strong">
                                application, line items and parameters
                            </span>
                        </li>
                        <li>
                            The current product version will not be affected
                        </li>
                    </ul>
                    <p className="check box">
                        {iUnderstandCheckbox}
                        <label htmlFor="confirm-new-product-version-checkbox">
                            I understand this will create a new version
                        </label>
                    </p>
                </>
            )}
        </ConfirmationPopup>
    );
};

export {
    NewProductVersionFirstStepPopup,
    NewProductVersionOfCurrentPopup,
    NewProductVersionOfNewProductPopup,
    NewProductVersionConfirmationPopup,
    NEW_VERSION_FIRST_STEP,
};
