import { useEffect } from "react";
import { useApi } from "contexts/ApiProvider";
import { useBranding } from "contexts/BrandingProvider";

const LogoutPage = () => {
    const { apiAuthProcess } = useApi();
    const { generateUrl } = useBranding();
    useEffect(() => {
        if (
            !apiAuthProcess.initializeFromStorageAttempted ||
            apiAuthProcess.loggedIn
        ) {
            apiAuthProcess.logout("/");
        } else {
            window.location.href = generateUrl("/auth/login");
        }
    }, [apiAuthProcess, generateUrl]);

    return <></>;
};

export default LogoutPage;
