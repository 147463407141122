import { FC, useEffect } from "react";
import NotAnchor from "components/NotAnchor";
import { useApi } from "contexts/ApiProvider";
import {
    Document,
    DocumentPacketTypeV1,
    DocumentStatus,
} from "@joshuins/insurance";
import FileDownload from "components/FileDownload";
import compact from "lodash/compact";
import axios from "axios";
import classNames from "classnames";
import range from "lodash/range";
import { retry } from "utils/retry";
import { useInsuranceProcess } from "pages/underwriter/quotes_and_policies/components/InsuranceProcessProvider";

const StoreDocumentsSection: FC<{
    documentType: DocumentPacketTypeV1;
}> = ({ documentType }) => {
    const {
        itemGetters: { getInsuranceProcess },
    } = useInsuranceProcess();
    const { quote, documents } = getInsuranceProcess();

    const typeDocuments = documents.filter(
        (document) =>
            document.document_type === documentType &&
            document.preview === false
    );
    const documentsAreNotReady =
        typeDocuments.filter(
            (document) => document.status !== DocumentStatus.Ready
        ).length !== 0;

    if (!typeDocuments || typeDocuments.length === 0) {
        return;
    }

    return (
        <>
            <h2 className="quote-documents">
                <i aria-hidden="true" className="icon-documents" />{" "}
                {documentType} Documents
                <FileDownload
                    fileIds={compact(
                        typeDocuments.map((document) => document.file_id)
                    )}
                    downloadName={`${documentType}_documents_${quote.id}`}
                    className={classNames("text-right", {
                        disabled: documentsAreNotReady,
                    })}
                >
                    <i aria-hidden="true" className="icon-download3" /> Download
                </FileDownload>
            </h2>
            <ul className="list-plain quote-documents">
                {typeDocuments.map((document) => (
                    <DocumentListItem key={document.id} document={document} />
                ))}
            </ul>
        </>
    );
};

const DocumentListItem: FC<{ document: Document }> = ({ document }) => {
    const { insuranceProcessDispatch } = useInsuranceProcess();
    const { sdkInsurance } = useApi();

    useEffect(() => {
        const [waitForDocumentPromise, cancelRetryWaitForDocument] = retry(
            () =>
                sdkInsurance.getDocument({
                    id: document.id,
                }),
            (document) => document.status !== DocumentStatus.Processing,
            2000
        );

        const checkDocumentTemplateReady = async () => {
            try {
                const document = await waitForDocumentPromise;
                if (document) {
                    insuranceProcessDispatch({
                        action: "SetDocument",
                        document: document,
                    });
                }
            } catch (error) {
                if (!axios.isAxiosError(error)) {
                    throw error;
                }
            }
        };
        checkDocumentTemplateReady();
        return () => {
            cancelRetryWaitForDocument();
        };
    }, [document.id, insuranceProcessDispatch, sdkInsurance]);

    if (document.status == DocumentStatus.Processing) {
        return (
            <li>
                <NotAnchor onClick={() => {}}>
                    <i className="icon-document" />
                    {document.name}
                </NotAnchor>
                <ul
                    onClick={() => {}}
                    style={{
                        background: "none",
                        textTransform: "none",
                    }}
                    className="list-inline a text-right"
                >
                    <div
                        className="lds-ring"
                        style={{
                            width: "20px",
                            height: "20px",
                        }}
                    >
                        {range(1, 5).map((level) => (
                            <div
                                key={level}
                                style={{
                                    borderColor:
                                        "var(--bg_primary_icon) transparent transparent transparent",
                                    borderWidth: "3px",
                                }}
                            />
                        ))}
                    </div>
                </ul>
            </li>
        );
    } else if (document.status == DocumentStatus.Error) {
        return (
            <li>
                <NotAnchor onClick={() => {}}>
                    <i className="icon-x-circle" />
                    {document.name}
                </NotAnchor>
            </li>
        );
    } else if (document.file_id && document.status == DocumentStatus.Ready) {
        return (
            <li>
                <FileDownload
                    fileIds={[document.file_id]}
                    downloadName={document.name ?? undefined}
                >
                    <i className="icon-document" />
                    {document.name}
                </FileDownload>
            </li>
        );
    }
};

export { StoreDocumentsSection };
