import { FC, useCallback, useEffect, useState } from "react";
import { useAuthUser } from "react-auth-kit";
import groupBy from "lodash/groupBy";
import keyBy from "lodash/keyBy";
import { asyncMap } from "modern-async";
import urlJoin from "url-join";
import { useApi } from "contexts/ApiProvider";
import { useBranding } from "contexts/BrandingProvider";
import { User, UserAccountRole } from "@joshuins/auth";
import {
    OngoingChangeUserRole,
    PolicyStatus,
    PolicyView,
    PolicyViewBody,
    SubmissionFlow,
} from "@joshuins/insurance";
import { MainPane } from "components/MainPane";
import { useCreateSubmissionWizard } from "pages/components/use-create-submission-wizard";
import { PaginationFooter, PaginationSearchInput } from "components/Pagination";
import {
    policyPendingChangeToColor,
    policyStatusToColor,
    submissionFlowToStatusText,
} from "pages/components/util";
import { useNavigate } from "react-router-dom";
import { fullName } from "utils/user";
import { formatDatetimeString } from "utils/datetime";
import NotAnchor from "components/NotAnchor";

interface PolicyData {
    policyId?: string;
    link: string;
}

const PolicyListItem: FC<{
    policy: PolicyViewBody;
    policyData: PolicyData;
    submissionUser: User | undefined;
}> = ({ policy, submissionUser }) => {
    // const { progress } = {
    //     progress: policyData?.currentStep,
    // };
    const { sdkInsurance } = useApi();
    const { generateUrl } = useBranding();
    const navigate = useNavigate();

    const gotoSubmission = useCallback(
        async (policy: PolicyViewBody) => {
            const submissions = await sdkInsurance.allSubmissions({
                policy_id: policy.id,
            });
            const submission = submissions.items[0];
            if (submission) {
                const submissionLink =
                    await sdkInsurance.generateSubmissionLink(submission);
                if (submissionLink) {
                    navigate(generateUrl(submissionLink));
                }
            }
        },
        [generateUrl, navigate, sdkInsurance]
    );

    return (
        <li>
            <NotAnchor onClick={() => gotoSubmission(policy)}>
                <header className="cols cols-mobile">
                    <h2>{policy.insured_name || "Unknown Insured"}</h2>
                    <p>
                        <span
                            className={`scheme-box ${policyStatusToColor(
                                policy.status
                            )}`}
                        >
                            {policy.status}
                        </span>
                        {policy.pending_change && (
                            <span
                                className={`scheme-box ${
                                    policy.pending_change
                                        ? policyPendingChangeToColor(
                                              policy.pending_change
                                          )
                                        : ""
                                }`}
                            >
                                {submissionFlowToStatusText(
                                    policy.pending_change
                                )}{" "}
                                Pending
                            </span>
                        )}
                    </p>
                </header>
                <ul className="list-inline a">
                    <li>{policy.product_name}</li>
                    <li>
                        {submissionUser
                            ? fullName(submissionUser)
                            : "Unknown Policy Owner"}
                    </li>
                    {/* <li>BAMA Partners, Ltd</li> */}
                </ul>
            </NotAnchor>
            <footer>
                <ul>
                    {policy.ongoing_change && (
                        <li>
                            <span className="scheme-box inv color-black-100">
                                {policy.ongoing_change.toLocaleUpperCase()}
                            </span>
                        </li>
                    )}
                    {policy.last_modified && (
                        <li className="text-right">
                            Last Modified:{" "}
                            {formatDatetimeString(policy.last_modified)}
                        </li>
                    )}
                </ul>
            </footer>
        </li>
    );
};

const PoliciesList: FC<{
    title: string;
    availableProducts?: boolean;
    inStatus?: PolicyStatus[];
    onGoing?: SubmissionFlow[];
    userRole?: OngoingChangeUserRole;
}> = ({
    title,
    availableProducts = false,
    inStatus = [],
    onGoing = [],
    userRole,
}) => {
    const { sdkInsurance, sdkSystem } = useApi();
    const { generateUrl } = useBranding();
    const [perPage, setPerPage] = useState<number>(10);
    const [policies, setPolicies] = useState<PolicyView[]>();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [query, setQuery] = useState<string | undefined>();
    const [policiesData, setPoliciesData] = useState<
        Record<string, PolicyData>
    >({});
    const statusFilter = inStatus ? inStatus.join(",") : undefined;
    const onGoingFilter = onGoing ? onGoing.join(",") : undefined;

    const newSubmissionWizardProceed = useCreateSubmissionWizard();
    const authUser = useAuthUser();
    const currentUser = authUser() as User;
    const [users, setUsers] = useState<Record<string, User>>({});

    const loadPolicies = useCallback(
        async (_page: number) => {
            const policies_ = await sdkInsurance.allPolicies({
                insured_name: query || undefined,
                _page,
                _per_page: perPage,
                ...(onGoingFilter && { ongoing_change: onGoingFilter }),
                ...(statusFilter && { status: statusFilter }),
                ...(userRole && { ongoing_change_user_role: userRole }),
            });
            setPolicies(policies_.items);
            setTotalPages(policies_.total_pages);
            setCurrentPage(_page);
        },
        [onGoingFilter, perPage, query, sdkInsurance, statusFilter, userRole]
    );

    useEffect(() => {
        const getPolicies = async () => {
            loadPolicies(currentPage);
        };
        getPolicies();
    }, [currentPage, loadPolicies, sdkInsurance]);

    const setPageSize = useCallback((value: string) => {
        setPerPage(parseInt(value));
        setCurrentPage(1);
    }, []);

    useEffect(() => {
        const getUsers = async () => {
            let users_ = [currentUser];
            if (
                currentUser.role === UserAccountRole.Underwriter ||
                currentUser.role === UserAccountRole.Admin
            ) {
                const userIds = groupBy(policies, "user_id");
                users_ = await asyncMap(
                    Object.keys(userIds),
                    async (id) => {
                        return await sdkSystem.getUser({ id: parseInt(id) });
                    },
                    Number.POSITIVE_INFINITY
                );
            }
            setUsers(keyBy(users_, "id"));
        };
        getUsers();
    }, [currentUser, policies, sdkSystem]);

    useEffect(() => {
        const getPolicyData = async () => {
            if (!policies) {
                return;
            }
            const policiesSubmissions_ = await asyncMap(
                policies,
                async (policy) =>
                    await sdkInsurance.allSubmissions({
                        policy_id: policy.id,
                    }),
                Number.POSITIVE_INFINITY
            );
            const policiesSubmissions = keyBy(
                policiesSubmissions_.map((result) => result.items).flat(),
                "policy_id"
            );

            const submissionsQuotes_ = await asyncMap(
                Object.values(policiesSubmissions),
                async (submission) =>
                    await sdkInsurance.allQuotes({
                        submission_id: submission.id,
                    }),
                Number.POSITIVE_INFINITY
            );

            const submissionsQuotes = keyBy(
                submissionsQuotes_.map((result) => result.items).flat(),
                "submission_id"
            );

            const policiesData_: PolicyData[] = policies.map((policy) => {
                const submission = policiesSubmissions[policy.id];
                let quote = undefined;
                if (submission) {
                    quote = submissionsQuotes[submission.id];
                }
                return {
                    policyId: policy.id,
                    link: quote
                        ? generateUrl(
                              urlJoin("/", "quotes", quote.id.toString())
                          )
                        : submission
                          ? generateUrl(
                                urlJoin(
                                    "/",
                                    "submissions",
                                    submission.id.toString()
                                )
                            )
                          : "",
                };
            });
            setPoliciesData(keyBy(policiesData_, "policyId"));
        };
        getPolicyData();
    }, [generateUrl, policies, sdkInsurance]);

    if (!policies || !policiesData) {
        return <></>;
    }

    return (
        <MainPane
            title={title}
            cta={
                availableProducts
                    ? {
                          buttonLabel: "New Submission",
                          callback: newSubmissionWizardProceed,
                      }
                    : undefined
            }
            layoutConfig={
                availableProducts ? { headerBottomMargin: false } : undefined
            }
        >
            <PaginationSearchInput
                placeholder="Search insureds"
                setCurrentPage={setCurrentPage}
                setQuery={setQuery}
            />

            <ul className="list-rows">
                {policies.map((policy, index) => (
                    <PolicyListItem
                        policy={policy}
                        policyData={policiesData[policy.id]}
                        submissionUser={
                            policy.user_id ? users[policy.user_id] : undefined
                        }
                        key={`${policy.id}-${index}`}
                    />
                ))}
            </ul>
            <div style={{ marginBottom: "200px" }}></div>
            {totalPages > 1 && (
                <PaginationFooter
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    totalPages={totalPages}
                    setPageSize={setPageSize}
                />
            )}
        </MainPane>
    );
};

export default PoliciesList;
