import {
    SubmissionCodeValueAndOptionalRank,
    RenderedSubjectivity,
    Industry,
    AssetCodeAndValue,
} from "@joshuins/insurance";
import { initObjectWithKeys } from "./object";
import { getRawValueFromJoValue, wrapAsJoValue } from "./jo-types-and-values";
import cloneDeep from "lodash/cloneDeep";
import groupBy from "lodash/groupBy";
import keyBy from "lodash/keyBy";
import isEmpty from "lodash/isEmpty";
import { JoValue } from "@joshuins/builder";

const SUBMISSION_DESIGNATION = [
    "app",
    "bind",
    "data",
    "subj",
    "subj",
    "submission",
    "insured",
    "check",
] as const;

const CHECKS = ["trade_gov_ofac", "clearance"] as const;

// const INDUSTRY_FIELDS = ["code", "title"] as const;

type SubmissionItems = Record<
    string,
    SubmissionCodeValueAndOptionalRank["value"]
>;

type AssetItems = Record<string, AssetCodeAndValue["value"][]>;

type SubmissionCodeStringElements = [
    designation: (typeof SUBMISSION_DESIGNATION)[number],
    code: string,
    ...rest: string[],
];

const splitSubmissionCode = (submissionCode: string) =>
    submissionCode.split(".") as SubmissionCodeStringElements;

interface AsApiIncludeTypes {
    applicationItems?: boolean;
    assetItems?: boolean;
    bindItems?: boolean;
    dataItems?: boolean;
    submissionItems?: boolean;
    insuredItems?: boolean;
    subjectivities?: {
        [code: string]: boolean;
    };
    subjectivityCheckedOnly?: {
        [code: string]: boolean;
    };
    insuredLocation?: boolean;
    insuredIndustry?: boolean;
    insuredChecks?: {
        [checkName in (typeof CHECKS)[number]]?: boolean;
    };
}

class Asset {
    id: string;
    applicationItems: SubmissionItems = {};
    assetItems: AssetItems = {};
    bindItems: SubmissionItems = {};
    dataItems: SubmissionItems = {};
    submissionItems: SubmissionItems = {};
    insuredItems: SubmissionItems = {};
    subjectivities: [string, RenderedSubjectivity][] = [];
    // deletedSubjectivites: [string, RenderedSubjectivity][] = [];
    insuredLocation: Location | undefined;
    insuredIndustry: Industry | undefined;
    insuredChecks: Record<(typeof CHECKS)[number], boolean | undefined> =
        initObjectWithKeys(CHECKS, undefined);

    constructor(
        id: string,
        apiSubmissionDataItems?: SubmissionCodeValueAndOptionalRank[]
    ) {
        this.id = id;
        const insuredSplitAddressItems: SubmissionItems = {};
        const insuredIndustryItems: SubmissionItems = {};
        const insuredCheckItems: SubmissionItems = {};
        const subjectivityItems: [
            string,
            {
                field: "text" | "checked";
                value: SubmissionCodeValueAndOptionalRank["value"];
            },
        ][] = [];

        if (!apiSubmissionDataItems) {
            return;
        }

        for (const apiSubmissionDataItem of apiSubmissionDataItems) {
            const [designation, code, ...rest] = splitSubmissionCode(
                apiSubmissionDataItem.code
            );

            switch (designation) {
                case "app": {
                    // DEV-4200 ignore codes with additional .XX (for example, location)
                    if (rest.length === 0) {
                        this.applicationItems[code] =
                            apiSubmissionDataItem.value;
                    }
                    break;
                }
                case "bind": {
                    this.bindItems[code] = apiSubmissionDataItem.value;
                    break;
                }
                case "data": {
                    this.dataItems[code] = apiSubmissionDataItem.value;
                    break;
                }
                case "subj": {
                    subjectivityItems.push([
                        code,
                        {
                            field: (rest[0] === undefined
                                ? "text"
                                : rest[0]) as "text" | "checked",
                            value: apiSubmissionDataItem.value,
                        },
                    ]);
                    break;
                }
                case "submission": {
                    this.submissionItems[code] = apiSubmissionDataItem.value;
                    break;
                }
                case "insured": {
                    const codeOrSubDesignation = code;
                    if (codeOrSubDesignation === "split_address") {
                        insuredSplitAddressItems[rest[0]] =
                            apiSubmissionDataItem.value;
                    } else if (codeOrSubDesignation === "industry") {
                        insuredIndustryItems[rest[0]] =
                            apiSubmissionDataItem.value;
                    } else {
                        this.insuredItems[codeOrSubDesignation] =
                            apiSubmissionDataItem.value;
                    }
                    break;
                }
                case "check": {
                    insuredCheckItems[code] = apiSubmissionDataItem.value;
                }
            }
        }

        // if (!isEmpty(insuredSplitAddressItems)) {
        //     this.insuredLocation = LOCATION_FIELDS.reduce(
        //         (accumulator, locationField) => {
        //             if (locationField in insuredSplitAddressItems) {
        //                 const splitAddressItemValue =
        //                     insuredSplitAddressItems[locationField];
        //                 accumulator[locationField] = splitAddressItemValue
        //                     ? (getRawValueFromJoValue(
        //                           splitAddressItemValue
        //                       ) as Location[keyof Location])
        //                     : splitAddressItemValue;
        //             }
        //             return accumulator;
        //         },
        //         {} as Location
        //     );
        // }

        // if (!isEmpty(insuredSplitAddressItems)) {
        //     this.insuredIndustry = INDUSTRY_FIELDS.reduce(
        //         (accumulator, industryField) => {
        //             const industryItemValue =
        //                 insuredIndustryItems[industryField];
        //             if (industryItemValue) {
        //                 accumulator[industryField] = getRawValueFromJoValue(
        //                     industryItemValue
        //                 ) as RenderedIndustry[keyof RenderedIndustry];
        //             }

        //             return accumulator;
        //         },
        //         initObjectWithKeys(INDUSTRY_FIELDS, "")
        //     );
        // }

        if (!isEmpty(insuredCheckItems)) {
            for (const check of CHECKS) {
                const insuredCheckValue = insuredCheckItems[check];
                if (insuredCheckValue) {
                    this.insuredChecks[check] = getRawValueFromJoValue(
                        insuredCheckValue
                    ) as boolean;
                }
            }
        }

        if (!isEmpty(subjectivityItems)) {
            const subjectivitiesChecked: Record<string, boolean> = {};
            for (const [code, { field, value }] of subjectivityItems) {
                if (!value) {
                    continue;
                }
                if (field === "checked") {
                    subjectivitiesChecked[code] = getRawValueFromJoValue(
                        value
                    ) as boolean;
                }
            }

            for (const [code, { field, value }] of subjectivityItems) {
                if (!value) {
                    continue;
                }
                if (field === "text") {
                    this.subjectivities.push([
                        code,
                        {
                            name: getRawValueFromJoValue(value) as string,
                            included: subjectivitiesChecked[code],
                        },
                    ]);
                }
            }
        }
    }

    public clone = () => {
        const asset = new Asset(this.id);
        asset.applicationItems = cloneDeep(this.applicationItems);
        asset.assetItems = cloneDeep(this.assetItems);
        asset.bindItems = cloneDeep(this.bindItems);
        asset.dataItems = cloneDeep(this.dataItems);
        asset.submissionItems = cloneDeep(this.submissionItems);
        asset.insuredItems = cloneDeep(this.insuredItems);
        asset.subjectivities = cloneDeep(this.subjectivities);
        // asset.deletedSubjectivites = cloneDeep(this.deletedSubjectivites);
        asset.insuredLocation = cloneDeep(this.insuredLocation);
        asset.insuredIndustry = cloneDeep(this.insuredIndustry);

        return asset;
    };

    public asApiSubmissionData = (
        types?: AsApiIncludeTypes
    ): SubmissionCodeValueAndOptionalRank[] => {
        const apiSubmissionData: SubmissionCodeValueAndOptionalRank[] = [];
        const apiAssetsData: AssetCodeAndValue[] = [];
        const asset_idx = parseInt(this.id);

        if (!types || types.applicationItems) {
            for (const [code, value] of Object.entries(this.applicationItems)) {
                apiSubmissionData.push({
                    code,
                    value,
                });
            }
        }

        if (!types || types.assetItems) {
            for (const [code, values] of Object.entries(this.assetItems)) {
                for (const value of values) {
                    apiAssetsData.push({
                        code,
                        value,
                        asset_idx,
                    });
                }
            }
        }

        if (!types || types.bindItems) {
            for (const [code, value] of Object.entries(this.bindItems)) {
                apiSubmissionData.push({
                    code: `bind.${code}`,
                    value,
                });
            }
        }

        if (!types || types.dataItems) {
            for (const [code, value] of Object.entries(this.dataItems)) {
                apiSubmissionData.push({
                    code: `data.${code}`,
                    value,
                });
            }
        }

        if (!types || types.submissionItems) {
            for (const [code, value] of Object.entries(this.submissionItems)) {
                apiSubmissionData.push({
                    code: `submission.${code}`,
                    value,
                });
            }
        }

        if (!types || types.insuredItems) {
            for (const [code, value] of Object.entries(this.insuredItems)) {
                apiSubmissionData.push({
                    code: `insured.${code}`,
                    value,
                });
            }
        }

        if (!types) {
            for (const [code, { included, name }] of this.subjectivities) {
                apiSubmissionData.push(
                    {
                        code: `subj.${code}`,
                        value: wrapAsJoValue({ Text: name }),
                    },
                    {
                        code: `subj.${code}.checked`,
                        value: wrapAsJoValue({ Boolean: included }),
                    }
                );
            }
        } else if (types.subjectivities) {
            for (const [code, { included, name }] of this.subjectivities) {
                if (!(code in types.subjectivities)) {
                    continue;
                }
                apiSubmissionData.push(
                    {
                        code: `subj.${code}`,
                        value: wrapAsJoValue({ Text: name }),
                    },
                    {
                        code: `subj.${code}.checked`,
                        value: wrapAsJoValue({ Boolean: included }),
                    }
                );
            }
        } else if (types.subjectivityCheckedOnly) {
            for (const [code, { included }] of this.subjectivities) {
                if (!(code in types.subjectivityCheckedOnly)) {
                    continue;
                }
                apiSubmissionData.push({
                    code: `subj.${code}.checked`,
                    value: wrapAsJoValue({ Boolean: included }),
                });
            }
        }

        // if (!types || types.insuredLocation) {
        //     if (this.insuredLocation) {
        //         const insuredLocation = this.insuredLocation;
        //         apiSubmissionData.push(
        //             ...LOCATION_FIELDS.reduce((accumulator, locationField) => {
        //                 const splitAddressItemValue =
        //                     insuredLocation[locationField];
        //                 if (splitAddressItemValue !== undefined) {
        //                     accumulator.push({
        //                         code: `insured.split_address.${locationField}`,
        //                         value:
        //                             splitAddressItemValue === null
        //                                 ? wrapAsJoValue({ Null: {} })
        //                                 : includes(
        //                                         ["lat", "lng"] as const,
        //                                         locationField
        //                                     )
        //                                   ? wrapAsJoValue({
        //                                         Number: splitAddressItemValue,
        //                                     })
        //                                   : wrapAsJoValue({
        //                                         Text: splitAddressItemValue,
        //                                     }),
        //                         asset_idx,
        //                     });
        //                 }
        //                 return accumulator;
        //             }, [] as SubmissionCodeValueAndOptionalRank[])
        //         );
        //     }
        // }

        if (!types || types.insuredIndustry) {
            if (this.insuredIndustry) {
                apiSubmissionData.push(
                    {
                        code: "insurance.industry.title",
                        value: wrapAsJoValue({
                            Text: this.insuredIndustry.title,
                        }),
                    },
                    {
                        code: "insurance.industry.code",
                        value: wrapAsJoValue({
                            Text: this.insuredIndustry.code,
                        }),
                    }
                );
            }
        }

        if (!types) {
            for (const checkName of CHECKS) {
                const check = this.insuredChecks[checkName];
                if (check !== undefined) {
                    apiSubmissionData.push({
                        code: `check.${checkName}`,
                        value: wrapAsJoValue({ Boolean: check }),
                    });
                }
            }
        } else if (types.insuredChecks) {
            for (const checkName of CHECKS) {
                if (checkName in types.insuredChecks) {
                    const check = this.insuredChecks[checkName];
                    if (check !== undefined) {
                        apiSubmissionData.push({
                            code: `check.${checkName}`,
                            value: wrapAsJoValue({ Boolean: check }),
                        });
                    }
                }
            }
        }

        return apiSubmissionData;
    };

    public createSubjectivity = (code: string, name: string) => {
        this.subjectivities.push([
            code,
            {
                included: false,
                name,
            },
        ]);
    };

    public markSubjectivity = (code: string, checked: boolean) => {
        const subjectivity = this.subjectivities.find(
            ([code_]) => code_ === code
        )?.[1];

        if (!subjectivity) {
            return;
        }

        subjectivity.included = checked;
    };

    public updateCheck = (name: (typeof CHECKS)[number], value: boolean) => {
        this.insuredChecks[name] = value;
    };
}

interface SubmissionDataItem {
    code: string;
    value: JoValue;
    asset_idx: number;
    rank?: string | null;
}
class SubmissionData {
    assets: Record<string, Asset>;
    orderedAssets: Asset[];

    constructor(apiSubmissionData?: SubmissionDataItem[]) {
        if (!apiSubmissionData) {
            this.orderedAssets = [];
        } else {
            this.orderedAssets = Object.entries(
                groupBy(apiSubmissionData, "asset_idx")
            ).reduce((accumulator, [assetIndexAsString, assets]) => {
                accumulator.push(new Asset(assetIndexAsString, assets));
                return accumulator;
            }, [] as Asset[]);
            this.orderedAssets.sort((a, b) => parseInt(a.id) - parseInt(b.id));
        }

        this.assets = keyBy(this.orderedAssets, "id");
    }

    public clone = () => {
        const submissionData = new SubmissionData();
        submissionData.assets = Object.entries(this.assets).reduce(
            (accumulator, [assetId, asset]) => {
                accumulator[assetId] = asset.clone();
                return accumulator;
            },
            {} as Record<string, Asset>
        );
        return submissionData;
    };

    public asApiSubmissionData = (
        assetIds?: string[],
        types?: AsApiIncludeTypes
    ): SubmissionCodeValueAndOptionalRank[] => {
        return Object.values(this.assets).reduce((accumulator, asset) => {
            if (!assetIds || assetIds.includes(asset.id)) {
                accumulator.push(...asset.asApiSubmissionData(types));
            }
            return accumulator;
        }, [] as SubmissionCodeValueAndOptionalRank[]);
    };

    public markSubjectivity = (
        assetId: string,
        code: string,
        checked: boolean
    ) => {
        this.assets[assetId].markSubjectivity(code, checked);
    };

    public createSubjectivity = (
        assetId: string,
        code: string,
        name: string
    ) => {
        this.assets[assetId].createSubjectivity(code, name);
    };

    public updateCheck = (name: (typeof CHECKS)[number], value: boolean) => {
        this.assets["0"].updateCheck(name, value);
    };
}

export default SubmissionData;
export type { Asset, SubmissionDataItem };
