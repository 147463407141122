import { useCallback } from "react";
import { useImmer } from "use-immer";

interface UseMapStateInterface<T> {
    addValues: (value: T[] | T) => void;
    removeValues: (key: string[] | string) => void;
    mapValues: <U>(
        callbackfn: (value: T, key: string, map: Map<string, T>) => U,
        thisArg?: unknown
    ) => U[];
    isEmpty: () => boolean;
}

const mapAdd = <V>(map: Map<string, V>, value: unknown) => {
    map.set(crypto.randomUUID(), value as V);
};

const mapRemove = <V>(map: Map<string, V>, key: string) => {
    map.delete(key);
};

const useMapState = <T = unknown>(
    initialValue?: T[]
): UseMapStateInterface<T> => {
    const [map, setMap] = useImmer<Map<string, T>>(() => {
        const map_ = new Map<string, T>();
        if (initialValue !== undefined) {
            for (const initialValue_ of initialValue) {
                mapAdd<T>(map, initialValue_);
            }
        }
        return map_;
    });

    const addValues = useCallback<(values: T[] | T) => void>(
        (values) => {
            setMap((draft) => {
                if (!Array.isArray(values)) {
                    values = [values];
                }
                for (const value of values) {
                    mapAdd<T>(draft as Map<string, T>, {
                        created: new Date(),
                        ...value,
                    });
                }
            });
        },
        [setMap]
    );

    const removeValues = useCallback<(keys: string[] | string) => void>(
        (keys) => {
            setMap((draft) => {
                if (!Array.isArray(keys)) {
                    keys = [keys];
                }
                for (const key of keys) {
                    mapRemove<T>(draft as Map<string, T>, key);
                }
            });
        },
        [setMap]
    );

    const mapValues = useCallback(
        <U>(
            callbackfn: (value: T, key: string, map: Map<string, T>) => U,
            thisArg?: unknown
        ): U[] => {
            if (thisArg === undefined) {
                thisArg = globalThis;
            }

            const mappedArray: U[] = [];
            for (const [key, value] of map.entries()) {
                mappedArray.push(callbackfn.call(thisArg, value, key, map));
            }
            return mappedArray;
        },
        [map]
    );

    const isEmpty = useCallback<() => boolean>(() => map.size === 0, [map]);

    return {
        addValues,
        removeValues,
        mapValues,
        isEmpty,
    };
};
export { useMapState };
