import NotAnchor from "components/NotAnchor";
import { usePage } from "components/Page";
import { useApi } from "contexts/ApiProvider";
import { usePopup } from "contexts/PopupProvider";
import { FC, useCallback } from "react";
import { useInsuranceProcess } from "../InsuranceProcessProvider";
import { ConfirmationPopup } from "components/Popup";
import { useNavigate } from "react-router-dom";
import urlJoin from "url-join";
import { waitForProcessingInsuranceObject } from "../../util";
import { useBranding } from "contexts/BrandingProvider";

const BindQuoteLink: FC = () => {
    const { openPopup } = usePopup();
    const { storeUrlName } = useBranding();
    const inStore = storeUrlName !== undefined;

    return (
        <NotAnchor
            onClick={() => {
                openPopup("bind-quote");
            }}
        >
            {inStore ? (
                <>
                    <i className="icon-documents" /> Request to{" "}
                    <span className="mobile-hide">Bind</span>
                </>
            ) : (
                <>
                    <i aria-hidden="true" className="icon-shield" /> Bind{" "}
                    <span className="mobile-hide">Quote </span>
                </>
            )}
        </NotAnchor>
    );
};

const BindQuotePopup: FC = () => {
    const { sdkInsurance } = useApi();
    const { tryCatchAndRaiseError } = usePage();
    const {
        itemGetters: { getInsuranceProcess },
    } = useInsuranceProcess();
    const { quote } = getInsuranceProcess();
    const navigate = useNavigate();
    const { generateUrl } = useBranding();

    const onSubmit = useCallback(async () => {
        tryCatchAndRaiseError(async () => {
            const newBinder = await sdkInsurance.updateQuote({
                id: quote.id,
                UpdateQuote: { status: "CreatePolicy" },
            });
            await waitForProcessingInsuranceObject(
                sdkInsurance,
                newBinder,
                "Binding Quote..."
            );
            navigate(
                generateUrl(urlJoin("/", "policies", newBinder.id.toString()))
            );
        });
    }, [tryCatchAndRaiseError, sdkInsurance, quote.id, navigate, generateUrl]);

    return (
        <ConfirmationPopup
            name="bind-quote"
            onSubmit={onSubmit}
            submitText="Bind Quote"
        >
            <header>
                <h2>Are you sure?</h2>
                <p className="size-14">
                    This quote will be converted to a bind order, but will not
                    yet publish the binder.
                </p>
            </header>
        </ConfirmationPopup>
    );
};

export { BindQuotePopup, BindQuoteLink };
