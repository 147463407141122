import classNames from "classnames";
import urlJoin from "url-join";
import { BUILDER_PATH, STORE_PATH, UNDERWRITER_PATH } from "globals";
import MenuPopover, { MenuItem } from "./MenuPopover";
import { useAuthUser } from "react-auth-kit";
import NotAnchor from "./NotAnchor";
import { toggleDocumentClass } from "paul/native-dom-manipulation";
import { usePopup } from "contexts/PopupProvider";
import { Link } from "components/DevAwareRoutingLink";
import { useMountedPath } from "utils/use-mounted-path";

import type { User } from "@joshuins/system";
import { fullName } from "utils/user";
import { useBranding } from "contexts/BrandingProvider";
import { UserAccountRole } from "@joshuins/auth";
import { usePage } from "./Page";
import { ProductVersion, Store } from "@joshuins/builder";
import { Quote } from "@joshuins/insurance";
import { isBinder, isPolicy, isQuote } from "utils/policies";
import { FC } from "react";

const UnderwritersLeftPaneMenu: FC<{ path: string }> = ({ path }) => {
    const { element } = usePage();
    const { generateUrl } = useBranding();
    const quote_ = element as unknown as Quote | undefined;
    const quote = quote_ && "submission_id" in quote_ ? quote_ : undefined;

    return (
        <>
            <li
                className={classNames({
                    active:
                        path.includes("in-review") ||
                        path.includes("submissions"),
                    toggle: false,
                })}
            >
                <Link to={generateUrl(urlJoin("/", "in-review"))}>
                    <i aria-hidden="true" className="icon-download2" />
                    In Review
                </Link>
            </li>
            <li
                className={classNames({
                    active:
                        path.endsWith("quotes") || (quote && isQuote(quote)),
                    toggle: false,
                })}
            >
                <Link to={generateUrl(urlJoin("/", "quotes"))}>
                    <i aria-hidden="true" className="icon-documents" />
                    Quotes
                </Link>
            </li>
            <li
                className={classNames({
                    active:
                        path.endsWith("binders") || (quote && isBinder(quote)),
                    toggle: false,
                })}
            >
                <Link to={generateUrl(urlJoin("/", "binders"))}>
                    <i aria-hidden="true" className="icon-documents" />
                    Binders
                </Link>
            </li>
            <li
                className={classNames({
                    active:
                        path.endsWith("policies") || (quote && isPolicy(quote)),
                    toggle: false,
                })}
            >
                <Link to={generateUrl(urlJoin("/", "policies"))}>
                    <i aria-hidden="true" className="icon-shield" />
                    Policies
                </Link>
            </li>
            <li
                className={classNames({
                    active: path.includes("insureds"),
                    toggle: false,
                })}
            >
                <Link to={generateUrl(urlJoin("/", "insureds"))}>
                    <i aria-hidden="true" className="icon-user-circle" />
                    Insureds
                </Link>
            </li>
        </>
    );
};

const StoreLeftPaneMenu: FC<{ path: string }> = ({ path }) => {
    const { element } = usePage();
    const { generateUrl } = useBranding();
    const quote_ = element as unknown as Quote | undefined;
    const quote = quote_ && "submission_id" in quote_ ? quote_ : undefined;

    return (
        <>
            <li
                className={classNames({
                    active: path.includes("submissions"),
                    toggle: false,
                })}
            >
                <Link to={generateUrl(urlJoin("/", "submissions"))}>
                    <i aria-hidden="true" className="icon-download2" />
                    Submissions
                </Link>
            </li>
            <li
                className={classNames({
                    active:
                        path.endsWith("quotes") || (quote && isQuote(quote)),
                    toggle: false,
                })}
            >
                <Link to={generateUrl(urlJoin("/", "quotes"))}>
                    <i aria-hidden="true" className="icon-documents" />
                    Quotes
                </Link>
            </li>
            <li
                className={classNames({
                    active:
                        path.endsWith("binders") || (quote && isBinder(quote)),
                    toggle: false,
                })}
            >
                <Link to={generateUrl(urlJoin("/", "binders"))}>
                    <i aria-hidden="true" className="icon-time" />
                    Binders
                </Link>
            </li>
            <li
                className={classNames({
                    active:
                        path.endsWith("policies") || (quote && isPolicy(quote)),
                    toggle: false,
                })}
            >
                <Link to={generateUrl(urlJoin("/", "policies"))}>
                    <i aria-hidden="true" className="icon-shield" />
                    Policies
                </Link>
            </li>
            <li
                className={classNames({
                    active: path.includes("insureds"),
                    toggle: false,
                })}
            >
                <Link to={generateUrl(urlJoin("/", "insureds"))}>
                    <i aria-hidden="true" className="icon-user-circle" />
                    Insureds
                </Link>
            </li>
        </>
    );
};

const BuilderLeftPaneMenu: FC<{ path: string }> = ({ path }) => {
    const { element } = usePage();
    const element_ = element as unknown as ProductVersion | Store | undefined;

    let productVersion: ProductVersion | undefined = undefined;
    let store: Store | undefined = undefined;

    if (element_) {
        if ("is_published" in element_) {
            productVersion = element_;
        } else if ("url_slug" in element_) {
            store = element_;
        }
    }

    return (
        <>
            <li
                className={classNames({
                    active: !productVersion && path.includes("products"),
                    toggle: productVersion,
                })}
            >
                <Link to={urlJoin("/", BUILDER_PATH, "products")}>
                    <i aria-hidden="true" className="icon-shield" />
                    Products
                </Link>
                {productVersion && (
                    <ul>
                        <li
                            className={classNames({
                                active: productVersion && path.includes("home"),
                            })}
                        >
                            <Link
                                to={urlJoin([
                                    "/",
                                    BUILDER_PATH,
                                    "products",
                                    productVersion.id.toString(),
                                    "home",
                                ])}
                            >
                                <i aria-hidden="true" className="icon-home" />
                                Home
                            </Link>
                        </li>
                        <li
                            className={classNames({
                                active:
                                    productVersion &&
                                    path.includes("application") &&
                                    !path.includes("documents"),
                            })}
                        >
                            <Link
                                to={urlJoin([
                                    "/",
                                    BUILDER_PATH,
                                    "products",
                                    productVersion.id.toString(),
                                    "application",
                                ])}
                            >
                                <i
                                    aria-hidden="true"
                                    className="icon-doc-edit"
                                />
                                Application
                            </Link>
                        </li>
                        <li
                            className={classNames({
                                active:
                                    productVersion && path.includes("rater"),
                            })}
                        >
                            <Link
                                to={urlJoin([
                                    "/",
                                    BUILDER_PATH,
                                    "products",
                                    productVersion.id.toString(),
                                    "rater",
                                ])}
                            >
                                <i aria-hidden="true" className="icon-rating" />
                                Rater
                            </Link>
                        </li>
                        <li
                            className={classNames({
                                active:
                                    productVersion &&
                                    path.includes("documents"),
                            })}
                        >
                            <Link
                                to={urlJoin([
                                    "/",
                                    BUILDER_PATH,
                                    "products",
                                    productVersion.id.toString(),
                                    "documents",
                                    "application",
                                ])}
                            >
                                <i
                                    aria-hidden="true"
                                    className="icon-documents"
                                />
                                Documents
                            </Link>
                        </li>
                        <li
                            className={classNames({
                                active:
                                    productVersion &&
                                    path.includes("integrations"),
                            })}
                        >
                            <Link
                                to={urlJoin([
                                    "/",
                                    BUILDER_PATH,
                                    "products",
                                    productVersion.id.toString(),
                                    "integrations",
                                ])}
                            >
                                <i
                                    aria-hidden="true"
                                    className="icon-upload-cloud"
                                />
                                Integrations
                            </Link>
                        </li>
                        <li
                            className={classNames({
                                active:
                                    productVersion &&
                                    path.includes("bind-requirements"),
                            })}
                        >
                            <Link
                                to={urlJoin([
                                    "/",
                                    BUILDER_PATH,
                                    "products",
                                    productVersion.id.toString(),
                                    "bind-requirements",
                                    "subjectivities",
                                ])}
                            >
                                <i
                                    aria-hidden="true"
                                    className="icon-check-circle-outline"
                                />
                                Bind Requiremenets
                            </Link>
                        </li>
                        <li
                            className={classNames({
                                active:
                                    productVersion && path.includes("settings"),
                            })}
                        >
                            <Link
                                to={urlJoin([
                                    "/",
                                    BUILDER_PATH,
                                    "products",
                                    productVersion.id.toString(),
                                    "settings",
                                    "general",
                                ])}
                            >
                                <i aria-hidden="true" className="icon-cog" />
                                Settings
                            </Link>
                        </li>
                    </ul>
                )}
            </li>
            <li
                className={classNames({
                    active: !store && path.includes("stores"),
                    toggle: store,
                })}
            >
                <Link to={urlJoin("/", BUILDER_PATH, "stores", "broker")}>
                    <i aria-hidden="true" className="icon-cart-window" />
                    Stores
                </Link>
                {store && (
                    <ul>
                        <li
                            className={classNames({
                                active: store && path.includes("home"),
                            })}
                        >
                            <Link
                                to={urlJoin([
                                    "/",
                                    BUILDER_PATH,
                                    "stores",
                                    store.id.toString(),
                                    "home",
                                ])}
                            >
                                <i aria-hidden="true" className="icon-home" />
                                Home
                            </Link>
                        </li>
                        <li
                            className={classNames({
                                active: store && path.includes("access"),
                            })}
                        >
                            <Link
                                to={urlJoin([
                                    "/",
                                    BUILDER_PATH,
                                    "stores",
                                    store.id.toString(),
                                    "access",
                                    "people",
                                ])}
                            >
                                <i
                                    aria-hidden="true"
                                    className="icon-user-circle"
                                />
                                Access
                            </Link>
                        </li>
                        <li
                            className={classNames({
                                active: store && path.includes("theme"),
                            })}
                        >
                            <Link
                                to={urlJoin([
                                    "/",
                                    BUILDER_PATH,
                                    "stores",
                                    store.id.toString(),
                                    "theme",
                                ])}
                            >
                                <i aria-hidden="true" className="icon-brand" />
                                Theme
                            </Link>
                        </li>
                        <li
                            className={classNames({
                                active: store && path.includes("settings"),
                            })}
                        >
                            <Link
                                to={urlJoin([
                                    "/",
                                    BUILDER_PATH,
                                    "stores",
                                    store.id.toString(),
                                    "settings",
                                ])}
                            >
                                <i aria-hidden="true" className="icon-cog" />
                                Settings
                            </Link>
                        </li>
                    </ul>
                )}
            </li>
            <li
                className={classNames({
                    active: !store && !productVersion && path.includes("team"),
                    toggle: false,
                })}
            >
                <Link to={urlJoin("/", BUILDER_PATH, "team")}>
                    <i aria-hidden="true" className="icon-team" />
                    Team
                </Link>
            </li>
            <li
                className={classNames({
                    active:
                        !store && !productVersion && path.includes("directory"),
                    toggle: false,
                })}
            >
                <Link to={urlJoin("/", BUILDER_PATH, "directory")}>
                    <i aria-hidden="true" className="icon-building" />
                    Directory
                </Link>
            </li>
            <li
                className={classNames({
                    active:
                        !store && !productVersion && path.includes("reports"),
                    toggle: false,
                })}
            >
                <Link to={urlJoin("/", BUILDER_PATH, "reports")}>
                    <i aria-hidden="true" className="icon-chart" />
                    Reports
                </Link>
            </li>
            <li
                className={classNames({
                    active: false,
                    toggle: path.includes("system"),
                })}
            >
                <Link to={urlJoin("/", BUILDER_PATH, "system", "integrations")}>
                    <i aria-hidden="true" className="icon-cog" />
                    System
                </Link>
                {path.includes("system") && (
                    <ul>
                        <li
                            className={classNames({
                                active: path.includes("integrations"),
                            })}
                        >
                            <Link
                                to={urlJoin([
                                    "/",
                                    BUILDER_PATH,
                                    "system",
                                    "integrations",
                                ])}
                            >
                                <i
                                    aria-hidden="true"
                                    className="icon-upload-cloud"
                                />
                                Integrations
                            </Link>
                        </li>
                        <li
                            className={classNames({
                                active: path.includes("insured-details"),
                            })}
                        >
                            <Link
                                to={urlJoin([
                                    "/",
                                    BUILDER_PATH,
                                    "system",
                                    "insured-details",
                                ])}
                            >
                                <i
                                    aria-hidden="true"
                                    className="icon-user-circle"
                                />
                                Insured Details
                            </Link>
                        </li>
                        <li
                            className={classNames({
                                active: path.includes("notifications"),
                            })}
                        >
                            <Link
                                to={urlJoin([
                                    "/",
                                    BUILDER_PATH,
                                    "system",
                                    "notifications",
                                ])}
                            >
                                <i
                                    aria-hidden="true"
                                    className="icon-envelope-refresh"
                                />
                                Notifications
                            </Link>
                        </li>
                        <li
                            className={classNames({
                                active: path.includes("sso"),
                            })}
                        >
                            <Link
                                to={urlJoin([
                                    "/",
                                    BUILDER_PATH,
                                    "system",
                                    "sso",
                                ])}
                            >
                                <i aria-hidden="true" className="icon-keys" />
                                SSO
                            </Link>
                        </li>
                        <li
                            className={classNames({
                                active: path.includes("data-export"),
                            })}
                        >
                            <Link
                                to={urlJoin([
                                    "/",
                                    BUILDER_PATH,
                                    "system",
                                    "data-export",
                                    "targets",
                                ])}
                            >
                                <i
                                    aria-hidden="true"
                                    className="icon-download-cloud"
                                />
                                Data Exports
                            </Link>
                        </li>
                        <li
                            className={classNames({
                                active: path.includes("policy-number-pools"),
                            })}
                        >
                            <Link
                                to={urlJoin([
                                    "/",
                                    BUILDER_PATH,
                                    "system",
                                    "policy-number-pools",
                                ])}
                            >
                                <i aria-hidden="true" className="icon-bricks" />
                                Policy Number Pools
                            </Link>
                        </li>
                        <li
                            className={classNames({
                                active: path.includes("policy-sync"),
                            })}
                        >
                            <Link
                                to={urlJoin([
                                    "/",
                                    BUILDER_PATH,
                                    "system",
                                    "policy-sync",
                                ])}
                            >
                                <i aria-hidden="true" className="icon-css" />
                                Policy Sync
                            </Link>
                        </li>
                        <li
                            className={classNames({
                                active: path.includes("product-imports"),
                            })}
                        >
                            <Link
                                to={urlJoin([
                                    "/",
                                    BUILDER_PATH,
                                    "system",
                                    "policy-number-pools",
                                ])}
                            >
                                <i
                                    aria-hidden="true"
                                    className="icon-upload-cloud"
                                />
                                Exported Products
                            </Link>
                        </li>
                    </ul>
                )}
            </li>
        </>
    );
};

const LeftPaneNavigation: FC = () => {
    const authUser = useAuthUser();
    const { openPopup } = usePopup();
    const { storeUrlName } = useBranding();
    const inStore = storeUrlName !== undefined;
    const path = useMountedPath().join();
    const mountedPath = useMountedPath();
    const user = authUser() as User;
    let leftPaneMenu;
    if (mountedPath[0] === UNDERWRITER_PATH) {
        leftPaneMenu = <UnderwritersLeftPaneMenu path={path} />;
    } else if (mountedPath[0] === BUILDER_PATH) {
        leftPaneMenu = <BuilderLeftPaneMenu path={path} />;
    } else if (mountedPath[0] === STORE_PATH) {
        leftPaneMenu = <StoreLeftPaneMenu path={path} />;
    }
    return (
        <nav id="nav" aria-label="Navigation">
            <ul>
                {leftPaneMenu}
                <MenuPopover
                    additionalClasses={["strong"]}
                    menuItems={[
                        ...(mountedPath[0] !== UNDERWRITER_PATH && !inStore
                            ? [
                                  {
                                      key: "underwriter-desk",
                                      label: "Underwriter Desk (Shift+U)",
                                      icon: "integrate2",
                                      hasSeparator: false,
                                      aHref: urlJoin("/", UNDERWRITER_PATH),
                                  } as MenuItem,
                              ]
                            : ([] as MenuItem[])),
                        ...(mountedPath[0] !== BUILDER_PATH &&
                        !inStore &&
                        user.role === UserAccountRole.Admin
                            ? [
                                  {
                                      key: "builder",
                                      label: "Builder (Shift+B)",
                                      icon: "integrate2",
                                      hasSeparator: false,
                                      aHref: urlJoin("/", BUILDER_PATH),
                                  } as MenuItem,
                              ]
                            : ([] as MenuItem[])),
                        {
                            key: "switch-menu-mode",
                            label: "Switch Menu Mode",
                            icon: "bricks",
                            hasSeparator: false,
                            onClick: () => toggleDocumentClass("menu-compact"),
                            className: "desktop-only",
                        },
                        {
                            key: "account-settings",
                            label: "Account Settings",
                            icon: "cog",
                            hasSeparator: true,
                            onClick: () => openPopup("account-settings"),
                        },
                        ...(!inStore
                            ? [
                                  {
                                      key: "api-tokens",
                                      label: "API Tokens",
                                      icon: "lock-thin",
                                      hasSeparator: false,
                                      linkTo: urlJoin(
                                          "/",
                                          BUILDER_PATH,
                                          "api-tokens"
                                      ),
                                  } as MenuItem,
                              ]
                            : ([] as MenuItem[])),
                        {
                            key: "sign-out",
                            label: "Sign Out",
                            icon: "exit",
                            hasSeparator: false,
                            linkTo: "/auth/logout",
                        },
                    ]}
                >
                    {({ toggleOpen, ToggleButton, Menu }) => (
                        <>
                            <NotAnchor
                                onClick={() => toggleOpen()}
                                data-popup="profile-settings"
                            >
                                <i aria-hidden="true" className="icon-user" />
                                {fullName(user)}
                                <span>{user.role}</span>
                            </NotAnchor>
                            {Menu}
                            {ToggleButton}
                        </>
                    )}
                </MenuPopover>
            </ul>
        </nav>
    );
};

export { LeftPaneNavigation };
