import { MainPane } from "components/MainPane";
import { Page, usePage } from "components/Page";
import { FC, useEffect, useState } from "react";
import { Submission } from "@joshuins/insurance";
import { useApi } from "contexts/ApiProvider";
import {
    getJoValueType,
    getRawValueFromJoValue,
} from "utils/jo-types-and-values";
import { useCreateSubmissionWizard } from "pages/components/use-create-submission-wizard";

const Main: FC = () => {
    const { sdkInsurance } = useApi();
    const { element } = usePage();
    const submission = element as unknown as Submission;
    const [insuredName, setInsuredName] = useState<string>();

    const newSubmissionWizardProceed = useCreateSubmissionWizard();

    useEffect(() => {
        const getInsuredName = async () => {
            sdkInsurance.getSubmissionWhenReady(submission.id);
            const parsedSubmissionData =
                await sdkInsurance.getParsedSubmissionData(submission.id);

            const insuredNameJoValue =
                parsedSubmissionData.assets["0"].insuredItems["name"];
            setInsuredName(
                insuredNameJoValue &&
                    getJoValueType(insuredNameJoValue) === "Text"
                    ? (getRawValueFromJoValue(insuredNameJoValue) as string)
                    : ""
            );
        };
        getInsuredName();
    }, [newSubmissionWizardProceed, sdkInsurance, submission.id]);

    return (
        <MainPane
            title={insuredName}
            titlePill={{ color: "gold", text: "Application Received" }}
            layoutConfig={{ mainLayout: "centerAndWide" }}
        >
            <div className="module-success inline">
                <h2>
                    <i className="icon-edit"></i> Application Received
                </h2>
                <p>
                    We usually return the quote within 48 hours.
                    <br /> We&apos;ll email you when it&apos;s ready!
                </p>
            </div>
        </MainPane>
    );
};

const PendingApprovalPage: FC = () => (
    <Page>
        <Main />
    </Page>
);

export default PendingApprovalPage;
