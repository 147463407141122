import { FC, PropsWithChildren, useCallback, useEffect, useState } from "react";
import NotAnchor from "./NotAnchor";

const CopyLinkButton: FC<
    PropsWithChildren<{ link: string; onClick?: () => void }>
> = ({ children, link, onClick }) => {
    const [link_, setLink] = useState<string>(link);

    useEffect(() => {
        setLink(link);
    }, [link]);

    const onClick_ = useCallback<() => void>(() => {
        navigator.clipboard.writeText(link_);
        if (onClick) {
            onClick();
        }
    }, [onClick, link_]);

    return <NotAnchor onClick={() => onClick_()}>{children}</NotAnchor>;
};

export default CopyLinkButton;
