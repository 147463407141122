import { FC, useCallback, useEffect } from "react";
import classNames from "classnames";
import cloneDeep from "lodash/cloneDeep";
import { z } from "zod";
import { ProductIntegrationV1 } from "@joshuins/builder";
import { Integration } from "@joshuins/system";
import { AlertCategory, usePage } from "components/Page";
import { createFormPopup } from "components/Popup";
import { usePopup } from "contexts/PopupProvider";
import { useIntegrations } from "../IntegrationsProvider";
import NotAnchor from "components/NotAnchor";

const GRAS_SETUP = "gras-setup-form" as const;

const grasSetupFormSchema = z.object({
    locationTag: z.string().trim().min(1, "This field is required"),
    valueTags: z.string().trim().min(1, "This field is required"),
    radiusMiles: z.string().regex(/^\d+$/, "Enter a number"),
    responseTag: z.string().trim().min(1, "This field is required"),
});
type GrasSetupFormType = z.infer<typeof grasSetupFormSchema>;

const {
    FormPopup: GrasSetupFormPopup,
    useFormReturnRef: useGrasSetupFormReturnRef,
} = createFormPopup(grasSetupFormSchema);

const GrasSetupPopup: FC = () => {
    const {
        formReturn: { reset },
        formReturnRefCallback,
    } = useGrasSetupFormReturnRef();
    const { addAlertMessages } = usePage();
    const { popupData, isPopupOpen } = usePopup();
    const {
        accessors: { allIntegrationTypes },
        mutators: { addProductIntegration, updateProductIntegration },
    } = useIntegrations();

    const integration = popupData?.integration as Integration | undefined;
    const productIntegration = popupData?.productIntegration as
        | ProductIntegrationV1
        | undefined;
    const integrationTypes = allIntegrationTypes();
    const integrationType =
        integration && integrationTypes
            ? integrationTypes[integration.type_]
            : undefined;
    const is_published = popupData?.is_published as boolean | undefined;

    useEffect(() => {
        const getProductIntegration = async () => {
            if (!productIntegration || !isPopupOpen(GRAS_SETUP) || !reset) {
                return;
            }

            reset({
                locationTag: productIntegration.config.gras_location_code,
                valueTags: productIntegration.config.gras_value_codes.join(","),
                radiusMiles: productIntegration.config.gras_radius_in_miles,
                responseTag: productIntegration.config.gras_response_code,
            });
        };
        getProductIntegration();
    }, [productIntegration, isPopupOpen, reset]);

    const onSubmit = useCallback(
        async (data: GrasSetupFormType) => {
            if (!integration || !integrationType) {
                return;
            }

            if (productIntegration) {
                const updateProductIntegratopn = cloneDeep(productIntegration);
                updateProductIntegratopn.config = {
                    gras_location_code: data.locationTag,
                    gras_value_codes: data.valueTags
                        .replace(" ", "")
                        .split(","),
                    gras_radius_in_miles: data.radiusMiles,
                    gras_response_code: data.responseTag,
                };
                await updateProductIntegration(
                    productIntegration.integration_id,
                    updateProductIntegratopn
                );
                addAlertMessages({
                    message: `${integrationType.display_name} integration was updated succesfully`,
                    category: AlertCategory.SUCCESS,
                });
            } else {
                const newProductIntegration: ProductIntegrationV1 = {
                    enabled: true,
                    integration_id: integration.id,
                    config: {
                        gras_location_code: data.locationTag,
                        gras_value_codes: data.valueTags
                            .replace(" ", "")
                            .split(","),
                        gras_radius_in_miles: data.radiusMiles,
                        gras_response_code: data.responseTag,
                    },
                };
                await addProductIntegration(newProductIntegration);
                addAlertMessages({
                    message: `${integrationType.display_name} integration was created succesfully`,
                    category: AlertCategory.SUCCESS,
                });
            }
        },
        [
            addAlertMessages,
            addProductIntegration,
            integration,
            integrationType,
            productIntegration,
            updateProductIntegration,
        ]
    );

    if (!integrationType) {
        return <></>;
    }

    return (
        <GrasSetupFormPopup
            name={GRAS_SETUP}
            defaultValues={{
                locationTag: "",
                valueTags: "",
                radiusMiles: "",
                responseTag: "",
            }}
            submitText="Save"
            formReturnRefCallback={formReturnRefCallback}
            onSubmit={onSubmit}
            disabled={is_published}
            overlayPopups={[
                <>
                    <h3>Location Tag</h3>
                    <p>
                        Choose a reference tag from your application questions
                        that asks for a location.
                    </p>
                </>,
                <>
                    <h3>Value Tags</h3>
                    <p>
                        Choose reference tags from your rater that are the
                        values you want to sum.
                        <br /> For example, if you want to sum the building
                        value AND the equipment value for properties in force
                        within the radius, use comma separated tags:{" "}
                        <em>building_value,equipment_value</em>.
                    </p>
                </>,
                <>
                    <h3>Radius (Miles)</h3>
                    <p>
                        GRAS will calculate the value for locations within a
                        given radius. Select from the options in the menu. If
                        you would like more than one radius, add another
                        integration and select a different radius.
                    </p>
                </>,
                <>
                    <h3>Response Tag</h3>
                    <p>
                        This is how you get the response data from the API into
                        your rater. We&apos;ve suggested a tag, but you can
                        change it to anything you want. Need examples? Check the
                        documentation for each integration.
                    </p>
                </>,
            ]}
        >
            {({ register, openOverlayPopup, formState: { errors } }) => (
                <>
                    <header>
                        <h2>{integrationType.display_name}</h2>
                    </header>
                    <p
                        className={classNames({
                            "has-error": errors.locationTag,
                        })}
                    >
                        <label htmlFor="lct-1">
                            Location Tag{" "}
                            <NotAnchor
                                onClick={() => {
                                    openOverlayPopup(0);
                                }}
                                className="text-right"
                            >
                                <i className="icon-help" />{" "}
                                <span className="hidden">Data Input</span>
                            </NotAnchor>
                        </label>
                        <input
                            type="text"
                            id="lct-1"
                            {...register("locationTag")}
                        />
                        {errors.locationTag && (
                            <label
                                id="lct-1-error"
                                className="error"
                                htmlFor="lct-1"
                            >
                                {errors.locationTag.message}
                            </label>
                        )}
                    </p>
                    <p
                        className={classNames({
                            "has-error": errors.valueTags,
                        })}
                    >
                        <label htmlFor="vlc-1">
                            Value Tags{" "}
                            <NotAnchor
                                onClick={() => {
                                    openOverlayPopup(1);
                                }}
                                className="text-right"
                            >
                                <i className="icon-help" />{" "}
                                <span className="hidden">Data Input</span>
                            </NotAnchor>
                        </label>
                        <input
                            type="text"
                            id="vlc-1"
                            {...register("valueTags")}
                        />
                        {errors.valueTags && (
                            <label
                                id="lct-1-error"
                                className="error"
                                htmlFor="lct-1"
                            >
                                {errors.valueTags.message}
                            </label>
                        )}
                    </p>
                    <p
                        className={classNames({
                            "has-error": errors.radiusMiles,
                        })}
                    >
                        <label htmlFor="rct-1">
                            Radius (Miles){" "}
                            <NotAnchor
                                onClick={() => {
                                    openOverlayPopup(2);
                                }}
                                className="text-right"
                            >
                                <i className="icon-help" />{" "}
                                <span className="hidden">Data Input</span>
                            </NotAnchor>
                        </label>
                        <input
                            type="number"
                            id="rct-1"
                            {...register("radiusMiles")}
                        />
                        {errors.radiusMiles && (
                            <label
                                id="lct-1-error"
                                className="error"
                                htmlFor="lct-1"
                            >
                                {errors.radiusMiles.message}
                            </label>
                        )}
                    </p>
                    <p
                        className={classNames({
                            "has-error": errors.responseTag,
                        })}
                    >
                        <label htmlFor="rsct-1">
                            Response Tag{" "}
                            <NotAnchor
                                onClick={() => {
                                    openOverlayPopup(3);
                                }}
                                className="text-right"
                            >
                                <i className="icon-help" />{" "}
                                <span className="hidden">Data Output</span>
                            </NotAnchor>
                        </label>
                        <input
                            type="text"
                            id="rsct-1"
                            {...register("responseTag")}
                        />
                        {errors.responseTag && (
                            <label
                                id="lct-1-error"
                                className="error"
                                htmlFor="lct-1"
                            >
                                {errors.responseTag.message}
                            </label>
                        )}
                    </p>
                </>
            )}
        </GrasSetupFormPopup>
    );
};

export default GrasSetupPopup;
