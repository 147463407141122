import { FC } from "react";

const GeneralErrorPage: FC = () => (
    <div id="wrap" className="center text-center">
        <main id="content" className="text-center">
            <div className="module-success inline">
                <h2>
                    <i aria-hidden="true" className="icon-alert"></i> Something
                    Went Wrong
                </h2>
                <p>
                    A monk asked, “When great difficulties come upon us how can
                    they be avoided?”
                    <br /> The master said, “Well come!”{" "}
                    <span className="size-14">
                        — Sayings of Zen master Joshu (778–897)
                    </span>
                </p>
            </div>
        </main>
    </div>
);

export default GeneralErrorPage;
