import { AlertCategory, Page, TabInterface, usePage } from "components/Page";
import { FC, useCallback, useEffect } from "react";
import { Footer } from "components/MainPane";
import ChecklistExtraPane from "components/extra-panes/ChecklistExtraPane";
import {
    DndContext,
    closestCenter,
    PointerSensor,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import {
    arrayMove,
    useSortable,
    SortableContext,
    verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import capitalize from "lodash/capitalize";
import range from "lodash/range";
import { Select } from "components/ReactHookFormUncontrolledComponents";
import { MenuItem as SelectMenuItem } from "components/Select";
import FormControl from "@mui/material/FormControl";
import { retry } from "utils/retry";
import {
    DocumentIncludeV1,
    DocumentPartV1,
    DocumentSchemaV1,
    ProductVersion,
} from "@joshuins/builder";
import NotAnchor from "components/NotAnchor";
import MenuPopover, { MenuItem } from "components/MenuPopover";
import {
    DocumentsProvider,
    tabToPacketType,
    useDocuments,
} from "./components/DocumentProvider";
import { usePopup } from "contexts/PopupProvider";
import { DragEndEvent } from "@dnd-kit/core";
import { useApi } from "contexts/ApiProvider";
import classNames from "classnames";
import { z } from "zod";
import axios from "axios";
import { ConfirmationPopup, Popup, createFormPopup } from "components/Popup";
import { FormulaSuggestionInput } from "components/ReactHookFormUncontrolledComponents";
import { FileUpload } from "components/FileUpload";
import ProductMainPane from "./components/ProductMainPane";
import { useWatch } from "react-hook-form";
import { getMessageFromAxiosError } from "utils/axios-extras";

const TEMPLATE_REFERENCES = "template-references" as const;

const TemplateReferences: FC = () => {
    const {
        accessors: { documentTemplate: getDocumentTemplate },
    } = useDocuments();

    const { popupData } = usePopup();
    const part = popupData?.part as DocumentPartV1 | undefined;
    const documentTemplate = part && getDocumentTemplate(part.template_id);

    return (
        <Popup name={TEMPLATE_REFERENCES}>
            <>
                <h2>Reference Codes</h2>
                {documentTemplate &&
                documentTemplate.references &&
                documentTemplate.references.length > 0 ? (
                    <div className="div-as-p">
                        These are the reference codes used by this template:
                        <pre
                            id="template-references-json"
                            style={{ whiteSpace: "pre-wrap" }}
                        >
                            {documentTemplate.references?.map((item) => (
                                <>
                                    <br />
                                    {item}
                                </>
                            ))}
                        </pre>
                    </div>
                ) : (
                    <div>No codes are used by this template</div>
                )}
            </>
        </Popup>
    );
};

const DeletePartPopup: FC = () => {
    const { popupData } = usePopup();
    const { addAlertMessages } = usePage();
    const {
        mutators: { removePart },
    } = useDocuments();
    const part = popupData?.part as DocumentPartV1 | undefined;
    const documentCode = popupData?.documentCode as DocumentSchemaV1["code"];

    const onSubmit = async () => {
        if (!part) {
            return;
        }
        removePart(documentCode, part.code);
        addAlertMessages({
            message: "Document part deleted successfully",
            category: AlertCategory.SUCCESS,
        });
    };

    return (
        <ConfirmationPopup
            name="delete-part"
            submitText="Delete"
            onSubmit={onSubmit}
        >
            <header>
                <h2>Are you sure?</h2>
                <p className="size-14">
                    The part will no longer be included in quotes, binders, or
                    policies. This cannot be undone.
                </p>
            </header>
        </ConfirmationPopup>
    );
};

const DeleteDocumentPopup: FC = () => {
    const { popupData } = usePopup();
    const { addAlertMessages } = usePage();
    const document = popupData?.document as DocumentSchemaV1 | undefined;
    const {
        mutators: { removeDocument },
    } = useDocuments();

    const onSubmit = async () => {
        if (!document) {
            return;
        }

        await removeDocument(document.code);

        addAlertMessages({
            message: "Document deleted successfully",
            category: AlertCategory.SUCCESS,
        });
    };

    return (
        <ConfirmationPopup
            name="delete-document"
            submitText="Delete"
            onSubmit={onSubmit}
        >
            <header>
                <h2>Are you sure?</h2>
                <p className="size-14">
                    This document and all its parts and their files will be
                    deleted.
                </p>
            </header>
        </ConfirmationPopup>
    );
};

const MovePartPopup: FC = () => {
    const { popupData } = usePopup();
    const {
        mutators: { movePart },
    } = useDocuments();

    const oldDocument = popupData?.oldDocument as DocumentSchemaV1 | undefined;
    const newDocument = popupData?.newDocument as DocumentSchemaV1 | undefined;
    const part = popupData?.part as DocumentPartV1 | undefined;

    const onSubmit = async () => {
        if (!oldDocument || !newDocument || !part) {
            return;
        }

        movePart(oldDocument.code, newDocument.code, part);
    };

    return (
        <ConfirmationPopup
            name="move-part"
            submitText="Move"
            onSubmit={onSubmit}
        >
            <header>
                <h2>Are you sure?</h2>
                <p className="size-14">
                    The document part will be removed from its current document
                    and moved to the new selected document.
                </p>
            </header>
        </ConfirmationPopup>
    );
};

const DocumentSelect: FC = () => {
    const { activeTab } = usePage();
    const {
        accessors: { allDocuments },
    } = useDocuments();
    const activeTab_ = activeTab();
    const documentType = activeTab_ ? tabToPacketType(activeTab_) : undefined;
    const { setValue, control } = useAddOrEditPartFormPopupReturn();

    const documents = allDocuments();

    useEffect(() => {
        if (documentType === "Application") {
            setValue("document", "none");
        }
    }, [documentType, setValue]);

    return (
        <Select
            labelId="document-label"
            id="ffh"
            name="document"
            control={control}
            fullWidth
        >
            <SelectMenuItem key="none" value="none">
                (None)
            </SelectMenuItem>
            {documents !== undefined &&
                documents.map((document) => (
                    <SelectMenuItem key={document.code} value={document.code}>
                        {document.name}
                    </SelectMenuItem>
                ))}
        </Select>
    );
};

const addOrEditPartSchema = z.object({
    document: z.union([
        z.string({ required_error: "This field is required" }),
        z.literal("none"),
    ]),
    code: z
        .object({
            value: z.string().min(1, "This field is required"),
            context: z.object({
                usedCodes: z.union([z.string().array(), z.null()]),
                originalCode: z.union([z.string(), z.null()]),
            }),
        })
        .refine(
            async ({ value, context }) => {
                const { usedCodes, originalCode } = context;
                if (usedCodes === null) {
                    return true;
                }

                if (originalCode !== null && originalCode === value) {
                    return true;
                }

                return !usedCodes.includes(value);
            },
            {
                message: "Code already in use",
                path: ["value"],
            }
        ),
    template_file: z.union([
        z.instanceof(File, { message: "No file selected" }),
        z.string().min(1, "No file selected"),
    ]),
    name: z.string().min(1, "This field is required"),
    form_number: z.string(),
    include: z
        .object({
            value: z.enum(["Always", "Condition", "Manual"], {
                errorMap: () => ({
                    message: "Please choose if the part is required",
                }),
            }),
            formula: z.string(),
            removable: z.enum(["no", "yes"], {
                errorMap: () => ({
                    message: "Please choose if this part can be removed",
                }),
            }),
        })
        .refine(
            (value) => value.value !== "Condition" || value.formula.length > 0,
            {
                message: "You must supply a condition",
                path: ["text"],
            }
        )
        .refine(
            (value) => value.value !== "Manual" || value.removable === "yes",
            {
                message: "A part must be removable if it is included manually",
                path: ["text"],
            }
        ),
});

type AddOrEditPartType = z.infer<typeof addOrEditPartSchema>;

const {
    FormPopup: AddOrEditPartFormPopup,
    useFormReturnRef: useAddOrEditPartFormReturnRef,
    useFormPopupReturn: useAddOrEditPartFormPopupReturn,
} = createFormPopup(addOrEditPartSchema);

const IncludeTypeFields: FC = () => {
    const {
        register,
        control,
        setValue,
        watch,
        formState: { errors },
        openOverlayPopup,
    } = useAddOrEditPartFormPopupReturn();

    const includeTypeValue = useWatch({
        control,
        name: "include.value",
    });

    useEffect(() => {
        if (includeTypeValue === "Manual") {
            setValue("include.removable", "yes");
        }
    }, [includeTypeValue, setValue]);

    return (
        <>
            <div className="">
                <h3 className="label">
                    When should this document part automatically be included?
                </h3>
                <ul className="check m25">
                    <li>
                        <input
                            {...register("include.value")}
                            type="radio"
                            id="fak-part-always"
                            value="Always"
                        />
                        <label htmlFor="fak-part-always">Always</label>
                    </li>
                    <li>
                        <input
                            {...register("include.value")}
                            type="radio"
                            id="fak-part-formula"
                            value="Condition"
                        />
                        <label htmlFor="fak-part-formula">
                            If the following condition is met...
                        </label>
                        <span className="text-right">
                            <NotAnchor
                                onClick={() => {
                                    openOverlayPopup(5);
                                    return;
                                }}
                            >
                                <i className="icon-help" />{" "}
                                <span className="hidden">More info</span>
                            </NotAnchor>
                        </span>
                        <div
                            className={classNames({
                                hidden: watch("include.value") !== "Condition",
                            })}
                        >
                            <FormulaSuggestionInput
                                name="include.formula"
                                control={control}
                                id="fal"
                                suggestions={[
                                    "Q1>1000",
                                    "SUM(Q1, Q2)",
                                    "MAX(Q1:Q2)",
                                    "MIN(Q1:Q2)",
                                    "IF(Q1<=3)",
                                ]}
                            />
                            {errors.include?.message && (
                                <label
                                    id="fal-error"
                                    className="error"
                                    htmlFor="fal"
                                >
                                    {errors.include.message}
                                </label>
                            )}
                        </div>
                    </li>
                    <li>
                        <input
                            {...register("include.value")}
                            type="radio"
                            id="fak-part-manual"
                            value="Manual"
                        />
                        <label htmlFor="fak-part-manual">Manual</label>
                    </li>
                </ul>
            </div>

            <div className="">
                <h3 className="label">If included, is it mandatory?</h3>
                <ul className="check m25">
                    <li>
                        <input
                            {...register("include.removable")}
                            type="radio"
                            id="fnp-req"
                            value="no"
                            disabled={watch("include.value") === "Manual"}
                        />
                        <label htmlFor="fnp-req">Yes. It is required.</label>
                    </li>
                    <li>
                        <input
                            {...register("include.removable")}
                            type="radio"
                            id="fnp-not-req"
                            value="yes"
                            disabled={watch("include.value") === "Manual"}
                        />
                        <label htmlFor="fnp-not-req">
                            No. An underwriter can remove it.
                        </label>
                    </li>
                </ul>
            </div>
        </>
    );
};

const includeTypeFormDataToApiData = (
    include: AddOrEditPartType["include"] | AddOrEditDocumentType["include"]
): DocumentIncludeV1 => {
    switch (include.value) {
        case "Always":
            return {
                Always: {
                    removable: include.removable === "yes",
                },
            };
        case "Condition":
            return {
                Condition: {
                    removable: include.removable === "yes",
                    formula: include.formula,
                },
            };
        case "Manual":
            return {
                Manual: {},
            };
    }
};

const getDocumentTemplateIncludeTypeAndValue = (include: DocumentIncludeV1) => {
    if ("Always" in include) {
        return ["Always", include["Always"]] as const;
    }
    if ("Condition" in include) {
        return ["Condition", include["Condition"]] as const;
    }
    if ("Manual" in include) {
        return ["Manual", include["Manual"]] as const;
    }
    throw new Error(`Unknown include type found in ${Object.keys(include)}`);
};

const includeTypeApiDataToFormData = (
    apiData: DocumentIncludeV1
): AddOrEditPartType["include"] => {
    const [includeType, includeValues] =
        getDocumentTemplateIncludeTypeAndValue(apiData);

    switch (includeType) {
        case "Always":
            return {
                value: includeType,
                formula: "",
                removable: includeValues.removable ? "yes" : "no",
            };
        case "Condition":
            return {
                value: includeType,
                formula: includeValues.formula,
                removable: includeValues.removable ? "yes" : "no",
            };
        case "Manual":
            return {
                value: includeType,
                formula: "",
                removable: "no",
            };
    }
};

const AddOrEditPartPopup: FC = () => {
    const { sdkBuilder } = useApi();
    const { popupData, isPopupOpen } = usePopup();
    const { addAlertMessages, activeTab } = usePage();
    const {
        mutators: {
            addPartToExistingDocument,
            addPartToNewDocument,
            updatePart,
        },
        accessors: { partAndIndex, generateUniqueRandomCode, usedCodes },
        allowEditing,
    } = useDocuments();
    const {
        formReturn: { reset, setValue },
        formReturnRefCallback,
    } = useAddOrEditPartFormReturnRef();
    const partCode = popupData?.partCode as string | undefined;
    const documentCode = popupData?.documentCode as string | undefined;
    const activeTab_ = activeTab();
    const documentType = activeTab_ ? tabToPacketType(activeTab_) : undefined;

    const createDocumentTemplate = useCallback(
        async (templateFile: File) => {
            try {
                return await sdkBuilder.createDocumentTemplateFromFile(
                    templateFile
                );
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    addAlertMessages({
                        message: getMessageFromAxiosError(error),
                        category: AlertCategory.ALERT,
                    });
                } else {
                    throw error;
                }
            }
        },
        [addAlertMessages, sdkBuilder]
    );

    const transformFormDataToApiDataForCreate = useCallback(
        async (
            formData: AddOrEditPartType
        ): Promise<DocumentPartV1 | undefined> => {
            if (!(formData.template_file instanceof File)) {
                return;
            }

            const documentTemplate = await createDocumentTemplate(
                formData.template_file
            );

            if (!documentTemplate) {
                return;
            }

            return {
                code: formData.code.value,
                template_id: documentTemplate.id,
                name: formData.name,
                form_number: formData.form_number,
                include: includeTypeFormDataToApiData(formData.include),
            };
        },
        [createDocumentTemplate]
    );

    const transformFormDataToApiDataForUpdate = useCallback(
        async (
            formData: AddOrEditPartType,
            partToUpdate: DocumentPartV1
        ): Promise<DocumentPartV1 | undefined> => {
            const documentTemplate =
                formData.template_file instanceof File
                    ? await createDocumentTemplate(formData.template_file)
                    : null;

            if (documentTemplate === undefined) {
                return;
            }

            return {
                ...partToUpdate,
                code: formData.code.value,
                ...(documentTemplate && { template_id: documentTemplate.id }),
                name: formData.name,
                form_number: formData.form_number,
                include: includeTypeFormDataToApiData(formData.include),
            };
        },
        [createDocumentTemplate]
    );

    const transformApiDataToFormData = useCallback(
        async (
            part: DocumentPartV1,
            documentCode: DocumentSchemaV1["code"]
        ): Promise<AddOrEditPartType | undefined> => {
            try {
                const getFileResponse =
                    await sdkBuilder.getFileResponseFromDocumentTemplateId(
                        part.template_id
                    );

                return {
                    document: documentCode,
                    code: {
                        value: part.code,
                        context: {
                            usedCodes: usedCodes?.["part"] ?? null,
                            originalCode: part.code,
                        },
                    },
                    template_file: getFileResponse.filename,
                    name: part.name ?? "",
                    form_number: part.form_number ?? "",
                    include: includeTypeApiDataToFormData(part.include),
                };
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    addAlertMessages({
                        message: getMessageFromAxiosError(error),
                        category: AlertCategory.ALERT,
                    });
                } else {
                    throw error;
                }
            }
        },
        [addAlertMessages, sdkBuilder, usedCodes]
    );

    useEffect(() => {
        const getPart = async () => {
            if (!isPopupOpen("add-or-edit-part") || !reset || !setValue) {
                return;
            }

            if (partCode) {
                if (!documentCode) {
                    return;
                }
                try {
                    const { part } = partAndIndex(documentCode, partCode);
                    if (!part) {
                        return;
                    }

                    const formData = await transformApiDataToFormData(
                        part,
                        documentCode
                    );
                    if (formData) {
                        reset(formData);
                    }
                } catch (error) {
                    if (axios.isAxiosError(error)) {
                        addAlertMessages({
                            message: getMessageFromAxiosError(error),
                            category: AlertCategory.ALERT,
                        });
                    } else {
                        throw error;
                    }
                }
            } else {
                const randomPartCode = generateUniqueRandomCode("part");
                if (!randomPartCode) {
                    return;
                }
                setValue("code", {
                    value: randomPartCode,
                    context: {
                        usedCodes: usedCodes?.["part"] ?? null,
                        originalCode: null,
                    },
                });
            }
        };
        getPart();
    }, [
        addAlertMessages,
        documentCode,
        generateUniqueRandomCode,
        isPopupOpen,
        partAndIndex,
        partCode,
        reset,
        setValue,
        transformApiDataToFormData,
        usedCodes,
    ]);

    const onSubmit = useCallback(
        async (formData: AddOrEditPartType) => {
            if (partCode) {
                if (!documentCode) {
                    return;
                }
                const { part } = partAndIndex(documentCode, partCode);
                if (!part) {
                    return;
                }

                const updatedPart = await transformFormDataToApiDataForUpdate(
                    formData,
                    part
                );

                if (!updatedPart) {
                    return;
                }

                await updatePart(documentCode, partCode, updatedPart);
                addAlertMessages({
                    message: "Document part updated successfully",
                    category: AlertCategory.SUCCESS,
                });
            } else {
                const newPart =
                    await transformFormDataToApiDataForCreate(formData);
                if (!newPart) {
                    return;
                }

                if (formData.document === "none") {
                    await addPartToNewDocument(newPart);
                } else {
                    await addPartToExistingDocument(formData.document, newPart);
                }
                addAlertMessages({
                    message: "Document part added successfully",
                    category: AlertCategory.SUCCESS,
                });
            }
        },
        [
            addAlertMessages,
            addPartToExistingDocument,
            addPartToNewDocument,
            documentCode,
            partAndIndex,
            partCode,
            transformFormDataToApiDataForCreate,
            transformFormDataToApiDataForUpdate,
            updatePart,
        ]
    );

    if (!activeTab_) {
        return <></>;
    }

    return (
        <AddOrEditPartFormPopup
            name="add-or-edit-part"
            defaultValues={{
                document: "none",
                code: {
                    value: "",
                    context: {
                        usedCodes: null,
                        originalCode: null,
                    },
                },
                template_file: "",
                name: "",
                form_number: "",
                include: {
                    value: "Always",
                    formula: "",
                    removable: "no",
                },
            }}
            submitText="Save"
            formReturnRefCallback={formReturnRefCallback}
            onSubmit={onSubmit}
            disabled={!allowEditing}
            overlayPopups={[
                <>
                    <h3>Display Name</h3>
                    <p>
                        This is the name that appears in all of the document
                        packets. Can be (and probably should be) different than
                        the actual filename.
                    </p>
                </>,
                <>
                    <h3>Code</h3>
                    <p>
                        The part&apos;s code is it&apos;s unique identifier
                        which can be used in other parts of the system.
                        {!partCode &&
                            " We have generated a random one for you which you can modify to something that you can understand"}
                    </p>
                </>,
                <>
                    <h3>Form Number</h3>
                    <p>
                        If you refer to your forms by a label system, for
                        example &quot;FM-1,&quot; you can enter that here. Can
                        be a mix of numbers, letters, and special characters.
                    </p>
                </>,
                <>
                    <h3>Document</h3>
                    <p>
                        Add this document part to an existing document. You can
                        move it to another document (or create a new document
                        for it) later if necessary.
                    </p>
                </>,
                <>
                    <h3>File</h3>
                    <p>
                        Any file type can be added. However, only Word docs with
                        Joshu Reference Tags can be used as templates for the
                        application, quote, and policy packets. If you upload
                        something like a PDF, it will be included in the correct
                        packet, but the information in the PDF cannot be
                        changed.
                    </p>
                </>,
                <>
                    <h3>Conditional</h3>
                    <p>
                        Use this option when you want to hide a document until
                        certain conditions are met. For example, if this is a
                        state-specific endorsement, you can use the following
                        Excel formula with Joshu Reference tags to show the
                        document: insured.state=&quot;CA&quot;
                    </p>
                </>,
            ]}
        >
            {({
                register,
                control,
                openOverlayPopup,
                formState: { errors },
            }) => {
                return (
                    <>
                        <header className="m30">
                            <h2>Document Part</h2>
                        </header>
                        <p>
                            <label htmlFor="fah">
                                Display Name
                                <span className="text-right">
                                    <NotAnchor
                                        onClick={() => {
                                            openOverlayPopup(0);
                                        }}
                                    >
                                        <i
                                            aria-hidden="true"
                                            className="icon-help"
                                        />{" "}
                                        <span className="hidden">
                                            More info
                                        </span>
                                    </NotAnchor>
                                </span>
                            </label>
                            <input
                                {...register("name")}
                                type="text"
                                id="fah"
                                maxLength={256}
                            />
                            {errors.name && (
                                <label className="error" htmlFor="fah">
                                    {errors.name.message}
                                </label>
                            )}
                        </p>
                        <div className="cols">
                            <p className="c50">
                                <label htmlFor="fai2">
                                    Code
                                    <span className="text-right">
                                        <NotAnchor
                                            onClick={() => {
                                                openOverlayPopup(1);
                                            }}
                                        >
                                            <i
                                                aria-hidden="true"
                                                className="icon-help"
                                            />{" "}
                                            <span className="hidden">
                                                More info
                                            </span>
                                        </NotAnchor>
                                    </span>
                                </label>
                                <input
                                    {...register("code.value")}
                                    type="text"
                                    id="fai2"
                                />
                                {errors.code?.value && (
                                    <label className="error" htmlFor="fai2">
                                        {errors.code.value.message}
                                    </label>
                                )}
                            </p>
                        </div>
                        <div className="cols">
                            <p className="c50">
                                <label htmlFor="fai">
                                    Form Number (Optional){" "}
                                    <span className="text-right">
                                        <NotAnchor
                                            onClick={() => {
                                                openOverlayPopup(2);
                                            }}
                                        >
                                            <i
                                                aria-hidden="true"
                                                className="icon-help"
                                            />{" "}
                                            <span className="hidden">
                                                More info
                                            </span>
                                        </NotAnchor>
                                    </span>
                                </label>
                                <input
                                    {...register("form_number")}
                                    type="text"
                                    id="fai"
                                />
                            </p>
                        </div>
                        <div
                            className={classNames("div-as-p", {
                                hidden:
                                    partCode || documentType === "Application",
                            })}
                        >
                            <span className="label">
                                Document
                                <span className="text-right">
                                    <NotAnchor
                                        onClick={() => {
                                            openOverlayPopup(3);
                                        }}
                                    >
                                        <i
                                            aria-hidden="true"
                                            className="icon-help"
                                        />
                                        <span className="hidden">
                                            More info
                                        </span>
                                    </NotAnchor>
                                </span>
                            </span>
                            <FormControl fullWidth error={!!errors.document}>
                                <DocumentSelect />
                                {errors.document && (
                                    <label className="error" htmlFor="ffh">
                                        {errors.document.message}
                                    </label>
                                )}
                            </FormControl>
                        </div>

                        <FileUpload
                            id="faj"
                            name="template_file"
                            control={control}
                            fileDesignation={
                                documentType === "Application"
                                    ? "ApplicationDocumentTemplate"
                                    : "DocumentTemplate"
                            }
                            disabled={!allowEditing}
                            error={errors.template_file?.message}
                            Label={
                                <span className="label">
                                    File{" "}
                                    <span className="text-right">
                                        10MB Limit{" "}
                                        <NotAnchor
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                openOverlayPopup(4);
                                            }}
                                        >
                                            <i
                                                aria-hidden="true"
                                                className="icon-help"
                                            />{" "}
                                            <span className="hidden">
                                                More info
                                            </span>
                                        </NotAnchor>
                                    </span>
                                </span>
                            }
                        />
                        <IncludeTypeFields />
                    </>
                );
            }}
        </AddOrEditPartFormPopup>
    );
};

const addOrEditDocumentSchema = z.object({
    code: z
        .object({
            value: z.string().min(1, "This field is required"),
            context: z.object({
                usedCodes: z.union([z.string().array(), z.null()]),
                originalCode: z.union([z.string(), z.null()]),
            }),
        })
        .refine(
            async ({ value, context }) => {
                const { usedCodes, originalCode } = context;
                if (usedCodes === null) {
                    return true;
                }

                if (originalCode !== null && originalCode === value) {
                    return true;
                }

                return !usedCodes.includes(value);
            },
            {
                message: "Code already in use",
                path: ["value"],
            }
        ),
    name: z.string().min(1, "This field is required"),
    include: z
        .object({
            value: z.enum(["Always", "Condition", "Manual"], {
                errorMap: () => ({
                    message: "Please choose if the part is required",
                }),
            }),
            formula: z.string(),
            removable: z.enum(["no", "yes"], {
                errorMap: () => ({
                    message: "Please choose if this part can be removed",
                }),
            }),
        })
        .refine(
            (value) => value.value !== "Condition" || value.formula.length > 0,
            {
                message: "You must supply a condition",
                path: ["text"],
            }
        )
        .refine(
            (value) => value.value !== "Manual" || value.removable === "yes",
            {
                message: "A part must be removable if it is included manually",
                path: ["text"],
            }
        ),
});
type AddOrEditDocumentType = z.infer<typeof addOrEditDocumentSchema>;

const {
    FormPopup: AddOrEditDocumentFormPopup,
    useFormReturnRef: useAddOrEditDocumentFormReturnRef,
} = createFormPopup(addOrEditDocumentSchema);

const AddOrEditDocumentPopup: FC = () => {
    const { popupData, isPopupOpen } = usePopup();
    const { addAlertMessages } = usePage();
    const {
        allowEditing,
        accessors: { documentAndIndex, generateUniqueRandomCode, usedCodes },
        mutators: { updateDocument, addDocument },
    } = useDocuments();
    const {
        formReturn: { reset, setValue },
        formReturnRefCallback,
    } = useAddOrEditDocumentFormReturnRef();
    const documentCode = popupData?.documentCode as string | undefined;

    const transformFormDataToApiDataForCreate = useCallback(
        (formData: AddOrEditDocumentType): DocumentSchemaV1 => ({
            code: formData.code.value,
            name: formData.name,
            include: includeTypeFormDataToApiData(formData.include),
            parts: [],
        }),
        []
    );

    const transformFormDataToApiDataForUpdate = useCallback(
        (
            formData: AddOrEditDocumentType,
            partToUpdate: DocumentSchemaV1
        ): DocumentSchemaV1 => ({
            ...partToUpdate,
            code: formData.code.value,
            name: formData.name,
            include: includeTypeFormDataToApiData(formData.include),
        }),
        []
    );

    const transformApiDataToFormData = useCallback(
        (document: DocumentSchemaV1): AddOrEditDocumentType => ({
            code: {
                value: document.code,
                context: {
                    usedCodes: usedCodes?.["document"] ?? null,
                    originalCode: document.code,
                },
            },
            name: document.name,
            include: includeTypeApiDataToFormData(document.include),
        }),
        [usedCodes]
    );

    useEffect(() => {
        if (!isPopupOpen("add-or-edit-document") || !reset || !setValue) {
            return;
        }

        if (documentCode) {
            const { document } = documentAndIndex(documentCode);
            if (!document) {
                return;
            }

            reset(transformApiDataToFormData(document));
        } else {
            const randomDocumentCode = generateUniqueRandomCode("document");
            if (!randomDocumentCode) {
                return;
            }
            setValue("code", {
                value: randomDocumentCode,
                context: {
                    usedCodes: usedCodes?.["document"] ?? null,
                    originalCode: null,
                },
            });
        }
    }, [
        documentAndIndex,
        documentCode,
        generateUniqueRandomCode,
        isPopupOpen,
        reset,
        setValue,
        transformApiDataToFormData,
        usedCodes,
    ]);

    const onSubmit = useCallback(
        async (data: AddOrEditDocumentType) => {
            if (documentCode) {
                const { document } = documentAndIndex(documentCode);
                if (!document) {
                    return;
                }

                await updateDocument(
                    documentCode,
                    transformFormDataToApiDataForUpdate(data, document)
                );
                addAlertMessages({
                    message: "Document updated successfully",
                    category: AlertCategory.SUCCESS,
                });
            } else {
                await addDocument(transformFormDataToApiDataForCreate(data));
                addAlertMessages({
                    message: "Document created successfully",
                    category: AlertCategory.SUCCESS,
                });
            }
        },
        [
            addAlertMessages,
            addDocument,
            documentAndIndex,
            documentCode,
            transformFormDataToApiDataForCreate,
            transformFormDataToApiDataForUpdate,
            updateDocument,
        ]
    );

    return (
        <AddOrEditDocumentFormPopup
            name="add-or-edit-document"
            defaultValues={{
                code: {
                    value: "",
                    context: {
                        usedCodes: null,
                        originalCode: null,
                    },
                },
                name: "",
                include: {
                    value: "Always",
                    formula: "",
                    removable: "no",
                },
            }}
            submitText="Save"
            formReturnRefCallback={formReturnRefCallback}
            onSubmit={onSubmit}
            disabled={!allowEditing}
            overlayPopups={[
                <>
                    <h3>Document Name</h3>
                    <p>
                        Use a document when you want several files to be
                        combined into a single PDF. For example, if you need to
                        merge several policy documents to create the final
                        policy document, you might create a document named
                        &quot;Policy.&quot; Any files added to that document
                        will be merged into a single file when downloaded as a
                        PDF.
                    </p>
                </>,
                <>
                    <h3>Code</h3>
                    <p>
                        The document&apos;s code is used for referencing the
                        document uniquely other places in the system.
                        {!documentCode &&
                            " We have generated a random one for you which you can modify to something that you can understand"}
                    </p>
                </>,
                <>
                    <h3>Conditional</h3>
                    <p>
                        Use Excel formulas to evaluate answers, display if true.
                    </p>
                </>,
            ]}
        >
            {({
                register,
                watch,
                control,
                openOverlayPopup,
                formState: { errors },
            }) => {
                return (
                    <>
                        <header className="m30">
                            <h2>Document</h2>
                        </header>
                        <p>
                            <label htmlFor="fahdc-542">
                                Display Name
                                <span className="text-right">
                                    <NotAnchor
                                        onClick={() => {
                                            openOverlayPopup(0);
                                        }}
                                    >
                                        <i className="icon-help" />{" "}
                                        <span className="hidden">
                                            More info
                                        </span>
                                    </NotAnchor>
                                </span>
                            </label>
                            <input
                                {...register("name")}
                                type="text"
                                id="fahdc-542"
                                maxLength={256}
                            />
                            {errors.name && (
                                <label
                                    id="fahdc-542-error"
                                    className="error"
                                    htmlFor="fahdc-542"
                                >
                                    {errors.name.message}
                                </label>
                            )}
                        </p>
                        <div className="cols">
                            <p className="c50">
                                <label htmlFor="fahdc-2">
                                    Code
                                    <span className="text-right">
                                        <NotAnchor
                                            onClick={() => {
                                                openOverlayPopup(1);
                                            }}
                                        >
                                            <i
                                                aria-hidden="true"
                                                className="icon-help"
                                            />{" "}
                                            <span className="hidden">
                                                More info
                                            </span>
                                        </NotAnchor>
                                    </span>
                                </label>
                                <input
                                    {...register("code.value")}
                                    type="text"
                                    id="fahdc-2"
                                />
                                {errors.code?.value && (
                                    <label className="error" htmlFor="fahdc-2">
                                        {errors.code.value.message}
                                    </label>
                                )}
                            </p>
                        </div>
                        <ul className="check overlay font-medium m25">
                            <li>
                                <input
                                    {...register("include.value")}
                                    type="radio"
                                    id="fak-always"
                                    value="Always"
                                />
                                <label htmlFor="fak-always">Always</label>
                            </li>
                            <li>
                                <input
                                    {...register("include.value")}
                                    type="radio"
                                    id="fak-formula"
                                    value="Condition"
                                />
                                <label htmlFor="fak-formula">
                                    If the following condition is met...
                                </label>
                                <span className="text-right">
                                    <NotAnchor
                                        onClick={() => {
                                            openOverlayPopup(2);
                                            return;
                                        }}
                                    >
                                        <i className="icon-help" />{" "}
                                        <span className="hidden">
                                            More info
                                        </span>
                                    </NotAnchor>
                                </span>
                                <div
                                    className={classNames({
                                        hidden:
                                            watch("include.value") !==
                                            "Condition",
                                    })}
                                >
                                    <FormulaSuggestionInput
                                        name="include.formula"
                                        control={control}
                                        id="fal"
                                        suggestions={[
                                            "Q1>1000",
                                            "SUM(Q1, Q2)",
                                            "MAX(Q1:Q2)",
                                            "MIN(Q1:Q2)",
                                            "IF(Q1<=3)",
                                        ]}
                                    />
                                    {errors.include?.message && (
                                        <label
                                            id="fal-error"
                                            className="error"
                                            htmlFor="fal"
                                        >
                                            {errors.include.message}
                                        </label>
                                    )}
                                </div>
                            </li>
                        </ul>
                    </>
                );
            }}
        </AddOrEditDocumentFormPopup>
    );
};

const PartRow: FC<{
    documentCode: string;
    partCode: string;
}> = ({ documentCode, partCode }) => {
    const {
        allowEditing,
        mutators: { updateDocumentTemplate },
        accessors: {
            parts,
            allDocuments,
            partAndIndex,
            documentAndIndex,
            otherDocumentsWithSingleParts,
            otherDocumentsWithZeroTwoOrMoreParts,
            documentHasSinglePart,
            documentTemplate: getDocumentTemplate,
        },
    } = useDocuments();
    const { sdkBuilder } = useApi();

    const { part, index: partIndex } = partAndIndex(documentCode, partCode);

    let sortableId;
    let zIndex: number | undefined = undefined;
    const { document, index: documentIndex } = documentAndIndex(documentCode);
    const documentTemplate = part && getDocumentTemplate(part.template_id);

    if (documentHasSinglePart(documentCode)) {
        sortableId = documentCode;
        if (documentIndex !== undefined) {
            const allDocuments_ = allDocuments();
            if (allDocuments_ !== undefined) {
                zIndex = allDocuments_.length - documentIndex;
            }
        }
    } else {
        sortableId = partCode;
        const parts_ = parts(documentCode);
        if (partIndex !== undefined && parts_) {
            zIndex = parts_.length - partIndex;
        }
    }

    const { attributes, listeners, setNodeRef, transform, transition } =
        useSortable({
            id: sortableId,
        });

    const { openPopup } = usePopup();

    useEffect(() => {
        if (!part || !documentTemplate) {
            return;
        }

        if (documentTemplate.status !== "Processing") {
            return;
        }

        // @TODO: Waiting for document schema statuses in order to implement all
        // this correctly
        const [
            waitForDocumentTemplatePromise,
            cancelRetryWaitForDocumentTemplate,
        ] = retry(
            () => sdkBuilder.getDocumentTemplate({ id: part.template_id }),
            (documentTemplate) => documentTemplate.status !== "Processing",
            2000
        );

        const waitForDocumentTemplate = async () => {
            try {
                const documentTemplate_ = await waitForDocumentTemplatePromise;
                if (!documentTemplate_) {
                    return;
                }
                updateDocumentTemplate(part.template_id, documentTemplate_);
            } catch (error) {
                if (!axios.isAxiosError(error)) {
                    throw error;
                }
            }
        };
        waitForDocumentTemplate();
        return () => {
            cancelRetryWaitForDocumentTemplate();
        };
    }, [documentTemplate, part, sdkBuilder, updateDocumentTemplate]);

    if (!part || !document || !documentTemplate) {
        return <></>;
    }

    const otherDocumentsWithSingleParts_ =
        otherDocumentsWithSingleParts(documentCode);

    const otherDocumentsWithZeroTwoOrMoreParts_ =
        otherDocumentsWithZeroTwoOrMoreParts(documentCode);

    if (
        !otherDocumentsWithSingleParts_ ||
        !otherDocumentsWithZeroTwoOrMoreParts_
    ) {
        return <></>;
    }

    const menuItems: MenuItem[] = [];

    if (allowEditing && otherDocumentsWithZeroTwoOrMoreParts_.length > 0) {
        menuItems.push({
            key: `${partCode}-move-to-document`,
            label: "Move to document...",
            children: otherDocumentsWithZeroTwoOrMoreParts_.map(
                (otherDocument) => ({
                    key: otherDocument.code,
                    label: otherDocument.name,
                    icon: "list",
                    onClick: () => {
                        openPopup("move-part", {
                            oldDocument: document,
                            newDocument: otherDocument,
                            part,
                        });
                    },
                })
            ),
        });
    }

    if (allowEditing && otherDocumentsWithSingleParts_.length > 0) {
        menuItems.push({
            key: `${partCode}-create-new-document-with`,
            label: "Create new document with...",
            hasSeparator: otherDocumentsWithZeroTwoOrMoreParts_.length > 0,
            children: otherDocumentsWithSingleParts_.map((otherDocument) => ({
                key: otherDocument.parts[0].code,
                label: otherDocument.parts[0].name ?? "(no name)",
                icon: "list",
                onClick: () => {
                    openPopup("move-part", {
                        oldDocument: document,
                        newDocument: otherDocument,
                        part,
                    });
                },
            })),
        });
    }

    menuItems.push({
        key: `${partCode}-download-file`,
        label: "Download File",
        icon: "download",
        hasSeparator:
            otherDocumentsWithZeroTwoOrMoreParts_.length +
                otherDocumentsWithSingleParts_.length >
            0,
        downloadFileId: documentTemplate.file_id,
    });

    if (allowEditing)
        menuItems.push({
            key: `${partCode}-delete-part`,
            label: "Delete File",
            icon: "trash",
            hasSeparator: true,
            onClick: () => {
                openPopup("delete-part", {
                    part,
                    documentCode,
                });
            },
        });

    menuItems.push({
        key: `${partCode}-template-codes`,
        label: "References",
        icon: "doc-edit",
        hasSeparator: true,
        onClick: () => {
            openPopup(TEMPLATE_REFERENCES, {
                part,
            });
        },
    });

    if (transform) {
        transform.x = 0;
    }

    return (
        <MenuPopover
            ref={setNodeRef}
            menuItems={menuItems}
            toggleButtonLabel={`Toggle${part.name ? ` ${part.name}` : ""}`}
            style={{
                zIndex,
                transform: CSS.Transform.toString(transform),
                transition,
            }}
        >
            {({ ToggleButton, Menu }) => (
                <>
                    <NotAnchor
                        className="toggle-sub"
                        onClick={() => {
                            openPopup("add-or-edit-part", {
                                partCode: part.code,
                                documentCode: document.code,
                            });
                        }}
                    >
                        {part.name ?? "(no name)"}
                    </NotAnchor>
                    <>
                        {ToggleButton}
                        {Menu}
                    </>

                    {documentTemplate.status === "Error" && (
                        <i
                            className="icon-error"
                            style={{ right: "-22px" }}
                            title={documentTemplate.error ?? undefined}
                        />
                    )}

                    <ul className="list-inline">
                        {part.form_number &&
                            documentTemplate.status !== "Error" && (
                                <li>{part.form_number}</li>
                            )}
                        {documentTemplate.status === "Error" && (
                            <li style={{ textTransform: "none" }}>
                                Upload Error{" "}
                            </li>
                        )}
                        {documentTemplate.status === "Processing" && (
                            <>
                                <li
                                    className="processing-tag"
                                    style={{
                                        background: "none",
                                        color: "var(--black_40)",
                                        fontFamily: "Red Hat Text",
                                        fontSize: "12px",
                                        fontWeight: 500,
                                        lineHeight: "22px",
                                        letterSpacing: "0em",
                                        textAlign: "right",
                                        textTransform: "none",
                                    }}
                                >
                                    Uploading
                                </li>
                                <li
                                    style={{
                                        background: "none",
                                        textTransform: "none",
                                    }}
                                >
                                    <div
                                        className="lds-ring"
                                        style={{
                                            width: "20px",
                                            height: "20px",
                                        }}
                                    >
                                        {range(1, 5).map((level) => (
                                            <div
                                                key={level}
                                                style={{
                                                    borderColor:
                                                        "var(--bg_primary_icon) transparent transparent transparent",
                                                    borderWidth: "3px",
                                                }}
                                            />
                                        ))}
                                    </div>
                                </li>
                            </>
                        )}
                    </ul>
                    <div
                        className="handle ui-sortable-handle"
                        {...attributes}
                        {...listeners}
                    />
                </>
            )}
        </MenuPopover>
    );
};

const DocumentRow: FC<{
    documentCode: string;
}> = ({ documentCode }) => {
    const { attributes, listeners, setNodeRef, transform, transition } =
        useSortable({ id: documentCode });
    const {
        allowEditing,
        accessors: { documentAndIndex, allDocuments },
        mutators: { setParts },
    } = useDocuments();
    const { openPopup } = usePopup();
    const sensors = useSensors(useSensor(PointerSensor));

    const { document, index: documentIndex } = documentAndIndex(documentCode);

    if (!document) {
        return <></>;
    }

    const numDocuments = allDocuments()?.length;

    if (transform) {
        transform.x = 0;
    }

    const handleDragEnd = (event: DragEndEvent) => {
        const parts = document.parts;
        const { active, over } = event;

        if (!over || !active || active.id === over.id || parts.length === 0) {
            return;
        }

        const oldIndex = parts.findIndex((part) => part.code === active.id);
        const newIndex = parts.findIndex((part) => part.code === over.id);

        const updatedParts = arrayMove(parts, oldIndex, newIndex);

        setParts(documentCode, updatedParts);
    };

    return (
        <MenuPopover
            ref={setNodeRef}
            additionalClasses="open strong has-sub"
            menuItems={
                allowEditing
                    ? [
                          {
                              key: `${documentCode}-delete-document`,
                              label: "Delete Document",
                              icon: "trash",
                              onClick: () => {
                                  openPopup("delete-document", {
                                      document: document,
                                  });
                              },
                          },
                      ]
                    : []
            }
            toggleButtonLabel={`Toggle ${document.name}`}
            style={{
                ...(numDocuments !== undefined && {
                    zIndex: numDocuments - documentIndex,
                }),
                transform: CSS.Transform.toString(transform),
                transition,
            }}
        >
            {({ ToggleButton, Menu }) => (
                <>
                    <NotAnchor
                        className="toggle-sub"
                        onClick={() => {
                            openPopup("add-or-edit-document", {
                                documentCode,
                            });
                        }}
                    >
                        {document.name}
                    </NotAnchor>
                    {allowEditing && ToggleButton}
                    <NotAnchor
                        role="tab"
                        className="open-close-menu"
                        onClick={() => {
                            return;
                        }}
                    />
                    <ul>
                        <li>{document.parts.length}</li>
                    </ul>
                    {allowEditing && Menu}

                    {document.parts.length > 0 && (
                        <DndContext
                            sensors={sensors}
                            collisionDetection={closestCenter}
                            onDragEnd={handleDragEnd}
                        >
                            <ul className="list-drag ui-sortable">
                                <SortableContext
                                    items={document.parts.map((part) => ({
                                        id: part.code,
                                        ...part,
                                    }))}
                                    strategy={verticalListSortingStrategy}
                                    disabled={!allowEditing}
                                >
                                    {document.parts.map((part) => (
                                        <PartRow
                                            key={part.code}
                                            documentCode={documentCode}
                                            partCode={part.code}
                                        />
                                    ))}
                                </SortableContext>
                            </ul>
                        </DndContext>
                    )}
                    <div
                        className="handle ui-sortable-handle"
                        {...attributes}
                        {...listeners}
                    />
                </>
            )}
        </MenuPopover>
    );
};

const DocumentTab: FC<{ tab: TabInterface }> = ({ tab }) => {
    const { element } = usePage();
    const productVersion = element as unknown as ProductVersion | undefined;
    const {
        allowEditing,
        accessors: { productDocuments, allDocuments, allParts },
        mutators: { setDocuments, setProductDocuments },
    } = useDocuments();
    const sensors = useSensors(useSensor(PointerSensor));
    const { openPopup } = usePopup();

    useEffect(() => {
        if (!productVersion) {
            return;
        }
        if (!productDocuments) {
            setProductDocuments(productVersion.schema.spec.documents);
        }
    }, [productDocuments, productVersion, setProductDocuments]);

    if (!productVersion) {
        return <></>;
    }

    const documents = allDocuments();

    const handleDragEnd = (event: DragEndEvent) => {
        const { active, over } = event;

        if (
            !over ||
            !active ||
            active.id === over.id ||
            !documents ||
            documents.length === 0
        ) {
            return;
        }

        const oldIndex = documents.findIndex(
            (document) => document.code === active.id
        );
        const newIndex = documents.findIndex(
            (document) => document.code === over.id
        );

        const updatedDocuments = arrayMove(documents, oldIndex, newIndex);

        setDocuments(updatedDocuments);
    };

    return (
        <>
            <div className="module-tabs-content">
                <div>
                    <DndContext
                        sensors={sensors}
                        collisionDetection={closestCenter}
                        onDragEnd={handleDragEnd}
                    >
                        <ul className="list-drag draggable ui-sortable">
                            {!documents || documents.length === 0 ? (
                                <div className="module-success inline">
                                    {tab.path === "application" ? (
                                        <>
                                            <h2>
                                                <i
                                                    aria-hidden="true"
                                                    className="icon-documents"
                                                />{" "}
                                                No Application Yet
                                            </h2>
                                            <p>
                                                Add an application template to
                                                allow users to download a PDF
                                                version
                                            </p>
                                        </>
                                    ) : (
                                        <>
                                            <h2>
                                                <i
                                                    aria-hidden="true"
                                                    className="icon-documents"
                                                />{" "}
                                                No Documents Yet
                                            </h2>
                                            <p>
                                                Add your formatted documents
                                                here to automatically process
                                                them for quotes and policies.
                                            </p>
                                        </>
                                    )}
                                </div>
                            ) : (
                                <SortableContext
                                    items={documents.map((document) => ({
                                        id: document.code,
                                        ...document,
                                    }))}
                                    strategy={verticalListSortingStrategy}
                                    disabled={!allowEditing}
                                >
                                    {documents.map((document) =>
                                        document.parts.length === 1 ? (
                                            <PartRow
                                                // using the document.code as the key for a <PartRow>, instead of part.code
                                                // makes sense here because the <PartRow> is being used in place of a
                                                // <DocumentRow> in this context when there is only one part in the document
                                                key={document.code}
                                                documentCode={document.code}
                                                partCode={
                                                    document.parts[0].code
                                                }
                                            />
                                        ) : (
                                            <DocumentRow
                                                key={document.code}
                                                documentCode={document.code}
                                            />
                                        )
                                    )}
                                </SortableContext>
                            )}
                        </ul>
                    </DndContext>
                </div>
            </div>
            {allowEditing && (
                <>
                    {documents && documents.length > 0 && (
                        <div style={{ paddingBottom: "200px" }} />
                    )}
                    ;
                    <Footer>
                        {tab.path !== "application" && (
                            <p className="link">
                                <NotAnchor
                                    onClick={() => {
                                        openPopup("add-or-edit-document");
                                    }}
                                >
                                    <i
                                        aria-hidden="true"
                                        className="icon-documents"
                                    />{" "}
                                    Create Document
                                </NotAnchor>
                            </p>
                        )}
                        <p className="link-btn">
                            <NotAnchor
                                className={classNames({
                                    disabled:
                                        tab.path === "application" &&
                                        allParts &&
                                        allParts.length > 0,
                                })}
                                onClick={() => {
                                    openPopup("add-or-edit-part");
                                }}
                            >
                                <i
                                    aria-hidden="true"
                                    className="icon-plus-circle"
                                />
                                Add {capitalize(tab.title)} Part
                                <span className="scheme-accesskey">
                                    <span>Shift</span> <span>d</span>
                                </span>
                            </NotAnchor>
                        </p>
                    </Footer>
                </>
            )}
        </>
    );
};

const Main = () => {
    const { activeTab } = usePage();
    const {
        accessors: { allDocuments: getAllDocuments },
    } = useDocuments();

    const activeTab_ = activeTab();
    const allDocuments = getAllDocuments();

    if (!activeTab_) {
        return <></>;
    }

    return (
        <ProductMainPane
            title="Documents"
            layoutConfig={{
                mainLayout:
                    allDocuments && allDocuments.length > 0 ? "wide" : "center",
            }}
        >
            <DocumentTab tab={activeTab_} />
        </ProductMainPane>
    );
};

const ProductVersionCheckList = () => {
    const { element } = usePage();
    const productVersion = element as unknown as ProductVersion | undefined;

    if (!productVersion) {
        return <></>;
    }

    return (
        <ChecklistExtraPane
            associatedId={productVersion.id}
            checklistType="product"
            title="Document Setup Checklist"
            description={
                "Follow these steps to configure the rater for this product. " +
                "Click 'Help Center' for videos and articles to learn more. " +
                "When you are finished, check the step as complete."
            }
            tags={[
                {
                    tag: "GatherFiles",
                    title: "Gather Original Files",
                    url: "https://help.joshu.insure/en/articles/6396291-documents-gather-original-files",
                },
                {
                    tag: "AddCodes",
                    title: "Add Reference Codes",
                    url: "https://help.joshu.insure/en/articles/6396293-documents-add-reference-tags",
                },
                {
                    tag: "Upload",
                    title: "Upload to Joshu",
                    url: "https://help.joshu.insure/en/articles/6396294-documents-upload-to-joshu",
                },
                {
                    tag: "GroupDocuments",
                    title: "Group Documents (optional)",
                    url: "https://help.joshu.insure/en/articles/6904811-document-groups",
                },
            ]}
        />
    );
};

const DocumentsPage: FC<{ tabs?: TabInterface[] }> = ({ tabs }) => (
    <Page tabs={tabs}>
        <DocumentsProvider>
            <Main />
            <ProductVersionCheckList />
            <AddOrEditDocumentPopup />
            <AddOrEditPartPopup />
            <DeleteDocumentPopup />
            <DeletePartPopup />
            <MovePartPopup />
            <TemplateReferences />
        </DocumentsProvider>
    </Page>
);

export default DocumentsPage;
