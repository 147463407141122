import { useLocation } from "react-router-dom";
import { hideLoader, unhideRoot } from "paul/native-dom-manipulation";
import {
    createContext,
    useContext,
    FC,
    PropsWithChildren,
    useEffect,
    useCallback,
    useMemo,
} from "react";

interface UnhidePageProviderInterface {
    blockUnhide: () => void;
    unhide: () => void;
}

const UnhidePageContext = createContext<
    UnhidePageProviderInterface | undefined
>(undefined);

const useUnhidePage = () => {
    const context = useContext(UnhidePageContext);

    if (context === undefined) {
        throw Error(
            "useUnhidePage must be used inside a UnhidePageProvider context"
        );
    }

    return context;
};

const UnhidePageProvider: FC<PropsWithChildren> = ({ children }) => {
    const location = useLocation();

    const block = useMemo(
        () => ({
            block: false,
        }),
        []
    );

    const blockUnhide = () => {
        // NB, we are not setting a state variable here since block.block is not a state variable.
        // It's done like this because we want a variable that will block the page being unhidden without
        // doing a rerender that would have happened if block.block was a state variable.
        block.block = true;
    };

    const unhide = useCallback(() => {
        unhideRoot();
        hideLoader();
        block.block = false;
    }, [block]);

    useEffect(() => {
        if (!block.block) {
            unhide();
        }
    }, [location.key, unhide, block.block]);

    return (
        <UnhidePageContext.Provider
            value={{
                blockUnhide,
                unhide,
            }}
        >
            {children}
        </UnhidePageContext.Provider>
    );
};

export { useUnhidePage, UnhidePageProvider };
