/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import "./Select.scss";
import MuiSelect, { SelectProps as MuiSelectProps } from "@mui/material/Select";
import MuiMenuItem, { MenuItemTypeMap } from "@mui/material/MenuItem";
import InputBase from "@mui/material/InputBase";
import { FC } from "react";
import { ExtendButtonBase } from "@mui/material/ButtonBase";
import { useFieldSet } from "./Form";

const Input = (
    <InputBase
        css={css`
            height: 38px;
            &.Mui-focused .MuiInputBase-input {
                box-shadow:
                    -2px 0 0 var(--bg_primary_input_border),
                    2px 0 0 var(--bg_primary_input_border),
                    0 -2px 0 var(--bg_primary_input_border),
                    0 2px 0 var(--bg_primary_input_border),
                    -1px -1px 0 var(--bg_primary_input_border),
                    1px -1px 0 var(--bg_primary_input_border),
                    -1px 1px 0 var(--bg_primary_input_border),
                    1px 1px 0 var(--bg_primary_input_border);
                border-color: var(--main_color);
                border-radius: 2px;
            }
            & .MuiInputBase-input {
                display: block;
                width: 100%;
                border-radius: 2px;
                border: 1px solid var(--black_10);
                background: var(--white) 100% center no-repeat;
                background-size: auto 5px;
                color: var(--black_100);
                font-weight: 400;
                font-size: 16px;
                font-family: var(--font_content);
                padding: 0 12px;
                line-height: 38px;

                em {
                    color: var(--black_40);
                    font-style: normal;
                }
            }
            &.Mui-error .MuiInputBase-input {
                box-shadow:
                    inset 1px 1px var(--main_color),
                    inset -1px 1px var(--main_color),
                    inset -1px -1px var(--main_color),
                    inset 1px -1px var(--main_color);
                border-color: var(--main_color);
            }
        `}
    />
);

const IconComponent: FC = () => (
    <span
        css={css`
            .MuiInputBase-root & {
                display: block;
                position: absolute;
                right: 0;
                top: 0;
                bottom: 0;
                width: 42px;
                color: var(--main_color);
                font-size: 5px;
                cursor: pointer;
                // https://stackoverflow.com/a/51416934
                pointer-events: none;
                &:before {
                    content: "\\e911";
                    display: block;
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 50%;
                    margin: -10px 0 0;
                    font-weight: 400;
                    font-family: i;
                    line-height: 20px;
                    text-align: center;
                    text-indent: 0;
                    letter-spacing: normal;
                }
            }
        `}
    />
);

const MenuItem: ExtendButtonBase<MenuItemTypeMap> = ({ ...rest }) => (
    <MuiMenuItem
        {...rest}
        css={css`
            color: var(--black_100);
            font-size: 14px;
            padding: 3px 0 3px 38px;
            font-family: var(--font_content);
            line-height: 1.25;
            &:hover {
                background-color: transparent;
                color: var(--main_color);
            }
            &.Mui-selected {
                background-color: transparent;
                &:before {
                    content: "\\e917";
                    left: 18px;
                    right: auto;
                    margin-top: 0;
                    color: var(--main_color);
                    font-size: 8px;
                    line-height: 16px;
                    display: block;
                    position: absolute;
                    font-weight: 400;
                    font-family: i;
                    text-align: center;
                    text-indent: 0;
                    letter-spacing: normal;
                }
                &:hover {
                    background-color: transparent;
                }
            }
        `}
        disableRipple
    />
);

type SelectProps<T> = Omit<
    MuiSelectProps<T>,
    "input" | "IconComponent" | "variant"
>;

const Select: <T>(props: SelectProps<T>) => JSX.Element = ({
    displayEmpty,
    multiple,
    ...rest
}) => {
    const fieldset = useFieldSet();
    return (
        <MuiSelect
            // these "displayEmpty" and "multiple" lines are a weird TS workaround to handle the
            // ConditionalRenderValueType not working well with the Omit<SelectProps<T>,....> above
            displayEmpty={displayEmpty as true}
            multiple={multiple as true}
            {...rest}
            notched={undefined}
            MenuProps={{
                className: "styled-select-menu",
            }}
            input={Input}
            IconComponent={IconComponent}
            onClose={(event) => event.stopPropagation()}
            disabled={fieldset && fieldset.disabled}
        />
    );
};

export { Select as Select, MenuItem as MenuItem };
export type { SelectProps };
