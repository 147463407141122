import { FC, useEffect, useState } from "react";
import { Link, useNavigate } from "components/DevAwareRoutingLink";
import { useApi } from "contexts/ApiProvider";
import { MainPane } from "components/MainPane";
import {
    AlertCategory,
    AlertMessageInterface,
    Page,
    TabInterface,
    usePage,
} from "components/Page";
import GettingStartedExtraPane from "components/extra-panes/GettingStartedPane";
import { formatDatetimeString } from "utils/datetime";
import { CreateStorePopup, StoreType } from "./StoreForm";
import urlJoin from "url-join";
import { BUILDER_PATH } from "globals";
import { unpaginate } from "components/sdk";
import { Store } from "@joshuins/builder";

interface ListStorePageInterface {
    tabs?: TabInterface[];
}

const TAB_PATH_TO_CHANNEL_LOOKUP = {
    direct: "Direct",
    broker: "Broker",
} as const;

const NewStorePopup: FC = () => {
    const { sdkBuilder } = useApi();
    const { tryCatchAndRaiseError } = usePage();
    const navigate = useNavigate();

    const onSubmit = async (data: StoreType) => {
        tryCatchAndRaiseError(async () => {
            const newStore = await sdkBuilder.createStore({ NewStore: data });
            const alertMessage: AlertMessageInterface = {
                message: `Successfully created ${data.name}`,
                category: AlertCategory.SUCCESS,
            };
            navigate(
                urlJoin(
                    "/",
                    BUILDER_PATH,
                    "stores",
                    newStore.id.toString(),
                    "home"
                ),
                {
                    state: {
                        alertMessages: alertMessage,
                    },
                }
            );
        });
    };

    return (
        <CreateStorePopup
            name="new-store"
            header="New online store"
            onSubmit={onSubmit}
        />
    );
};

const Main: FC = () => {
    const { sdkBuilder } = useApi();
    const { activeTab } = usePage();
    const tabPath = activeTab()
        ?.path as keyof typeof TAB_PATH_TO_CHANNEL_LOOKUP;
    const channel = TAB_PATH_TO_CHANNEL_LOOKUP[tabPath];

    const [stores, setStores] = useState<Store[]>([]);

    useEffect(() => {
        const getStores = async () => {
            const stores_ = await unpaginate(sdkBuilder.allStores, { channel });
            setStores(stores_);
        };
        getStores();
    }, [channel, sdkBuilder.allStores]);

    if (!stores) {
        return <></>;
    }

    return (
        <MainPane
            title="Stores"
            cta={{
                buttonLabel: "New Store",
                popupName: "new-store",
            }}
            layoutConfig={{
                mainLayout: stores.length > 0 ? "wide" : "center",
            }}
        >
            {stores.length > 0 ? (
                <div className="module-tabs-content">
                    <div>
                        <ul className="list-products">
                            {stores.map((store) => (
                                <li key={store.id}>
                                    <Link
                                        to={urlJoin(
                                            "/",
                                            BUILDER_PATH,
                                            "stores",
                                            store.id.toString(),
                                            "home"
                                        )}
                                    >
                                        <i className="icon-cart-window" />
                                        {store.name}
                                        {store.last_updated ? (
                                            <span>
                                                Modified&nbsp;
                                                {formatDatetimeString(
                                                    store.last_updated
                                                )}
                                            </span>
                                        ) : (
                                            <span
                                                style={{
                                                    height: "1.25em",
                                                }}
                                            />
                                        )}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            ) : (
                <div className="module-success inline">
                    <h2>
                        <i className="icon-cart-window" /> No Stores Yet
                    </h2>
                    <p>
                        Click &quot;New Store&quot; above to start setting up
                        your first store.
                    </p>
                </div>
            )}
        </MainPane>
    );
};

const ListStoresPage: FC<ListStorePageInterface> = ({ tabs }) => (
    <Page tabs={tabs}>
        <Main />
        <GettingStartedExtraPane />
        <NewStorePopup />
    </Page>
);

export default ListStoresPage;
