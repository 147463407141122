import { FC, useCallback, useEffect } from "react";
import { useInsuranceProcess } from "../InsuranceProcessProvider";
import { usePopup } from "contexts/PopupProvider";
import NotAnchor from "components/NotAnchor";
import MenuPopover from "components/MenuPopover";
import { useApi } from "contexts/ApiProvider";
import {
    Document,
    DocumentPacketTypeV1,
    DocumentPartV1,
    DocumentSchemaV1,
    DocumentStatus,
    QuoteCodeAndValue,
    QuoteStatus,
} from "@joshuins/insurance";
import FileDownload from "components/FileDownload";
import compact from "lodash/compact";
import axios from "axios";
import classNames from "classnames";
import range from "lodash/range";
import { retry } from "utils/retry";
import keyBy from "lodash/keyBy";
import Tooltip from "@mui/material/Tooltip";
import {
    joValueIsPlainAndBoolean,
    wrapAsJoValue,
} from "utils/jo-types-and-values";
import { documentTypeToTitle } from "pages/components/util";
import sortByLexorank from "utils/lexorank";
import {
    getIncludedQuoteDocsCodes,
    isDocumentIncluded,
    getManualDocumentsFromQuoteData,
    getDocumentByCode,
    getManualPartsFromQuoteData,
} from "./util";
import { isQuote } from "utils/policies";

const DocumentPartListItem: FC<{
    documentPart: DocumentPartV1;
    numOfParts?: number;
    index?: number;
    readOnly: boolean;
    document?: Document;
    manual?: boolean;
}> = ({ documentPart, numOfParts, index, readOnly, document, manual }) => {
    const {
        itemGetters: { getInsuranceProcess },
        insuranceProcessDispatch,
    } = useInsuranceProcess();
    const { quoteCodesAndValues } = getInsuranceProcess();

    const quoteCodesAndValuesLookup = keyBy(quoteCodesAndValues, "code");
    const removeDocument = useCallback(async () => {
        const removedItem = {
            code: documentPart.code,
            user_value: wrapAsJoValue({ Boolean: false }),
            rater_value:
                quoteCodesAndValuesLookup[documentPart.code].rater_value,
            rank: quoteCodesAndValuesLookup[documentPart.code].rank,
        };

        insuranceProcessDispatch({
            action: "SetDirtyFields",
            dirtyFields: [removedItem],
        });
        insuranceProcessDispatch({
            action: "UpdateQuoteCodesAndValues",
            quoteCodesAndValues: [removedItem],
        });
    }, [
        documentPart.code,
        insuranceProcessDispatch,
        quoteCodesAndValuesLookup,
    ]);

    const removeManualPart = useCallback(async () => {
        // if its the last part of a parent, mark the parent as false as well
        const parentCode = documentPart.code.split(".").slice(0, 3).join(".");
        const parentParts = getManualPartsFromQuoteData(
            quoteCodesAndValues,
            parentCode
        );
        const shouldDeleteParent = parentParts.length === 1;
        let quoteDataManualPartCodesToDelete = quoteCodesAndValues.filter(
            (data) =>
                data.code.split(".")[4] === documentPart.code.split(".")[4]
        );
        if (shouldDeleteParent) {
            const parentCodeAndValue = quoteCodesAndValues.filter(
                (data) =>
                    data.code.split(".")[2] === parentCode.split(".")[2] &&
                    data.code.split(".").length === 3
            );

            quoteDataManualPartCodesToDelete =
                quoteDataManualPartCodesToDelete.concat(parentCodeAndValue);
        }
        const removeManualPart: QuoteCodeAndValue[] = [];

        quoteDataManualPartCodesToDelete.map((item) => {
            // set user_value to false if its boolean
            if (joValueIsPlainAndBoolean(item.user_value)) {
                removeManualPart.push({
                    code: item.code,
                    user_value: wrapAsJoValue({ Boolean: false }),
                    rater_value: wrapAsJoValue({ Boolean: false }),
                    rank: item.rank,
                });
            } else {
                removeManualPart.push({
                    code: item.code,
                    user_value: item.user_value,
                    rater_value: item.rater_value,
                    rank: item.rank,
                });
            }
        });

        insuranceProcessDispatch({
            action: "SetDirtyFields",
            dirtyFields: removeManualPart,
        });
        insuranceProcessDispatch({
            action: "UpdateQuoteCodesAndValues",
            quoteCodesAndValues: removeManualPart,
        });
    }, [documentPart.code, insuranceProcessDispatch, quoteCodesAndValues]);

    // add the remove document button when needed
    let isRemovable: boolean = false;
    if (
        (("Always" in documentPart.include &&
            documentPart.include.Always.removable) ||
            ("Condition" in documentPart.include &&
                documentPart.include.Condition.removable) ||
            "Manual" in documentPart.include) &&
        !readOnly
    ) {
        isRemovable = true;
    }

    const menuItems = [];
    // application documents should'nt have toggle.
    // they can be downloaded through the FileDownload component
    if (document?.document_type !== DocumentPacketTypeV1.Application) {
        if (document) {
            menuItems.push({
                key: "download-document",
                label: "Download Document",
                icon: "download",
                downloadFileId: document.file_id || undefined,
            });
        }
        if (isRemovable) {
            menuItems.push({
                key: "remove",
                label: "Remove",
                icon: "trash",
                onClick: () => {
                    manual ? removeManualPart() : removeDocument();
                },
            });
        }
        menuItems;
    }

    return (
        <MenuPopover
            key={documentPart.code}
            menuItems={menuItems}
            component="li"
            style={{
                ...(numOfParts !== undefined &&
                    index !== undefined && {
                        zIndex: numOfParts - index,
                    }),
            }}
        >
            {({ ToggleButton, Menu }) => {
                return (
                    <>
                        <NotAnchor onClick={() => {}}>
                            <i aria-hidden="true" className="icon-document" />
                            {documentPart.name}
                        </NotAnchor>
                        {manual && !readOnly && (
                            <ul className="list-inline text-right">
                                <li
                                    style={{
                                        color: "#8f8b88",
                                        fontSize: "10px",
                                    }}
                                >
                                    MANUSCRIPT
                                </li>
                            </ul>
                        )}
                        {/* <div></div> is here because of Paul's CSS that adds an unwanted downward arrow from ::after of last element. */}
                        <div></div>
                        {menuItems.length > 0 && (
                            <>
                                {ToggleButton}
                                {Menu}
                            </>
                        )}
                    </>
                );
            }}
        </MenuPopover>
    );
};

const DocumentListItem: FC<{
    documentSchema: DocumentSchemaV1;
    document?: Document;
    schemaIndex: number;
    numOfSchemas: number;
    readOnly: boolean;
    manual?: boolean;
}> = ({
    documentSchema,
    document,
    readOnly,
    schemaIndex,
    numOfSchemas,
    manual,
}) => {
    const {
        itemGetters: { getInsuranceProcess },
        insuranceProcessState: { dirtyFields: dirtyFields },
    } = useInsuranceProcess();
    const { quoteCodesAndValues } = getInsuranceProcess();
    const quoteCodesAndValuesLookup = keyBy(quoteCodesAndValues, "code");
    const includedQuoteDocsCodes = getIncludedQuoteDocsCodes(
        Object.values(quoteCodesAndValuesLookup)
    );

    const manualParts = getManualPartsFromQuoteData(
        Object.values(quoteCodesAndValuesLookup),
        documentSchema.code
    );

    const schemaParts = documentSchema.parts.filter((docPart) =>
        includedQuoteDocsCodes.includes(docPart.code)
    );
    const allParts = schemaParts.concat(manualParts);
    const sortedCodesByRanks = sortByLexorank([...quoteCodesAndValues]).map(
        (item) => item.code
    );
    allParts.sort(
        (a, b) =>
            sortedCodesByRanks.indexOf(a.code) -
            sortedCodesByRanks.indexOf(b.code)
    );

    const { sdkInsurance } = useApi();
    const { openPopup } = usePopup();
    const { insuranceProcessDispatch } = useInsuranceProcess();

    const removeManualDocument = useCallback(async () => {
        const quoteDataManualCodes = quoteCodesAndValues.filter(
            (data) =>
                data.code.split(".")[2] === documentSchema.code.split(".")[2]
        );
        const removeManualDocument: QuoteCodeAndValue[] = [];
        quoteDataManualCodes.map((item) => {
            // set user_value to false if its boolean
            if (joValueIsPlainAndBoolean(item.user_value)) {
                removeManualDocument.push({
                    code: item.code,
                    user_value: wrapAsJoValue({ Boolean: false }),
                    rater_value: wrapAsJoValue({ Boolean: false }),
                    rank: item.rank,
                });
            } else {
                removeManualDocument.push({
                    code: item.code,
                    user_value: item.user_value,
                    rater_value: item.rater_value,
                    rank: item.rank,
                });
            }
        });

        insuranceProcessDispatch({
            action: "SetDirtyFields",
            dirtyFields: removeManualDocument,
        });
        insuranceProcessDispatch({
            action: "UpdateQuoteCodesAndValues",
            quoteCodesAndValues: removeManualDocument,
        });
    }, [documentSchema.code, insuranceProcessDispatch, quoteCodesAndValues]);

    useEffect(() => {
        if (!document?.id) {
            return;
        }

        const [waitForDocumentPromise, cancelRetryWaitForDocument] = retry(
            () =>
                sdkInsurance.getDocument({
                    id: document.id,
                }),
            (document_) => document_.status !== DocumentStatus.Processing,
            2000
        );

        const checkDocumentTemplateReady = async () => {
            try {
                const document_ = await waitForDocumentPromise;
                if (document_) {
                    insuranceProcessDispatch({
                        action: "SetDocument",
                        document: document_,
                    });
                }
            } catch (error) {
                if (!axios.isAxiosError(error)) {
                    throw error;
                }
            }
        };
        checkDocumentTemplateReady();
        return () => {
            cancelRetryWaitForDocument();
        };
    }, [document?.id, insuranceProcessDispatch, sdkInsurance]);

    if (document && document.status == DocumentStatus.Processing) {
        return (
            <>
                <li className="sub">
                    <NotAnchor onClick={() => {}}>
                        <i className="icon-document" />
                        {document.name}
                        <div
                            className="lds-ring text-right"
                            style={{
                                width: "20px",
                                height: "20px",
                            }}
                        >
                            {range(1, 5).map((level) => (
                                <div
                                    key={level}
                                    style={{
                                        borderColor:
                                            "var(--bg_primary_icon) transparent transparent transparent",
                                        borderWidth: "3px",
                                    }}
                                />
                            ))}
                        </div>
                    </NotAnchor>
                    <i />
                </li>
            </>
        );
    } else if (document && document.status == DocumentStatus.Error) {
        return (
            <MenuPopover
                key={documentSchema.code}
                menuItems={[]}
                component="li"
                style={{ zIndex: numOfSchemas - schemaIndex }}
            >
                {({ ToggleButton, Menu }) => {
                    return (
                        <>
                            <NotAnchor
                                onClick={() => {
                                    openPopup("document-error-popup", {
                                        error: document.docmosis_error,
                                    });
                                }}
                            >
                                <Tooltip
                                    title={document.docmosis_error}
                                    placement="bottom-start"
                                >
                                    <i
                                        aria-hidden="true"
                                        className="icon-x-circle color-wine"
                                    />
                                </Tooltip>
                                {document.name}
                            </NotAnchor>
                            {ToggleButton}
                            {Menu}
                        </>
                    );
                }}
            </MenuPopover>
        );
    }
    if (documentSchema.parts.length === 1) {
        return (
            <DocumentPartListItem
                documentPart={documentSchema.parts[0]}
                readOnly={readOnly}
                document={document}
                numOfParts={numOfSchemas}
                index={schemaIndex}
            />
        );
    } else if (documentSchema.parts.length > 1 || manual) {
        const menuItems = compact([
            document &&
                dirtyFields.length === 0 && {
                    key: "download-document",
                    label: "Download Document",
                    icon: "download",
                    downloadFileId: document?.file_id || undefined,
                },
            !readOnly && {
                key: `upload-manu-part-${documentSchema.code}`,
                label: "Upload Manuscript",
                icon: "upload",
                onClick: () => {
                    openPopup("upload-manu-part", {
                        documentSchema: documentSchema,
                        parts: allParts,
                    });
                },
            },
            manual &&
                !readOnly && {
                    key: `delete-manu-doc-${documentSchema.code}`,
                    label: "Remove",
                    icon: "trash",
                    onClick: () => {
                        removeManualDocument();
                    },
                },
        ]);
        return (
            <>
                <MenuPopover
                    key={documentSchema.code}
                    menuItems={menuItems}
                    component="li"
                    style={{
                        zIndex: documentSchema.parts.length + 1,
                    }}
                >
                    {({ ToggleButton, Menu }) => {
                        return (
                            <>
                                <NotAnchor onClick={() => {}}>
                                    <i
                                        aria-hidden="true"
                                        className="icon-document"
                                    />
                                    {documentSchema.name}
                                </NotAnchor>
                                {ToggleButton}
                                {Menu}
                            </>
                        );
                    }}
                </MenuPopover>
                <ul className="list-plain box">
                    {allParts.map((docPart, index) => {
                        return (
                            <DocumentPartListItem
                                key={docPart.name}
                                documentPart={docPart}
                                numOfParts={documentSchema.parts.length}
                                index={index}
                                readOnly={readOnly}
                                manual={
                                    docPart.code.split(".").length > 2
                                        ? true
                                        : false
                                }
                            />
                        );
                    })}
                </ul>
            </>
        );
    }
};

const DocumentsSection: FC<{
    documentType: DocumentPacketTypeV1;
}> = ({ documentType }) => {
    const {
        itemGetters: { getInsuranceProcess },
        insuranceProcessState: { dirtyFields: dirtyFields },
    } = useInsuranceProcess();
    const { quote, documents, productVersion, quoteCodesAndValues } =
        getInsuranceProcess();
    const { openPopup } = usePopup();
    const quoteCodesAndValuesLookup = keyBy(quoteCodesAndValues, "code");

    const includedQuoteDocsCodes = getIncludedQuoteDocsCodes(
        Object.values(quoteCodesAndValuesLookup)
    );
    let typeDocuments = documents.filter(
        (document) => document.document_type === documentType
    );

    let documentsSchemas: DocumentSchemaV1[] = [];
    let readOnly: boolean = true;
    let preview: boolean = true;
    switch (documentType) {
        // the following business logic of 'readonly' and 'preview' is described in the table in ticket number DEV-3247
        case DocumentPacketTypeV1.NewQuote: {
            documentsSchemas =
                productVersion.schema.spec.documents.packets.NewQuote
                    ?.documents ?? [];
            readOnly = quote.status !== QuoteStatus.QuotePending;
            preview = quote.status === QuoteStatus.QuotePending;
            break;
        }
        case DocumentPacketTypeV1.Binder: {
            documentsSchemas =
                productVersion.schema.spec.documents.packets.Binder
                    ?.documents ?? [];
            readOnly =
                (isQuote(quote) && quote.status !== QuoteStatus.QuotePending) ||
                (!isQuote(quote) &&
                    quote.status !== QuoteStatus.BinderPending) ||
                false;
            preview =
                isQuote(quote) || quote.status === QuoteStatus.BinderPending;
            break;
        }
        case DocumentPacketTypeV1.Policy: {
            documentsSchemas =
                productVersion.schema.spec.documents.packets.Policy
                    ?.documents ?? [];
            readOnly =
                (isQuote(quote) && quote.status !== QuoteStatus.QuotePending) ||
                (!isQuote(quote) &&
                    quote.status !== QuoteStatus.BinderPending &&
                    quote.status !== QuoteStatus.BinderPublished) ||
                false;
            preview =
                isQuote(quote) || quote.status !== QuoteStatus.CoverageActive;
            break;
        }
        case DocumentPacketTypeV1.EndorsementQuote: {
            documentsSchemas =
                productVersion.schema.spec.documents.packets.EndorsementQuote
                    ?.documents ?? [];
            readOnly = quote.status !== QuoteStatus.QuotePending;
            preview = quote.status === QuoteStatus.QuotePending;
            break;
        }
        case DocumentPacketTypeV1.RenewalQuote: {
            documentsSchemas =
                productVersion.schema.spec.documents.packets.RenewalQuote
                    ?.documents ?? [];
            readOnly = quote.status !== QuoteStatus.QuotePending;
            preview = quote.status === QuoteStatus.QuotePending;
            break;
        }
        case DocumentPacketTypeV1.Cancellation: {
            documentsSchemas =
                productVersion.schema.spec.documents.packets.Cancellation
                    ?.documents ?? [];
            readOnly = true;
            preview = false;
            break;
        }
        case DocumentPacketTypeV1.Application: {
            documentsSchemas =
                productVersion.schema.spec.documents.packets.Application
                    ?.documents ?? [];
            readOnly = true;
            preview = false;
            break;
        }
    }

    const includedDocumentsSchemas = documentsSchemas.filter(
        (documentSchema) => {
            return isDocumentIncluded(
                documentSchema.code,
                documents,
                includedQuoteDocsCodes
            );
        }
    );

    const documentsAreNotReady =
        typeDocuments.filter(
            (document) =>
                document.status !== DocumentStatus.Ready &&
                document.status !== DocumentStatus.UnmetCondition
        ).length !== 0;

    // get the appropriate documents with/without watermark
    // filter preview docs only if there are no errors, else show everything.
    if (quote.status !== QuoteStatus.Error) {
        typeDocuments = typeDocuments.filter(
            (document) => document.preview === preview
        );
    }
    const manualDocuments = getManualDocumentsFromQuoteData(
        Object.values(quoteCodesAndValuesLookup),
        documentType
    );

    if (
        !typeDocuments ||
        typeDocuments.length === 0 ||
        !includedQuoteDocsCodes
    ) {
        return <></>;
    }

    return (
        <>
            <h2 className="list-plain box">
                <i aria-hidden="true" className="icon-documents" />{" "}
                {documentTypeToTitle(documentType)}
                {preview && " Preview"}
                <FileDownload
                    fileIds={compact(
                        typeDocuments.map((document) => document.file_id)
                    )}
                    downloadName={`${documentType}_documents_${quote.id}`}
                    className={classNames("text-right", {
                        disabled:
                            dirtyFields.length > 0 || documentsAreNotReady,
                    })}
                >
                    <i aria-hidden="true" className="icon-download3" />
                    Download
                </FileDownload>
                {!readOnly && (
                    <NotAnchor
                        onClick={() => {
                            openPopup(`add-${documentType}-documents`);
                        }}
                        className="text-right"
                    >
                        <i aria-hidden="true" className="icon-plus-circle" />
                        Add Document
                    </NotAnchor>
                )}
            </h2>
            <ul className="list-plain box">
                {includedDocumentsSchemas.map((documentSchema, index) => {
                    const foundDocument = getDocumentByCode(
                        documentSchema.code,
                        typeDocuments
                    );
                    return (
                        <DocumentListItem
                            key={documentSchema.code}
                            documentSchema={documentSchema}
                            document={foundDocument}
                            readOnly={readOnly}
                            schemaIndex={index}
                            numOfSchemas={includedDocumentsSchemas.length}
                        />
                    );
                })}
                {manualDocuments.map((documentSchema, index) => {
                    const foundDocument = getDocumentByCode(
                        documentSchema.code,
                        typeDocuments
                    );
                    return (
                        <DocumentListItem
                            key={documentSchema.code}
                            documentSchema={documentSchema}
                            document={foundDocument}
                            readOnly={readOnly}
                            schemaIndex={index}
                            numOfSchemas={includedDocumentsSchemas.length}
                            manual={true}
                        />
                    );
                })}
            </ul>
            {documentType !== "Application" && !readOnly && (
                <p className="link-strong submission-subjectivities">
                    <NotAnchor
                        onClick={() => {
                            openPopup("upload-manu-document", {
                                documentType: documentType,
                                documentsSchemas: documentsSchemas,
                            });
                        }}
                    >
                        <i aria-hidden="true" className="icon-plus-circle" />
                        Upload Manuscript Document
                    </NotAnchor>
                </p>
            )}
        </>
    );
};

export { DocumentsSection };
