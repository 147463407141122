import GettingStartedExtraPane from "components/extra-panes/GettingStartedPane";
import { Page, TabInterface, usePage } from "components/Page";
import { useApi } from "contexts/ApiProvider";
import { FC, useCallback, useEffect, useState } from "react";
import { formatDatetimeString } from "utils/datetime";

import { ProductVersionView } from "@joshuins/builder";
import { z } from "zod";
import { createFormPopup } from "components/Popup";
import {
    MAX_PRODUCT_DESCRIPTION_LENGTH,
    MAX_PRODUCT_NAME_LENGTH,
    PRODUCT_VERSION_TYPES,
} from "./components/util";
import NotAnchor from "components/NotAnchor";
import classNames from "classnames";
import FormControl from "@mui/material/FormControl";
import { Select } from "components/ReactHookFormUncontrolledComponents";
import { MenuItem } from "components/Select";
import ListItemIcon from "@mui/material/ListItemIcon";
import urlJoin from "url-join";
import { BUILDER_PATH } from "globals";
import { useNavigate } from "components/DevAwareRoutingLink";
import { Footer, MainPane } from "components/MainPane";
import ImportProductVersionPopup from "./components/ImportProductPopup";
import { usePopup } from "contexts/PopupProvider";
import { unpaginate } from "components/sdk";
import compact from "lodash/compact";
import { Link } from "react-router-dom";

interface ListProductVersionsPageInterface {
    tabs?: TabInterface[];
}

const newProductSchema = z.object({
    name: z
        .string()
        .min(1, "This field is required")
        .max(
            MAX_PRODUCT_NAME_LENGTH,
            `${MAX_PRODUCT_NAME_LENGTH} character limit`
        ),
    internal_version_name: z
        .string()
        .min(1, "This field is required")
        .max(
            MAX_PRODUCT_NAME_LENGTH,
            `${MAX_PRODUCT_NAME_LENGTH} character limit`
        ),
    description: z
        .string()
        .max(
            MAX_PRODUCT_DESCRIPTION_LENGTH,
            `${MAX_PRODUCT_DESCRIPTION_LENGTH} character limit`
        ),
    product_type: z.string(),
});

type NewProductType = z.infer<typeof newProductSchema>;

const { FormPopup } = createFormPopup(newProductSchema);

const NewProductPopup: FC = () => {
    const { sdkBuilder } = useApi();
    const { refreshState, tryCatchAndRaiseError } = usePage();
    const navigate = useNavigate();

    const onSubmit = useCallback(
        async (data: NewProductType) => {
            tryCatchAndRaiseError(async () => {
                const product = await sdkBuilder.createProduct({ body: {} });
                const schema = await sdkBuilder.newProductSkeleton();
                schema.metadata = { ...data };

                const productVersion = await sdkBuilder.createProductVersion({
                    CreateProductVersion: {
                        New: { product_id: product.id, schema },
                    },
                });
                navigate(
                    urlJoin(
                        "/",
                        BUILDER_PATH,
                        "products",
                        productVersion.id.toString()
                    )
                );
            }, refreshState);
        },
        [tryCatchAndRaiseError, refreshState, sdkBuilder, navigate]
    );

    return (
        <FormPopup
            name="new-product"
            defaultValues={{
                name: "",
                internal_version_name: "",
                description: "",
                product_type: "",
            }}
            onSubmit={onSubmit}
            submitText="Save"
            overlayPopups={[
                <>
                    <h3>Product Name</h3>
                    <p>
                        This is how the product will be listed in stores,
                        quotes, and documents. Keep it as short as possible.
                    </p>
                </>,
                <>
                    <h3>First Internal Version Name</h3>
                    <p>
                        Internal version names are used to note differences
                        between products. For example, if you modify your
                        product you may want to add a version number or date.
                        This field denotes first version name of your new
                        product.
                    </p>
                    <p>
                        The internal version name will display as part of the
                        meta data below the product name in Joshu and is not
                        visible in stores.
                    </p>
                </>,
                <>
                    <h3>Store Description</h3>
                    <p>
                        Enter a short line of text to be displayed with the
                        product name when beginning a submission. For example,
                        for a commercial property product, the one line
                        description might be &quot;Commercial property
                        protection for small business&quot;
                    </p>
                </>,
                <>
                    <h3>Product Type</h3>
                    <p>
                        Optional classification for the product type. Displays
                        an icon to help differentiate products, but doesn&quot;t
                        affect anything else.
                    </p>
                </>,
            ]}
        >
            {({
                openOverlayPopup,
                register,
                control,
                formState: { errors },
            }) => (
                <>
                    <header>
                        <h2>New Product</h2>
                    </header>
                    <p
                        className={classNames("color-black-100", {
                            "has-error": errors.name,
                        })}
                    >
                        <label htmlFor="ift">
                            Product Name
                            <NotAnchor
                                onClick={() => openOverlayPopup(0)}
                                className="text-right"
                                data-overlay="ns1"
                            >
                                <i aria-hidden="true" className="icon-help" />
                                <span className="hidden">More info</span>
                            </NotAnchor>
                        </label>
                        <input
                            id="ift"
                            placeholder='For example, "PropertyShield"...'
                            type="text"
                            {...register("name")}
                        />
                        {errors.name && (
                            <label
                                id="ift-error"
                                className="error"
                                htmlFor="ift"
                            >
                                {errors.name.message}
                            </label>
                        )}
                    </p>
                    <p
                        className={classNames({
                            "has-error": errors.internal_version_name,
                        })}
                    >
                        <label htmlFor="ifi">
                            First Internal Version Name
                            <span className="text-right">
                                {MAX_PRODUCT_NAME_LENGTH} Character Limit
                                <NotAnchor
                                    onClick={() => openOverlayPopup(1)}
                                    className="text-right"
                                    data-overlay="ns2"
                                >
                                    <i
                                        aria-hidden="true"
                                        className="icon-help"
                                    />
                                    <span className="hidden">More info</span>
                                </NotAnchor>
                            </span>
                        </label>
                        <input
                            id="ifi"
                            type="text"
                            placeholder='For example,"1.0," "v1," or "Q1 2021"...'
                            {...register("internal_version_name")}
                        />
                        {errors.internal_version_name && (
                            <label
                                id="ifi-error"
                                className="error"
                                htmlFor="ifi"
                            >
                                {errors.internal_version_name.message}
                            </label>
                        )}
                    </p>
                    <p
                        className={classNames({
                            "has-error": errors.description,
                        })}
                    >
                        <label htmlFor="ifj">
                            Store Description (Optional)
                            <span className="text-right">
                                {MAX_PRODUCT_DESCRIPTION_LENGTH} Character Limit
                                <NotAnchor
                                    onClick={() => openOverlayPopup(2)}
                                    className="text-right"
                                    data-overlay="ns3"
                                >
                                    <i
                                        aria-hidden="true"
                                        className="icon-help"
                                    />
                                    <span className="hidden">More info</span>
                                </NotAnchor>
                            </span>
                        </label>
                        <input
                            id="ifj"
                            type="text"
                            placeholder='For example, "Property protection for small business"...'
                            {...register("description")}
                        />
                    </p>
                    <div>
                        <span className="label">
                            Product Type (Optional)
                            <span className="text-right">
                                <NotAnchor
                                    onClick={() => openOverlayPopup(3)}
                                    className="text-right"
                                    data-overlay="ns4"
                                >
                                    <i
                                        aria-hidden="true"
                                        className="icon-help"
                                    />
                                    <span className="hidden">More info</span>
                                </NotAnchor>
                            </span>
                        </span>
                        <FormControl fullWidth error={!!errors.product_type}>
                            <Select
                                labelId="product-type-select-label"
                                id="ifv"
                                name="product_type"
                                control={control}
                            >
                                {PRODUCT_VERSION_TYPES.map((item) => (
                                    <MenuItem
                                        key={item.value}
                                        value={item.value}
                                    >
                                        <ListItemIcon>
                                            <i
                                                aria-hidden="true"
                                                className={`icon-${item.icon}`}
                                            />
                                        </ListItemIcon>
                                        {item.title}
                                    </MenuItem>
                                ))}
                            </Select>
                            {errors.product_type && (
                                <label className="error" htmlFor="ifv">
                                    {errors.product_type.message}
                                </label>
                            )}
                        </FormControl>
                    </div>
                </>
            )}
        </FormPopup>
    );
};

const ProductCard: FC<{
    product: ProductVersionView;
}> = ({ product }) => {
    return (
        <li>
            <Link
                to={urlJoin(
                    "/",
                    BUILDER_PATH,
                    "products",
                    product.id.toString()
                )}
            >
                <i className={`icon-${product.product_type}`} />
                {product.name}{" "}
            </Link>
            {product.last_updated && (
                <span className="text-right size-14">
                    <span style={{ textAlign: "left" }}>
                        Last Modified{" "}
                        {formatDatetimeString(
                            product.last_updated,
                            "namedMonth"
                        )}
                    </span>
                </span>
            )}
        </li>
    );
};

const ListProductVersionsMain: FC = () => {
    const { sdkBuilder } = useApi();
    const { openPopup } = usePopup();
    const { stateId } = usePage();
    const [productVersions, setProductVersions] =
        useState<ProductVersionView[]>();

    useEffect(() => {
        const getProducts = async () => {
            const products_ = await unpaginate(sdkBuilder.allProducts, {});
            const products = compact(
                products_.map((product) => {
                    const latest_published = product.published;
                    const latest_draft = product.latest_draft;
                    if (!latest_published) {
                        return latest_draft;
                    }
                    if (
                        latest_draft &&
                        latest_draft.last_updated &&
                        latest_published.last_updated &&
                        latest_draft.last_updated >
                            latest_published.last_updated
                    ) {
                        return latest_draft;
                    }
                    return latest_published;
                })
            );
            setProductVersions(products);
        };

        getProducts();
    }, [sdkBuilder, stateId]);

    if (productVersions === undefined) {
        return <></>;
    }

    return (
        <MainPane
            title="Products"
            cta={{
                buttonLabel: "New Product",
                popupName: "new-product",
            }}
            layoutConfig={{
                mainLayout:
                    !productVersions || productVersions.length === 0
                        ? "center"
                        : "wide",
            }}
        >
            <div>
                {productVersions && productVersions.length > 0 ? (
                    <ul className="list-products">
                        {productVersions.map((product) => (
                            <ProductCard
                                key={product.product_id}
                                product={product}
                            />
                        ))}
                    </ul>
                ) : (
                    <div className="module-success inline">
                        <h2>
                            <i aria-hidden="true" className="icon-shield" /> No
                            Products Yet
                        </h2>
                        <p>
                            Click &quot;New Product&quot; above to start a new
                            product.
                        </p>
                    </div>
                )}
            </div>
            <Footer>
                <p className="link-btn has-inline">
                    <NotAnchor
                        className="inline"
                        onClick={() => {
                            openPopup("import-product-version", {
                                productVersions: productVersions,
                            });
                        }}
                    >
                        <i className="icon-upload-cloud" />
                        Import
                    </NotAnchor>
                </p>
            </Footer>
        </MainPane>
    );
};

const ListProductVersionsPage: FC<ListProductVersionsPageInterface> = () => (
    <Page>
        <ListProductVersionsMain />
        <GettingStartedExtraPane />
        <NewProductPopup />
        <ImportProductVersionPopup />
    </Page>
);

export default ListProductVersionsPage;
