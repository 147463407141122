import "./Select.scss";
import {
    Select as OriginalSelect,
    SelectProps as OriginalSelectProps,
} from "components/Select";
import { ComponentProps, InputHTMLAttributes, Ref, useState } from "react";
import {
    Control,
    Controller,
    ControllerProps as ControllerProps_,
    ControllerRenderProps,
    FieldPath,
    FieldValues,
    Path,
} from "react-hook-form";
import { TinyColor } from "@ctrl/tinycolor";
import { ColorPicker as OriginalColorPicker } from "./ColorPicker";
import { MuiColorInputProps } from "mui-color-input";
import classNames from "classnames";
import NotAnchor from "./NotAnchor";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import "./Select.scss";

import GoogleMapsAutocompleteOriginal from "./GoogleMapsAutocomplete";
import SecretInput from "./SecretInput";
import omit from "lodash/omit";
import { NumericFormat } from "react-number-format";
import { InputChanged } from "pages/underwriter/submissions/ApplicationPage";

type ControllerProps<TFieldValues extends FieldValues> = Omit<
    ControllerProps_<TFieldValues>,
    "render"
> & {
    // We want control to be required, and not optional as it is on the original ControllerProps_
    control: Control<TFieldValues>;
};

type SelectProps<T, TFieldValues extends FieldValues> = OriginalSelectProps<T> &
    ControllerProps<TFieldValues>;

const Select: <T, TFieldValues extends FieldValues>(
    // customRef: https://stackoverflow.com/a/58473012
    props: SelectProps<T, TFieldValues> & {
        customRef?: Ref<typeof Select>;
    }
) => JSX.Element = ({ control, name, customRef, ...rest }) => (
    <Controller
        render={({ field }) => {
            // The value={field.value ?? ""} is here because Zod's z.infer creates a type where the value is expected to be undefined
            // if not set to value. However, MUI's <Select> expects an empty string if not set to a value. So this line creates an
            // interface between the two.

            return (
                <OriginalSelect
                    {...rest}
                    {...field}
                    ref={customRef}
                    value={field.value ?? ""}
                />
            );
        }}
        control={control}
        name={name}
    />
);

type ColorPickerInterface<T extends FieldValues> = Omit<
    MuiColorInputProps,
    "value" | "name"
> &
    ControllerProps<T>;

const ColorPicker = <T extends FieldValues>({
    control,
    name,
    ...rest
}: ColorPickerInterface<T>) => (
    <Controller
        name={name}
        control={control}
        render={({ field, fieldState }) => {
            return (
                <OriginalColorPicker
                    {...rest}
                    {...omit(field, "ref")}
                    value={new TinyColor(field.value).toHexString()}
                    format="hex"
                    isAlphaHidden
                    helperText={fieldState.invalid ? "Color is invalid" : ""}
                    error={fieldState.invalid}
                />
            );
        }}
    />
);

interface FormulaSuggestionInputProps {
    id: string;
    suggestions: string[];
}

const customField = <T extends FieldValues>(
    field: ControllerRenderProps<T, Path<T>>,
    hooks: {
        onBlur?: (...event: never[]) => unknown;
        onChange?: (...event: never[]) => unknown;
    }
): ControllerRenderProps<FieldValues, FieldPath<FieldValues>> => {
    const { onChange, onBlur, ref, name, value } = field;

    let newOnChange: typeof onChange;
    const hooksOnChange = hooks.onChange;
    if (hooksOnChange) {
        newOnChange = async (...rest) => {
            const onChangeReturn = await onChange(...rest);
            await hooksOnChange(...(rest as never[]));
            return onChangeReturn;
        };
    } else {
        newOnChange = onChange;
    }

    let newOnBlur: typeof onBlur;
    const hooksOnBlur = hooks.onBlur;
    if (hooksOnBlur) {
        newOnBlur = async (...rest) => {
            const onBlurReturn = await onBlur(...rest);
            await hooksOnBlur(...rest);
            return onBlurReturn;
        };
    } else {
        newOnBlur = onBlur;
    }

    return {
        onChange: newOnChange,
        onBlur: newOnBlur,
        ref,
        name,
        value,
    };
};

const FormulaSuggestionInput = <T extends FieldValues>({
    control,
    name,
    id,
    suggestions,
}: FormulaSuggestionInputProps & ControllerProps<T>) => {
    const [inputFocused, setInputFocused] = useState(false);

    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => {
                const field_ = customField(field, {
                    onBlur: () => {
                        // Without the setTimeout, this onBlur makes the NotAnchors hidden before their
                        // onClicks can even happen. So we add the setTimeout to give a chance to the
                        //onClicks to happen before the NotAnchors are hidden.
                        setTimeout(() => {
                            setInputFocused(false);
                        }, 100);
                    },
                });

                return (
                    <span
                        className={classNames("input-autocomplete", {
                            focus: inputFocused && field_.value.length === 0,
                        })}
                    >
                        <input
                            {...field_}
                            onFocus={() => {
                                setInputFocused(true);
                            }}
                            type="text"
                            id={id}
                            autoComplete="off"
                        />
                        <span>
                            <span>Common Formulas</span>
                            {suggestions.map((formula) => (
                                <NotAnchor
                                    key={formula}
                                    onClick={() => {
                                        field_.onChange(formula);
                                    }}
                                >
                                    {formula}
                                </NotAnchor>
                            ))}
                        </span>
                    </span>
                );
            }}
        />
    );
};

const InputWithOnBlur = <T extends FieldValues>({
    control,
    name,
    id,
    onBlur,
}: ControllerProps<T> & InputHTMLAttributes<HTMLInputElement>) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => {
                const field_ = customField(field, {
                    onBlur,
                });

                return (
                    <input {...field_} type="text" id={id} autoComplete="off" />
                );
            }}
        />
    );
};

interface SecretInputProps {
    id: string;
    showStarsWhenBlurred: boolean;
}

const SecretInput_ = <T extends FieldValues>({
    control,
    name,
    id,
    showStarsWhenBlurred,
}: SecretInputProps & ControllerProps<T>) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => (
                <SecretInput
                    // Make things work without all the tedious mucking about with refs.
                    {...omit(field, ["ref"])}
                    id={id}
                    showStarsWhenBlurred={showStarsWhenBlurred}
                />
            )}
        />
    );
};

const DatePicker = <T extends FieldValues>({
    control,
    name,
    value = null,
    minDate = null,
    maxDate = null,
    disabled = false,
}: {
    value?: Date | null;
    minDate?: Date | null;
    maxDate?: Date | null;
    disabled?: boolean;
} & ControllerProps<T>) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => {
                return (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <MuiDatePicker
                            {...field}
                            maxDate={maxDate}
                            minDate={minDate}
                            disabled={disabled}
                            value={value}
                            slotProps={{
                                inputAdornment: {
                                    position: "start",
                                },
                            }}
                        />
                    </LocalizationProvider>
                );
            }}
        />
    );
};

const GoogleMapsAutocomplete = <TFieldValues extends FieldValues>({
    id,
    control,
    name,
    onChangeHook,
    disabled = false,
}: {
    id: string;
    onChangeHook?: (...event: never[]) => unknown;
} & ControllerProps<TFieldValues>) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => {
                const field_ = customField(field, {
                    onChange: onChangeHook,
                });

                return (
                    <GoogleMapsAutocompleteOriginal
                        id={id}
                        value={field_.value}
                        onChange={field_.onChange}
                        disabled={disabled}
                    />
                );
            }}
        />
    );
};

const ApplicationNumericInput = ({
    id,
    inputChanged,
    fieldName,
    assetIndex,
    ...rest
}: {
    id?: string;
    inputChanged: InputChanged;
    fieldName: string;
    assetIndex: number | undefined;
} & ComponentProps<typeof NumericFormat>) => {
    const [value, setValue] = useState<string>();

    return (
        <NumericFormat
            id={id}
            onValueChange={(values) => {
                setValue(values.value);
            }}
            onBlur={() => {
                inputChanged(value ?? "", fieldName, assetIndex);
            }}
            {...rest}
        />
    );
};

export {
    customField,
    Select as Select,
    ColorPicker,
    FormulaSuggestionInput,
    DatePicker,
    GoogleMapsAutocomplete,
    SecretInput_ as SecretInput,
    InputWithOnBlur,
    ApplicationNumericInput,
};

export type { ControllerProps };
