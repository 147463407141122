import { DocumentPacketTypeV1, DocumentSchemaV1 } from "@joshuins/builder";
import { TextField } from "@mui/material";
import { usePage } from "components/Page";
import { ConfirmationPopup } from "components/Popup";
import { LexoRank } from "lexorank";
import { keyBy, compact } from "lodash";
import { FC, useState, useCallback } from "react";
import { wrapAsJoValue } from "utils/jo-types-and-values";
import sortByLexorank from "utils/lexorank";
import { useInsuranceProcess } from "../InsuranceProcessProvider";
import {
    getDocumentSchemaByPartCode,
    getIncludedQuoteDocsCodes,
    getManualPartsFromQuoteData,
} from "./util";
import MuiAutocomplete from "@mui/material/Autocomplete";
import { QuoteCodeAndValue } from "@joshuins/insurance";

const AddAllDocumentTypesPopup: FC = () => {
    return Object.values(DocumentPacketTypeV1).map((documentType) => (
        <AddDocumentPopup
            documentType={documentType}
            popupName={`add-${documentType}-documents`}
            key={`add-documents-${documentType}`}
        />
    ));
};

const AddDocumentPopup: FC<{
    documentType: DocumentPacketTypeV1;
    popupName: string;
}> = ({ documentType, popupName }) => {
    const { tryCatchAndRaiseError } = usePage();
    const {
        itemGetters: { getInsuranceProcess },
        insuranceProcessDispatch,
    } = useInsuranceProcess();
    const { productVersion, quoteCodesAndValues } = getInsuranceProcess();
    const packets = productVersion.schema.spec.documents.packets;
    const quoteCodesAndValuesLookup = keyBy(quoteCodesAndValues, "code");
    const includedQuoteDocsCodes = getIncludedQuoteDocsCodes(
        Object.values(quoteCodesAndValuesLookup)
    );
    const [selectedpartCode, setSelectedPartCode] = useState<string>();

    let packetDocuments: DocumentSchemaV1[] = [];
    switch (documentType) {
        case DocumentPacketTypeV1.NewQuote: {
            packetDocuments = packets.NewQuote?.documents ?? [];
            break;
        }
        case DocumentPacketTypeV1.Binder: {
            packetDocuments = packets.Binder?.documents ?? [];
            break;
        }
        case DocumentPacketTypeV1.Policy: {
            packetDocuments = packets.Policy?.documents ?? [];
            break;
        }
        case DocumentPacketTypeV1.EndorsementQuote: {
            packetDocuments = packets.EndorsementQuote?.documents ?? [];
            break;
        }
        case DocumentPacketTypeV1.RenewalQuote: {
            packetDocuments = packets.RenewalQuote?.documents ?? [];
            break;
        }
        case DocumentPacketTypeV1.Cancellation: {
            packetDocuments = packets.Cancellation?.documents ?? [];
            break;
        }
    }

    const documentsWithParts = packetDocuments.filter(
        (document) => document.parts.length > 0
    );
    const partsList = compact(
        documentsWithParts.flatMap((document) =>
            document.parts.map((part) => {
                if (
                    !includedQuoteDocsCodes.includes(part.code) &&
                    ("Manual" in part.include ||
                        ("Always" in part.include &&
                            part.include.Always.removable) ||
                        ("Condition" in part.include &&
                            part.include.Condition.removable))
                ) {
                    return part;
                }
            })
        )
    );

    const onSubmit = useCallback(async () => {
        tryCatchAndRaiseError(async () => {
            if (!selectedpartCode) {
                return;
            }
            const addedDocumentSchema = getDocumentSchemaByPartCode(
                selectedpartCode,
                documentsWithParts
            );
            let updatedQuoteDocs: QuoteCodeAndValue[] = [];

            if (addedDocumentSchema) {
                // figure out in what rank we should use if a manual part allready beeb added between two schema parts.
                // we look for the part document IN SCHEMA that is after the selected part, and put
                // the rank between it, and after whatever comes before, if its a schema part or manual.
                const manualParts = getManualPartsFromQuoteData(
                    Object.values(quoteCodesAndValuesLookup),
                    addedDocumentSchema.code
                );
                const includedSchemaParts = addedDocumentSchema.parts.filter(
                    (docPart) => includedQuoteDocsCodes.includes(docPart.code)
                );
                const allSchemaParts = addedDocumentSchema.parts;

                const allCurrentIncludedPartsFromSchema =
                    includedSchemaParts.concat(manualParts);
                const allCurrentIncludedPartsCodes =
                    allCurrentIncludedPartsFromSchema.map((item) => item.code);
                const partsQuoteCodesAndValues = quoteCodesAndValues.filter(
                    (item) =>
                        item.code.split(".")[0] === "doc" &&
                        allCurrentIncludedPartsCodes.includes(item.code)
                );

                if (allCurrentIncludedPartsFromSchema.length === 0) {
                    // first added part, arbitrary rank is ok
                    updatedQuoteDocs = [
                        // parent documents need to set to true as well
                        {
                            code: addedDocumentSchema.code,
                            user_value: wrapAsJoValue({ Boolean: true }),
                            rater_value: wrapAsJoValue({ Boolean: true }),
                            rank: LexoRank.middle().toString(),
                        },
                        {
                            code: selectedpartCode,
                            user_value: wrapAsJoValue({ Boolean: true }),
                            rater_value: wrapAsJoValue({ Boolean: true }),
                            rank: LexoRank.middle().toString(),
                        },
                    ];
                } else {
                    // allPartsSortedByRankes is a rank-sorted array of all (manual + schema) parts of the schema document.
                    const allPartsSortedByRankes = sortByLexorank([
                        ...partsQuoteCodesAndValues,
                    ]);
                    // find where is the part located in schema
                    const selectedPartCodeSchemaIndex =
                        allSchemaParts.findIndex(
                            (obj) => obj.code === selectedpartCode
                        );
                    // get an array of all parts codes that are located AFTER the part we want to add
                    const afterPartCodes = allSchemaParts
                        .slice(selectedPartCodeSchemaIndex + 1)
                        .map((item) => item.code);
                    let afterRank: string | undefined = undefined;
                    let beforeRank: string | undefined = undefined;
                    let newRank: LexoRank | undefined = undefined;
                    // iterate on all parts with respect to order, if we reach to one of the schema parts
                    // that comes after our part, (afterPartCodes), take the current and the previous rank.
                    for (const [
                        index,
                        item,
                    ] of allPartsSortedByRankes.entries()) {
                        if (afterPartCodes.includes(item.code)) {
                            afterRank = allPartsSortedByRankes[index - 1]
                                ? allPartsSortedByRankes[index - 1].rank
                                : undefined;
                            beforeRank = allPartsSortedByRankes[index]
                                ? allPartsSortedByRankes[index].rank
                                : undefined;
                            break;
                        }
                    }

                    if (afterRank && beforeRank) {
                        // if its in the middle
                        newRank = LexoRank.parse(beforeRank).between(
                            LexoRank.parse(afterRank)
                        );
                    } else if (!afterRank && beforeRank) {
                        // if its the first element
                        newRank = LexoRank.parse(beforeRank).genPrev();
                    } else {
                        // if its the last element (no beforeParts)
                        newRank = LexoRank.parse(
                            allPartsSortedByRankes.slice(-1)[0].rank
                        ).genNext();
                    }

                    updatedQuoteDocs = [
                        {
                            code: selectedpartCode,
                            user_value: wrapAsJoValue({ Boolean: true }),
                            rater_value: wrapAsJoValue({ Boolean: true }),
                            rank: newRank.toString(),
                        },
                    ];
                }

                insuranceProcessDispatch({
                    action: "SetDirtyFields",
                    dirtyFields: updatedQuoteDocs,
                });
                insuranceProcessDispatch({
                    action: "UpdateQuoteCodesAndValues",
                    quoteCodesAndValues: updatedQuoteDocs,
                });
            }
        });
    }, [
        documentsWithParts,
        includedQuoteDocsCodes,
        insuranceProcessDispatch,
        quoteCodesAndValues,
        quoteCodesAndValuesLookup,
        selectedpartCode,
        tryCatchAndRaiseError,
    ]);

    if (partsList.length === 0) {
        return (
            <ConfirmationPopup
                name={popupName}
                onSubmit={() => {}}
                submitText="Ok"
                mobileSubmitText="Ok"
            >
                <header>
                    <h2>No Documents To Add</h2>
                    <p>All documents are included in the quote.</p>
                </header>
            </ConfirmationPopup>
        );
    } else {
        return (
            <ConfirmationPopup
                name={popupName}
                onSubmit={onSubmit}
                submitText="Save"
                mobileSubmitText="Save"
            >
                <header>
                    <h2>Select Document</h2>
                </header>
                <label>Select Document</label>
                <MuiAutocomplete
                    renderInput={(params) => <TextField {...params} />}
                    getOptionLabel={(part) => `${part.name}`}
                    isOptionEqualToValue={(option, value) =>
                        option.code === value.code
                    }
                    options={partsList}
                    onChange={(event, newValue) => {
                        setSelectedPartCode(newValue?.code);
                    }}
                    className={"styled-select-menu"}
                />
            </ConfirmationPopup>
        );
    }
};

export { AddAllDocumentTypesPopup };
