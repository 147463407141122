/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { FC, useEffect } from "react";
import { BUILDER_PATH } from "globals";
import { usePopup } from "contexts/PopupProvider";
import { AlertCategory, Page, usePage } from "components/Page";
import { ProductVersion, ProductIntegrationV1 } from "@joshuins/builder";
import {
    IntegrationsProvider,
    useIntegrations,
} from "./components/IntegrationsProvider";
import { ConfirmationPopup } from "components/Popup";
import ExtraPane from "components/extra-panes/ExtraPane";
import { Footer } from "components/MainPane";
import MenuPopover from "components/MenuPopover";
import NotAnchor from "components/NotAnchor";
import ProductMainPane from "./components/ProductMainPane";
import kebabCase from "lodash/kebabCase";
import urlJoin from "url-join";
import DeleteProductIntegrationPopup, {
    DELETE_PRODUCT_INTEGRATION,
} from "./components/integrations/DeleteProductIntegrationPopup";
import SelectIntegrationPopup from "./components/integrations/SelectProductIntegration";
import DrcSetupPopup from "./components/integrations/DrcSetupPopup";
import GrasSetupPopup from "./components/integrations/GrasSetupPopup";
import HazardHubSetupPopup from "./components/integrations/HazardHubSetupPopup";
import NoConfigurationPopup from "./components/integrations/NoConifgurationPopup";

const NoIntegrationPopup: FC = () => (
    <ConfirmationPopup name="no-integrations" submitText="Close">
        <header>
            <h2>No Integrations With This Event Yet</h2>
        </header>
    </ConfirmationPopup>
);

const ProductIntegrationRow: FC<{
    productIntegration: ProductIntegrationV1;
    is_published: boolean;
    index: number;
}> = ({ productIntegration, is_published, index }) => {
    const {
        accessors: {
            allIntegrationTypes,
            getIntegration,
            getProductIntegrations,
        },
        mutators: { updateProductIntegration },
    } = useIntegrations();
    const { addAlertMessages } = usePage();
    const { openPopup } = usePopup();

    const integrationTypes = allIntegrationTypes();
    const integration = getIntegration(productIntegration.integration_id);
    const numProductIntegrations = getProductIntegrations()?.length ?? 0;
    const integrationType =
        integration && integrationTypes
            ? integrationTypes[integration.type_]
            : undefined;

    const setProductIntegrationActive = async (enabled: boolean) => {
        if (!productIntegration) {
            return;
        }
        const updatedProductIntegration = { ...productIntegration, enabled };
        await updateProductIntegration(
            productIntegration.integration_id,
            updatedProductIntegration
        );
        addAlertMessages({
            message: `Integration was ${
                enabled ? "activated" : "paused"
            } successfully.`,
            category: AlertCategory.SUCCESS,
        });
    };

    if (!integration || !integrationType) {
        return <></>;
    }

    const activePill = productIntegration.enabled
        ? { color: "apple", text: "CONNECTED" }
        : { color: "wine", text: "PAUSED" };

    return (
        <MenuPopover
            menuItems={[
                {
                    label: "Documentation",
                    key: `${productIntegration.integration_id}-documentation`,
                    icon: "html",
                    linkTo: urlJoin(
                        "/",
                        BUILDER_PATH,
                        "system",
                        "integrations",
                        integration.id.toString()
                    ),
                },
                productIntegration.enabled
                    ? {
                          label: "Pause Integration",
                          key: `${productIntegration.integration_id}-pause-product-integration`,
                          icon: "alert",
                          hasSeparator: true,
                          onClick: () => {
                              setProductIntegrationActive(false);
                          },
                      }
                    : {
                          label: "Activate Integration",
                          key: `${productIntegration.integration_id}-activate-product-integration`,
                          icon: "check",
                          hasSeparator: true,
                          onClick: () => {
                              setProductIntegrationActive(true);
                          },
                      },
                {
                    label: "Delete Integration",
                    key: `${productIntegration.integration_id}-${DELETE_PRODUCT_INTEGRATION}`,
                    icon: "trash",
                    hasSeparator: true,
                    onClick: () => {
                        openPopup(DELETE_PRODUCT_INTEGRATION, {
                            productIntegration,
                            integration,
                        });
                    },
                },
            ]}
            toggleButtonLabel={`Toggle ${integrationType.display_name} ${activePill.text}`}
            style={{
                ...(numProductIntegrations !== undefined && {
                    zIndex: numProductIntegrations - index,
                }),
            }}
        >
            {({ ToggleButton, Menu }) => (
                <>
                    <NotAnchor
                        onClick={() => {
                            openPopup(
                                `${kebabCase(integration.type_)}-setup-form`,
                                {
                                    productIntegration,
                                    integration,
                                    is_published,
                                }
                            );
                        }}
                        className="toggle-sub"
                    >
                        {integrationType.display_name}
                        <span className="text-right">
                            <span
                                className={`scheme-box color-${activePill.color}`}
                            >
                                {activePill.text}
                            </span>
                        </span>
                    </NotAnchor>
                    {ToggleButton}
                    {Menu}
                    <div
                        css={css`
                            margin-top: 0.5em;
                            flex-basis: 100%;
                            color: var(--black_40);
                            font-family: var(--font_content);
                            font-weight: 500;
                            font-size: 0.8em;
                            line-height: 1.5rem;
                        `}
                    ></div>
                </>
            )}
        </MenuPopover>
    );
};

const IntegrationsHelp = () => (
    <ExtraPane>
        <h3>
            <i aria-hidden="true" className="icon-help" /> Integrations
        </h3>
        <p>
            With Joshu, it&apos;s easy to integrate third-party data APIs to
            enrich rating or pre-fill application questions. With powerful
            integrations, insurers can get great data for underwriting with
            fewer application questions, and deliver a much simpler customer
            experience.
        </p>
        <ul className="list-icon b">
            <li>
                <i aria-hidden="true" className="icon-upload-cloud" /> Add
                Integrations{" "}
                <span>
                    Click &quot;Add Integration.&quot; Then choose an
                    integration and fill out the options.
                </span>
            </li>
            <li>
                <i aria-hidden="true" className="icon-xls" /> Update Rater{" "}
                <span>
                    Use the Response Reference Tags in your Excel rater to
                    receive the data from the API. See the Integrations tab in
                    System for examples.
                </span>
            </li>
        </ul>
    </ExtraPane>
);

const Main = () => {
    const { openPopup } = usePopup();
    const {
        accessors: { allIntegrations },
    } = useIntegrations();
    const { element } = usePage();
    const productVersion = element as unknown as ProductVersion | undefined;

    const {
        accessors: { productIntegrations },
        mutators: { setProductIntegrations },
    } = useIntegrations();
    const integrations = allIntegrations();

    useEffect(() => {
        if (!productVersion) {
            return;
        }
        if (!productIntegrations) {
            setProductIntegrations(productVersion.schema.spec.integrations);
        }
    }, [productVersion, productIntegrations, setProductIntegrations]);

    if (!productVersion) {
        return;
    }

    return (
        <ProductMainPane
            title="Integrations"
            layoutConfig={{
                mainLayout:
                    productVersion.schema.spec.integrations.length > 0
                        ? "wide"
                        : "center",
            }}
        >
            {productVersion.schema.spec.integrations.length > 0 ? (
                <ul className="list-drag draggable ui-sortable">
                    {productVersion.schema.spec.integrations.map(
                        (productIntegration, index) => (
                            <ProductIntegrationRow
                                key={productIntegration.integration_id}
                                productIntegration={productIntegration}
                                is_published={productVersion.is_published}
                                index={index}
                            />
                        )
                    )}
                </ul>
            ) : (
                <div className="module-success inline">
                    <h2>
                        <i aria-hidden="true" className="icon-download" />
                        No Integrations Yet
                    </h2>
                </div>
            )}
            {integrations && Object.keys(integrations).length > 0 && (
                <Footer>
                    <p className="link-btn">
                        <NotAnchor
                            onClick={() => {
                                openPopup("select-integration");
                            }}
                        >
                            <i
                                aria-hidden="true"
                                className="icon-plus-circle"
                            />
                            Add Integration
                        </NotAnchor>
                    </p>
                </Footer>
            )}
        </ProductMainPane>
    );
};

const IntegrationsPage: FC = () => (
    <Page>
        <IntegrationsProvider>
            <Main />
            <IntegrationsHelp />
            <SelectIntegrationPopup />
            <NoIntegrationPopup />
            <DeleteProductIntegrationPopup />
            <DrcSetupPopup />
            <GrasSetupPopup />
            <HazardHubSetupPopup />
            <NoConfigurationPopup />
        </IntegrationsProvider>
    </Page>
);

export default IntegrationsPage;
