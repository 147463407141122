import { FC, useCallback, useEffect, useState } from "react";
import { User } from "@joshuins/auth";
import { Insured } from "@joshuins/insurance";
import { MainPane } from "components/MainPane";
import { Page } from "components/Page";
import ExtraPane from "components/extra-panes/ExtraPane";
import { useApi } from "contexts/ApiProvider";
import urlJoin from "url-join";
import {
    useAvailableProductVersions,
    useCreateSubmissionWizard,
} from "./use-create-submission-wizard";
import { fullName } from "utils/user";
import { RoleAwareLink } from "contexts/BrandingProvider";
import { asyncMap } from "modern-async";
import groupBy from "lodash/groupBy";
import keyBy from "lodash/keyBy";
import { formatDatetimeString } from "utils/datetime";
import { PaginationFooter, PaginationSearchInput } from "components/Pagination";

const Main = () => {
    const { sdkInsurance, sdkSystem } = useApi();
    const newSubmissionWizardProceed = useCreateSubmissionWizard();
    const [users, setUsers] = useState<Record<string, User>>({});
    const [insureds, setInsureds] = useState<Insured[]>([]);
    const [perPage, setPerPage] = useState<number>(10);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [query, setQuery] = useState<string | undefined>();
    const availableProducts = useAvailableProductVersions();

    const loadInsureds = useCallback(
        async (_page: number) => {
            const insureds_ = await sdkInsurance.allInsureds({
                _per_page: perPage,
                _page,
                name: query || undefined,
            });
            setInsureds(insureds_.items);
            setTotalPages(insureds_.total_pages);
            setCurrentPage(_page);
        },
        [perPage, query, sdkInsurance]
    );

    useEffect(() => {
        const getInsureds = async () => {
            loadInsureds(currentPage);
        };
        getInsureds();
    }, [currentPage, loadInsureds, sdkInsurance]);

    const setPageSize = useCallback((value: string) => {
        setPerPage(parseInt(value));
        setCurrentPage(1);
    }, []);

    useEffect(() => {
        const getUsers = async () => {
            const insuredsWithUsers = insureds.filter(
                (insured) =>
                    insured.user_id !== undefined && insured.user_id !== null
            );
            const userIds = groupBy(insuredsWithUsers, "user_id");
            const users_ = await asyncMap(
                Object.keys(userIds),
                async (id) => {
                    return await sdkSystem.getUser({ id: parseInt(id) });
                },
                Number.POSITIVE_INFINITY
            );
            setUsers(keyBy(users_, "id"));
        };
        getUsers();
    }, [insureds, sdkSystem]);

    if (insureds === undefined) {
        return <></>;
    }

    return (
        <MainPane
            title="Insureds"
            cta={
                availableProducts
                    ? {
                          buttonLabel: "New Submission",
                          callback: newSubmissionWizardProceed,
                      }
                    : undefined
            }
        >
            <PaginationSearchInput
                placeholder="Find insureds..."
                setCurrentPage={setCurrentPage}
                setQuery={setQuery}
            />
            {insureds.length > 0 ? (
                <ul className="list-strong">
                    {insureds.map((insured) => (
                        <li key={insured.id}>
                            <h2>
                                <i
                                    aria-hidden="true"
                                    className="icon-building"
                                ></i>
                                <RoleAwareLink
                                    to={urlJoin(
                                        "/",
                                        "insureds",
                                        insured.id.toString()
                                    )}
                                >
                                    {insured.name}
                                </RoleAwareLink>
                            </h2>
                            <ul className="list-inline">
                                <li>
                                    {formatDatetimeString(insured.created_at)}
                                </li>
                                {insured.user_id && users[insured.user_id] && (
                                    <li>{fullName(users[insured.user_id])}</li>
                                )}
                            </ul>
                        </li>
                    ))}
                </ul>
            ) : (
                <div
                    className="module-success inline"
                    style={{ paddingTop: "100px" }}
                >
                    <h2>
                        <i className="icon-building" /> No Insureds Yet
                    </h2>
                    <p>
                        Insureds that you add to submissions will be listed
                        here.
                    </p>
                </div>
            )}
            <div style={{ marginBottom: "200px" }}></div>
            {totalPages > 1 && (
                <PaginationFooter
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    totalPages={totalPages}
                    setPageSize={setPageSize}
                />
            )}
        </MainPane>
    );
};

const ListInsuredsPane = () => (
    <ExtraPane>
        <h3>
            <i aria-hidden="true" className="icon-help" />
            Insureds
        </h3>
        <p className="size-14">
            All insureds with your submissions, quotes, and policies. Click a
            company name to see their page with more details.
        </p>
    </ExtraPane>
);

const InsuredsPage: FC = () => (
    <Page>
        <Main />
        <ListInsuredsPane />
    </Page>
);

export default InsuredsPage;
