import { FC, useEffect, useState } from "react";
import { Page } from "components/Page";
import { useApi } from "contexts/ApiProvider";
import ExtraPane from "components/extra-panes/ExtraPane";
import InsuranceObjectList from "pages/underwriter/quotes_and_policies/components/InsuranceObjectList";
import { InsuranceListObject } from "pages/underwriter/quotes_and_policies/util";
import { useAuthUser } from "react-auth-kit";
import type { User } from "@joshuins/system";

const Main: FC = () => {
    const { sdkInsurance } = useApi();
    const [insuranceObjects, setInsuranceObjects] =
        useState<InsuranceListObject[]>();
    const [icon, setIcon] = useState<string>();
    const authUser = useAuthUser();
    const user = authUser() as User;

    useEffect(() => {
        const getInsuranceObjects = async () => {
            const quotes = await sdkInsurance.getAllQuotesWithoutPolicies();
            const icon = "icon-documents";

            setInsuranceObjects(quotes);
            setIcon(icon);
        };
        getInsuranceObjects();
    }, [sdkInsurance, user.id]);

    if (!insuranceObjects) {
        return <></>;
    }

    return (
        <div className="module-tabs-content">
            <InsuranceObjectList
                title="Quotes"
                insuranceObjects={insuranceObjects}
                textWhenEmpty='Click the "New Submission" button to start an application. After the application was approved, quotes will appear here.'
                icon={icon ? icon : "icon-document"}
            />
        </div>
    );
};

const QuotesPane = () => (
    <ExtraPane>
        <h3>
            <i aria-hidden="true" className="icon-help" /> Quotes
        </h3>
        <p>
            Everything look good? Click &quot;Request to Bind&quot; and your
            underwriter will be notified immediately. They will begin the bind
            process and you&apos;ll receive a status update via email.
        </p>
        <p>
            Want to see what your premium would look like with different
            numbers? Choose &quot;Request New Quote&quot; and some areas of the
            quote can be customized and re-submitted for another quote.
        </p>
    </ExtraPane>
);

const StoreQuotesPage: FC = () => (
    <Page>
        <Main />
        <QuotesPane />
    </Page>
);
export default StoreQuotesPage;
