import isNull from "lodash/isNull";
import omitBy from "lodash/omitBy";
import mapValues from "lodash/mapValues";

// These types are taken and modified from here: https://stackoverflow.com/a/68714494
type IsNullableProperty<K extends keyof T, T> = null extends T[K] ? K : never;
type IsNotNullableProperty<K extends keyof T, T> = null extends T[K]
    ? never
    : K;
// The NullToUndefined type takes a type which may have properties that are a union with null
// and replaces these properties with with the same named properties but instead of null
// they are undefined. Any other properties are left untouched. This represents what happens in
// the removeNull method: any values that are null are omitted i.e they become undefined
type NullToUndefined<T> = {
    [K in keyof T as IsNullableProperty<K, T>]?: NonNullable<T[K]>;
} & {
    [K in keyof T as IsNotNullableProperty<K, T>]: T[K];
};

const removeNull = <T extends object>(obj: T): NullToUndefined<T> =>
    omitBy(obj, isNull) as NullToUndefined<T>;

// maybe Concrete isn't the right word if you're pedantic, but it's the best I could come up
// with to describe converting "string | null" to "string"
type NullableToConcreteTypeOnly<T> = {
    [K in keyof T as IsNullableProperty<K, T>]: NonNullable<T[K]>;
} & {
    [K in keyof T as IsNotNullableProperty<K, T>]: T[K];
};

const nullToEmptyString = <T extends object>(
    obj: T
): NullableToConcreteTypeOnly<T> => {
    return mapValues(obj, (value) =>
        isNull(value) ? "" : value
    ) as unknown as NullableToConcreteTypeOnly<T>;
};

const initObjectWithKeys = <T extends string, U>(
    keys: ReadonlyArray<T>,
    initialValue: U
) =>
    keys.reduce(
        (accumulator, key) => {
            accumulator[key] = initialValue;
            return accumulator;
        },
        {} as { [x in (typeof keys)[number]]: U }
    );

export { removeNull, nullToEmptyString, initObjectWithKeys };
