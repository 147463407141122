import { useEffect, useRef, useState } from "react";
import { useApi } from "contexts/ApiProvider";

import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";

import ReCAPTCHA from "react-google-recaptcha";
import { useBranding } from "contexts/BrandingProvider";

import axios from "axios";
import { hideLoader, showLoader } from "paul/native-dom-manipulation";
import SsoLoginButton from "components/SsoLoginButton";
import useLoginProviders from "utils/use-login-providers";
import { getMessageFromAxiosError } from "utils/axios-extras";

const registrationSchema = z.object({
    email: z.string().email(),
});
type RegistrationType = z.infer<typeof registrationSchema>;

const RegistrationPage = () => {
    const { navigate } = useBranding();

    const captchaRef = useRef<ReCAPTCHA>(null);

    const [recaptchaToken, setRecaptchaToken] = useState<
        string | null | undefined
    >(null);
    const [recaptchaKey, setRecaptchaKey] = useState<string | null>(null);

    const [submissionFailedMessage, setSubmissionFailedMessage] =
        useState<string>();

    const { ssoProviders, passwordEnabled, googleEnabled } =
        useLoginProviders();

    const { sdkAuthForProcess } = useApi();
    const { register, handleSubmit } = useForm<RegistrationType>({
        resolver: zodResolver(registrationSchema),
        defaultValues: {
            email: "",
        },
    });

    useEffect(() => {
        const configureRecaptcha = async () => {
            try {
                const { recaptcha_public_key } =
                    await sdkAuthForProcess.registrationParams();
                setRecaptchaKey(recaptcha_public_key);
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    setRecaptchaKey(null);
                } else {
                    throw error;
                }
            }
        };

        configureRecaptcha();
    }, [sdkAuthForProcess, recaptchaToken]);

    const setToken = (token: string | null): void => {
        setRecaptchaToken(token);
    };

    const onSubmit = async (data: RegistrationType) => {
        showLoader();
        const email = data.email;

        let recaptchaResponse = recaptchaToken;
        if (!recaptchaResponse) {
            recaptchaResponse = await captchaRef.current?.executeAsync();
        }

        let errorMessage: string | undefined;

        if (recaptchaResponse) {
            try {
                await sdkAuthForProcess.registrationStart({
                    RegistrationStartRequest: {
                        recaptcha_response: recaptchaResponse,
                        email,
                    },
                });
                navigate("/auth/register-check-email");
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    errorMessage = getMessageFromAxiosError(error);
                } else {
                    throw error;
                }
            }
        } else {
            errorMessage = "Error";
        }

        if (errorMessage) {
            setSubmissionFailedMessage(errorMessage);
            hideLoader();
        }
    };

    return (
        <main id="content">
            <form className="form-aside" onSubmit={handleSubmit(onSubmit)}>
                <header className="m25">
                    <h2>Register</h2>
                    <p>Sign in using one of the options below</p>
                </header>
                {googleEnabled && <SsoLoginButton oidcSlug="google" />}
                {ssoProviders &&
                    ssoProviders.map(({ slug, display_name }) => (
                        <SsoLoginButton
                            key={slug}
                            oidcSlug={slug}
                            displayName={display_name}
                        />
                    ))}
                {passwordEnabled && (
                    <div className="form-overlay">
                        <p>
                            <label htmlFor="fai">Email Address</label>
                            <input
                                {...register("email")}
                                type="email"
                                id="fai"
                                autoComplete="username"
                            />
                        </p>
                        {recaptchaKey && (
                            <ReCAPTCHA
                                sitekey={recaptchaKey}
                                ref={captchaRef}
                                size="invisible"
                                onChange={setToken}
                            />
                        )}
                        <p className="submit">
                            {submissionFailedMessage && (
                                <label id="failed" className="error">
                                    {submissionFailedMessage}
                                </label>
                            )}
                            <button type="submit">Next</button>
                        </p>
                    </div>
                )}
            </form>
        </main>
    );
};

export default RegistrationPage;
