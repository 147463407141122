import { FC, useCallback, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import classNames from "classnames";
import { useApi } from "contexts/ApiProvider";
import { useInsuranceProcess } from "../InsuranceProcessProvider";
import { usePopup } from "contexts/PopupProvider";
import { usePage } from "components/Page";
import NotAnchor from "components/NotAnchor";
import { DatePicker } from "components/ReactHookFormUncontrolledComponents";
import { ConfirmationPopup, Popup, createFormPopup } from "components/Popup";
import { MenuItem as SelectMenuItem } from "components/Select";
import { Select } from "components/ReactHookFormUncontrolledComponents";
import {
    CANCEL_POLICY_REASONS,
    waitForProcessingInsuranceObject,
} from "../../util";
import { z } from "zod";
import { unpaginate } from "components/sdk";
import { useNavigate } from "components/DevAwareRoutingLink";
import urlJoin from "url-join";
import {
    getRawValueAsDateFromJoValue,
    getRawValueAsStringFromJoValue,
    getUserOrRaterValue,
} from "utils/jo-types-and-values";
import { useBranding } from "contexts/BrandingProvider";
import { DATE_FORMAT_STRINGS, formatDate } from "utils/datetime";
import FormControl from "@mui/material/FormControl";
import { QuoteStatus } from "@joshuins/insurance";
import keyBy from "lodash/keyBy";
import { format } from "date-fns";

const MANAGE_POLICY = "manage-policy" as const;
const MANAGE_POLICY_STORE = "manage-policy-store" as const;

const ManagePolicyStoreLink: FC = () => {
    const { openPopup } = usePopup();
    return (
        <NotAnchor
            onClick={() => {
                openPopup(MANAGE_POLICY_STORE);
            }}
            className="inline"
        >
            <i className="icon-pencil" /> Manage{" "}
            <span className="mobile-hide">Policy</span>
        </NotAnchor>
    );
};

const ManagePolicyStorePopup: FC = () => {
    return (
        <ConfirmationPopup name={MANAGE_POLICY_STORE} submitText="Close">
            <header>
                <h2 className="m25 color-primary">Manage Policy</h2>
                <div>Please contact your underwriter</div>
            </header>
        </ConfirmationPopup>
    );
};

const ManagePolicyLink: FC = () => {
    const { openPopup } = usePopup();
    return (
        <NotAnchor
            onClick={() => {
                openPopup(MANAGE_POLICY);
            }}
            className="inline"
        >
            <i className="icon-pencil" /> Manage{" "}
            <span className="mobile-hide">Policy</span>
        </NotAnchor>
    );
};

const createManagePolicySchema = z.object({
    managePolicyAction: z
        .object({
            cancelOrCreate: z
                .enum(["cancel", "create", ""], {
                    errorMap: () => ({ message: "Please make a selection" }),
                })
                .refine((value) => value !== "", {
                    message: "Please make a selection",
                }),
            iUnderstand: z.union([z.boolean(), z.undefined()]),
            reason: z.string(),
            cancellation_type: z.enum(["flat", "manual"], {
                errorMap: () => ({
                    message: "Please choose a cancellation type",
                }),
            }),
            additional_details: z.string().optional(),
        })
        .refine(
            (value) => value.cancelOrCreate === "create" || value.iUnderstand,
            {
                message: "Please acknowledge coverage cancelation",
                path: ["iUnderstand"],
            }
        )
        .refine(
            (value) =>
                value.cancelOrCreate === "create" || value.reason.length > 0,
            {
                message: "This field is required",
                path: ["reason"],
            }
        ),
    cancellationEffectiveDate: z.date().optional(),
    endorsementEffectiveDate: z.date().optional(),

    premium: z.object({
        value: z.string().min(1, "This field is required"),
        quotePremium: z.number(),
    }),
});

type ManagePolicyType = z.infer<typeof createManagePolicySchema>;
const { FormPopup: CreateManagePolicyFormPopup } = createFormPopup(
    createManagePolicySchema
);

const ManagePolicyPopup: FC = () => {
    const { sdkInsurance } = useApi();
    const { tryCatchAndRaiseError } = usePage();
    const { isPopupOpen } = usePopup();
    const {
        itemGetters: { getInsuranceProcess },
    } = useInsuranceProcess();
    const { quote: policy, quoteCodesAndValues } = getInsuranceProcess();
    const navigate = useNavigate();
    const { generateUrl } = useBranding();
    const [quoteEffectiveDate, setQuoteEffectiveDate] = useState<Date>();
    const [quoteExpirationDate, setQuoteExpirationDate] = useState<Date>();
    const [quotePremium, setQuotePremium] = useState<number>();
    const [premium, setPremium] = useState<number>();
    const [retainedPremium, setRetainedPremium] = useState<number>(0);
    const [manualPremium, setManualPremium] = useState<number>(0);

    useEffect(() => {
        if (!quoteCodesAndValues || !isPopupOpen(MANAGE_POLICY)) {
            return;
        }
        const quoteCodesAndValuesByCode = keyBy(quoteCodesAndValues, "code");
        let effectiveDate = undefined;
        let premium_ = undefined;
        if (quoteCodesAndValuesByCode["prm.effective_date"]) {
            effectiveDate = getRawValueAsDateFromJoValue(
                getUserOrRaterValue(
                    quoteCodesAndValuesByCode["prm.effective_date"]
                )
            );
            setQuoteEffectiveDate(effectiveDate);
        }
        if (quoteCodesAndValuesByCode["prm.expiration_date"]) {
            setQuoteExpirationDate(
                getRawValueAsDateFromJoValue(
                    getUserOrRaterValue(
                        quoteCodesAndValuesByCode["prm.expiration_date"]
                    )
                )
            );
        }
        if (quoteCodesAndValuesByCode["prm.premium"]) {
            premium_ = parseFloat(
                getRawValueAsStringFromJoValue(
                    getUserOrRaterValue(
                        quoteCodesAndValuesByCode["prm.premium"]
                    )
                )
            );
            setQuotePremium(premium_);
            setPremium(premium_);
            setManualPremium(premium_);
            setRetainedPremium(0);
        }
    }, [isPopupOpen, quoteCodesAndValues]);

    const onSubmit = useCallback(
        async (data: ManagePolicyType) => {
            if (data.managePolicyAction.cancelOrCreate === "create") {
                const newEffectiveDate = data.endorsementEffectiveDate;
                if (!newEffectiveDate) {
                    return;
                }
                tryCatchAndRaiseError(async () => {
                    const newSubmission = await sdkInsurance.createSubmission({
                        CreateSubmission: {
                            Endorsement: {
                                quote_id: policy.id,
                                effective_date: format(
                                    newEffectiveDate,
                                    DATE_FORMAT_STRINGS["apiDate"]
                                ),
                            },
                        },
                    });
                    const newEndorsementQuote = (
                        await unpaginate(sdkInsurance.allQuotes, {
                            submission_id: newSubmission.id,
                        })
                    )[0];
                    await waitForProcessingInsuranceObject(
                        sdkInsurance,
                        newEndorsementQuote,
                        "Creating Endorsement..."
                    );
                    if (newEndorsementQuote) {
                        navigate(
                            generateUrl(
                                urlJoin(
                                    "/",
                                    "quotes",
                                    newEndorsementQuote.id.toString()
                                )
                            )
                        );
                    }
                });
            } else if (data.managePolicyAction.cancelOrCreate === "cancel") {
                const newEffectiveDate =
                    data.managePolicyAction.cancellation_type === "flat"
                        ? quoteEffectiveDate
                        : data.cancellationEffectiveDate;
                if (!newEffectiveDate) {
                    return;
                }
                tryCatchAndRaiseError(async () => {
                    const newSubmission = await sdkInsurance.createSubmission({
                        CreateSubmission: {
                            Cancellation: {
                                quote_id: policy.id,
                            },
                        },
                    });
                    const newCancellationQuote = (
                        await unpaginate(sdkInsurance.allQuotes, {
                            submission_id: newSubmission.id,
                        })
                    )[0];
                    await waitForProcessingInsuranceObject(
                        sdkInsurance,
                        newCancellationQuote,
                        "Canceling..."
                    );
                    await sdkInsurance.updateQuoteData({
                        id: newCancellationQuote.id,
                        CreateQuoteData: {
                            data: [
                                {
                                    code: "segment.expiration_date",
                                    user_value: {
                                        V1: {
                                            Plain: {
                                                Date: format(
                                                    newEffectiveDate,
                                                    DATE_FORMAT_STRINGS[
                                                        "apiDate"
                                                    ]
                                                ),
                                            },
                                        },
                                    },
                                },
                                {
                                    code: "prm.premium",
                                    user_value: {
                                        V1: {
                                            Plain: {
                                                Monetary: {
                                                    currency: "USD",
                                                    amount: retainedPremium.toString(),
                                                },
                                            },
                                        },
                                    },
                                },
                            ],
                        },
                    });

                    await sdkInsurance.updateQuote({
                        id: newCancellationQuote.id,
                        UpdateQuote: {
                            status: QuoteStatus.CoverageActive,
                            reason: data.managePolicyAction.reason,
                            additional_details:
                                data.managePolicyAction.additional_details,
                        },
                    });
                    navigate(
                        generateUrl(
                            urlJoin(
                                "/",
                                "quotes",
                                newCancellationQuote.id.toString()
                            )
                        )
                    );
                });
            }
        },
        [
            generateUrl,
            navigate,
            policy.id,
            quoteEffectiveDate,
            retainedPremium,
            sdkInsurance,
            tryCatchAndRaiseError,
        ]
    );

    if (!premium) {
        return (
            <Popup name={MANAGE_POLICY}>
                <header
                    style={{
                        borderBottom: "1px solid var(--black_10)",
                        paddingBottom: "8px ",
                    }}
                >
                    <h2>Manage Policy</h2>
                    <p>
                        This policy cannot be changed, please contact your
                        administrator.
                    </p>
                </header>
            </Popup>
        );
    }

    return (
        <CreateManagePolicyFormPopup
            name={MANAGE_POLICY}
            defaultValues={{
                cancellationEffectiveDate: quoteEffectiveDate,
                endorsementEffectiveDate: quoteEffectiveDate,
                premium: { value: premium?.toString(), quotePremium },
                managePolicyAction: {
                    cancelOrCreate: "",
                    iUnderstand: false,
                    reason: "",
                },
            }}
            onSubmit={onSubmit}
            submitText="Continue"
        >
            {({ control, register, watch, formState: { errors } }) => (
                <>
                    <div
                        className={classNames({
                            "has-error": errors.managePolicyAction?.reason,
                            hidden:
                                watch("managePolicyAction.cancelOrCreate") ===
                                "cancel",
                        })}
                    >
                        <header>
                            <h2>Manage Policy</h2>
                        </header>

                        <ul className="check strong">
                            <li>
                                <input
                                    {...register(
                                        "managePolicyAction.cancelOrCreate"
                                    )}
                                    type="radio"
                                    id="faf"
                                    value="create"
                                />{" "}
                                <label htmlFor="faf">
                                    Change Policy{" "}
                                    <span>
                                        Coverage, limits, retention, and dates{" "}
                                    </span>
                                </label>
                            </li>
                            <li>
                                <input
                                    {...register(
                                        "managePolicyAction.cancelOrCreate"
                                    )}
                                    type="radio"
                                    id="fae"
                                    value="cancel"
                                />{" "}
                                <label htmlFor="fae">
                                    Cancel Policy{" "}
                                    <span>Cancel an active policy</span>
                                </label>
                            </li>
                        </ul>
                    </div>
                    <div
                        className={classNames("div-as-p", {
                            hidden:
                                watch("managePolicyAction.cancelOrCreate") !==
                                "create",
                        })}
                    >
                        <span className="label">Effective Date</span>
                        <span className="input">
                            <FormControl fullWidth>
                                <DatePicker
                                    control={control}
                                    name="endorsementEffectiveDate"
                                    value={quoteEffectiveDate}
                                    minDate={quoteEffectiveDate}
                                    maxDate={quoteExpirationDate}
                                />
                            </FormControl>
                        </span>
                        {errors.endorsementEffectiveDate && (
                            <label
                                id="effective-date-input-error"
                                className="error"
                                htmlFor="effective-date-input"
                            >
                                {errors.endorsementEffectiveDate.message}
                            </label>
                        )}
                    </div>
                    <div
                        className={classNames("m25", {
                            "has-error": errors.managePolicyAction?.reason,
                            hidden:
                                watch("managePolicyAction.cancelOrCreate") !==
                                "cancel",
                        })}
                    >
                        {" "}
                        <header>
                            <h2>Cancel Policy</h2>
                        </header>
                        <ul className="check strong">
                            <li>
                                <input
                                    {...register(
                                        "managePolicyAction.cancellation_type"
                                    )}
                                    type="radio"
                                    id="flatcancel"
                                    value="flat"
                                    defaultChecked={true}
                                    onClick={() => {
                                        if (quotePremium) {
                                            setRetainedPremium(0);
                                        }
                                    }}
                                />{" "}
                                <label htmlFor="flatcancel">
                                    Flat Cancellation{" "}
                                    <span>
                                        Add text about flat cancellation
                                    </span>{" "}
                                </label>
                            </li>
                            <li>
                                <input
                                    {...register(
                                        "managePolicyAction.cancellation_type"
                                    )}
                                    type="radio"
                                    id="manualcancel"
                                    value="manual"
                                    onClick={() => {
                                        if (quotePremium) {
                                            setRetainedPremium(
                                                quotePremium - manualPremium
                                            );
                                        }
                                    }}
                                />{" "}
                                <label htmlFor="manualcancel">
                                    Manual Cancellation{" "}
                                    <span>
                                        Add text about manual cancellation
                                    </span>
                                </label>
                            </li>
                        </ul>
                        <div className="cols">
                            <p
                                className={classNames("c50", {
                                    hidden:
                                        watch(
                                            "managePolicyAction.cancellation_type"
                                        ) === "manual",
                                })}
                            >
                                <span className="label">Return Premium</span>
                                <NumericFormat
                                    style={{
                                        background: "var(--sand_40)",
                                        border: "none",
                                    }}
                                    type="text"
                                    disabled={true}
                                    value={quotePremium}
                                    thousandSeparator=","
                                    allowLeadingZeros={false}
                                    decimalScale={2}
                                    prefix="$ "
                                />
                            </p>
                            <p
                                className={classNames("c50", {
                                    hidden:
                                        watch(
                                            "managePolicyAction.cancellation_type"
                                        ) !== "manual",
                                })}
                            >
                                <span className="label">Return Premium</span>
                                <Controller
                                    name="premium.value"
                                    control={control}
                                    render={({ field }) => (
                                        <NumericFormat
                                            {...field}
                                            defaultValue={premium?.toString()}
                                            displayType="input"
                                            thousandSeparator=","
                                            allowLeadingZeros={false}
                                            decimalScale={2}
                                            prefix="$ "
                                            disabled={
                                                watch(
                                                    "managePolicyAction.cancellation_type"
                                                ) !== "manual"
                                            }
                                            onValueChange={(values) => {
                                                if (
                                                    premium &&
                                                    values.floatValue !==
                                                        undefined
                                                ) {
                                                    field.onChange(
                                                        values.value
                                                    );
                                                    setManualPremium(
                                                        values.floatValue
                                                    );
                                                    setRetainedPremium(
                                                        premium -
                                                            values.floatValue
                                                    );
                                                }
                                            }}
                                            onChange={() => {
                                                // IMPORTANT override
                                            }}
                                        />
                                    )}
                                />
                                {errors.premium && (
                                    <label
                                        id="premium-error"
                                        className="error"
                                        htmlFor="managePolicyAction.premium"
                                    >
                                        {errors.premium.value?.message}
                                    </label>
                                )}
                            </p>
                            <p className="c50">
                                <span className="label">Retained Premium</span>
                                <NumericFormat
                                    style={{
                                        background: "var(--sand_40)",
                                        border: "none",
                                    }}
                                    type="text"
                                    disabled={true}
                                    value={retainedPremium}
                                    thousandSeparator=","
                                    allowLeadingZeros={false}
                                    decimalScale={2}
                                    prefix="$ "
                                />
                                {retainedPremium < 0 && (
                                    <label className="error">
                                        This will create a negative balance
                                    </label>
                                )}
                            </p>
                        </div>
                        <div
                            className={classNames("div-as-p", {
                                hidden:
                                    watch(
                                        "managePolicyAction.cancellation_type"
                                    ) === "manual",
                            })}
                        >
                            <span className="label">Effective Date</span>
                            {quoteEffectiveDate && (
                                <span>{formatDate(quoteEffectiveDate)}</span>
                            )}
                        </div>
                        <div
                            className={classNames("div-as-p", {
                                hidden:
                                    watch(
                                        "managePolicyAction.cancellation_type"
                                    ) !== "manual",
                            })}
                        >
                            <span className="label">Effective Date</span>
                            <span className="input">
                                <FormControl fullWidth>
                                    <DatePicker
                                        control={control}
                                        disabled={
                                            watch(
                                                "managePolicyAction.cancellation_type"
                                            ) !== "manual"
                                        }
                                        name="cancellationEffectiveDate"
                                        defaultValue={quoteEffectiveDate}
                                        value={quoteEffectiveDate}
                                        minDate={quoteEffectiveDate}
                                        maxDate={quoteExpirationDate}
                                    />
                                </FormControl>
                            </span>
                            {errors.cancellationEffectiveDate && (
                                <label
                                    id="effective-date-input-error"
                                    className="error"
                                    htmlFor="effective-date-input"
                                >
                                    {errors.cancellationEffectiveDate.message}
                                </label>
                            )}
                        </div>
                        <span className="label">Reason for Cancellation</span>
                        <Select
                            id="cancel-reason"
                            name="managePolicyAction.reason"
                            placeholder="Select reason..."
                            control={control}
                            fullWidth
                        >
                            {CANCEL_POLICY_REASONS.map((item, index) => (
                                <SelectMenuItem key={index} value={item.value}>
                                    {item.value}
                                </SelectMenuItem>
                            ))}
                        </Select>
                        {errors.managePolicyAction?.reason && (
                            <label
                                id="cancel-reason-error"
                                className={classNames("error", {
                                    hidden:
                                        watch(
                                            "managePolicyAction.cancelOrCreate"
                                        ) !== "cancel",
                                })}
                                htmlFor="cancel-error"
                            >
                                {errors.managePolicyAction?.reason.message}
                            </label>
                        )}
                    </div>
                    <div
                        className={classNames("cols m15", {
                            hidden:
                                watch("managePolicyAction.cancelOrCreate") !==
                                "cancel",
                        })}
                    >
                        <label htmlFor="faican">
                            Additional Details (optional)
                        </label>
                        <textarea
                            {...register(
                                "managePolicyAction.additional_details"
                            )}
                            id="faican"
                        />
                    </div>
                    <p
                        className={classNames("check box", {
                            hidden:
                                watch("managePolicyAction.cancelOrCreate") !==
                                "cancel",
                        })}
                    >
                        <input
                            type="checkbox"
                            id="confirm-cancel-policy"
                            {...register("managePolicyAction.iUnderstand")}
                        />
                        <label htmlFor="confirm-cancel-policy">
                            I understand this will cancel coverage.{" "}
                        </label>
                    </p>
                    {errors.managePolicyAction?.iUnderstand && (
                        <p
                            className={classNames({
                                hidden:
                                    watch(
                                        "managePolicyAction.cancelOrCreate"
                                    ) !== "cancel",
                            })}
                        >
                            <label
                                id="i-understood-message"
                                className="error"
                                htmlFor="confirm-cancel-policy"
                            >
                                {errors.managePolicyAction?.iUnderstand.message}
                            </label>
                        </p>
                    )}
                </>
            )}
        </CreateManagePolicyFormPopup>
    );
};

export {
    ManagePolicyLink,
    ManagePolicyPopup,
    ManagePolicyStoreLink,
    ManagePolicyStorePopup,
};
