import { FC, useCallback, useEffect } from "react";
import {
    NewReportTemplate,
    ReportTemplate,
    UpdateReportTemplate,
    User,
} from "@joshuins/system";
import { FormControl } from "@mui/material";
import axios from "axios";
import NotAnchor from "components/NotAnchor";
import { AlertCategory, usePage } from "components/Page";
import { Select } from "components/ReactHookFormUncontrolledComponents";
import { MenuItem } from "components/Select";
import { createFormPopup } from "components/Popup";
import { useApi } from "contexts/ApiProvider";
import { usePopup } from "contexts/PopupProvider";
import pick from "lodash/pick";
import { useAuthUser } from "react-auth-kit";
import { z } from "zod";
import { FileUpload } from "components/FileUpload";
import { getMessageFromAxiosError } from "utils/axios-extras";

const ADD_EDIT_REPORT_TEMPLATE = "add-or-edit-report-template" as const;

const TEMPLATES_TYPES_FOR_SELECT = [
    "Submissions",
    "Quotes",
    "Transactions",
] as const;

const addOrEditReportTemplateSchema = z.object({
    name: z.string().min(1, "This field is required"),
    type: z.enum(TEMPLATES_TYPES_FOR_SELECT, {
        required_error: "This field is required",
    }),
    file: z.union([
        z.instanceof(File, { message: "No file selected" }),
        z.string().min(1, "No file selected"),
    ]),
});

type AddOrEditReportTemplateType = z.infer<
    typeof addOrEditReportTemplateSchema
>;

const { FormPopup, useFormReturnRef } = createFormPopup(
    addOrEditReportTemplateSchema
);

const AddOrEditReportTemplatePopup: FC = () => {
    const {
        formReturn: { reset },
        formReturnRefCallback,
    } = useFormReturnRef();
    const { addAlertMessages, refreshState, tryCatchAndRaiseError } = usePage();
    const { popupData, isPopupOpen } = usePopup();
    const { sdkBuilder, sdkSystem } = useApi();
    const authUser = useAuthUser();
    const currentUser = authUser() as User;
    const existingReportTemplateIdToUpdate = popupData?.reportTemplateId as
        | string
        | undefined;
    const nextDisplayOrder = popupData?.nextDisplayOrder as number | undefined;

    const transformFormDataToApiDataForCreate = useCallback(
        async (
            formData: AddOrEditReportTemplateType
        ): Promise<NewReportTemplate | undefined> => {
            if (
                nextDisplayOrder === undefined ||
                !(formData.file instanceof File)
            ) {
                return undefined;
            }

            try {
                const id = await sdkBuilder.uploadFile(
                    formData.file,
                    "ReportTemplate"
                );

                return {
                    ...pick(formData, ["name", "type"]),
                    file_id: id,
                    display_order: nextDisplayOrder,
                    user_id: currentUser.id,
                };
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    addAlertMessages({
                        message: getMessageFromAxiosError(error),
                        category: AlertCategory.ALERT,
                    });
                    return undefined;
                } else {
                    throw error;
                }
            }
        },
        [sdkBuilder, addAlertMessages, nextDisplayOrder, currentUser]
    );

    const transformFormDataToApiDataForUpdate = useCallback(
        async (
            formData: AddOrEditReportTemplateType
        ): Promise<UpdateReportTemplate | undefined> => {
            try {
                let fileId: string | undefined;
                if (formData.file instanceof File) {
                    const id = await sdkBuilder.uploadFile(
                        formData.file,
                        "ReportTemplate"
                    );
                    fileId = id;
                }

                return {
                    ...pick(formData, ["name", "type"]),
                    user_id: currentUser.id,
                    ...(fileId && {
                        file_id: fileId,
                    }),
                };
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    addAlertMessages({
                        message: getMessageFromAxiosError(error),
                        category: AlertCategory.ALERT,
                    });
                    return undefined;
                } else {
                    throw error;
                }
            }
        },
        [sdkBuilder, addAlertMessages, currentUser]
    );

    const transformApiDataToFormData = useCallback(
        async (
            reportTemplate: ReportTemplate
        ): Promise<AddOrEditReportTemplateType | undefined> => {
            try {
                const getFileResponse = await sdkBuilder.getFile({
                    id: reportTemplate.file_id,
                });

                return {
                    type: reportTemplate.type_,
                    name: reportTemplate.name ?? "",
                    file: getFileResponse.filename,
                };
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    addAlertMessages({
                        message: getMessageFromAxiosError(error),
                        category: AlertCategory.ALERT,
                    });
                    return undefined;
                } else {
                    throw error;
                }
            }
        },
        [sdkBuilder, addAlertMessages]
    );

    useEffect(() => {
        const getDocumentTemplate = async () => {
            if (
                !existingReportTemplateIdToUpdate ||
                !isPopupOpen(ADD_EDIT_REPORT_TEMPLATE) ||
                !reset
            ) {
                return;
            }
            tryCatchAndRaiseError(async () => {
                const reportTemplate = await sdkSystem.getReportTemplate({
                    id: existingReportTemplateIdToUpdate,
                });

                const formData =
                    await transformApiDataToFormData(reportTemplate);
                if (formData) {
                    reset(formData);
                }
            });
        };
        getDocumentTemplate();
    }, [
        sdkBuilder,
        sdkSystem,
        existingReportTemplateIdToUpdate,
        transformApiDataToFormData,
        isPopupOpen,
        addAlertMessages,
        reset,
        tryCatchAndRaiseError,
    ]);

    const onSubmit = useCallback(
        async (data: AddOrEditReportTemplateType) => {
            tryCatchAndRaiseError(async () => {
                if (existingReportTemplateIdToUpdate) {
                    const apiData =
                        await transformFormDataToApiDataForUpdate(data);
                    if (!apiData) {
                        return;
                    }
                    await sdkSystem.updateReportTemplate({
                        id: existingReportTemplateIdToUpdate,
                        UpdateReportTemplate: apiData,
                    });
                    addAlertMessages({
                        message: "Bordereau template successfully updated.",
                        category: AlertCategory.SUCCESS,
                    });
                } else {
                    const apiData =
                        await transformFormDataToApiDataForCreate(data);
                    if (!apiData) {
                        return;
                    }

                    await sdkSystem.createReportTemplate({
                        NewReportTemplate: apiData,
                    });
                    addAlertMessages({
                        message: "Bordereau template successfully added.",
                        category: AlertCategory.SUCCESS,
                    });
                }
            }, refreshState);
        },
        [
            tryCatchAndRaiseError,
            refreshState,
            existingReportTemplateIdToUpdate,
            transformFormDataToApiDataForUpdate,
            sdkSystem,
            addAlertMessages,
            transformFormDataToApiDataForCreate,
        ]
    );

    return (
        <FormPopup
            name={ADD_EDIT_REPORT_TEMPLATE}
            defaultValues={{
                name: "",
                type: undefined,
                file: undefined,
            }}
            submitText="Upload"
            formReturnRefCallback={formReturnRefCallback}
            onSubmit={onSubmit}
            overlayPopups={[
                <>
                    <h2>Template Name</h2>
                    <p>
                        This name will not appear in the exports, it is only
                        used to display the list of templates in Joshu.
                    </p>
                </>,
                <>
                    <h2>Template Type</h2>
                    <p>
                        This setting tells us what to pull from the database.
                        You can add as many templates as you need for each type.
                    </p>
                </>,
            ]}
        >
            {({
                register,
                control,
                openOverlayPopup,
                formState: { errors },
            }) => {
                return (
                    <>
                        <h2>Template</h2>
                        <p className="size-14">
                            Upload an Excel bordereau file with appropriate
                            reference tags included
                        </p>
                        <p>
                            <label htmlFor="iha">
                                Template Name{" "}
                                <NotAnchor
                                    onClick={() => {
                                        openOverlayPopup(0);
                                    }}
                                    className="text-right"
                                >
                                    <i
                                        aria-hidden="true"
                                        className="icon-help"
                                    />{" "}
                                    <span className="hidden">More info</span>
                                </NotAnchor>
                            </label>
                            <input {...register("name")} type="text" id="iha" />
                            {errors.name && (
                                <label className="error" htmlFor="iha">
                                    {errors.name.message}
                                </label>
                            )}
                        </p>
                        <div className="div-as-p">
                            <span className="label">
                                Template Type{" "}
                                <NotAnchor
                                    onClick={() => {
                                        openOverlayPopup(1);
                                    }}
                                    className="text-right"
                                >
                                    <i className="icon-help" />{" "}
                                    <span className="hidden">More info</span>
                                </NotAnchor>
                            </span>
                            <FormControl fullWidth error={!!errors.type}>
                                <Select
                                    labelId="type-select-label"
                                    id="ihf"
                                    name="type"
                                    control={control}
                                >
                                    {TEMPLATES_TYPES_FOR_SELECT.map((value) => (
                                        <MenuItem key={value} value={value}>
                                            {value}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {errors.type && (
                                    <label className="error" htmlFor="ifv">
                                        {errors.type.message}
                                    </label>
                                )}
                            </FormControl>
                        </div>
                        <FileUpload
                            id="ihb"
                            name="file"
                            control={control}
                            fileDesignation="ReportTemplate"
                            error={errors.file?.message}
                        />
                    </>
                );
            }}
        </FormPopup>
    );
};

export default AddOrEditReportTemplatePopup;
export { ADD_EDIT_REPORT_TEMPLATE };
