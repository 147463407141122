import { FC, useCallback, useEffect } from "react";
import classNames from "classnames";
import TextareaAutosize from "react-textarea-autosize";
import { z } from "zod";
import { ProductIntegrationV1 } from "@joshuins/builder";
import { Integration } from "@joshuins/system";
import { AlertCategory, usePage } from "components/Page";
import { createFormPopup } from "components/Popup";
import { usePopup } from "contexts/PopupProvider";
import { useIntegrations } from "../IntegrationsProvider";
import cloneDeep from "lodash/cloneDeep";

const drcSetupFormSchema = z.object({
    configuration: z
        .string()
        .trim()
        .min(1, "This field is required")
        .refine(
            (value) => {
                let jsonError = undefined;
                try {
                    JSON.parse(value);
                } catch (error) {
                    jsonError = (error as SyntaxError).message;
                }
                return jsonError === undefined;
            },
            {
                message: "Invalid Configuration",
            }
        ),
});
type DrcSetupFormType = z.infer<typeof drcSetupFormSchema>;

const {
    FormPopup: DrcSetupFormPopup,
    useFormReturnRef: useDrcSetupFormReturnRef,
} = createFormPopup(drcSetupFormSchema);

const DrcSetupPopup: FC = () => {
    const {
        formReturn: { reset },
        formReturnRefCallback,
    } = useDrcSetupFormReturnRef();
    const { addAlertMessages } = usePage();
    const { popupData, isPopupOpen } = usePopup();
    const {
        accessors: { allIntegrationTypes },
        mutators: { addProductIntegration, updateProductIntegration },
    } = useIntegrations();

    const integration = popupData?.integration as Integration | undefined;
    const productIntegration = popupData?.productIntegration as
        | ProductIntegrationV1
        | undefined;
    const integrationTypes = allIntegrationTypes();
    const integrationType =
        integration && integrationTypes
            ? integrationTypes[integration.type_]
            : undefined;
    const is_published = popupData?.is_published as boolean | undefined;

    useEffect(() => {
        const getProductIntegration = async () => {
            if (
                !productIntegration ||
                !isPopupOpen("drc-setup-form") ||
                !reset
            ) {
                return;
            }

            reset({
                configuration: undefined,
            });
        };
        getProductIntegration();
    }, [productIntegration, isPopupOpen, reset]);

    const onSubmit = useCallback(
        async (data: DrcSetupFormType) => {
            if (!integration || !integrationType) {
                return;
            }
            let config;
            try {
                config = JSON.parse(data.configuration);
            } catch (error) {
                addAlertMessages({
                    message: (error as SyntaxError).message,
                    category: AlertCategory.ALERT,
                });
                return;
            }
            if (productIntegration) {
                const updatedProductIntegration = cloneDeep(productIntegration);
                updatedProductIntegration.config = config;
                await updateProductIntegration(
                    productIntegration.integration_id,
                    updatedProductIntegration
                );
                addAlertMessages({
                    message: `${integrationType.display_name} integration was updated succesfully`,
                    category: AlertCategory.SUCCESS,
                });
            } else {
                const newProductIntegration: ProductIntegrationV1 = {
                    enabled: true,
                    integration_id: integration.id,
                    config: JSON.parse(data.configuration),
                };
                await addProductIntegration(newProductIntegration);
                addAlertMessages({
                    message: `${integrationType.display_name} integration was created succesfully`,
                    category: AlertCategory.SUCCESS,
                });
            }
        },
        [
            addAlertMessages,
            addProductIntegration,
            integration,
            integrationType,
            productIntegration,
            updateProductIntegration,
        ]
    );

    if (!integrationType) {
        return <></>;
    }

    return (
        <DrcSetupFormPopup
            name="drc-setup-form"
            defaultValues={{
                configuration:
                    productIntegration && productIntegration.config
                        ? JSON.stringify(
                              productIntegration.config,
                              undefined,
                              2
                          )
                        : "",
            }}
            submitText="Save"
            formReturnRefCallback={formReturnRefCallback}
            onSubmit={onSubmit}
            disabled={is_published}
            overlayPopups={[]}
        >
            {({ register, formState: { errors } }) => (
                <>
                    <header>
                        <h2>{integrationType.display_name}</h2>
                    </header>
                    <p
                        className={classNames({
                            "has-error": errors.configuration,
                        })}
                    >
                        <label htmlFor="configuration">Configuration </label>
                        <TextareaAutosize
                            id="configuration"
                            {...register("configuration")}
                        />
                        {errors.configuration && (
                            <label
                                id="configutation-error"
                                className="error"
                                htmlFor="configutation"
                            >
                                {errors.configuration.message}
                            </label>
                        )}
                    </p>
                </>
            )}
        </DrcSetupFormPopup>
    );
};

export default DrcSetupPopup;
