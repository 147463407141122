import {
    DocumentPacketTypeV1,
    DocumentSchemaV1,
    FileDesignation,
} from "@joshuins/builder";
import { QuoteCodeAndValue } from "@joshuins/insurance";
import { FileUpload } from "components/FileUpload";
import { usePage } from "components/Page";
import { createFormPopup } from "components/Popup";
import { useApi } from "contexts/ApiProvider";
import { usePopup } from "contexts/PopupProvider";
import { LexoRank } from "lexorank";
import { range } from "lodash";
import { FC, useState, useEffect } from "react";
import { wrapAsJoValue } from "utils/jo-types-and-values";
import sortByLexorank from "utils/lexorank";
import { randomString } from "utils/string";
import { z } from "zod";
import { useInsuranceProcess } from "../InsuranceProcessProvider";
import { MANUAL_DOC_PREFIX } from "./util";

interface UploadManuscriptDocumentDataInterface {
    documentType: DocumentPacketTypeV1;
    documentsSchemas: DocumentSchemaV1[];
}

const UploadManuscriptDocumentSchema = z.object({
    display_name: z.string().min(1, "This field is required"),
    file: z
        .instanceof(File)
        .refine(
            (file) => file?.type === "application/pdf",
            "Only .pdf format is supported."
        ),
});

const {
    FormPopup: UploadManuscriptDocumentFormPopup,
    useFormReturnRef: uploadManuscriptDocumentFormReturnRef,
} = createFormPopup(UploadManuscriptDocumentSchema);

type UploadManuscriptDocumentType = z.infer<
    typeof UploadManuscriptDocumentSchema
>;

const UploadManuscriptDocumentPopup: FC = () => {
    const { tryCatchAndRaiseError } = usePage();
    const { popupData, isPopupOpen } = usePopup();
    const { sdkBuilder } = useApi();
    const {
        insuranceProcessDispatch,
        itemGetters: { getInsuranceProcess },
    } = useInsuranceProcess();

    const { quoteCodesAndValues } = getInsuranceProcess();
    const [documentType, setDocumentType] = useState<DocumentPacketTypeV1>();
    const [filteredManualDocuments, setFilteredManualDocuments] = useState<
        QuoteCodeAndValue[]
    >([]);
    const popupData_ =
        popupData as unknown as UploadManuscriptDocumentDataInterface;

    useEffect(() => {
        const onLoad = () => {
            if (!isPopupOpen("upload-manu-document") || !popupData_) {
                return;
            }
            setDocumentType(popupData_?.documentType);
            const documentsSchemas = popupData_?.documentsSchemas;
            const productScemaCodes = documentsSchemas.map((item) => item.code);
            setFilteredManualDocuments(
                quoteCodesAndValues.filter((item) => {
                    item.code.split(".")[0] === "doc" &&
                        productScemaCodes.includes(item.code);
                })
            );
        };
        onLoad();
    }, [documentType, isPopupOpen, popupData_, quoteCodesAndValues]);

    let maxRank = LexoRank.middle().toString();
    if (filteredManualDocuments.length > 0) {
        maxRank = sortByLexorank([...filteredManualDocuments]).slice(-1)[0]
            .rank;
    } else if (quoteCodesAndValues.length > 0) {
        maxRank = sortByLexorank([...quoteCodesAndValues]).slice(-1)[0].rank;
    }
    const nextRanksArray = [LexoRank.parse(maxRank).genNext()];

    range(6).map(() => {
        return nextRanksArray.push(nextRanksArray.slice(-1)[0].genNext());
    });

    const { formReturnRefCallback } = uploadManuscriptDocumentFormReturnRef();

    const onSubmit = async (data: UploadManuscriptDocumentType) => {
        tryCatchAndRaiseError(async () => {
            if (!data.file || !documentType) {
                return;
            }
            const fileId = await sdkBuilder.uploadFile(
                data.file,
                FileDesignation.ManualDocument
            );
            const newPartCode = randomString(8);
            const newDocumentCode = randomString(8);
            const updatedQuoteDocs: QuoteCodeAndValue[] = [
                {
                    code: `${MANUAL_DOC_PREFIX}.${newDocumentCode}`,
                    user_value: wrapAsJoValue({ Boolean: true }),
                    rater_value: wrapAsJoValue({ Boolean: true }),
                    rank: nextRanksArray[0].toString(),
                },
                {
                    code: `${MANUAL_DOC_PREFIX}.${newDocumentCode}.packet_type`,
                    user_value: wrapAsJoValue({ Text: documentType }),
                    rater_value: wrapAsJoValue({ Text: documentType }),
                    rank: nextRanksArray[1].toString(),
                },
                {
                    code: `${MANUAL_DOC_PREFIX}.${newDocumentCode}.name`,
                    user_value: wrapAsJoValue({ Text: data.display_name }),
                    rater_value: wrapAsJoValue({ Text: data.display_name }),
                    rank: nextRanksArray[2].toString(),
                },
                {
                    code: `${MANUAL_DOC_PREFIX}.${newDocumentCode}.part.${newPartCode}`,
                    user_value: wrapAsJoValue({ Boolean: true }),
                    rater_value: wrapAsJoValue({ Boolean: true }),
                    rank: nextRanksArray[3].toString(),
                },
                {
                    code: `${MANUAL_DOC_PREFIX}.${newDocumentCode}.part.${newPartCode}.file_id`,
                    user_value: wrapAsJoValue({ File: fileId }),
                    rater_value: wrapAsJoValue({ File: fileId }),
                    rank: nextRanksArray[4].toString(),
                },
                {
                    code: `${MANUAL_DOC_PREFIX}.${newDocumentCode}.part.${newPartCode}.name`,
                    user_value: wrapAsJoValue({ Text: data.display_name }),
                    rater_value: wrapAsJoValue({ Text: data.display_name }),
                    rank: nextRanksArray[5].toString(),
                },
            ];

            insuranceProcessDispatch({
                action: "SetDirtyFields",
                dirtyFields: updatedQuoteDocs,
            });
            insuranceProcessDispatch({
                action: "UpdateQuoteCodesAndValues",
                quoteCodesAndValues: updatedQuoteDocs,
            });
        });
    };
    if (!documentType) {
        return;
    }

    return (
        <UploadManuscriptDocumentFormPopup
            name="upload-manu-document"
            onSubmit={onSubmit}
            submitText="Upload Manuscript Document"
            mobileSubmitText="Upload"
            defaultValues={{
                display_name: "",
                file: undefined,
            }}
            formReturnRefCallback={formReturnRefCallback}
        >
            {({ register, control, formState: { errors } }) => (
                <>
                    <header>
                        <h2>Upload Manuscript Document</h2>
                    </header>
                    <div className={"div-as-p"}>
                        <label htmlFor="dsply">Display Name</label>
                        <input
                            type="text"
                            id="dsply"
                            {...register("display_name")}
                        />
                        {errors.display_name && (
                            <label
                                id="dsply-error"
                                className="error"
                                htmlFor="dsply"
                            >
                                {errors.display_name.message}
                            </label>
                        )}
                    </div>
                    <div className={"div-as-p"}>
                        <FileUpload
                            id="mnscrptfl"
                            name="file"
                            control={control}
                            fileDesignation={FileDesignation.ManualDocument}
                            error={errors.file?.message}
                            Label={
                                <label htmlFor="mnscrptfl">
                                    File
                                    <span className="text-right">
                                        10MB Limit
                                    </span>
                                </label>
                            }
                        />
                    </div>
                </>
            )}
        </UploadManuscriptDocumentFormPopup>
    );
};

export { UploadManuscriptDocumentPopup };
