import axios from "axios";
import JSZip from "jszip";
import { AnchorHTMLAttributes, DetailedHTMLProps, FC } from "react";
import { usePage } from "./Page";
import { useApi } from "contexts/ApiProvider";
import { asyncMap } from "modern-async";
import { GetFileResponse } from "@joshuins/builder";
import { hideLoader, showLoader } from "paul/native-dom-manipulation";

interface FileDownloadLinkInterface
    extends Omit<
        DetailedHTMLProps<
            AnchorHTMLAttributes<HTMLAnchorElement>,
            HTMLAnchorElement
        >,
        "href" | "onClick"
    > {
    fileIds: FileIds;
    downloadName?: string;
}

const downloadFile = async (file: GetFileResponse, downloadName: string) => {
    showLoader();
    const response = await axios({
        url: file.download_url,
        method: "GET",
        responseType: "blob",
    });
    hideLoader();
    return downloadBlob(response.data, downloadName);
};

const downloadBlob = (blob: Blob, name: string | undefined) => {
    const content = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = content;
    link.setAttribute("download", name ?? "");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(content);
};

type FileIds = string[] | (() => string[] | Promise<string[]>);

const useFileDownload = (fileIds: FileIds, downloadName?: string) => {
    const { sdkBuilder } = useApi();
    const { tryCatchAndRaiseError } = usePage();

    const downloadFiles = async () => {
        const fileIds_ =
            typeof fileIds === "function"
                ? await Promise.resolve(fileIds())
                : fileIds;
        if (fileIds_.length === 0) {
            return;
        }
        tryCatchAndRaiseError(async () => {
            if (fileIds_.length === 1) {
                const joshuFile = await sdkBuilder.getFile({
                    id: fileIds_[0],
                });
                downloadFile(joshuFile, downloadName || joshuFile.filename);
            } else {
                const zip = new JSZip();
                const files = await asyncMap(
                    fileIds_,
                    async (id_) => {
                        const getFileResponse = await sdkBuilder.getFile({
                            id: id_,
                        });
                        const response = await axios({
                            url: getFileResponse.download_url,
                            method: "GET",
                            responseType: "blob",
                        });
                        return [
                            `${getFileResponse.filename}.pdf`,
                            response.data,
                        ];
                    },
                    Number.POSITIVE_INFINITY
                );
                for (const [filename, data] of files) {
                    zip.file(filename, data);
                }

                const blob = await zip.generateAsync({ type: "blob" });
                downloadBlob(blob, downloadName);
            }
        });
    };

    return downloadFiles;
};

const FileDownload: FC<FileDownloadLinkInterface> = ({
    fileIds,
    children,
    downloadName,
    ...rest
}) => {
    const downloadFiles = useFileDownload(fileIds, downloadName);

    return (
        <a
            onClick={(e) => {
                e.preventDefault();
                downloadFiles();
            }}
            href="./"
            {...rest}
        >
            {children}
        </a>
    );
};

export default FileDownload;
export { useFileDownload };
