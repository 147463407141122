import { Submission, SubmissionStatus } from "@joshuins/insurance";
import { AlertCategory, usePage } from "components/Page";
import { ConfirmationPopup } from "components/Popup";
import { useApi } from "contexts/ApiProvider";
import { useBranding } from "contexts/BrandingProvider";
import { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import urlJoin from "url-join";

const UNLOCK_SUBMISSION_POPUP = "unlock-submission" as const;

const UnlockSubmissionPopup: FC = () => {
    const { sdkInsurance } = useApi();
    const { generateUrl } = useBranding();
    const navigate = useNavigate();
    const { element, addAlertMessages, tryCatchAndRaiseError } = usePage();
    const submission = element as unknown as Submission | undefined;

    const onSubmit = useCallback(async () => {
        if (!submission) {
            return;
        }
        tryCatchAndRaiseError(async () => {
            await sdkInsurance.updateSubmission({
                id: submission.id,
                UpdateSubmission: { status: SubmissionStatus.Incomplete },
            });
            addAlertMessages({
                message:
                    "Submission is unlocked! Make any changes and submit for new quote.",
                category: AlertCategory.SUCCESS,
            });
            navigate(
                generateUrl(
                    urlJoin("/", "submissions", submission.id.toString())
                )
            );
        });
    }, [
        addAlertMessages,
        generateUrl,
        navigate,
        sdkInsurance,
        submission,
        tryCatchAndRaiseError,
    ]);

    return (
        <ConfirmationPopup
            name="unlock-submission"
            onSubmit={onSubmit}
            submitText="Unlock Submission"
            mobileSubmitText="Unlock"
        >
            {submission && (
                <>
                    <header>
                        <h2>Are you sure?</h2>
                        <p className="size-14">
                            This will close all related quotes from In Review
                            and revert the submission to an incomplete
                            application.
                        </p>
                    </header>
                </>
            )}
        </ConfirmationPopup>
    );
};

export { UnlockSubmissionPopup, UNLOCK_SUBMISSION_POPUP };
