import { FC, useEffect, useState } from "react";
import { Popup } from "components/Popup";
import { Brokerage, Office, User } from "@joshuins/system";
import { useApi } from "contexts/ApiProvider";
import { usePopup } from "contexts/PopupProvider";
import { getLocationFromJoPlainValue } from "utils/jo-types-and-values";
import { ParsedGoogleAddress } from "@joshuins/builder";

const BROKER_INFO_POPUP = "broker-info" as const;

const BrokerInformationPopup: FC = () => {
    const { isPopupOpen, popupData } = usePopup();
    const broker = popupData?.broker as User;
    const brokerage = popupData?.brokerage as Brokerage;
    const { sdkSystem, sdkInsurance } = useApi();
    const [office, setOffice] = useState<Office | undefined>();
    const [officeLocation, setOfficeLocation] = useState<string | undefined>();

    useEffect(() => {
        if (!isPopupOpen(BROKER_INFO_POPUP) || !broker || !broker.office_id) {
            return;
        }
        const getOffice = async () => {
            if (!broker.office_id) {
                return;
            }
            const office_ = await sdkSystem.getOffice({
                id: broker.office_id,
            });
            if (office_) {
                setOffice(office_);
                const officeData = await sdkSystem.getOfficeData({
                    id: office_.id,
                });
                const locationData = officeData.find(
                    (item) => item.code === "office.location"
                );
                if (locationData && "Plain" in locationData.value.V1) {
                    const parsedAddress = getLocationFromJoPlainValue(
                        locationData.value.V1.Plain
                    );
                    if (typeof parsedAddress === "string") {
                        setOfficeLocation(parsedAddress);
                    } else {
                        setOfficeLocation(
                            (parsedAddress as ParsedGoogleAddress)
                                .formatted_address
                        );
                    }
                } else {
                    setOfficeLocation(undefined);
                }
            }
        };
        getOffice();
    }, [broker, broker?.office_id, isPopupOpen, sdkInsurance, sdkSystem]);

    if (broker && brokerage) {
        return (
            <Popup name={BROKER_INFO_POPUP}>
                <header
                    style={{
                        borderBottom: "1px solid var(--black_10)",
                        paddingBottom: "8px ",
                    }}
                >
                    <h2>Broker Profile</h2>
                </header>
                <p className="size-16 color-primary font-medium">
                    {broker.first_name} {broker.last_name}
                </p>
                <p className="size-14">{broker.email}</p>
                {brokerage.name && (
                    <p className="size-14">Brokerage: {brokerage.name}</p>
                )}
                {office?.name && (
                    <p className="size-14">Office: {office.name}</p>
                )}
                {officeLocation && (
                    <p className="size-14">Address: {officeLocation}</p>
                )}
                {brokerage.commission && (
                    <p className="size-14">
                        Commission: {brokerage.commission}
                    </p>
                )}
            </Popup>
        );
    }
};

export { BrokerInformationPopup, BROKER_INFO_POPUP };
