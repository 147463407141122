import { ProductVersion } from "@joshuins/insurance";
import { MainPane, MainPaneInterface } from "components/MainPane";
import { usePage } from "components/Page";
import { Popup } from "components/Popup";
import omit from "lodash/omit";
import { FC, PropsWithChildren, useEffect, useState } from "react";
import { formatDatetimeString } from "utils/datetime";
import { productVersionStatusTextAndColor } from "./util";
import { usePopup } from "contexts/PopupProvider";
import { useApi } from "contexts/ApiProvider";
import { ISSUES_WARNING, IssuesWarningPopup } from "./IssuesWarningPopup";

const DraftWarningPopup = () => {
    const { popupData } = usePopup();
    const numSubmissionsForProductVersion =
        popupData?.numSubmissionsForProductVersion as number | undefined;

    return (
        <Popup name="draft-warning">
            <h2 className="m25 color-primary">Draft with Submissions</h2>
            <p>
                {numSubmissionsForProductVersion !== undefined && (
                    <>
                        This draft version has{" "}
                        <span className="strong">
                            {numSubmissionsForProductVersion}
                        </span>{" "}
                        submission{numSubmissionsForProductVersion !== 1 && "s"}
                        .{" "}
                    </>
                )}
                When you change a draft version, previous submissions are
                removed from the system.
            </p>
            <p>
                If you would like to keep{" "}
                {numSubmissionsForProductVersion === 1
                    ? "this submission"
                    : "these submissions"}{" "}
                for further testing, you can duplicate this draft version and
                make changes in the second draft. You will then be able to
                duplicate{" "}
                {numSubmissionsForProductVersion === 1
                    ? "the submission"
                    : "submissions"}{" "}
                from this version to the second draft and keep making changes to
                the second draft.
            </p>
        </Popup>
    );
};
const ProductMainPane: FC<
    PropsWithChildren<
        Omit<MainPaneInterface, "subHeader"> & {
            subHeader?: Omit<
                NonNullable<MainPaneInterface["subHeader"]>,
                "items"
            > & {
                itemsBefore?: (string | null)[];
                itemsAfter?: (string | null)[];
            };
        }
    >
> = ({ subHeader, ...props }) => {
    const { element } = usePage();
    const { openPopup } = usePopup();
    const { sdkInsurance } = useApi();
    const productVersion = element as unknown as ProductVersion | undefined;
    const [
        numSubmissionsForProductVersion,
        setNumSubmissionsForProductVersion,
    ] = useState<number>();

    let customSubHeader: MainPaneInterface["subHeader"] | undefined =
        subHeader && omit(subHeader, "itemsBefore", "itemsAfter");

    useEffect(() => {
        const getNumSubmissionsForProductVersion = async () => {
            if (!productVersion) {
                return;
            }
            const numSubmissionsForProductVersion_ = (
                await sdkInsurance.allSubmissions({
                    product_version_id: productVersion.id,
                    _per_page: 1,
                })
            ).total_items;
            setNumSubmissionsForProductVersion(
                numSubmissionsForProductVersion_
            );
        };
        getNumSubmissionsForProductVersion();
    }, [productVersion, sdkInsurance]);

    if (!productVersion || numSubmissionsForProductVersion === undefined) {
        return;
    }

    if (!customSubHeader) {
        customSubHeader = {};
    }

    customSubHeader.items = [
        ...(subHeader?.itemsBefore ?? []),
        productVersion.is_published && productVersion.publish_time
            ? `Published ${formatDatetimeString(
                  productVersion.publish_time,
                  "numericDateOnly"
              )}`
            : productVersion.last_updated
              ? `Modified ${formatDatetimeString(
                    productVersion.last_updated,
                    "numericDateOnly"
                )}`
              : null,
        ...(subHeader?.itemsAfter ?? []),
    ];

    const productVersionStatusTextAndColor_ =
        productVersionStatusTextAndColor(productVersion);

    if (!customSubHeader.pill) {
        customSubHeader.pill = productVersionStatusTextAndColor_;
    }
    if (productVersion.issues.length > 0) {
        customSubHeader.error = {
            onClick: () => {
                openPopup(ISSUES_WARNING);
            },
        };
    }
    if (
        numSubmissionsForProductVersion > 0 &&
        !customSubHeader.warning &&
        productVersionStatusTextAndColor_.text === "DRAFT"
    ) {
        customSubHeader.warning = {
            onClick: () => {
                openPopup("draft-warning", {
                    numSubmissionsForProductVersion,
                });
            },
        };
    }

    return (
        <>
            <MainPane
                {...props}
                {...(customSubHeader && { subHeader: customSubHeader })}
            />
            <DraftWarningPopup />
            <IssuesWarningPopup />
        </>
    );
};

export default ProductMainPane;
