import isArray from "lodash/isArray";
import keys from "lodash/keys";
import map from "lodash/map";
import mapValues from "lodash/mapValues";
import values from "lodash/values";
import zipObject from "lodash/zipObject";
import fastCartesian from "fast-cartesian";

/** Generate all combination of arguments from objects
 *  {Object} opts - An object or arrays with keys describing options {firstName:['Ben', 'Jade', 'Darren'], lastName: ['Smith', 'Miller']}
 *  {Array}       - An array of objects e.g. [{firstName: 'Ben', LastName: 'Smith'},{..]
 **/
// this function and its documentation are taken and modified from this: https://gist.github.com/wassname/a882ac3981c8e18d2556
const productObj = (obj: Record<string, unknown[] | unknown>) => {
    const newObj = mapValues(obj, (value): unknown[] =>
        isArray(value) ? value : [value]
    );

    const keys_ = keys(obj);
    const opts = values(newObj);
    const combs = fastCartesian(opts);
    return map(combs, (comb) => zipObject(keys_, comb));
};

export { productObj };
