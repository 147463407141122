import uniqWith from "lodash/uniqWith";
import isEqual from "lodash/isEqual";
import pick from "lodash/pick";

const uniqObjectsByKeys = <T>(objects: T[], keys: (keyof T)[]): T[] =>
    uniqWith(objects, (objectA, objectB) =>
        isEqual(pick(objectA, keys), pick(objectB, keys))
    );

export { uniqObjectsByKeys };
