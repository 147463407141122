const RegistrationCheckEmailPage = () => (
    <main id="content">
        <form className="form-aside is-success">
            <div className="form-success">
                <h3>
                    <i aria-hidden="true" className="icon-envelope" />
                    Check your email!
                </h3>
                <p>We just sent you a link to set your password.</p>
            </div>
        </form>
    </main>
);

export default RegistrationCheckEmailPage;
