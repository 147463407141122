import {
    DocumentPacketTypeV1,
    DocumentPartV1,
    DocumentSchemaV1,
} from "@joshuins/builder";
import {
    GlobalParameterSchemaV1,
    LineItemSchemaV1,
    LineItemParameterSchemaV1,
    ProductSchemaV1Spec,
    QuoteCodeAndValue,
    Quote,
    QuoteStatus,
} from "@joshuins/insurance";
import cloneDeep from "lodash/cloneDeep";
import keyBy from "lodash/keyBy";
import orderBy from "lodash/orderBy";

const GLOBAL_PARAMETER_PREFIX = "prm" as const;
const LINE_ITEM_PREFIX = "litem" as const;
const DOCUMENT_PREFIX = "doc" as const;

const ACTIVE_QUOTE_STATUSES = [
    QuoteStatus.QuoteStoreEdit,
    QuoteStatus.QuotePending,
    QuoteStatus.QuotePublished,
] as const;

type QuoteDataItem<T, T2 = undefined> = {
    value:
        | {
              rater: QuoteCodeAndValue["rater_value"];
              user: QuoteCodeAndValue["user_value"];
          }
        | undefined;
    schema: T;
} & (T2 extends undefined ? unknown : { children: QuoteDataItems<T2> });

type NonEmptyQuoteDataItem<T, T2 = undefined> = {
    value:
        | {
              rater: QuoteCodeAndValue["rater_value"];
              user: QuoteCodeAndValue["user_value"];
          }
        | undefined;
    schema: T;
} & (T2 extends undefined ? unknown : { children: QuoteDataItems<T2> });

type QuoteDataItems<T, T2 = undefined> = {
    lookup: Record<string, QuoteDataItem<T, T2>>;
    nonEmptyLookup: Record<string, NonEmptyQuoteDataItem<T, T2>>;
    ordered: QuoteDataItem<T, T2>[];
    nonEmptyOrdered: NonEmptyQuoteDataItem<T, T2>[];
};

type GlobalParameterQuoteDataItems = QuoteDataItems<GlobalParameterSchemaV1>;
type LineItemQuoteDataItems = QuoteDataItems<
    LineItemSchemaV1,
    LineItemParameterSchemaV1
>;
type DocumentQuoteDataItems = QuoteDataItems<DocumentSchemaV1, DocumentPartV1>;

class ParsedQuote {
    globalParameters: GlobalParameterQuoteDataItems = {
        lookup: {},
        nonEmptyLookup: {},
        ordered: [],
        nonEmptyOrdered: [],
    };
    lineItems: LineItemQuoteDataItems = {
        lookup: {},
        nonEmptyLookup: {},
        ordered: [],
        nonEmptyOrdered: [],
    };
    documents: {
        [x in DocumentPacketTypeV1]: DocumentQuoteDataItems;
    } = Object.values(DocumentPacketTypeV1).reduce(
        (acc, key) => ({
            ...acc,
            [key]: {
                lookup: {},
                nonEmptyLookup: {},
                ordered: [],
                nonEmptyOrdered: [],
            },
        }),
        {} as {
            [x in DocumentPacketTypeV1]: DocumentQuoteDataItems;
        }
    );

    constructor(
        quoteCodesAndValues?: QuoteCodeAndValue[],
        productVersionSpec?: ProductSchemaV1Spec
    ) {
        if (!quoteCodesAndValues || !productVersionSpec) {
            return;
        }

        const quoteCodesAndValuesLookup = keyBy(quoteCodesAndValues, "code");

        // GLOBAL PARAMETERS
        for (const globalParameterSchema of productVersionSpec.global_params) {
            const quoteCodeAndValue =
                quoteCodesAndValuesLookup[
                    `${GLOBAL_PARAMETER_PREFIX}.${globalParameterSchema.code}`
                ];
            // quoteCodeAndValue can be undefined if it exists on schema but not quoteCodesAndValues.
            // this can happen when the visibility is set to 0, so its not returning from the BE.
            if (quoteCodeAndValue) {
                const quoteDataItem = {
                    value: {
                        user: quoteCodeAndValue.user_value,
                        rater: quoteCodeAndValue.rater_value,
                    },
                    schema: globalParameterSchema,
                };

                this.globalParameters.lookup[globalParameterSchema.code] =
                    quoteDataItem;
                this.globalParameters.ordered.push(quoteDataItem);
                if (quoteDataItem.value) {
                    this.globalParameters.nonEmptyLookup[
                        globalParameterSchema.code
                    ] = quoteDataItem;
                    this.globalParameters.nonEmptyOrdered.push(quoteDataItem);
                }
            }
        }

        // LINE ITEMS AND LINE ITEM PARAMETERS
        for (const lineItemSchema of productVersionSpec.line_items) {
            const lineItemChildren: QuoteDataItems<LineItemParameterSchemaV1> =
                {
                    lookup: {},
                    nonEmptyLookup: {},
                    ordered: [],
                    nonEmptyOrdered: [],
                };
            for (const lineItemParameterSchema of lineItemSchema.params) {
                const quoteCodeAndValue =
                    quoteCodesAndValuesLookup[
                        `${LINE_ITEM_PREFIX}.${lineItemSchema.code}.${lineItemParameterSchema.code}`
                    ];
                // quoteCodeAndValue can be undefined if it exists on schema but not quoteCodesAndValues.
                // this can happen when the visibility is set to 0, so its not returning from the BE.
                if (quoteCodeAndValue) {
                    const quoteDataItem = {
                        value: {
                            user: quoteCodeAndValue.user_value,
                            rater: quoteCodeAndValue.rater_value,
                        },
                        schema: lineItemParameterSchema,
                    };
                    lineItemChildren.lookup[lineItemParameterSchema.code] =
                        quoteDataItem;
                    lineItemChildren.ordered.push(quoteDataItem);
                    if (quoteDataItem.value) {
                        lineItemChildren.nonEmptyLookup[
                            lineItemParameterSchema.code
                        ] = quoteDataItem;
                        lineItemChildren.nonEmptyOrdered.push(quoteDataItem);
                    }
                }
            }

            const quoteCodeAndValue =
                quoteCodesAndValuesLookup[
                    `${LINE_ITEM_PREFIX}.${lineItemSchema.code}`
                ];
            // quoteCodeAndValue can be undefined if it exists on schema but not quoteCodesAndValues.
            // this can happen when the visibility is set to 0, so its not returning from the BE.
            if (quoteCodeAndValue) {
                const quoteDataItem = {
                    value: {
                        user: quoteCodeAndValue.user_value,
                        rater: quoteCodeAndValue.rater_value,
                    },
                    schema: lineItemSchema,
                    children: lineItemChildren,
                };
                this.lineItems.lookup[lineItemSchema.code] = quoteDataItem;
                this.lineItems.ordered.push(quoteDataItem);
                if (quoteDataItem.value) {
                    this.lineItems.nonEmptyLookup[lineItemSchema.code] =
                        quoteDataItem;
                    this.lineItems.nonEmptyOrdered.push(quoteDataItem);
                }
            }
        }

        // DOCUMENTS AND PARTS
        for (const packetType of Object.values(DocumentPacketTypeV1)) {
            const documents =
                productVersionSpec.documents.packets[packetType]?.documents;
            if (!documents) {
                continue;
            }
            for (const document of documents) {
                const documentChildren: QuoteDataItems<DocumentPartV1> = {
                    lookup: {},
                    nonEmptyLookup: {},
                    ordered: [],
                    nonEmptyOrdered: [],
                };
                for (const part of document.parts) {
                    const quoteCodeAndValue =
                        quoteCodesAndValuesLookup[
                            `${DOCUMENT_PREFIX}.${part.code}`
                        ];
                    if (quoteCodeAndValue) {
                        const quoteCodeAndValue =
                            quoteCodesAndValuesLookup[
                                `${DOCUMENT_PREFIX}.${part.code}`
                            ];
                        const quoteDataItem = {
                            value: {
                                user: quoteCodeAndValue.user_value,
                                rater: quoteCodeAndValue.rater_value,
                            },
                            schema: part,
                        };
                        documentChildren.lookup[part.code] = quoteDataItem;
                        documentChildren.ordered.push(quoteDataItem);
                        if (quoteDataItem.value) {
                            documentChildren.nonEmptyLookup[part.code] =
                                quoteDataItem;
                            documentChildren.nonEmptyOrdered.push(
                                quoteDataItem
                            );
                        }
                    }
                }
                const quoteCodeAndValue =
                    quoteCodesAndValuesLookup[
                        `${DOCUMENT_PREFIX}.${document.code}`
                    ];
                if (quoteCodeAndValue) {
                    const quoteDataItem = {
                        value: {
                            user: quoteCodeAndValue.user_value,
                            rater: quoteCodeAndValue.rater_value,
                        },
                        schema: document,
                        children: documentChildren,
                    };
                    this.documents[packetType].lookup[document.code] =
                        quoteDataItem;
                    this.documents[packetType].ordered.push(quoteDataItem);
                    if (quoteDataItem.value) {
                        this.documents[packetType].nonEmptyLookup[
                            document.code
                        ] = quoteDataItem;
                        this.documents[packetType].nonEmptyOrdered.push(
                            quoteDataItem
                        );
                    }
                }
            }
        }
    }

    public asApiQuoteData = () => [
        ...this.globalParameters.nonEmptyOrdered.map(
            ({ value, schema: { code } }) => ({
                code: `${GLOBAL_PARAMETER_PREFIX}.${code}`,
                value,
            })
        ),
        ...this.lineItems.nonEmptyOrdered.flatMap(
            ({
                value: lineItemValue,
                schema: { code: lineItemCode },
                children,
            }) => [
                {
                    code: `${LINE_ITEM_PREFIX}.${lineItemCode}`,
                    user_value: lineItemValue?.user,
                },
                ...children.nonEmptyOrdered.map(
                    ({
                        value: lineItemParameterValue,
                        schema: { code: lineItemParameterCode },
                    }) => ({
                        code: `${LINE_ITEM_PREFIX}.${lineItemCode}.${lineItemParameterCode}`,
                        value: lineItemParameterValue,
                    })
                ),
            ]
        ),
        ...Object.values(this.documents).flatMap((documentQuoteDataItems) =>
            documentQuoteDataItems.nonEmptyOrdered.flatMap(
                ({
                    value: documentValue,
                    schema: { code: documentCode },
                    children,
                }) => [
                    {
                        code: `${DOCUMENT_PREFIX}.${documentCode}`,
                        value: documentValue,
                    },
                    ...children.nonEmptyOrdered.map(
                        ({ value: partValue, schema: { code: partCode } }) => ({
                            code: `${DOCUMENT_PREFIX}.${partCode}`,
                            value: partValue,
                        })
                    ),
                ]
            )
        ),
    ];

    public clone = () => {
        const parsedQuote = new ParsedQuote();
        parsedQuote.globalParameters = cloneDeep(this.globalParameters);
        parsedQuote.lineItems = cloneDeep(this.lineItems);
        parsedQuote.documents = cloneDeep(this.documents);

        return parsedQuote;
    };
}

const quoteToShowFromVariations = (quotes: Quote[]) => {
    const sortedQuotes = orderBy(quotes, "id", "desc");
    for (const quote of sortedQuotes) {
        if (quote.status === QuoteStatus.QuotePublished) {
            return quote;
        }
    }
    return sortedQuotes[0];
};

export { ParsedQuote, quoteToShowFromVariations, ACTIVE_QUOTE_STATUSES };
