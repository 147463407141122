import { usePage } from "components/Page";
import { useApi } from "contexts/ApiProvider";
import { FC, useCallback } from "react";
import { QuoteStatus } from "@joshuins/insurance";
import { useInsuranceProcess } from "../InsuranceProcessProvider";
import NotAnchor from "components/NotAnchor";
import { ConfirmationPopup } from "components/Popup";
import { usePopup } from "contexts/PopupProvider";
import { useNavigate } from "components/DevAwareRoutingLink";
import urlJoin from "url-join";
import { waitForProcessingInsuranceObject } from "../../util";
import { useBranding } from "contexts/BrandingProvider";

const EditBinderLink: FC = () => {
    const { openPopup } = usePopup();

    return (
        <NotAnchor
            onClick={() => {
                openPopup("edit-binder");
            }}
            className="inline"
            aria-controls="edit-binder"
            aria-haspopup="true"
        >
            <i aria-hidden="true" className="icon-pencil" /> Edit{" "}
            <span className="mobile-hide">Binder </span>
            <span className="scheme-accesskey">
                <span>Shift</span> <span>q</span>
            </span>
        </NotAnchor>
    );
};

const EditBinderPopup: FC = () => {
    const { sdkInsurance } = useApi();
    const { tryCatchAndRaiseError, refreshElement } = usePage();
    const {
        itemGetters: { getInsuranceProcess },
    } = useInsuranceProcess();
    const { quote } = getInsuranceProcess();
    const navigate = useNavigate();
    const { generateUrl } = useBranding();

    const onSubmit = useCallback(async () => {
        if (!quote) {
            return <></>;
        }
        tryCatchAndRaiseError(async () => {
            const newQuote = await sdkInsurance.duplicateQuote({
                id: quote.id,
            });
            await waitForProcessingInsuranceObject(
                sdkInsurance,
                newQuote,
                "Creating New Quote Variation..."
            );
            const updatedQuote = await sdkInsurance.updateQuote({
                id: quote.id,
                UpdateQuote: { status: QuoteStatus.BinderDeclined },
            });
            await waitForProcessingInsuranceObject(
                sdkInsurance,
                updatedQuote,
                "Declining Binder..."
            );
            navigate(
                generateUrl(urlJoin("/", "quotes", newQuote.id.toString()))
            );
        }, refreshElement);
    }, [
        quote,
        tryCatchAndRaiseError,
        refreshElement,
        sdkInsurance,
        navigate,
        generateUrl,
    ]);

    return (
        <ConfirmationPopup
            name="edit-binder"
            onSubmit={onSubmit}
            submitText="Edit Binder"
        >
            <header>
                <h2>Are you sure?</h2>
                <p className="size-14">
                    We will edit this binder and notify any recipients via email
                </p>
            </header>
        </ConfirmationPopup>
    );
};

export { EditBinderLink, EditBinderPopup };
