// Figured out how to do a base64 encoding of the SHA-256 hash of a file using the following links:
// 1) https://gist.github.com/remarkablemark/92aa346b7bca97d6f108ad2c67c2e368#file-browser-hash-js
// 2) https://github.com/DimitarNestorov/file-to-hash/blob/master/packages/blob-to-hash/src/index.ts
// 3) https://stackoverflow.com/a/65116591
// 4) https://developer.mozilla.org/en-US/docs/Web/API/Web_Crypto_API/Non-cryptographic_uses_of_subtle_crypto
const fileHash = async (file: File) => {
    const fileArrayBuffer = await file.arrayBuffer();
    const hashArrayBuffer = await crypto.subtle.digest(
        "SHA-256",
        fileArrayBuffer
    );
    const hashUint8Array = Array.from(new Uint8Array(hashArrayBuffer));
    return window.btoa(String.fromCharCode.apply(null, hashUint8Array));
};

export { fileHash };
