import { FC, Fragment, useCallback, useEffect, useState } from "react";
import { useInsuranceProcess } from "../InsuranceProcessProvider";
import {
    LineItemSchemaV1,
    QuoteCodeAndUserValue,
    QuoteStatus,
    SubmissionFlow,
} from "@joshuins/insurance";
import ParameterInput, { ParameterData } from "./ParameterInput";
import NotAnchor from "components/NotAnchor";
import { ConfirmationPopup } from "components/Popup";
import { usePopup } from "contexts/PopupProvider";
import {
    isPermissionValid,
    getModifiedParametersCodes,
    isParameterModified,
} from "./util";
import compact from "lodash/compact";
import chunk from "lodash/chunk";
import keyBy from "lodash/keyBy";
import {
    getRawValueAsStringFromJoValue,
    getUserOrRaterValue,
    wrapAsJoValue,
} from "utils/jo-types-and-values";
import { LexoRank } from "lexorank";
import sortByLexorank from "utils/lexorank";
import { useAuthUser } from "react-auth-kit";
import { User } from "@joshuins/auth";

const CHANGE_COVERAGES = "change-coverages" as const;

const ChangeCoveragesPopup: FC = () => {
    const {
        itemGetters: { getInsuranceProcess },
        recalculateQuote,
    } = useInsuranceProcess();
    const { productVersion, quoteCodesAndValues } = getInsuranceProcess();
    const [selectedLineItems, setSelectedLineItems] = useState<string[]>();
    const authUser = useAuthUser();
    const currentUser = authUser() as User;

    useEffect(() => {
        const getLineItems = async () => {
            const quoteLineItems = quoteCodesAndValues.filter(
                (item) =>
                    item.code.startsWith("litem") &&
                    item.code.split(".").length === 2
            );
            setSelectedLineItems(
                compact(
                    quoteLineItems.map((item) => {
                        if (
                            getRawValueAsStringFromJoValue(
                                getUserOrRaterValue(item)
                            ) === "true"
                        ) {
                            return item.code;
                        }
                    })
                )
            );
        };
        getLineItems();
    }, [productVersion.schema.spec.line_items, quoteCodesAndValues]);

    const visibleLitems = productVersion.schema.spec.line_items.filter(
        (litem) => isPermissionValid(currentUser, litem.visibility)
    );

    const onSubmit = useCallback(async () => {
        if (!selectedLineItems) {
            return;
        }
        const visibleAndEditableLineItemsLookup = keyBy(
            visibleLitems.filter((litem) =>
                isPermissionValid(currentUser, litem.customizability)
            ),
            "code"
        );

        const updatedLineItems: QuoteCodeAndUserValue[] = [];
        Object.keys(visibleAndEditableLineItemsLookup).map((litemCode) => {
            const included = selectedLineItems.includes(litemCode);
            updatedLineItems.push({
                code: litemCode,
                user_value: wrapAsJoValue({ Boolean: included }),
                rank: LexoRank.parse(
                    sortByLexorank([...quoteCodesAndValues]).slice(-1)[0].rank
                )
                    .genNext()
                    .toString(),
            });
        });
        const newQuoteData = updatedLineItems
            // filter out document elements when we update solely line items
            .filter((item) => !item.code.startsWith("doc."));
        recalculateQuote(newQuoteData);
    }, [
        visibleLitems,
        quoteCodesAndValues,
        recalculateQuote,
        selectedLineItems,
        currentUser,
    ]);

    const handleChange = (code: string) => {
        if (selectedLineItems) {
            if (selectedLineItems.includes(code)) {
                const ind = selectedLineItems.indexOf(code);
                selectedLineItems.splice(ind, 1);
                setSelectedLineItems(selectedLineItems);
            } else {
                selectedLineItems.push(code);
                setSelectedLineItems(selectedLineItems);
            }
        }
    };
    return (
        <>
            {selectedLineItems && (
                <ConfirmationPopup
                    name={CHANGE_COVERAGES}
                    onSubmit={onSubmit}
                    submitText="Save Changes"
                >
                    <header>
                        <h2>Available Coverages</h2>
                    </header>
                    {productVersion.schema.spec.line_items && (
                        <ul className="check font-medium">
                            {visibleLitems.map((lineItem) => {
                                const litemCode = lineItem.code;
                                const customizable = isPermissionValid(
                                    currentUser,
                                    lineItem.customizability
                                );

                                return (
                                    <li key={lineItem.code}>
                                        <input
                                            type="checkbox"
                                            id={litemCode}
                                            value={litemCode}
                                            defaultChecked={selectedLineItems.includes(
                                                litemCode
                                            )}
                                            onChange={() =>
                                                handleChange(litemCode)
                                            }
                                            disabled={!customizable}
                                        />
                                        <label htmlFor={litemCode}>
                                            {lineItem.name}
                                        </label>
                                    </li>
                                );
                            })}
                        </ul>
                    )}
                </ConfirmationPopup>
            )}
        </>
    );
};

const CoverageSection: FC = () => {
    const {
        itemGetters: { getInsuranceProcess, getPreviousQuoteCodesAndValues },
        pageIds: { quoteId: pageQuoteId },
        insuranceProcessState: { dirtyFields: dirtyFields },
    } = useInsuranceProcess();
    const { productVersion, quoteCodesAndValues, quote, submission } =
        getInsuranceProcess();
    const { openPopup } = usePopup();
    const authUser = useAuthUser();
    const currentUser = authUser() as User;
    const [lineItems, setLineItems] = useState<{
        [key: string]: LineItemSchemaV1;
    }>();
    const [modifiedParametersCodes, setModifiedParametersCodes] = useState<
        string[]
    >([]);
    const [litemParameters, setLitemParameters] = useState<ParameterData[]>();
    const quoteCodesAndValuesByCode = keyBy(quoteCodesAndValues, "code");

    useEffect(() => {
        const quoteCodesAndValuesByCode_ = keyBy(quoteCodesAndValues, "code");
        const getLitemParameters = async () => {
            const litemParametersQuoteData = quoteCodesAndValues.filter(
                (item) => item.code.startsWith("litem")
            );
            const quoteCodes = litemParametersQuoteData.map(
                (quoteCodeAndValue) => quoteCodeAndValue.code
            );
            const visibleLineItems =
                productVersion.schema.spec.line_items.filter(
                    (litem) =>
                        quoteCodes.includes(litem.code) &&
                        isPermissionValid(currentUser, litem.visibility)
                );

            const litemParameters: ParameterData[] = [];
            for (const lineItem of productVersion.schema.spec.line_items) {
                for (const param of lineItem.params) {
                    const paramCode = param.code;
                    if (quoteCodesAndValuesByCode_[paramCode]) {
                        litemParameters.push({
                            schema: param,
                            value: quoteCodesAndValuesByCode_[paramCode],
                        });
                    }
                }
            }
            setLineItems(keyBy(visibleLineItems, "code"));
            setLitemParameters(litemParameters);
            if (pageQuoteId && submission.flow === SubmissionFlow.Endorsement) {
                const prevQuoteData = await getPreviousQuoteCodesAndValues();
                setModifiedParametersCodes(
                    getModifiedParametersCodes(
                        quoteCodesAndValues,
                        prevQuoteData
                    )
                );
            }
        };
        getLitemParameters();
    }, [
        currentUser,
        getPreviousQuoteCodesAndValues,
        pageQuoteId,
        productVersion.schema.spec.line_items,
        quoteCodesAndValues,
        submission,
    ]);
    const quoteIsEditable =
        quote.status === QuoteStatus.QuotePending ||
        quote.status === QuoteStatus.QuoteStoreEdit;

    return (
        <>
            <h2>
                <i aria-hidden="true" className="icon-doc-check" /> Coverage
                {quoteIsEditable && (
                    <NotAnchor
                        onClick={() => {
                            openPopup(CHANGE_COVERAGES);
                        }}
                        className="text-right"
                    >
                        <i aria-hidden="true" className="icon-pencil" /> Change{" "}
                        <span className="mobile-hide">Coverage </span>
                    </NotAnchor>
                )}
            </h2>
            {lineItems && litemParameters && (
                <table className="table-b">
                    <tbody>
                        {Object.keys(lineItems).map((lineItemCode) => {
                            const lineItem = lineItems[lineItemCode];
                            const included =
                                getRawValueAsStringFromJoValue(
                                    getUserOrRaterValue(
                                        quoteCodesAndValuesByCode[lineItemCode]
                                    )
                                ) === "true";
                            if (included) {
                                if (litemParameters.length === 0) {
                                    return (
                                        <Fragment key={lineItemCode}>
                                            <tr>
                                                <td>{lineItem.name}</td>
                                            </tr>
                                        </Fragment>
                                    );
                                } else {
                                    return chunk(
                                        litemParameters.filter(
                                            (param) =>
                                                `litem.${
                                                    param.value.code.split(
                                                        "."
                                                    )[1]
                                                }` === lineItemCode
                                        ),
                                        2
                                    ).map((chunk, index) => (
                                        <Fragment key={chunk[0].value.code}>
                                            <tr>
                                                <th></th>
                                                {chunk.map((param) => (
                                                    <th key={param.value.code}>
                                                        {param.schema.name}
                                                        {submission.flow ===
                                                            SubmissionFlow.Endorsement &&
                                                            isParameterModified(
                                                                dirtyFields,
                                                                param.value,
                                                                modifiedParametersCodes
                                                            ) && (
                                                                <span className="font-medium color-wine">
                                                                    {" "}
                                                                    Modified
                                                                </span>
                                                            )}
                                                    </th>
                                                ))}
                                            </tr>
                                            <tr>
                                                {index === 0 ? (
                                                    <th scope="col">
                                                        {lineItem.name}
                                                    </th>
                                                ) : (
                                                    <th className="empty"></th>
                                                )}

                                                {chunk.map((param) => (
                                                    <td
                                                        key={param.value.code}
                                                        className="parameter-data"
                                                    >
                                                        <span className="th">
                                                            {param.schema.name}
                                                        </span>
                                                        <ParameterInput
                                                            parameterData={
                                                                param
                                                            }
                                                        />
                                                    </td>
                                                ))}
                                            </tr>
                                        </Fragment>
                                    ));
                                }
                            }
                        })}
                    </tbody>
                </table>
            )}
        </>
    );
};

export { CoverageSection, ChangeCoveragesPopup };
