import { Page } from "components/Page";
import ExtraPane from "components/extra-panes/ExtraPane";
import { useApi } from "contexts/ApiProvider";
import { FC, useCallback, useEffect, useState } from "react";
import { MainPane } from "components/MainPane";
import EmptyPage from "pages/underwriter/quotes_and_policies/components/quote-and-policy-sections/EmptyPage";
import { AllTraces200Response } from "@joshuins/system";
import NotAnchor from "components/NotAnchor";
import { PaginationFooter, PaginationSearchInput } from "components/Pagination";
import { formatDatetimeString } from "utils/datetime";
import { useNavigate, useSearchParams } from "react-router-dom";
import urlJoin from "url-join";

const Main = () => {
    const { sdkSystem } = useApi();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [traces, setTraces] = useState<AllTraces200Response>();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [perPage, setPerPage] = useState<number>(10);
    const [query, setQuery] = useState<string | undefined>(
        searchParams.get("objectId") || undefined
    );

    const loadTraces = useCallback(
        async (_page: number) => {
            const traces_ = await sdkSystem.allTraces({
                _per_page: perPage,
                _page,
                object_id: query || undefined,
            });
            setTraces(traces_);
            setCurrentPage(_page);
        },
        [perPage, query, sdkSystem]
    );
    useEffect(() => {
        const getTraces = async () => {
            loadTraces(currentPage);
        };
        getTraces();
    }, [currentPage, loadTraces, sdkSystem]);

    const setPageSize = useCallback((perPage_: string) => {
        setPerPage(parseInt(perPage_));
        setCurrentPage(1);
    }, []);

    if (!traces) {
        return <></>;
    }

    return (
        <MainPane
            title={"Traces"}
            layoutConfig={{
                mainLayout:
                    !traces || traces.items.length === 0 ? "center" : "wide",
            }}
        >
            {traces.items.length > 0 ? (
                <>
                    <PaginationSearchInput
                        placeholder="Find trace..."
                        setCurrentPage={setCurrentPage}
                        setQuery={setQuery}
                        query={query}
                    />
                    <ul
                        className="list-plain box no-img"
                        // style adjusments are here in order to align with the old design (missing line at the top of the list)
                        style={{
                            borderTop: "1px solid var(--black_10)",
                            paddingTop: "10px",
                        }}
                    >
                        {traces.items.map((trace) => (
                            <li key={trace.id}>
                                <NotAnchor
                                    onClick={() =>
                                        navigate(
                                            urlJoin(
                                                "/",
                                                "admin",
                                                "system",
                                                "traces",
                                                trace.id.toString()
                                            )
                                        )
                                    }
                                >
                                    {trace.title}
                                </NotAnchor>
                                <ul className="list-inline a">
                                    <li>{`Created at ${formatDatetimeString(trace.created_at, "withTime")}`}</li>
                                    {/* <li>{"Text"}</li> */}
                                    {/* <li className={`overlay color-gold`}>
                                        <span>{`status`}</span>
                                    </li> */}
                                </ul>
                            </li>
                        ))}
                    </ul>
                </>
            ) : (
                <EmptyPage
                    title={"Traces"}
                    text={"No Traces Yet"}
                    icon={"icon-css"}
                />
            )}
            {traces.total_pages > 1 && (
                <PaginationFooter
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    totalPages={traces.total_pages}
                    setPageSize={setPageSize}
                />
            )}
        </MainPane>
    );
};

const ListTraceExtraPane = () => (
    <ExtraPane>
        <h3>
            <i aria-hidden="true" className="icon-help" /> Traces
        </h3>
        <p className="size-14">{/* TODO: enter text */}</p>
    </ExtraPane>
);

const TracesPage: FC = () => (
    <Page>
        <Main />
        <ListTraceExtraPane />
    </Page>
);

export default TracesPage;
