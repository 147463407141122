import useKeyboardShortcut from "use-keyboard-shortcut";
import { FC, PropsWithChildren } from "react";
import urlJoin from "url-join";
import { BUILDER_PATH, UNDERWRITER_PATH } from "globals";

const KeyboardShortcuts: FC<PropsWithChildren> = ({ children }) => {
    useKeyboardShortcut(
        ["Shift", "U"],
        () => (window.location.href = urlJoin("/", UNDERWRITER_PATH)),
        {
            overrideSystem: false,
            ignoreInputFields: true,
            repeatOnHold: false,
        }
    );
    useKeyboardShortcut(
        ["Shift", "B"],
        () => (window.location.href = urlJoin("/", BUILDER_PATH)),
        {
            overrideSystem: false,
            ignoreInputFields: true,
            repeatOnHold: false,
        }
    );

    return <>{children}</>;
};

export default KeyboardShortcuts;
