import {
    JoPlainTypeV1,
    JoPlainValueV1,
    JoTypeV1,
    JoValue,
    JoValueV1,
    MonetaryValue,
    ParsedAddress,
    ProductVersion,
    QuoteCodeAndValue,
} from "@joshuins/insurance";
import { Dictionary } from "lodash";
import isArray from "lodash/isArray";
import isEqual from "lodash/isEqual";
import keyBy from "lodash/keyBy";
import { CodePrefixes } from "pages/builder/products/components/ApplicationProvider";

class JoValueError extends Error {
    constructor() {
        super("unrecognised JoValue type");
    }
}

type RawJoPlainValue =
    | string
    | object
    | boolean
    | MonetaryValue
    | ParsedAddress;

const getRawValueFromJoPlainValue = (
    joPlainValue: JoPlainValueV1
): RawJoPlainValue => {
    if ("Text" in joPlainValue) {
        return joPlainValue.Text;
    }
    if ("Null" in joPlainValue) {
        return joPlainValue.Null;
    }
    if ("Boolean" in joPlainValue) {
        return joPlainValue.Boolean;
    }
    if ("Number" in joPlainValue) {
        return joPlainValue.Number;
    }
    if ("Monetary" in joPlainValue) {
        return joPlainValue.Monetary;
    }
    if ("Date" in joPlainValue) {
        return joPlainValue.Date;
    }
    if ("DateTime" in joPlainValue) {
        return joPlainValue.DateTime;
    }
    if ("Location" in joPlainValue) {
        return joPlainValue.Location;
    }
    if ("File" in joPlainValue) {
        return joPlainValue.File;
    }
    if ("User" in joPlainValue) {
        return joPlainValue.User;
    }
    throw new JoValueError();
};

const getRawValueFromJoValue = (joValue: JoValue) => {
    if ("Array" in joValue.V1) {
        return joValue.V1.Array.map((joPlainValue) =>
            getRawValueFromJoPlainValue(joPlainValue)
        );
    }
    if ("Plain" in joValue.V1) {
        return getRawValueFromJoPlainValue(joValue.V1.Plain);
    }
    throw new JoValueError();
};

const getRawValueFromPlainFileJoValue = (joValue: {
    V1: { Plain: Extract<JoPlainValueV1, { File: unknown }> };
}) => joValue.V1.Plain.File;

const getRawValueFromPlainBooleanJoValue = (joValue: {
    V1: { Plain: Extract<JoPlainValueV1, { Boolean: unknown }> };
}) => joValue.V1.Plain.Boolean;

const getRawValueFromArrayTextJoValue = (joValue: {
    V1: { Array: Extract<JoPlainValueV1, { Text: unknown }>[] };
}) => joValue.V1.Array.map((joPlainValue) => joPlainValue.Text);

const parseDate = (strDate: string, to: "UI" | "DB") => {
    const timezoneFix =
        to === "UI" && !strDate.includes("T") ? "T00:00:00" : "";
    const date = new Date(`${strDate}${timezoneFix}`);
    const dd = String(date.getDate()).padStart(2, "0");
    const mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = date.getFullYear();
    if (to === "DB") {
        return yyyy + "-" + mm + "-" + dd;
    }
    if (to === "UI") {
        return mm + "/" + dd + "/" + yyyy;
    }
    return strDate;
};

const getLocationFromJoPlainValue = (
    joPlainValue: JoPlainValueV1
): ParsedAddress | string => {
    if ("Location" in joPlainValue) {
        if (typeof joPlainValue.Location === "string") {
            return joPlainValue.Location;
        }
        if ("name" in joPlainValue.Location) {
            return joPlainValue.Location.name;
        }
        return joPlainValue.Location;
    }
    return "";
};

const getRawValueAsStringFromJoPlainValue = (
    joPlainValue: JoPlainValueV1
): string => {
    if ("Text" in joPlainValue) {
        return joPlainValue.Text;
    }
    if ("Null" in joPlainValue) {
        return "";
    }
    if ("Boolean" in joPlainValue) {
        return joPlainValue.Boolean.toString();
    }
    if ("Number" in joPlainValue) {
        return joPlainValue.Number;
    }
    if ("Monetary" in joPlainValue) {
        return `${joPlainValue.Monetary.amount}`;
    }
    if ("Date" in joPlainValue) {
        return parseDate(joPlainValue.Date, "UI");
    }
    if ("DateTime" in joPlainValue) {
        return parseDate(joPlainValue.DateTime, "UI");
    }
    if ("Location" in joPlainValue) {
        if (typeof joPlainValue.Location === "string") {
            return joPlainValue.Location;
        }
        if ("name" in joPlainValue.Location) {
            return joPlainValue.Location.name;
        }
        // @TODO: make use the address components instead of the formatted address
        return joPlainValue.Location.formatted_address;
    }
    if ("File" in joPlainValue) {
        return joPlainValue.File;
    }
    if ("User" in joPlainValue) {
        return joPlainValue.User;
    }
    throw new JoValueError();
};

const getRawValueAsStringFromJoValue = (joValue: JoValue) => {
    if ("Array" in joValue.V1) {
        return joValue.V1.Array.map((joPlainValue) =>
            getRawValueAsStringFromJoPlainValue(joPlainValue)
        ).join(",");
    }
    if ("Plain" in joValue.V1) {
        return getRawValueAsStringFromJoPlainValue(joValue.V1.Plain);
    }
    throw new JoValueError();
};

const getRawValueAsDateFromJoValue = (joValue: JoValue) => {
    if ("Plain" in joValue.V1) {
        const dateString = getRawValueAsStringFromJoPlainValue(
            joValue.V1.Plain
        );
        if (!dateString) {
            return undefined;
        }
        return new Date(dateString);
    }
    throw new JoValueError();
};

const getTypeFromJoPlainValue = (joPlainValue: JoPlainValueV1) => {
    if ("Text" in joPlainValue) {
        return "Text";
    }
    if ("Null" in joPlainValue) {
        return "Null";
    }
    if ("Boolean" in joPlainValue) {
        return "Boolean";
    }
    if ("Number" in joPlainValue) {
        return "Number";
    }
    if ("Monetary" in joPlainValue) {
        return "Monetary";
    }
    if ("Date" in joPlainValue) {
        return "Date";
    }
    if ("DateTime" in joPlainValue) {
        return "DateTime";
    }
    if ("Location" in joPlainValue) {
        return "Location";
    }
    if ("File" in joPlainValue) {
        return "File";
    }
    if ("User" in joPlainValue) {
        return "User";
    }
    throw new JoValueError();
};

const getJoValueType = (joValue: JoValue) => {
    if ("Array" in joValue.V1) {
        return joValue.V1.Array.map((joPlainValue) =>
            getTypeFromJoPlainValue(joPlainValue)
        );
    } else if ("Plain" in joValue.V1) {
        return getTypeFromJoPlainValue(joValue.V1.Plain);
    } else {
        throw new JoValueError();
    }
};

const joValueIsPlainAndFile = (
    joValue: JoValue
): joValue is {
    V1: { Plain: Extract<JoPlainValueV1, { File: unknown }> };
} => {
    const joValueType = getJoValueType(joValue);
    if (isArray(joValueType)) {
        return false;
    } else {
        return joValueType === "File";
    }
};

const joValueIsPlainAndLocation = (
    joValue: JoValue
): joValue is {
    V1: { Plain: Extract<JoPlainValueV1, { Location: unknown }> };
} => {
    const joValueType = getJoValueType(joValue);
    if (isArray(joValueType)) {
        return false;
    } else {
        return joValueType === "Location";
    }
};

const joValueIsPlainAndDate = (
    joValue: JoValue
): joValue is {
    V1: { Plain: Extract<JoPlainValueV1, { File: unknown }> };
} => {
    const joValueType = getJoValueType(joValue);
    if (isArray(joValueType)) {
        return false;
    } else {
        return joValueType === "Date";
    }
};

const joValueIsPlainAndText = (
    joValue: JoValue
): joValue is {
    V1: { Plain: Extract<JoPlainValueV1, { Text: unknown }> };
} => {
    const joValueType = getJoValueType(joValue);
    if (isArray(joValueType)) {
        return false;
    } else {
        return joValueType === "Text";
    }
};

const joValueIsPlainAndNumber = (
    joValue: JoValue
): joValue is {
    V1: { Plain: Extract<JoPlainValueV1, { Number: unknown }> };
} => {
    const joValueType = getJoValueType(joValue);
    if (isArray(joValueType)) {
        return false;
    } else {
        return joValueType === "Number";
    }
};

const joValueIsPlainAndMonetary = (
    joValue: JoValue
): joValue is {
    V1: { Plain: Extract<JoPlainValueV1, { Monetary: unknown }> };
} => {
    const joValueType = getJoValueType(joValue);
    if (isArray(joValueType)) {
        return false;
    } else {
        return joValueType === "Monetary";
    }
};

const joValueIsArrayAndText = (
    joValue: JoValue
): joValue is {
    V1: { Array: Extract<JoPlainValueV1, { Text: unknown }>[] };
} => {
    const joValueType = getJoValueType(joValue);
    if (!isArray(joValueType)) {
        return false;
    } else {
        return joValueType.every((type_) => type_ === "Text");
    }
};

const joValueIsPlainAndBoolean = (
    joValue: JoValue
): joValue is {
    V1: { Plain: Extract<JoPlainValueV1, { Boolean: unknown }> };
} => {
    const joValueType = getJoValueType(joValue);
    if (isArray(joValueType)) {
        return false;
    } else {
        return joValueType === "Boolean";
    }
};

const joValueIsPlainAndNull = (
    joValue: JoValue
): joValue is {
    V1: { Plain: Extract<JoPlainValueV1, { Null: unknown }> };
} => {
    const joValueType = getJoValueType(joValue);
    if (isArray(joValueType)) {
        return false;
    } else {
        return joValueType === "Null";
    }
};

const getTypeNameFromJoPlainType = (joPlainType: JoPlainTypeV1) => {
    if ("Text" in joPlainType) {
        return "Text";
    } else if ("Boolean" in joPlainType) {
        return "Boolean";
    } else if ("Number" in joPlainType) {
        return "Number";
    } else if ("Monetary" in joPlainType) {
        return "Monetary";
    } else if ("Date" in joPlainType) {
        return "Date";
    } else if ("Location" in joPlainType) {
        return "Location";
    } else if ("File" in joPlainType) {
        return "File";
    } else {
        throw new JoValueError();
    }
};

const getJoTypeName = (joType: JoTypeV1) => {
    if ("Array" in joType) {
        return [getTypeNameFromJoPlainType(joType.Array.type), true] as const;
    } else {
        return [getTypeNameFromJoPlainType(joType), false] as const;
    }
};

const joTypeIsArray = (
    joType: JoTypeV1
): joType is Extract<JoTypeV1, { Array: unknown }> => {
    return "Array" in joType;
};

const joTypeIsPlainAndText = (
    joType: JoTypeV1
): joType is Extract<JoPlainTypeV1, { Text: unknown }> => {
    const [joTypeName, isArray] = getJoTypeName(joType);
    return !isArray && joTypeName === "Text";
};

const joTypeIsArrayAndText = (
    joType: JoTypeV1
): joType is { Array: { type: Extract<JoPlainTypeV1, { Text: unknown }> } } => {
    const [joTypeName, isArray] = getJoTypeName(joType);
    return isArray && joTypeName === "Text";
};

const joValuesEqual = (joValue1: JoValue, joValue2: JoValue) => {
    const joValue1Type = getJoValueType(joValue1);
    const joValue2Type = getJoValueType(joValue2);
    const joValue1TypeIsArray = Array.isArray(joValue1Type);
    const joValue2TypeIsArray = Array.isArray(joValue2Type);

    if (joValue1TypeIsArray !== joValue2TypeIsArray) {
        // if one is an array and one is not, then they're not equal
        return false;
    } else if (joValue1TypeIsArray) {
        // if one is an array and one is not, then they're equal if
        // 1) they have the same length
        // 2) the JoValue types are the equal for every element of both arrays.
        //    i.e the value types at both array's index 0 must be the sames and
        //    the same for index 1 and index 2 etc...
        // 3) The JoValue raw values are deeply equal (see lodash's isEqual documentation)
        //    for every element of both arrays.
        return (
            joValue1Type.length === joValue2Type.length &&
            isEqual(joValue1Type, joValue2Type) &&
            isEqual(
                getRawValueFromJoValue(joValue1),
                getRawValueFromJoValue(joValue2)
            )
        );
    } else {
        // if both values are plain then they are equal if their types are equal and
        // their raw values are deeply equal
        return (
            joValue1Type === joValue2Type &&
            isEqual(
                getRawValueFromJoValue(joValue1),
                getRawValueFromJoValue(joValue2)
            )
        );
    }
};

const wrapAsJoValue = (
    joPlainValue: JoPlainValueV1 | JoPlainValueV1[]
): JoValue => {
    const joValue: JoValueV1 = Array.isArray(joPlainValue)
        ? { Array: joPlainValue }
        : { Plain: joPlainValue };

    return { V1: joValue };
};

const getJoTypeFromParamCode = (
    productVersion: ProductVersion,
    code: string
) => {
    let joType: JoTypeV1;
    if (code.startsWith(CodePrefixes.LINE_ITEM_PREFIX)) {
        const litemParamsLookup = keyBy(
            productVersion.schema.spec.line_items.flatMap(
                (litem) => litem.params
            ),
            "code"
        );
        joType = litemParamsLookup[code].type;
    } else if (code.startsWith(CodePrefixes.PARAMETER_PREFIX)) {
        const globalParamsLookup = keyBy(
            productVersion.schema.spec.global_params,
            "code"
        );
        joType = globalParamsLookup[code].type;
    } else {
        return;
    }
    return joType;
};

const setJoValue = (joValue: JoValue, newValue: string) => {
    // TODO: UI formatting is inside the form values, removing them here with .replaceAll()
    // this is not great, need to find how to exclude them from getValues()
    if ("V1" in joValue && "Plain" in joValue.V1) {
        const v1PlainJoValue = joValue.V1.Plain;
        if ("Number" in v1PlainJoValue) {
            joValue = wrapAsJoValue({ Number: newValue.replaceAll(",", "") });
            return joValue;
        } else if ("Boolean" in v1PlainJoValue) {
            joValue = wrapAsJoValue({ Boolean: Boolean(newValue === "true") });
            return joValue;
        } else if ("Date" in v1PlainJoValue) {
            joValue = wrapAsJoValue({ Date: parseDate(newValue, "DB") });
            return joValue;
        } else if ("DateTime" in v1PlainJoValue) {
            joValue = wrapAsJoValue({ DateTime: parseDate(newValue, "DB") });
            return joValue;
        } else if ("Text" in v1PlainJoValue) {
            joValue = wrapAsJoValue({ Text: newValue });
            return joValue;
        } else if ("Monetary" in v1PlainJoValue) {
            joValue = wrapAsJoValue({
                Monetary: {
                    amount: newValue.replaceAll(",", "").replaceAll("$", ""),
                    currency: "USD",
                },
            });
            return joValue;
        } else {
            return joValue;
        }
    } else {
        return joValue;
    }
};

const createPlainJoValue = (joType: JoTypeV1, newValue: string) => {
    // this function takes values that are "dirty" strings (has dollar signs, commas, etc)
    // and return the appropriate JoValue after cleanups with respect to its type.

    // if the input string is empty, assume the user wanted to 'Null' the parameter
    if (!newValue) {
        return wrapAsJoValue({ Null: {} });
    }
    if ("Number" in joType) {
        return wrapAsJoValue({ Number: newValue.replaceAll(",", "").trim() });
    } else if ("Boolean" in joType) {
        return wrapAsJoValue({
            Boolean: Boolean(newValue === "true" || newValue === "1"),
        });
    } else if ("Date" in joType) {
        return wrapAsJoValue({ Date: parseDate(newValue, "DB") });
    } else if ("DateTime" in joType) {
        return wrapAsJoValue({ DateTime: newValue });
    } else if ("Text" in joType) {
        return wrapAsJoValue({ Text: newValue });
    } else if ("Monetary" in joType) {
        return wrapAsJoValue({
            Monetary: {
                amount: newValue.replaceAll(",", "").replaceAll("$", ""),
                currency: "USD",
            },
        });
    } else if ("Location" in joType) {
        return wrapAsJoValue({ Location: newValue });
    }
    return wrapAsJoValue({ Null: {} });
};

const getUserOrRaterValue = (quoteCodeAndValue: QuoteCodeAndValue) => {
    const userValue = quoteCodeAndValue.user_value;
    if (getJoValueType(userValue) === "Null") {
        return quoteCodeAndValue.rater_value;
    }
    return userValue;
};

const getUserOrRaterValueByCode = (
    code: string,
    quoteCodeAndValuesByCode: Dictionary<QuoteCodeAndValue>
) => {
    const quoteValue = quoteCodeAndValuesByCode[code];
    return quoteValue
        ? getRawValueAsStringFromJoValue(getUserOrRaterValue(quoteValue))
        : undefined;
};

export {
    getRawValueFromJoValue,
    getLocationFromJoPlainValue,
    getRawValueAsStringFromJoValue,
    getRawValueFromPlainFileJoValue,
    getRawValueAsDateFromJoValue,
    wrapAsJoValue,
    getJoValueType,
    joValuesEqual,
    joValueIsPlainAndFile,
    joValueIsPlainAndText,
    joValueIsArrayAndText,
    joValueIsPlainAndBoolean,
    joValueIsPlainAndNull,
    joValueIsPlainAndDate,
    joValueIsPlainAndLocation,
    joValueIsPlainAndNumber,
    joValueIsPlainAndMonetary,
    getRawValueFromPlainBooleanJoValue,
    getRawValueFromArrayTextJoValue,
    joTypeIsArray,
    joTypeIsPlainAndText,
    joTypeIsArrayAndText,
    getJoTypeName,
    createPlainJoValue,
    setJoValue,
    getUserOrRaterValue,
    getUserOrRaterValueByCode,
    parseDate,
    getJoTypeFromParamCode,
};
