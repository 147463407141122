import { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Submission, SubmissionStatus } from "@joshuins/insurance";
import { usePage } from "components/Page";
import { createFormPopup } from "components/Popup";
import { useApi } from "contexts/ApiProvider";
import { useBranding } from "contexts/BrandingProvider";
import { Select } from "components/ReactHookFormUncontrolledComponents";
import { MenuItem as SelectMenuItem } from "components/Select";
import urlJoin from "url-join";
import { z } from "zod";
import { DECLINE_SUBMISSION_REASONS } from "../quotes_and_policies/util";

const declineIncompleteSubmissionSchema = z.object({
    decline_reason: z.string().min(1, "This field is required"),
});

type DeclineIncompleteSubmissionFlow = z.infer<
    typeof declineIncompleteSubmissionSchema
>;

const DeclineIncompleteSubmissionPopup: FC = () => {
    const { sdkInsurance } = useApi();
    const { generateUrl } = useBranding();
    const navigate = useNavigate();
    const { element, tryCatchAndRaiseError } = usePage();
    const submission = element as unknown as Submission | undefined;
    const { FormPopup } = createFormPopup(declineIncompleteSubmissionSchema);

    const onSubmit = useCallback(
        async (data: DeclineIncompleteSubmissionFlow) => {
            if (submission) {
                tryCatchAndRaiseError(async () => {
                    await sdkInsurance.updateSubmission({
                        id: submission.id,
                        UpdateSubmission: {
                            status: SubmissionStatus.Declined,
                            decline_reason: data.decline_reason,
                        },
                    });
                    navigate(generateUrl(urlJoin("/", "in-review")));
                });
            }
        },
        [submission, tryCatchAndRaiseError, sdkInsurance, navigate, generateUrl]
    );

    return (
        <FormPopup
            name="decline-submission"
            defaultValues={{
                decline_reason: "",
            }}
            onSubmit={onSubmit}
            submitText="Decline Submission"
        >
            {({ control }) => (
                <>
                    <header>
                        <h2>Decline Submission?</h2>
                    </header>
                    <p className="size-14 m15">
                        Please choose a reason to help the broker understand why
                        the submission was declined.
                    </p>
                    <div>
                        <span className="label">Reason for Decline</span>
                        <Select
                            id="decline-submission-reason"
                            name="decline_reason"
                            placeholder="Select reason..."
                            required
                            control={control}
                            fullWidth
                        >
                            {DECLINE_SUBMISSION_REASONS.map((item, index) => (
                                <SelectMenuItem key={index} value={item.value}>
                                    {item.value}
                                </SelectMenuItem>
                            ))}
                        </Select>
                    </div>
                </>
            )}
        </FormPopup>
    );
};

export { DeclineIncompleteSubmissionPopup };
