import axios from "axios";
import { useNavigate } from "components/DevAwareRoutingLink";
import { AlertCategory, usePage } from "components/Page";
import { useApi } from "contexts/ApiProvider";
import { useCallback, useEffect, useRef, useState } from "react";
import urlJoin from "url-join";
import { NewInsuredType } from "./NewInsuredPage";
import { Insured } from "@joshuins/insurance";
import { hideLoader, showLoader } from "paul/native-dom-manipulation";
import { useBranding } from "contexts/BrandingProvider";
import { ProductVersion } from "@joshuins/builder";
import { useComplexApiData } from "contexts/ComplexApiDataProvider";
import { getMessageFromAxiosError } from "utils/axios-extras";

interface WizardElements {
    newInsured?: NewInsuredType;
    insuredId?: number;
    productVersionId?: number;
    submissionId?: number;
    bypassScreening?: boolean;
}

interface AvailableSteps {
    newInsured: (props: WizardElements) => void;
    newSubmission: (props: WizardElements) => void;
    screening: (props: WizardElements) => void;
    application: (props: WizardElements) => void;
}

const useCreateSubmissionWizard = () => {
    const { generateUrl } = useBranding();
    const navigate = useNavigate();
    const { addAlertMessages, tryCatchAndRaiseError } = usePage();
    const { sdkInsurance } = useApi();
    const steps = useRef<AvailableSteps>();

    const nextStepAfter = useCallback(
        (
            previousStep?: keyof AvailableSteps,
            wizardElements: WizardElements = {}
        ) => {
            if (!steps.current) {
                return;
            }
            switch (previousStep) {
                case undefined:
                    steps.current.newInsured(wizardElements);
                    break;
                case "newInsured":
                    if (!wizardElements) {
                        return;
                    }
                    steps.current.newSubmission(wizardElements);
                    break;
                case "newSubmission":
                    if (!wizardElements) {
                        return;
                    }
                    steps.current.screening(wizardElements);
                    break;
                case "screening":
                    if (!wizardElements) {
                        return;
                    }
                    steps.current.application(wizardElements);
                    break;
                case "application":
                    break;
            }
        },
        []
    );

    const proceed = useCallback(
        (
            currentStep?: keyof AvailableSteps,
            wizardElements: WizardElements = {}
        ) => {
            if (!steps.current) {
                return;
            }
            switch (currentStep) {
                case undefined:
                    steps.current.newInsured(wizardElements);
                    break;
                case "newInsured":
                    if (!wizardElements) {
                        return;
                    }
                    steps.current.newInsured(wizardElements);
                    break;
                case "newSubmission":
                    if (!wizardElements) {
                        return;
                    }
                    steps.current.newSubmission(wizardElements);
                    break;
                case "screening":
                    if (!wizardElements) {
                        return;
                    }
                    steps.current.screening(wizardElements);
                    break;
                case "application":
                    if (!wizardElements) {
                        return;
                    }
                    steps.current.application(wizardElements);
                    break;
            }
        },
        []
    );

    const newInsured = useCallback(
        async ({ insuredId, newInsured }: WizardElements) => {
            if (insuredId) {
                nextStepAfter("newInsured", { insuredId });
            } else if (newInsured) {
                let insured: Insured;
                try {
                    if (typeof newInsured.address !== "string") {
                        throw new Error("Invalid address");
                    }
                    const address = JSON.parse(newInsured.address);
                    insured = await sdkInsurance.createInsured({
                        CreateInsured: {
                            name: newInsured.name,
                            ...(newInsured.industry.value && {
                                industry_id: newInsured.industry.value,
                            }),
                            ...(newInsured.website.value && {
                                website: newInsured.website.value,
                            }),
                        },
                    });

                    await sdkInsurance.updateInsuredData({
                        id: insured.id,
                        CreateInsuredData: {
                            data: [
                                {
                                    code: "insured.location",
                                    value: {
                                        V1: {
                                            Plain: {
                                                Location: address,
                                            },
                                        },
                                    },
                                },
                            ],
                        },
                    });
                } catch (error) {
                    const message = axios.isAxiosError(error)
                        ? getMessageFromAxiosError(error)
                        : "Invalid address";
                    addAlertMessages({
                        message,
                        category: AlertCategory.ALERT,
                    });
                    return;
                }
                nextStepAfter("newInsured", { insuredId: insured.id });
            } else {
                navigate(generateUrl(urlJoin("/", "insureds", "new")));
            }
        },
        [nextStepAfter, sdkInsurance, addAlertMessages, navigate, generateUrl]
    );

    const newSubmission = useCallback(
        async ({ productVersionId, insuredId }: WizardElements) => {
            if (!insuredId) {
                return;
            }

            let chosenProductVersion: ProductVersion | undefined = undefined;
            if (productVersionId) {
                chosenProductVersion = await sdkInsurance.getProductVersion({
                    id: productVersionId,
                });
            } else {
                const allProductVersions =
                    await sdkInsurance.allProductVersions({
                        _per_page: 1,
                    });
                if (allProductVersions.total_items === 1) {
                    chosenProductVersion = await sdkInsurance.getProductVersion(
                        {
                            id: allProductVersions.items[0].id,
                        }
                    );
                }
            }

            tryCatchAndRaiseError(async () => {
                if (chosenProductVersion) {
                    const newPolicy = await sdkInsurance.createPolicy({
                        CreatePolicy: { insured_id: insuredId },
                    });
                    const newSubmission = await sdkInsurance.createSubmission({
                        CreateSubmission: {
                            New: {
                                product_version_id: chosenProductVersion.id,
                                policy_id: newPolicy.id,
                                test: !chosenProductVersion.is_published,
                            },
                        },
                    });
                    nextStepAfter("newSubmission", {
                        submissionId: newSubmission.id,
                    });
                } else {
                    navigate(
                        generateUrl(
                            urlJoin(
                                "/",
                                "insureds",
                                insuredId.toString(),
                                "new-submission"
                            )
                        ),
                        { state: { fromWizard: true }, replace: true }
                    );
                }
            });
        },
        [
            generateUrl,
            navigate,
            nextStepAfter,
            sdkInsurance,
            tryCatchAndRaiseError,
        ]
    );

    const screening = useCallback(
        async ({ submissionId, bypassScreening }: WizardElements) => {
            if (!submissionId) {
                return;
            }
            const [getSubmissionPromise, , isReadyImmediatelyPromise] =
                sdkInsurance.getSubmissionWhenReady(submissionId);
            if (!(await isReadyImmediatelyPromise)) {
                showLoader("Screening...");
            }
            await getSubmissionPromise;
            const parsedSubmissionData =
                await sdkInsurance.getParsedSubmissionData(submissionId);
            if (!(await isReadyImmediatelyPromise)) {
                hideLoader();
            }
            if (
                parsedSubmissionData.assets["0"].insuredChecks
                    .trade_gov_ofac === undefined ||
                parsedSubmissionData.assets["0"].insuredChecks.trade_gov_ofac
            ) {
                nextStepAfter("screening", { submissionId: submissionId });
            } else if (bypassScreening) {
                const parsedSubmissionDataClone = parsedSubmissionData.clone();
                parsedSubmissionDataClone.updateCheck("trade_gov_ofac", true);

                tryCatchAndRaiseError(async () => {
                    await sdkInsurance.updateSubmissionData({
                        id: submissionId,
                        CreateSubmissionData: {
                            data: parsedSubmissionDataClone.asApiSubmissionData(
                                ["0"],
                                {
                                    insuredChecks: {
                                        trade_gov_ofac: true,
                                    },
                                }
                            ),
                        },
                    });

                    nextStepAfter("screening", {
                        submissionId: submissionId,
                    });
                });
            } else {
                navigate(
                    generateUrl(
                        urlJoin(
                            "/",
                            "submissions",
                            submissionId.toString(),
                            "screening"
                        )
                    )
                );
            }
        },
        [
            sdkInsurance,
            nextStepAfter,
            tryCatchAndRaiseError,
            navigate,
            generateUrl,
        ]
    );

    const application = useCallback(
        async ({ submissionId }: WizardElements) => {
            if (!submissionId) {
                return;
            }

            navigate(
                generateUrl(
                    urlJoin(
                        "/",
                        "submissions",
                        submissionId.toString(),
                        "application"
                    )
                )
            );
        },
        [generateUrl, navigate]
    );

    steps.current = {
        newInsured,
        newSubmission,
        screening,
        application,
    };

    return proceed;
};

const useAvailableProductVersions = () => {
    const { userHasAvailableProducts } = useComplexApiData();
    const [availableProducts, setAvailableProducts] = useState<boolean>(false);

    useEffect(() => {
        const productVersions = async () => {
            setAvailableProducts(await userHasAvailableProducts());
        };
        productVersions();
    }, [userHasAvailableProducts]);
    return availableProducts;
};

export { useCreateSubmissionWizard, useAvailableProductVersions };
