/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { FC } from "react";
import { MuiColorInputProps, MuiColorInput } from "mui-color-input";

const ColorPicker: FC<MuiColorInputProps> = ({ ...rest }) => (
    <MuiColorInput
        {...rest}
        css={css`
            & .MuiInputBase-root {
                height: 40px;
                line-height: 40px;
                font-family: var(--font_content);
                & .MuiInputBase-input {
                    padding: 0;
                }
                & .MuiOutlinedInput-notchedOutline {
                    top: 0;
                    border: 1px solid var(--black_10);
                    border-radius: 2px;
                }

                &:hover {
                    & .MuiOutlinedInput-notchedOutline {
                        border: 1px solid var(--black_10);
                    }
                }

                &.Mui-focused {
                    box-shadow:
                        -2px 0 0 var(--bg_primary_input_border),
                        2px 0 0 var(--bg_primary_input_border),
                        0 -2px 0 var(--bg_primary_input_border),
                        0 2px 0 var(--bg_primary_input_border),
                        -1px -1px 0 var(--bg_primary_input_border),
                        1px -1px 0 var(--bg_primary_input_border),
                        -1px 1px 0 var(--bg_primary_input_border),
                        1px 1px 0 var(--bg_primary_input_border);
                    border-color: var(--main_color);
                    border-style: solid;
                    border-width: 1px;
                    border-radius: 2px;
                    & .MuiInputBase-input {
                        border: 0;
                        box-shadow: none;
                    }
                    & .MuiOutlinedInput-notchedOutline {
                        border: 0;
                    }
                }

                & .MuiButtonBase-root {
                    height: 16px;
                    width: 16px;
                }
            }
        `}
    />
);

export { ColorPicker };
