import { FC, useCallback, useEffect, useRef, useState } from "react";
import { Wrapper } from "@googlemaps/react-wrapper";
import { ParsedGoogleAddress } from "@joshuins/insurance";
import { z } from "zod";
import { GoogleMapsMap } from "./GoogleMapsMap";

interface GoogleMapsAutocompleteProps {
    id: string;
    value: string | object | undefined;
    disabled: boolean;
    onChange: (value: string | object | undefined) => void;
    useAddressAsString?: boolean;
}

const zodField = z.object({});
const GoogleMapsAutocomplete: FC<GoogleMapsAutocompleteProps> = ({
    id,
    value,
    onChange,
    disabled = false,
    useAddressAsString = true,
}) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const autocompleteRef = useRef<google.maps.places.Autocomplete>();
    const listenerRef = useRef<google.maps.MapsEventListener>();
    const [latLang, setLatLang] = useState<google.maps.LatLng>();

    const emptyValue = useCallback(() => {
        if (value === undefined || value === null) {
            return true;
        }
        if (typeof value === "string") {
            const stringValue = value as string;
            return stringValue.length === 0;
        }
        if (typeof value === "object") {
            return !("geometry" in value);
        }
        return false;
    }, [value]);

    const mapClicked = useCallback((latLng: google.maps.LatLng) => {
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ location: latLng }, (results, status) => {
            if (
                status === "OK" &&
                results &&
                results[0] &&
                autocompleteRef.current
            ) {
                autocompleteRef.current.set("place", results[0]);
            }
        });
    }, []);

    const createAutoComplete = useCallback(() => {
        if (autocompleteRef.current === undefined) {
            autocompleteRef.current = new google.maps.places.Autocomplete(
                document.getElementById(id) as HTMLInputElement,
                {
                    fields: [
                        "address_components",
                        "formatted_address",
                        "geometry",
                        "plus_code",
                    ],
                }
            );

            listenerRef.current = autocompleteRef.current.addListener(
                "place_changed",
                () => {
                    if (autocompleteRef.current) {
                        const placeResult = autocompleteRef.current.getPlace();
                        const value = useAddressAsString
                            ? JSON.stringify(placeResult)
                            : placeResult;
                        if (placeResult && placeResult.geometry) {
                            if (placeResult.geometry.location) {
                                setLatLang(placeResult.geometry.location);
                            }
                        } else if (emptyValue()) {
                            setLatLang(undefined);
                        }
                        onChange(value);
                    }
                }
            );
        }
    }, [id, useAddressAsString, onChange, emptyValue]);

    const resetInputField = useCallback(
        (isInit: boolean = false) => {
            if (inputRef.current === null) {
                return;
            }
            if (emptyValue()) {
                inputRef.current.value = "";
            } else {
                let finalValue = value;
                let doFocusOnInit = false;
                if (typeof value === "string") {
                    try {
                        finalValue = JSON.parse(value);
                    } catch (error) {
                        doFocusOnInit = true;
                    }
                }
                const finalValueAsString =
                    typeof finalValue === "object" &&
                    "formatted_address" in finalValue
                        ? (finalValue.formatted_address as string)
                        : typeof finalValue === "string"
                          ? finalValue
                          : "";
                if (finalValueAsString) {
                    inputRef.current.value = finalValueAsString;
                }
                if (isInit && doFocusOnInit) {
                    inputRef.current.focus();
                }
            }
        },
        [value, emptyValue]
    );

    const initInputField = useCallback(() => {
        resetInputField(true);
    }, [resetInputField]);

    const setMapValue = useCallback(() => {
        if (typeof value === "object" && "geometry" in value) {
            const parsedAddress = value as ParsedGoogleAddress;
            const currentLocation = new google.maps.LatLng(
                parseFloat(parsedAddress.geometry.location.lat),
                parseFloat(parsedAddress.geometry.location.lng)
            );
            setLatLang(currentLocation);
        }

        if (emptyValue()) {
            setLatLang(undefined);
        }
    }, [value, emptyValue]);

    const onInputBlur = () => {
        resetInputField();
    };

    useEffect(() => {
        createAutoComplete();
        initInputField();
        setMapValue();
    }, [createAutoComplete, initInputField, setMapValue]);

    useEffect(() => {
        return () => {
            if (autocompleteRef.current) {
                autocompleteRef.current = undefined;
            }
            if (listenerRef.current) {
                listenerRef.current.remove();
                listenerRef.current = undefined;
            }
        };
    }, []);

    return (
        <>
            <input
                ref={inputRef}
                disabled={disabled}
                readOnly={disabled}
                id={id}
                onBlur={onInputBlur}
            />
            <GoogleMapsMap
                id={`_map_${id}`}
                latLang={latLang}
                readOnly={disabled}
                onClick={mapClicked}
            />
        </>
    );
};

const GoogleMapsAutocompleteWrapper: FC<GoogleMapsAutocompleteProps> = (
    props
) => (
    <Wrapper
        apiKey={window.joshuGlobals.GOOGLE_MAP_PLACES_API_KEY}
        libraries={["places"]}
    >
        <GoogleMapsAutocomplete {...props} />
    </Wrapper>
);

export default GoogleMapsAutocompleteWrapper;
export { zodField };
