import {
    createContext,
    Dispatch,
    FC,
    PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { useImmerReducer } from "use-immer";

import { usePage } from "components/Page";
import {
    BindDatapointV1,
    ProductVersion,
    SectionV1,
    Submission,
    SubmissionCodeValueAndRank,
    SubmissionDataStatus,
    Quote,
    QuoteStatus,
} from "@joshuins/insurance";
import { GetFileResponse } from "@joshuins/builder";
import { useApi } from "contexts/ApiProvider";
import keyBy from "lodash/keyBy";
import { includes } from "utils/array";
import { isAssetSection } from "utils/datapoints-and-sections";
import SubmissionData from "utils/submission-data";
import { asyncMap } from "modern-async";
import {
    getRawValueFromPlainFileJoValue,
    joValueIsPlainAndFile,
} from "utils/jo-types-and-values";
import compact from "lodash/compact";
import fromPairs from "lodash/fromPairs";
import { isQuote } from "utils/policies";

type BindQuestionAction = {
    action: "SetSubmissionData";
    submissionData: SubmissionDataViews;
};

interface SubmissionDataViews {
    codesValuesAndAssets: SubmissionCodeValueAndRank[];
    parsed: SubmissionData;
    status: SubmissionDataStatus;
    files: Record<string, GetFileResponse>;
}

interface BindState {
    productVersion: ProductVersion;
    editable: boolean;
    submissionData: SubmissionDataViews;
    submissionDataStatus: SubmissionDataStatus;
}
interface BindQuestionProviderData {
    productVersion: ProductVersion;
    editable: boolean;
    submissionData: SubmissionDataViews;
    submissionDataStatus: SubmissionDataStatus;
    submission: Submission;
}

interface BindQuestionProviderInterface {
    editable: boolean;
    bindQuestionsState: BindState;
    applicationDispatch: Dispatch<BindQuestionAction>;
    refreshSubmissionData: () => Promise<void>;
    itemGetters: {
        getProductVersion: () => ProductVersion;
        getSubmissionData: () => SubmissionCodeValueAndRank[];
        getSubmissionDataFiles: () => Record<string, GetFileResponse>;
        getSubmissionDataStatus: () => SubmissionDataStatus;
        section: (code: string) => SectionV1 | undefined;
        datapoint: (code: string) => BindDatapointV1 | undefined;
    };
}

const BindQuestionContext = createContext<
    BindQuestionProviderInterface | undefined
>(undefined);

const useBindQuestion = () => {
    const context = useContext(BindQuestionContext);

    if (context === undefined) {
        throw Error(
            "useBindQuestion must be used inside an BindQuestionProvider context"
        );
    }

    return context;
};

const reducer = (draft: BindState, action: BindQuestionAction) => {
    switch (action.action) {
        case "SetSubmissionData": {
            draft.submissionData = action.submissionData;
            break;
        }
    }
};

const useSubmissionData = () => {
    const { sdkInsurance, sdkBuilder } = useApi();

    const getSubmissionData = useCallback(
        async (submission: Submission) => {
            const [
                { codesValuesAndAssets, parsedSubmissionData, files },
                submissionDataStatus,
            ] = await Promise.all([
                (async () => {
                    const submissionData = await sdkInsurance.getSubmissionData(
                        {
                            id: submission.id,
                        }
                    );
                    const parsedSubmissionData =
                        await sdkInsurance.getParsedSubmissionData(
                            submission.id
                        );
                    const fileApplicationItems = compact(
                        compact(
                            Object.values(parsedSubmissionData.assets)
                        ).flatMap((asset) =>
                            Object.entries(asset.bindItems).map(
                                ([code, applicationItem]) => {
                                    if (
                                        applicationItem &&
                                        joValueIsPlainAndFile(applicationItem)
                                    ) {
                                        return { code: code, applicationItem };
                                    }
                                }
                            )
                        )
                    );

                    const files = fromPairs(
                        await asyncMap(
                            fileApplicationItems,
                            async (fileApplicationItem) => {
                                const fileId = getRawValueFromPlainFileJoValue(
                                    fileApplicationItem.applicationItem
                                );

                                return [
                                    fileApplicationItem.code,
                                    await sdkBuilder.getFile({
                                        id: fileId,
                                    }),
                                ] as [string, GetFileResponse];
                            },
                            Number.POSITIVE_INFINITY
                        )
                    );

                    return {
                        codesValuesAndAssets: submissionData,
                        parsedSubmissionData,
                        files,
                    };
                })(),
                sdkInsurance.getSubmissionDataStatus({
                    id: submission.id,
                }),
            ]);

            return {
                codesValuesAndAssets,
                parsed: parsedSubmissionData,
                status: submissionDataStatus,
                files,
            };
        },
        [sdkBuilder, sdkInsurance]
    );

    return getSubmissionData;
};

const BindQuestionProvider: FC<PropsWithChildren<BindQuestionProviderData>> = ({
    children,
    productVersion,
    submissionData,
    editable,
    submissionDataStatus,
    submission,
}) => {
    const processedSectionData = useMemo(() => {
        const submissionDataStatusLookUp = keyBy(
            submissionDataStatus.sections,
            "code"
        );
        const sections = productVersion.schema.spec.sections.filter(
            (section) =>
                section.type !== "BindQuestion" &&
                submissionDataStatusLookUp[section.code] &&
                submissionDataStatusLookUp[section.code].condition_met !== false
        );

        const bindSection = productVersion.schema.spec.sections.find(
            (section) => section.type === "BindQuestion"
        );

        return {
            sections,
            bindSection,
            assetSections: sections.filter((section) =>
                isAssetSection(section)
            ) as Extract<SectionV1, { type: "Asset" }>[],
            sectionsByCode: keyBy(sections, "code"),
            sectionIndexByCode: sections.reduce(
                (accumulator, section, index) => {
                    accumulator[section.code] = index;
                    return accumulator;
                },
                {} as Record<string, number>
            ),
            datapointsByCode: sections.reduce(
                (accumulator, section) => {
                    for (const sectionItem of section.items) {
                        if ("Datapoint" in sectionItem) {
                            accumulator[sectionItem.Datapoint.code] =
                                sectionItem.Datapoint;
                        }
                    }
                    return accumulator;
                },
                {} as Record<string, BindDatapointV1>
            ),
            datapointsInSectionCode: sections.reduce(
                (accumulator, section) => {
                    if (!includes(["Asset", "Application"], section.type)) {
                        return accumulator;
                    }
                    if (!accumulator[section.code]) {
                        accumulator[section.code] = [];
                    }
                    for (const sectionItem of section.items) {
                        if ("Datapoint" in sectionItem) {
                            accumulator[section.code].push(
                                sectionItem.Datapoint
                            );
                        }
                    }
                    return accumulator;
                },
                {} as Record<string, BindDatapointV1[]>
            ),
        };
    }, [submissionDataStatus, productVersion.schema.spec.sections]);

    const [bindQuestionsState, applicationDispatch] = useImmerReducer<
        BindState,
        BindQuestionAction
    >(reducer, {
        productVersion,
        submissionData,
        editable,
        submissionDataStatus,
    });

    const getSubmissionData_ = useSubmissionData();

    const refreshSubmissionData = useCallback(async () => {
        if (!submission) {
            return;
        }
        applicationDispatch({
            action: "SetSubmissionData",
            submissionData: await getSubmissionData_(submission),
        });
    }, [applicationDispatch, getSubmissionData_, submission]);

    const getProductVersion = useCallback(
        () => bindQuestionsState.productVersion,
        [bindQuestionsState.productVersion]
    );

    const getSubmissionData = useCallback(
        () => bindQuestionsState.submissionData.codesValuesAndAssets,
        [bindQuestionsState.submissionData.codesValuesAndAssets]
    );

    const getSubmissionDataFiles = useCallback(
        () => bindQuestionsState.submissionData.files,
        [bindQuestionsState.submissionData.files]
    );

    const getSubmissionDataStatus = useCallback(
        () => bindQuestionsState.submissionData.status,
        [bindQuestionsState.submissionData.status]
    );

    const section = useCallback(
        (code: string) => processedSectionData.sectionsByCode[code],
        [processedSectionData.sectionsByCode]
    );

    const datapoint = useCallback(
        (code: string) => {
            if (processedSectionData.bindSection) {
                for (const sectionItem of processedSectionData.bindSection
                    .items) {
                    if (
                        "Datapoint" in sectionItem &&
                        sectionItem.Datapoint.code === code
                    ) {
                        return sectionItem.Datapoint;
                    }
                }
            }
        },
        [processedSectionData.bindSection]
    );

    return (
        <BindQuestionContext.Provider
            value={{
                editable,
                bindQuestionsState,
                applicationDispatch,
                refreshSubmissionData,
                itemGetters: {
                    getProductVersion,
                    getSubmissionData,
                    getSubmissionDataFiles,
                    getSubmissionDataStatus,
                    section,
                    datapoint,
                },
            }}
        >
            {children}
        </BindQuestionContext.Provider>
    );
};

const BindQuestionProviderWrapper: FC<PropsWithChildren> = ({ children }) => {
    const { element } = usePage();
    const quote = element as unknown as Quote;
    const { sdkBuilder, sdkInsurance, sdkSystem } = useApi();
    const [bindQuestionsProviderData, setBindQuestionProviderData] =
        useState<BindQuestionProviderData>();
    const getSubmissionData = useSubmissionData();

    useEffect(() => {
        const getBindQuestionProviderData = async () => {
            if (!quote) {
                return;
            }
            // let policy: Quote;
            const submission = await sdkInsurance.getSubmission({
                id: quote.submission_id,
            });
            const [productVersion_, submissionData, submissionDataStatus_] =
                await Promise.all([
                    sdkInsurance.getProductVersion({
                        id: submission.product_version_id,
                    }),
                    getSubmissionData(submission),
                    sdkInsurance.getSubmissionDataStatus({ id: submission.id }),
                    submission,
                ]);

            setBindQuestionProviderData({
                productVersion: productVersion_,
                submissionData,
                editable:
                    isQuote(quote) ||
                    quote.status === QuoteStatus.BinderPending,
                submissionDataStatus: submissionDataStatus_,
                submission,
            });
        };
        getBindQuestionProviderData();
    }, [getSubmissionData, quote, sdkBuilder, sdkInsurance, sdkSystem]);

    if (!bindQuestionsProviderData) {
        return <></>;
    }

    return (
        <BindQuestionProvider {...bindQuestionsProviderData}>
            {children}
        </BindQuestionProvider>
    );
};

export { BindQuestionProviderWrapper as BindQuestionProvider, useBindQuestion };
