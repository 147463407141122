import { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useInsuranceProcess } from "../InsuranceProcessProvider";
import { useApi } from "contexts/ApiProvider";
import NotAnchor from "components/NotAnchor";
import urlJoin from "url-join";
import { waitForProcessingInsuranceObject } from "../../util";
import { useBranding } from "contexts/BrandingProvider";

const NewVariationLink: FC = () => {
    const { sdkInsurance } = useApi();
    const navigate = useNavigate();
    const {
        itemGetters: { getInsuranceProcess },
    } = useInsuranceProcess();
    const { quote } = getInsuranceProcess();
    const { storeUrlName, generateUrl } = useBranding();
    const inStore = storeUrlName !== undefined;

    const newQuoteVariation = useCallback(async () => {
        const newQuote = await sdkInsurance.duplicateQuote({
            id: quote.id,
        });
        await waitForProcessingInsuranceObject(
            sdkInsurance,
            newQuote,
            "Creating New Quote Variation..."
        );
        navigate(generateUrl(urlJoin("/", "quotes", newQuote.id.toString())));
    }, [navigate, quote.id, sdkInsurance, generateUrl]);

    return (
        <NotAnchor
            onClick={() => {
                newQuoteVariation();
            }}
            className="inline"
            aria-controls="new-variation"
            aria-haspopup="true"
        >
            {inStore ? (
                <>
                    <i className="icon-documents" /> Request New{" "}
                    <span className="mobile-hide">Quote</span>
                </>
            ) : (
                <>
                    <i className="icon-plus-circle" /> Create New{" "}
                    <span className="mobile-hide">Variation</span>
                </>
            )}
        </NotAnchor>
    );
};

export { NewVariationLink };
