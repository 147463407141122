import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
    Dispatch,
    forwardRef,
    FC,
    PropsWithChildren,
    SetStateAction,
} from "react";
import {
    LinkProps,
    NavigateOptions,
    Path,
    useLocation,
    useParams,
    useSearchParams,
} from "react-router-dom";
import urlJoin from "url-join";
import { useApi } from "contexts/ApiProvider";
import { ThemePropertiesInterface, setTheme } from "paul/theme";
import { useUnhidePage } from "./UnhidePageProvider";
import {
    Link as ReactRouterLink,
    useNavigate,
} from "components/DevAwareRoutingLink";
import { STORE_PATH, UNDERWRITER_PATH } from "globals";

type generateUrlArgumentType = string | Partial<Path>;

// conditional type
type generateUrlReturnType<T> = T extends string
    ? string
    : T extends Partial<Path>
      ? Partial<Path>
      : never;

interface BrandingContextValue {
    generateUrl: <T extends generateUrlArgumentType>(
        url: T
    ) => generateUrlReturnType<T>;
    generateNextUrl: () => string;
    generateOidcSsoUrl: (urlSlug: string) => string;
    navigate: (to: string, options?: NavigateOptions) => void;
    storeUrlName: string | null | undefined;
    setStoreUrlName: Dispatch<SetStateAction<string | undefined>> | undefined;
}

const BrandingContext = createContext<BrandingContextValue | undefined>(
    undefined
);

const useBranding = () => {
    const context = useContext(BrandingContext);

    if (context === undefined) {
        throw Error(
            "useBranding must be used inside a BrandingProvider context"
        );
    }

    return context;
};

const BrandingProvider: FC<PropsWithChildren> = ({ children }) => {
    const { sdkBranding } = useApi();
    const [storeUrlName, setStoreUrlName] = useState<string | undefined>(
        undefined
    );
    const { unhide } = useUnhidePage();
    const reactRouterNavigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        if (storeUrlName) {
            const retrieveAndSetBrandingConfig = async () => {
                const data = await sdkBranding.getBranding();
                setTheme(data as ThemePropertiesInterface);
                unhide();
            };
            retrieveAndSetBrandingConfig();
        }
    }, [storeUrlName, location.key, sdkBranding, unhide]);

    const generateUrl = useCallback<
        <T extends generateUrlArgumentType>(url: T) => generateUrlReturnType<T>
    >(
        <T extends generateUrlArgumentType>(
            url: T
        ): generateUrlReturnType<T> => {
            let pathname;
            if (typeof url === "string") {
                pathname = url;
            } else {
                pathname = url.pathname || "";
            }
            const urlParams = ["/"];
            if (storeUrlName) {
                urlParams.push(STORE_PATH, storeUrlName);
            } else {
                if (pathname.indexOf("/auth/") === -1) {
                    urlParams.push(UNDERWRITER_PATH);
                }
            }
            urlParams.push(pathname);
            pathname = urlJoin(...urlParams);

            let returnVal;
            if (typeof url === "string") {
                returnVal = pathname;
            } else {
                url.pathname = pathname;
                returnVal = url;
            }
            return returnVal as generateUrlReturnType<T>;
        },
        [storeUrlName]
    );

    const generateNextUrl = useCallback(() => {
        return searchParams.get("next") ?? "/";
    }, [searchParams]);

    const generateOidcSsoUrl = useCallback(
        (oidcSlug: string) =>
            `/api/auth/v1/sso/${oidcSlug}?next=${generateNextUrl()}`,
        [generateNextUrl]
    );

    const [navigateTo, setNavigateTo] = useState<{
        to: string;
        options?: NavigateOptions;
    } | null>(null);

    useEffect(() => {
        if (navigateTo) {
            reactRouterNavigate(generateUrl(navigateTo.to), navigateTo.options);
        }
    }, [navigateTo, generateUrl, reactRouterNavigate]);

    // We can't call reactRouterNavigate() directly from another component because it results in this
    // confusing error:
    // "You should call navigate() in a React.useEffect(), not when your component is first rendered."
    // (https://github.com/remix-run/react-router/blob/892238bee052177e78012167c088290b06400206/packages/react-router/lib/hooks.tsx#L211)
    // So instead call it indirectly by using useEffect() and changing navigateTo to the url you want to
    // navigate to. Stupid
    const navigate = useCallback((to: string, options?: NavigateOptions) => {
        setNavigateTo({ to: to, options: options });
    }, []);

    return (
        <BrandingContext.Provider
            value={{
                generateUrl,
                generateNextUrl,
                generateOidcSsoUrl,
                navigate,
                storeUrlName,
                setStoreUrlName,
            }}
        >
            {children}
        </BrandingContext.Provider>
    );
};

const RoleAwareLink = forwardRef<HTMLAnchorElement, LinkProps>(
    ({ to, ...rest }, ref) => {
        const { generateUrl } = useBranding();

        return <ReactRouterLink {...rest} to={generateUrl(to)} ref={ref} />;
    }
);
RoleAwareLink.displayName = "RoleAwareLink";

const SetStoreFromReactRouterParams: FC<PropsWithChildren> = ({ children }) => {
    const { storeUrlName } = useParams();
    const { setStoreUrlName: setStoreUrlName_ } = useBranding();
    const { blockUnhide } = useUnhidePage();

    const setStoreUrlName = useCallback(
        (val: string | undefined): void => {
            if (setStoreUrlName_) {
                if (val) {
                    localStorage.setItem("storeUrlName", val);
                }
                setStoreUrlName_(val);
            }
        },
        [setStoreUrlName_]
    );

    useEffect(() => {
        blockUnhide();
        setStoreUrlName(storeUrlName || undefined);
    }, [storeUrlName, setStoreUrlName, blockUnhide]);

    return <>{children}</>;
};

export {
    useBranding,
    BrandingProvider,
    SetStoreFromReactRouterParams,
    RoleAwareLink,
};
