import { FC } from "react";

const EmptyPage: FC<{
    title: string;
    text: string;
    icon: string;
}> = ({ title, text, icon }) => (
    <div className="module-success inline">
        <h2>
            <i className={icon} /> No {title} Yet
        </h2>
        <p>{text}</p>
    </div>
);

export default EmptyPage;
