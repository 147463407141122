import {
    Control,
    Controller,
    FieldValues,
    Path,
    PathValue,
} from "react-hook-form";
import { useDropzone } from "react-dropzone";
import { ElementType, FC, useEffect } from "react";
import classNames from "classnames";
import Img from "./Img";
import { ControllerProps } from "./ReactHookFormUncontrolledComponents";

const Dropzone: FC<{
    onNewFile?: <T extends File>(acceptedFile: T) => void;
    Component?: ElementType;
    Label?: JSX.Element;
    value: File | string;
    [x: string]: unknown;
}> = ({ onNewFile, Component = "p", Label, value, ...rest }) => {
    const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
        accept: {
            "image/*": [],
        },
        ...rest,
    });

    useEffect(() => {
        const file_ = acceptedFiles[0];
        if (!file_) {
            return;
        }
        if (onNewFile) {
            onNewFile(file_);
        }
    }, [acceptedFiles, onNewFile]);

    return (
        <Component
            {...getRootProps({
                className: classNames("dropzone c50 input-file", {
                    "done-image": value,
                }),
            })}
        >
            {Label}
            <input {...getInputProps({ id: "stb" })} />
            <label htmlFor="stb">
                <i aria-hidden="true" className="icon-plus-circle" />
                Select File
                {value && (
                    <span
                        className="img"
                        style={{
                            display: "block !important",
                        }}
                    >
                        <Img src={value} alt="logo" />
                    </span>
                )}
            </label>
        </Component>
    );
};

const LogoUpload = <T extends FieldValues>({
    name,
    control,
    ...rest
}: {
    control: Control<T> | undefined;
    [x: string]: unknown;
} & ControllerProps<T>) => (
    <Controller
        render={({ field: { onChange, value } }) => (
            <Dropzone onNewFile={onChange} value={value} {...rest} />
        )}
        name={name}
        control={control}
        defaultValue={"" as PathValue<T, Path<T>>}
    />
);

export { LogoUpload };
