import { FC } from "react";
import { Page } from "components/Page";
import ExtraPane from "components/extra-panes/ExtraPane";
import { useAvailableProductVersions } from "pages/components/use-create-submission-wizard";
import PoliciesList from "pages/underwriter/quotes_and_policies/components/PoliciesList";
import { SubmissionFlow } from "@joshuins/builder";

const SubmissionsPane = () => (
    // TODO: fix text after decisions about where to present pending quotes.
    <ExtraPane>
        <h3>
            <i aria-hidden="true" className="icon-help"></i> Get a Quote
        </h3>
        <p className="size-14">
            Welcome to our online quoting portal. If you have any questions,
            please visit the Help Center at left.
        </p>
        <ul className="list-icon b">
            <li>
                <i aria-hidden="true" className="icon-list"></i> What You’ll
                Need{" "}
                <span>
                    General company information, specifics for each section.
                </span>
            </li>
            <li>
                <i aria-hidden="true" className="icon-time"></i> Quick Response{" "}
                <span>
                    Depending on the application, you may get an instant quote.
                </span>
            </li>
            <li>
                <i aria-hidden="true" className="icon-upload-cloud"></i> Saved
                Automatically{" "}
                <span>
                    Leave the application and come back any time to finish.
                </span>
            </li>
        </ul>
    </ExtraPane>
);

const StoreSubmissionsPage: FC = () => {
    const availableProducts = useAvailableProductVersions();
    return (
        <Page>
            <PoliciesList
                title="In Review"
                onGoing={Object.values(SubmissionFlow)}
                availableProducts={availableProducts}
            />
            <SubmissionsPane />
        </Page>
    );
};

export default StoreSubmissionsPage;
