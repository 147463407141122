import {
    createContext,
    Dispatch,
    FC,
    PropsWithChildren,
    useContext,
    useEffect,
} from "react";
import { useImmerReducer } from "use-immer";
import type { ProductVersion } from "@joshuins/builder";
import { usePage } from "components/Page";

enum CodePrefixes {
    APPLICATION_PREFIX = "app",
    BIND_PREFIX = "bind",
    PARAMETER_PREFIX = "prm",
    LINE_ITEM_PREFIX = "litem",
    SUBJECTIVITY_PREFIX = "subj",
    POLICY_ALLOCATION = "policy.number",
    POLICY_ID = "policy.id",
}

type ApplicationAction =
    | {
          action: "SetProductVersion";
          productVersion: ProductVersion | undefined;
      }
    | {
          action: "ToggleOpenCloseSection";
          code: string;
          status: boolean | undefined;
      }
    | { action: "OpenAllSections" }
    | { action: "CloseAllSections" };

interface ApplicationState {
    productVersion: ProductVersion | undefined;
    toggle: { [key: string]: boolean };
}

interface ApplicationProviderInterface {
    applicationState: ApplicationState;
    applicationDispatch: Dispatch<ApplicationAction>;
    allowEditing: boolean;
}

const ApplicationContext = createContext<
    ApplicationProviderInterface | undefined
>(undefined);

const useApplication = () => {
    const context = useContext(ApplicationContext);

    if (context === undefined) {
        throw Error(
            "useApplication must be used inside a ApplicationProvider context"
        );
    }

    return context;
};

const ApplicationProvider: FC<PropsWithChildren> = ({ children }) => {
    const { element } = usePage();
    const productVersion = element as unknown as ProductVersion | undefined;

    const reducer = (draft: ApplicationState, action: ApplicationAction) => {
        switch (action.action) {
            case "SetProductVersion": {
                draft.productVersion = action.productVersion;
                productVersion?.schema.spec.sections.forEach(
                    (section) =>
                        (draft.toggle[section.code] =
                            draft.toggle[section.code] || false)
                );
                break;
            }
            case "ToggleOpenCloseSection": {
                draft.toggle[action.code] =
                    action.status || !draft.toggle[action.code];
                break;
            }
            case "OpenAllSections": {
                Object.keys(draft.toggle).map(
                    (item) => (draft.toggle[item] = true)
                );
                break;
            }
            case "CloseAllSections": {
                Object.keys(draft.toggle).map(
                    (item) => (draft.toggle[item] = false)
                );
                break;
            }
        }
    };

    const [applicationState, applicationDispatch] = useImmerReducer<
        ApplicationState,
        ApplicationAction
    >(reducer, {
        productVersion: undefined,
        toggle: {},
    });

    useEffect(() => {
        const getProductVersion = async () => {
            applicationDispatch({
                action: "SetProductVersion",
                productVersion: productVersion,
            });
        };
        getProductVersion();
    }, [applicationDispatch, productVersion]);

    if (!productVersion) {
        return <></>;
    }

    return (
        <ApplicationContext.Provider
            value={{
                applicationState,
                applicationDispatch,
                allowEditing: !productVersion.is_published,
            }}
        >
            {children}
        </ApplicationContext.Provider>
    );
};

export { ApplicationProvider, useApplication, CodePrefixes };
