import { Page } from "components/Page";
import { useApi } from "contexts/ApiProvider";
import { FC, useCallback, useEffect, useState } from "react";
import { MainPane } from "components/MainPane";
import EmptyPage from "pages/underwriter/quotes_and_policies/components/quote-and-policy-sections/EmptyPage";
import { GetTraceLogs200Response } from "@joshuins/system";
import { PaginationFooter } from "components/Pagination";
import { formatDatetimeString } from "utils/datetime";
import { useNavigate, useParams } from "react-router-dom";
import urlJoin from "url-join";
import "./Traces.scss";

const Main = () => {
    const { sdkSystem } = useApi();
    const { traceId } = useParams();
    const navigate = useNavigate();
    const [traceLogs, setTraceLogs] = useState<GetTraceLogs200Response>();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [perPage, setPerPage] = useState<number>(10);

    const loadTraces = useCallback(
        async (_page: number) => {
            if (!traceId) {
                navigate(urlJoin("/", "admin", "system", "traces"));
                return;
            }
            const traceslogs_ = await sdkSystem.getTraceLogs({
                _per_page: perPage,
                _page,
                id: traceId,
            });
            setTraceLogs(traceslogs_);
            setCurrentPage(_page);
        },
        [navigate, perPage, sdkSystem, traceId]
    );
    useEffect(() => {
        const getTraces = async () => {
            loadTraces(currentPage);
        };
        getTraces();
    }, [currentPage, loadTraces, sdkSystem]);

    const setPageSize = useCallback((perPage_: string) => {
        setPerPage(parseInt(perPage_));
        setCurrentPage(1);
    }, []);

    if (!traceLogs) {
        return <></>;
    }

    return (
        <MainPane
            title={"Traces Logs"}
            layoutConfig={{
                mainLayout:
                    !traceLogs || traceLogs.items.length === 0
                        ? "center"
                        : "wide",
            }}
        >
            {traceLogs.items.length > 0 ? (
                <>
                    <div className="trace-logs">
                        {traceLogs.items.map((traceLog) => {
                            if ("Error" in traceLog.data) {
                                return (
                                    <div className="log-line" key={traceLog.id}>
                                        <div className="log-level">
                                            <div className="log-level-error">
                                                ERROR
                                            </div>
                                        </div>
                                        <div className="log-timestamp">
                                            {formatDatetimeString(
                                                traceLog.created_at,
                                                "withTime"
                                            )}
                                        </div>
                                        <div className="log-content">
                                            {traceLog.data.Error}
                                        </div>
                                    </div>
                                );
                            } else if ("Info" in traceLog.data) {
                                return (
                                    <div className="log-line" key={traceLog.id}>
                                        <div className="log-level">
                                            <div className="log-level-info">
                                                INFO
                                            </div>
                                        </div>
                                        <div className="log-timestamp">
                                            {formatDatetimeString(
                                                traceLog.created_at,
                                                "withTime"
                                            )}
                                        </div>
                                        <div className="log-content">
                                            {traceLog.data.Info}
                                        </div>
                                    </div>
                                );
                            } else {
                                return (
                                    <div className="log-line" key={traceLog.id}>
                                        <div className="log-level ">
                                            <div className="log-level-debug">
                                                DEBUG
                                            </div>
                                        </div>
                                        <div className="log-timestamp">
                                            {formatDatetimeString(
                                                traceLog.created_at,
                                                "withTime"
                                            )}
                                        </div>
                                        <div className="log-content">
                                            <div>
                                                {
                                                    traceLog.data.Object
                                                        .description
                                                }
                                            </div>
                                            {/* <div>
                                                {JSON.toString(
                                                    traceLog.data.Object
                                                        .contents
                                                )}
                                            </div> */}
                                        </div>
                                    </div>
                                );
                            }
                        })}
                    </div>
                </>
            ) : (
                <EmptyPage
                    title={"Traces"}
                    text={"No Traces Yet"}
                    icon={"icon-css"}
                />
            )}
            {traceLogs.total_pages > 1 && (
                <PaginationFooter
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    totalPages={traceLogs.total_pages}
                    setPageSize={setPageSize}
                />
            )}
        </MainPane>
    );
};

const TraceLogsPage: FC = () => (
    <Page>
        <Main />
    </Page>
);

export default TraceLogsPage;
