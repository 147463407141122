import { zodResolver } from "@hookform/resolvers/zod";
import FormControl from "@mui/material/FormControl";
import { Setting, UpdateSetting } from "@joshuins/insurance";
import { Footer, MainPane } from "components/MainPane";
import NotAnchor from "components/NotAnchor";
import { AlertCategory, Page, usePage } from "components/Page";
import { Popup } from "components/Popup";
import { useApi } from "contexts/ApiProvider";
import { usePopup } from "contexts/PopupProvider";
import omit from "lodash/omit";
import { FC, useCallback, useEffect } from "react";
import { Control, useForm } from "react-hook-form";
import { z } from "zod";
import { MenuItem } from "components/Select";
import { Select } from "components/ReactHookFormUncontrolledComponents";
import differenceBy from "lodash/differenceBy";
import sortBy from "lodash/sortBy";
import { asyncMap } from "modern-async";
import keyBy from "lodash/keyBy";
import isEqual from "lodash/isEqual";
import {
    AddOrEditIndustryPopup,
    IndustriesList,
} from "./components/IndustriesList";
import { unpaginate } from "components/sdk";
import { INSURED_OPTIONAL_SETTINGS_APPEARANCE_OPTIONS } from "globals";
import ExtraPane from "components/extra-panes/ExtraPane";
import { Industry } from "@joshuins/system";

const QuestionsToIdentifyInsuredHelpPopup = () => (
    <Popup name="questions-to-identify-insured-help">
        <header>
            <h2 className="color-primary font-medium">
                Questions to Identify Insured
            </h2>
        </header>
        <p className="last-child">
            These questions are asked at the start of all new submissions to
            identify the insured for clearance and eligibility. Name and Address
            are always required. Website and Class of Business can be hidden,
            optional, or required. In addition, for Class of Business, you can
            choose between SIC or NAICS codes.
        </p>
    </Popup>
);

const insuredDetailsSchema = z.object({
    name: z.literal("Required"),
    address: z.literal("Required"),
    website_field_in_insured_page: z.union([
        z.null(),
        z.enum(INSURED_OPTIONAL_SETTINGS_APPEARANCE_OPTIONS, {
            required_error: "This field is required",
        }),
    ]),
    class_of_business_field_in_insured_page: z.union([
        z.null(),
        z.enum(INSURED_OPTIONAL_SETTINGS_APPEARANCE_OPTIONS, {
            required_error: "This field is required",
        }),
    ]),
    industries: z
        .object({
            id: z.number(),
            code: z.string().min(1),
            title: z.string().min(1),
            unique_id: z.string().uuid(),
        })
        .array(),
});

type InsuredDetailsType = z.infer<typeof insuredDetailsSchema>;

const InsuredDetailsPopups: FC = () => (
    <>
        <QuestionsToIdentifyInsuredHelpPopup />
        <AddOrEditIndustryPopup />
    </>
);

const Main: FC = () => {
    const { sdkSystem, sdkInsurance } = useApi();
    const { addAlertMessages, tryCatchAndRaiseError } = usePage();
    const { openPopup } = usePopup();

    const {
        handleSubmit,
        control: control_,
        reset,
        formState: { errors, isDirty, isValid },
    } = useForm<InsuredDetailsType>({
        resolver: zodResolver(insuredDetailsSchema),
        defaultValues: {
            name: "Required",
            address: "Required",
            website_field_in_insured_page: undefined,
            class_of_business_field_in_insured_page: undefined,
            industries: [],
        },
    });
    const control = control_ as unknown as Control; // @TODO: this is really bad and has to be fixed with making <FormPopup> and <Select> support generics

    const transformApiDataToFormData = useCallback(
        (settings: Setting, industries: Industry[]): InsuredDetailsType => {
            return {
                name: "Required",
                address: "Required",
                industries,
                ...omit(settings, ["id"]),
            };
        },
        []
    );

    const transformFormDataToApiData = useCallback(
        (
            formData: InsuredDetailsType
        ): { settings: UpdateSetting; industries: Industry[] } => {
            return {
                settings: omit(formData, [
                    "id",
                    "name",
                    "address",
                    "industries",
                ]),
                industries: formData.industries,
            };
        },
        []
    );

    const updateIndustries = useCallback(
        async (industries: Industry[]): Promise<Industry[]> => {
            const currentIndustries = await unpaginate(
                sdkSystem.allIndustries,
                {}
            );
            const industryLookup = keyBy(industries, "id");

            const updatedCurrentIndustries = await Promise.all(
                currentIndustries.map((currentIndustry) => {
                    const industry = industryLookup[currentIndustry.id];
                    if (isEqual(industry, currentIndustry)) {
                        return industry;
                    } else {
                        return sdkSystem.updateIndustry({
                            id: industry.id,
                            UpdateIndustry: omit(industry, ["id"]),
                        });
                    }
                })
            );

            const industriesToAdd = differenceBy(
                industries,
                currentIndustries,
                "id"
            );

            const addedIndustries = await asyncMap(
                industriesToAdd,
                async (industry) =>
                    await sdkSystem.createIndustry({
                        NewIndustry: omit(industry, ["id"]),
                    }),
                Number.POSITIVE_INFINITY
            );

            return sortBy(updatedCurrentIndustries.concat(addedIndustries), [
                "id",
            ]);
        },
        [sdkSystem]
    );

    const onSubmit_ = async (data: InsuredDetailsType) => {
        tryCatchAndRaiseError(async () => {
            const { settings, industries } = transformFormDataToApiData(data);
            const [updatedSettings, updatedIndustries] = await Promise.all([
                sdkInsurance.updateClearanceSettings({
                    UpdateSetting: settings,
                }),
                updateIndustries(industries),
            ]);

            reset(
                await transformApiDataToFormData(
                    updatedSettings,
                    updatedIndustries
                )
            );
            addAlertMessages({
                message: "Insured details were saved!",
                category: AlertCategory.SUCCESS,
            });
        });
    };

    useEffect(() => {
        const getSettings = async () => {
            const [settings, industries] = await Promise.all([
                sdkInsurance.getClearanceSettings(),
                unpaginate(sdkSystem.allIndustries, {}),
            ]);
            reset(
                transformApiDataToFormData(settings, sortBy(industries, ["id"]))
            );
        };

        getSettings();
    }, [reset, sdkInsurance, sdkSystem, transformApiDataToFormData]);

    return (
        <MainPane title="Insured Details">
            <div>
                <form
                    className="form-validate"
                    onSubmit={handleSubmit(onSubmit_)}
                >
                    <div className="form-box aside">
                        <h2 className="size-18 m25 color-primary">
                            Questions to Identify Insured{" "}
                            <NotAnchor
                                className="text-right"
                                onClick={() => {
                                    openPopup(
                                        "questions-to-identify-insured-help"
                                    );
                                }}
                            >
                                <i aria-hidden="true" className="icon-help" />{" "}
                                <span className="hidden">More info</span>
                            </NotAnchor>
                        </h2>
                        <p>
                            <label htmlFor="msa">Name</label>
                            <input
                                type="text"
                                id="msa"
                                name="msa"
                                value="Required"
                                readOnly
                            />
                        </p>
                        <p>
                            <label htmlFor="msb">Address</label>
                            <input
                                type="text"
                                id="msb"
                                name="msb"
                                value="Required"
                                readOnly
                            />
                        </p>
                        <div className="div-as-p">
                            <span className="label">Website</span>
                            <FormControl
                                error={!!errors.website_field_in_insured_page}
                            >
                                <Select
                                    labelId="msc-label"
                                    id="msd"
                                    name="website_field_in_insured_page"
                                    control={control}
                                    sx={{
                                        width: "156px",
                                    }}
                                >
                                    {INSURED_OPTIONAL_SETTINGS_APPEARANCE_OPTIONS.map(
                                        (value) => (
                                            <MenuItem key={value} value={value}>
                                                {value}
                                            </MenuItem>
                                        )
                                    )}
                                </Select>
                                {errors.website_field_in_insured_page && (
                                    <label className="error" htmlFor="msc">
                                        {
                                            errors.website_field_in_insured_page
                                                .message
                                        }
                                    </label>
                                )}
                            </FormControl>
                        </div>
                        <div className="div-as-p">
                            <span className="label">Class of Business</span>
                            <FormControl
                                error={
                                    !!errors.class_of_business_field_in_insured_page
                                }
                            >
                                <Select
                                    labelId="msd-label"
                                    id="msd"
                                    name="class_of_business_field_in_insured_page"
                                    control={control}
                                    sx={{
                                        width: "156px",
                                    }}
                                >
                                    {INSURED_OPTIONAL_SETTINGS_APPEARANCE_OPTIONS.map(
                                        (value) => (
                                            <MenuItem key={value} value={value}>
                                                {value}
                                            </MenuItem>
                                        )
                                    )}
                                </Select>
                                {errors.class_of_business_field_in_insured_page && (
                                    <label className="error" htmlFor="msd">
                                        {
                                            errors
                                                .class_of_business_field_in_insured_page
                                                .message
                                        }
                                    </label>
                                )}
                            </FormControl>
                        </div>
                        <div>
                            <p className="label">Classes of Business</p>
                            <IndustriesList
                                name="industries"
                                control={control}
                            />
                        </div>
                    </div>
                    <Footer>
                        <p className="link-btn">
                            <button
                                type="submit"
                                disabled={!isDirty || !isValid}
                            >
                                <i
                                    aria-hidden="true"
                                    className="icon-check-circle-outline"
                                />{" "}
                                Save{" "}
                                <span className="mobile-hide">Settings</span>
                            </button>
                        </p>
                    </Footer>
                </form>
            </div>
        </MainPane>
    );
};

const InsuredDetailsExtraPane = () => (
    <ExtraPane>
        <ul className="list-icon b">
            <li>
                <i className="icon-user-circle" /> Insured Details{" "}
                <span>
                    Use these settings to define what information you need to
                    collect about the insured when a new submission is started.
                </span>
            </li>
        </ul>
    </ExtraPane>
);

const InsuredDetailsPage: FC = () => {
    return (
        <Page>
            <Main />
            <InsuredDetailsExtraPane />
            <InsuredDetailsPopups />
        </Page>
    );
};

export default InsuredDetailsPage;
