/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { FC, useEffect } from "react";
import FileDownload from "components/FileDownload";
import { Page } from "components/Page";
import { DataGrid } from "@mui/x-data-grid/DataGrid";
import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import Box from "@mui/material/Box";
import { useImmer } from "use-immer";
import { useApi } from "contexts/ApiProvider";
import type { ExportDump } from "@joshuins/system";
import { formatDatetimeString } from "utils/datetime";
import { textCenterEmptyTab } from "paul/native-dom-manipulation";

const dateFormatter = (dateValue: string) => {
    try {
        return formatDatetimeString(dateValue, "withTime");
    } catch (error) {
        return "";
    }
};

const columns: GridColDef[] = [
    {
        field: "file_id",
        headerName: "",
        width: 100,
        sortable: false,
        renderCell: (params) => (
            <FileDownload fileIds={[params?.value]}>Download</FileDownload>
        ),
    },
    {
        field: "created_at",
        headerName: "Created At",
        width: 150,
        sortable: false,
        valueFormatter: (value: string) => dateFormatter(value),
    },
    {
        field: "range_start",
        headerName: "Range Start",
        width: 150,
        sortable: false,
        valueFormatter: (value: string) => dateFormatter(value),
    },
    {
        field: "range_end",
        headerName: "Range End",
        width: 150,
        sortable: false,
        valueFormatter: (value: string) => dateFormatter(value),
    },
    {
        field: "create_status",
        headerName: "Create Status",
        width: 150,
        sortable: false,
    },
    {
        field: "sync_status",
        headerName: "Sync Status",
        width: 150,
        sortable: false,
    },
    {
        field: "error",
        headerName: "Error",
        width: 150,
        sortable: false,
    },
];

const pageSizeOptions = [10, 25, 50, 100];

const Main = () => {
    const [dataGridState, setDataGridState] = useImmer<{
        page: number;
        pageSize: number;
        rows: ExportDump[];
        rowCount: number;
    }>({
        page: 0,
        pageSize: pageSizeOptions[0],
        rows: [],
        rowCount: 0,
    });

    const { sdkSystem } = useApi();

    useEffect(() => {
        const getNotifications = async () => {
            const exports = await sdkSystem.allExportDumps({
                _page: dataGridState.page + 1,
                _per_page: dataGridState.pageSize,
            });
            setDataGridState((draft) => {
                draft.rows = exports.items;
                draft.rowCount = exports.total_items;
            });
        };
        getNotifications();
    }, [
        sdkSystem,
        dataGridState.page,
        dataGridState.pageSize,
        setDataGridState,
    ]);

    useEffect(() => {
        textCenterEmptyTab(false);
    }, []);

    return (
        <Box
            sx={{ width: "100%" }}
            css={css`
                & p {
                    margin-bottom: 0px !important;
                }
            `}
        >
            <DataGrid
                {...dataGridState}
                pagination
                paginationMode="server"
                columns={columns}
                pageSizeOptions={pageSizeOptions}
                onPaginationModelChange={({ page, pageSize }) => {
                    setDataGridState((draft) => {
                        draft.page = page;
                        draft.pageSize = pageSize;
                    });
                }}
            />
        </Box>
    );
};

const DataExportEventsTab: FC = () => (
    <Page>
        <Main />
    </Page>
);

export default DataExportEventsTab;
