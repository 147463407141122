import { usePage } from "components/Page";
import { useApi } from "contexts/ApiProvider";
import { FC, useCallback } from "react";
import { useInsuranceProcess } from "../InsuranceProcessProvider";
import NotAnchor from "components/NotAnchor";
import { ConfirmationPopup } from "components/Popup";
import { usePopup } from "contexts/PopupProvider";
import { waitForProcessingInsuranceObject } from "../../util";
import urlJoin from "url-join";
import { useNavigate } from "react-router-dom";
import { useBranding } from "contexts/BrandingProvider";
import { QuoteStatus } from "@joshuins/insurance";

const IssueEndorsementLink: FC = () => {
    const { openPopup } = usePopup();

    return (
        <NotAnchor
            onClick={() => {
                openPopup("issue-endorsement");
            }}
        >
            <i aria-hidden="true" className="icon-check-circle-outline" /> Issue{" "}
            <span className="mobile-hide">Endorsement</span>
            <span className="scheme-accesskey">
                <span>Shift</span> <span>q</span>
            </span>
        </NotAnchor>
    );
};

const IssueEndorsementPopup: FC = () => {
    const { sdkInsurance } = useApi();
    const { tryCatchAndRaiseError, refreshElement } = usePage();
    const {
        itemGetters: { getInsuranceProcess },
    } = useInsuranceProcess();
    const { quote } = getInsuranceProcess();
    const navigate = useNavigate();
    const { generateUrl } = useBranding();

    const onSubmit = useCallback(async () => {
        tryCatchAndRaiseError(async () => {
            await sdkInsurance.updateQuote({
                id: quote.id,
                UpdateQuote: { status: QuoteStatus.CoverageActive },
            });
            await waitForProcessingInsuranceObject(
                sdkInsurance,
                quote,
                "Issuing Endorsement..."
            );
            navigate(
                generateUrl(urlJoin("/", "policies", quote.id.toString()))
            );
        }, refreshElement);
    }, [
        generateUrl,
        navigate,
        quote,
        refreshElement,
        sdkInsurance,
        tryCatchAndRaiseError,
    ]);

    return (
        <ConfirmationPopup
            name="issue-endorsement"
            onSubmit={onSubmit}
            submitText="Issue Endorsement"
            mobileSubmitText="Issue"
        >
            <header>
                <header>
                    <h2>Are you sure?</h2>
                    <p className="size-20">
                        We will issue this endorsement and notify any recipients
                        via email
                    </p>
                </header>
            </header>
        </ConfirmationPopup>
    );
};

export { IssueEndorsementLink, IssueEndorsementPopup };
