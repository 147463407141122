import { FC, useCallback, useEffect } from "react";
import axios from "axios";
import omit from "lodash/omit";
import { z } from "zod";
import { NewOffice, Office, UpdateOffice } from "@joshuins/system";
import { GoogleMapsAutocomplete } from "components/ReactHookFormUncontrolledComponents";
import { zodField as googleMapsAutocompleteZodField } from "components/GoogleMapsAutocomplete";
import { AlertCategory, usePage } from "components/Page";
import { createFormPopup } from "components/Popup";
import { useApi } from "contexts/ApiProvider";
import { usePopup } from "contexts/PopupProvider";
import { getMessageFromAxiosError } from "utils/axios-extras";

const addOrEditOfficeSchema = z.object({
    name: z.string().min(1, "This field is required"),
    address: z.union([googleMapsAutocompleteZodField, z.string()]).optional(),
    broker_code: z.string().min(1, "This field is required"),
});

type AddOrEditOfficeType = z.infer<typeof addOrEditOfficeSchema>;

const {
    FormPopup: AddOrEditOfficeFormPopup,
    useFormReturnRef: useAddOrEditOfficeFormReturnRef,
} = createFormPopup(addOrEditOfficeSchema);

const AddOrEditOfficePopup: FC = () => {
    const { sdkSystem } = useApi();
    const { addAlertMessages, refreshState } = usePage();
    const { popupData, isPopupOpen } = usePopup();
    const {
        formReturn: { reset },
        formReturnRefCallback,
    } = useAddOrEditOfficeFormReturnRef();

    const office = popupData?.office as Office | undefined;
    const brokerageId = popupData?.brokerageId as number | undefined;

    const transformFormDataToApiDataForCreate = useCallback(
        async (data: AddOrEditOfficeType): Promise<NewOffice | undefined> => {
            if (!brokerageId) {
                return;
            }
            try {
                return {
                    ...omit(data, "address"),
                    brokerage_id: brokerageId,
                };
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    addAlertMessages({
                        message: getMessageFromAxiosError(error),
                        category: AlertCategory.ALERT,
                    });
                } else {
                    throw error;
                }
            }
        },
        [addAlertMessages, brokerageId]
    );

    const transformFormDataToApiDataForUpdate = useCallback(
        async (
            data: AddOrEditOfficeType
        ): Promise<UpdateOffice | undefined> => {
            if (!office) {
                return;
            }
            try {
                if (typeof data.address === "string") {
                    await sdkSystem.updateOfficeData({
                        id: office.id,
                        CreateOfficeData: {
                            data: [
                                {
                                    code: "office.location",
                                    value: {
                                        V1: {
                                            Plain: {
                                                Location: JSON.parse(
                                                    data.address
                                                ),
                                            },
                                        },
                                    },
                                },
                            ],
                        },
                    });
                }

                return {
                    ...omit(data, "address"),
                };
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    addAlertMessages({
                        message: getMessageFromAxiosError(error),
                        category: AlertCategory.ALERT,
                    });
                } else {
                    throw error;
                }
            }
        },
        [addAlertMessages, office, sdkSystem]
    );

    const transformApiDataToFormData = useCallback(
        async (office: Office): Promise<AddOrEditOfficeType> => {
            let address: object | undefined;
            const officeData = await sdkSystem.getOfficeData({ id: office.id });
            const locationData = officeData.find(
                (item) => item.code === "office.location"
            );
            if (
                locationData &&
                "Plain" in locationData.value.V1 &&
                "Location" in locationData.value.V1.Plain
            ) {
                address = locationData.value.V1.Plain.Location as object;
            }

            return {
                name: office.name ?? "",
                broker_code: office.broker_code ?? "",
                address: address,
            };
        },
        [sdkSystem]
    );

    useEffect(() => {
        const getOffice = async () => {
            if (!isPopupOpen("add-or-edit-office") || !office || !reset) {
                return;
            }

            reset(await transformApiDataToFormData(office));
        };
        getOffice();
    }, [sdkSystem, isPopupOpen, office, transformApiDataToFormData, reset]);

    const onSubmit = useCallback(
        async (data: AddOrEditOfficeType) => {
            if (!brokerageId) {
                return;
            }
            try {
                if (!office) {
                    const newOffice =
                        await transformFormDataToApiDataForCreate(data);
                    if (!newOffice) {
                        return;
                    }
                    const office = await sdkSystem.createOffice({
                        NewOffice: newOffice,
                    });
                    if (typeof data.address === "string") {
                        try {
                            const location = JSON.parse(data.address);
                            await sdkSystem.updateOfficeData({
                                id: office.id,
                                CreateOfficeData: {
                                    data: [
                                        {
                                            code: "office.location",
                                            value: {
                                                V1: {
                                                    Plain: {
                                                        Location: location,
                                                    },
                                                },
                                            },
                                        },
                                    ],
                                },
                            });
                        } catch (error) {
                            /* empty */
                        }
                    }
                    addAlertMessages({
                        message: `${office.name} has been added.`,
                        category: AlertCategory.SUCCESS,
                    });
                } else {
                    const updateOffice =
                        await transformFormDataToApiDataForUpdate(data);
                    if (!updateOffice) {
                        return;
                    }
                    await sdkSystem.updateOffice({
                        id: office.id,
                        UpdateOffice: updateOffice,
                    });
                    addAlertMessages({
                        message: `${data.name} has been updated`,
                        category: AlertCategory.SUCCESS,
                    });
                }
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    addAlertMessages({
                        message: getMessageFromAxiosError(error),
                        category: AlertCategory.ALERT,
                    });
                } else {
                    throw error;
                }
            } finally {
                refreshState();
            }
        },
        [
            office,
            sdkSystem,
            addAlertMessages,
            refreshState,
            brokerageId,
            transformFormDataToApiDataForCreate,
            transformFormDataToApiDataForUpdate,
        ]
    );

    return (
        <AddOrEditOfficeFormPopup
            name="add-or-edit-office"
            defaultValues={{
                name: "",
                address: {},
                broker_code: "",
            }}
            onSubmit={onSubmit}
            submitText="Save"
            mobileSubmitText="Save"
            formReturnRefCallback={formReturnRefCallback}
            submitOnKeyboardEnterEnabled={false}
        >
            {({ register, control, formState: { errors } }) => {
                return (
                    <>
                        <header>
                            <h2>Office</h2>
                        </header>
                        <p>
                            <label htmlFor="ppc">Office Name</label>
                            <input type="text" id="ppc" {...register("name")} />
                            {errors.name && (
                                <label
                                    id="ppc-error"
                                    className="error"
                                    htmlFor="ppc"
                                >
                                    {errors.name.message}
                                </label>
                            )}
                        </p>
                        <div className="div-as-p">
                            <label htmlFor="_formatted_address">
                                Office Address
                            </label>
                            <GoogleMapsAutocomplete
                                id="_formatted_address"
                                control={control}
                                name="address"
                            />
                            {errors.address && (
                                <label
                                    id="_formatted_address-error"
                                    className="error"
                                    htmlFor="_formatted_address"
                                >
                                    {errors.address?.message}
                                </label>
                            )}
                        </div>
                        <p className="c50">
                            <label htmlFor="ppi">Producer Code</label>
                            <input
                                type="text"
                                id="ppi"
                                {...register("broker_code")}
                            />
                            {errors.broker_code && (
                                <label
                                    id="ppi-error"
                                    className="error"
                                    htmlFor="ppi"
                                >
                                    {errors.broker_code.message}
                                </label>
                            )}
                        </p>
                    </>
                );
            }}
        </AddOrEditOfficeFormPopup>
    );
};

export default AddOrEditOfficePopup;
