type ExpectedErrorType = "alert" | "info";

abstract class ExpectedError extends Error {
    isExpectedError = true;
    type: ExpectedErrorType | undefined;
}

class AlertExpectedError extends ExpectedError {
    type: ExpectedErrorType = "alert";
    constructor(message: string, options?: ErrorOptions) {
        super(message, options);
        Object.setPrototypeOf(this, AlertExpectedError.prototype);
    }
}

class InfoExpectedError extends ExpectedError {
    type: ExpectedErrorType = "info";
    constructor(message: string, options?: ErrorOptions) {
        super(message, options);
        Object.setPrototypeOf(this, AlertExpectedError.prototype);
    }
}

const isExpectedError = (payload: unknown): payload is ExpectedError => {
    return (payload as ExpectedError).isExpectedError === true;
};

const isAlertExpectedError = (
    payload: unknown
): payload is AlertExpectedError => {
    return (
        isExpectedError(payload) && (payload as ExpectedError).type === "alert"
    );
};

const isInfoExpectedError = (
    payload: unknown
): payload is InfoExpectedError => {
    return (
        isExpectedError(payload) && (payload as ExpectedError).type === "info"
    );
};

export {
    AlertExpectedError,
    InfoExpectedError,
    isAlertExpectedError,
    isInfoExpectedError,
};
