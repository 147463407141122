import { FC, useEffect, useState } from "react";
import { useApi } from "contexts/ApiProvider";
import NotAnchor from "components/NotAnchor";
import { createFormPopup } from "components/Popup";
import { MenuItem } from "components/Select";
import { Select } from "components/ReactHookFormUncontrolledComponents";
import FormControl from "@mui/material/FormControl";
import classNames from "classnames";
import urlJoin from "url-join";
import { z } from "zod";
import { SubmitHandler } from "react-hook-form";

import type { StoreChannel } from "@joshuins/builder";

const storeSchema = z.object({
    name: z.string().min(1, "This field is required"),
    url_slug: z
        .string()
        .min(1, "This field is required")
        .regex(
            /^[A-Za-z0-9_-]+$/,
            "URL word can only have letters, numbers, _ and -"
        ),
    channel: z.enum(["Broker", "Direct"], {
        required_error: "This field is required",
    }),
    admin_approval_required: z.literal(true),
});

interface CreateStorePopupProps {
    name: string;
    header: string;
    onSubmit: SubmitHandler<StoreType>;
    initialStoreName?: string;
    initialStoreChannel?: StoreChannel;
}

type StoreType = z.infer<typeof storeSchema>;

const initialStoreDefaultValues: {
    admin_approval_required: true;
    channel: StoreChannel | undefined;
    url_slug: string;
    name: string;
} = {
    admin_approval_required: true,
    channel: undefined,
    url_slug: "",
    name: "",
};

const updateableStoreFields = ["name"];
type UpdateableStoreType = { name: string };

const { FormPopup } = createFormPopup(storeSchema);

const CreateStorePopup: FC<CreateStorePopupProps> = ({
    name,
    header,
    onSubmit,
    initialStoreName,
    initialStoreChannel,
}) => {
    const { sdkSystem } = useApi();
    const [serverUrl, setServerUrl] = useState<string>();

    useEffect(() => {
        const getServerUrl = async () => {
            const { server_url } = await sdkSystem.getSystemInfo();
            setServerUrl(server_url);
        };
        getServerUrl();
    }, [sdkSystem]);

    const initialStoreDefaultValues_ = {
        ...initialStoreDefaultValues,
        ...(initialStoreName && { name: initialStoreName }),
        ...(initialStoreChannel && { channel: initialStoreChannel }),
    };

    return (
        <FormPopup
            name={name}
            defaultValues={initialStoreDefaultValues_}
            onSubmit={onSubmit}
            overlayPopups={[
                <>
                    <h3>Store Name</h3>
                    <p>
                        This is how the store will be listed in Joshu, and
                        referred to in email communications. You can upload any
                        logo for the upper left of the store.
                    </p>
                </>,
                <>
                    <h3>Channel</h3>
                    <p>
                        Choose &quot;Broker&quot; if you have agents acting on
                        behalf of their clients. Choose &quot;Direct&quot; if
                        you want companies to buy directly from you.
                    </p>
                </>,
                <>
                    <h3>Short Name for URL</h3>
                    <p>The URL for your store is:</p>
                    <ul>
                        <li className="strong">
                            {urlJoin(
                                serverUrl ?? "<workspace-name>",
                                "store",
                                "<shortname>"
                            )}
                        </li>
                        <li>
                            For example,
                            alliance.joshuins.com/store/alliancestore
                        </li>
                        <li>
                            The Joshu Workspace name is usually your company
                            name
                        </li>
                    </ul>
                    <p>
                        <span className="strong">Best practices</span> for the
                        shortname are:
                    </p>
                    <ul>
                        <li>No spaces or capital letters</li>
                        <li>
                            Dashes and underscores allowed but not recommended
                        </li>
                        <li>
                            For example &quot;commercialinsurance&quot; over
                            &quot;commercial-insurance&quot; or
                            &quot;commercial_insurance&quot;
                        </li>
                    </ul>
                    <p>
                        Need a <span className="strong">custom link</span>?
                        Enter a temporary name and contact us to learn more
                        about setting it up
                    </p>
                </>,
            ]}
        >
            {({
                openOverlayPopup,
                register,
                control,
                watch,
                formState: { errors },
            }) => (
                <>
                    <header>
                        <h2>{header}</h2>
                    </header>
                    <p
                        className={classNames("color-black-100", {
                            "has-error": errors.name,
                        })}
                    >
                        <label htmlFor="ift">
                            Store Name
                            <NotAnchor
                                onClick={() => openOverlayPopup(0)}
                                className="text-right"
                                data-overlay="ns1"
                            >
                                <i aria-hidden="true" className="icon-help" />
                                <span className="hidden">More info</span>
                            </NotAnchor>
                        </label>
                        <input
                            id="ift"
                            placeholder="For example, 'Alliance Commercial Insurance'..."
                            type="text"
                            {...register("name")}
                        />
                        {errors.name && (
                            <label
                                id="ift-error"
                                className="error"
                                htmlFor="ift"
                            >
                                {errors.name.message}
                            </label>
                        )}
                    </p>
                    {/* This 4px top margin was requested in DEV-2689 */}
                    <p style={{ marginTop: "4px" }}>
                        <span className="cols">
                            <label className="c100" htmlFor="ifu">
                                Short Name for URL
                                <NotAnchor
                                    onClick={() => openOverlayPopup(2)}
                                    className="text-right"
                                    data-overlay="ns3"
                                >
                                    <i
                                        aria-hidden="true"
                                        className="icon-help"
                                    />
                                    <span className="hidden">More info</span>
                                </NotAnchor>
                            </label>
                        </span>
                        <span className="cols align-center">
                            <span
                                className={classNames("c50", {
                                    "has-error": errors.url_slug,
                                })}
                                style={{ width: "100%" }}
                            >
                                <input
                                    id="ifu"
                                    placeholder='For example, "alliancecommercial" ...'
                                    type="text"
                                    aria-invalid="true"
                                    {...register("url_slug")}
                                />
                            </span>
                            {serverUrl && (
                                <span
                                    style={{
                                        color: "var(--black_60)",
                                        fontSize: "12px",
                                    }}
                                >
                                    Full path will be:{" "}
                                    {urlJoin(
                                        serverUrl,
                                        "store",
                                        (!errors.url_slug &&
                                            watch("url_slug")) ||
                                            "<shortname>"
                                    )}
                                </span>
                            )}
                        </span>
                        {errors.url_slug && (
                            <label className="error" htmlFor="ifu">
                                {errors.url_slug.message}
                            </label>
                        )}
                    </p>
                    <div className="cols">
                        <div className="div-as-p c50">
                            <span className="label">
                                Channel
                                <NotAnchor
                                    onClick={() => openOverlayPopup(1)}
                                    className="text-right"
                                    data-overlay="ns2"
                                >
                                    <i
                                        aria-hidden="true"
                                        className="icon-help"
                                    />
                                    <span className="hidden">More info</span>
                                </NotAnchor>
                            </span>
                            <FormControl fullWidth error={!!errors.channel}>
                                <Select
                                    labelId="channel-select-label"
                                    id="ifv"
                                    name="channel"
                                    control={control}
                                >
                                    {["Broker", "Direct"].map((value) => (
                                        <MenuItem key={value} value={value}>
                                            {value}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {errors.channel && (
                                    <label className="error" htmlFor="ifv">
                                        {errors.channel.message}
                                    </label>
                                )}
                            </FormControl>
                        </div>
                    </div>
                    <input
                        {...register("admin_approval_required")}
                        type="hidden"
                    />
                </>
            )}
        </FormPopup>
    );
};

export {
    storeSchema,
    initialStoreDefaultValues,
    updateableStoreFields,
    CreateStorePopup,
};
export type { StoreType, UpdateableStoreType };
