import { AlertCategory, usePage } from "components/Page";
import { ConfirmationPopup } from "components/Popup";
import { useApi } from "contexts/ApiProvider";
import { usePopup } from "contexts/PopupProvider";
import cloneDeep from "lodash/cloneDeep";
import startCase from "lodash/startCase";
import { FC, useCallback } from "react";
import { findIndexesBySectionItemCode } from "utils/product-version";
import { useApplication } from "./ApplicationProvider";

const DeleteDatapointPopup: FC<{
    valueTypeLabel: string;
    popupName: string;
    onSubmitFinally?: () => void;
}> = ({ valueTypeLabel, popupName, onSubmitFinally }) => {
    const { popupData } = usePopup();
    const { sdkBuilder } = useApi();
    const { addAlertMessages, tryCatchAndRaiseError } = usePage();
    const {
        allowEditing,
        applicationDispatch,
        applicationState: { productVersion },
    } = useApplication();
    const datapointCode = popupData?.datapointCode as string | undefined;

    const onSubmit = useCallback(async () => {
        if (!productVersion || !datapointCode || !allowEditing) {
            return;
        }
        tryCatchAndRaiseError(async () => {
            const { sectionIndex, itemIndex: datapointIndex } =
                findIndexesBySectionItemCode(productVersion, datapointCode);
            if (sectionIndex !== undefined && datapointIndex !== undefined) {
                const updatedProductVersion = cloneDeep(productVersion);
                updatedProductVersion.schema.spec.sections[
                    sectionIndex
                ].items.splice(datapointIndex, 1);
                await sdkBuilder.updateProductVersion({
                    id: productVersion.id,
                    UpdateProductVersion: {
                        schema: updatedProductVersion.schema,
                    },
                });
                applicationDispatch({
                    action: "SetProductVersion",
                    productVersion: updatedProductVersion,
                });
                addAlertMessages({
                    message: `${startCase(
                        valueTypeLabel
                    )} deleted successfully`,
                    category: AlertCategory.SUCCESS,
                });
            }
        }, onSubmitFinally);
    }, [
        productVersion,
        datapointCode,
        allowEditing,
        tryCatchAndRaiseError,
        onSubmitFinally,
        sdkBuilder,
        applicationDispatch,
        addAlertMessages,
        valueTypeLabel,
    ]);

    return (
        <ConfirmationPopup
            name={popupName}
            submitText={`Delete ${startCase(valueTypeLabel)}`}
            mobileSubmitText="Delete"
            onSubmit={onSubmit}
        >
            <header>
                <h2>Are you sure?</h2>
                <p className="size-14">
                    This {valueTypeLabel} will be deleted permanently. This
                    action cannot be undone.
                </p>
            </header>
        </ConfirmationPopup>
    );
};

export default DeleteDatapointPopup;
