import { FC, useCallback, useEffect, useState } from "react";
import { useApi } from "contexts/ApiProvider";
import {
    AlertCategory,
    AlertMessageInterface,
    Page,
    usePage,
} from "components/Page";
import CopyLinkButton from "components/CopyLinkButton";
import { Footer, MainPane } from "components/MainPane";
import MenuPopover from "components/MenuPopover";
import NotAnchor from "components/NotAnchor";
import StoreCheckList from "./components/StoreChecklistExtraPane";
import { usePopup } from "contexts/PopupProvider";
import { formatDatetimeString } from "utils/datetime";
import urlJoin from "url-join";
import { z } from "zod";
import axios from "axios";
import { ConfirmationPopup, Popup, createFormPopup } from "components/Popup";
import difference from "lodash/difference";
import { CreateStorePopup } from "./StoreForm";
import { Link, useNavigate } from "components/DevAwareRoutingLink";
import keyBy from "lodash/keyBy";
import compact from "lodash/compact";
import { BUILDER_PATH, STORE_PATH } from "globals";

import type {
    Store,
    NewStore,
    ProductVersion,
    ProductVersionView,
} from "@joshuins/builder";
import { unpaginate } from "components/sdk";
import { getMessageFromAxiosError } from "utils/axios-extras";

interface RemoveStoreProductPopupDataInterface {
    productVersionId: number;
    productId: number;
}

const storesProductsFormSchema = z.object({
    productIds: z.string().regex(/^\d+$/).array(),
});

type StoresProductsFormType = z.infer<typeof storesProductsFormSchema>;

const { FormPopup, useFormReturnRef } = createFormPopup(
    storesProductsFormSchema
);

const StoresProductsPopup: FC = () => {
    const { sdkBuilder } = useApi();
    const {
        addAlertMessages,
        element,
        refreshState,
        stateId,
        tryCatchAndRaiseError,
    } = usePage();
    const [latestProductVersions, setLatestProductVersions] =
        useState<ProductVersionView[]>();
    const [currentProductIds, setCurrentProductIds] = useState<number[]>();
    const store = element as unknown as Store | undefined;
    const {
        formReturn: { reset },
        formReturnRefCallback,
    } = useFormReturnRef();

    useEffect(() => {
        const getLatestProductVersions = async () => {
            if (!store || !reset) {
                return;
            }
            const latestProductVersions_ =
                await sdkBuilder.getLatestProductVersions();
            setLatestProductVersions(latestProductVersions_);

            const storeProducts = await unpaginate(
                sdkBuilder.allStoreProducts,
                {
                    store_id: store.id,
                }
            );
            const currentProductIds_ = storeProducts.map(
                ({ product_id }) => product_id
            );
            setCurrentProductIds(currentProductIds_);
            reset({
                productIds: currentProductIds_.map((id) => id.toString()),
            });
        };
        getLatestProductVersions();
    }, [sdkBuilder, store, stateId, reset]);

    const onSubmit = useCallback(
        async (data: StoresProductsFormType) => {
            const newProductIds = data.productIds.map((id) => parseInt(id));
            if (!currentProductIds || !store || !reset) {
                return;
            }
            tryCatchAndRaiseError(async () => {
                const productIdsToAdd = difference(
                    newProductIds,
                    currentProductIds
                );

                const productIdsToRemove = difference(
                    currentProductIds,
                    newProductIds
                );

                for (const productId of productIdsToAdd) {
                    await sdkBuilder.createStoreProduct({
                        NewStoreProduct: {
                            product_id: productId,
                            store_id: store.id,
                        },
                    });
                }

                for (const productId of productIdsToRemove) {
                    await sdkBuilder.deleteStoreProduct({
                        product_id: productId,
                        store_id: store.id,
                    });
                }

                setCurrentProductIds(newProductIds);
                reset({
                    productIds: newProductIds.map((id) => id.toString()),
                });

                addAlertMessages({
                    message: "Successfully updated the products in this store",
                    category: AlertCategory.SUCCESS,
                });
                refreshState();
            });
        },
        [
            currentProductIds,
            store,
            reset,
            tryCatchAndRaiseError,
            addAlertMessages,
            refreshState,
            sdkBuilder,
        ]
    );

    return (
        <FormPopup
            name="add-product"
            defaultValues={{
                productIds: [],
            }}
            onSubmit={onSubmit}
            formReturnRefCallback={formReturnRefCallback}
            resetToDefaultValuesOnClose={false}
        >
            {({ register }) => (
                <>
                    <header>
                        <h2>Add product to store</h2>
                        <p className="size-14">
                            Add Drafts for admins and underwriters to test. Add
                            Published products to make them live for brokers
                            &amp; insureds.
                        </p>
                    </header>
                    {latestProductVersions &&
                        latestProductVersions.length > 0 && (
                            <ul className="check hr font-medium">
                                {latestProductVersions.map((productVersion) => (
                                    <li key={productVersion.product_id}>
                                        <input
                                            type="checkbox"
                                            id={`tda-${productVersion.product_id}`}
                                            value={productVersion.product_id}
                                            {...register("productIds")}
                                        />
                                        <label
                                            htmlFor={`tda-${productVersion.product_id}`}
                                        >
                                            {productVersion.name}
                                        </label>
                                        <span
                                            className={`scheme-box color-${
                                                productVersion.is_published
                                                    ? "pear"
                                                    : "gold"
                                            }`}
                                        >
                                            {productVersion.is_published
                                                ? "Published"
                                                : "Draft"}
                                        </span>
                                    </li>
                                ))}
                            </ul>
                        )}
                </>
            )}
        </FormPopup>
    );
};

const RemoveStoreProductPopup = () => {
    const { sdkBuilder } = useApi();
    const { addAlertMessages, refreshState, element, tryCatchAndRaiseError } =
        usePage();
    const [productVersion, setProductVersion] = useState<ProductVersion>();
    const store = element as unknown as Store | undefined;
    const { popupData, isPopupOpen } = usePopup();
    const popupData_ =
        popupData as unknown as RemoveStoreProductPopupDataInterface;

    useEffect(() => {
        const getProductVersion = async () => {
            if (!isPopupOpen("remove-store-product-confirm") || !popupData_) {
                return;
            }
            const productVersion_ = await sdkBuilder.getProductVersion({
                id: popupData_.productVersionId,
            });
            setProductVersion(productVersion_);
        };
        getProductVersion();
    }, [sdkBuilder, popupData_, isPopupOpen]);

    const onSubmit = useCallback(async () => {
        if (!popupData_ || !store) {
            return;
        }
        tryCatchAndRaiseError(async () => {
            await sdkBuilder.deleteStoreProduct({
                product_id: popupData_.productId,
                store_id: store.id,
            });

            const productVersionName =
                productVersion?.schema.metadata.name ?? "";

            addAlertMessages({
                message: `${productVersionName}${
                    productVersionName ? " " : ""
                }product was successfully removed from ${store.name} store`,
                category: AlertCategory.SUCCESS,
            });
        }, refreshState);
    }, [
        popupData_,
        store,
        tryCatchAndRaiseError,
        refreshState,
        sdkBuilder,
        productVersion?.schema.metadata.name,
        addAlertMessages,
    ]);

    return (
        <ConfirmationPopup
            name="remove-store-product-confirm"
            onSubmit={onSubmit}
            submitText="Remove"
        >
            <header>
                <h2>Are you sure?</h2>
                <p className="size-14">
                    The product will be removed from this store only. Other
                    stores are not affected, and you can add it again at any
                    time.
                </p>
            </header>
        </ConfirmationPopup>
    );
};

const ProductsInfoPopup: FC = () => (
    <Popup name="products-info">
        <h2 className="m25 color-primary">Products</h2>
        <p className="last-child">
            This is a list of all products in the store. &quot;Drafts&quot;
            added to a store for testing are only visible to admins and
            underwriters. &quot;Published&quot; products added to a store are
            live and ready to receive submissions.
        </p>
    </Popup>
);

const Main = () => {
    const { sdkBuilder, sdkSystem } = useApi();
    const { element } = usePage();
    const { openPopup } = usePopup();
    const [latestStoreProductVersions, setLatestStoreProductVersions] =
        useState<ProductVersionView[]>();
    const [storeUsersCounter, setStoreUsersCounter] = useState<number>();
    const { addAlertMessages, stateId } = usePage();
    const [serverUrl, setServerUrl] = useState<string>();

    const store = element as unknown as Store | undefined;

    useEffect(() => {
        const getStoreUsersCounter = async () => {
            if (store) {
                const numStoreUsers = (
                    await sdkBuilder.allStoreUsers({
                        store_id: store.id,
                    })
                ).total_items;
                setStoreUsersCounter(numStoreUsers);
            }
        };
        getStoreUsersCounter();
    }, [sdkBuilder, store, storeUsersCounter]);

    useEffect(() => {
        const getLatestStoreProductVersions = async () => {
            if (store) {
                const storeProducts = await unpaginate(
                    sdkBuilder.allStoreProducts,
                    {
                        store_id: store.id,
                    }
                );
                const latestProductVersions =
                    await sdkBuilder.getLatestProductVersions();
                const allLatestProductVersionsLookup = keyBy(
                    latestProductVersions,
                    "product_id"
                );

                // The compact() is here because a product_id in storeProducts might not actually be in allLatestProductVersionsLookup.
                // It is possible to get to this state if a product version is updated to be part of another product and its original
                // product is left without product versions. In which case
                // allLatestProductVersionsLookup[storeProduct.product_id]
                // will be undefined. So compact() removes this undefined
                const allLatestProductVersions = compact(
                    storeProducts.map(
                        (storeProduct) =>
                            allLatestProductVersionsLookup[
                                storeProduct.product_id
                            ]
                    )
                );
                setLatestStoreProductVersions(allLatestProductVersions);
            }
        };
        getLatestStoreProductVersions();
    }, [sdkBuilder, store, stateId]);

    useEffect(() => {
        const getServerUrl = async () => {
            const { server_url } = await sdkSystem.getSystemInfo();
            setServerUrl(server_url);
        };
        getServerUrl();
    }, [sdkSystem]);

    const links = [
        {
            subpath: "access",
            icon: "id",
            label: "Access",
            subtext:
                storeUsersCounter !== undefined
                    ? `${storeUsersCounter} People`
                    : "",
        },
        {
            subpath: "theme",
            icon: "brand",
            label: "Theme",
            subtext: store ? (store.theme_id ? "Custom" : "Default") : "",
        },
        {
            subpath: "settings",
            icon: "cog",
            label: "Settings",
            subtext: "Default",
        },
    ];

    if (latestStoreProductVersions === undefined) {
        return <></>;
    }

    return (
        <MainPane
            title={store?.name || ""}
            layoutConfig={{
                headerPosition: "inside-content",
                headerClass: "product",
            }}
            subHeader={{
                items: store && [
                    store.last_updated &&
                        `Modified ${formatDatetimeString(
                            store.last_updated,
                            "numericDateOnly"
                        )}`,
                ],
                pill: store && {
                    color: "pear",
                    text: "Active",
                },
            }}
        >
            <ul className="list-products triple">
                {store &&
                    links.map(({ subpath, icon, label, subtext }) => (
                        <li key={subpath}>
                            <Link
                                to={urlJoin(
                                    "/",
                                    BUILDER_PATH,
                                    "stores",
                                    store.id.toString(),
                                    subpath
                                )}
                            >
                                <i
                                    aria-hidden="true"
                                    className={`icon-${icon}`}
                                />
                                {label} <span>{subtext}</span>
                            </Link>
                        </li>
                    ))}
            </ul>
            <h3 className="font-medium">
                Products
                <NotAnchor
                    className="text-right"
                    onClick={() => openPopup("products-info")}
                >
                    <i aria-hidden="true" className="icon-help" />
                    <span className="hidden">Read more</span>
                </NotAnchor>
            </h3>
            {latestStoreProductVersions.length > 0 && (
                <ul className="list-box">
                    {latestStoreProductVersions.map((productVersion, index) => (
                        <MenuPopover
                            key={productVersion.id}
                            menuItems={[
                                {
                                    key: "remove-from-store",
                                    label: "Remove From Store",
                                    icon: "x-circle",
                                    onClick: () => {
                                        openPopup(
                                            "remove-store-product-confirm",
                                            {
                                                productVersionId:
                                                    productVersion.id,
                                                productId:
                                                    productVersion.product_id,
                                            }
                                        );
                                    },
                                },
                            ]}
                            style={{
                                zIndex:
                                    latestStoreProductVersions.length - index,
                            }}
                        >
                            {({ ToggleButton, Menu }) => (
                                <>
                                    <Link
                                        to={urlJoin(
                                            "/",
                                            BUILDER_PATH,
                                            "products",
                                            productVersion.id.toString()
                                        )}
                                    >
                                        {productVersion.name}
                                    </Link>
                                    <span
                                        className={`scheme-box color-${
                                            productVersion.is_published
                                                ? "pear"
                                                : "gold"
                                        }`}
                                    >
                                        {productVersion.is_published
                                            ? "Published"
                                            : "Draft"}
                                    </span>
                                    {Menu}
                                    {ToggleButton}
                                </>
                            )}
                        </MenuPopover>
                    ))}
                </ul>
            )}
            <p className="link-strong" style={{ zIndex: 0 }}>
                <NotAnchor onClick={() => openPopup("add-product")}>
                    <i aria-hidden="true" className="icon-plus-circle" />
                    Add Product
                </NotAnchor>
            </p>
            <Footer>
                <p className="link-btn">
                    <NotAnchor
                        className="inline"
                        onClick={() => openPopup("duplicate-store")}
                    >
                        <i aria-hidden="true" className="icon-copy" />
                        Duplicate
                    </NotAnchor>
                </p>
                <p className="link-btn">
                    {store && store.channel === "Broker" && (
                        <a
                            href={urlJoin("/", STORE_PATH, store.url_slug)}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <i className="icon-cart-window" /> View Store
                        </a>
                    )}
                    {store && store.channel === "Direct" && serverUrl && (
                        <span className="link-strong">
                            <CopyLinkButton
                                link={urlJoin(
                                    serverUrl,
                                    "store",
                                    store.url_slug,
                                    "auth",
                                    "login"
                                )}
                                onClick={() => {
                                    addAlertMessages({
                                        message: "Link copied to clipboard!",
                                        category: AlertCategory.SUCCESS,
                                    });
                                }}
                            >
                                <i className="icon-paperclips" />
                                Copy Link
                            </CopyLinkButton>
                        </span>
                    )}
                </p>
            </Footer>
        </MainPane>
    );
};

const DuplicateStorePopup: FC = () => {
    const { element, addAlertMessages } = usePage();
    const { sdkBuilder } = useApi();
    const originalStore = element as unknown as Store;
    const navigate = useNavigate();

    const onSubmit = useCallback<(store: NewStore) => Promise<void>>(
        async (store: NewStore) => {
            try {
                const newStore = await sdkBuilder.duplicateStore(
                    originalStore.id,
                    store
                );
                const alertMessage: AlertMessageInterface = {
                    message: `${originalStore.name} store was successfully duplicated to ${newStore.name} store`,
                    category: AlertCategory.SUCCESS,
                };
                navigate(
                    urlJoin(
                        "/",
                        BUILDER_PATH,
                        "stores",
                        newStore.id.toString()
                    ),
                    {
                        state: {
                            alertMessages: alertMessage,
                        },
                    }
                );
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    addAlertMessages({
                        message: getMessageFromAxiosError(error),
                        category: AlertCategory.ALERT,
                    });
                } else {
                    throw error;
                }
            }
        },
        [sdkBuilder, navigate, originalStore, addAlertMessages]
    );

    if (!originalStore) {
        return <></>;
    }

    return (
        <CreateStorePopup
            name="duplicate-store"
            header="Duplicate Online Store"
            initialStoreName={originalStore.name}
            initialStoreChannel={originalStore.channel}
            onSubmit={onSubmit}
        />
    );
};

const StoreHomePage: FC = () => (
    <Page>
        <Main />
        <StoreCheckList />
        <StoresProductsPopup />
        <RemoveStoreProductPopup />
        <ProductsInfoPopup />
        <DuplicateStorePopup />
    </Page>
);

export default StoreHomePage;
