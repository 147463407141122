import { SsoProviderMetadata } from "@joshuins/auth";
import PasswordInput from "components/PasswordInput";
import SsoLoginButton from "components/SsoLoginButton";
import { FC } from "react";
import {
    SubmitHandler,
    UseFormHandleSubmit,
    UseFormRegister,
} from "react-hook-form";

type RegistrationFields = {
    email: string;
    first_name: string;
    last_name: string;
    password: string;
};

const RegistrationForm: FC<{
    onSubmit: SubmitHandler<RegistrationFields>;
    handleSubmit: UseFormHandleSubmit<RegistrationFields>;
    googleEnabled: boolean | undefined;
    ssoProviders: SsoProviderMetadata[] | undefined;
    passwordEnabled: boolean | undefined;
    register: UseFormRegister<RegistrationFields>;
    submissionFailedMessage: string | undefined;
}> = ({
    onSubmit,
    handleSubmit,
    googleEnabled,
    ssoProviders,
    passwordEnabled,
    register,
    submissionFailedMessage,
}) => {
    return (
        <form className="form-aside" onSubmit={handleSubmit(onSubmit)}>
            <header className="m25">
                <h2>Complete Registration</h2>
                <p>Sign in using one of the options below</p>
            </header>
            {googleEnabled && <SsoLoginButton oidcSlug="google" />}
            {ssoProviders &&
                ssoProviders.map(({ slug, display_name }) => (
                    <SsoLoginButton
                        key={slug}
                        oidcSlug={slug}
                        displayName={display_name}
                    />
                ))}

            {passwordEnabled && (
                <div className="form-overlay">
                    <div className="cols">
                        <p className="c50">
                            <label htmlFor="fag">First Name</label>
                            <input
                                {...register("first_name")}
                                type="text"
                                id="fag"
                                autoComplete="given-name"
                            />
                        </p>
                        <p className="c50">
                            <label htmlFor="fah">Last Name</label>
                            <input
                                {...register("last_name")}
                                type="text"
                                id="fah"
                                autoComplete="family-name"
                            />
                        </p>
                    </div>
                    <p>
                        <label htmlFor="fai">Email Address</label>
                        <input
                            {...register("email")}
                            readOnly
                            type="text"
                            id="fai"
                            autoComplete="username"
                        />
                    </p>
                    <PasswordInput {...register("password")} id="faj" />
                    <p className="submit">
                        {submissionFailedMessage && (
                            <label id="failed" className="error">
                                {submissionFailedMessage}
                            </label>
                        )}
                        <button type="submit">Sign In</button>
                    </p>
                </div>
            )}
        </form>
    );
};

export default RegistrationForm;
