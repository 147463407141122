import { PolicyViewBody, Quote, Submission } from "@joshuins/insurance";
import { User, UserAccountRole } from "@joshuins/system";
import { Link } from "components/DevAwareRoutingLink";
import { MainPane } from "components/MainPane";
import { useApi } from "contexts/ApiProvider";
import {
    useAvailableProductVersions,
    useCreateSubmissionWizard,
} from "pages/components/use-create-submission-wizard";
import { FC, useEffect, useState } from "react";
import urlJoin from "url-join";
import { fullName } from "utils/user";
import { InsuranceListObject } from "../util";
import orderBy from "lodash/orderBy";
import useAuthUser from "react-auth-kit/dist/hooks/useAuthUser";
import { useBranding } from "contexts/BrandingProvider";
import {
    quoteStatusToText,
    quoteStatusToColor,
    submissionStatusTimeDescription,
} from "pages/components/util";
import EmptyPage from "./quote-and-policy-sections/EmptyPage";
import groupBy from "lodash/groupBy";
import keyBy from "lodash/keyBy";
import { asyncMap } from "modern-async";
import { isBinder, isPolicy } from "utils/policies";
import { formatDatetimeString } from "utils/datetime";

const useListFormatter = (insuranceObject: InsuranceListObject) => {
    const { generateUrl } = useBranding();

    if ("quote" in insuranceObject) {
        const quote = insuranceObject.quote;
        const link = isPolicy(quote) || isBinder(quote) ? "policies" : "quotes";
        return {
            color: quoteStatusToColor(quote.status),
            link: generateUrl(urlJoin("/", link, quote.id.toString())),
            text: `Modified ${formatDatetimeString(quote.modified_at)}`,
            status: quoteStatusToText[quote.status],
        };
    } else {
        return {
            color: "color-wine",
            text: submissionStatusTimeDescription(insuranceObject.submission),
            link: generateUrl(
                urlJoin(
                    "/",
                    "submissions",
                    insuranceObject.submission.id.toString()
                )
            ),
            status: insuranceObject.submission.status,
        };
    }
};

const ListItemComponent: FC<{
    insuranceObject: InsuranceListObject;
    submissionUser: User | undefined;
    title: string;
}> = ({
    title,
    insuranceObject: { submission },
    insuranceObject,
    submissionUser,
}) => {
    const { sdkInsurance } = useApi();
    const [policy, setPolicy] = useState<PolicyViewBody>();
    const authUser = useAuthUser();
    const user = authUser() as User;

    useEffect(() => {
        const getInsureds = async () => {
            let policy = undefined;
            if (submission.policy_id) {
                policy = await sdkInsurance.getPolicy({
                    id: submission.policy_id,
                });
            }
            setPolicy(policy);
        };
        getInsureds();
    }, [sdkInsurance, title, submission, user]);

    const { link, color, text, status } = useListFormatter(insuranceObject);

    return (
        <li>
            <Link to={link ? link : ""}>
                {policy ? policy.insured_name : "Unknown Insured"}
            </Link>
            <ul className="list-inline a">
                <li>
                    {submissionUser
                        ? fullName(submissionUser)
                        : "Unknown Policy Owner"}
                </li>
                <li>{text}</li>
                <li className={`overlay ${color}`}>
                    <span>{status}</span>
                </li>
            </ul>
        </li>
    );
};

const InsuranceObjectList: FC<{
    title: string;
    insuranceObjects: InsuranceListObject[];
    textWhenEmpty: string;
    icon: string;
}> = ({ title, insuranceObjects, textWhenEmpty, icon }) => {
    const { sdkSystem, sdkInsurance } = useApi();
    const authUser = useAuthUser();
    const currentUser = authUser() as User;
    const newSubmissionWizardProceed = useCreateSubmissionWizard();
    const availableProducts = useAvailableProductVersions();
    const [users, setUsers] = useState<Record<string, User>>({});

    useEffect(() => {
        const getUsers = async () => {
            let users_ = [currentUser];
            if (
                currentUser.role === UserAccountRole.Underwriter ||
                currentUser.role === UserAccountRole.Admin
            ) {
                const userIds = groupBy(insuranceObjects, "submission.user_id");
                users_ = await asyncMap(
                    Object.keys(userIds),
                    async (id) => {
                        return await sdkSystem.getUser({ id: parseInt(id) });
                    },
                    Number.POSITIVE_INFINITY
                );
            }
            setUsers(keyBy(users_, "id"));
        };
        getUsers();
    }, [currentUser, insuranceObjects, sdkInsurance, sdkSystem]);

    // sort everything by modified_at
    const insuranceObjectsToSort: {
        modifiedAt?: string;
        quote?: Quote;
        submission: Submission;
    }[] = insuranceObjects.map((item) => {
        return {
            ...item,
            // TODO SHIMI
            //modifiedAt: item.policy.last_modified,
            modifiedAt: item.submission.modified_at,
        };
    });

    const sortedInsuranceObjects = orderBy(
        insuranceObjectsToSort,
        "modifiedAt",
        "desc"
    );

    return (
        <MainPane
            title={title}
            cta={
                availableProducts
                    ? {
                          buttonLabel: "New Submission",
                          callback: newSubmissionWizardProceed,
                      }
                    : undefined
            }
            layoutConfig={{
                headerBottomMargin: true,
                mainLayout:
                    !insuranceObjects || insuranceObjects.length === 0
                        ? "center"
                        : "wide",
            }}
        >
            {insuranceObjects.length > 0 ? (
                <ul
                    className="list-plain box no-img"
                    // style adjusments are here in order to align with the old design (missing line at the top of the list)
                    style={{
                        borderTop: "1px solid var(--black_10)",
                        paddingTop: "10px",
                    }}
                >
                    {sortedInsuranceObjects.map((insuranceObject) => (
                        <ListItemComponent
                            insuranceObject={insuranceObject}
                            submissionUser={
                                insuranceObject.submission.user_id
                                    ? users[insuranceObject.submission.user_id]
                                    : undefined
                            }
                            title={title}
                            key={insuranceObject.submission.id}
                        />
                    ))}
                </ul>
            ) : (
                <EmptyPage title={title} text={textWhenEmpty} icon={icon} />
            )}
        </MainPane>
    );
};

export default InsuranceObjectList;
