import { FC, useCallback, useEffect } from "react";
import { ExportEncryptionKeyType } from "@joshuins/system";
import { useApi } from "contexts/ApiProvider";
import { usePopup } from "contexts/PopupProvider";
import { unpaginate } from "components/sdk";
import { usePage } from "components/Page";
import { ConfirmationPopup, createFormPopup } from "components/Popup";
import { z } from "zod";
import { useDataExport } from "./DataExportProvider";
import { Footer } from "components/MainPane";
import NotAnchor from "components/NotAnchor";
import { textCenterEmptyTab } from "paul/native-dom-manipulation";

const AddKeySchema = z.object({
    content: z.string().min(1, "This field is required"),
});

type AddKeyType = z.infer<typeof AddKeySchema>;

const { FormPopup: AddKeyFormPopup, useFormReturnRef: useAddKeyFormReturnRef } =
    createFormPopup(AddKeySchema);

const AddKeyPopup: FC = () => {
    const { sdkSystem } = useApi();
    const { tryCatchAndRaiseError } = usePage();
    const { dataExportDispatch } = useDataExport();
    const { formReturnRefCallback } = useAddKeyFormReturnRef();

    const onSubmit = useCallback(
        async (data: AddKeyType) => {
            tryCatchAndRaiseError(async () => {
                const newKey = await sdkSystem.createExportEncryptionKey({
                    CreateExportEncryptionKey: {
                        type_: ExportEncryptionKeyType.OpenPgp,
                        content: data.content,
                    },
                });
                dataExportDispatch({
                    action: "AddKey",
                    encryptionKey: newKey,
                });
            });
        },
        [dataExportDispatch, sdkSystem, tryCatchAndRaiseError]
    );

    return (
        <AddKeyFormPopup
            name="add-key"
            defaultValues={{
                content: "",
            }}
            onSubmit={onSubmit}
            submitText="Save"
            mobileSubmitText="Save"
            formReturnRefCallback={formReturnRefCallback}
        >
            {({ register, formState: { errors } }) => (
                <>
                    <header>
                        <h2>Encryption Key</h2>
                    </header>
                    <p className="c50">
                        <label htmlFor="tpb">Content</label>
                        <textarea id="tpb" {...register("content")} />
                        {errors.content && (
                            <label
                                id="tpb-error"
                                className="error"
                                htmlFor="tpb"
                            >
                                {errors.content.message}
                            </label>
                        )}
                    </p>
                </>
            )}
        </AddKeyFormPopup>
    );
};

const DeleteKeyPopup: FC = () => {
    const { popupData } = usePopup();
    const { dataExportDispatch } = useDataExport();
    const { tryCatchAndRaiseError } = usePage();
    const { sdkSystem } = useApi();
    const keyId = popupData?.keyId as string | undefined;

    const onSubmit = async () => {
        if (!keyId) {
            return;
        }
        tryCatchAndRaiseError(async () => {
            await sdkSystem.deleteExportEncryptionKey({ id: keyId });

            dataExportDispatch({
                action: "RemoveKey",
                keyId,
            });
        });
    };

    return (
        <ConfirmationPopup
            name="delete-key"
            submitText="Delete"
            onSubmit={onSubmit}
        >
            <header>
                <h2>Are you sure?</h2>
                <p className="size-14">
                    This export encryption key will be deleted.
                </p>
            </header>
        </ConfirmationPopup>
    );
};

const Main: FC = () => {
    const { sdkSystem } = useApi();
    const { openPopup } = usePopup();
    const {
        dataExportDispatch,
        itemGetters: { allKeys: getAllKeys },
    } = useDataExport();

    const allKeys = getAllKeys();

    useEffect(() => {
        const getKeys = async () => {
            const keys = await unpaginate(
                sdkSystem.allExportEncryptionKeys,
                {}
            );

            dataExportDispatch({
                action: "SetKeys",
                encryptionKeys: keys,
            });
        };

        getKeys();
    }, [dataExportDispatch, sdkSystem]);

    useEffect(() => {
        textCenterEmptyTab(!allKeys || allKeys.length === 0);
    }, [allKeys]);

    return (
        <div className="module-tabs-content">
            <div>
                {allKeys && allKeys.length > 0 ? (
                    <ul className="list-drag draggable ui-sortable">
                        {allKeys.map((encryptionKey) => {
                            return (
                                <li key={encryptionKey.id}>
                                    {encryptionKey.created_at}
                                </li>
                            );
                        })}
                    </ul>
                ) : (
                    <div className="module-success inline">
                        <h2>
                            <i aria-hidden="true" className="icon-keys" /> No
                            Encryption Keys Yet
                        </h2>
                    </div>
                )}
            </div>
            <Footer>
                <p className="link-btn">
                    <NotAnchor
                        onClick={() => {
                            openPopup("add-key");
                        }}
                    >
                        <i aria-hidden="true" className="icon-plus-circle" />
                        Add Encryption Key
                    </NotAnchor>
                </p>
            </Footer>
        </div>
    );
};

const DataExportKeysTab: FC = () => {
    return <Main />;
};

export { DataExportKeysTab, AddKeyPopup, DeleteKeyPopup };
