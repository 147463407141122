import { FC, useEffect, useState } from "react";
import { ConfirmationPopup } from "components/Popup";
import { useApi } from "contexts/ApiProvider";
import { LineItemSchemaV1 } from "@joshuins/insurance";
import { usePopup } from "contexts/PopupProvider";
import { useApplication } from "./ApplicationProvider";
import keyBy from "lodash/keyBy";
import {
    ParameterData,
    parameterReadOnlyFormat,
    parameterTypeToStringAndOptions,
} from "pages/underwriter/quotes_and_policies/components/quote-and-policy-sections/ParameterInput";
import {
    getRawValueAsStringFromJoValue,
    getUserOrRaterValue,
} from "utils/jo-types-and-values";

const INDICATION_QUOTE_POPUP = "indication-quote" as const;
const INDICATION_QUOTE_ERROR_POPUP = "indication-quote-error" as const;

const IndicationQuotePopup: FC = () => {
    const { sdkSystem, sdkInsurance } = useApi();
    const { isPopupOpen } = usePopup();
    const [indicationParameters, setIndicationParameters] =
        useState<ParameterData[]>();
    const [lineItems, setLineItems] = useState<LineItemSchemaV1[]>();
    const {
        itemGetters: { getProductVersion },
    } = useApplication();
    const { popupData } = usePopup();
    const productVersion = getProductVersion();
    const indicationeQuoteId = popupData?.indicationeQuoteId as number;

    useEffect(() => {
        if (!isPopupOpen(INDICATION_QUOTE_POPUP)) {
            return;
        }
        const getParameters = async () => {
            if (!indicationeQuoteId) {
                return;
            }
            const quoteData = await sdkInsurance.getQuoteData({
                id: indicationeQuoteId,
            });
            const quoteCodesAndValuesByCode = keyBy(quoteData, "code");
            const productSchemaParameters =
                productVersion.schema.spec.global_params || [];
            const productSchemaLitemParameters =
                productVersion.schema.spec.line_items.flatMap(
                    (litem) => litem.params
                ) || [];

            const allParameters_: ParameterData[] = [];
            for (const parameter of productSchemaParameters) {
                const matchingObj = quoteCodesAndValuesByCode[parameter.code];
                if (
                    matchingObj &&
                    matchingObj.code.split(".")[1] !== "authority"
                ) {
                    allParameters_.push({
                        schema: parameter,
                        value: matchingObj,
                    });
                }
            }
            for (const litemParameter of productSchemaLitemParameters) {
                const matchingObj =
                    quoteCodesAndValuesByCode[litemParameter.code];
                if (
                    matchingObj &&
                    matchingObj.code.split(".")[1] !== "authority"
                ) {
                    allParameters_.push({
                        schema: litemParameter,
                        value: matchingObj,
                    });
                }
            }
            const indicationParameters = allParameters_.filter(
                (param) => param.schema.show_in_indication === true
            );
            setIndicationParameters(indicationParameters);
            setLineItems(productVersion.schema.spec.line_items);
        };
        getParameters();
    }, [
        sdkSystem,
        sdkInsurance,
        productVersion.schema.spec.global_params,
        productVersion.schema.spec.line_items,
        indicationeQuoteId,
        isPopupOpen,
    ]);

    if (!indicationParameters) {
        return;
    }
    const paramCodeToLitemNameLookup: Record<string, string> = {};
    lineItems?.map((litem) =>
        litem.params.map(
            (param) => (paramCodeToLitemNameLookup[param.code] = litem.name)
        )
    );

    return (
        <ConfirmationPopup
            name={INDICATION_QUOTE_POPUP}
            submitText="Proceed"
            mobileSubmitText="Proceed"
        >
            <header>
                <h2>Premium Indication</h2>
                <p className="size-14">
                    This is a non-binding estimate based on the information you
                    provided and may change due to further details like claims
                    review. Proceed to quote to get a more accurate premium.
                </p>
            </header>
            <div className="cols">
                {indicationParameters.map((parameter) => {
                    const { parameterType } = parameterTypeToStringAndOptions(
                        parameter.schema.type
                    );
                    return (
                        <p className="c50" key={parameter.schema.code}>
                            <label htmlFor={parameter.schema.code}>
                                {parameter.schema.name}{" "}
                                {paramCodeToLitemNameLookup[
                                    parameter.schema.code
                                ]
                                    ? `(${
                                          paramCodeToLitemNameLookup[
                                              parameter.schema.code
                                          ]
                                      })`
                                    : ""}
                            </label>
                            <input
                                readOnly={true}
                                type="text"
                                id={parameter.schema.code}
                                name={parameter.schema.code}
                                className="black-letters"
                                value={parameterReadOnlyFormat(
                                    parameterType,
                                    getRawValueAsStringFromJoValue(
                                        getUserOrRaterValue(parameter.value)
                                    )
                                )}
                            />
                        </p>
                    );
                })}
            </div>
        </ConfirmationPopup>
    );
};

const IndicationQuoteErrorPopup: FC = () => {
    return (
        <ConfirmationPopup
            name={INDICATION_QUOTE_ERROR_POPUP}
            submitText="Proceed"
            mobileSubmitText="Proceed"
        >
            <header>
                <h2> Indication Not Available</h2>
                <p className="size-14">Please continue with quote</p>
            </header>
        </ConfirmationPopup>
    );
};

export {
    IndicationQuotePopup,
    IndicationQuoteErrorPopup,
    INDICATION_QUOTE_POPUP,
    INDICATION_QUOTE_ERROR_POPUP,
};
