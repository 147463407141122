import { FC, useCallback, useEffect, useState } from "react";
import { ProductVersion, Store } from "@joshuins/builder";
import { AlertCategory, usePage } from "components/Page";
import { useApi } from "contexts/ApiProvider";
import { usePopup } from "contexts/PopupProvider";
import { ConfirmationPopup } from "components/Popup";

const REMOVE_STORE_PRODUCT = "remove-store-from-product-confirm" as const;

const RemoveStoreFromProductPopup: FC = () => {
    const { sdkBuilder } = useApi();
    const { addAlertMessages, refreshState, element, tryCatchAndRaiseError } =
        usePage();
    const [store, setStore] = useState<Store>();
    const productVersion = element as unknown as ProductVersion | undefined;
    const { popupData, isPopupOpen } = usePopup();
    const popupData_ = popupData as unknown as { storeId: number };

    useEffect(() => {
        const getStore = async () => {
            if (!isPopupOpen(REMOVE_STORE_PRODUCT) || !popupData_) {
                return;
            }
            const store_ = await sdkBuilder.getStore({
                id: popupData_.storeId,
            });
            setStore(store_);
        };
        getStore();
    }, [sdkBuilder, popupData_, isPopupOpen]);

    const onSubmit = useCallback(async () => {
        if (!popupData_ || !productVersion) {
            return;
        }
        tryCatchAndRaiseError(async () => {
            await sdkBuilder.deleteStoreProduct({
                product_id: productVersion.product_id,
                store_id: popupData_.storeId,
            });

            const storeName = store?.name ?? "";

            addAlertMessages({
                message: `${storeName}${
                    storeName ? " " : ""
                }store was successfully removed from ${
                    productVersion.schema.metadata.name
                } product`,
                category: AlertCategory.SUCCESS,
            });
        }, refreshState);
    }, [
        popupData_,
        productVersion,
        tryCatchAndRaiseError,
        refreshState,
        sdkBuilder,
        store?.name,
        addAlertMessages,
    ]);

    return (
        <ConfirmationPopup
            name={REMOVE_STORE_PRODUCT}
            onSubmit={onSubmit}
            submitText="Delete"
            needsIUnderstandCheckbox
            iUnderstandCheckboxId="confirm-store-from-product-deletion-checkbox"
        >
            {({ iUnderstandCheckbox }) => (
                <>
                    <header>
                        <h2>Are you sure?</h2>
                    </header>
                    <ul className="list-static a">
                        <li>
                            This product will be deleted from{" "}
                            <span className="strong">this store</span> only
                        </li>
                        <li>
                            Eligible quotes, binders, and policies that use this
                            product will <span className="strong">not</span> be
                            affected
                        </li>
                    </ul>
                    <p className="check box">
                        {iUnderstandCheckbox}
                        <label htmlFor="confirm-store-from-product-deletion-checkbox">
                            I understand this will unpublish and delete from
                            this store
                        </label>
                    </p>
                </>
            )}
        </ConfirmationPopup>
    );
};

export default RemoveStoreFromProductPopup;
export { REMOVE_STORE_PRODUCT };
