import {
    createContext,
    FC,
    PropsWithChildren,
    useContext,
    useEffect,
    useState,
} from "react";
import useHotjar from "react-use-hotjar";
import { useApi } from "./ApiProvider";
import { IntercomProvider, useIntercom } from "react-use-intercom";
import MultiProvider from "components/MultiProvider";
import { useAuthUser } from "react-auth-kit";
import { UserServices, User, UserAccountRole } from "@joshuins/auth";

const UserAnalyticsContext = createContext<
    | {
          userServices: UserServices | undefined;
      }
    | undefined
>(undefined);

const useUserAnalytics = () => {
    // Usually we would do the following code before returning the context:
    //
    // const context = useContext(UserAnalyticsContext);
    //
    // if (context === undefined) {
    //     throw Error(
    //         "useUserAnalytics must be used inside a UserAnalyticsProvider context"
    //     );
    // }
    //
    // However, since UserAnalyticsProvider is added conditionally in
    // UserAnalyticsProviderWrapper, the children of UserAnalyticsProviderWrapper
    // that use useUserAnalytics can't know in advance if it has been added or not,
    // so the context might be undefined. It is up to the child to know what to do
    // if it is undefined. For example, the WaitForInitialization component does
    // this.

    return useContext(UserAnalyticsContext);
};

const Hotjar: FC<{ hotjar_id: string | null }> = ({ hotjar_id }) => {
    const { initHotjar } = useHotjar();

    useEffect(() => {
        if (hotjar_id) {
            initHotjar(parseInt(hotjar_id), 6);
        }
    }, [initHotjar, hotjar_id]);

    return <></>;
};

const Intercom: FC<{ intercom_user_hash: string | null }> = ({
    intercom_user_hash,
}) => {
    const { boot: intercomBoot } = useIntercom();
    const authUser = useAuthUser();
    const currentAuthUser = authUser();

    useEffect(() => {
        if (
            currentAuthUser &&
            currentAuthUser.role === UserAccountRole.Admin &&
            intercom_user_hash
        ) {
            const { email, first_name, last_name } = currentAuthUser as User;
            intercomBoot({
                userHash: intercom_user_hash,
                email,
                name: `${first_name} ${last_name}`,
            });
        }
    }, [currentAuthUser, intercomBoot, intercom_user_hash]);

    return <></>;
};

const UserAnalyticsProvider: FC<
    PropsWithChildren<{ userServices: UserServices | undefined }>
> = ({ children, userServices }) => {
    let analyticsElements;
    if (userServices) {
        const { intercom_user_hash } = userServices;
        const intercom_app_id = window.joshuGlobals.INTERCOM_APP_ID;
        const hotjar_id = window.joshuGlobals.HOTJAR_ID;
        analyticsElements = (
            <>
                {intercom_app_id && (
                    <Intercom intercom_user_hash={intercom_user_hash} />
                )}
                <Hotjar hotjar_id={hotjar_id} />
            </>
        );
    }

    return (
        <UserAnalyticsContext.Provider value={{ userServices }}>
            {analyticsElements}
            {children}
        </UserAnalyticsContext.Provider>
    );
};

const UserAnalyticsProviderWrapper: FC<PropsWithChildren> = ({ children }) => {
    const [userServices, setUserServices] = useState<UserServices>();
    const [ready, setReady] = useState<boolean>(false);
    const { sdkAuth, apiAuthProcess } = useApi();

    useEffect(() => {
        const setupUserAnalytics = async () => {
            if (apiAuthProcess.initializeFromStorageAttempted) {
                if (apiAuthProcess.loggedIn) {
                    const userServices = (await sdkAuth.me()).user_services;
                    setUserServices(userServices);
                }
                setReady(true);
            }
        };

        setupUserAnalytics();
    }, [sdkAuth, apiAuthProcess]);

    if (!ready) {
        return <>{children}</>;
    }

    const intercom_app_id = window.joshuGlobals.INTERCOM_APP_ID;

    const providers = [];
    if (intercom_app_id && intercom_app_id.length > 0) {
        providers.push(
            <IntercomProvider
                key="intercom-provider"
                appId={intercom_app_id}
                apiBase="https://api-iam.intercom.io"
                children={undefined} // eslint-disable-line react/no-children-prop
            />
        );
    }
    providers.push(
        <UserAnalyticsProvider
            key="user-analytics-provider"
            userServices={userServices}
        />
    );

    return <MultiProvider providers={providers}>{children}</MultiProvider>;
};

export {
    UserAnalyticsProviderWrapper as UserAnalyticsProvider,
    useUserAnalytics,
};
