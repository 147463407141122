import { usePage } from "components/Page";
import { FC, useCallback, useEffect, useState } from "react";
import { z } from "zod";
import { ConfirmationPopup, createFormPopup } from "components/Popup";
import NotAnchor from "components/NotAnchor";
import { User } from "@joshuins/system";
import { unpaginate } from "components/sdk";
import { useApi } from "contexts/ApiProvider";
import { ProductVersionView, Submission } from "@joshuins/insurance";
import { useAuthUser } from "react-auth-kit";
import { useNavigate } from "components/DevAwareRoutingLink";
import { useBranding } from "contexts/BrandingProvider";
import urlJoin from "url-join";
import { usePopup } from "contexts/PopupProvider";
import { isStoreUser } from "pages/components/util";
import { hideLoader, showLoader } from "paul/native-dom-manipulation";
import compact from "lodash/compact";
import flatten from "lodash/flatten";
import orderBy from "lodash/orderBy";

const DUPLICATE_SUBMISSION_POPUP = "duplicate-submission" as const;

const duplicateSubmissionSchema = z.object({
    product_version_id: z.string(),
});

type DuplicateSubmissionFlow = z.infer<typeof duplicateSubmissionSchema>;

const {
    FormPopup: DuplicateSubmissionFormPopup,
    useFormReturnRef: useDuplicateSubmissionFormReturnRef,
} = createFormPopup(duplicateSubmissionSchema);

const DuplicateSubmissionPopup: FC = () => {
    const authUser = useAuthUser();
    const currentUser = authUser() as User;
    const { isPopupOpen } = usePopup();
    const { generateUrl } = useBranding();
    const navigate = useNavigate();
    const { formReturnRefCallback } = useDuplicateSubmissionFormReturnRef();
    const { sdkInsurance } = useApi();
    const { element, tryCatchAndRaiseError } = usePage();
    const submission = element as unknown as Submission | undefined;
    const [productVersions, setProductVersions] =
        useState<ProductVersionView[]>();

    useEffect(() => {
        if (!isPopupOpen(DUPLICATE_SUBMISSION_POPUP)) {
            return;
        }
        const getProductVersions = async () => {
            if (!isStoreUser(currentUser)) {
                showLoader("Getting products...");
                const products = await unpaginate(sdkInsurance.allProducts, {});
                const productVersions_ = compact(
                    flatten(
                        products.map((product) => [
                            product.latest_draft,
                            product.published,
                        ])
                    )
                );
                setProductVersions(productVersions_);
                hideLoader();
            }
        };
        getProductVersions();
    }, [currentUser, isPopupOpen, sdkInsurance]);

    const onSubmit = useCallback(
        async (data: DuplicateSubmissionFlow) => {
            if (!submission) {
                return;
            }
            tryCatchAndRaiseError(async () => {
                const newSubmission = await sdkInsurance.createSubmission({
                    CreateSubmission: {
                        Duplicate: {
                            submission_id: submission.id,
                            product_version_id: parseInt(
                                data.product_version_id
                            ),
                        },
                    },
                });
                navigate(
                    generateUrl(
                        urlJoin("/", "submissions", newSubmission.id.toString())
                    )
                );
            });
        },
        [generateUrl, navigate, sdkInsurance, submission, tryCatchAndRaiseError]
    );

    if (isStoreUser(currentUser) || !productVersions) {
        return;
    }

    if (productVersions.length === 0) {
        return (
            <ConfirmationPopup
                name={DUPLICATE_SUBMISSION_POPUP}
                submitText="Ok"
                mobileSubmitText="Ok"
            >
                <header>
                    <h2>No Products</h2>
                    <p className="size-14">
                        There are no products in the system yet.
                    </p>
                </header>
            </ConfirmationPopup>
        );
    }

    return (
        <DuplicateSubmissionFormPopup
            name={DUPLICATE_SUBMISSION_POPUP}
            defaultValues={{
                product_version_id: undefined,
            }}
            submitText="Duplicate"
            formReturnRefCallback={formReturnRefCallback}
            onSubmit={onSubmit}
        >
            {({ openOverlayPopup, register, formState: { errors } }) => (
                <>
                    <header>
                        <h2>Duplicate Submission</h2>
                    </header>
                    <p>
                        This will create a new, editable submission, with the
                        same insured details and application data.
                    </p>
                    <label htmlFor="product_version_name">
                        Select Product
                        <NotAnchor
                            onClick={() => openOverlayPopup(0)}
                            className="text-right"
                        >
                            <i aria-hidden="true" className="icon-help" />
                            <span className="hidden">More info</span>
                        </NotAnchor>
                    </label>
                    <ul className="check hr font-medium">
                        {orderBy(productVersions, "last_updated", "desc").map(
                            (productVersion) => (
                                <li key={productVersion.id}>
                                    <input
                                        type="radio"
                                        id={`tda-${productVersion.id}`}
                                        value={productVersion.id}
                                        {...register("product_version_id", {
                                            valueAsNumber: true,
                                        })}
                                    />
                                    <label htmlFor={`tda-${productVersion.id}`}>
                                        {productVersion.name}
                                        {productVersion.internal_version_name &&
                                            ` | ${productVersion.internal_version_name}`}
                                    </label>
                                    <span
                                        className={`scheme-box color-${
                                            productVersion.is_published
                                                ? "pear"
                                                : "gold"
                                        }`}
                                    >
                                        {productVersion.is_published
                                            ? "Published"
                                            : "Draft"}
                                    </span>
                                </li>
                            )
                        )}
                    </ul>
                    {errors.product_version_id && (
                        <label id="ifs-error" className="error" htmlFor="ifs">
                            {errors.product_version_id.message}
                        </label>
                    )}
                </>
            )}
        </DuplicateSubmissionFormPopup>
    );
};

export { DuplicateSubmissionPopup, DUPLICATE_SUBMISSION_POPUP };
