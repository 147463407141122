import classNames from "classnames";
import { Footer, MainPane } from "components/MainPane";
import MenuPopover from "components/MenuPopover";
import NotAnchor from "components/NotAnchor";
import { AlertCategory, Page, usePage } from "components/Page";
import { ConfirmationPopup, createFormPopup } from "components/Popup";
import { unpaginate } from "components/sdk";
import { useApi } from "contexts/ApiProvider";
import { usePopup } from "contexts/PopupProvider";
import { FC, useCallback, useEffect, useState } from "react";
import { z } from "zod";
import ExtraPane from "components/extra-panes/ExtraPane";
import { PolicyNumberPool } from "@joshuins/builder";

const DeleteNumberPoolPopup: FC = () => {
    const { sdkBuilder } = useApi();
    const { addAlertMessages, refreshState, tryCatchAndRaiseError } = usePage();
    const { popupData } = usePopup();
    const numberPool = popupData?.numberPool as PolicyNumberPool | undefined;

    const onSubmit = useCallback(async () => {
        if (!numberPool) {
            return;
        }
        tryCatchAndRaiseError(async () => {
            await sdkBuilder.deletePolicyNumberPool({ id: numberPool.id });
            addAlertMessages({
                message: `The ${numberPool.name} number pool has been deleted`,
                category: AlertCategory.SUCCESS,
            });
        }, refreshState);
    }, [
        addAlertMessages,
        numberPool,
        refreshState,
        sdkBuilder,
        tryCatchAndRaiseError,
    ]);

    return (
        <ConfirmationPopup
            name="delete-number-pool"
            onSubmit={onSubmit}
            submitText="Delete Number Pool"
            mobileSubmitText="Delete"
        >
            {numberPool && (
                <>
                    <header>
                        <h2>Are you sure?</h2>
                        <p className="size-14">
                            This policy number pool will be deleted and this
                            cannot be undone. If the number pool is already in
                            use, it cannot be deleted.
                        </p>
                    </header>
                </>
            )}
        </ConfirmationPopup>
    );
};

const addOrEditNumberPoolSchema = z.object({
    name: z.string().min(1, "This field is required"),
    first_value: z
        .string()
        .regex(/^\d+$/, "Must be a valid integer, equal to or greater than 0")
        .or(z.string().max(0)),
    last_value: z
        .string()
        .regex(/^\d+$/, "Must be a valid integer, equal to or greater than 0")
        .or(z.string().max(0))
        .optional(),
});

type AddOrEditNumberPoolType = z.infer<typeof addOrEditNumberPoolSchema>;

const {
    FormPopup: AddOrEditNumberPoolFormPopup,
    useFormReturnRef: useAddOrEditNumberPoolFormReturnRef,
} = createFormPopup(addOrEditNumberPoolSchema);

const AddOrEditNumberPoolPopup: FC = () => {
    const apiProviderContext = useApi();
    const { sdkBuilder } = apiProviderContext;
    const { addAlertMessages, refreshState, tryCatchAndRaiseError } = usePage();
    const { popupData, isPopupOpen } = usePopup();
    const {
        formReturnRefCallback,
        formReturn: { reset },
    } = useAddOrEditNumberPoolFormReturnRef();

    const numberPool = popupData?.numberPool as PolicyNumberPool | undefined;
    useEffect(() => {
        if (!isPopupOpen("add-or-edit-number-pool") || !reset) {
            return;
        }

        if (numberPool) {
            const last_value = numberPool.last_value
                ? numberPool.last_value.toString()
                : undefined;
            reset({
                name: numberPool.name,
                first_value: numberPool.first_value.toString(),
                last_value: last_value,
            });
        }
    }, [isPopupOpen, numberPool, reset]);

    const onSubmit = useCallback(
        async (data: AddOrEditNumberPoolType) => {
            tryCatchAndRaiseError(async () => {
                const last_value = data.last_value
                    ? parseInt(data.last_value)
                    : null;
                if (!numberPool) {
                    const newNumberPool =
                        await sdkBuilder.createPolicyNumberPool({
                            CreatePolicyNumberPool: {
                                name: data.name,
                                first_value: parseInt(data.first_value),
                                last_value: last_value,
                            },
                        });
                    addAlertMessages({
                        message: `${newNumberPool.name} has been added.`,
                        category: AlertCategory.SUCCESS,
                    });
                } else {
                    const updatedNumberPool =
                        await sdkBuilder.updatePolicyNumberPool({
                            id: numberPool.id,
                            UpdatePolicyNumberPool: {
                                name: data.name,
                                first_value: parseInt(data.first_value),
                                last_value: last_value,
                            },
                        });
                    addAlertMessages({
                        message: `${updatedNumberPool.name} has been updated`,
                        category: AlertCategory.SUCCESS,
                    });
                }
            }, refreshState);
        },
        [
            addAlertMessages,
            numberPool,
            refreshState,
            sdkBuilder,
            tryCatchAndRaiseError,
        ]
    );

    return (
        <AddOrEditNumberPoolFormPopup
            name="add-or-edit-number-pool"
            onSubmit={onSubmit}
            defaultValues={{
                name: "",
                first_value: "",
                last_value: "",
            }}
            submitText="Save"
            formReturnRefCallback={formReturnRefCallback}
            overlayPopups={[
                <>
                    <h3>Name</h3>
                    <p>{/* TODO: Add Text */}</p>
                </>,
                <>
                    <h3>First Value</h3>
                    <p>{/* TODO: Add Text */}</p>
                </>,
                <>
                    <h3>Last Value</h3>
                    <p>{/* TODO: Add Text */}</p>
                </>,
            ]}
        >
            {({ openOverlayPopup, register, formState: { errors } }) => {
                return (
                    <>
                        <header>
                            <h2>New Policy Number Pool</h2>
                        </header>
                        <p
                            className={classNames({
                                "has-error": errors.name,
                            })}
                        >
                            <label htmlFor="name">
                                Name
                                <NotAnchor
                                    onClick={() => openOverlayPopup(0)}
                                    className="text-right"
                                >
                                    <i className="icon-help" />
                                    <span className="hidden">More info</span>
                                </NotAnchor>
                            </label>
                            <input
                                id="name"
                                type="text"
                                {...register("name")}
                            />
                            {errors.name && (
                                <label
                                    id="name-error"
                                    className="error"
                                    htmlFor="name"
                                >
                                    {errors.name.message}
                                </label>
                            )}
                        </p>
                        <p
                            className={classNames("color-black-100", {
                                "has-error": errors.first_value,
                            })}
                        >
                            <label htmlFor="first_value">
                                First Value
                                <NotAnchor
                                    onClick={() => openOverlayPopup(1)}
                                    className="text-right"
                                >
                                    <i className="icon-help" />
                                    <span className="hidden">More info</span>
                                </NotAnchor>
                            </label>
                            <input
                                id="first_value"
                                type="number"
                                {...register("first_value")}
                            />
                            {errors.first_value && (
                                <label
                                    id="first_value-error"
                                    className="error"
                                    htmlFor="first_value"
                                >
                                    {errors.first_value.message}
                                </label>
                            )}
                        </p>
                        <p
                            className={classNames({
                                "has-error": errors.last_value,
                            })}
                        >
                            <label htmlFor="last_value">
                                Last Value (Optional)
                                <NotAnchor
                                    onClick={() => openOverlayPopup(2)}
                                    className="text-right"
                                >
                                    <i className="icon-help" />
                                    <span className="hidden">More info</span>
                                </NotAnchor>
                            </label>
                            <input
                                id="last_value"
                                type="number"
                                {...register("last_value")}
                            />
                            {errors.last_value && (
                                <label
                                    id="last_value-error"
                                    className="error"
                                    htmlFor="last_value"
                                >
                                    {errors.last_value.message}
                                </label>
                            )}
                        </p>
                    </>
                );
            }}
        </AddOrEditNumberPoolFormPopup>
    );
};

const NumberPoolsPopups: FC = () => (
    <>
        <AddOrEditNumberPoolPopup />
        <DeleteNumberPoolPopup />
    </>
);

const NumberPoolList: FC<{ numberPools: PolicyNumberPool[] }> = ({
    numberPools,
}) => {
    const { openPopup } = usePopup();

    return (
        <ul className="list-plain box no-img">
            {numberPools.map((numberPool, index) => {
                return (
                    <MenuPopover
                        additionalClasses={["list-plain-li"]}
                        key={numberPool.id}
                        menuItems={[
                            {
                                key: "delete-number-pool",
                                label: "Delete Number Pool",
                                icon: "trash",
                                onClick: () =>
                                    openPopup("delete-number-pool", {
                                        numberPool,
                                    }),
                            },
                        ]}
                        style={{
                            zIndex: numberPools.length - index,
                        }}
                    >
                        {({ ToggleButton, Menu }) => (
                            <>
                                <NotAnchor
                                    onClick={() => {
                                        openPopup("add-or-edit-number-pool", {
                                            numberPool,
                                        });
                                    }}
                                >
                                    {numberPool.name}
                                </NotAnchor>
                                {ToggleButton}
                                {Menu}
                            </>
                        )}
                    </MenuPopover>
                );
            })}
        </ul>
    );
};

const Main: FC = () => {
    const { sdkBuilder } = useApi();
    const { openPopup } = usePopup();
    const { stateId } = usePage();
    const [numberPools, setNumberPools] = useState<PolicyNumberPool[]>();

    useEffect(() => {
        const getNumberPools = async () => {
            const numberPools_ = await unpaginate(
                sdkBuilder.allPolicyNumberPools,
                {}
            );
            setNumberPools(numberPools_);
        };
        getNumberPools();
    }, [sdkBuilder, stateId]);

    if (numberPools === undefined) {
        return <></>;
    }

    return (
        <MainPane
            title="Policy Number Pools"
            layoutConfig={{
                mainLayout: numberPools.length === 0 ? "center" : "wide",
            }}
        >
            {numberPools.length === 0 ? (
                <div className="module-success inline">
                    <h2>
                        <i className="icon-bricks" /> No Policy Number Pools yet
                    </h2>
                    <p>
                        Click &quot;New Policy Number Pool&quot; below in order
                        to apply on future policies in the system.
                    </p>
                </div>
            ) : (
                <NumberPoolList numberPools={numberPools} />
            )}
            <Footer>
                <p className="link-btn">
                    <NotAnchor
                        onClick={() => {
                            openPopup("add-or-edit-number-pool");
                        }}
                    >
                        <i aria-hidden="true" className="icon-plus-circle" />
                        New
                        <span className="mobile-hide"> Policy Number Pool</span>
                    </NotAnchor>
                </p>
            </Footer>
            {/* <MenuPopover component={Footer} menuItems={[]}>
                {({ ToggleButton, Menu }) => (
                    <>
                        <p className="link-btn">
                            <NotAnchor
                                onClick={() => {
                                    openPopup("add-or-edit-number-pool");
                                }}
                            >
                                <i
                                    aria-hidden="true"
                                    className="icon-plus-circle"
                                />
                                New
                                <span className="mobile-hide">
                                    {" "}
                                    SSO Provider
                                </span>
                            </NotAnchor>
                        </p>
                        {ToggleButton}
                        {Menu}
                    </>
                )}
            </MenuPopover> */}
        </MainPane>
    );
};

const PolicyNumerPoolsExtraPane = () => (
    <ExtraPane>
        <ul className="list-icon b">
            <li>
                <i className="icon-bricks" /> Policy NumerPools{" "}
                <span>{/* TODO: Add Text */}</span>
            </li>
        </ul>
    </ExtraPane>
);

const PolicyNumberPoolsPage: FC = () => (
    <Page>
        <Main />
        <PolicyNumerPoolsExtraPane />
        <NumberPoolsPopups />
    </Page>
);

export default PolicyNumberPoolsPage;
