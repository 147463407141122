import { minTime } from "date-fns/constants";
import { formatInTimeZone } from "date-fns-tz";
import { parseISO } from "date-fns/parseISO";

const DATE_FORMAT_STRINGS = {
    withTime: "MMM dd yyyy | HH:mm",
    namedMonth: "MMM dd, yyyy",
    numericDateOnly: "MM/dd/yyyy",
    numericDateOnlyShortYear: "MM/dd/yy",
    apiDate: "yyyy-MM-dd",
} as const;

const MIN_DATE = new Date(minTime);

type DateFormatType = keyof typeof DATE_FORMAT_STRINGS;

const formatDate = (date: Date, formatType?: DateFormatType) => {
    try {
        return formatInTimeZone(
            date,
            "UTC",
            DATE_FORMAT_STRINGS[formatType ?? "namedMonth"]
        );
    } catch {
        return "";
    }
};

const formatDatetimeString = (
    ISO8601DatetimeString: string,
    formatType?: DateFormatType
): string => formatDate(parseISO8601(ISO8601DatetimeString), formatType);

// other files could just import parseISO directly, from date-fns, but we're trying to keep
// all the datetime functionality in a single file in case we decide to switch out date-fns in
// the future - in which case we'll only have to switch it out in this file.
const parseISO8601 = parseISO;

export {
    parseISO8601,
    formatDate,
    formatDatetimeString,
    DATE_FORMAT_STRINGS,
    MIN_DATE,
};
