import "./AccessPage.scss";
import {
    Dispatch,
    FC,
    Fragment,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { Footer, MainPane } from "components/MainPane";
import { AlertCategory, Page, TabInterface, usePage } from "components/Page";
import { useApi } from "contexts/ApiProvider";
import StoreChecklistExtraPane from "./components/StoreChecklistExtraPane";
import NotAnchor from "components/NotAnchor";
import { ConfirmationPopup, createFormPopup } from "components/Popup";
import { z } from "zod";
import axios from "axios";
import keyBy from "lodash/keyBy";
import MenuPopover from "components/MenuPopover";
import { usePopup } from "contexts/PopupProvider";
import { formatDatetimeString } from "utils/datetime";
import classNames from "classnames";
import { useSpring, animated } from "@react-spring/web";
import { useImmer } from "use-immer";
import fromPairs from "lodash/fromPairs";
import upperFirst from "lodash/upperFirst";
import difference from "lodash/difference";
import pickBy from "lodash/pickBy";
import pick from "lodash/pick";
import size from "lodash/size";
import groupBy from "lodash/groupBy";
import { NumericDictionary } from "lodash";

import type {
    Store,
    StoreUser,
    StoreUserStatus,
    StoreOffice,
} from "@joshuins/builder";
import type { Brokerage, Office, User } from "@joshuins/system";
import { nullToEmptyString } from "utils/object";
import { unpaginate, unpaginateWithMultiParams } from "components/sdk";
import { fullName } from "utils/user";
import { getMessageFromAxiosError } from "utils/axios-extras";

interface AccessPageInterface {
    tabs?: TabInterface[];
}

type SortDirection = "ascending" | "descending";

interface InvitePeoplePopupPopupDataInterface {
    userId?: number;
}

interface BlockAccountPopupPopupDataInterface {
    userId: number;
}

const statusToColourAndText = (
    status: StoreUserStatus
): {
    statusColour: string;
    statusText: string;
    timeStatusText: string;
    popupName: string;
} => {
    switch (status) {
        case "Approved":
            return {
                statusColour: "apple",
                statusText: "approved",
                timeStatusText: "approved",
                popupName: "update-profile",
            };
        case "ApproveOrDecline":
            return {
                statusColour: "gold",
                statusText: "approve or decline",
                timeStatusText: "requested",
                popupName: "approve-or-decline-access",
            };
        case "Declined":
            return {
                statusColour: "wine",
                statusText: "declined",
                timeStatusText: "declined",
                popupName: "update-profile",
            };
        case "Invited":
            return {
                statusColour: "gold",
                statusText: "invited",
                timeStatusText: "requested",
                popupName: "update-profile",
            };
    }
};

const getHostnameFromEmail = (email: string) => {
    return email.substring(email.indexOf("@") + 1);
};

const PeopleTab: FC<{
    setHasUsers: Dispatch<SetStateAction<boolean>>;
}> = ({ setHasUsers }) => {
    const { sdkBuilder, sdkSystem } = useApi();
    const { element, stateId } = usePage();
    const { openPopup } = usePopup();
    const store = element as unknown as Store | undefined;
    const [dataSortDirection, setDataSortDirection] = useState<SortDirection>();
    const [uiSortDirection, setUiSortDirection] = useState<SortDirection>();
    const [users, setUsers] = useState<
        | {
              user: User;
              storeUser: StoreUser;
          }[]
        | undefined
    >();

    const sortUsersByName = useCallback<() => void>(() => {
        if (!users || dataSortDirection === uiSortDirection) {
            return;
        }
        const sortedUsers = [...users].sort(
            ({ user: userA }, { user: userB }) => {
                const userAName = fullName(userA);
                const userBName = fullName(userB);
                let order;
                if (userAName > userBName) {
                    order = 1;
                } else if (userAName < userBName) {
                    order = -1;
                } else {
                    return 0;
                }

                if (uiSortDirection === "descending") {
                    order = -order;
                }
                return order;
            }
        );

        setUsers(sortedUsers);
        setDataSortDirection(uiSortDirection);
    }, [users, dataSortDirection, uiSortDirection]);

    useEffect(() => {
        sortUsersByName();
    }, [uiSortDirection, sortUsersByName]);

    useEffect(() => {
        const getStoreUsers = async () => {
            if (store !== undefined) {
                const storeUsers = await unpaginate(sdkBuilder.allStoreUsers, {
                    store_id: store.id,
                });

                const allUsers = await Promise.all(
                    storeUsers.map(({ user_id }) =>
                        sdkSystem.getUser({ id: user_id })
                    )
                );
                const allUsersLookup = keyBy(allUsers, "id");
                const allUsersData = storeUsers.map((storeUser) => ({
                    storeUser,
                    user: allUsersLookup[storeUser.user_id],
                }));
                setUsers(allUsersData);
                setHasUsers(allUsersData.length > 0);
                setDataSortDirection(undefined);
            }
        };

        getStoreUsers();
    }, [sdkBuilder, store, stateId, setHasUsers, sdkSystem]);

    if (users === undefined) {
        return <></>;
    }

    if (users.length === 0) {
        return (
            <div>
                <div className="module-success inline">
                    <h2>
                        <i aria-hidden="true" className="icon-user-circle" /> No
                        People Yet
                    </h2>
                    <p>
                        Click the button below to invite people to this store.
                    </p>
                </div>
            </div>
        );
    }

    return (
        <div>
            <div className="table-wrapper" style={{ paddingBottom: "180px" }}>
                <table className="table-d table-sort table-scroll">
                    <thead>
                        <tr>
                            <th
                                className="tablesorter-header-inner"
                                onClick={() => {
                                    setUiSortDirection(
                                        dataSortDirection === "ascending"
                                            ? "descending"
                                            : "ascending"
                                    );
                                }}
                            >
                                Name
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map(
                            ({
                                user: {
                                    first_name,
                                    last_name,
                                    email,
                                    brokerage_id,
                                },
                                storeUser: { created_at, status, user_id },
                            }) => {
                                const {
                                    statusColour,
                                    statusText,
                                    timeStatusText,
                                    popupName,
                                } = statusToColourAndText(status);

                                const menuItems = [
                                    {
                                        key: "resend-invitation",
                                        label: "Resend Invitation",
                                        icon: "envelope",
                                        hasSeparator: false,
                                        onClick: () =>
                                            openPopup("invite-people", {
                                                userId: user_id,
                                            }),
                                    },
                                    {
                                        key: "block-account",
                                        label: "Block Account",
                                        icon: "trash",
                                        hasSeparator: true,
                                        onClick: () =>
                                            openPopup("block-account", {
                                                userId: user_id,
                                            }),
                                    },
                                ];

                                // const emailDomain = getHostnameFromEmail(email);
                                // const unknownBrokerage =
                                //     brokerages &&
                                //     !brokerages.some(
                                //         (brokerage) =>
                                //             brokerage.domain === emailDomain
                                //     );

                                if (!brokerage_id) {
                                    menuItems.splice(1, 0, {
                                        key: "create-brokerage",
                                        label: "Create Brokerage",
                                        icon: "building",
                                        hasSeparator: false,
                                        onClick: () =>
                                            openPopup("create-brokerage", {
                                                userId: user_id,
                                            }),
                                    });
                                }

                                return (
                                    <tr key={email}>
                                        <td>
                                            <NotAnchor
                                                onClick={() =>
                                                    openPopup(popupName, {
                                                        userId: user_id,
                                                    })
                                                }
                                            >
                                                {first_name} {last_name}
                                            </NotAnchor>
                                        </td>
                                        <td className="text-right size-14 color-black-40">
                                            {upperFirst(timeStatusText)}{" "}
                                            {formatDatetimeString(
                                                created_at,
                                                "numericDateOnly"
                                            )}
                                            {!brokerage_id && (
                                                <span className="scheme-box color-gold">
                                                    UNKNOWN BROKERAGE
                                                </span>
                                            )}
                                            <span
                                                className={`scheme-box color-${statusColour}`}
                                            >
                                                {statusText.toUpperCase()}
                                            </span>
                                        </td>
                                        <MenuPopover
                                            component="td"
                                            menuItems={menuItems}
                                        >
                                            {({ ToggleButton, Menu }) => (
                                                <>
                                                    {Menu}
                                                    {ToggleButton}
                                                </>
                                            )}
                                        </MenuPopover>
                                    </tr>
                                );
                            }
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

const BrokerageRow: FC<{
    brokerage: Brokerage;
    totalRows: number;
    row: number;
}> = ({ brokerage, totalRows, row }) => {
    const [open, setOpen] = useState<boolean>(false);
    const [maxHeight, setMaxHeight] = useState<number>(0);
    const [offices, setOffices] = useState<Office[]>();
    const [preapprovedOffices, setPreapprovedOffices] = useImmer<
        | {
              [key: number]: boolean;
          }
        | undefined
    >(undefined);
    const ref = useRef<HTMLDivElement>(null);
    const { sdkBuilder, sdkSystem } = useApi();
    const { element } = usePage();
    const store = element as unknown as Store | undefined;

    const numPreapprovedOffices = useMemo(
        () =>
            preapprovedOffices
                ? size(pickBy(preapprovedOffices, (value) => value))
                : 0,
        [preapprovedOffices]
    );

    useEffect(() => {
        const getOfficesAndPreapprovedOffices = async () => {
            if (!store) {
                return;
            }
            const offices = await unpaginate(sdkSystem.allOffices, {
                brokerage_id: brokerage.id,
            });
            const officeIds = offices.map(({ id }) => id);

            const preapprovedOffices = (
                await unpaginate(sdkBuilder.allStoreOffices, {
                    store_id: store.id,
                })
            )
                .filter(({ office_id }) => officeIds.includes(office_id))
                .reduce(
                    (previousValue, storeOffice) => {
                        previousValue[storeOffice.office_id] = true;
                        return previousValue;
                    },
                    fromPairs(offices.map((office) => [office.id, false]))
                );

            setOffices(offices);
            setPreapprovedOffices(preapprovedOffices);
        };
        getOfficesAndPreapprovedOffices();
    }, [sdkBuilder, setPreapprovedOffices, store, brokerage, sdkSystem]);

    useEffect(() => {
        if (ref.current) {
            setMaxHeight(ref.current.scrollHeight);
        }
        // Even though offices isn't used in the useEffect, it is included in the dependency array to make sure that height is set once the offices have been retrieved
        // Without it, the height of the office is zero, since no offices are rendered until they have been retrieved.
    }, [offices]);

    const closedStyle = useMemo(
        () => ({
            height: 0,
            paddingTop: 0,
            paddingBottom: 0,
        }),
        []
    );

    const openStyle = useMemo(
        () => ({
            height: maxHeight,
            paddingTop: 7,
            paddingBottom: 2,
        }),
        [maxHeight]
    );

    const [springs, api] = useSpring(() => ({
        from: closedStyle,
        config: { duration: 150 },
    }));

    const toggleOpen = () => {
        let fromStyle, toStyle;
        if (open) {
            fromStyle = openStyle;
            toStyle = closedStyle;
        } else {
            fromStyle = closedStyle;
            toStyle = openStyle;
        }
        setOpen(!open);
        api.start({
            from: fromStyle,
            to: toStyle,
        });
    };

    const toggleOfficePreapproved = useCallback<
        (officeId: number) => Promise<void>
    >(
        async (officeId) => {
            if (!preapprovedOffices || !store) {
                return;
            }

            const newValue = !(preapprovedOffices[officeId] ?? false);

            if (newValue) {
                await sdkBuilder.createStoreOffice({
                    NewStoreOffice: {
                        store_id: store.id,
                        office_id: officeId,
                    },
                });
            } else {
                await sdkBuilder.deleteStoreOffice({
                    store_id: store.id,
                    office_id: officeId,
                });
            }

            setPreapprovedOffices((draft) => {
                if (!draft) {
                    return;
                }
                draft[officeId] = newValue;
            });
        },
        [setPreapprovedOffices, sdkBuilder, store, preapprovedOffices]
    );

    const setAllOfficesPreapproved = useCallback<
        () => Promise<void>
    >(async () => {
        if (!preapprovedOffices || !store) {
            return;
        }

        const officesToPreapprove: NumericDictionary<boolean> = pickBy(
            preapprovedOffices,
            (isPreapproved) => !isPreapproved
        );
        for (const [officeId] of Object.entries(officesToPreapprove)) {
            await sdkBuilder.createStoreOffice({
                NewStoreOffice: {
                    store_id: store.id,
                    office_id: parseInt(officeId),
                },
            });
        }
        setPreapprovedOffices((draft) => {
            if (!draft) {
                return;
            }
            for (const [officeId] of Object.entries(officesToPreapprove)) {
                draft[parseInt(officeId)] = true;
            }
        });
    }, [preapprovedOffices, sdkBuilder, store, setPreapprovedOffices]);

    const setAllOfficesUnapproved = useCallback<
        () => Promise<void>
    >(async () => {
        if (!preapprovedOffices || !store) {
            return;
        }

        const officesToUnpreapprove: NumericDictionary<boolean> = pickBy(
            preapprovedOffices,
            (isPreapproved) => isPreapproved
        );
        for (const [officeId] of Object.entries(officesToUnpreapprove)) {
            await sdkBuilder.deleteStoreOffice({
                store_id: store.id,
                office_id: parseInt(officeId),
            });
        }
        setPreapprovedOffices((draft) => {
            if (!draft) {
                return;
            }
            for (const [officeId] of Object.entries(officesToUnpreapprove)) {
                draft[parseInt(officeId)] = false;
            }
        });
    }, [preapprovedOffices, sdkBuilder, store, setPreapprovedOffices]);

    const zIndex = totalRows * 2 - row * 2;

    return (
        <>
            <header
                className={classNames("header", { toggle: open })}
                style={{ zIndex: zIndex }}
            >
                <h3>{brokerage.name}</h3>
                <span className="text-right size-14 color-black-40">
                    {numPreapprovedOffices}/{offices?.length ?? "0"} Offices
                    Pre-Approved
                </span>
                <ul className="list-inline btn" style={{ marginLeft: "12px" }}>
                    <MenuPopover
                        menuItems={[
                            {
                                key: "select-all",
                                label: "Select All",
                                icon: "check-circle-outline",
                                onClick: () => setAllOfficesPreapproved(),
                            },
                            {
                                key: "select-none",
                                label: "Select None",
                                icon: "x-circle",
                                onClick: () => setAllOfficesUnapproved(),
                            },
                        ]}
                    >
                        {({ ToggleButton, Menu }) => (
                            <>
                                {Menu}
                                {ToggleButton}
                            </>
                        )}
                    </MenuPopover>
                </ul>
                <NotAnchor role="tab" onClick={() => toggleOpen()} />
            </header>
            <animated.div
                style={{
                    overflow: "hidden",
                    zIndex: zIndex - 1,
                    ...springs,
                }}
                ref={ref}
            >
                {offices && preapprovedOffices && offices.length > 0 && (
                    <ul className="check small size-14" id="brokerage-4">
                        {offices.map(({ id: officeId, name: officeName }) => {
                            const approved =
                                preapprovedOffices[officeId] ?? false;
                            return (
                                <li key={officeId}>
                                    <input
                                        id={`ldra-${officeId}`}
                                        className="office-check"
                                        type="checkbox"
                                        checked={approved}
                                        onChange={() =>
                                            toggleOfficePreapproved(officeId)
                                        }
                                    />{" "}
                                    <label htmlFor={`ldra-${officeId}`}>
                                        {officeName}
                                    </label>
                                    {approved && (
                                        <span className="scheme-box color-apple">
                                            PRE-APPROVED
                                        </span>
                                    )}
                                </li>
                            );
                        })}
                    </ul>
                )}
            </animated.div>
        </>
    );
};

const BrokeragesTab: FC<{
    setHasBrokerages: Dispatch<SetStateAction<boolean>>;
}> = ({ setHasBrokerages }) => {
    const { sdkSystem } = useApi();
    const { stateId } = usePage();
    const [brokerages, setBrokerages] = useState<Brokerage[]>();

    useEffect(() => {
        const getBrokerages = async () => {
            const brokerages_ = await unpaginate(sdkSystem.allBrokerages, {});
            setBrokerages(brokerages_);
            setHasBrokerages(brokerages_.length > 0);
        };
        getBrokerages();
    }, [sdkSystem, setHasBrokerages, stateId]);

    if (brokerages === undefined) {
        return <></>;
    }

    if (brokerages.length === 0) {
        return (
            <div>
                <div className="module-success inline">
                    <h2>
                        <i aria-hidden="true" className="icon-building" /> No
                        Brokerages Yet
                    </h2>
                    <p>
                        Add brokerages here to create pre-approved domains for
                        your stores
                    </p>
                </div>
            </div>
        );
    }

    return (
        <div style={{ marginBottom: "80px" }}>
            <div className="accordion-a a is-accordion" role="tablist">
                {brokerages.map((brokerage, index) => (
                    <BrokerageRow
                        key={brokerage.id}
                        brokerage={brokerage}
                        totalRows={brokerages.length}
                        row={index}
                    />
                ))}
            </div>
        </div>
    );
};

const Main: FC = () => {
    const { activeTab, element } = usePage();
    const { openPopup } = usePopup();
    const [hasPeople, setHasPeople] = useState<boolean>(false);
    const [hasBrokerages, setHasBrokerages] = useState<boolean>(false);
    const store = element as unknown as Store | undefined;

    const activeTab_ = activeTab();

    if (!activeTab_) {
        return <></>;
    }

    return (
        <MainPane
            title="Access"
            layoutConfig={{
                mainLayout:
                    (activeTab_.path === "people" && !hasPeople) ||
                    (activeTab_.path === "brokerage" && !hasBrokerages)
                        ? "center"
                        : "wide",
            }}
            {...(store?.channel === "Direct" && {
                hiddenTabPaths: ["brokerage"],
            })}
        >
            <div className="module-tabs-content">
                {(() => {
                    switch (activeTab_.path) {
                        case "people":
                            return <PeopleTab setHasUsers={setHasPeople} />;
                        case "brokerage":
                            return (
                                <BrokeragesTab
                                    setHasBrokerages={setHasBrokerages}
                                />
                            );
                        default:
                            return <></>;
                    }
                })()}
            </div>
            <Footer>
                <p className="link-btn">
                    <NotAnchor onClick={() => openPopup("invite-people")}>
                        <i className="icon-plus-circle" />
                        Invite People
                    </NotAnchor>
                </p>
            </Footer>
        </MainPane>
    );
};

const approveOrDeclineAccessSchema = z.object({
    first_name: z.string().min(1, "This field is required"),
    last_name: z.string().min(1, "This field is required"),
    email: z.string().email(),
});

const {
    FormPopup: ApproveOrDeclineAccessFormPopup,
    useFormReturnRef: useApproveOrDeclineAccessFormReturnRef,
} = createFormPopup(approveOrDeclineAccessSchema);

const ApproveOrDeclineAccessPopup: FC = () => {
    const { sdkBuilder, sdkSystem } = useApi();
    const { element, addAlertMessages, refreshState } = usePage();
    const { popupData, isPopupOpen } = usePopup();
    const {
        formReturn: { reset },
        formReturnRefCallback,
    } = useApproveOrDeclineAccessFormReturnRef();
    const store = element as unknown as Store | undefined;

    const userId = popupData?.userId as number;

    useEffect(() => {
        const getUser = async () => {
            if (
                !isPopupOpen("approve-or-decline-access") ||
                !userId ||
                !reset
            ) {
                return;
            }
            const user = await sdkSystem.getUser({ id: userId });
            reset(nullToEmptyString(user));
        };
        getUser();
    }, [sdkSystem, userId, isPopupOpen, reset]);

    const updateStoreUserStatus = useCallback(
        async (approveOrDecline: "approve" | "decline") => {
            if (!userId || !store) {
                return;
            }

            try {
                await sdkBuilder.createStoreUser({
                    NewStoreUser: {
                        store_id: store.id,
                        user_id: userId,
                        status:
                            approveOrDecline === "approve"
                                ? "Approved"
                                : "Declined",
                    },
                });
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    addAlertMessages({
                        message: getMessageFromAxiosError(error),
                        category: AlertCategory.ALERT,
                    });
                } else {
                    throw error;
                }
            } finally {
                refreshState();
            }
        },
        [userId, sdkBuilder, store, addAlertMessages, refreshState]
    );

    const onSubmit = useCallback(async () => {
        await updateStoreUserStatus("approve");
    }, [updateStoreUserStatus]);

    return (
        <ApproveOrDeclineAccessFormPopup
            name="approve-or-decline-access"
            defaultValues={{
                first_name: "",
                last_name: "",
                email: "",
            }}
            onSubmit={onSubmit}
            submitText="Approve"
            cancelText="Decline"
            cancelClicked={async () => {
                await updateStoreUserStatus("decline");
            }}
            formReturnRefCallback={formReturnRefCallback}
        >
            {({ register }) => (
                <>
                    <header>
                        <h2>Approve access?</h2>
                    </header>
                    <div className="cols">
                        <p className="c50">
                            <label htmlFor="ppe">First Name</label>
                            <input
                                {...register("first_name")}
                                type="text"
                                id="ppe"
                                readOnly
                            />
                        </p>
                        <p className="c50">
                            <label htmlFor="ppf">Last Name</label>
                            <input
                                {...register("last_name")}
                                type="text"
                                id="ppf"
                                readOnly
                            />
                        </p>
                    </div>
                    <p>
                        <label htmlFor="ppg">Email Address</label>
                        <input
                            {...register("email")}
                            type="email"
                            id="ppg"
                            readOnly
                        />
                    </p>
                </>
            )}
        </ApproveOrDeclineAccessFormPopup>
    );
};

const createBrokerageSchema = z.object({
    first_name: z.string().min(1, "This field is required"),
    last_name: z.string().min(1, "This field is required"),
    email: z.string().email(),
    domain: z.string().min(1, "This field is required"),
});

type CreateBrokerageType = z.infer<typeof createBrokerageSchema>;

const {
    FormPopup: CreateBrokerageFormPopup,
    useFormReturnRef: useCreateBrokerageFormReturnRef,
} = createFormPopup(createBrokerageSchema);

const CreateBrokeragePopup: FC = () => {
    const { sdkBuilder, sdkSystem } = useApi();
    const { element, addAlertMessages, refreshState } = usePage();
    const { popupData, isPopupOpen } = usePopup();
    const {
        formReturn: { reset },
        formReturnRefCallback,
    } = useCreateBrokerageFormReturnRef();
    const store = element as unknown as Store | undefined;

    const userId = popupData?.userId as number;

    useEffect(() => {
        const getUser = async () => {
            if (!isPopupOpen("create-brokerage") || !userId || !reset) {
                return;
            }
            const user = await sdkSystem.getUser({ id: userId });
            reset({
                ...nullToEmptyString(user),
                domain: getHostnameFromEmail(user.email),
            });
        };
        getUser();
    }, [sdkSystem, userId, isPopupOpen, reset]);

    const onSubmit = useCallback(
        async (data: CreateBrokerageType) => {
            if (!userId || !store) {
                return;
            }

            const { email } = data;
            const emailDomain = getHostnameFromEmail(email);

            try {
                const brokerages = await unpaginate(sdkSystem.allBrokerages, {
                    domain: emailDomain,
                });
                let brokerage =
                    brokerages.length > 0 ? brokerages[0] : undefined;
                let office: Office | undefined;

                if (brokerage) {
                    addAlertMessages({
                        message:
                            "A brokerage with the same email domain already exists. Connected all users with the same email domain to the existing brokerage.",
                        category: AlertCategory.INFO,
                    });
                    const offices = await unpaginate(sdkSystem.allOffices, {
                        brokerage_id: brokerage.id,
                    });

                    // if the length is more than one then we can't choose an office to eventually assign to the domainUsers below
                    if (offices.length === 1) {
                        office = offices[0];
                    }
                } else {
                    brokerage = await sdkSystem.createBrokerage({
                        NewBrokerage: {
                            domain: emailDomain,
                            name: emailDomain,
                        },
                    });
                    office = (
                        await unpaginate(sdkSystem.allOffices, {
                            brokerage_id: brokerage.id,
                        })
                    )[0];
                }

                const unassignedDomainUsers = (
                    await unpaginate(sdkSystem.allUsers, {
                        email: `@${emailDomain}`,
                    })
                ).filter((user) => !user.brokerage_id);

                const unassignedPendingDomainStoreUsers =
                    await unpaginateWithMultiParams(sdkBuilder.allStoreUsers, {
                        user_id: unassignedDomainUsers.map((user) => user.id),
                        store_id: store.id,
                        status: "ApproveOrDecline",
                    });

                const updateUserPromises = [];
                const brokerageData = {
                    brokerage_id: brokerage.id,
                    ...(office && { office_id: office.id }),
                };
                for (const unassignedDomainUser of unassignedDomainUsers) {
                    updateUserPromises.push(
                        sdkSystem.updateUser({
                            id: unassignedDomainUser.id,
                            UpdateUser: brokerageData,
                        })
                    );
                }

                let storeOffice: StoreOffice | undefined;
                try {
                    storeOffice = office
                        ? await sdkBuilder.getStoreOffice({
                              store_id: store.id,
                              office_id: office.id,
                          })
                        : undefined;
                } catch (error) {
                    if (!axios.isAxiosError(error)) {
                        throw error;
                    }
                }

                for (const pendingDomainStoreUser of unassignedPendingDomainStoreUsers) {
                    if (office) {
                        if (storeOffice) {
                            updateUserPromises.push(
                                sdkBuilder.createStoreUser({
                                    NewStoreUser: {
                                        store_id:
                                            pendingDomainStoreUser.store_id,
                                        user_id: pendingDomainStoreUser.user_id,
                                        status: "Approved",
                                    },
                                })
                            );
                        }
                    } else {
                        updateUserPromises.push(
                            sdkBuilder.deleteStoreUser({
                                store_id: store.id,
                                user_id: pendingDomainStoreUser.user_id,
                            })
                        );
                    }
                }
                if (updateUserPromises.length > 0) {
                    await Promise.allSettled(updateUserPromises);
                }
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    addAlertMessages({
                        message: getMessageFromAxiosError(error),
                        category: AlertCategory.ALERT,
                    });
                } else {
                    throw error;
                }
            } finally {
                refreshState();
            }
        },
        [userId, addAlertMessages, sdkBuilder, refreshState, store, sdkSystem]
    );

    return (
        <CreateBrokerageFormPopup
            name="create-brokerage"
            defaultValues={{
                first_name: "",
                last_name: "",
                email: "",
                domain: "",
            }}
            onSubmit={onSubmit}
            submitText="Create Brokerage"
            mobileSubmitText="Create"
            formReturnRefCallback={formReturnRefCallback}
        >
            {({ register }) => (
                <>
                    <header>
                        <h2>Unknown Brokerage</h2>
                        <p>
                            Would you like to create a brokerage for this
                            user&apos;s Email domain? Doing so will not approve
                            the user.
                        </p>
                    </header>
                    <div className="cols">
                        <p className="c50">
                            <label htmlFor="ppe">First Name</label>
                            <input
                                {...register("first_name")}
                                type="text"
                                id="ppe"
                                readOnly
                            />
                        </p>
                        <p className="c50">
                            <label htmlFor="ppf">Last Name</label>
                            <input
                                {...register("last_name")}
                                type="text"
                                id="ppf"
                                readOnly
                            />
                        </p>
                    </div>
                    <p>
                        <label htmlFor="ppg">Email Address</label>
                        <input
                            {...register("email")}
                            type="email"
                            id="ppg"
                            readOnly
                        />
                    </p>
                    <p>
                        <label htmlFor="ppg">Email Domain</label>
                        <input
                            {...register("domain")}
                            type="text"
                            id="create-brokerage-domain"
                            readOnly
                        />
                    </p>
                </>
            )}
        </CreateBrokerageFormPopup>
    );
};

const invitePeopleSchema = z.object({
    first_name: z.string().min(1, "This field is required"),
    last_name: z.string().min(1, "This field is required"),
    email: z.string().email(),
    role: z.literal("Broker"),
});

type InvitePeopleType = z.infer<typeof invitePeopleSchema>;

const {
    FormPopup: InvitePeopleFormPopup,
    useFormReturnRef: useInvitePeopleFormReturnRef,
} = createFormPopup(invitePeopleSchema);

const InvitePeoplePopup: FC = () => {
    const { sdkBuilder, sdkSystem } = useApi();
    const { element, addAlertMessages, refreshState } = usePage();
    const { popupData, isPopupOpen } = usePopup();
    const [inputsDisabled, setInputsDisabled] = useState<boolean>(false);
    const {
        formReturn: { reset },
        formReturnRefCallback,
    } = useInvitePeopleFormReturnRef();
    const store = element as unknown as Store | undefined;

    const popupData_ =
        popupData as unknown as InvitePeoplePopupPopupDataInterface;

    useEffect(() => {
        const getUser = async () => {
            if (!reset) {
                return;
            }

            if (!isPopupOpen("invite-people") || !popupData_?.userId) {
                setInputsDisabled(false);
                return;
            }
            const user = await sdkSystem.getUser({ id: popupData_.userId });
            reset({
                ...nullToEmptyString(
                    pick(user, ["first_name", "last_name", "email"])
                ),
                role: "Broker",
            });
            setInputsDisabled(true);
        };
        getUser();
    }, [sdkBuilder, popupData_, isPopupOpen, sdkSystem, reset]);

    const onSubmit = useCallback(
        async (data: InvitePeopleType) => {
            if (!store) {
                return;
            }

            // the if is for inviting a user newly to the store
            if (!popupData_?.userId) {
                try {
                    const { email } = data;
                    const users = await unpaginate(sdkSystem.allUsers, {
                        email,
                    });

                    let user: User;
                    if (users.length > 0) {
                        user = users[0];
                    } else {
                        user = await sdkSystem.createUser({
                            CreateUser: data,
                        });
                    }

                    if (!user.brokerage_id) {
                        const emailDomain = getHostnameFromEmail(email);
                        const brokerages = await unpaginate(
                            sdkSystem.allBrokerages,
                            {
                                domain: emailDomain,
                            }
                        );
                        let brokerage =
                            brokerages.length > 0 ? brokerages[0] : undefined;
                        if (!brokerage) {
                            brokerage = await sdkSystem.createBrokerage({
                                NewBrokerage: {
                                    domain: emailDomain,
                                    name: emailDomain,
                                },
                            });
                            addAlertMessages({
                                message: `${email} is not a part of a registered brokerage, we have added this brokerage for you. Remember to add an office!`,
                                category: AlertCategory.ALERT,
                            });
                        }

                        await sdkSystem.updateUser({
                            id: user.id,
                            UpdateUser: {
                                brokerage_id: brokerage.id,
                            },
                        });
                    }

                    await sdkBuilder.createStoreUser({
                        NewStoreUser: {
                            store_id: store.id,
                            user_id: user.id,
                            status: "Invited",
                        },
                    });

                    addAlertMessages({
                        message: `${user.email} is invited to ${store.name}`,
                        category: AlertCategory.SUCCESS,
                    });
                } catch (error) {
                    if (axios.isAxiosError(error)) {
                        addAlertMessages({
                            message: getMessageFromAxiosError(error),
                            category: AlertCategory.ALERT,
                        });
                    } else {
                        throw error;
                    }
                } finally {
                    refreshState();
                }
            } else {
                // the else is for reinviting a user
                try {
                    await sdkBuilder.createStoreUser({
                        NewStoreUser: {
                            store_id: store.id,
                            user_id: popupData_.userId,
                            status: "Invited",
                        },
                    });
                    addAlertMessages({
                        message: `The invitation to ${fullName(
                            data
                        )} has been sent`,
                        category: AlertCategory.SUCCESS,
                    });
                } catch (error) {
                    if (axios.isAxiosError(error)) {
                        addAlertMessages({
                            message: getMessageFromAxiosError(error),
                            category: AlertCategory.ALERT,
                        });
                    } else {
                        throw error;
                    }
                } finally {
                    refreshState();
                }
            }
        },
        [
            popupData_,
            sdkBuilder,
            store,
            addAlertMessages,
            refreshState,
            sdkSystem,
        ]
    );

    return (
        <InvitePeopleFormPopup
            name="invite-people"
            defaultValues={{
                first_name: "",
                last_name: "",
                email: "",
                role: "Broker",
            }}
            onSubmit={onSubmit}
            submitText="Send Invite"
            mobileSubmitText="Send"
            formReturnRefCallback={formReturnRefCallback}
        >
            {({ register, formState: { errors } }) => (
                <>
                    <header>
                        <h2>Invite People</h2>
                        <p className="size-14">
                            Choose a role and which stores and products they
                            will be invited to.
                        </p>
                    </header>
                    <div className="cols">
                        <p className="c50">
                            <label htmlFor="ppb">First Name</label>
                            <input
                                type="text"
                                id="ppb"
                                {...register("first_name")}
                                disabled={inputsDisabled}
                            />
                            {errors.first_name && (
                                <label
                                    id="ppb-error"
                                    className="error"
                                    htmlFor="ppb"
                                >
                                    {errors.first_name.message}
                                </label>
                            )}
                        </p>
                        <p className="c50">
                            <label htmlFor="ppc">Last Name</label>
                            <input
                                type="text"
                                id="ppc"
                                {...register("last_name")}
                                disabled={inputsDisabled}
                            />
                            {errors.last_name && (
                                <label
                                    id="ppc-error"
                                    className="error"
                                    htmlFor="ppc"
                                >
                                    {errors.last_name.message}
                                </label>
                            )}
                        </p>
                    </div>
                    <p>
                        <label htmlFor="ppd">Email Address</label>
                        <input
                            type="text"
                            id="ppd"
                            {...register("email")}
                            disabled={inputsDisabled}
                        />
                        {errors.email && (
                            <label
                                id="ppd-error"
                                className="error"
                                htmlFor="ppd"
                            >
                                {errors.email.message}
                            </label>
                        )}
                    </p>
                    <input type="hidden" {...register("role")} />
                </>
            )}
        </InvitePeopleFormPopup>
    );
};

const updateProfileSchema = z.object({
    first_name: z.string().min(1, "This field is required"),
    last_name: z.string().min(1, "This field is required"),
    email: z.string().email(),
    role: z.literal("Broker"),
    storeIds: z.string().regex(/^\d+$/).array(),
});

type UpdateProfileType = z.infer<typeof updateProfileSchema>;

const {
    FormPopup: UpdateProfileFormPopup,
    useFormReturnRef: useUpdateProfileFormReturnRef,
} = createFormPopup(updateProfileSchema);

const UpdateProfilePopup: FC = () => {
    const {
        formReturn: { reset },
        formReturnRefCallback,
    } = useUpdateProfileFormReturnRef();
    const [allStores, setAllStores] = useState<Store[]>();
    const [currentStoreIds, setCurrentStoreIds] = useState<number[]>();
    const { addAlertMessages, refreshState, tryCatchAndRaiseError } = usePage();
    const { sdkBuilder, sdkSystem } = useApi();
    const { popupData } = usePopup();

    const userId = popupData?.userId as number;

    const onSubmit = useCallback(
        async (data: UpdateProfileType) => {
            if (!userId || !reset) {
                return;
            }
            const newStoreIds = data.storeIds.map((id) => parseInt(id));
            if (!currentStoreIds) {
                return;
            }
            tryCatchAndRaiseError(async () => {
                const updatedUser = await sdkSystem.updateUser({
                    id: userId,
                    UpdateUser: pick(data, [
                        "first_name",
                        "last_name",
                        "email",
                        "role",
                    ]),
                });

                const storeIdsToAdd = difference(newStoreIds, currentStoreIds);

                const storeIdsToRemove = difference(
                    currentStoreIds,
                    newStoreIds
                );

                for (const storeId of storeIdsToAdd) {
                    await sdkBuilder.createStoreUser({
                        NewStoreUser: {
                            store_id: storeId,
                            user_id: userId,
                            status: "Invited",
                        },
                    });
                }

                for (const storeId of storeIdsToRemove) {
                    await sdkBuilder.deleteStoreUser({
                        store_id: storeId,
                        user_id: userId,
                    });
                }

                setCurrentStoreIds(newStoreIds);
                reset({
                    ...pick(updatedUser, "email"),
                    first_name:
                        updatedUser.first_name === null
                            ? ""
                            : updatedUser.first_name,
                    last_name:
                        updatedUser.last_name === null
                            ? ""
                            : updatedUser.last_name,
                    role: "Broker",
                    storeIds: newStoreIds.map((id) => id.toString()),
                });
                addAlertMessages({
                    message: `${fullName(
                        updatedUser
                    )} was successfully updated!`,
                    category: AlertCategory.SUCCESS,
                });
            }, refreshState);
        },
        [
            userId,
            reset,
            currentStoreIds,
            tryCatchAndRaiseError,
            refreshState,
            sdkSystem,
            addAlertMessages,
            sdkBuilder,
        ]
    );

    useEffect(() => {
        const getUser = async () => {
            if (!userId || !reset) {
                return;
            }
            const user_ = await sdkSystem.getUser({ id: userId });
            const storeUsers = await unpaginate(sdkBuilder.allStoreUsers, {
                user_id: userId,
            });
            const currentStoreIds_ = storeUsers.map(({ store_id }) => store_id);
            const allStores_ = await unpaginate(sdkBuilder.allStores, {});
            setAllStores(allStores_);
            setCurrentStoreIds(currentStoreIds_);
            reset({
                ...pick(user_, ["email"]),
                first_name: user_.first_name === null ? "" : user_.first_name,
                last_name: user_.last_name === null ? "" : user_.last_name,
                role: "Broker",
                storeIds: currentStoreIds_.map((id) => id.toString()),
            });
        };
        getUser();
    }, [userId, sdkBuilder, sdkSystem, reset]);

    const groupedStoresByChannel = groupBy(allStores, "channel");

    return (
        <UpdateProfileFormPopup
            name="update-profile"
            defaultValues={{
                first_name: "",
                last_name: "",
                email: "",
                role: "Broker",
                storeIds: [],
            }}
            onSubmit={onSubmit}
            submitText="Save"
            formReturnRefCallback={formReturnRefCallback}
        >
            {({ register, formState: { errors } }) => (
                <>
                    <header>
                        <h2>Profile</h2>
                    </header>
                    <div className="cols">
                        <p className="c50">
                            <label htmlFor="ppb-b">First Name</label>
                            <input
                                type="text"
                                id="ppb-b"
                                {...register("first_name")}
                            />
                            {errors.first_name && (
                                <label
                                    id="ppb-b-error"
                                    className="error"
                                    htmlFor="ppb-b"
                                >
                                    {errors.first_name.message}
                                </label>
                            )}
                        </p>
                        <p className="c50">
                            <label htmlFor="ppc-b">Last Name</label>
                            <input
                                type="text"
                                id="ppc-b"
                                {...register("last_name")}
                            />
                            {errors.last_name && (
                                <label
                                    id="ppc-b-error"
                                    className="error"
                                    htmlFor="ppc-b"
                                >
                                    {errors.last_name.message}
                                </label>
                            )}
                        </p>
                    </div>
                    <p>
                        <label htmlFor="ppd-b">Email Address</label>
                        <input
                            type="email"
                            id="ppd-b"
                            {...register("email")}
                            readOnly
                        />
                    </p>
                    <div className="div-as-p">
                        <input type="hidden" {...register("role")} />
                        {(["Broker", "Direct"] as const).map((channel) => (
                            <Fragment key={channel}>
                                <h2 className="size-16 m10">
                                    {channel} Stores
                                </h2>
                                {allStores && allStores.length > 0 && (
                                    <ul className="check font-medium">
                                        {groupedStoresByChannel[channel]?.map(
                                            ({ id, name }) => (
                                                <li key={id}>
                                                    <input
                                                        type="checkbox"
                                                        id={`igf-1-${id}`}
                                                        value={id}
                                                        {...register(
                                                            "storeIds"
                                                        )}
                                                    />
                                                    <label
                                                        htmlFor={`igf-1-${id}`}
                                                    >
                                                        {name}
                                                    </label>
                                                </li>
                                            )
                                        )}
                                    </ul>
                                )}
                            </Fragment>
                        ))}
                    </div>
                </>
            )}
        </UpdateProfileFormPopup>
    );
};

const BlockAccountPopup: FC = () => {
    const { sdkBuilder, sdkSystem } = useApi();
    const { addAlertMessages, refreshState, element, tryCatchAndRaiseError } =
        usePage();
    const { popupData, isPopupOpen } = usePopup();
    const [user, setUser] = useState<User>();

    const store = element as unknown as Store | undefined;

    const popupData_ =
        popupData as unknown as BlockAccountPopupPopupDataInterface;

    useEffect(() => {
        const getUser = async () => {
            if (!isPopupOpen("block-account") || !popupData_) {
                return;
            }
            const user_ = await sdkSystem.getUser({ id: popupData_.userId });
            setUser(user_);
        };
        getUser();
    }, [sdkSystem, popupData_, isPopupOpen]);

    const onSubmit = useCallback(async () => {
        if (!popupData_ || !store) {
            return;
        }
        tryCatchAndRaiseError(async () => {
            await sdkBuilder.deleteStoreUser({
                store_id: store.id,
                user_id: popupData_.userId,
            });
            const userFullName = user
                ? `${user.first_name} ${user.last_name}`
                : "";

            addAlertMessages({
                message: `${userFullName}${
                    userFullName ? " " : ""
                }member has been blocked`,
                category: AlertCategory.SUCCESS,
            });
        }, refreshState);
    }, [
        popupData_,
        store,
        tryCatchAndRaiseError,
        refreshState,
        sdkBuilder,
        user,
        addAlertMessages,
    ]);

    return (
        <ConfirmationPopup
            name="block-account"
            onSubmit={onSubmit}
            submitText="Disable Account"
            mobileSubmitText="Disable"
        >
            {user && (
                <>
                    <header>
                        <h2>Disable Account?</h2>
                    </header>
                    <ul className="list-static a">
                        <li>
                            {fullName(user)} will be
                            <span className="strong"> hidden </span>
                            from all products & stores and
                            <span className="strong"> blocked </span> from
                            signing in.
                        </li>
                        <li>
                            All of their
                            <span className="strong">
                                {" "}
                                quotes, binders, and policies
                            </span>{" "}
                            will still be visible to Admins.
                        </li>
                    </ul>
                </>
            )}
        </ConfirmationPopup>
    );
};

const AccessPage: FC<AccessPageInterface> = ({ tabs }) => (
    <Page tabs={tabs}>
        <Main />
        <StoreChecklistExtraPane />
        <InvitePeoplePopup />
        <UpdateProfilePopup />
        <BlockAccountPopup />
        <ApproveOrDeclineAccessPopup />
        <CreateBrokeragePopup />
    </Page>
);

export default AccessPage;
