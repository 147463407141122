import { FC, useCallback, useEffect, useState } from "react";
import { Brokerage } from "@joshuins/system";
import { useApi } from "contexts/ApiProvider";
import { usePopup } from "contexts/PopupProvider";
import { AlertCategory, usePage } from "components/Page";
import { ConfirmationPopup } from "components/Popup";
import { unpaginate } from "components/sdk";

const DeleteBrokeragePopup: FC = () => {
    const { sdkSystem } = useApi();
    const { addAlertMessages, tryCatchAndRaiseError } = usePage();
    const { popupData, isPopupOpen } = usePopup();
    const [brokerageToBeDeleted, setBrokerageToBeDeleted] =
        useState<Brokerage>();
    const brokerageIdToBeDeleted = popupData?.brokerageIdToBeDeleted as number;

    useEffect(() => {
        const getUser = async () => {
            if (!isPopupOpen("delete-brokerage") || !brokerageIdToBeDeleted) {
                return;
            }
            const brokerage_ = await sdkSystem.getBrokerage({
                id: brokerageIdToBeDeleted,
            });
            setBrokerageToBeDeleted(brokerage_);
        };
        getUser();
    }, [sdkSystem, brokerageIdToBeDeleted, isPopupOpen]);

    const onSubmit = useCallback(async () => {
        if (!brokerageIdToBeDeleted || !brokerageToBeDeleted) {
            return;
        }
        const brokerageUsers = await unpaginate(sdkSystem.allUsers, {
            brokerage_id: brokerageIdToBeDeleted,
        });
        if (brokerageUsers.length === 0) {
            tryCatchAndRaiseError(async () => {
                await sdkSystem.deleteBrokerage({ id: brokerageIdToBeDeleted });
                addAlertMessages({
                    message: `${brokerageToBeDeleted.name} brokerage has been deleted`,
                    category: AlertCategory.SUCCESS,
                });
            }, window.location.reload);
        } else {
            addAlertMessages({
                message:
                    "This brokerage or one of the associated offices has brokers assigned to it and cannot be deleted. Reassign people to another office, or contact Joshu support for further assistance.",
                category: AlertCategory.SUCCESS,
            });
        }
    }, [
        brokerageIdToBeDeleted,
        brokerageToBeDeleted,
        sdkSystem,
        tryCatchAndRaiseError,
        addAlertMessages,
    ]);

    return (
        <ConfirmationPopup
            name="delete-brokerage"
            onSubmit={onSubmit}
            submitText="Delete Brokerage"
            mobileSubmitText="Delete"
        >
            {brokerageToBeDeleted && (
                <>
                    <header>
                        <h2>Are you sure?</h2>
                        <p className="size-14">
                            This brokerage and all associated offices will be
                            deleted, and cannot be undone.
                        </p>
                    </header>
                </>
            )}
        </ConfirmationPopup>
    );
};

export default DeleteBrokeragePopup;
