import { useApi } from "contexts/ApiProvider";
import { useInsuranceProcess } from "../InsuranceProcessProvider";
import { FC, useCallback } from "react";
import { usePopup } from "contexts/PopupProvider";
import NotAnchor from "components/NotAnchor";
import { Popup, createFormPopup } from "components/Popup";
import { z } from "zod";
import { usePage } from "components/Page";
import classNames from "classnames";
import { isBinderActive, isQuote } from "utils/policies";

const RequiredSubjectivitiesPopup = () => (
    <Popup name="required-subjectivities">
        <header>
            <h2 className="color-primary font-medium">
                Required Subjectivities
            </h2>
        </header>
        <p className="last-child">
            These are items that must be completed to issue a policy. They can
            only be marked as complete by an Underwriter. Note: you can still
            issue a binder with incomplete required subjectivites, but the
            actual policy cannot be issued until these are complete.
        </p>
    </Popup>
);

const addSubjectivitySchema = z.object({
    title: z.string().min(1, "This field is required"),
});

type AddSubjectivityType = z.infer<typeof addSubjectivitySchema>;

const {
    FormPopup: AddSubjectivityFormPopup,
    useFormReturnRef: useAddSubjectivityFormReturnRef,
} = createFormPopup(addSubjectivitySchema);

const AddSubjectivityPopup: FC = () => {
    const { tryCatchAndRaiseError } = usePage();
    const {
        itemGetters: { getInsuranceProcess },
        insuranceProcessDispatch,
    } = useInsuranceProcess();
    const { submission, parsedSubmissionData } = getInsuranceProcess();
    const { formReturnRefCallback } = useAddSubjectivityFormReturnRef();
    const { sdkInsurance } = useApi();

    const onSubmit = useCallback(
        async (data: AddSubjectivityType) => {
            const parsedSubmissionDataClone = parsedSubmissionData.clone();
            const randomCode = Math.random().toString(36).substring(2, 12);
            parsedSubmissionDataClone.createSubjectivity(
                "0",
                randomCode,
                data.title
            );

            tryCatchAndRaiseError(async () => {
                await sdkInsurance.updateSubmissionData({
                    id: submission.id,
                    CreateSubmissionData: {
                        data: parsedSubmissionDataClone.asApiSubmissionData(
                            ["0"],
                            {
                                subjectivities: { [randomCode]: true },
                            }
                        ),
                    },
                });
                const updatedParsedSubmissionData =
                    await sdkInsurance.getParsedSubmissionData(submission.id);
                insuranceProcessDispatch({
                    action: "SetParsedSubmissionData",
                    parsedSubmissionData: updatedParsedSubmissionData,
                });
            });
        },
        [
            insuranceProcessDispatch,
            parsedSubmissionData,
            sdkInsurance,
            submission.id,
            tryCatchAndRaiseError,
        ]
    );

    return (
        <AddSubjectivityFormPopup
            name="add-subjectivity"
            defaultValues={{
                title: "",
            }}
            submitText="Save"
            formReturnRefCallback={formReturnRefCallback}
            onSubmit={onSubmit}
        >
            {({ register, formState: { errors } }) => (
                <>
                    <header>
                        <h2>Additional Subjectivity</h2>
                    </header>
                    <p
                        className={classNames({
                            "has-error": !!errors.title,
                        })}
                    >
                        <label htmlFor="ifs"> Title</label>
                        <textarea
                            {...register("title")}
                            id="ifs"
                            aria-invalid="false"
                        />
                        {errors.title && (
                            <label
                                id="ifs-error"
                                className="error"
                                htmlFor="ifs"
                            >
                                {errors.title.message}
                            </label>
                        )}
                    </p>
                </>
            )}
        </AddSubjectivityFormPopup>
    );
};

const SubjectivitiesSection: FC = () => {
    const {
        itemGetters: { getInsuranceProcess },
        insuranceProcessDispatch,
    } = useInsuranceProcess();
    const { submission, quote, parsedSubmissionData } = getInsuranceProcess();
    const { sdkInsurance } = useApi();
    const { openPopup } = usePopup();
    const editableSubjectivities = isQuote(quote) || isBinderActive(quote);

    const markSubjectivityAsChecked = async (
        code: string,
        checked: boolean
    ) => {
        const parsedSubmissionDataClone = parsedSubmissionData.clone();
        parsedSubmissionDataClone.markSubjectivity("0", code, checked);

        await sdkInsurance.updateSubmissionData({
            id: submission.id,
            CreateSubmissionData: {
                data: parsedSubmissionDataClone.asApiSubmissionData(["0"], {
                    subjectivityCheckedOnly: { [code]: true },
                }),
            },
        });
        const updatedParsedSubmissionData =
            await sdkInsurance.getParsedSubmissionData(submission.id);

        insuranceProcessDispatch({
            action: "SetParsedSubmissionData",
            parsedSubmissionData: updatedParsedSubmissionData,
        });
    };

    const deleteSubjectivity = async (code: string) => {
        await sdkInsurance.deleteSubmissionData({
            id: submission.id,
            DeleteSubmissionData: {
                data: [
                    { code: `subj.${code}` },
                    { code: `subj.${code}.checked` },
                ],
            },
        });
        const updatedParsedSubmissionData =
            await sdkInsurance.getParsedSubmissionData(submission.id);

        insuranceProcessDispatch({
            action: "SetParsedSubmissionData",
            parsedSubmissionData: updatedParsedSubmissionData,
        });
    };

    const subjectivities = parsedSubmissionData.assets["0"].subjectivities;

    // If no subjectivities were added (Object.entries(subjectivities).length === 0)
    // and the "Add Subjectivity" button should be disabled (editableSubjectivities = isQuote(quote) || quote.status === QuoteStatus.BinderPending;)
    // so the section has no purpose, return nothing.
    if (subjectivities.length === 0 && !editableSubjectivities) {
        return <></>;
    }

    return (
        <>
            <h2>
                <i className="icon-error" /> Required Subjectivities
                <NotAnchor
                    className="text-right"
                    onClick={() => {
                        openPopup("required-subjectivities"); // TODO
                    }}
                >
                    <i aria-hidden="true" className="icon-help" />
                    <span className="hidden">More info</span>
                </NotAnchor>
            </h2>
            {subjectivities.length > 0 && (
                <ul className="check small">
                    {subjectivities.map(([code, subjectivity]) => {
                        return (
                            <li key={code}>
                                <input
                                    type="checkbox"
                                    className="check-list"
                                    id={code}
                                    checked={subjectivity.included}
                                    onChange={(event) =>
                                        markSubjectivityAsChecked(
                                            code,
                                            event.target.checked
                                        )
                                    }
                                    value={code}
                                    disabled={!editableSubjectivities}
                                />
                                <label htmlFor={code}>
                                    {subjectivity.name}
                                </label>
                                {editableSubjectivities && (
                                    <NotAnchor
                                        className="text-right"
                                        onClick={() => deleteSubjectivity(code)}
                                    >
                                        <i
                                            aria-hidden="true"
                                            className="icon-trash"
                                        />
                                    </NotAnchor>
                                )}
                            </li>
                        );
                    })}
                </ul>
            )}
            {editableSubjectivities && (
                <p className="link-strong submission-subjectivities">
                    <NotAnchor
                        onClick={() => {
                            openPopup("add-subjectivity");
                        }}
                    >
                        <i aria-hidden="true" className="icon-plus-circle" />{" "}
                        Add Subjectivity
                    </NotAnchor>
                </p>
            )}
        </>
    );
};

export {
    SubjectivitiesSection,
    RequiredSubjectivitiesPopup,
    AddSubjectivityPopup,
};
