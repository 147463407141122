import { DetailedHTMLProps, FC, ReactEventHandler } from "react";

interface ImgInterface
    extends Omit<
        DetailedHTMLProps<
            React.ImgHTMLAttributes<HTMLImageElement>,
            HTMLImageElement
        >,
        "src" | "alt"
    > {
    src?: string | File | undefined;
    alt: string;
}

const Img: FC<ImgInterface> = ({ src, onLoad, alt, ...rest }) => {
    let src_: string | undefined;
    let onLoad_: ReactEventHandler<HTMLImageElement> | undefined;
    if (src instanceof File) {
        src_ = URL.createObjectURL(src);
        onLoad_ = (...rest) => {
            URL.revokeObjectURL(src_ as string);
            if (onLoad) {
                return onLoad(...rest);
            }
        };
    } else {
        src_ = src;
        onLoad_ = onLoad;
    }
    return <img src={src_} onLoad={onLoad_} alt={alt} {...rest} />;
};

export default Img;
