import { OngoingChangeUserRole, SubmissionFlow } from "@joshuins/insurance";
import { Page } from "components/Page";
import ExtraPane from "components/extra-panes/ExtraPane";
import { useAvailableProductVersions } from "pages/components/use-create-submission-wizard";
import PoliciesList from "./components/PoliciesList";

const InReviewExtraPane = () => (
    // TODO: fix text after decisions about where to present pending quotes.
    <ExtraPane>
        <h3>
            <i aria-hidden="true" className="icon-help" /> About In Review
        </h3>
        <p className="size-14">
            This is where all new submissions and bind requests will appear.
            Consider it your underwriting inbox!
        </p>
    </ExtraPane>
);

const InReviewPage = () => {
    const availableProducts = useAvailableProductVersions();
    return (
        <Page>
            <PoliciesList
                title="In Review"
                onGoing={Object.values(SubmissionFlow)}
                availableProducts={availableProducts}
                userRole={OngoingChangeUserRole.Underwriter}
            />
            <InReviewExtraPane />
        </Page>
    );
};

export default InReviewPage;
