/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import axios from "axios";
import { FC, ReactNode, useCallback, useEffect, useState } from "react";
import { MainPane } from "components/MainPane";
import MenuPopover from "components/MenuPopover";
import NotAnchor from "components/NotAnchor";
import { AlertCategory, Page, usePage } from "components/Page";
import { ConfirmationPopup, createFormPopup } from "components/Popup";
import { useApi } from "contexts/ApiProvider";
import { usePopup } from "contexts/PopupProvider";
import { formatDatetimeString, parseISO8601 } from "utils/datetime";
import differenceWith from "lodash/differenceWith";
import isEqual from "lodash/isEqual";
import { z } from "zod";
import ExtraPane from "components/extra-panes/ExtraPane";
import { Select } from "components/ReactHookFormUncontrolledComponents";
import { MenuItem } from "components/Select";
import range from "lodash/range";
import pick from "lodash/pick";
import times from "lodash/times";
import { useAuthUser } from "react-auth-kit";
import {
    AlertExpectedError,
    InfoExpectedError,
    isAlertExpectedError,
    isInfoExpectedError,
} from "utils/error";

import type { ProductVersionView } from "@joshuins/builder";
import type { User, UpdateUser } from "@joshuins/system";
import { isNotNull } from "utils";
import { unpaginate } from "components/sdk";
import { includes } from "utils/array";
import { fullName } from "utils/user";
import { getMessageFromAxiosError } from "utils/axios-extras";

type SortType = "Recent" | "User A-Z";

const inviteAndEditTeamMemberSchema = z.object({
    first_name: z.string().min(1, "This field is required"),
    last_name: z.string().min(1, "This field is required"),
    email: z.string().email(),
    role: z.enum(["Admin", "Underwriter"], {
        errorMap: () => ({ message: "Please choose a role" }),
    }),
    products: z
        .union([
            z.object({
                productId: z.boolean(),
                // The "or" part is for the case of chooisng an authority level but then unselecting the product.
                // In this case, the authority stays still set
                authorityLevel: z.undefined().or(z.string().regex(/^\d+$/)),
            }),
            z.object({
                productId: z.string().regex(/^\d+$/),
                authorityLevel: z
                    .string({
                        errorMap: () => ({
                            message: "Choose",
                        }),
                    })
                    .regex(/^\d+$/, "Choose"),
            }),
        ])
        .array(),
});

type InviteAndEditTeamMemberType = z.infer<
    typeof inviteAndEditTeamMemberSchema
>;

const { FormPopup, useFormReturnRef } = createFormPopup(
    inviteAndEditTeamMemberSchema
);

const accountStatusClass = (accountStatus: string): string => {
    switch (accountStatus) {
        case "Active":
            return "apple";
        case "Inactive":
            return "wine";
        default:
            return "gold";
    }
};

const InviteAndEditTeamMemberPopup: FC = () => {
    const { sdkBuilder, sdkSystem } = useApi();
    const { addAlertMessages, refreshState } = usePage();
    const { popupData, isPopupOpen } = usePopup();

    const [latestProductVersions, setlatestProductVersions] =
        useState<ProductVersionView[]>();
    const [
        userProductIdsAndAuthorityLevels,
        setUserProductIdsAndAuthorityLevels,
    ] = useState<{ productId: number; authorityLevel: number }[]>();
    const [inputsDisabled, setInputsDisabled] = useState<boolean>(false);
    const {
        formReturn: { reset },
        formReturnRefCallback,
    } = useFormReturnRef();
    const authUser = useAuthUser();

    const currentUser = authUser() as User;
    const existingUserIdToUpdate = popupData?.userId as number;
    const shouldSetUserToPending =
        !existingUserIdToUpdate ||
        (popupData?.forceSetUserToPendingOnSubmit ?? false);

    useEffect(() => {
        const getUser = async () => {
            if (!reset) {
                return;
            }

            let latestProductVersions_: ProductVersionView[] | undefined;
            if (isPopupOpen("invite-and-edit-team-member")) {
                latestProductVersions_ =
                    await sdkBuilder.getLatestProductVersions();
                setlatestProductVersions(latestProductVersions_);
            }

            if (!latestProductVersions_ || !existingUserIdToUpdate) {
                setInputsDisabled(false);
                setUserProductIdsAndAuthorityLevels(undefined);
                return;
            }

            const user_ = await sdkSystem.getUser({
                id: existingUserIdToUpdate,
            });
            const userProducts = await unpaginate(sdkBuilder.allProductUsers, {
                user_id: existingUserIdToUpdate,
            });
            const userProductIdsAndAuthorityLevels_ = userProducts.map(
                ({ product_id, authority_level }) => ({
                    productId: product_id,
                    authorityLevel: authority_level,
                })
            );
            setUserProductIdsAndAuthorityLevels(
                userProductIdsAndAuthorityLevels_
            );

            const userProductIdToAuthorityLevelLookup = userProducts.reduce(
                (previousValue, { product_id, authority_level }) => {
                    previousValue[product_id] = authority_level;
                    return previousValue;
                },
                {} as Record<number, number>
            );

            const formProducts = latestProductVersions_.reduce(
                (previousValue, { product_id: productId }, index) => {
                    const authorityLevel =
                        userProductIdToAuthorityLevelLookup[productId];
                    if (authorityLevel) {
                        previousValue[index].productId = productId.toString();
                        previousValue[index].authorityLevel =
                            authorityLevel.toString();
                    }
                    return previousValue;
                },
                times(
                    latestProductVersions_.length,
                    () =>
                        ({
                            productId: false,
                            authorityLevel: undefined,
                        }) as {
                            productId: string | false;
                            authorityLevel: string | undefined;
                        }
                )
            );

            reset({
                ...pick(user_, ["email"]),
                first_name: user_.first_name === null ? "" : user_.first_name,
                last_name: user_.last_name === null ? "" : user_.last_name,
                role: includes(["Admin", "Underwriter"] as const, user_.role)
                    ? user_.role
                    : undefined,
                products: formProducts,
            });
            setInputsDisabled(true);
        };
        getUser();
    }, [
        sdkBuilder,
        popupData,
        isPopupOpen,
        existingUserIdToUpdate,
        sdkSystem,
        reset,
    ]);

    const onSubmit = useCallback(
        async (data: InviteAndEditTeamMemberType) => {
            try {
                let newUserProductIdsAndAuthorityLevels: {
                    productId: number;
                    authorityLevel: number;
                }[];
                if (data.role === "Underwriter") {
                    newUserProductIdsAndAuthorityLevels = data.products
                        .map(({ productId, authorityLevel }) =>
                            typeof productId === "boolean"
                                ? null
                                : {
                                      productId: parseInt(productId),
                                      authorityLevel: parseInt(authorityLevel),
                                  }
                        )
                        .filter(isNotNull);

                    if (newUserProductIdsAndAuthorityLevels.length === 0) {
                        throw new InfoExpectedError(
                            "An underwriter must have at least one product assigned."
                        );
                    }
                } else {
                    newUserProductIdsAndAuthorityLevels = [];
                }

                const userProductIdsAndAuthorityLevelsToAdd = differenceWith(
                    newUserProductIdsAndAuthorityLevels,
                    userProductIdsAndAuthorityLevels ?? [],
                    isEqual
                );

                const userProductIdsAndAuthorityLevelsToRemove = differenceWith(
                    userProductIdsAndAuthorityLevels,
                    newUserProductIdsAndAuthorityLevels,
                    isEqual
                );

                let currentUserId: number;
                if (existingUserIdToUpdate) {
                    if (existingUserIdToUpdate === currentUser.id) {
                        throw new InfoExpectedError(
                            "Cannot change self permissions. Contact customer service if needed."
                        );
                    }
                    currentUserId = existingUserIdToUpdate;
                    await sdkSystem.updateUser({
                        id: existingUserIdToUpdate,
                        UpdateUser: pick<UpdateUser>(data, ["role"]),
                    });
                } else {
                    const existingUsers = await unpaginate(sdkSystem.allUsers, {
                        email: data.email,
                    });
                    if (existingUsers.length > 0) {
                        throw new AlertExpectedError(
                            "You already have an active team member with the same email address."
                        );
                    }
                    const user = await sdkSystem.createUser({
                        CreateUser: {
                            ...pick(data, [
                                "email",
                                "first_name",
                                "last_name",
                                "role",
                            ]),
                            account_status: "Inactive",
                        },
                    });
                    currentUserId = user.id;
                }

                for (const {
                    productId,
                } of userProductIdsAndAuthorityLevelsToRemove) {
                    await sdkBuilder.deleteProductUser({
                        product_id: productId,
                        user_id: currentUserId,
                    });
                }

                for (const {
                    productId,
                    authorityLevel,
                } of userProductIdsAndAuthorityLevelsToAdd) {
                    await sdkBuilder.createProductUser({
                        NewProductUser: {
                            product_id: productId,
                            user_id: currentUserId,
                            authority_level: authorityLevel,
                        },
                    });
                }

                let successMessage;
                const fullName_ = fullName(data);
                if (existingUserIdToUpdate) {
                    if (data.role === "Admin") {
                        successMessage = `${fullName_} was successfully added as an admin!`;
                    } else {
                        successMessage = `${fullName_} was successfully updated!`;
                    }
                } else {
                    successMessage = `${fullName_} was successfully added as an ${data.role.toLowerCase()}!`;
                }

                if (shouldSetUserToPending) {
                    await sdkSystem.updateUser({
                        id: currentUserId,
                        UpdateUser: {
                            account_status: "Pending",
                        },
                    });
                }

                addAlertMessages({
                    message: successMessage,
                    category: AlertCategory.SUCCESS,
                });
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    addAlertMessages({
                        message: getMessageFromAxiosError(error),
                        category: AlertCategory.ALERT,
                    });
                } else if (isAlertExpectedError(error)) {
                    addAlertMessages({
                        message: error.message,
                        category: AlertCategory.ALERT,
                    });
                } else if (isInfoExpectedError(error)) {
                    addAlertMessages({
                        message: error.message,
                        category: AlertCategory.INFO,
                    });
                } else {
                    throw error;
                }
            } finally {
                refreshState();
            }
        },
        [
            sdkBuilder,
            existingUserIdToUpdate,
            addAlertMessages,
            refreshState,
            userProductIdsAndAuthorityLevels,
            currentUser,
            shouldSetUserToPending,
            sdkSystem,
        ]
    );

    return (
        <FormPopup
            name="invite-and-edit-team-member"
            defaultValues={{
                first_name: "",
                last_name: "",
                email: "",
                role: undefined,
                products: [],
            }}
            onSubmit={onSubmit}
            submitText={
                shouldSetUserToPending ? "Send Invite" : "Update Profile"
            }
            mobileSubmitText={shouldSetUserToPending ? "Send" : "Update"}
            formReturnRefCallback={formReturnRefCallback}
            overlayPopups={[
                <>
                    <h3>Role</h3>
                    <p>
                        Underwriters are generally assigned to specific products
                        and can only access the Joshu Underwriter Desk, not the
                        Builder (this interface you are using right now). They
                        cannot modify products or stores.
                    </p>
                    <p>
                        Admins have full access to Joshu Builder to create and
                        modify products and stores. They can also create new
                        submissions in order to test products.
                    </p>
                    <p>
                        Note that neither underwriters nor admins can sign in to
                        a Store as a broker. If you would like to test that
                        experience, register as a new user on that store (or
                        invite yourself) using a different email address than
                        the one you sign in with here. A handy trick if you have
                        GMail: use your name &quot;+&quot; something to create
                        an alias email that will still be delivered to your
                        inbox. For example &quot;myname+alias@gmail.com&quot;.
                    </p>
                </>,
                <>
                    <h3>Products &amp; Authority Level </h3>
                    <p>
                        You can assign underwriters to specific products. All
                        underwriters can see all submissions for products they
                        are authorized to underwrite. An underwriter must be
                        assigned to at least one product.
                    </p>
                    <p>
                        The authority level determines if an underwriter is
                        allowed to quote, publish a binder, and issue policy.
                        All these actions are allowed if the authority level
                        given to the underwriter is greater than quote authority
                        level as returned from the rater. (See help in the Rater
                        section of the product for more on implementing
                        authority level.)
                    </p>
                </>,
            ]}
        >
            {({
                openOverlayPopup,
                register,
                control,
                watch,
                formState: { errors },
            }) => (
                <>
                    <header>
                        {existingUserIdToUpdate ? (
                            <h2>Profile</h2>
                        ) : (
                            <>
                                <h2>Invite Team</h2>
                                <p className="size-14">
                                    Invite Underwriters, give optional Admin
                                    privileges.
                                </p>
                            </>
                        )}
                    </header>
                    <div className="cols">
                        <p className="c50">
                            <label htmlFor="iga">First Name</label>
                            <input
                                type="text"
                                id="iga"
                                {...register("first_name")}
                                disabled={inputsDisabled}
                                readOnly={inputsDisabled}
                            />
                            {errors.first_name && (
                                <label
                                    id="iga-error"
                                    className="error"
                                    htmlFor="iga"
                                >
                                    {errors.first_name.message}
                                </label>
                            )}
                        </p>
                        <p className="c50">
                            <label htmlFor="igb">Last Name</label>
                            <input
                                type="text"
                                id="igb"
                                {...register("last_name")}
                                disabled={inputsDisabled}
                                readOnly={inputsDisabled}
                            />
                            {errors.last_name && (
                                <label
                                    id="igb-error"
                                    className="error"
                                    htmlFor="igb"
                                >
                                    {errors.last_name.message}
                                </label>
                            )}
                        </p>
                    </div>
                    <p>
                        <label htmlFor="igc">
                            Email Address
                            <span className="text-right">
                                Cannot be changed later!
                            </span>
                        </label>
                        <input
                            type="text"
                            id="igc"
                            {...register("email")}
                            disabled={inputsDisabled}
                            readOnly={inputsDisabled}
                        />
                        {errors.email && (
                            <label
                                id="igc-error"
                                className="error"
                                htmlFor="igc"
                            >
                                {errors.email.message}
                            </label>
                        )}
                    </p>
                    <h3 className="label">
                        Role
                        <NotAnchor
                            onClick={() => openOverlayPopup(0)}
                            className="text-right"
                        >
                            <i aria-hidden="true" className="icon-help" />
                            <span className="hidden">More info</span>
                        </NotAnchor>
                    </h3>
                    <ul className="check inline font-medium">
                        <li>
                            <input
                                type="radio"
                                value="Underwriter"
                                id="role-uwr"
                                {...register("role")}
                            />
                            <label htmlFor="role-uwr">Underwriter</label>
                        </li>
                        <li>
                            <input
                                type="radio"
                                value="Admin"
                                id="role-adm"
                                {...register("role")}
                            />
                            <label htmlFor="role-adm">Admin</label>
                        </li>
                    </ul>
                    {errors.role && (
                        <label id="igc-error" className="error">
                            {errors.role.message}
                        </label>
                    )}
                    {watch("role") && (
                        <>
                            <h3 className="label">
                                Products &amp; Authority Level
                                <span className="text-right">
                                    At least one product must be chosen
                                </span>
                                <NotAnchor
                                    onClick={() => openOverlayPopup(1)}
                                    className="text-right"
                                    css={css`
                                        .label &.text-right {
                                            margin-left: 0;
                                        }
                                    `}
                                >
                                    <i
                                        aria-hidden="true"
                                        className="icon-help"
                                    />
                                    <span className="hidden">More info</span>
                                </NotAnchor>
                            </h3>
                            <ul className="check hr font-medium product-and-auth-level">
                                {watch("role") === "Admin" ? (
                                    <li className="size-14">
                                        Admins can view all products &amp;
                                        stores
                                    </li>
                                ) : watch("role") === "Underwriter" ? (
                                    latestProductVersions &&
                                    latestProductVersions.map(
                                        (productVersion, index) => (
                                            <li key={productVersion.product_id}>
                                                <input
                                                    type="checkbox"
                                                    id={`igdd-${productVersion.product_id}`}
                                                    value={
                                                        productVersion.product_id
                                                    }
                                                    {...register(
                                                        `products.${index}.productId`
                                                    )}
                                                />
                                                <label
                                                    htmlFor={`igdd-${productVersion.product_id}`}
                                                >
                                                    {productVersion.name}
                                                    {productVersion.internal_version_name &&
                                                        ` | ${productVersion.internal_version_name}`}
                                                    {!productVersion.is_published &&
                                                        " (Draft)"}
                                                </label>
                                                {watch(
                                                    `products.${index}.productId`
                                                ) && (
                                                    <div
                                                        className="div-as-p"
                                                        css={css`
                                                            margin-top: 0;
                                                            .check li > & {
                                                                position: static;
                                                                width: 80px;
                                                                padding: 0;
                                                                color: var(
                                                                    --black_100
                                                                );
                                                                font-weight: 400;
                                                                font-size: 16px;
                                                                text-align: left;
                                                            }
                                                            -webkit-align-self: center;
                                                            align-self: center;
                                                            top: 2px;
                                                            display: block;
                                                            margin: -6px 0 0
                                                                auto;
                                                        `}
                                                    >
                                                        <Select
                                                            css={css`
                                                                height: 32px;
                                                                width: 80px;
                                                                .check
                                                                    li
                                                                    > div
                                                                    &
                                                                    input {
                                                                    background-color: rgb(
                                                                        255,
                                                                        255,
                                                                        255
                                                                    );
                                                                    display: block;
                                                                    left: 0;
                                                                    margin: 0;
                                                                    opacity: 0;
                                                                    position: absolute;
                                                                    top: -2px;
                                                                    visibility: visible;
                                                                    z-index: 1;
                                                                }
                                                                &
                                                                    .MuiInputBase-input {
                                                                    line-height: 32px;
                                                                    width: 100%;
                                                                }
                                                            `}
                                                            labelId="levels-id"
                                                            displayEmpty
                                                            renderValue={(
                                                                value
                                                            ): ReactNode =>
                                                                value === ""
                                                                    ? "Choose..."
                                                                    : (value as string)
                                                            }
                                                            id={`igdd-${productVersion.product_id}`}
                                                            name={`products.${index}.authorityLevel`}
                                                            control={control}
                                                        >
                                                            {range(5).map(
                                                                (level) => (
                                                                    <MenuItem
                                                                        key={
                                                                            level +
                                                                            1
                                                                        }
                                                                        value={(
                                                                            level +
                                                                            1
                                                                        ).toString()}
                                                                    >
                                                                        {level +
                                                                            1}
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                        </Select>
                                                        {errors.products?.[
                                                            index
                                                        ]?.authorityLevel && (
                                                            <label
                                                                htmlFor={`igde-${productVersion.product_id}`}
                                                                className="error"
                                                                css={css`
                                                                    #root
                                                                        .check
                                                                        li
                                                                        > div
                                                                        & {
                                                                        color: var(
                                                                            --main_color
                                                                        );
                                                                    }
                                                                `}
                                                            >
                                                                {
                                                                    // wut? - Javascript is 🥴
                                                                    errors
                                                                        .products?.[
                                                                        index
                                                                    ]
                                                                        ?.authorityLevel
                                                                        ?.message
                                                                }
                                                            </label>
                                                        )}
                                                    </div>
                                                )}
                                            </li>
                                        )
                                    )
                                ) : (
                                    <></>
                                )}
                            </ul>
                        </>
                    )}
                </>
            )}
        </FormPopup>
    );
};

const DisableAccountPopup: FC = () => {
    const { sdkBuilder, sdkSystem } = useApi();
    const { tryCatchAndRaiseError, refreshState, addAlertMessages } = usePage();
    const { popupData, isPopupOpen } = usePopup();
    const [userToBeBlocked, setUserToBeBlocked] = useState<User>();
    const authUser = useAuthUser();

    const userIdToBeBlocked = popupData?.userId as number;
    const currentUser = authUser() as User;

    useEffect(() => {
        const getUser = async () => {
            if (!isPopupOpen("disable-account") || !userIdToBeBlocked) {
                return;
            }
            const user_ = await sdkSystem.getUser({ id: userIdToBeBlocked });
            setUserToBeBlocked(user_);
        };
        getUser();
    }, [sdkBuilder, userIdToBeBlocked, isPopupOpen, sdkSystem]);

    const onSubmit = useCallback(async () => {
        if (!userIdToBeBlocked) {
            return;
        } else if (userIdToBeBlocked === currentUser.id) {
            addAlertMessages({
                message: "Self blocking is not permitted",
                category: AlertCategory.INFO,
            });
            return;
        }
        tryCatchAndRaiseError(async () => {
            await sdkSystem.updateUser({
                id: userIdToBeBlocked,
                UpdateUser: {
                    account_status: "Inactive",
                },
            });
            const userFullName = userToBeBlocked
                ? fullName(userToBeBlocked)
                : "";

            addAlertMessages({
                message: `${userFullName}${
                    userFullName ? " " : ""
                }member has been blocked`,
                category: AlertCategory.SUCCESS,
            });
        }, refreshState);
    }, [
        userIdToBeBlocked,
        currentUser.id,
        tryCatchAndRaiseError,
        refreshState,
        addAlertMessages,
        sdkSystem,
        userToBeBlocked,
    ]);

    return (
        <ConfirmationPopup
            name="disable-account"
            onSubmit={onSubmit}
            submitText="Disable Account"
            mobileSubmitText="Disable"
        >
            {userToBeBlocked && (
                <>
                    <header>
                        <h2>Disable Account?</h2>
                    </header>
                    <ul className="list-static a">
                        <li>
                            {fullName(userToBeBlocked)} will be
                            <span className="strong"> hidden </span>
                            from all products & stores and
                            <span className="strong"> blocked </span> from
                            signing in.
                        </li>
                        <li>
                            All of their
                            <span className="strong">
                                {" "}
                                quotes, binders, and policies
                            </span>{" "}
                            will still be visible to Admins.
                        </li>
                    </ul>
                </>
            )}
        </ConfirmationPopup>
    );
};

const TeamExtraPane: FC = () => (
    <ExtraPane>
        <h3>
            <i aria-hidden="true" className="icon-help" /> About Team
        </h3>
        <p className="size-14">
            This is where you invite and manage underwriters and other admins on
            your team.
        </p>
        <ul className="list-icon b">
            <li>
                <i aria-hidden="true" className="icon-shield" /> Assign Products{" "}
                <span>
                    Underwriters will automatically be notified when the
                    products they are assigned to get new submissions.
                </span>
            </li>
            <li>
                <i aria-hidden="true" className="icon-keys" /> Set Authority
                Levels{" "}
                <span>
                    Set different levels of authority for your team. Submissions
                    will be routed to the proper level.
                </span>
            </li>
        </ul>
    </ExtraPane>
);

const userCreatedAtDate = (user: User) => parseISO8601(user.created_at);

const Main = () => {
    const { sdkBuilder, sdkSystem } = useApi();
    const { openPopup } = usePopup();
    const [users, setUsers] = useState<User[]>();
    const { stateId } = usePage();
    const [uiSortType, setUiSortType] = useState<SortType>();
    const [dataSortType, setDataSortType] = useState<SortType>();

    const sortAndSetUsers = useCallback<(users: User[]) => void>(
        (users) => {
            let sortValueGetter: (user: User) => Date | string;
            let sortedUsers;

            if (uiSortType) {
                switch (uiSortType) {
                    case "Recent":
                        sortValueGetter = userCreatedAtDate;
                        break;
                    case "User A-Z":
                        sortValueGetter = fullName;
                        break;
                }
                sortedUsers = [...users].sort((userA, userB) => {
                    const userAValue = sortValueGetter(userA);
                    const userBValue = sortValueGetter(userB);
                    let order;
                    if (userAValue > userBValue) {
                        order = 1;
                    } else if (userAValue < userBValue) {
                        order = -1;
                    } else {
                        return 0;
                    }

                    if (uiSortType === "Recent") {
                        order = -order;
                    }
                    return order;
                });
            } else {
                sortedUsers = [...users];
            }

            setUsers(sortedUsers);
            setDataSortType(uiSortType);
        },
        [uiSortType]
    );

    useEffect(() => {
        const getUsers = async () => {
            const adminUsers = await unpaginate(sdkSystem.allUsers, {
                role: "Admin",
            });
            const underwriterUsers = await unpaginate(sdkSystem.allUsers, {
                role: "Underwriter",
            });
            sortAndSetUsers(adminUsers.concat(underwriterUsers));
        };

        getUsers();
    }, [sdkBuilder, stateId, sortAndSetUsers, sdkSystem]);

    useEffect(() => {
        if (!users || dataSortType === uiSortType) {
            return;
        }
        sortAndSetUsers(users);
    }, [users, uiSortType, dataSortType, sortAndSetUsers]);

    if (users === undefined) {
        return <></>;
    }

    return (
        <MainPane
            title="Team"
            cta={{
                buttonLabel: "Invite Team",
                popupName: "invite-and-edit-team-member",
            }}
            layoutConfig={{
                mainLayout: !users || users.length === 0 ? "center" : "wide",
            }}
            headerMenu={{
                items: [
                    {
                        key: "recent",
                        label: "Recent",
                        icon: "time",
                        onClick: () => {
                            setUiSortType("Recent");
                        },
                    },
                    {
                        key: "user-a-z",
                        label: "User A-Z",
                        icon: "id",
                        onClick: () => {
                            setUiSortType("User A-Z");
                        },
                    },
                ],
                toggleButtonLabel: uiSortType ?? "Recent",
            }}
        >
            {users && users.length > 0 ? (
                <ul className="list-plain box no-img">
                    {users.map((user, index) => (
                        <MenuPopover
                            component="li"
                            additionalClasses={["list-plain-li"]}
                            key={user.id}
                            style={{ zIndex: users.length - index }}
                            menuItems={[
                                {
                                    key: "resend-invitation",
                                    label: "Resend Invitation",
                                    icon: "envelope",
                                    onClick: () => {
                                        openPopup(
                                            "invite-and-edit-team-member",
                                            {
                                                userId: user.id,
                                                forceSetUserToPendingOnSubmit:
                                                    true,
                                            }
                                        );
                                    },
                                },
                                {
                                    key: "disable-account",
                                    label: "Disable Account",
                                    icon: "trash",
                                    hasSeparator: true,
                                    onClick: () => {
                                        openPopup("disable-account", {
                                            userId: user.id,
                                        });
                                    },
                                },
                            ]}
                        >
                            {({ ToggleButton, Menu }) => (
                                <>
                                    <NotAnchor
                                        onClick={() =>
                                            openPopup(
                                                "invite-and-edit-team-member",
                                                {
                                                    userId: user.id,
                                                }
                                            )
                                        }
                                        role="tab"
                                    >
                                        {fullName(user)}
                                    </NotAnchor>
                                    {ToggleButton}
                                    <ul className="list-inline a">
                                        <li>
                                            Added{" "}
                                            {formatDatetimeString(
                                                user.created_at
                                            )}
                                        </li>
                                        <li
                                            className={`overlay color-${accountStatusClass(
                                                user.account_status
                                            )}`}
                                        >
                                            <span>
                                                {user.account_status.toUpperCase()}
                                            </span>
                                        </li>
                                    </ul>
                                    {Menu}
                                </>
                            )}
                        </MenuPopover>
                    ))}
                </ul>
            ) : (
                <div className="module-success inline">
                    <h2>
                        <i className="icon-cart-window" /> No users Yet
                    </h2>
                    <p>
                        Click &quot;New Store&quot; above to start setting up
                        your first store.
                    </p>
                </div>
            )}
        </MainPane>
    );
};

const TeamPage: FC = () => (
    <Page>
        <Main />
        <TeamExtraPane />
        <InviteAndEditTeamMemberPopup />
        <DisableAccountPopup />
    </Page>
);

export default TeamPage;
