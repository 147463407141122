import { Page } from "components/Page";
import ExtraPane from "components/extra-panes/ExtraPane";
import { useApi } from "contexts/ApiProvider";
import { FC, useCallback, useEffect, useState } from "react";
import { MainPane } from "components/MainPane";
import EmptyPage from "pages/underwriter/quotes_and_policies/components/quote-and-policy-sections/EmptyPage";
import { AllPolicySyncs200Response } from "@joshuins/system";
import NotAnchor from "components/NotAnchor";
import { formatDatetimeString } from "utils/datetime";
import { useNavigate, useParams } from "react-router-dom";
import urlJoin from "url-join";
import { statusToColor } from "pages/components/util";

const Main = () => {
    const { sdkSystem } = useApi();
    const navigate = useNavigate();
    const [policySyncs, setPolicySyncs] = useState<AllPolicySyncs200Response>();
    const { policyId } = useParams();

    const loadTraces = useCallback(async () => {
        const policySyncs_ = await sdkSystem.allPolicySyncs({
            quote_id: policyId ? Number(policyId) : undefined,
        });
        setPolicySyncs(policySyncs_);
    }, [policyId, sdkSystem]);

    useEffect(() => {
        const getTraces = async () => {
            loadTraces();
        };
        getTraces();
    }, [loadTraces, sdkSystem]);

    if (!policySyncs) {
        return <></>;
    }

    return (
        <MainPane
            title={"Policy Sync"}
            layoutConfig={{
                mainLayout:
                    !policySyncs || policySyncs.items.length === 0
                        ? "center"
                        : "wide",
            }}
        >
            {policySyncs.items.length > 0 ? (
                <>
                    <ul className="list-plain box no-img">
                        {policySyncs.items.map((policySync) => (
                            <li key={policySync.id}>
                                <NotAnchor
                                    onClick={() =>
                                        navigate(
                                            urlJoin(
                                                "/",
                                                "admin",
                                                "system",
                                                "traces",
                                                `?objectId=${policySync.id.toString()}`
                                            )
                                        )
                                    }
                                >
                                    {policySync.event}
                                </NotAnchor>
                                <ul className="list-inline a">
                                    <li>{`Created at ${formatDatetimeString(policySync.created_at, "withTime")}`}</li>
                                    <li>Policy No. {policySync.quote_id}</li>
                                    <li
                                        className={`overlay ${statusToColor(
                                            policySync.status
                                        )}`}
                                    >
                                        <span>
                                            {policySync.status.toString()}
                                        </span>
                                    </li>
                                </ul>
                            </li>
                        ))}
                    </ul>
                </>
            ) : (
                <EmptyPage
                    title={"Policy Sync"}
                    text={"No Syncs Yet"}
                    icon={"icon-css"}
                />
            )}
        </MainPane>
    );
};

const PolicySyncExtraPane = () => (
    <ExtraPane>
        <h3>
            <i aria-hidden="true" className="icon-help" /> Traces
        </h3>
        <p className="size-14">{/* TODO: enter text */}</p>
    </ExtraPane>
);

const PolicySyncPage: FC = () => (
    <Page>
        <Main />
        <PolicySyncExtraPane />
    </Page>
);

export default PolicySyncPage;
