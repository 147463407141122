import { FC } from "react";
import {
    Quote,
    QuoteCodeAndValue,
    QuoteStatus,
    SubmissionFlow,
} from "@joshuins/insurance";
import { useInsuranceProcess } from "./components/InsuranceProcessProvider";
import {
    CloseQuoteLink,
    CloseEndorsementQuoteLink,
} from "./components/insurance-process-popups/CloseQuotePopup";
import { DeclineSubmissionLink } from "./components/insurance-process-popups/DeclineSubmissionPopup";
import {
    PublishQuoteLink,
    PublishEndorsementQuoteLink,
} from "./components/insurance-process-popups/PublishQuotePopup";
import { BindQuoteLink } from "./components/insurance-process-popups/BindQuotePopup";
import { NewVariationLink } from "./components/insurance-process-popups/NewVariationPopup";
import { IssueEndorsementLink } from "./components/insurance-process-popups/IssueEndorsementPopup";
import { useBranding } from "contexts/BrandingProvider";
import { SendQuoteForReviewLink } from "./components/insurance-process-popups/SendForReviewPopup";
import { SaveChangesLink } from "./components/insurance-process-popups/SaveQuoteChanges";
import { isBinder, isPolicy } from "utils/policies";

function quoteFooterClassifier(
    quote: Quote,
    submissionType: SubmissionFlow,
    inStore: boolean,
    dirtyFields: QuoteCodeAndValue[]
) {
    const showSaveChanges = dirtyFields.length !== 0;
    if (
        !inStore &&
        (submissionType == SubmissionFlow.New ||
            submissionType == SubmissionFlow.Renewal) &&
        quote.status === QuoteStatus.QuotePending
    ) {
        return <UWPendingFooter showSaveChanges={showSaveChanges} />;
    }
    if (
        !inStore &&
        (submissionType == SubmissionFlow.New ||
            submissionType == SubmissionFlow.Renewal) &&
        quote.status === QuoteStatus.QuotePublished
    ) {
        return <UWPublishedFooter isDirty={dirtyFields.length !== 0} />;
    }
    if (
        !inStore &&
        submissionType == SubmissionFlow.Endorsement &&
        quote.status === QuoteStatus.QuotePending
    ) {
        return <UWPendingEndorsementFooter showSaveChanges={showSaveChanges} />;
    }
    if (
        !inStore &&
        submissionType == SubmissionFlow.Endorsement &&
        quote.status === QuoteStatus.QuotePublished
    ) {
        return <UWPublishedEndorsementFooter />;
    }

    if (
        inStore &&
        (submissionType == SubmissionFlow.New ||
            submissionType == SubmissionFlow.Renewal) &&
        quote.status === QuoteStatus.QuoteStoreEdit
    ) {
        return (
            <StoreEditFooter quote={quote} showSaveChanges={showSaveChanges} />
        );
    }
    if (
        inStore &&
        (submissionType == SubmissionFlow.New ||
            submissionType == SubmissionFlow.Renewal) &&
        quote.status === QuoteStatus.QuotePublished
    ) {
        return <StorePublishedFooter />;
    }
    if (
        inStore &&
        submissionType == SubmissionFlow.Endorsement &&
        quote.status === QuoteStatus.QuoteStoreEdit
    ) {
        return <StoreEditEndorsementFooter showSaveChanges={showSaveChanges} />;
    }
    if (
        inStore &&
        submissionType == SubmissionFlow.Endorsement &&
        quote.status === QuoteStatus.QuotePublished
    ) {
        return (
            <>
                <p className="link-btn">
                    <IssueEndorsementLink />
                </p>
                <a className="toggle toggle-sub" href="./"></a>
            </>
        );
    }
    return <></>;
}

const UWPublishedFooter: FC<{ isDirty: boolean }> = ({ isDirty }) => {
    return (
        <>
            <p className="link-btn has-inline">
                <NewVariationLink />
                <CloseQuoteLink />
            </p>
            <p className="link-btn">{!isDirty && <BindQuoteLink />}</p>
            <a className="toggle toggle-sub" href="./"></a>
        </>
    );
};

const UWPublishedEndorsementFooter: FC = () => {
    return (
        <>
            <p className="link-btn has-inline">
                <CloseEndorsementQuoteLink />
            </p>
            <p className="link-btn">
                <IssueEndorsementLink />
            </p>
            <a className="toggle toggle-sub" href="./"></a>
        </>
    );
};

const UWPendingEndorsementFooter: FC<{ showSaveChanges: boolean }> = ({
    showSaveChanges,
}) => {
    return (
        <>
            <p className="link-btn has-inline">
                <CloseEndorsementQuoteLink />
                <PublishEndorsementQuoteLink />
            </p>
            <p className="link-btn">
                {showSaveChanges ? (
                    <SaveChangesLink />
                ) : (
                    <IssueEndorsementLink />
                )}
            </p>
            <a className="toggle toggle-sub" href="./"></a>
        </>
    );
};

const UWPendingFooter: FC<{ showSaveChanges: boolean }> = ({
    showSaveChanges,
}) => {
    return (
        <>
            <p className="link-btn has-inline">
                <DeclineSubmissionLink />
                <CloseQuoteLink />
            </p>
            <p className="link-btn">
                {showSaveChanges ? <SaveChangesLink /> : <PublishQuoteLink />}
            </p>
            {/* TODO: replace this <a> to support hiding links
            in menu in mobile mode */}
            <a className="toggle toggle-sub" href="./"></a>
        </>
    );
};

const StorePublishedFooter: FC = () => {
    return (
        <>
            <p className="link-btn has-inline">
                <NewVariationLink />
            </p>
            <p className="link-btn">
                <BindQuoteLink />
            </p>
            <a className="toggle toggle-sub" href="./"></a>
        </>
    );
};

const StoreEditFooter: FC<{ quote: Quote; showSaveChanges: boolean }> = ({
    quote,
    showSaveChanges,
}) => {
    return (
        <>
            <p className="link-btn">
                {showSaveChanges ? (
                    <SaveChangesLink />
                ) : quote.authority_level === 0 ? (
                    <PublishQuoteLink />
                ) : (
                    <SendQuoteForReviewLink />
                )}
            </p>
            <a className="toggle toggle-sub" href="./"></a>
        </>
    );
};

const StoreEditEndorsementFooter: FC<{ showSaveChanges: boolean }> = ({
    showSaveChanges,
}) => {
    return (
        <>
            <p className="link-btn has-inline">
                <CloseEndorsementQuoteLink />
            </p>
            <p className="link-btn">
                {showSaveChanges ? (
                    <SaveChangesLink />
                ) : (
                    <SendQuoteForReviewLink />
                )}
            </p>
            <a className="toggle toggle-sub" href="./"></a>
        </>
    );
};

const QuoteFooter: FC = () => {
    const {
        itemGetters: { getInsuranceProcess },
        insuranceProcessState: { dirtyFields: dirtyFields },
    } = useInsuranceProcess();
    const { quote, submission } = getInsuranceProcess();

    const { storeUrlName } = useBranding();
    const inStore = storeUrlName !== undefined;

    if (isPolicy(quote) || isBinder(quote)) {
        return <></>;
    }

    {
        return (
            <>
                {quoteFooterClassifier(
                    quote,
                    submission.flow,
                    inStore,
                    dirtyFields
                )}
            </>
        );
    }
};

export { QuoteFooter };
