import { FC } from "react";
import { PolicyStatus, QuoteStatus, SubmissionFlow } from "@joshuins/insurance";
import { useInsuranceProcess } from "./components/InsuranceProcessProvider";
import { useBranding } from "contexts/BrandingProvider";
import { SaveChangesLink } from "./components/insurance-process-popups/SaveQuoteChanges";
import {
    ManagePolicyLink,
    ManagePolicyStoreLink,
} from "./components/insurance-process-popups/ManagePolicyPopup";
import {
    CancelBinderLink,
    DeclineBindRequestLink,
} from "./components/insurance-process-popups/DeclineBindRequestPopup";
import { EditBinderLink } from "./components/insurance-process-popups/EditBinderPopup";
import { IssuePolicyLink } from "./components/insurance-process-popups/IssuePolicyPopup";
import { PublishBinderLink } from "./components/insurance-process-popups/PublishBinderPopup";
import { CancelPolicyLink } from "./components/insurance-process-popups/CancelPolicy";
import { DeclineCancellationLink } from "./components/insurance-process-popups/DeclineCancellationPopup";

const UWPendingCancellationFooter: FC = () => {
    return (
        <>
            <p className="link-btn has-inline">
                <DeclineCancellationLink />
            </p>
            <p className="link-btn">
                <CancelPolicyLink />
            </p>
        </>
    );
};

const UWPendingBinderFooter: FC<{ numModifiedDocuments: number }> = ({
    numModifiedDocuments,
}) => {
    return (
        <>
            <p className="link-btn has-inline">
                <DeclineBindRequestLink />
                <EditBinderLink />
                <PublishBinderLink />
            </p>
            <p className="link-btn">
                {numModifiedDocuments > 0 ? (
                    <SaveChangesLink />
                ) : (
                    <IssuePolicyLink />
                )}
            </p>
        </>
    );
};

const UWPublishedBinderFooter: FC<{ numModifiedDocuments: number }> = ({
    numModifiedDocuments,
}) => {
    return (
        <>
            <p className="link-btn has-inline">
                <CancelBinderLink />
                <EditBinderLink />
            </p>
            <p className="link-btn">
                {numModifiedDocuments > 0 ? (
                    <SaveChangesLink />
                ) : (
                    <IssuePolicyLink />
                )}
            </p>
        </>
    );
};

const PolicyFooter: FC = () => {
    const {
        itemGetters: { getInsuranceProcess },
        insuranceProcessState: { dirtyFields: dirtyFields },
        insuranceHistory,
    } = useInsuranceProcess();
    const { submission, policyView, quote: policy } = getInsuranceProcess();
    const { storeUrlName } = useBranding();
    const inStore = storeUrlName !== undefined;

    if (!policy) {
        return <></>;
    }

    return (
        <>
            {(() => {
                switch (policy.status) {
                    case QuoteStatus.QuotePending: {
                        if (inStore) {
                            return <></>;
                        } else {
                            return <UWPendingCancellationFooter />;
                        }
                    }
                    case QuoteStatus.BinderPending: {
                        if (inStore) {
                            return <></>;
                        } else {
                            return (
                                <UWPendingBinderFooter
                                    numModifiedDocuments={dirtyFields.length}
                                />
                            );
                        }
                    }
                    case QuoteStatus.BinderPublished: {
                        if (inStore) {
                            return (
                                <p className="link-btn">
                                    <IssuePolicyLink />
                                </p>
                            );
                        } else {
                            return (
                                <UWPublishedBinderFooter
                                    numModifiedDocuments={dirtyFields.length}
                                />
                            );
                        }
                    }
                    case QuoteStatus.CoveragePending: {
                        if (inStore) {
                            return <></>;
                        } else {
                            return (
                                <p className="link-btn">
                                    <IssuePolicyLink />
                                </p>
                            );
                        }
                    }
                    case QuoteStatus.CoverageActive: {
                        if (
                            (policyView?.status === PolicyStatus.Active ||
                                policyView?.status === PolicyStatus.Future) &&
                            insuranceHistory.length === 1 &&
                            submission.flow === SubmissionFlow.New &&
                            !inStore
                        ) {
                            if (inStore) {
                                return (
                                    <p className="link-btn has-inline">
                                        <ManagePolicyStoreLink />
                                    </p>
                                );
                            } else {
                                return (
                                    <p className="link-btn has-inline">
                                        <ManagePolicyLink />
                                    </p>
                                );
                            }
                        } else {
                            return <></>;
                        }
                    }
                    default: {
                        return <></>;
                    }
                }
            })()}
        </>
    );
};
export { PolicyFooter };
