import {
    cloneElement,
    ReactElement,
    ReactNode,
    FC,
    PropsWithChildren,
} from "react";

// https://dev.to/alfredosalzillo/the-react-context-hell-7p4
// Although... even though all this code implements a MultiProvider, it can also
// be used with other React components too i.e. components that are not providers.
const nest = (children: ReactNode, component: ReactElement) =>
    cloneElement(component, {}, children);

type MultiProviderProps = PropsWithChildren<{
    providers: ReactElement[];
}>;

const MultiProvider: FC<MultiProviderProps> = ({ children, providers }) => (
    <>{providers.reduceRight(nest, children)}</>
);

export default MultiProvider;
