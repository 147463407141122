import { Navigate, Outlet, RouteObject } from "react-router-dom";
import authRoutes from "./auth/routes";
import { FC, useEffect } from "react";
import { SetStoreFromReactRouterParams } from "contexts/BrandingProvider";
import { BUILDER_PATH, STORE_PATH, UNDERWRITER_PATH } from "globals";
import urlJoin from "url-join";
import { useAuthUser } from "react-auth-kit";
import { User } from "@joshuins/auth";
import { useApi } from "contexts/ApiProvider";
import { QuoteOrPolicyPage } from "./underwriter/quotes_and_policies/QuoteOrPolicyPage";
import InsuredsPage from "./components/ListInsuredsPage";
import NewInsuredPage from "./components/NewInsuredPage";
import NewSubmissionPage from "./components/NewSubmissionPage";
import InsuredPage from "./components/InsuredPage";
import ScreeningPage from "./underwriter/submissions/ScreeningPage";
import ListProductsPage from "./builder/products/ListProductsPage";
import HomePage from "./builder/products/HomePage";
import RaterPage from "./builder/products/RaterPage";
import DocumentsPage from "./builder/products/DocumentsPage";
import ApplicationPage from "./underwriter/submissions/ApplicationPage";
import IntegrationsPage from "./builder/products/IntegrationsPage";
import BindRequirementsPage from "./builder/products/BindRequirementsPage";
import SettingsPage from "./builder/products/SettingsPage";
import ListStoresPage from "./builder/stores/ListStoresPage";
import StoreHomePage from "./builder/stores/StoreHomePage";
import AccessPage from "./builder/stores/AccessPage";
import ThemePage from "./builder/stores/ThemePage";
import StoreSettingsPage from "./builder/stores/StoreSettingsPage";
import TeamPage from "./builder/team/TeamPage";
import DirectoryPage from "./builder/directory/DirectoryPage";
import ReportsPage from "./builder/reports/ReportsPage";
import InsuredDetailsPage from "./builder/system/InsuredDetailsPage";
import NotificationsPage from "./builder/system/NotificationsPage";
import SsoPage from "./builder/system/SsoPage";
import DataExportPage from "./builder/system/DataExportPage";
import PolicyNumberPoolsPage from "./builder/system/PolicyNumerPoolsPage";
import ProductImportPage from "./builder/system/ProductImportPage";
import SystemIntegrationsPage from "./builder/system/IntegrationsPage";
import StoreSubmissionsPage from "./store/submissions/StoreSubmissionsPage";
import StoreQuotesPage from "./store/quotes/StoreQuotesPage";
import StoreBindersPage from "./store/binders/StoreBindersPage";
import StorePoliciesPage from "./store/policies/StorePoliciesPage";
import InReviewPage from "./underwriter/quotes_and_policies/InReviewPage";
import UnderwritersBindersPage from "./underwriter/quotes_and_policies/UnderwritersBindersPage";
import UnderwritersPoliciesPage from "./underwriter/quotes_and_policies/UnderwritersPoliciesPage";
import UnderwritersQuotesPage from "./underwriter/quotes_and_policies/UnderwritersQuotesPage";
import ApplicationBuilderPage from "./builder/products/ApplicationBuilderPage";
import ListApiTokensPage from "./builder/api-tokens/ListApiTokensPage";
import RevealNewApiTokenPage from "./builder/api-tokens/RevealNewApiTokenPage";
import SingleIntegrationPage from "./builder/system/SingleIntegrationPage";
import PolicySyncPage from "./builder/system/PolicySyncPage";
import TracesPage from "./builder/system/TracesPage";
import TraceLogsPage from "./builder/system/TraceLogsPage";
import PendingApprovalPage from "./store/submissions/PendingApprovalPage";

const NotFound: FC = () => {
    return (
        <>
            <div id="wrap" className="text-center">
                <main id="content text-center">
                    <div className="module-success inline">
                        <h2>
                            <i
                                aria-hidden="true"
                                className="icon-alert-thin"
                            ></i>{" "}
                            We can&lsquo;t load this page...
                        </h2>
                        <p>
                            Either the page doesn&lsquo;t exist, or you
                            don&lsquo;t have permission to access it. Check your
                            link, or <a href="/auth/login">sign in</a> to try
                            again.
                        </p>
                    </div>
                </main>
            </div>
        </>
    );
};

const IndexRoute: FC = () => {
    const authUser = useAuthUser();
    const { sdkInsurance } = useApi();

    const currentUser = authUser() as User;

    useEffect(() => {
        const redirect = async () => {
            if (currentUser) {
                if (currentUser.role === "Admin") {
                    window.location.href = urlJoin("/", BUILDER_PATH);
                } else if (currentUser.role === "Underwriter") {
                    window.location.href = urlJoin("/", UNDERWRITER_PATH);
                } else {
                    const storeName = localStorage.getItem("storeUrlName");
                    if (storeName) {
                        window.location.href = urlJoin(
                            "/",
                            STORE_PATH,
                            storeName
                        );
                    } else {
                        const { items: stores } =
                            await sdkInsurance.allStores();
                        if (stores.length > 0) {
                            window.location.href = urlJoin(
                                "/",
                                STORE_PATH,
                                stores[0].store.url_slug
                            );
                        }
                    }
                }
                return;
            }
            window.location.href = "/auth/login";
            return;
        };
        redirect();
    }, [currentUser, sdkInsurance]);
    return <></>;
};

const quoteOrPolicyTabs = [
    {
        title: "Details",
        path: "details",
    },
    {
        title: "Bind Requirements",
        path: "bind_requirements",
    },
];

const documentsBuilderTabs = [
    {
        title: "Application",
        path: "application",
    },
    {
        title: "Quote",
        path: "new-quote",
    },
    {
        title: "Binder",
        path: "binder",
    },
    {
        title: "Policy",
        path: "policy",
    },
    {
        title: "Midterm",
        path: "endorsement-quote",
    },
    {
        title: "Renewal",
        path: "renewal-quote",
    },
    {
        title: "Cancel",
        path: "cancellation",
    },
];

const bindRequiremenetsTabs = [
    {
        title: "Subjectivities",
        path: "subjectivities",
    },
    {
        title: "Supporting Information",
        path: "supporting-information",
    },
];

const productSettingsTabs = [
    {
        title: "General",
        path: "general",
    },
    {
        title: "Policy IDs",
        path: "policy-ids",
    },
];

const storesListTabs = [
    {
        title: "Broker",
        path: "broker",
    },
    {
        title: "Direct",
        path: "direct",
    },
];

const storesAccessTabs = [
    {
        title: "People",
        path: "people",
    },
    {
        title: "Brokerage",
        path: "brokerage",
    },
];

const dataExportTabs = [
    {
        title: "Targets",
        path: "targets",
    },
    {
        title: "Encryption Keys",
        path: "keys",
    },
    {
        title: "Data Dumps",
        path: "files",
    },
];

const underwriterRoutes: RouteObject[] = [
    {
        path: UNDERWRITER_PATH,
        element: <Navigate to="in-review" replace />,
    },
    {
        path: `${UNDERWRITER_PATH}/quotes/:quoteId`,
        element: <Navigate to="details" replace />,
    },
    {
        path: `${UNDERWRITER_PATH}/policies/:policyId`,
        element: <Navigate to="details" replace />,
    },
    {
        path: `${UNDERWRITER_PATH}/submissions/:submissionId`,
        element: <Navigate to="application" replace />,
    },
    {
        path: UNDERWRITER_PATH,
        children: [
            {
                path: "in-review",
                element: <InReviewPage />,
            },
            {
                path: "quotes",
                element: <UnderwritersQuotesPage />,
            },
            {
                path: "quotes/:quoteId",
                children: [
                    {
                        path: "details",
                        element: <QuoteOrPolicyPage tabs={quoteOrPolicyTabs} />,
                    },
                    {
                        path: "bind_requirements",
                        element: <QuoteOrPolicyPage tabs={quoteOrPolicyTabs} />,
                    },
                ],
            },
            {
                path: "binders",
                element: <UnderwritersBindersPage />,
            },
            {
                path: "policies",
                element: <UnderwritersPoliciesPage />,
            },
            {
                path: "policies/:policyId",
                children: [
                    {
                        path: "details",
                        element: <QuoteOrPolicyPage tabs={quoteOrPolicyTabs} />,
                    },
                    {
                        path: "bind_requirements",
                        element: <QuoteOrPolicyPage tabs={quoteOrPolicyTabs} />,
                    },
                ],
            },
            {
                path: "insureds",
                element: <InsuredsPage />,
            },
            {
                path: "insureds/new",
                element: <NewInsuredPage />,
            },
            {
                path: "insureds/:insuredId/new-submission",
                element: <NewSubmissionPage />,
            },
            {
                path: "insureds/:insuredId",
                element: <InsuredPage />,
            },
            {
                path: "submissions/:submissionId",
                children: [
                    {
                        path: "application",
                        element: <ApplicationPage />,
                    },
                    {
                        path: "application/:sectionCode",
                        element: <ApplicationPage />,
                    },
                    {
                        path: "screening",
                        element: <ScreeningPage />,
                    },
                ],
            },
        ],
    },
];

const builderRoutes: RouteObject[] = [
    {
        path: BUILDER_PATH,
        element: <Navigate to="products" replace />,
    },
    {
        path: `${BUILDER_PATH}/products/:productId`,
        element: <Navigate to="home" replace />,
    },
    {
        path: `${BUILDER_PATH}/products`,
        element: <ListProductsPage />,
    },
    {
        path: `${BUILDER_PATH}/products/:productId/documents`,
        element: <Navigate to="application" replace />,
    },
    {
        path: `${BUILDER_PATH}/products/bind-requirements`,
        element: <Navigate to="subjectivities" replace />,
    },
    {
        path: `${BUILDER_PATH}/products/:productId/settings`,
        element: <Navigate to="general" replace />,
    },
    {
        path: `${BUILDER_PATH}/stores`,
        element: <Navigate to="broker" />,
    },
    {
        path: `${BUILDER_PATH}/stores/:storeId`,
        element: <Navigate to="home" />,
    },
    {
        path: `${BUILDER_PATH}/stores/:storeId/access`,
        element: <Navigate to="people" />,
    },
    {
        path: `${BUILDER_PATH}/stores/system`,
        element: <Navigate to="integrations" />,
    },
    {
        path: BUILDER_PATH,
        children: [
            {
                path: "products/:productId",
                children: [
                    {
                        path: "home",
                        element: <HomePage />,
                    },
                    {
                        path: "application",
                        element: <ApplicationBuilderPage />,
                    },
                    {
                        path: "rater",
                        element: <RaterPage />,
                    },
                    {
                        path: "documents",
                        children: [
                            {
                                path: "application",
                                element: (
                                    <DocumentsPage
                                        tabs={documentsBuilderTabs}
                                    />
                                ),
                            },
                            {
                                path: "new-quote",
                                element: (
                                    <DocumentsPage
                                        tabs={documentsBuilderTabs}
                                    />
                                ),
                            },
                            {
                                path: "binder",
                                element: (
                                    <DocumentsPage
                                        tabs={documentsBuilderTabs}
                                    />
                                ),
                            },
                            {
                                path: "policy",
                                element: (
                                    <DocumentsPage
                                        tabs={documentsBuilderTabs}
                                    />
                                ),
                            },
                            {
                                path: "endorsement-quote",
                                element: (
                                    <DocumentsPage
                                        tabs={documentsBuilderTabs}
                                    />
                                ),
                            },
                            {
                                path: "renewal-quote",
                                element: (
                                    <DocumentsPage
                                        tabs={documentsBuilderTabs}
                                    />
                                ),
                            },
                            {
                                path: "cancellation",
                                element: (
                                    <DocumentsPage
                                        tabs={documentsBuilderTabs}
                                    />
                                ),
                            },
                        ],
                    },
                    {
                        path: "integrations",
                        element: <IntegrationsPage />,
                    },
                    {
                        path: "bind-requirements",
                        children: [
                            {
                                path: "subjectivities",
                                element: (
                                    <BindRequirementsPage
                                        tabs={bindRequiremenetsTabs}
                                    />
                                ),
                            },
                            {
                                path: "supporting-information",
                                element: (
                                    <BindRequirementsPage
                                        tabs={bindRequiremenetsTabs}
                                    />
                                ),
                            },
                        ],
                    },
                    {
                        path: "settings",
                        children: [
                            {
                                path: "general",
                                element: (
                                    <SettingsPage tabs={productSettingsTabs} />
                                ),
                            },
                            {
                                path: "policy-ids",
                                element: (
                                    <SettingsPage tabs={productSettingsTabs} />
                                ),
                            },
                        ],
                    },
                ],
            },
            {
                path: "stores",
                children: [
                    {
                        path: "broker",
                        element: <ListStoresPage tabs={storesListTabs} />,
                    },
                    {
                        path: "direct",
                        element: <ListStoresPage tabs={storesListTabs} />,
                    },
                ],
            },
            {
                path: "stores/:storeId",
                children: [
                    {
                        path: "home",
                        element: <StoreHomePage />,
                    },
                    {
                        path: "access",
                        children: [
                            {
                                path: "people",
                                element: <AccessPage tabs={storesAccessTabs} />,
                            },
                            {
                                path: "brokerage",
                                element: <AccessPage tabs={storesAccessTabs} />,
                            },
                        ],
                    },
                    {
                        path: "theme",
                        element: <ThemePage />,
                    },
                    {
                        path: "settings",
                        element: <StoreSettingsPage />,
                    },
                ],
            },
            {
                path: "team",
                element: <TeamPage />,
            },
            {
                path: "directory",
                element: <DirectoryPage />,
            },
            {
                path: "reports",
                element: <ReportsPage />,
            },
            {
                path: "system",
                children: [
                    {
                        path: "integrations",
                        element: <SystemIntegrationsPage />,
                    },
                    {
                        path: "integrations/:integrationId",
                        element: <SingleIntegrationPage />,
                    },
                    {
                        path: "insured-details",
                        element: <InsuredDetailsPage />,
                    },
                    {
                        path: "notifications",
                        element: <NotificationsPage />,
                    },
                    {
                        path: "sso",
                        element: <SsoPage />,
                    },
                    {
                        path: "data-export",
                        element: <Navigate to="targets" />,
                    },
                    {
                        path: "data-export/targets",
                        element: <DataExportPage tabs={dataExportTabs} />,
                    },
                    {
                        path: "data-export/keys",
                        element: <DataExportPage tabs={dataExportTabs} />,
                    },
                    {
                        path: "data-export/files",
                        element: <DataExportPage tabs={dataExportTabs} />,
                    },
                    {
                        path: "policy-number-pools",
                        element: <PolicyNumberPoolsPage />,
                    },
                    {
                        path: "policy-sync",
                        element: <PolicySyncPage />,
                    },
                    {
                        path: "policy-sync/:policyId",
                        element: <PolicySyncPage />,
                    },
                    {
                        path: "traces",
                        element: <TracesPage />,
                    },
                    {
                        path: "traces/:traceId",
                        element: <TraceLogsPage />,
                    },
                    {
                        path: "product-imports/:productExportId",
                        element: <ProductImportPage />,
                    },
                ],
            },
            {
                path: "api-tokens",
                element: <ListApiTokensPage />,
                children: [
                    {
                        path: "reveal-new",
                        element: <RevealNewApiTokenPage />,
                    },
                ],
            },
        ],
    },
];

const storeRoutes: RouteObject[] = [
    {
        path: `${STORE_PATH}/:storeUrlName`,
        element: <Navigate to="submissions" />,
    },
    {
        path: `${STORE_PATH}/:storeUrlName/submissions/:submissionId`,
        element: <Navigate to="application" />,
    },
    {
        path: `${STORE_PATH}/:storeUrlName/quotes/:quoteId`,
        element: <Navigate to="details" />,
    },
    {
        path: `${STORE_PATH}/:storeUrlName/policies/:policiesId`,
        element: <Navigate to="details" />,
    },
    {
        path: `${STORE_PATH}/:storeUrlName/insureds/:insuredId`,
        element: <Navigate to="details" />,
    },
    {
        path: `${STORE_PATH}/:storeUrlName`,
        children: [
            {
                path: "submissions",
                element: <StoreSubmissionsPage />,
            },
            {
                path: "insureds/:insuredId",
                children: [
                    {
                        path: "details",
                        element: <InsuredPage />,
                    },
                    {
                        path: "new-submission",
                        element: <NewSubmissionPage />,
                    },
                ],
            },
            {
                path: "submissions/:submissionId",
                children: [
                    {
                        path: "application",
                        element: <ApplicationPage />,
                    },
                    {
                        path: "application/:sectionCode",
                        element: <ApplicationPage />,
                    },
                    {
                        path: "screening",
                        element: <ScreeningPage />,
                    },
                    {
                        path: "pending",
                        element: <PendingApprovalPage />,
                    },
                ],
            },
            {
                path: "quotes",
                element: <StoreQuotesPage />,
            },
            {
                path: "quotes/:quoteId",
                children: [
                    {
                        path: "details",
                        element: <QuoteOrPolicyPage tabs={quoteOrPolicyTabs} />,
                    },
                    {
                        path: "bind_requirements",
                        element: <QuoteOrPolicyPage tabs={quoteOrPolicyTabs} />,
                    },
                ],
            },
            {
                path: "binders",
                element: <StoreBindersPage />,
            },
            {
                path: "policies",
                element: <StorePoliciesPage />,
            },
            {
                path: "policies/:policyId",
                children: [
                    {
                        path: "details",
                        element: <QuoteOrPolicyPage tabs={quoteOrPolicyTabs} />,
                    },
                    {
                        path: "bind_requirements",
                        element: <QuoteOrPolicyPage tabs={quoteOrPolicyTabs} />,
                    },
                ],
            },
            {
                path: "insureds",
                element: <InsuredsPage />,
            },
            {
                path: "insureds/new",
                element: <NewInsuredPage />,
            },
        ],
    },
];

const authenticationRoutes: RouteObject[] = [
    {
        path: "auth",
        children: authRoutes({ indexRedirectUrl: "/auth/login" }),
    },
    {
        element: (
            <SetStoreFromReactRouterParams>
                <Outlet />
            </SetStoreFromReactRouterParams>
        ),
        path: `${STORE_PATH}/:storeUrlName/auth`,
        children: authRoutes({ includeRegisterRoutes: true }),
    },
];

const otherRoutes: RouteObject[] = [
    {
        index: true,
        element: <IndexRoute />,
    },
    {
        path: "*",
        element: <NotFound />,
    },
];

export {
    storeRoutes,
    builderRoutes,
    otherRoutes,
    authenticationRoutes,
    underwriterRoutes,
};
