import { useNavigate } from "components/DevAwareRoutingLink";
import { MainPane } from "components/MainPane";
import MenuPopover from "components/MenuPopover";
import NotAnchor from "components/NotAnchor";
import { Page, usePage } from "components/Page";
import { ConfirmationPopup, createFormPopup } from "components/Popup";
import { useApi } from "contexts/ApiProvider";
import { usePopup } from "contexts/PopupProvider";
import { FC, useCallback, useEffect, useState } from "react";
import { formatDatetimeString } from "utils/datetime";
import { z } from "zod";
import ApiTokensExtraPane from "./components/ApiTokensExtraPane";
import urlJoin from "url-join";
import { BUILDER_PATH } from "globals";

import type { ApiToken } from "@joshuins/auth";

const REVEAL_NEW_PATH = urlJoin("/", BUILDER_PATH, "api-tokens", "reveal-new");

const createApiTokenSchema = z.object({
    name: z.string().min(1, "This field is required"),
});

type CreateApiTokenType = z.infer<typeof createApiTokenSchema>;

const { FormPopup } = createFormPopup(createApiTokenSchema);

const CreateApiTokenPopup: FC = () => {
    const navigate = useNavigate();

    const onSubmit = useCallback(
        async (data: CreateApiTokenType) => {
            navigate(REVEAL_NEW_PATH, {
                state: { requestedName: data.name },
            });
        },
        [navigate]
    );

    return (
        <FormPopup
            name="create-api-token"
            defaultValues={{
                name: "",
            }}
            onSubmit={onSubmit}
            submitText="Create"
        >
            {({ register }) => (
                <>
                    <header>
                        <h2>Create API Token</h2>
                    </header>
                    <p>
                        Please enter a name to describe the new API token. This
                        name doesn&apos;t have to be unique.
                    </p>
                    <p>
                        <label htmlFor="name">Name</label>
                        <input type="text" {...register("name")} />
                    </p>
                </>
            )}
        </FormPopup>
    );
};

const RevokeApiTokenPopup: FC = () => {
    const { sdkAuth } = useApi();
    const { popupData } = usePopup();
    const { refreshState, tryCatchAndRaiseError } = usePage();
    const popupData_ = popupData as unknown as { id: string };

    const onSubmit = useCallback(async () => {
        if (!popupData_) {
            return;
        }
        tryCatchAndRaiseError(async () => {
            await sdkAuth.deleteApiToken({ id: popupData_.id });
        }, refreshState);
    }, [popupData_, tryCatchAndRaiseError, refreshState, sdkAuth]);

    return (
        <ConfirmationPopup
            name="revoke-api-token"
            onSubmit={onSubmit}
            submitText="Revoke Token"
            mobileSubmitText="Revoke Token"
        >
            {popupData && (
                <>
                    <header>
                        <h2>Revoke Token?</h2>
                    </header>
                    <p>
                        API Token{" "}
                        <span
                            style={{ fontFamily: "'Courier'" }}
                            className="strong"
                        >
                            {popupData.id as string}
                        </span>{" "}
                        will be revoked and all API calls using it will receive
                        a 401 UNAUTHORIZED response.
                    </p>
                </>
            )}
        </ConfirmationPopup>
    );
};

const ApiTokensList: FC<{ apiTokens: ApiToken[] }> = ({ apiTokens }) => {
    const { openPopup } = usePopup();

    return (
        <ul className="list-plain box no-img">
            {apiTokens.map(({ id, name, last_used_at }, index) => (
                <MenuPopover
                    additionalClasses={["list-plain-li"]}
                    key={id}
                    menuItems={[
                        {
                            key: "revoke-token",
                            label: "Revoke Token",
                            icon: "trash",
                            onClick: () =>
                                openPopup("revoke-api-token", {
                                    id,
                                }),
                        },
                    ]}
                    style={{
                        zIndex: apiTokens.length - index,
                    }}
                >
                    {({ ToggleButton, Menu }) => (
                        <>
                            <NotAnchor
                                onClick={() => {
                                    return;
                                }}
                            >
                                <span style={{ fontFamily: "'Courier'" }}>
                                    {id}
                                </span>
                                &nbsp;({name})
                            </NotAnchor>
                            {ToggleButton}
                            <ul className="list-inline a">
                                <li>
                                    {last_used_at ? (
                                        <>
                                            Last used{" "}
                                            {formatDatetimeString(last_used_at)}
                                        </>
                                    ) : (
                                        "Never used"
                                    )}
                                </li>
                            </ul>
                            {Menu}
                        </>
                    )}
                </MenuPopover>
            ))}
        </ul>
    );
};

const ListApiTokensMain: FC = () => {
    const { sdkAuth } = useApi();
    const [apiTokens, setApiTokens] = useState<ApiToken[]>();
    const { stateId } = usePage();

    useEffect(() => {
        const listApiTokens = async () => {
            const apiTokens_ = await sdkAuth.allApiTokens();
            setApiTokens(apiTokens_);
        };
        listApiTokens();
    }, [sdkAuth, stateId]);

    if (!apiTokens) {
        return <></>;
    }

    let content;
    if (apiTokens.length > 0) {
        content = <ApiTokensList apiTokens={apiTokens} />;
    } else {
        content = (
            <div className="module-success inline">
                <h2>
                    <i className="icon-lock-thin" /> No API Tokens Yet
                </h2>
                <p>
                    Click &quot;Create Token&quot; above to start setting up
                    your first API token.
                </p>
            </div>
        );
    }

    return (
        <MainPane
            title="API Tokens"
            cta={{
                buttonLabel: "Create Token",
                popupName: "create-api-token",
            }}
            layoutConfig={{
                mainLayout: apiTokens.length > 0 ? "wide" : "center",
            }}
        >
            {content}
        </MainPane>
    );
};

const ListApiTokensPage: FC = () => (
    <Page>
        <ListApiTokensMain />
        <ApiTokensExtraPane />
        <CreateApiTokenPopup />
        <RevokeApiTokenPopup />
    </Page>
);

export default ListApiTokensPage;
