import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import urlJoin from "url-join";

interface MountedPathProps {
    upTo?: number | undefined;
    addSuffix?: string[] | undefined;
}

const useMountedPath = (
    props: MountedPathProps | undefined = undefined
): string[] => {
    const { upTo, addSuffix } = props ?? {};

    const location = useLocation();
    return useMemo(() => {
        let splitPath_ = location.pathname.split("/").slice(1);
        if (upTo !== undefined) {
            splitPath_ = splitPath_.slice(0, upTo + 1);
        }
        if (addSuffix) {
            splitPath_ = splitPath_.concat(addSuffix);
        }
        return splitPath_;
    }, [location, upTo, addSuffix]);
};

const useMountedPathAsString = ({
    upTo,
    addSuffix,
}: MountedPathProps): string => {
    const mountedPath = useMountedPath({ upTo, addSuffix });
    return urlJoin("/", ...mountedPath);
};

export { useMountedPath, useMountedPathAsString };
