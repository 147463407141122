import { AuthApi as AuthApi_, BearerTokens, MeResponse } from "@joshuins/auth";

class AuthApi extends AuthApi_ {
    async login(
        email: string,
        password: string
    ): Promise<{ token: BearerTokens; me: MeResponse }> {
        const loginResponse = await this.passwordLogin({
            FinishLoginRequest: {
                email,
                password,
            },
        });

        const me_ = await this.me({
            headers: {
                Authorization: `${loginResponse.token_type} ${loginResponse.access_token}`,
            },
        });

        return {
            token: loginResponse,
            me: me_,
        };
    }

    async ssoLogin(
        providerSlug: string,
        state: string,
        code: string
    ): Promise<{
        nextUrl?: string;
        token: BearerTokens;
        me: MeResponse;
    }> {
        const {
            client_state: { next: nextUrl },
            ...tokenResponse
        } = await this.ssoLoginFinish({
            provider_slug: providerSlug,
            FinishSsoRequest: {
                state,
                code,
            },
        });

        const me_ = await this.me({
            headers: {
                Authorization: `${tokenResponse.token_type} ${tokenResponse.access_token}`,
            },
        });

        return {
            nextUrl,
            token: tokenResponse,
            me: me_,
        };
    }
}

export default AuthApi;
