import { FC } from "react";
import classNames from "classnames";
import {
    quoteStatusToColor,
    quoteStatusTimeDescription,
    quoteStatusToText,
} from "../../components/util";
import { useInsuranceProcess } from "./components/InsuranceProcessProvider";
import { useApi } from "contexts/ApiProvider";
import { useBranding } from "contexts/BrandingProvider";
import {
    QuoteStatus,
    RenewalSetting,
    SubmissionFlow,
} from "@joshuins/insurance";
import { Link } from "components/DevAwareRoutingLink";
import NotAnchor from "components/NotAnchor";
import { ListSubheader } from "@mui/material";
import { MenuItem, Select } from "components/Select";
import { TabInterface, usePage } from "components/Page";
import { HeaderDetails } from "./QuoteHeader";
import { getRawValueAsStringFromJoValue } from "utils/jo-types-and-values";
import urlJoin from "url-join";

const RENEWAL_STATUSES = [
    { value: RenewalSetting.AutoRenewal, title: "Auto Renewal" },
    { value: RenewalSetting.ReviewRequired, title: "Review Required" },
    { value: RenewalSetting.NonRenewable, title: "Non-Renewable" },
] as const;

const PolicyHeader: FC<{ tabs: TabInterface[] }> = ({ tabs }) => {
    const { sdkInsurance } = useApi();
    const {
        itemGetters: { getInsuranceProcess, getBindQuestions },
        insuranceProcessState,
    } = useInsuranceProcess();
    const bindQuestions = getBindQuestions();

    const {
        policyView,
        submission,
        quote: policy,
        submissionUser,
        insured,
        productVersion,
        quoteCodesAndValues,
    } = getInsuranceProcess();

    const { storeUrlName, generateUrl } = useBranding();
    const { activeTab } = usePage();
    const inStore = storeUrlName !== undefined;

    if (!insured || !policy || !policyView) {
        return <></>;
    }
    const externalNumbers: string[] = [];
    quoteCodesAndValues.map((data) => {
        if (data.code.startsWith("policy.id.")) {
            externalNumbers.push(
                getRawValueAsStringFromJoValue(data.rater_value)
            );
        }
    });
    const policyNumber =
        externalNumbers.length > 0
            ? externalNumbers.join(", ")
            : policyView.id.toString();

    let statusPill: { text: string; color: string } | undefined;
    const statusText = quoteStatusTimeDescription(
        policy,
        insuranceProcessState.insuranceProcess?.quoteCodesAndValues ?? []
    );
    const statusColor = quoteStatusToColor(policy.status);
    if (statusColor) {
        statusPill = {
            text: quoteStatusToText[policy.status],
            color: statusColor,
        };
    }

    const changeRenewal = async (value: RenewalSetting) => {
        if (policy && value) {
            await sdkInsurance.updateQuote({
                id: policy?.id,
                UpdateQuote: { renewal_setting: value },
            });
        }
    };

    return (
        <header className="header-double m35 a">
            <h1>
                {insured.name}
                <NotAnchor
                    className="close-tabs"
                    onClick={() => {
                        return;
                    }}
                />
            </h1>
            {!inStore &&
                policy &&
                submission &&
                submission.flow === SubmissionFlow.New &&
                policy.status === QuoteStatus.CoverageActive && (
                    <div className="m0" style={{ minWidth: "170px" }}>
                        <Select
                            labelId="renewal-status-id"
                            defaultValue={policy.renewal_setting}
                            displayEmpty
                            id={`igdd-${policy.id}`}
                            name={`renewal.status.policy-${policy.id}`}
                            style={{ width: "100%" }}
                            onChange={(event) => {
                                changeRenewal(
                                    event.target.value as RenewalSetting
                                );
                            }}
                        >
                            <ListSubheader
                                style={{
                                    color: "rgb(96, 76, 153)",
                                    fontFamily: "Red Hat Display",
                                    fontSize: "12px",
                                    fontWeight: "700",
                                    margin: "5px",
                                    padding: "5px",
                                    lineHeight: "15px",
                                }}
                            >
                                Renewal Status
                            </ListSubheader>
                            {RENEWAL_STATUSES.map((item) => (
                                <MenuItem
                                    key={item.title}
                                    value={item.value}
                                    style={{ lineHeight: "1.25" }}
                                >
                                    {item.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                )}
            <HeaderDetails
                productVersion={productVersion}
                submissionUser={submissionUser}
                submission={submission}
                quote={policy}
                inStore={inStore}
                statusText={statusText}
                statusPill={statusPill}
                policyNumber={policyNumber}
            />
            {policy.additional_details && (
                <ul className="list-inline" style={{ marginTop: "4px" }}>
                    <li className="color-wine">{policy.additional_details}</li>
                </ul>
            )}
            {bindQuestions &&
                bindQuestions.length > 0 &&
                submission.flow !== SubmissionFlow.Endorsement && (
                    <ul className="list-inline tabs strong module-tabs-nav is-tabs">
                        {tabs.map((tab) => (
                            <li
                                key={tab.path}
                                className={classNames({
                                    active: tab === activeTab(),
                                })}
                            >
                                <Link
                                    to={generateUrl(
                                        urlJoin(
                                            "/",
                                            "policies",
                                            policy.id.toString(),
                                            tab.path
                                        )
                                    )}
                                >
                                    {tab.title}
                                </Link>
                            </li>
                        ))}
                    </ul>
                )}
        </header>
    );
};

export { PolicyHeader };
