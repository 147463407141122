import { FC, useCallback, useEffect, useState } from "react";
import { useApi } from "contexts/ApiProvider";
import { useInsuranceProcess } from "./InsuranceProcessProvider";
import { useBranding } from "contexts/BrandingProvider";
import { useNavigate } from "components/DevAwareRoutingLink";
import { Quote, Submission, SubmissionFlow } from "@joshuins/insurance";
import ExtraPane from "components/extra-panes/ExtraPane";
import classNames from "classnames";
import NotAnchor from "components/NotAnchor";
import { formatDatetimeString } from "utils/datetime";

const NavigateSubmission: FC<{
    submission: Submission;
}> = ({ submission }) => {
    const {
        pageIds: { quoteId: pageQuoteId, policyId: pagePolicyId },
    } = useInsuranceProcess();
    const { sdkInsurance } = useApi();
    const { generateUrl } = useBranding();
    const navigate = useNavigate();
    const [quote, setQuote] = useState<Quote>();
    useEffect(() => {
        const getQuoteAndPolicy = async () => {
            const quoteId = pageQuoteId || pagePolicyId;
            if (quoteId) {
                const quote = await sdkInsurance.getQuote({ id: quoteId });
                setQuote(quote);
            }
        };
        getQuoteAndPolicy();
    }, [pageQuoteId, pagePolicyId, sdkInsurance, submission.policy_id]);

    const gotoSubmission = useCallback(
        async (submission: Submission) => {
            const submissionLink =
                await sdkInsurance.generateSubmissionLink(submission);
            if (submissionLink) {
                navigate(generateUrl(submissionLink));
            }
        },
        [generateUrl, navigate, sdkInsurance]
    );

    return (
        <>
            {quote && (
                <li
                    className={classNames({
                        active: quote.submission_id === submission.id,
                    })}
                >
                    <NotAnchor onClick={() => gotoSubmission(submission)}>
                        {submission.flow === SubmissionFlow.New
                            ? "New Policy"
                            : submission.flow}
                        <span>
                            Last modified{" "}
                            {formatDatetimeString(submission.modified_at)}
                        </span>
                    </NotAnchor>
                </li>
            )}
        </>
    );
};

const QuoteOrPolicyExtraPane: FC = () => {
    const { insuranceHistory } = useInsuranceProcess();

    return (
        <ExtraPane>
            <h3>
                <i aria-hidden="true" className="icon-help" /> Activity
            </h3>
            <p className="size-14">
                Use the links below to see the history of the original
                submission.
            </p>
            <ul className="list-plain a">
                {insuranceHistory.map((submission) => (
                    <NavigateSubmission
                        key={submission.id}
                        submission={submission}
                    />
                ))}
            </ul>
        </ExtraPane>
    );
};

export default QuoteOrPolicyExtraPane;
