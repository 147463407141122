/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { FC, useCallback, useEffect, useState } from "react";
import { z } from "zod";
import { ProductVersion, Store } from "@joshuins/builder";
import { AlertCategory, usePage } from "components/Page";
import { createFormPopup } from "components/Popup";
import { useApi } from "contexts/ApiProvider";
import { unpaginate } from "components/sdk";
import difference from "lodash/difference";

const ADD_TO_STORE = "add-to-store" as const;

const addToStoreFormSchema = z.object({
    storeIds: z.string().regex(/^\d+$/).array(),
});

type AddToStoreFormType = z.infer<typeof addToStoreFormSchema>;

const {
    FormPopup: AddToStoreFormPopup,
    useFormReturnRef: useAddToStoreFormReturnRef,
} = createFormPopup(addToStoreFormSchema);

const AddToStorePopup: FC = () => {
    const [allStores, setAllStores] = useState<{
        list: Store[];
        lookup: Record<number, Store>;
    }>();
    const {
        addAlertMessages,
        element,
        refreshState,
        stateId,
        tryCatchAndRaiseError,
    } = usePage();
    const [currentStoreIds, setCurrentStoreIds] = useState<number[]>();
    const productVersion = element as unknown as ProductVersion | undefined;
    const {
        formReturn: { reset },
        formReturnRefCallback,
    } = useAddToStoreFormReturnRef();

    const { sdkBuilder } = useApi();

    useEffect(() => {
        const getStores = async () => {
            if (!productVersion || !reset) {
                return;
            }

            const allStores_ = await unpaginate(sdkBuilder.allStores, {});
            setAllStores({
                list: allStores_,
                lookup: Object.fromEntries(
                    allStores_.map((store) => [store.id, store])
                ),
            });

            const storeProducts = await unpaginate(
                sdkBuilder.allStoreProducts,
                {
                    product_id: productVersion.product_id,
                }
            );
            const currentStoreIds_ = storeProducts.map(
                ({ store_id }) => store_id
            );
            setCurrentStoreIds(currentStoreIds_);
            reset({
                storeIds: currentStoreIds_.map((id) => id.toString()),
            });
        };
        getStores();
    }, [sdkBuilder, productVersion, stateId, reset]);

    const onSubmit = useCallback(
        async (data: AddToStoreFormType) => {
            const newStoreIds = data.storeIds.map((id) => parseInt(id));
            if (!currentStoreIds || !allStores || !productVersion || !reset) {
                return;
            }
            tryCatchAndRaiseError(async () => {
                const storeIdsToAdd = difference(newStoreIds, currentStoreIds);

                const storeIdsToRemove = difference(
                    currentStoreIds,
                    newStoreIds
                );

                for (const storeId of storeIdsToAdd) {
                    await sdkBuilder.createStoreProduct({
                        NewStoreProduct: {
                            product_id: productVersion.product_id,
                            store_id: storeId,
                        },
                    });
                }

                for (const storeId of storeIdsToRemove) {
                    await sdkBuilder.deleteStoreProduct({
                        product_id: productVersion.product_id,
                        store_id: storeId,
                    });
                }

                setCurrentStoreIds(newStoreIds);
                reset({
                    storeIds: newStoreIds.map((id) => id.toString()),
                });

                addAlertMessages(
                    storeIdsToAdd.map((storeId) => ({
                        message: `${productVersion.schema.metadata.name} was successfully added to ${allStores.lookup[storeId].name}`,
                        category: AlertCategory.SUCCESS,
                    }))
                );

                refreshState();
            });
        },
        [
            currentStoreIds,
            allStores,
            productVersion,
            reset,
            tryCatchAndRaiseError,
            addAlertMessages,
            refreshState,
            sdkBuilder,
        ]
    );

    if (!productVersion) {
        return <></>;
    }

    return (
        <AddToStoreFormPopup
            name="add-to-store"
            defaultValues={{
                storeIds: [],
            }}
            onSubmit={onSubmit}
            formReturnRefCallback={formReturnRefCallback}
            submitText="Save"
        >
            {({ register }) => (
                <>
                    <header>
                        <h2>Add to Store</h2>
                        <p className="size-14">
                            {productVersion.is_published ? (
                                <>
                                    Add this product to any available stores. To
                                    create a new store, click Stores in the main
                                    menu at left.
                                </>
                            ) : (
                                <>
                                    Add this draft product to any available
                                    stores to test it. Drafts are only visible
                                    to Admins. To create a new store, click
                                    Stores in left navigation menu.
                                </>
                            )}
                        </p>
                    </header>
                    <ul
                        className="color-black-100 check"
                        css={css`
                            &.check input.disabled ~ label {
                                color: var(--black_40);
                                cursor: default;
                            }
                            &.check input.disabled ~ label:before {
                                border-color: var(--black_40);
                                background: none;
                                cursor: default;
                            }
                            #root
                                &.check
                                input.disabled:checked
                                ~ label:before {
                                border-color: var(--black_40);
                                background: var(--black_40);
                            }
                        `}
                    >
                        {allStores &&
                            currentStoreIds &&
                            allStores.list.map(({ id, name }) => (
                                <li key={id}>
                                    <input
                                        type="checkbox"
                                        id={`store-${id}`}
                                        value={id}
                                        // Sadly we can't use the "disabled" prop because React-Hook-Forms has specified
                                        // that "Disabled inputs will be returned as undefined."
                                        // (https://react-hook-form.com/api/useform/getvalues#rules)
                                        // So we've resorted to setting a "disabled" class with custom css styling above
                                        // and preventing clicks using e.preventDefault(). Blahhhhh
                                        {...(currentStoreIds.includes(id) && {
                                            className: "disabled",
                                            onClick: (e) => e.preventDefault(),
                                        })}
                                        {...register("storeIds")}
                                    />
                                    <label htmlFor={`store-${id}`}>
                                        {name}
                                    </label>
                                </li>
                            ))}
                    </ul>
                </>
            )}
        </AddToStoreFormPopup>
    );
};

export default AddToStorePopup;
export { ADD_TO_STORE };
