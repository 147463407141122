import {
    createContext,
    Dispatch,
    FC,
    PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
} from "react";
import { useImmerReducer } from "use-immer";
import { usePage } from "components/Page";
import flatMap from "lodash/flatMap";

import type {
    ProductVersion,
    GlobalParameterSchemaV1,
    LineItemParameterSchemaV1,
    LineItemSchemaV1,
    ProductRater,
} from "@joshuins/builder";
import isArray from "lodash/isArray";

type ExcelErrorsObject = {
    [error in keyof typeof ExcelError]?: string[] | boolean;
};

type RaterAction =
    | {
          action: "SetParameters";
          parameters: GlobalParameterSchemaV1[];
      }
    | {
          action: "SetProductVersion";
          productVersion: ProductVersion | undefined;
      }
    | { action: "AddParameter"; parameter: GlobalParameterSchemaV1 }
    | {
          action: "UpdateParameter";
          parameter: GlobalParameterSchemaV1;
      }
    | { action: "RemoveParameter"; parameterCode: string }
    | { action: "ToggleOpenCloseParameters" }
    | {
          action: "SetLineItems";
          lineItems: {
              lineItem: LineItemSchemaV1;
              parameters: GlobalParameterSchemaV1[];
              open: boolean;
          }[];
      }
    | { action: "AddLineItem"; lineItem: LineItemSchemaV1 }
    | { action: "UpdateLineItem"; lineItem: LineItemSchemaV1 }
    | { action: "RemoveLineItem"; lineItemCode: string }
    | { action: "ToggleOpenCloseLineItems" }
    | {
          action: "ToggleOpenCloseLineItem";
          lineItemCode: string;
      }
    | {
          action: "SetLineItemParameters";
          lineItemCode: string;
          parameters: LineItemParameterSchemaV1[];
      }
    | {
          action: "AddLineItemParameter";
          lineItemCode: string;
          parameter: LineItemParameterSchemaV1;
      }
    | {
          action: "UpdateLineItemParameter";
          lineItemCode: string;
          parameter: LineItemParameterSchemaV1;
      }
    | {
          action: "RemoveLineItemParameter";
          lineItemCode: string;
          parameterCode: string;
      }
    | {
          action: "SetRater";
          rater: ProductRater;
      }
    | {
          action: "SetExcelErrors";
          excelErrors: {
              excelErrors: ExcelErrorsObject;
              open: boolean;
          };
      }
    | {
          action: "ToggleOpenCloseExcelErrors";
      }
    | { action: "OpenAll" }
    | { action: "CloseAll" };

enum ExcelError {
    FILE_TOO_BIG = "file_too_big",
    MISSING_SHEET = "missing_sheet",
    NOT_IN_EXCEL = "not_in_excel",
    NOT_IN_JOSHU = "not_in_joshu",
    NOT_WIRED = "not_wired",
    VALIDATION_REQUIRED = "validation_required",
}

interface RaterState {
    parameters: {
        parameters: GlobalParameterSchemaV1[] | undefined;
        open: boolean;
    };
    lineItems: {
        lineItems:
            | {
                  lineItem: LineItemSchemaV1;
                  parameters: LineItemParameterSchemaV1[];
                  open: boolean;
              }[]
            | undefined;
        open: boolean;
    };
    rater: ProductRater | undefined;
    excelErrors: {
        excelErrors: ExcelErrorsObject;
        open: boolean;
    };
    productVersion: ProductVersion | undefined;
}

interface RaterProviderInterface {
    raterState: RaterState;
    raterDispatch: Dispatch<RaterAction>;
    allowEditing: boolean;
    itemGetters: {
        parametersOpen: () => boolean;
        parameterAndIndex: (
            parameterCode: string
        ) =>
            | { parameter: GlobalParameterSchemaV1; index: number }
            | { parameter: undefined; index: undefined };
        allParameters: () => GlobalParameterSchemaV1[] | undefined;
        lineItemsOpen: () => boolean;
        lineItemAndIndex: (lineItemCode: string) =>
            | {
                  lineItem: {
                      lineItem: LineItemSchemaV1;
                      parameters: LineItemParameterSchemaV1[];
                      open: boolean;
                  };
                  index: number;
              }
            | { lineItem: undefined; index: undefined };
        allLineItems: () =>
            | {
                  lineItem: LineItemSchemaV1;
                  parameters: LineItemParameterSchemaV1[];
                  open: boolean;
              }[]
            | undefined;
        lineItemParameterAndIndex: (
            lineItemCode: string,
            lineItemParameterCode: string
        ) =>
            | { lineItemParameter: LineItemParameterSchemaV1; index: number }
            | { lineItemParameter: undefined; index: undefined };
        lineItemParameters: (
            lineItemCode: string
        ) => LineItemParameterSchemaV1[];
        rater: () => ProductRater | undefined;
        excelErrorsOpen: () => boolean;
        excelErrors: () => ExcelErrorsObject;
        excelErrorCodes: () => string[];
    };
}

const RaterContext = createContext<RaterProviderInterface | undefined>(
    undefined
);

const useRater = () => {
    const context = useContext(RaterContext);

    if (context === undefined) {
        throw Error("useRater must be used inside a RaterProvider context");
    }

    return context;
};

const RaterProvider: FC<PropsWithChildren> = ({ children }) => {
    const { element } = usePage();
    const productVersion = element as unknown as ProductVersion | undefined;

    const getParameters = (draft: RaterState) => {
        if (draft.parameters.parameters === undefined) {
            draft.parameters.parameters = [];
        }
        return draft.parameters.parameters;
    };

    const getLineItems = (draft: RaterState) => {
        if (draft.lineItems.lineItems === undefined) {
            draft.lineItems.lineItems = [];
        }
        return draft.lineItems.lineItems;
    };

    const reducer = (draft: RaterState, action: RaterAction) => {
        switch (action.action) {
            case "SetProductVersion": {
                draft.productVersion = action.productVersion;
                break;
            }
            case "SetParameters": {
                draft.parameters.parameters = action.parameters;
                break;
            }
            case "AddParameter": {
                getParameters(draft).push(action.parameter);
                break;
            }
            case "UpdateParameter": {
                const parameters = getParameters(draft);
                const parameterIndex = parameters.findIndex(
                    (parameter) => parameter.code === action.parameter.code
                );
                if (parameterIndex !== -1) {
                    parameters[parameterIndex] = action.parameter;
                }
                break;
            }
            case "RemoveParameter": {
                const parameters = getParameters(draft);
                const parameterIndex = parameters.findIndex(
                    (parameter) => parameter.code === action.parameterCode
                );

                if (parameterIndex !== -1) {
                    parameters.splice(parameterIndex, 1);
                }

                break;
            }
            case "ToggleOpenCloseParameters": {
                draft.parameters.open = !draft.parameters.open;
                break;
            }
            case "SetLineItems": {
                draft.lineItems.lineItems = action.lineItems;
                break;
            }
            case "AddLineItem": {
                getLineItems(draft).push({
                    lineItem: action.lineItem,
                    parameters: [],
                    open: true,
                });
                break;
            }
            case "UpdateLineItem": {
                const lineItems = getLineItems(draft);
                const lineItemIndex = lineItems.findIndex(
                    (lineItem) =>
                        lineItem.lineItem.code === action.lineItem.code
                );
                if (lineItemIndex !== -1) {
                    lineItems[lineItemIndex].lineItem = action.lineItem;
                }
                break;
            }
            case "RemoveLineItem": {
                const lineItems = getLineItems(draft);
                const lineItemIndex = lineItems.findIndex(
                    (lineItem) => lineItem.lineItem.code === action.lineItemCode
                );

                if (lineItemIndex !== -1) {
                    lineItems.splice(lineItemIndex, 1);
                }

                break;
            }
            case "ToggleOpenCloseLineItems": {
                draft.lineItems.open = !draft.lineItems.open;
                break;
            }
            case "ToggleOpenCloseLineItem": {
                const draftLineItem = getLineItems(draft).find(
                    (lineItem) => lineItem.lineItem.code === action.lineItemCode
                );
                if (draftLineItem) {
                    draftLineItem.open = !draftLineItem.open;
                }
                break;
            }
            case "SetLineItemParameters": {
                const lineItem = getLineItems(draft).find(
                    (lineItem) => lineItem.lineItem.code === action.lineItemCode
                );

                if (lineItem) {
                    lineItem.parameters = action.parameters;
                }
                break;
            }
            case "AddLineItemParameter": {
                const lineItem = getLineItems(draft).find(
                    (lineItem) => lineItem.lineItem.code === action.lineItemCode
                );

                if (lineItem) {
                    lineItem.parameters.push(action.parameter);
                }
                break;
            }
            case "UpdateLineItemParameter": {
                const lineItem = getLineItems(draft).find(
                    (lineItem) => lineItem.lineItem.code === action.lineItemCode
                );

                if (lineItem) {
                    const parameterIndex = lineItem.parameters.findIndex(
                        (parameter) => parameter.code === action.parameter.code
                    );
                    if (parameterIndex !== -1) {
                        lineItem.parameters[parameterIndex] = action.parameter;
                    }
                }
                break;
            }
            case "RemoveLineItemParameter": {
                const lineItem = getLineItems(draft).find(
                    (lineItem) => lineItem.lineItem.code === action.lineItemCode
                );

                if (lineItem) {
                    const parameterIndex = lineItem.parameters.findIndex(
                        (parameter) => parameter.code === action.parameterCode
                    );
                    if (parameterIndex !== -1) {
                        lineItem.parameters.splice(parameterIndex, 1);
                    }
                }

                break;
            }
            case "SetRater": {
                draft.rater = action.rater;
                break;
            }
            case "SetExcelErrors": {
                draft.excelErrors = action.excelErrors;
                break;
            }
            case "ToggleOpenCloseExcelErrors": {
                draft.excelErrors.open = !draft.excelErrors.open;
                break;
            }
            case "OpenAll": {
                draft.parameters.open = true;
                draft.lineItems.open = true;
                draft.excelErrors.open = true;

                for (const lineItem of getLineItems(draft)) {
                    lineItem.open = true;
                }
                break;
            }
            case "CloseAll": {
                draft.parameters.open = false;
                draft.lineItems.open = false;
                draft.excelErrors.open = false;

                for (const lineItem of getLineItems(draft)) {
                    lineItem.open = false;
                }
                break;
            }
        }
    };

    const [raterState, raterDispatch] = useImmerReducer<
        RaterState,
        RaterAction
    >(reducer, {
        parameters: { parameters: undefined, open: true },
        lineItems: { lineItems: undefined, open: true },
        rater: undefined,
        excelErrors: {
            excelErrors: {},
            open: true,
        },
        productVersion: undefined,
    });

    useEffect(() => {
        const getProductVersion = async () => {
            raterDispatch({
                action: "SetProductVersion",
                productVersion: productVersion,
            });
        };
        getProductVersion();
    }, [productVersion, raterDispatch]);

    const getParametersOpen = useCallback(
        () => raterState.parameters.open,
        [raterState.parameters.open]
    );

    const getParameterAndIndex = useCallback(
        (parameterCode: string) => {
            const parameters = getParameters(raterState);
            const index = parameters.findIndex(
                (parameter) => parameter.code === parameterCode
            );
            if (index !== -1) {
                return {
                    parameter: parameters[index],
                    index,
                };
            } else {
                return {
                    parameter: undefined,
                    index: undefined,
                };
            }
        },
        [raterState]
    );

    const getAllParameters = useCallback(
        () => raterState.parameters.parameters,
        [raterState.parameters.parameters]
    );

    const getLineItemsOpen = useCallback(
        () => raterState.lineItems.open,
        [raterState.lineItems.open]
    );

    const getLineItemAndIndex = useCallback(
        (lineItemCode: string) => {
            const lineItems = getLineItems(raterState);
            const index = lineItems.findIndex(
                (lineItem) => lineItem.lineItem.code === lineItemCode
            );
            if (index !== -1) {
                return {
                    lineItem: lineItems[index],
                    index,
                };
            } else {
                return {
                    parameter: undefined,
                    index: undefined,
                };
            }
        },
        [raterState]
    );

    const getAllLineItems = useCallback(
        () => raterState.lineItems.lineItems,
        [raterState.lineItems.lineItems]
    );

    const getLineItemParameterAndIndex = useCallback(
        (lineItemCode: string, lineItemParameterCode: string) => {
            const { lineItem } = getLineItemAndIndex(lineItemCode);
            if (!lineItem) {
                return {
                    lineItemParameter: undefined,
                    index: undefined,
                };
            }

            const index = lineItem.parameters.findIndex(
                (lineItemParameter) =>
                    lineItemParameter.code === lineItemParameterCode
            );
            if (index !== -1) {
                return {
                    lineItemParameter: lineItem.parameters[index],
                    index,
                };
            } else {
                return {
                    lineItemParameter: undefined,
                    index: undefined,
                };
            }
        },
        [getLineItemAndIndex]
    );

    const getLineItemParameters = useCallback(
        (lineItemCode: string) => {
            const { lineItem } = getLineItemAndIndex(lineItemCode);
            return lineItem ? lineItem.parameters : [];
        },
        [getLineItemAndIndex]
    );

    const getExcelErrorsOpen = useCallback(
        () => raterState.excelErrors.open,
        [raterState.excelErrors]
    );

    const getExcelErrors = useCallback(
        () => raterState.excelErrors.excelErrors,
        [raterState.excelErrors]
    );

    const getExcelErrorCodes = useCallback(
        () =>
            flatMap(raterState.excelErrors.excelErrors, (codes) =>
                isArray(codes) ? codes : []
            ),
        [raterState.excelErrors]
    );

    const getRater = useCallback(() => {
        return raterState.rater;
    }, [raterState.rater]);

    if (!productVersion) {
        return <></>;
    }

    return (
        <RaterContext.Provider
            value={{
                raterState,
                raterDispatch,
                allowEditing: !productVersion.is_published,
                itemGetters: {
                    parametersOpen: getParametersOpen,
                    parameterAndIndex: getParameterAndIndex,
                    allParameters: getAllParameters,
                    lineItemsOpen: getLineItemsOpen,
                    lineItemAndIndex: getLineItemAndIndex,
                    allLineItems: getAllLineItems,
                    lineItemParameterAndIndex: getLineItemParameterAndIndex,
                    lineItemParameters: getLineItemParameters,
                    rater: getRater,
                    excelErrorsOpen: getExcelErrorsOpen,
                    excelErrors: getExcelErrors,
                    excelErrorCodes: getExcelErrorCodes,
                },
            }}
        >
            {children}
        </RaterContext.Provider>
    );
};

export { RaterProvider, useRater, ExcelError };
export type { ExcelErrorsObject };
