import { UserServices } from "@joshuins/auth";
import ExtraPane from "components/extra-panes/ExtraPane";
import { useApi } from "contexts/ApiProvider";
import { useEffect, useState } from "react";

const ApiTokensExtraPane = () => {
    const [userServices, setUserServices] = useState<UserServices>();
    const { sdkAuth } = useApi();

    useEffect(() => {
        const setupUserServices = async () => {
            const userServices = (await sdkAuth.me()).user_services;
            setUserServices(userServices);
        };

        setupUserServices();
    }, [sdkAuth]);

    return (
        <ExtraPane>
            <h3>
                <i aria-hidden="true" className="icon-help" />
                API Tokens
            </h3>
            <p className="size-14">
                Create and manage API tokens to integrate with Joshu&apos;s API.
                A token must be included in the header of all requests to the
                API.
            </p>
            {userServices && userServices.docs_url && (
                <span>
                    <a
                        href={userServices.docs_url}
                        target="_blank"
                        rel="noreferrer"
                        style={{ fontSize: "12px" }}
                    >
                        <i aria-hidden="true" className="icon-list" /> API
                        Documentation
                    </a>
                </span>
            )}
        </ExtraPane>
    );
};

export default ApiTokensExtraPane;
