import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    useCallback,
} from "react";
import {
    Link,
    LinkProps,
    Navigate,
    NavigateFunction,
    NavigateOptions,
    NavigateProps,
    NavLink,
    NavLinkProps,
    To,
    useHref,
    useNavigate,
} from "react-router-dom";

const DevAwareRoutingLink: ForwardRefExoticComponent<
    LinkProps & React.RefAttributes<HTMLAnchorElement>
> = forwardRef<HTMLAnchorElement, LinkProps>(
    ({ to, relative, ...rest }, ref) => {
        const href = useHref(to, { relative });

        return <Link {...rest} to={href} ref={ref} />;
    }
);

DevAwareRoutingLink.displayName = "DevAwareRoutingLink";

const DevAwareRoutingNavLink: ForwardRefExoticComponent<
    NavLinkProps & React.RefAttributes<HTMLAnchorElement>
> = forwardRef<HTMLAnchorElement, NavLinkProps>(({ to, ...rest }, ref) => (
    <NavLink {...rest} to={to} ref={ref} />
));

DevAwareRoutingNavLink.displayName = "DevAwareRoutingNavLink";

const DevAwareRoutingNavigate = ({
    to,
    relative,
    ...rest
}: NavigateProps): ReactElement<typeof Navigate> | null => {
    return <Navigate to={to} relative={relative} {...rest} />;
};

const useDevAwareNavigate = (): NavigateFunction => {
    const navigate = useNavigate();

    return useCallback(
        (to: To | number, options: NavigateOptions = {}) => {
            if (typeof to === "number") {
                navigate(to);
            } else {
                navigate(to, options);
            }
        },
        [navigate]
    );
};

export {
    DevAwareRoutingLink as Link,
    DevAwareRoutingNavLink as NavLink,
    DevAwareRoutingNavigate as Navigate,
    useDevAwareNavigate as useNavigate,
};
