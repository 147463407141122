import { Link, To } from "react-router-dom";
import NotAnchor from "./NotAnchor";

interface PageMessage {
    title: string;
    message: string;
    iconType?: string;
    button?: {
        text: string;
        onClick?: () => void;
        aHref?: string;
        aHrefNewTab?: string;
        linkTo?: To;
    };
}

const FullPageMessage1 = ({ iconType, title, message }: PageMessage) => (
    <form className="form-aside is-success">
        <div className="form-success">
            <h3>
                {iconType && (
                    <i aria-hidden="true" className={`icon-${iconType}`} />
                )}
                {title}
            </h3>
            <p>{message}</p>
        </div>
    </form>
);

const FullPageMessage2 = ({ title, message, button }: PageMessage) => (
    <article className="form-aside form-validate ">
        <header className="m40">
            <h2>{title}</h2>
            <p>{message}</p>
        </header>
        {button && (
            <p className="link-btn">
                {button.onClick ? (
                    <NotAnchor
                        onClick={() => {
                            button.onClick && button.onClick();
                        }}
                    >
                        {button.text}
                    </NotAnchor>
                ) : button.aHref ? (
                    <a href={button.aHref}>{button.text}</a>
                ) : button.aHrefNewTab ? (
                    <a
                        href={button.aHrefNewTab}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {button.text}
                    </a>
                ) : button.linkTo ? (
                    <Link to={button.linkTo}>{button.text}</Link>
                ) : (
                    button.text
                )}
            </p>
        )}
    </article>
);

export { FullPageMessage1, FullPageMessage2 };
