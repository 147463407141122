import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "paul-overrides.css";
import initializeSentry from "utils/sentry";
import initializeAxiosRetry from "utils/axios-extras";

initializeAxiosRetry();
initializeSentry();

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
