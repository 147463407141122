import { FC, useCallback } from "react";
import { ReportTemplate } from "@joshuins/system";
import { usePage } from "components/Page";
import { ConfirmationPopup } from "components/Popup";
import { useApi } from "contexts/ApiProvider";
import { usePopup } from "contexts/PopupProvider";

const DeleteReportTemplatePopup: FC = () => {
    const { popupData } = usePopup();
    const { sdkSystem } = useApi();
    const { tryCatchAndRaiseError, refreshState } = usePage();

    const reportTemplate = popupData?.reportTemplate as
        | ReportTemplate
        | undefined;

    const onSubmit = useCallback(async () => {
        if (!reportTemplate) {
            return;
        }
        tryCatchAndRaiseError(async () => {
            await sdkSystem.deleteReportTemplate({ id: reportTemplate.id });
        }, refreshState);
    }, [reportTemplate, tryCatchAndRaiseError, refreshState, sdkSystem]);

    return (
        <ConfirmationPopup
            name="delete-report-template"
            onSubmit={onSubmit}
            submitText="Delete"
        >
            <header>
                <h2>Are you sure?</h2>
                <p className="size-14">
                    This template file will be deleted, and cannot be undone.
                </p>
            </header>
        </ConfirmationPopup>
    );
};

export default DeleteReportTemplatePopup;
