import { FC, useCallback, useEffect, useState } from "react";
import { useInsuranceProcess } from "../InsuranceProcessProvider";
import { includes } from "utils/array";
import SubmissionData, { Asset } from "utils/submission-data";
import urlJoin from "url-join";
import { asyncReduce } from "modern-async";
import { JoValue, SectionV1 } from "@joshuins/insurance";
import {
    getJoTypeName,
    getJoValueType,
    getRawValueFromJoValue,
    joValuesEqual,
} from "utils/jo-types-and-values";
import compact from "lodash/compact";
import isEqual from "lodash/isEqual";
import sortBy from "lodash/sortBy";
import { useBranding } from "contexts/BrandingProvider";
import { removeAppPrefix } from "pages/underwriter/submissions/components/utils";

const VISIBLE_SECTION_TYPES = ["Application", "Asset"] as const;

const ApplicationDetailsSection: FC = () => {
    const {
        itemGetters: {
            getInsuranceProcess,
            getPreviousParsedSubmissionData,
            getPreviousProductVersion,
        },
    } = useInsuranceProcess();
    const { productVersion, parsedSubmissionData, submission } =
        getInsuranceProcess();
    const [
        sectionsWithModifiedDatapointCount,
        setSectionsWithModifiedDatapointCount,
    ] = useState<{ section: SectionV1; modifiedAnswersCount: number }[]>();

    const { generateUrl } = useBranding();

    const fileAnswersEqual = async (
        fileAnswer1: JoValue,
        fileAnswer2: JoValue
    ) => {
        if (
            getJoValueType(fileAnswer1) !== "Text" ||
            getJoValueType(fileAnswer2) !== "Text"
        ) {
            throw new Error(
                "fileAnswersEqual() can only be used on File JoValues"
            );
        }

        const fileId1 = getRawValueFromJoValue(fileAnswer1) as string;
        const fileId2 = getRawValueFromJoValue(fileAnswer2) as string;

        if (fileId1 === fileId2) {
            return true;
        }

        // TODO: Get the files by the fileIds which are the UUIDs in the unique_id
        // column in the files table and then compare them
        return false;
    };

    const isAssetInSection = useCallback((asset: Asset, section: SectionV1) => {
        const datapointsInSection = compact(
            section.items.flatMap((item) => {
                if ("Datapoint" in item) return item.Datapoint;
            })
        );
        return datapointsInSection.some(
            (datapoint) => !!asset.applicationItems[datapoint.code]
        );
    }, []);

    const countModifiedDatapointsInSection = useCallback(
        async (
            section: SectionV1,
            previousParsedSubmissionData: SubmissionData,
            assetId: string
        ) => {
            const datapointsInSection = compact(
                section.items.flatMap((item) => {
                    if ("Datapoint" in item) return item.Datapoint;
                })
            );

            return await asyncReduce(
                datapointsInSection,
                async (accumulator, datapoint) => {
                    const answer =
                        parsedSubmissionData.assets[assetId]?.applicationItems[
                            removeAppPrefix(datapoint.code)
                        ];
                    const previousAnswer =
                        previousParsedSubmissionData.assets[assetId]
                            ?.applicationItems[removeAppPrefix(datapoint.code)];

                    if (!answer && !previousAnswer) {
                        return accumulator;
                    }

                    if (
                        (answer && !previousAnswer) ||
                        (!answer && previousAnswer)
                    ) {
                        accumulator += 1;
                    } else if (
                        answer &&
                        previousAnswer &&
                        !joValuesEqual(answer, previousAnswer) &&
                        (!isEqual(getJoTypeName(datapoint.type), [
                            "File",
                            false,
                        ]) ||
                            !(await fileAnswersEqual(answer, previousAnswer)))
                    ) {
                        accumulator += 1;
                    }

                    return accumulator;
                },
                0
            );
        },
        [parsedSubmissionData]
    );

    useEffect(() => {
        const determineModifiedAnswers = async () => {
            const sections_ = sortBy(
                productVersion.schema.spec.sections.filter((section) =>
                    includes(VISIBLE_SECTION_TYPES, section.type)
                ),
                "display_order"
            );

            const [previousProductVersion, previousParsedSubmissionData] =
                await Promise.all([
                    getPreviousProductVersion(),
                    getPreviousParsedSubmissionData(),
                ]);

            if (!previousProductVersion || !previousParsedSubmissionData) {
                return;
            }

            const sectionsWithModifiedDatapointCount_ = await asyncReduce(
                sections_,
                async (accumulator, section) => {
                    let modifiedAnswersCount: number;
                    if (section.type === "Asset") {
                        // If it's an Asset section, then the count counts the number of
                        // modified assets irrespective of how many datapoints are modified
                        // for each asset
                        const assetIdsInSection = Object.entries(
                            parsedSubmissionData.assets
                        ).reduce((accumulator, [id, asset]) => {
                            if (isAssetInSection(asset, section)) {
                                accumulator.push(id);
                            }
                            return accumulator;
                        }, [] as string[]);

                        modifiedAnswersCount = await asyncReduce(
                            assetIdsInSection,
                            async (accumulator, id) => {
                                if (
                                    (await countModifiedDatapointsInSection(
                                        section,
                                        previousParsedSubmissionData,
                                        id
                                    )) > 0
                                ) {
                                    accumulator += 1;
                                }
                                return accumulator;
                            },
                            0
                        );
                        // } else if (section.type === "Application") {
                    } else {
                        // If it's not an Asset sections then count the number of modified
                        // datapoints
                        modifiedAnswersCount =
                            await countModifiedDatapointsInSection(
                                section,
                                previousParsedSubmissionData,
                                "0"
                            );
                    }
                    accumulator.push({
                        section,
                        modifiedAnswersCount,
                    });

                    return accumulator;
                },
                [] as {
                    section: SectionV1;
                    modifiedAnswersCount: number;
                }[]
            );
            setSectionsWithModifiedDatapointCount(
                sectionsWithModifiedDatapointCount_
            );
        };
        determineModifiedAnswers();
    }, [
        countModifiedDatapointsInSection,
        getPreviousParsedSubmissionData,
        getPreviousProductVersion,
        isAssetInSection,
        parsedSubmissionData.assets,
        productVersion.schema.spec.sections,
    ]);

    if (!parsedSubmissionData || !sectionsWithModifiedDatapointCount) {
        return;
    }

    return (
        <>
            <h2 className="m8">
                <i className="icon-doc-edit"></i> Application
            </h2>
            <ul className="list-plain box">
                {sectionsWithModifiedDatapointCount &&
                    sectionsWithModifiedDatapointCount.map(
                        ({ section, modifiedAnswersCount }) => (
                            <li key={section.code}>
                                <a
                                    href={generateUrl(
                                        urlJoin(
                                            "/",
                                            "submissions",
                                            submission.id.toString(),
                                            "application",
                                            removeAppPrefix(section.code)
                                        )
                                    )}
                                >
                                    <i
                                        aria-hidden="true"
                                        className="icon-doc-edit"
                                    />{" "}
                                    {section.type === "Asset"
                                        ? section.title_singular
                                        : section.title}
                                    {modifiedAnswersCount > 0 && (
                                        <span className="text-right">
                                            <span className="scheme-box color-wine">
                                                {modifiedAnswersCount}
                                            </span>
                                        </span>
                                    )}
                                </a>
                            </li>
                        )
                    )}
            </ul>
        </>
    );
};

export { ApplicationDetailsSection };
