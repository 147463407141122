import { FC, useCallback, useEffect } from "react";
import classNames from "classnames";
import { z } from "zod";
import {
    Integration,
    IntegrationCredentials,
    IntegrationCredentialsType,
    IntegrationTypeInfo,
} from "@joshuins/system";
import { createFormPopup } from "components/Popup";
import { usePopup } from "contexts/PopupProvider";
import NotAnchor from "components/NotAnchor";
import { useApi } from "contexts/ApiProvider";
import { useNavigate } from "react-router-dom";
import urlJoin from "url-join";
import { BUILDER_PATH } from "globals";
import { AlertCategory, usePage } from "components/Page";

const SETUP_INTEGRATION_POPUP = "setup-integration" as const;

const setupIntegrationFormSchema = z
    .object({
        integrationType: z.object({
            url_mandatory: z.boolean(),
            credentials_type: z.string(),
        }),
        integration: z
            .object({
                url: z.union([
                    z.undefined(),
                    z.literal(""),
                    z.string().trim().url(),
                ]),
                credentials: z.object({
                    apiKey: z.string().optional().nullable(),
                    username: z.string().optional(),
                    password: z.string().optional(),
                }),
            })
            .optional(),
    })
    .refine(
        ({ integration, integrationType }) => {
            if (integrationType.url_mandatory && !integration?.url) {
                return false;
            }
            if (integrationType.credentials_type === "None") {
                return true;
            }
            if (
                integrationType.credentials_type === "ApiKey" &&
                integration?.credentials.apiKey
            ) {
                return true;
            }
            if (
                integrationType.credentials_type === "UsernamePassword" &&
                integration?.credentials.username &&
                integration?.credentials.password
            ) {
                return true;
            }
            return false;
        },
        {
            message: "Missing required fields",
            path: ["integration"],
        }
    );

type SetupIntgerationTypePopup = z.infer<typeof setupIntegrationFormSchema>;

const {
    FormPopup: SetupIntgerationFormPopup,
    useFormReturnRef: useDrcSetupFormReturnRef,
} = createFormPopup(setupIntegrationFormSchema);

const SetupIntgerationPopup: FC = () => {
    const { sdkSystem } = useApi();
    const navigate = useNavigate();
    const { addAlertMessages } = usePage();
    const { popupData } = usePopup();
    const {
        formReturn: { reset },
        formReturnRefCallback,
    } = useDrcSetupFormReturnRef();
    const integrationType = popupData?.integrationType as
        | IntegrationTypeInfo
        | undefined;
    const integration = popupData?.integration as Integration | undefined;

    useEffect(() => {
        if (!reset || !integrationType) {
            return;
        }
        reset({
            integrationType: {
                url_mandatory: integrationType.url_mandatory,
                credentials_type: integrationType.credentials_type.toString(),
            },
            integration:
                integration && integration.url
                    ? { url: integration.url, credentials: {} }
                    : undefined,
        });
    }, [integration, integrationType, reset]);

    const onSubmit = useCallback(
        async (data: SetupIntgerationTypePopup) => {
            if (!integrationType) {
                return;
            }
            const credentials: IntegrationCredentials =
                integrationType.credentials_type === "None"
                    ? { None: {} }
                    : integrationType.credentials_type === "ApiKey"
                      ? { ApiKey: data.integration?.credentials.apiKey ?? "" }
                      : {
                            UsernamePassword: {
                                username:
                                    data.integration?.credentials.username ??
                                    "",
                                password:
                                    data.integration?.credentials.password ??
                                    "",
                            },
                        };
            if (integration) {
                await sdkSystem.updateIntegration({
                    id: integration.id,
                    UpdateIntegration: {
                        url: data.integration?.url,
                        credentials,
                    },
                });
                addAlertMessages({
                    message: `${integrationType.name} has been updated`,
                    category: AlertCategory.SUCCESS,
                });
            } else {
                const newIntegration = await sdkSystem.createIntegration({
                    NewIntegration: {
                        type_: integrationType.name,
                        url: data.integration?.url,
                        credentials,
                    },
                });
                navigate(
                    urlJoin(
                        "/",
                        BUILDER_PATH,
                        "system",
                        "integrations",
                        newIntegration.id
                    )
                );
            }
        },
        [addAlertMessages, integration, integrationType, navigate, sdkSystem]
    );

    if (!integrationType) {
        return <></>;
    }

    return (
        <SetupIntgerationFormPopup
            name={SETUP_INTEGRATION_POPUP}
            defaultValues={{
                integration: undefined,
            }}
            submitText="Save"
            onSubmit={onSubmit}
            formReturnRefCallback={formReturnRefCallback}
            overlayPopups={[
                <>
                    <h3>Credentials</h3>
                    <p>
                        Some data providers require credentials such as an API
                        key or client key and secret. These credentials can be
                        easily obtained by setting up an account with the data
                        provider, which sometimes incurs additional payment
                        directly to the data provider.
                    </p>
                    <p>Contact us if you need help with this step.</p>
                </>,
            ]}
        >
            {({ register, openOverlayPopup, formState: { errors } }) => (
                <>
                    <header>
                        <h2>Settings</h2>
                        <p className="size-14">
                            Enter your credentials below to connect this
                            account.
                        </p>
                    </header>
                    {integrationType.credentials_type ===
                        IntegrationCredentialsType.ApiKey && (
                        <p
                            className={classNames({
                                "has-error":
                                    errors.integration?.credentials?.apiKey,
                            })}
                        >
                            <label htmlFor="cbaa">
                                API Key
                                <NotAnchor
                                    onClick={() => {
                                        openOverlayPopup(0);
                                    }}
                                    className="text-right"
                                >
                                    <i className="icon-help" />{" "}
                                    <span className="hidden">More info</span>
                                </NotAnchor>
                            </label>
                            <input
                                type="text"
                                id="cbaa"
                                {...register("integration.credentials.apiKey")}
                            />
                            {errors.integration?.credentials?.apiKey && (
                                <label
                                    id="cbaa-error"
                                    className="error"
                                    htmlFor="cbaa"
                                >
                                    {
                                        errors.integration?.credentials?.apiKey
                                            .message
                                    }
                                </label>
                            )}
                        </p>
                    )}
                    {integrationType.credentials_type ===
                        IntegrationCredentialsType.UsernamePassword && (
                        <>
                            <p
                                className={classNames({
                                    "has-error":
                                        errors.integration?.credentials
                                            ?.username,
                                })}
                            >
                                <label htmlFor="cbd">
                                    User Name
                                    <NotAnchor
                                        onClick={() => {
                                            openOverlayPopup(0);
                                        }}
                                        className="text-right"
                                    >
                                        <i className="icon-help" />{" "}
                                        <span className="hidden">
                                            More info
                                        </span>
                                    </NotAnchor>
                                </label>
                                <input
                                    type="text"
                                    id="cbd"
                                    {...register(
                                        "integration.credentials.username"
                                    )}
                                />
                                {errors.integration?.credentials?.username && (
                                    <label
                                        id="cbd-error"
                                        className="error"
                                        htmlFor="cbb"
                                    >
                                        {
                                            errors.integration?.credentials
                                                ?.username.message
                                        }
                                    </label>
                                )}
                            </p>
                            <p
                                className={classNames({
                                    "has-error":
                                        errors.integration?.credentials
                                            ?.password,
                                })}
                            >
                                <label htmlFor="cbc">
                                    Password
                                    <NotAnchor
                                        onClick={() => {
                                            openOverlayPopup(0);
                                        }}
                                        className="text-right"
                                    >
                                        <i className="icon-help" />{" "}
                                        <span className="hidden">
                                            More info
                                        </span>
                                    </NotAnchor>
                                </label>
                                <input
                                    type="text"
                                    id="cbc"
                                    {...register(
                                        "integration.credentials.password"
                                    )}
                                />
                                {errors.integration?.credentials?.password && (
                                    <label
                                        id="cbc-error"
                                        className="error"
                                        htmlFor="cbc"
                                    >
                                        {
                                            errors.integration?.credentials
                                                ?.password.message
                                        }
                                    </label>
                                )}
                            </p>
                            {integrationType.url_mandatory && (
                                <p
                                    className={classNames({
                                        "has-error": errors.integration?.url,
                                    })}
                                >
                                    <label htmlFor="cbb">URL</label>
                                    <input
                                        type="text"
                                        id="cbb"
                                        {...register("integration.url")}
                                    />
                                    {errors.integration?.url && (
                                        <label
                                            id="cbb-error"
                                            className="error"
                                            htmlFor="cbb"
                                        >
                                            {errors.integration?.url.message}
                                        </label>
                                    )}
                                </p>
                            )}
                        </>
                    )}
                    {errors.integration && (
                        <p>
                            <label id="integration-error" className="error">
                                {errors.integration.message ??
                                    "Missing required fields"}
                            </label>
                        </p>
                    )}
                </>
            )}
        </SetupIntgerationFormPopup>
    );
};

export default SetupIntgerationPopup;
export { SETUP_INTEGRATION_POPUP };
