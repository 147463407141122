import { zodResolver } from "@hookform/resolvers/zod";
import {
    Insured,
    InsuredSettingsAppearanceOptions,
    ParsedAddress,
    Setting,
} from "@joshuins/insurance";
import { Industry } from "@joshuins/system";
import { zodField as googleMapsAutocompleteZodField } from "components/GoogleMapsAutocomplete";
import { Footer, MainPane } from "components/MainPane";
import { Page } from "components/Page";
import {
    GoogleMapsAutocomplete,
    InputWithOnBlur,
    Select,
} from "components/ReactHookFormUncontrolledComponents";
import { MenuItem } from "components/Select";
import ExtraPane from "components/extra-panes/ExtraPane";
import { unpaginate } from "components/sdk";
import { useApi } from "contexts/ApiProvider";
import { INSURED_OPTIONAL_SETTINGS_APPEARANCE_OPTIONS } from "globals";
import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { includes } from "utils/array";
import { URL_REGEX } from "utils/url";
import { z } from "zod";
import { useCreateSubmissionWizard } from "./use-create-submission-wizard";

const Sidebar = () => (
    <ExtraPane>
        <h3>
            <i aria-hidden="true" className="icon-help" /> New Insured
        </h3>
        <p>
            This is the first step opening a new submission. Here you must enter
            basic details about the insured so that we can verify if the entity
            is suitable to be insured.
        </p>
    </ExtraPane>
);

const newInsuredSchema = z.object({
    name: z.string().min(1, "This field is required"),
    address: z.union([googleMapsAutocompleteZodField, z.string()]),
    website: z
        .object({
            value: z.union([
                z.null(),
                z.string().regex(URL_REGEX, "A valid website is required"),
                z.string().max(0),
            ]),
            context: z.object({
                setting: z.object({
                    website_field_in_insured_page: z.union([
                        z.enum(INSURED_OPTIONAL_SETTINGS_APPEARANCE_OPTIONS),
                        z.null(),
                    ]),
                }),
            }),
        })
        .refine(
            async ({
                value,
                context: {
                    setting: { website_field_in_insured_page },
                },
            }) => {
                if (
                    includes(
                        ["Required", "Clearance"] as const,
                        website_field_in_insured_page
                    )
                ) {
                    return !!value;
                } else {
                    return true;
                }
            },
            { message: "This field is required", path: ["value"] }
        )
        .refine(
            async ({
                value,
                context: {
                    setting: { website_field_in_insured_page },
                },
            }) => {
                if (
                    website_field_in_insured_page ===
                    InsuredSettingsAppearanceOptions.Hidden
                ) {
                    return !value;
                } else {
                    return true;
                }
            },
            { message: "This must be empty", path: ["value"] }
        ),
    industry: z
        .object({
            value: z.union([
                z.number({ required_error: "This field is required" }),
                z.undefined(),
            ]),
            context: z.object({
                setting: z.object({
                    class_of_business_field_in_insured_page: z.union([
                        z.enum(INSURED_OPTIONAL_SETTINGS_APPEARANCE_OPTIONS),
                        z.null(),
                    ]),
                }),
            }),
        })
        .refine(
            async ({
                value,
                context: {
                    setting: { class_of_business_field_in_insured_page },
                },
            }) => {
                if (
                    includes(
                        ["Required", "Clearance"] as const,
                        class_of_business_field_in_insured_page
                    )
                ) {
                    return !!value;
                } else {
                    return true;
                }
            },
            { message: "This field is required", path: ["value"] }
        )
        .refine(
            async ({
                value,
                context: {
                    setting: { class_of_business_field_in_insured_page },
                },
            }) => {
                if (
                    class_of_business_field_in_insured_page ===
                    InsuredSettingsAppearanceOptions.Hidden
                ) {
                    return !value;
                } else {
                    return true;
                }
            },
            { message: "This must be empty", path: ["value"] }
        ),
});

type NewInsuredType = z.infer<typeof newInsuredSchema>;

const InsuredDetailsForm: FC<{
    insured?: Insured;
    location?: ParsedAddress | string;
    industry?: Industry;
    modalFooter?: boolean;
    submit?: (data: NewInsuredType) => Promise<void>;
}> = ({ insured, location, industry, modalFooter = false, submit }) => {
    const [setting, setSetting] = useState<Setting>();
    const [industries, setIndustries] = useState<Industry[]>();
    const { sdkInsurance } = useApi();
    const newSubmissionWizardProceed = useCreateSubmissionWizard();

    const onSubmit = async (data: NewInsuredType) => {
        newSubmissionWizardProceed("newInsured", { newInsured: data });
    };

    const {
        register,
        reset,
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        getValues,
    } = useForm<NewInsuredType>({
        resolver: zodResolver(newInsuredSchema),
        defaultValues: {
            name: "",
            address: undefined,
            website: {
                value: "",
                context: undefined,
            },
            industry: {
                value: undefined,
                context: undefined,
            },
        },
    });

    useEffect(() => {
        const getSetting = async () => {
            const setting = await sdkInsurance.getClearanceSettings();
            setSetting(setting);
            setValue("website.context.setting", setting);
            setValue("industry.context.setting", setting);
        };
        getSetting();
    }, [sdkInsurance, setValue]);

    useEffect(() => {
        const getIndustries = async () => {
            if (
                setting &&
                setting.class_of_business_field_in_insured_page !==
                    InsuredSettingsAppearanceOptions.Hidden
            ) {
                const industries = await unpaginate(
                    sdkInsurance.allIndustries,
                    {}
                );
                setIndustries(industries);
            }
        };
        getIndustries();
    }, [setting, sdkInsurance]);

    useEffect(() => {
        if (insured) {
            reset({
                name: insured.name,
                address: location,
                website: { value: insured.website },
                industry: { value: industry?.id },
            });
        }
    }, [insured, location, industry, reset]);

    if (
        !setting ||
        (setting.class_of_business_field_in_insured_page !==
            InsuredSettingsAppearanceOptions.Hidden &&
            !industries)
    ) {
        return;
    }

    return (
        <form onSubmit={handleSubmit(submit || onSubmit)}>
            <p>
                <label htmlFor="faa">Insured Name</label>
                <InputWithOnBlur
                    control={control}
                    name="name"
                    className="faa"
                    id="faa"
                    type="text"
                    onBlur={(event) => {
                        if (!getValues("address")) {
                            setValue("address", event.target.value);
                        }
                    }}
                />
                {errors.name && (
                    <label id="faa-error" className="error" htmlFor="faa">
                        {errors.name.message}
                    </label>
                )}
            </p>
            <div className="div-as-p">
                <label htmlFor="address">Main Address</label>
                <GoogleMapsAutocomplete
                    control={control}
                    name="address"
                    id="address"
                />
                {errors.address && (
                    <label
                        id="address-error"
                        className="error"
                        htmlFor="address"
                    >
                        {errors.address.message}
                    </label>
                )}
            </div>
            {setting.website_field_in_insured_page !==
                InsuredSettingsAppearanceOptions.Hidden && (
                <p>
                    <label htmlFor="fai">
                        Website
                        {includes(
                            ["Optional", null] as const,
                            setting.website_field_in_insured_page
                        ) && " (Optional)"}
                    </label>
                    <input
                        {...register("website.value")}
                        className="fai"
                        id="fai"
                    />
                    {errors.website && (
                        <label
                            id="website-error"
                            className="error"
                            htmlFor="website"
                        >
                            {errors.website.value?.message}
                        </label>
                    )}
                </p>
            )}
            {setting.class_of_business_field_in_insured_page !==
                InsuredSettingsAppearanceOptions.Hidden &&
                industries &&
                industries.length > 0 && (
                    <div className="div-as-p">
                        <label htmlFor="industry">
                            Class Of Business
                            {includes(
                                ["Optional", null] as const,
                                setting.class_of_business_field_in_insured_page
                            ) && " (Optional)"}
                        </label>
                        <Select
                            id="industry"
                            name="industry.value"
                            control={control}
                            fullWidth
                            displayEmpty={true}
                        >
                            <MenuItem
                                disabled
                                value=""
                                sx={{
                                    display: "none",
                                }}
                            >
                                <em>Choose...</em>
                            </MenuItem>
                            {industries.map(({ id, title, code }) => (
                                <MenuItem key={id} value={id}>
                                    {code}: {title}
                                </MenuItem>
                            ))}
                        </Select>
                        {errors.industry && (
                            <label
                                id="industry-error"
                                className="error"
                                htmlFor="industry"
                            >
                                {errors.industry.value?.message}
                            </label>
                        )}
                    </div>
                )}
            {modalFooter ? (
                <footer>
                    <p className="link-btn wide">
                        <button type="submit">Save</button>
                    </p>
                </footer>
            ) : (
                <Footer className="compact">
                    <p className="link-btn">
                        <button type="submit">
                            <i
                                aria-hidden="true"
                                className="icon-check-circle-outline"
                            />
                            Continue
                        </button>
                    </p>
                </Footer>
            )}
        </form>
    );
};

const NewInsuredPage: FC = () => (
    <Page>
        <MainPane
            title="Who will be insured?"
            layoutConfig={{
                headerPosition: "inside-content",
                headerClass: null,
                mainLayout: null,
            }}
        >
            <InsuredDetailsForm />
        </MainPane>
        <Sidebar />
    </Page>
);

export default NewInsuredPage;
export { InsuredDetailsForm };
export type { NewInsuredType };
