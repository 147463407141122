import {
    useState,
    forwardRef,
    ChangeEventHandler,
    FocusEventHandler,
    ChangeEvent,
} from "react";

const PASSWORD_REGEX = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=\S+$).{8,}$/;
const UPPERCASE_CHARACTER_REGEX = /[A-Z]/;
const NUMBER_REGEX = /[0-9]/;

interface PasswordInputProps {
    id: string;
    onChange: ChangeEventHandler<HTMLInputElement>;
    onBlur: FocusEventHandler<HTMLInputElement>;
    name: string;
}

const PasswordInput = forwardRef<HTMLInputElement, PasswordInputProps>(
    ({ id, onChange, onBlur, name }, ref) => {
        const MIN_PASSWORD_LENGTH = 7;

        const [minLength, setMinLength] = useState<string>("");
        const [minUpper, setMinUpper] = useState<string>("");
        const [minNumber, setMinNumber] = useState<string>("");

        const [showPassword, setShowPassword] = useState<boolean>(false);

        const passwordChanged = (
            event: ChangeEvent<HTMLInputElement>
        ): void => {
            const value = event.target.value;
            value.length > MIN_PASSWORD_LENGTH
                ? setMinLength("active")
                : setMinLength("");
            UPPERCASE_CHARACTER_REGEX.test(value)
                ? setMinUpper("active")
                : setMinUpper("");
            NUMBER_REGEX.test(value)
                ? setMinNumber("active")
                : setMinNumber("");
            onChange(event);
        };

        const togglePassword = (): void => {
            setShowPassword(!showPassword);
        };

        return (
            <p>
                <label htmlFor={id}>
                    Password
                    <span
                        onClick={() => togglePassword()}
                        className="text-right color-primary"
                        style={{ cursor: "pointer" }}
                    >
                        {showPassword ? "Hide" : "Show"}
                    </span>
                </label>
                <input
                    type={showPassword ? "text" : "password"}
                    autoComplete="new-password"
                    id={id}
                    name={name}
                    onChange={passwordChanged}
                    onBlur={onBlur}
                    ref={ref}
                />
                <span className="scheme-info">
                    Password must have at least:
                </span>
                <span className="scheme-info cols scheme-password-check">
                    <span data-length="8" className={minLength}>
                        <i aria-hidden="true" className="icon-check-circle" /> 8
                        Characters
                    </span>
                    <span data-capital="1" className={minUpper}>
                        <i aria-hidden="true" className="icon-check-circle" /> 1
                        Uppercase
                    </span>
                    <span data-number="1" className={minNumber}>
                        <i aria-hidden="true" className="icon-check-circle" /> 1
                        Number
                    </span>
                </span>
            </p>
        );
    }
);
PasswordInput.displayName = "PasswordInput";

export default PasswordInput;
export { PASSWORD_REGEX };
