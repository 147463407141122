import { AlertCategory, Page, usePage } from "components/Page";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Footer } from "components/MainPane";
import ChecklistExtraPane from "components/extra-panes/ChecklistExtraPane";
import {
    DndContext,
    closestCenter,
    PointerSensor,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import {
    arrayMove,
    useSortable,
    SortableContext,
    verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { formatDatetimeString } from "utils/datetime";
import isEmpty from "lodash/isEmpty";
import NotAnchor from "components/NotAnchor";
import { useApi } from "contexts/ApiProvider";
import {
    ExcelError,
    RaterProvider,
    useRater,
} from "./components/RaterProvider";
import MenuPopover from "components/MenuPopover";
import classNames from "classnames";
import { usePopup } from "contexts/PopupProvider";
import { AddOrEditParameterPopup } from "./components/AddOrEditParameterPopup";
import { AddOrEditLineItemPopup } from "./components/AddOrEditLineItemPopup";
import axios from "axios";
import { createFormPopup } from "components/Popup";
import { ConfirmationPopup, Popup } from "components/Popup";
import { randomString } from "utils/string";
import { z } from "zod";
import { FileUpload } from "components/FileUpload";

import {
    type GetFileResponse,
    ProductRaterStatus,
    LineItemParameterSchemaV1,
    LineItemSchemaV1,
    GlobalParameterSchemaV1,
} from "@joshuins/builder";
import type { DragEndEvent } from "@dnd-kit/core";
import type { ExcelErrorsObject } from "./components/RaterProvider";
import isArray from "lodash/isArray";
import ProductMainPane from "./components/ProductMainPane";
import FileDownload from "components/FileDownload";
import { retry } from "utils/retry";
import {
    findGlobalParameterIndex,
    findLineItem,
    findLineItemIndex,
    findLineItemParameter,
} from "utils/product-version";
import cloneDeep from "lodash/cloneDeep";

const ProductVersionCheckList: FC = () => {
    const {
        raterState: { productVersion },
    } = useRater();

    if (!productVersion) {
        return <></>;
    }

    return (
        <ChecklistExtraPane
            associatedId={productVersion.id}
            checklistType="product"
            title="Rater Setup Checklist"
            description={
                "Follow these steps to configure the rater for this product. " +
                'Click "Help Center" for videos and articles to learn more. ' +
                "When you are finished, check the step as complete."
            }
            tags={[
                {
                    tag: "ReviewDefault",
                    title: "Review Default Policy Parameters",
                    url: "https://help.joshu.insure/en/articles/6355303-rater-default-policy-parameters",
                },
                {
                    tag: "AddCoverages",
                    title: "Add Coverages & Line Items",
                    url: "https://help.joshu.insure/en/articles/6355316-rater-coverages-line-items",
                },
                {
                    tag: "UploadExcel",
                    title: "Upload Excel File",
                    url: "https://help.joshu.insure/en/articles/6355318-rater-upload-excel-file",
                },
                {
                    tag: "ConnectSheets",
                    title: "Connect Sheets in Excel",
                    url: "https://help.joshu.insure/en/articles/6355319-rater-connect-sheets-in-excel",
                },
                {
                    tag: "FinalFile",
                    title: "Final Excel File & Making Changes",
                    url: "https://help.joshu.insure/en/articles/6355320-rater-final-upload-and-making-changes",
                },
            ]}
        />
    );
};

const DeleteLineItemPopup: FC = () => {
    const { sdkBuilder } = useApi();
    const { popupData } = usePopup();
    const { addAlertMessages } = usePage();
    const lineItemCode = popupData?.lineItemCode as string | undefined;
    const {
        raterDispatch,
        raterState: { productVersion },
    } = useRater();

    const onSubmit = useCallback(async () => {
        if (!productVersion || !lineItemCode) {
            return;
        }
        const updateProdcutVersion = cloneDeep(productVersion);
        const lineItemIndex = findLineItemIndex(
            updateProdcutVersion,
            lineItemCode
        );
        if (lineItemIndex >= 0) {
            const lineItems = updateProdcutVersion.schema.spec.line_items;
            lineItems.splice(lineItemIndex, 1);
            updateProdcutVersion.schema.spec.line_items = lineItems;
            await sdkBuilder.updateProductVersion({
                id: updateProdcutVersion.id,
                UpdateProductVersion: {
                    schema: updateProdcutVersion.schema,
                },
            });
            raterDispatch({
                action: "SetProductVersion",
                productVersion: updateProdcutVersion,
            });

            addAlertMessages({
                message: "Line Item deleted successfully",
                category: AlertCategory.SUCCESS,
            });
        }
    }, [
        productVersion,
        lineItemCode,
        sdkBuilder,
        raterDispatch,
        addAlertMessages,
    ]);

    return (
        <ConfirmationPopup
            name="delete-line-item"
            submitText="Delete Line Item"
            mobileSubmitText="Delete"
            onSubmit={onSubmit}
        >
            <header>
                <h2>Delete Line Item?</h2>
                <p className="size-14">
                    The line item and all of its parameters will be deleted.
                    This cannot be undone.
                </p>
            </header>
        </ConfirmationPopup>
    );
};

const DeleteParameterPopup: FC = () => {
    const { sdkBuilder } = useApi();
    const { popupData } = usePopup();
    const { addAlertMessages, tryCatchAndRaiseError } = usePage();
    const parameterCode = popupData?.parameterCode as string | undefined;
    const lineItemCode = popupData?.lineItemCode as string | undefined;
    const {
        raterDispatch,
        raterState: { productVersion },
    } = useRater();
    const onSubmit = useCallback(async () => {
        if (!productVersion || !parameterCode) {
            return;
        }
        const updateProdcutVersion = cloneDeep(productVersion);
        tryCatchAndRaiseError(async () => {
            if (lineItemCode) {
                const lineItems = updateProdcutVersion.schema.spec.line_items;
                const lineItem = findLineItem(
                    updateProdcutVersion,
                    lineItemCode
                );
                const lineItemIndex = findLineItemIndex(
                    updateProdcutVersion,
                    lineItemCode
                );
                if (lineItem) {
                    const parameterIndex = lineItem.params.findIndex(
                        (param) => param.code == parameterCode
                    );
                    if (parameterIndex >= 0) {
                        const editedLineItem = cloneDeep(lineItem);
                        editedLineItem.params.splice(parameterIndex, 1);
                        lineItems[lineItemIndex] = editedLineItem;
                    }
                    updateProdcutVersion.schema.spec.line_items = lineItems;
                }
            } else {
                const globalParams = cloneDeep(
                    updateProdcutVersion.schema.spec.global_params
                );
                const parameterIndex = findGlobalParameterIndex(
                    updateProdcutVersion,
                    parameterCode
                );
                if (parameterIndex >= 0) {
                    globalParams.splice(parameterIndex, 1);
                }
                updateProdcutVersion.schema.spec.global_params = globalParams;
            }
            await sdkBuilder.updateProductVersion({
                id: updateProdcutVersion.id,
                UpdateProductVersion: {
                    schema: updateProdcutVersion.schema,
                },
            });
            raterDispatch({
                action: "SetProductVersion",
                productVersion: updateProdcutVersion,
            });
            addAlertMessages({
                message: "Parameter deleted successfully",
                category: AlertCategory.SUCCESS,
            });
        });
    }, [
        addAlertMessages,
        lineItemCode,
        parameterCode,
        productVersion,
        raterDispatch,
        sdkBuilder,
        tryCatchAndRaiseError,
    ]);

    return (
        <ConfirmationPopup
            name="delete-parameter"
            submitText="Delete Parameter"
            mobileSubmitText="Delete"
            onSubmit={onSubmit}
        >
            <header>
                <h2>Delete Parameter?</h2>
                <p className="size-14">
                    The parameter will be deleted. This action cannot be undone.
                </p>
            </header>
        </ConfirmationPopup>
    );
};

const uploadExcelSchema = z.object({
    excelFileUrl: z.instanceof(File, { message: "No file selected" }),
});

type UploadExcelType = z.infer<typeof uploadExcelSchema>;

const { FormPopup } = createFormPopup(uploadExcelSchema);

const UploadExcelFilePopup: FC = () => {
    const { sdkBuilder } = useApi();
    const { addAlertMessages, tryCatchAndRaiseError } = usePage();
    const { isPopupOpen } = usePopup();
    const [excelFile, setExcelFile] = useState<GetFileResponse>();
    const {
        itemGetters: { rater: getRater },
    } = useRater();
    const rater = getRater();
    const {
        raterDispatch,
        raterState: { productVersion },
    } = useRater();

    const onSubmit = useCallback(
        async (data: UploadExcelType) => {
            if (!productVersion || !(data.excelFileUrl instanceof File)) {
                return;
            }
            const updateProdcutVersion = cloneDeep(productVersion);
            tryCatchAndRaiseError(async () => {
                const id = await sdkBuilder.uploadFile(
                    data.excelFileUrl,
                    "ProductRater"
                );
                const newRater = await sdkBuilder.createProductRater({
                    NewProductRater: {
                        file_id: id,
                        prepare_from_product_version_id: productVersion.id,
                    },
                });
                updateProdcutVersion.schema.spec.rater = {
                    Internal: { id: newRater.id },
                };
                await sdkBuilder.updateProductVersion({
                    id: updateProdcutVersion.id,
                    UpdateProductVersion: {
                        schema: updateProdcutVersion.schema,
                    },
                });
                raterDispatch({
                    action: "SetProductVersion",
                    productVersion: updateProdcutVersion,
                });
                addAlertMessages({
                    message: "Rater file uploaded sucessfully",
                    category: AlertCategory.SUCCESS,
                });
            });
        },
        [
            addAlertMessages,
            productVersion,
            raterDispatch,
            sdkBuilder,
            tryCatchAndRaiseError,
        ]
    );

    useEffect(() => {
        if (!isPopupOpen("upload-excel-file")) {
            return;
        }

        const getExcelFile = async () => {
            if (!rater) {
                return;
            }
            const excelFile_ = await sdkBuilder.getFile({
                id: rater.file_id,
            });
            setExcelFile(excelFile_);
        };
        getExcelFile();
    }, [rater, sdkBuilder, isPopupOpen]);

    return (
        <FormPopup
            name="upload-excel-file"
            defaultValues={{
                excelFileUrl: undefined,
            }}
            submitText="Upload"
            onSubmit={onSubmit}
        >
            {({ control, formState: { errors } }) => (
                <>
                    <header>
                        <h2>Update Excel File</h2>
                        <p>
                            {excelFile ? (
                                <>
                                    Have you referenced{" "}
                                    <span className="strong">_Joshu</span> in
                                    your rater calculations? Copied the final
                                    outputs from your rater to{" "}
                                    <span className="strong">_Joshu</span>? Then
                                    your file is ready!
                                </>
                            ) : (
                                <>
                                    Find the Excel file with the rater for this
                                    product.
                                </>
                            )}
                        </p>
                    </header>
                    {excelFile && (
                        <p style={{ overflowWrap: "break-word" }}>
                            <label htmlFor="file3">
                                Current File{" "}
                                <span className="text-right">
                                    Uploaded{" "}
                                    {formatDatetimeString(excelFile.created_at)}{" "}
                                </span>
                            </label>
                            {excelFile.filename}
                        </p>
                    )}
                    <FileUpload
                        id="ii"
                        name="excelFileUrl"
                        control={control}
                        fileDesignation="ProductRater"
                        error={errors.excelFileUrl?.message}
                        Label={
                            <label htmlFor="ii">
                                File{" "}
                                <span className="text-right">64MB Limit</span>
                            </label>
                        }
                    />
                </>
            )}
        </FormPopup>
    );
};

const ConnectSheetsInExcelPopup: FC = () => (
    <ConfirmationPopup name="connect-sheets-in-excel" submitText="Continue">
        <header>
            <h2>Connect Sheets in Excel</h2>
            <p className="size-18">
                Since this is a brand new rater file, we added{" "}
                <span className="strong">_Joshu</span> and all your new
                parameters and line items.
            </p>
        </header>
        <ul className="list-size-15">
            <li>
                Click <span className="strong">Download Excel File</span> to
                download the updated file
            </li>
            <li>
                Open the file in Excel and connect the cells in{" "}
                <span className="strong">_Joshu</span> to the corresponding
                cells in your rater sheet
            </li>
        </ul>
    </ConfirmationPopup>
);

const ExcelErrorComponent: FC<{
    excelError: ExcelError;
    codes?: string[] | boolean;
}> = ({ excelError, codes }) => {
    const excelErrorMessage = useMemo(() => {
        switch (excelError) {
            case ExcelError.FILE_TOO_BIG:
                return "Attached Excel file is too big for automatic analysis.";
            case ExcelError.MISSING_SHEET:
                // openPopup("connect-sheets-in-excel");
                // return "Joshu sheets have been added to your file. Download and connect new cells across sheets.";
                return "There were problems with your rater file, please correct them and upload again";
            case ExcelError.VALIDATION_REQUIRED:
                return 'Changes detected, press "Validate Excel File" to see if your Excel file is updated accordingly.';
            case ExcelError.NOT_IN_JOSHU:
                return "These tags in your Excel file are missing in Joshu. Add them above.";
            case ExcelError.NOT_IN_EXCEL:
                return "These tags from Joshu are missing in your Excel file. We'll add them automatically when you download the file, but you need to connect them to your rater sheet.";
            case ExcelError.NOT_WIRED:
                return "All tags marked customizable must include an input in your _Joshu sheet.";
        }
    }, [excelError]);

    return (
        <li style={{ color: "#8f8b88" }}>
            {excelErrorMessage}
            <i aria-hidden="true" className="icon-error" />
            {isArray(codes) &&
                codes &&
                codes.map((code) => (
                    <div key={code} style={{ fontWeight: 500, padding: "1px" }}>
                        {code}
                    </div>
                ))}
        </li>
    );
};

const ExcelErrors: FC = () => {
    const {
        itemGetters: { excelErrors: getExcelErrors, excelErrorsOpen },
        raterDispatch,
    } = useRater();
    const excelErrors = getExcelErrors();

    if (isEmpty(excelErrors)) {
        return <></>;
    }

    return (
        <li
            className={classNames("strong", {
                open: excelErrorsOpen(),
            })}
        >
            <NotAnchor
                onClick={() => {
                    raterDispatch({ action: "ToggleOpenCloseExcelErrors" });
                }}
                role="tab"
            >
                Issues
            </NotAnchor>
            <i aria-hidden="true" className="icon-error" />
            <ul className="list-plain">
                {Object.keys(ExcelError).map((key) => {
                    const key_ = key as keyof typeof ExcelError;

                    return (
                        key_ in excelErrors && (
                            <ExcelErrorComponent
                                key={key_}
                                excelError={ExcelError[key_]}
                                codes={excelErrors[key_]}
                            />
                        )
                    );
                })}
            </ul>
        </li>
    );
};

const LineItemParameter: FC<{
    lineItemCode: string;
    lineItemParameterCode: string;
    parameterIndex: number;
}> = ({ lineItemCode, lineItemParameterCode }) => {
    const { attributes, listeners, setNodeRef, transform, transition } =
        useSortable({ id: lineItemParameterCode });
    const {
        itemGetters: {
            lineItemParameterAndIndex: getLineItemParameterAndIndex,
            lineItemParameters: getLineItemParameters,
            excelErrorCodes: getExcelErrorCodes,
        },
        allowEditing,
        raterDispatch,
        raterState: { productVersion },
    } = useRater();
    const { openPopup } = usePopup();
    const { sdkBuilder } = useApi();
    const { addAlertMessages, tryCatchAndRaiseError } = usePage();
    const { lineItemParameter, index: lineItemParameterIndex } =
        getLineItemParameterAndIndex(lineItemCode, lineItemParameterCode);
    const lineItemParameters = getLineItemParameters(lineItemCode);
    const numLineItemParameters = lineItemParameters.length;

    const excelErrorCodes = getExcelErrorCodes();
    const hasExcelError = useMemo(() => {
        if (!lineItemParameter) {
            return false;
        }
        return excelErrorCodes.some((excelErrorCode) =>
            excelErrorCode.includes(lineItemParameter.code)
        );
    }, [excelErrorCodes, lineItemParameter]);

    const duplicateLineItemParameter = useCallback(async () => {
        if (!productVersion || !lineItemCode || !lineItemParameterCode) {
            return;
        }
        const updateProdcutVersion = cloneDeep(productVersion);
        const lineItem = findLineItem(updateProdcutVersion, lineItemCode);
        if (lineItem) {
            const lineItemIndex = findLineItemIndex(
                updateProdcutVersion,
                lineItemCode
            );
            const lineItemParameter = findLineItemParameter(
                lineItem,
                lineItemParameterCode
            );
            if (lineItemParameter) {
                const updatedLineItem =
                    updateProdcutVersion.schema.spec.line_items[lineItemIndex];
                const lineItemParameterIndex = lineItem.params.findIndex(
                    (param) => param.code === lineItemParameterCode
                );
                tryCatchAndRaiseError(async () => {
                    const code = `${lineItemParameter.code}_${randomString(3, {
                        digits: false,
                    })}`;
                    const duplicatedLineItemParameter: LineItemParameterSchemaV1 =
                        {
                            ...lineItemParameter,
                            code,
                            locked: false,
                        };
                    updatedLineItem.params.splice(
                        lineItemParameterIndex + 1,
                        0,
                        duplicatedLineItemParameter
                    );
                    updateProdcutVersion.schema.spec.line_items[lineItemIndex] =
                        updatedLineItem;
                    await sdkBuilder.updateProductVersion({
                        id: updateProdcutVersion.id,
                        UpdateProductVersion: {
                            schema: updateProdcutVersion.schema,
                        },
                    });
                    addAlertMessages({
                        message: "Parameter duplicated successfully",
                        category: AlertCategory.SUCCESS,
                    });
                    raterDispatch({
                        action: "SetLineItemParameters",
                        lineItemCode: lineItemCode,
                        parameters: updatedLineItem.params,
                    });
                    raterDispatch({
                        action: "SetProductVersion",
                        productVersion: updateProdcutVersion,
                    });
                });
            }
        }
    }, [
        productVersion,
        lineItemCode,
        lineItemParameterCode,
        tryCatchAndRaiseError,
        sdkBuilder,
        addAlertMessages,
        raterDispatch,
    ]);

    // prevents horizontal movement
    if (transform) {
        transform.x = 0;
    }

    if (!lineItemParameter) {
        return <></>;
    }

    return (
        <MenuPopover
            ref={setNodeRef}
            style={{
                ...(numLineItemParameters > 0 &&
                    lineItemParameterIndex !== undefined && {
                        zIndex: numLineItemParameters - lineItemParameterIndex,
                    }),
                transform: CSS.Transform.toString(transform),
                transition,
            }}
            menuItems={[
                {
                    key: "duplicate-parameter",
                    label: "Duplicate Parameter",
                    icon: "copy",
                    onClick: () => {
                        duplicateLineItemParameter();
                    },
                },
                {
                    key: "delete-parameter",
                    label: "Delete Parameter",
                    icon: "trash",
                    hasSeparator: true,
                    onClick: () => {
                        openPopup("delete-parameter", {
                            lineItemCode,
                            parameterCode: lineItemParameterCode,
                        });
                    },
                },
            ]}
            toggleButtonLabel={`Toggle ${lineItemParameter.name}`}
        >
            {({ ToggleButton, Menu }) => (
                <>
                    <NotAnchor
                        className="toggle-sub"
                        onClick={() => {
                            openPopup("add-or-edit-parameter", {
                                parameterCode: lineItemParameterCode,
                                lineItemCode,
                            });
                        }}
                    >
                        {lineItemParameter.name}
                        <span className="text-right">
                            {lineItemParameter.code}
                        </span>
                    </NotAnchor>
                    {allowEditing && (
                        <>
                            {ToggleButton}
                            {Menu}
                        </>
                    )}
                    {hasExcelError && (
                        <i aria-hidden="true" className="icon-error" />
                    )}
                    <div
                        className="handle ui-sortable-handle"
                        {...attributes}
                        {...listeners}
                    />
                </>
            )}
        </MenuPopover>
    );
};

const LineItemParameters: FC<{ lineItemCode: string }> = ({ lineItemCode }) => {
    const { sdkBuilder } = useApi();
    const sensors = useSensors(useSensor(PointerSensor));
    const {
        allowEditing,
        raterDispatch,
        raterState: { productVersion },
    } = useRater();

    if (!productVersion) {
        return <></>;
    }
    const updateProdcutVersion = cloneDeep(productVersion);
    const lineItem = findLineItem(updateProdcutVersion, lineItemCode);
    const lineItemIndex = findLineItemIndex(updateProdcutVersion, lineItemCode);
    const lineItemParameters = lineItem?.params || [];

    const handleDragEnd = async (event: DragEndEvent) => {
        const { active, over } = event;
        if (!over || !active || active.id === over.id || !lineItem) {
            return;
        }

        const oldIndex = lineItem.params.findIndex(
            (lineItemParameter) => lineItemParameter.code === active.id
        );
        const newIndex = lineItem.params.findIndex(
            (lineItemParameter) => lineItemParameter.code === over.id
        );
        const lineItems = cloneDeep(
            updateProdcutVersion.schema.spec.line_items
        );
        const updatedLineItem = lineItems[lineItemIndex];
        const params = cloneDeep(lineItem.params);
        updatedLineItem.params = arrayMove(params, oldIndex, newIndex);
        updateProdcutVersion.schema.spec.line_items = lineItems;
        sdkBuilder.updateProductVersion({
            id: updateProdcutVersion.id,
            UpdateProductVersion: {
                schema: updateProdcutVersion.schema,
            },
        });
        raterDispatch({
            action: "SetProductVersion",
            productVersion: updateProdcutVersion,
        });
        raterDispatch({
            action: "SetLineItemParameters",
            lineItemCode: lineItemCode,
            parameters: updatedLineItem.params,
        });
    };

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
        >
            <ul className="list-drag ui-sortable">
                <SortableContext
                    items={
                        lineItemParameters !== undefined
                            ? lineItemParameters.map((lineItemParameter) => ({
                                  id: lineItemParameter.code,
                              }))
                            : []
                    }
                    strategy={verticalListSortingStrategy}
                    disabled={!allowEditing}
                >
                    {lineItemParameters.map((lineItemParameter, index) => (
                        <LineItemParameter
                            key={lineItemParameter.code}
                            lineItemCode={lineItemCode}
                            lineItemParameterCode={lineItemParameter.code}
                            parameterIndex={index}
                        />
                    ))}
                </SortableContext>
            </ul>
        </DndContext>
    );
};

const LineItem: FC<{ lineItem: LineItemSchemaV1; lineItemIndex: number }> = ({
    lineItem,
    lineItemIndex,
}) => {
    const { attributes, listeners, setNodeRef, transform, transition } =
        useSortable({ id: lineItem.code });
    const { sdkBuilder } = useApi();
    const { openPopup } = usePopup();
    const { addAlertMessages, tryCatchAndRaiseError } = usePage();
    const {
        itemGetters: {
            excelErrorCodes: getExcelErrorCodes,
            lineItemAndIndex: getLineItemAndIndex,
        },
        raterDispatch,
        allowEditing,
        raterState: { productVersion },
    } = useRater();

    const { lineItem: raterLineItem } = getLineItemAndIndex(lineItem.code);
    const excelErrorCodes = getExcelErrorCodes();
    const hasExcelError = useMemo(() => {
        if (!lineItem) {
            return false;
        }
        return excelErrorCodes.some((excelErrorCode) =>
            excelErrorCode.includes(lineItem.code)
        );
    }, [excelErrorCodes, lineItem]);

    const duplicateLineItem = useCallback(async () => {
        if (!productVersion || !lineItem) {
            return;
        }
        const updateProdcutVersion = cloneDeep(productVersion);
        tryCatchAndRaiseError(async () => {
            const lineItems = updateProdcutVersion.schema.spec.line_items;
            const index = findLineItemIndex(
                updateProdcutVersion,
                lineItem.code
            );
            const code = `${lineItem.code}_${randomString(3, {
                digits: false,
            })}`;
            const duplicatedLineItem: LineItemSchemaV1 = {
                ...lineItem,
                code,
            };
            lineItems.splice(index + 1, 0, duplicatedLineItem);
            updateProdcutVersion.schema.spec.line_items = lineItems;
            await sdkBuilder.updateProductVersion({
                id: updateProdcutVersion.id,
                UpdateProductVersion: {
                    schema: updateProdcutVersion.schema,
                },
            });
            raterDispatch({
                action: "SetProductVersion",
                productVersion: updateProdcutVersion,
            });
            raterDispatch({
                action: "SetLineItems",
                lineItems: lineItems.map((lineItem) => ({
                    lineItem,
                    parameters: lineItem.params,
                    open: true,
                })),
            });
            addAlertMessages({
                message: "Line item duplicated successfully",
                category: AlertCategory.SUCCESS,
            });
        });
    }, [
        productVersion,
        lineItem,
        tryCatchAndRaiseError,
        sdkBuilder,
        raterDispatch,
        addAlertMessages,
    ]);

    // prevents horizontal movement
    if (transform) {
        transform.x = 0;
    }

    if (!lineItem) {
        return <></>;
    }

    return (
        <MenuPopover
            ref={setNodeRef}
            additionalClasses={classNames("has-sub", {
                open: raterLineItem?.open,
            })}
            style={{
                ...(productVersion?.schema.spec.line_items &&
                    lineItemIndex !== undefined && {
                        zIndex:
                            1 +
                            productVersion.schema.spec.line_items.length -
                            lineItemIndex,
                    }),
                transform: CSS.Transform.toString(transform),
                transition,
            }}
            menuItems={[
                {
                    key: "duplicate-line-item",
                    label: "Duplicate Line Item",
                    icon: "copy",
                    onClick: () => {
                        duplicateLineItem();
                    },
                },
                {
                    key: "delete-line-item",
                    label: "Delete Line Item",
                    icon: "trash",
                    hasSeparator: true,
                    onClick: () => {
                        openPopup("delete-line-item", {
                            lineItemCode: lineItem.code,
                        });
                    },
                },
            ]}
            toggleButtonLabel={`Toggle ${lineItem.name}`}
        >
            {({ ToggleButton, Menu }) => (
                <>
                    <NotAnchor
                        className="toggle-sub"
                        onClick={() => {
                            openPopup("add-or-edit-line-item", {
                                lineItem,
                            });
                        }}
                    >
                        {lineItem.name}
                    </NotAnchor>
                    {allowEditing && (
                        <>
                            {ToggleButton}
                            {Menu}
                        </>
                    )}
                    <NotAnchor
                        onClick={() => {
                            raterDispatch({
                                action: "ToggleOpenCloseLineItem",
                                lineItemCode: lineItem.code,
                            });
                        }}
                        role="tab"
                    >
                        Toggle subnav
                    </NotAnchor>
                    <LineItemParameters lineItemCode={lineItem.code} />
                    {allowEditing && (
                        <p className="link-strong">
                            <NotAnchor
                                onClick={() => {
                                    openPopup("add-or-edit-parameter", {
                                        parameterCode: undefined,
                                        lineItemCode: lineItem.code,
                                    });
                                }}
                            >
                                <i
                                    aria-hidden="true"
                                    className="icon-plus-circle"
                                />{" "}
                                Add Line Item Parameter
                            </NotAnchor>
                        </p>
                    )}

                    {hasExcelError && (
                        <i aria-hidden="true" className="icon-error" />
                    )}
                    <div
                        className="handle ui-sortable-handle"
                        {...attributes}
                        {...listeners}
                    />
                </>
            )}
        </MenuPopover>
    );
};

const LineItems: FC = () => {
    const { sdkBuilder } = useApi();
    const { openPopup } = usePopup();
    const sensors = useSensors(useSensor(PointerSensor));
    const {
        itemGetters: { lineItemsOpen },
        raterDispatch,
        allowEditing,
        raterState: { productVersion },
    } = useRater();

    const handleDragEnd = async (event: DragEndEvent) => {
        const { active, over } = event;
        if (!productVersion || !over || !active || active.id === over.id) {
            return;
        }
        const updateProdcutVersion = cloneDeep(productVersion);
        const oldIndex = findLineItemIndex(
            updateProdcutVersion,
            active.id.toString()
        );
        const newIndex = findLineItemIndex(
            updateProdcutVersion,
            over.id.toString()
        );
        const lineItems = cloneDeep(
            updateProdcutVersion.schema.spec.line_items
        );
        updateProdcutVersion.schema.spec.line_items = arrayMove(
            lineItems,
            oldIndex,
            newIndex
        );
        await sdkBuilder.updateProductVersion({
            id: updateProdcutVersion.id,
            UpdateProductVersion: {
                schema: updateProdcutVersion.schema,
            },
        });
        raterDispatch({
            action: "SetProductVersion",
            productVersion: updateProdcutVersion,
        });
        raterDispatch({
            action: "SetLineItems",
            lineItems: lineItems.map((lineItem) => ({
                lineItem,
                parameters: lineItem.params,
                open: true,
            })),
        });
    };

    if (!productVersion) {
        return <></>;
    }

    return (
        <li
            className={classNames("strong has-sub", { open: lineItemsOpen() })}
            style={{ zIndex: 1 }}
        >
            <NotAnchor
                role="tab"
                onClick={() => {
                    raterDispatch({ action: "ToggleOpenCloseLineItems" });
                }}
            >
                Line Items
            </NotAnchor>
            <NotAnchor
                role="tab"
                className="toggle"
                onClick={() => {
                    raterDispatch({ action: "ToggleOpenCloseLineItems" });
                }}
            >
                Toggle subnav
            </NotAnchor>
            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
            >
                <ul className="list-drag static ui-sortable">
                    <SortableContext
                        items={productVersion.schema.spec.line_items.map(
                            (lineItem) => ({ id: lineItem.code })
                        )}
                        strategy={verticalListSortingStrategy}
                        disabled={!allowEditing}
                    >
                        {productVersion.schema.spec.line_items.map(
                            (lineItem, index) => (
                                <LineItem
                                    key={lineItem.code}
                                    lineItem={lineItem}
                                    lineItemIndex={index}
                                />
                            )
                        )}
                    </SortableContext>
                </ul>
            </DndContext>
            {allowEditing && (
                <p className="link-strong">
                    <NotAnchor
                        onClick={() => {
                            openPopup("add-or-edit-line-item");
                        }}
                    >
                        <i aria-hidden="true" className="icon-plus-circle" />{" "}
                        Add Line Item
                    </NotAnchor>
                </p>
            )}
        </li>
    );
};

const Parameter: FC<{
    parameter: GlobalParameterSchemaV1;
    index: number;
    zIndexOffset?: number;
}> = ({ parameter, index, zIndexOffset }) => {
    const { attributes, listeners, setNodeRef, transform, transition } =
        useSortable({ id: parameter.code });
    const {
        allowEditing,
        raterDispatch,
        itemGetters: { excelErrorCodes: getExcelErrorCodes },
        raterState: { productVersion },
    } = useRater();
    const { sdkBuilder } = useApi();
    const { openPopup } = usePopup();
    const { addAlertMessages, tryCatchAndRaiseError } = usePage();

    const excelErrorCodes = getExcelErrorCodes();
    const hasExcelError = useMemo(() => {
        if (!parameter) {
            return false;
        }

        return excelErrorCodes.some(
            (excelErrorCode) => excelErrorCode === parameter.code
        );
    }, [excelErrorCodes, parameter]);

    const duplicateParameter = useCallback(async () => {
        if (!productVersion || !parameter) {
            return;
        }
        const updateProdcutVersion = cloneDeep(productVersion);
        tryCatchAndRaiseError(async () => {
            const globalParameters =
                updateProdcutVersion.schema.spec.global_params;
            const index = findGlobalParameterIndex(
                updateProdcutVersion,
                parameter.code
            );
            const code = `${parameter.code}_${randomString(3, {
                digits: false,
            })}`;
            const duplicatedParameter: GlobalParameterSchemaV1 = {
                ...parameter,
                code,
                locked: false,
            };
            globalParameters.splice(index + 1, 0, duplicatedParameter);
            updateProdcutVersion.schema.spec.global_params = globalParameters;
            await sdkBuilder.updateProductVersion({
                id: updateProdcutVersion.id,
                UpdateProductVersion: {
                    schema: updateProdcutVersion.schema,
                },
            });
            raterDispatch({
                action: "SetProductVersion",
                productVersion: updateProdcutVersion,
            });
            addAlertMessages({
                message: "Parameter duplicated successfully",
                category: AlertCategory.SUCCESS,
            });
        });
    }, [
        productVersion,
        parameter,
        tryCatchAndRaiseError,
        sdkBuilder,
        raterDispatch,
        addAlertMessages,
    ]);

    // prevents horizontal movement
    if (transform) {
        transform.x = 0;
    }
    if (!productVersion) {
        return;
    }

    return (
        <MenuPopover
            ref={setNodeRef}
            style={{
                ...(zIndexOffset !== undefined &&
                    productVersion?.schema.spec.global_params.length > 0 &&
                    index !== undefined && {
                        zIndex:
                            zIndexOffset +
                            productVersion.schema.spec.global_params.length -
                            index,
                    }),
                transform: CSS.Transform.toString(transform),
                transition,
            }}
            menuItems={[
                {
                    key: "duplicate-parameter",
                    label: "Duplicate Parameter",
                    icon: "copy",
                    onClick: () => {
                        duplicateParameter();
                    },
                },
                {
                    key: "delete-parameter",
                    label: "Delete Parameter",
                    icon: "trash",
                    hasSeparator: true,
                    onClick: () => {
                        openPopup("delete-parameter", {
                            parameterCode: parameter.code,
                        });
                    },
                    enabled: !parameter.locked,
                },
            ]}
            toggleButtonLabel={`Toggle ${parameter.name}`}
        >
            {({ ToggleButton, Menu }) => (
                <>
                    <NotAnchor
                        className="toggle-sub"
                        onClick={() => {
                            openPopup("add-or-edit-parameter", {
                                parameterCode: parameter.code,
                                lineItemCode: undefined,
                            });
                        }}
                    >
                        {parameter.name}
                    </NotAnchor>
                    {allowEditing && (
                        <>
                            {ToggleButton}
                            {Menu}
                        </>
                    )}
                    {hasExcelError && (
                        <i aria-hidden="true" className="icon-error" />
                    )}
                    <div
                        className="handle ui-sortable-handle"
                        {...attributes}
                        {...listeners}
                    />
                </>
            )}
        </MenuPopover>
    );
};

const Parameters: FC = () => {
    const { sdkBuilder } = useApi();
    const {
        itemGetters: { parametersOpen },
        raterDispatch,
        allowEditing,
        raterState: { productVersion },
    } = useRater();
    const { openPopup } = usePopup();
    const sensors = useSensors(useSensor(PointerSensor));

    const handleDragEnd = (event: DragEndEvent) => {
        const { active, over } = event;
        if (!productVersion || !over || !active || active.id === over.id) {
            return;
        }
        const updateProdcutVersion = cloneDeep(productVersion);
        const oldIndex = findGlobalParameterIndex(
            updateProdcutVersion,
            active.id.toString()
        );
        const newIndex = findGlobalParameterIndex(
            updateProdcutVersion,
            over.id.toString()
        );
        const globalParams = updateProdcutVersion.schema.spec.global_params;
        updateProdcutVersion.schema.spec.global_params = arrayMove(
            globalParams,
            oldIndex,
            newIndex
        );
        sdkBuilder.updateProductVersion({
            id: updateProdcutVersion.id,
            UpdateProductVersion: {
                schema: updateProdcutVersion.schema,
            },
        });
        raterDispatch({
            action: "SetProductVersion",
            productVersion: updateProdcutVersion,
        });
        raterDispatch({
            action: "SetParameters",
            parameters: productVersion.schema.spec.global_params || [],
        });
    };

    if (!productVersion) {
        return <></>;
    }

    return (
        <li
            className={classNames("strong has-sub", {
                open: parametersOpen(),
            })}
            {...{
                style: {
                    zIndex: productVersion.schema.spec.line_items.length + 1,
                },
            }}
        >
            <NotAnchor
                role="tab"
                onClick={() => {
                    raterDispatch({ action: "ToggleOpenCloseParameters" });
                }}
            >
                Global Parameters
            </NotAnchor>
            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
            >
                <ul className="list-drag ui-sortable">
                    <SortableContext
                        items={productVersion.schema.spec.global_params.map(
                            (parameter) => parameter.code
                        )}
                        strategy={verticalListSortingStrategy}
                        disabled={!allowEditing}
                    >
                        {productVersion.schema.spec.global_params.map(
                            (parameter, index) => (
                                <Parameter
                                    key={parameter.code}
                                    parameter={parameter}
                                    index={index}
                                    {...{
                                        zIndexOffset:
                                            productVersion.schema.spec
                                                .line_items.length + 1,
                                    }}
                                />
                            )
                        )}
                    </SortableContext>
                </ul>
            </DndContext>
            {allowEditing && (
                <p className="link-strong">
                    <NotAnchor
                        onClick={() => {
                            openPopup("add-or-edit-parameter", {
                                parameterCode: undefined,
                                lineItemCode: undefined,
                            });
                        }}
                    >
                        <i aria-hidden="true" className="icon-plus-circle" />{" "}
                        Add Global Parameter
                    </NotAnchor>
                </p>
            )}
        </li>
    );
};
const HelpOverlay = () => {
    const { closePopup } = usePopup();
    const { sdkBuilder } = useApi();

    const onClick = async () => {
        await sdkBuilder.createUserChecklist({
            tag: "EnteredRater",
        });
        closePopup("help-overlay");
    };

    return (
        <Popup name="help-overlay" black={false} fullscreen>
            <h2>
                <i aria-hidden="true" className="icon-forward" /> Checklist
                Guides
            </h2>
            <p>
                New here? Follow the steps in the right panel to get up to
                speed.
            </p>
            <p className="link-btn last-child">
                <NotAnchor onClick={onClick}>Ok</NotAnchor>
            </p>
        </Popup>
    );
};

const Main = () => {
    const { sdkBuilder } = useApi();
    const {
        raterDispatch,
        allowEditing,
        itemGetters: {
            allParameters: getAllParameters,
            allLineItems: getAllLineItems,
            rater: getRater,
        },
        raterState: { productVersion },
    } = useRater();
    const { openPopup } = usePopup();
    const rater = getRater();
    const excelErrors = useMemo<ExcelErrorsObject>(
        () => rater?.errors ?? {},
        [rater?.errors]
    );

    useEffect(() => {
        const getParameters = () => {
            if (!productVersion) {
                return;
            }
            raterDispatch({
                action: "SetParameters",
                parameters: productVersion.schema.spec.global_params || [],
            });
        };
        getParameters();
    }, [sdkBuilder, raterDispatch, productVersion]);

    useEffect(() => {
        const getLineItems = () => {
            if (!productVersion) {
                return;
            }
            const lineItems = productVersion.schema.spec.line_items.map(
                (lineItem) => ({
                    lineItem,
                    parameters: lineItem.params,
                    open: true,
                })
            );

            raterDispatch({
                action: "SetLineItems",
                lineItems,
            });
        };
        getLineItems();
    }, [sdkBuilder, raterDispatch, productVersion]);

    useEffect(() => {
        const getRater = async () => {
            if (!productVersion || !productVersion.schema.spec.rater) {
                return;
            }

            const rater_ = await sdkBuilder.getProductRater({
                id: productVersion.schema.spec.rater.Internal.id,
            });

            raterDispatch({
                action: "SetRater",
                rater: rater_,
            });
        };
        getRater();
    }, [productVersion, raterDispatch, sdkBuilder]);

    useEffect(() => {
        if (!rater || rater.status !== ProductRaterStatus.Pending) {
            return;
        }
        const [waitForRaterPromise, cancelRetryWaitForRater] = retry(
            () =>
                sdkBuilder.getProductRater({
                    id: rater.id,
                }),
            (rater) => rater.status !== ProductRaterStatus.Pending,
            2000
        );

        const updateRaterWhenReady = async () => {
            try {
                const rater = await waitForRaterPromise;
                if (rater) {
                    raterDispatch({
                        action: "SetRater",
                        rater,
                    });
                }
            } catch (error) {
                if (!axios.isAxiosError(error)) {
                    throw error;
                }
            }
        };
        updateRaterWhenReady();
        return () => {
            cancelRetryWaitForRater();
        };
    }, [sdkBuilder, raterDispatch, rater]);

    useEffect(() => {
        raterDispatch({
            action: "SetExcelErrors",
            excelErrors: {
                excelErrors,
                open: true,
            },
        });
    }, [excelErrors, raterDispatch]);

    useEffect(() => {
        const doOpenHelpOverlay = async () => {
            const enteredRater = (await sdkBuilder.allUserChecklists()).filter(
                (userChecklist) => userChecklist.tag === "EnteredRater"
            );
            if (enteredRater.length === 0) {
                openPopup("help-overlay");
            }
        };
        doOpenHelpOverlay();
    }, [openPopup, sdkBuilder]);

    if (!productVersion) {
        return <></>;
    }

    const uploadExcelFileLink = (
        <p className="link-btn">
            <NotAnchor
                onClick={() => {
                    openPopup("upload-excel-file");
                }}
            >
                <i className="icon-upload3" /> Upload{" "}
                <span className="mobile-hide">Excel File</span>
            </NotAnchor>
        </p>
    );

    if (getAllParameters() === undefined || getAllLineItems() === undefined) {
        return <></>;
    }

    return (
        <ProductMainPane
            title="Rater"
            layoutConfig={{ headerPosition: "inside-content" }}
            subHeader={{
                itemsAfter: productVersion && ["File status:"],
                pill:
                    productVersion &&
                    (!allowEditing
                        ? { text: "Locked", color: "wine" }
                        : !productVersion.schema.spec.rater
                          ? { text: "NO FILE", color: "wine" }
                          : rater?.status === "Pending"
                            ? { text: "UPLOADING", color: "gold" }
                            : !isEmpty(excelErrors)
                              ? { text: "ISSUES FOUND", color: "gold" }
                              : rater?.status === "Error"
                                ? { text: "ERROR", color: "wine" }
                                : { text: "NO ISSUES", color: "pear" }),
            }}
            headerActions={[
                {
                    text: "Open All",
                    mobileText: "All",
                    onClick: () => {
                        raterDispatch({
                            action: "OpenAll",
                        });
                    },
                    icon: "chevron-square-down",
                },
                {
                    text: "Close All",
                    mobileText: "All",
                    onClick: () => {
                        raterDispatch({
                            action: "CloseAll",
                        });
                    },
                    icon: "chevron-square-up",
                },
            ]}
            headerActionsOnSameLineAsTitle
        >
            <ul
                className="list-drag static color-primary ui-sortable"
                role="tablist"
            >
                <Parameters />
                <LineItems />
                {rater?.status !== "Pending" && <ExcelErrors />}
            </ul>
            {rater ? (
                <MenuPopover
                    component={Footer}
                    menuItems={[
                        {
                            key: "download",
                            label: "Download Excel File",
                            icon: "download3",
                            downloadFileId: rater.file_id,
                        },
                    ]}
                >
                    {({ ToggleButton, Menu }) =>
                        rater?.file_id && (
                            <>
                                <p
                                    className={classNames(
                                        "link-btn has-inline",
                                        {
                                            "text-left": !allowEditing,
                                        }
                                    )}
                                >
                                    <FileDownload
                                        fileIds={[rater.file_id]}
                                        className={classNames("inline", {
                                            disabled:
                                                !rater ||
                                                rater.status === "Pending",
                                        })}
                                    >
                                        <i className="icon-download3" />{" "}
                                        Download Excel File
                                    </FileDownload>
                                </p>
                                {allowEditing && uploadExcelFileLink}
                                {ToggleButton}
                                {Menu}
                            </>
                        )
                    }
                </MenuPopover>
            ) : (
                <Footer>
                    <p
                        className={classNames("link-btn has-inline", {
                            "text-left": !allowEditing,
                        })}
                    >
                        <NotAnchor
                            onClick={() => {
                                return;
                            }}
                            className="inline disabled"
                        >
                            <i className="icon-download3" /> Download Excel File
                        </NotAnchor>
                    </p>
                    {allowEditing && uploadExcelFileLink}
                </Footer>
            )}
        </ProductMainPane>
    );
};

const RaterPage: FC = () => (
    <Page>
        <RaterProvider>
            <Main />
            <ProductVersionCheckList />
            <AddOrEditParameterPopup />
            <AddOrEditLineItemPopup />
            <DeleteLineItemPopup />
            <DeleteParameterPopup />
            <UploadExcelFilePopup />
            <ConnectSheetsInExcelPopup />
            <HelpOverlay />
        </RaterProvider>
    </Page>
);

export default RaterPage;
