import NotAnchor from "components/NotAnchor";
import { toggleDocumentClass } from "paul/native-dom-manipulation";
import { FC, PropsWithChildren } from "react";

const ExtraPane: FC<PropsWithChildren> = ({ children }) => {
    return (
        <>
            <aside id="aside">{children}</aside>
            <NotAnchor
                className="aside-toggle"
                onClick={() => toggleDocumentClass("aside-active")}
            >
                <span></span>
            </NotAnchor>
            <NotAnchor
                className="aside-shadow"
                onClick={() => toggleDocumentClass("aside-active")}
            />
        </>
    );
};

export default ExtraPane;
