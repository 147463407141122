import { FC, useCallback } from "react";
import { z } from "zod";
import { createFormPopup } from "components/Popup";
import { useIntegrations } from "../IntegrationsProvider";
import { usePopup } from "contexts/PopupProvider";
import kebabCase from "lodash/kebabCase";

const selectIntegrationSchema = z.object({
    integrationId: z.string().min(1, "Please make a selection"),
});
type SelectIntegrationType = z.infer<typeof selectIntegrationSchema>;

const { FormPopup: SelectIntegrationFormPopup } = createFormPopup(
    selectIntegrationSchema
);

const SelectIntegrationPopup: FC = () => {
    const {
        accessors: {
            allIntegrations,
            allIntegrationTypes,
            getIntegration,
            hasProductIntegration,
        },
    } = useIntegrations();
    const { openPopup } = usePopup();

    const onSubmit = useCallback(
        async (data: SelectIntegrationType) => {
            if (!data.integrationId) {
                return;
            }
            const integration = getIntegration(data.integrationId);
            if (!integration) {
                return;
            }
            openPopup(`${kebabCase(integration.type_)}-setup-form`, {
                integration: integration,
            });
        },
        [getIntegration, openPopup]
    );

    const integrations = allIntegrations();
    const integrationTypes = allIntegrationTypes();

    if (
        !integrations ||
        Object.keys(integrations).length === 0 ||
        !integrationTypes
    ) {
        return <></>;
    }

    return (
        <SelectIntegrationFormPopup
            name="select-integration"
            defaultValues={{
                integrationId: "",
            }}
            submitText="Next"
            onSubmit={onSubmit}
        >
            {({ register, formState: { errors } }) => (
                <>
                    <header>
                        <h2>Select Integration </h2>
                        <p className="size-14">
                            Who do you want to receive data from?
                        </p>
                    </header>
                    <ul className="check strong">
                        {Object.values(integrations).map((integration) => {
                            const integrationType =
                                integrationTypes[integration.type_];
                            return (
                                <li key={integration.id}>
                                    <input
                                        {...register("integrationId")}
                                        value={integration.id}
                                        type="radio"
                                        id={`fae-${integration.id}`}
                                        disabled={hasProductIntegration(
                                            integration
                                        )}
                                    />
                                    <label htmlFor={`fae-${integration.id}`}>
                                        {integrationType.display_name}
                                        <span>
                                            {integrationType.description}
                                        </span>
                                    </label>
                                </li>
                            );
                        })}
                    </ul>

                    {errors.integrationId && (
                        <label
                            id="integration-error"
                            className="error"
                            htmlFor={`fae-${integrations[0].id}`}
                        >
                            {errors.integrationId.message}
                        </label>
                    )}
                </>
            )}
        </SelectIntegrationFormPopup>
    );
};

export default SelectIntegrationPopup;
