import { FC } from "react";
import classNames from "classnames";
import {
    isStoreUser,
    quoteStatusTimeDescription,
    quoteStatusToColor,
    quoteStatusToText,
} from "../../components/util";
import { useInsuranceProcess } from "./components/InsuranceProcessProvider";
import { useBranding } from "contexts/BrandingProvider";
import { TabInterface, usePage } from "components/Page";
import {
    ProductVersion,
    Quote,
    QuoteStatus,
    Submission,
    SubmissionFlow,
} from "@joshuins/insurance";
import { fullName } from "utils/user";
import NotAnchor from "components/NotAnchor";
import { User } from "@joshuins/system";
import { Link } from "components/DevAwareRoutingLink";
import urlJoin from "url-join";
import { useAuthUser } from "react-auth-kit";

const HeaderDetails: FC<{
    productVersion: ProductVersion;
    submissionUser: User;
    submission: Submission;
    quote: Quote;
    inStore: boolean;
    statusText: string | undefined;
    statusPill:
        | {
              text: string;
              color: string;
          }
        | undefined;
    policyNumber: string | undefined;
}> = ({
    productVersion,
    submissionUser,
    submission,
    quote,
    inStore,
    statusText,
    statusPill,
    policyNumber,
}) => {
    return (
        <>
            <ul className="list-inline a">
                <li>{productVersion.schema.metadata.name}</li>
                {fullName(submissionUser) && (
                    <li>{fullName(submissionUser)}</li>
                )}
                {submission.flow === SubmissionFlow.Endorsement &&
                quote.status === QuoteStatus.QuotePending &&
                !inStore ? (
                    <li>
                        Make any requested changes below. Click &quot;Issue
                        Endorsement&quot; when done.
                    </li>
                ) : (
                    <>{statusText && <li>{statusText}</li>}</>
                )}
                {policyNumber && <li>Policy No. {policyNumber}</li>}
                {statusPill && (
                    <li className={`overlay ${statusPill.color}`}>
                        <span>{statusPill.text}</span>
                    </li>
                )}
            </ul>
        </>
    );
};

const QuoteHeader: FC<{ tabs: TabInterface[] }> = ({ tabs }) => {
    const {
        itemGetters: { getInsuranceProcess, getBindQuestions },
        insuranceProcessState,
    } = useInsuranceProcess();
    const { submission, quote, submissionUser, insured, productVersion } =
        getInsuranceProcess();
    const bindQuestions = getBindQuestions();
    const { storeUrlName, generateUrl } = useBranding();
    const { activeTab } = usePage();
    const authUser = useAuthUser();
    const currentUser = authUser() as User;
    const inStore = storeUrlName !== undefined;

    if (!insured) {
        return <></>;
    }

    const computedStatus =
        isStoreUser(currentUser) && quote.status === QuoteStatus.Error
            ? QuoteStatus.QuotePending
            : quote.status;
    let statusPill: { text: string; color: string } | undefined;
    const statusText = quoteStatusTimeDescription(
        quote,
        insuranceProcessState.insuranceProcess?.quoteCodesAndValues ?? []
    );
    const statusColor = quoteStatusToColor(quote.status);
    if (statusColor) {
        statusPill = {
            text: quoteStatusToText[computedStatus],
            color: statusColor,
        };
    }

    const hideTabs =
        inStore &&
        (quote.status === QuoteStatus.QuotePending ||
            quote.status === QuoteStatus.QuoteDeclined ||
            quote.status === QuoteStatus.QuoteClosed);

    return (
        <header className="header-double m35 a">
            <h1>
                {insured.name}
                <NotAnchor
                    className="close-tabs"
                    onClick={() => {
                        return;
                    }}
                />
            </h1>
            <HeaderDetails
                productVersion={productVersion}
                submissionUser={submissionUser}
                submission={submission}
                quote={quote}
                inStore={inStore}
                statusText={statusText}
                statusPill={statusPill}
                policyNumber={undefined}
            />
            {inStore && quote.status === QuoteStatus.QuoteStoreEdit && (
                <ul className="list-inline" style={{ marginTop: "4px" }}>
                    <li>
                        This quote has no premium yet, finish editing and click
                        Send For Review below.
                    </li>
                </ul>
            )}

            {quote.additional_details && !isStoreUser(currentUser) && (
                <ul className="list-inline" style={{ marginTop: "4px" }}>
                    <li className="color-wine">{quote.additional_details}</li>
                </ul>
            )}

            {!hideTabs &&
                bindQuestions &&
                bindQuestions.length > 0 &&
                submission.flow !== SubmissionFlow.Endorsement && (
                    <ul className="list-inline tabs strong module-tabs-nav is-tabs">
                        {tabs.map((tab) => (
                            <li
                                key={tab.path}
                                className={classNames({
                                    active: tab === activeTab(),
                                })}
                            >
                                <Link
                                    to={generateUrl(
                                        urlJoin(
                                            "/",
                                            "quotes",
                                            quote.id.toString(),
                                            tab.path
                                        )
                                    )}
                                >
                                    {tab.title}
                                </Link>
                            </li>
                        ))}
                    </ul>
                )}
        </header>
    );
};

export { QuoteHeader, HeaderDetails };
