import { ProductVersion } from "@joshuins/insurance";
import { Footer, MainPane } from "components/MainPane";
import { Page, usePage } from "components/Page";
import ExtraPane from "components/extra-panes/ExtraPane";
import { useComplexApiData } from "contexts/ComplexApiDataProvider";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import compact from "lodash/compact";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useCreateSubmissionWizard } from "./use-create-submission-wizard";
import { Insured } from "@joshuins/insurance";
import { useLocation } from "react-router-dom";
import { showLoader } from "paul/native-dom-manipulation";
import { useBranding } from "contexts/BrandingProvider";

const selectProductVersionSchema = z.object({
    productVersionId: z.string().regex(/^\d+$/, "Please make a selection"),
});
type SelectProductVersionType = z.infer<typeof selectProductVersionSchema>;

const NewSubmissionExtraPane: FC = () => (
    <ExtraPane>
        <h3>
            <i aria-hidden="true" className="icon-help" /> Select a Product
        </h3>
        <p>
            To create a submission you need to pick the product for which the
            insured is looking for coverage.
        </p>
    </ExtraPane>
);

const ProductVersionsList: FC = () => {
    const [productVersions, setProductVersions] = useState<{
        published: ProductVersion[];
        draft: ProductVersion[] | undefined;
    }>();
    const { productVersionsForCurrentUser } = useComplexApiData();
    const previouslyUnmounted = useRef(false);
    const newSubmissionWizardProceed = useCreateSubmissionWizard();
    const { element } = usePage();
    const insured = element as unknown as Insured;
    const { state } = useLocation();
    const { storeUrlName } = useBranding();
    const inStore = storeUrlName !== undefined;

    const {
        register,
        handleSubmit,
        formState: { isValid },
    } = useForm<SelectProductVersionType>({
        resolver: zodResolver(selectProductVersionSchema),
        defaultValues: {
            productVersionId: "",
        },
    });

    useEffect(() => {
        const initialize = async () => {
            if (!state?.fromWizard) {
                newSubmissionWizardProceed("newSubmission", {
                    insuredId: insured.id,
                });
                return;
            }
            window.history.replaceState({}, document.title);
            const productVersions_ = await productVersionsForCurrentUser();
            const allProductVersions = (productVersions_.draft || []).concat(
                productVersions_.published
            );
            if (allProductVersions.length === 1) {
                newSubmissionWizardProceed("newSubmission", {
                    productVersionId: allProductVersions[0].id,
                    insuredId: insured.id,
                });
            } else {
                setProductVersions(productVersions_);
            }
        };

        if (!previouslyUnmounted.current) {
            previouslyUnmounted.current = true;
            initialize();
        }

        return () => {
            previouslyUnmounted.current = true;
        };
    }, [
        newSubmissionWizardProceed,
        productVersionsForCurrentUser,
        insured,
        state,
        state?.fromWizard,
    ]);

    const onSubmit = useCallback(
        async (data: SelectProductVersionType) => {
            if (!data.productVersionId) {
                return;
            }
            showLoader("Processing...");
            newSubmissionWizardProceed("newSubmission", {
                insuredId: insured.id,
                productVersionId: parseInt(data.productVersionId),
            });
        },
        [newSubmissionWizardProceed, insured.id]
    );

    if (!productVersions) {
        return <></>;
    }

    return (
        <MainPane
            title="Select Product"
            layoutConfig={{
                headerPosition: "inside-content",
                mainLayout: null,
                headerClass: null,
            }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                {productVersions.published &&
                    productVersions.published.length > 0 && (
                        <>
                            <h2>Published Products</h2>
                            <ul className="check strong">
                                {productVersions.published.map(
                                    (productVersion) => (
                                        <li key={productVersion.id}>
                                            <input
                                                {...register(
                                                    "productVersionId"
                                                )}
                                                type="radio"
                                                value={productVersion.id}
                                                id={`fa-${productVersion.id}`}
                                                disabled={
                                                    productVersion.issues
                                                        .length > 0
                                                }
                                            />
                                            <label
                                                htmlFor={`fa-${productVersion.id}`}
                                            >
                                                {
                                                    productVersion.schema
                                                        .metadata.name
                                                }
                                                <span>
                                                    {compact([
                                                        inStore
                                                            ? undefined
                                                            : productVersion
                                                                  .schema
                                                                  .metadata
                                                                  .internal_version_name,
                                                        productVersion.schema
                                                            .metadata
                                                            .description,
                                                    ]).join(" | ")}
                                                </span>
                                            </label>
                                        </li>
                                    )
                                )}
                            </ul>
                        </>
                    )}
                {productVersions.draft && productVersions.draft.length > 0 && (
                    <>
                        <h2>Drafts (Use test data only)</h2>
                        <ul className="check strong">
                            {productVersions.draft.map((productVersion) => {
                                return (
                                    <li key={productVersion.id}>
                                        <input
                                            {...register("productVersionId")}
                                            type="radio"
                                            value={productVersion.id}
                                            id={`fa-${productVersion.id}`}
                                            disabled={
                                                productVersion.issues.length > 0
                                            }
                                        />
                                        <label
                                            htmlFor={`fa-${productVersion.id}`}
                                        >
                                            {
                                                productVersion.schema.metadata
                                                    .name
                                            }
                                            <span>
                                                {compact([
                                                    productVersion.schema
                                                        .metadata
                                                        .internal_version_name,
                                                    productVersion.schema
                                                        .metadata.description,
                                                ]).join(" | ")}
                                            </span>
                                        </label>
                                    </li>
                                );
                            })}
                        </ul>
                    </>
                )}

                <Footer className="compact">
                    <p className="link-btn">
                        <button type="submit" disabled={!isValid}>
                            <i
                                aria-hidden="true"
                                className="icon-check-circle-outline"
                            />
                            Continue
                        </button>
                    </p>
                </Footer>
            </form>
        </MainPane>
    );
};

const NewSubmissionPage: FC = () => {
    const { key } = useLocation();

    return (
        <Page>
            <ProductVersionsList key={key} />
            <NewSubmissionExtraPane />
        </Page>
    );
};

export default NewSubmissionPage;
