import { FC, useEffect, useState } from "react";
import { Popup } from "components/Popup";
import { useApi } from "contexts/ApiProvider";
import { usePopup } from "contexts/PopupProvider";
import { BrokerageCodeAndValue, OfficeCodeAndValue } from "@joshuins/system";
import { getRawValueAsStringFromJoValue } from "utils/jo-types-and-values";

const BrokerageDataPopup: FC = () => {
    const apiProviderContext = useApi();
    const { sdkSystem } = apiProviderContext;
    const { popupData, isPopupOpen } = usePopup();
    const brokerageId = popupData?.brokerageId as number;
    const [brokerageData, setBrokerageData] =
        useState<BrokerageCodeAndValue[]>();

    useEffect(() => {
        const getBrokerageData = async () => {
            if (!isPopupOpen("brokerage-data") || !brokerageId) {
                return;
            }
            const brokerageData_ = await sdkSystem.getBrokerageData({
                id: brokerageId,
            });
            setBrokerageData(brokerageData_);
        };
        getBrokerageData();
    }, [isPopupOpen, brokerageId, sdkSystem]);

    return (
        <Popup name="brokerage-data">
            {brokerageData && brokerageData.length > 0 && (
                <>
                    <h2 className="m25 color-primary">
                        Additional Brokerage Data
                    </h2>
                    <ul className="list-plain box">
                        {brokerageData.map((item, index) => (
                            <li key={index} style={{ wordBreak: "break-all" }}>
                                <span>
                                    <b>{item.code}</b>
                                </span>
                                {getRawValueAsStringFromJoValue(item.value)}
                            </li>
                        ))}
                    </ul>
                </>
            )}
            {!brokerageData ||
                (brokerageData.length === 0 && (
                    <h2 className="m25 color-primary">No Additional Data</h2>
                ))}
        </Popup>
    );
};

const OfficeDataPopup: FC = () => {
    const apiProviderContext = useApi();
    const { sdkSystem } = apiProviderContext;
    const { popupData, isPopupOpen } = usePopup();
    const officeId = popupData?.officeId as number;
    const [officeData, setOfficeData] = useState<OfficeCodeAndValue[]>();

    useEffect(() => {
        const getOfficeData = async () => {
            if (!isPopupOpen("office-data") || !officeId) {
                return;
            }
            const officeData_ = await sdkSystem.getOfficeData({
                id: officeId,
            });
            setOfficeData(officeData_);
        };
        getOfficeData();
    }, [isPopupOpen, officeId, sdkSystem]);

    return (
        <Popup name="office-data">
            {officeData && officeData.length > 0 && (
                <>
                    <h2 className="m25 color-primary">
                        Additional Office Data
                    </h2>
                    <ul className="list-plain box">
                        {officeData.map((item, index) => (
                            <li key={index}>
                                <span>
                                    <b>{item.code}</b>
                                </span>
                                {getRawValueAsStringFromJoValue(item.value)}
                            </li>
                        ))}
                    </ul>
                </>
            )}
            {!officeData ||
                (officeData.length === 0 && <h2>No Additional Data</h2>)}
        </Popup>
    );
};

export { BrokerageDataPopup, OfficeDataPopup };
