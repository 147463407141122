import {
    createContext,
    Dispatch,
    FC,
    PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";

import {
    ApplicationDatapointV1,
    ApplicationSectionItemV1,
    Document,
    ProductVersion,
    Quote,
    QuoteCodeAndValue,
    QuoteCodeAndUserValue,
    QuoteStatus,
    SubmissionFlow,
    Submission,
} from "@joshuins/insurance";
import { useApi } from "contexts/ApiProvider";
import { z, ZodType } from "zod";
import { createForm } from "components/Form";
import { AlertCategory, usePage } from "components/Page";
import {
    ComplexApiDataProviderInterface,
    useComplexApiData,
} from "contexts/ComplexApiDataProvider";
import { useImmerReducer } from "use-immer";
import { hideLoader, showLoader } from "paul/native-dom-manipulation";
import axios from "axios";
import SubmissionData from "utils/submission-data";
import keyBy from "lodash/keyBy";
import compact from "lodash/compact";
import { unpaginateWithMultiParams } from "components/sdk";
import { getMessageFromAxiosError } from "utils/axios-extras";
import { isBinder, isPolicy } from "utils/policies";

const DOC_CODE_PREFIX = "doc." as const;

type InsuranceProcess = NonNullable<
    Awaited<
        Awaited<
            ReturnType<ComplexApiDataProviderInterface["insuranceProcess"]>
        >["insuranceProcessPromise"]
    >
>;

type InsuranceAction =
    | {
          action: "SetParsedSubmissionData";
          parsedSubmissionData: SubmissionData;
      }
    | {
          action: "SetQuote";
          quote: Quote;
      }
    | {
          action: "SetQuoteCodesAndValues";
          quoteCodesAndValues: QuoteCodeAndValue[];
      }
    | {
          action: "UpdateQuoteCodesAndValues";
          quoteCodesAndValues: QuoteCodeAndValue[];
      }
    | {
          action: "SetInsuranceProcess";
          insuranceProcess: InsuranceProcess;
      }
    | {
          action: "SetDocument";
          document: Document;
      }
    | {
          action: "SetDocuments";
          documents: Document[];
      }
    | {
          action: "SetDirtyFields";
          dirtyFields: QuoteCodeAndValue[];
      };

interface InsuranceProcessState {
    insuranceProcess: InsuranceProcess | undefined;
    dirtyFields: QuoteCodeAndValue[];
}

interface InsuranceProcessProviderInterface {
    insuranceProcessState: InsuranceProcessState;
    insuranceProcessDispatch: Dispatch<InsuranceAction>;
    insuranceHistory: Submission[]; //Awaited<ReturnType<InsuranceApi["insuranceHistory"]>>;
    formConfig: ReturnType<typeof createForm>;
    itemGetters: {
        getInsuranceProcess: () => InsuranceProcess;
        getBindQuestions: () => ApplicationSectionItemV1[] | undefined;
        getPreviousQuoteCodesAndValues: () => Promise<
            QuoteCodeAndValue[] | undefined
        >;
        getPreviousParsedSubmissionData: () => Promise<
            SubmissionData | undefined
        >;
        getPreviousProductVersion: () => Promise<ProductVersion | undefined>;
        getPreviousDatapoints: () => Promise<
            ApplicationDatapointV1[] | undefined
        >;
    };
    pageIds: { quoteId: number | undefined; policyId: number | undefined };
    recalculateQuote: (
        newQuoteCodesAndValues: QuoteCodeAndUserValue[]
    ) => Promise<undefined>;
}

const generateParameterSchema = (quoteCodesAndValues: QuoteCodeAndValue[]) => {
    const fields = quoteCodesAndValues.reduce(
        (all, curr) => {
            if (!curr.code.startsWith(DOC_CODE_PREFIX)) {
                // TODO: check if zod is necessary in other formats except .string()
                all[curr.code] = z.string();
            }
            return all;
        },
        {} as Record<string, ZodType>
    );

    return z.object(fields);
};

const QuoteContext = createContext<
    InsuranceProcessProviderInterface | undefined
>(undefined);

const useInsuranceProcess = () => {
    const context = useContext(QuoteContext);

    if (context === undefined) {
        throw Error(
            "useInsuranceProcess must be used inside a InsuranceProcessProvider context"
        );
    }

    return context;
};

const InsuranceProcessProvider: FC<PropsWithChildren> = ({ children }) => {
    const { sdkInsurance } = useApi();
    const { insuranceProcess: getInsuranceProcess } = useComplexApiData();
    const [insuranceProcessReturn, setInsuranceProcessReturn] =
        useState<Awaited<ReturnType<typeof getInsuranceProcess>>>();
    const [dirtyFields, setDirtyFields] = useState<QuoteCodeAndValue[]>();
    const { addAlertMessages, element } = usePage();
    const quoteOrPolicy = element as unknown as Quote;
    const [formConfig, setFormConfig] =
        useState<ReturnType<typeof createForm>>();
    const [insuranceHistory, setInsuranceHistory] = useState<Submission[]>();
    const [pageIds, setPageIds] = useState<{
        quoteId: number | undefined;
        policyId: number | undefined;
    }>();

    const recalculateQuote = async (
        updateQuoteCodesAndValues: QuoteCodeAndUserValue[]
    ) => {
        const quoteId =
            pageIds?.quoteId ||
            insuranceProcessState.insuranceProcess?.quote.id;
        if (!quoteId) {
            return;
        }
        try {
            await sdkInsurance.updateQuoteData({
                id: quoteId,
                CreateQuoteData: {
                    data: updateQuoteCodesAndValues,
                },
            });
            const [newQuotePromise] = sdkInsurance.getQuoteWhenReady(quoteId);
            showLoader("Calculating...");
            const newQuote = await newQuotePromise;
            if (newQuote) {
                const newQuoteData = await sdkInsurance.getQuoteData({
                    id: newQuote.id,
                });
                const newDocuments = await unpaginateWithMultiParams(
                    sdkInsurance.allDocuments,
                    {
                        quote_id: newQuote.id,
                    }
                );
                insuranceProcessDispatch({
                    action: "SetQuote",
                    quote: newQuote,
                });
                insuranceProcessDispatch({
                    action: "SetQuoteCodesAndValues",
                    quoteCodesAndValues: newQuoteData,
                });
                insuranceProcessDispatch({
                    action: "UpdateQuoteCodesAndValues",
                    quoteCodesAndValues: newQuoteData,
                });
                insuranceProcessDispatch({
                    action: "SetDocuments",
                    documents: newDocuments,
                });
                insuranceProcessDispatch({
                    action: "SetDirtyFields",
                    dirtyFields: [],
                });
                hideLoader();
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                addAlertMessages({
                    message: getMessageFromAxiosError(error),
                    category: AlertCategory.ALERT,
                });
                return undefined;
            } else {
                throw error;
            }
        }
    };

    const reducer = (draft: InsuranceProcessState, action: InsuranceAction) => {
        switch (action.action) {
            case "SetInsuranceProcess": {
                draft.insuranceProcess = action.insuranceProcess;
                break;
            }
            case "SetParsedSubmissionData": {
                const insuranceProcess = draft.insuranceProcess;
                if (insuranceProcess) {
                    insuranceProcess.parsedSubmissionData =
                        action.parsedSubmissionData;
                }
                break;
            }
            case "SetQuote": {
                const insuranceProcess = draft.insuranceProcess;
                if (insuranceProcess) {
                    insuranceProcess.quote = action.quote;
                }
                break;
            }
            case "SetQuoteCodesAndValues": {
                const insuranceProcess = draft.insuranceProcess;
                if (insuranceProcess) {
                    insuranceProcess.quoteCodesAndValues =
                        action.quoteCodesAndValues;
                }
                break;
            }
            case "UpdateQuoteCodesAndValues": {
                if (draft.insuranceProcess) {
                    if (action.quoteCodesAndValues.length > 0) {
                        const quoteCodesAndValuesByCode = keyBy(
                            draft.insuranceProcess.quoteCodesAndValues,
                            "code"
                        );
                        action.quoteCodesAndValues.map((item) => {
                            quoteCodesAndValuesByCode[item.code] = item;
                        });
                        draft.insuranceProcess.quoteCodesAndValues =
                            Object.values(quoteCodesAndValuesByCode);
                    } else {
                        draft.insuranceProcess.quoteCodesAndValues = [];
                    }
                }
                break;
            }
            case "SetDocuments": {
                const insuranceProcess = draft.insuranceProcess;
                if (insuranceProcess) {
                    insuranceProcess.documents = action.documents;
                }
                break;
            }
            case "SetDocument": {
                const insuranceProcess = draft.insuranceProcess;
                if (insuranceProcess) {
                    const documentIndex = insuranceProcess.documents.findIndex(
                        (document) => document.id === action.document.id
                    );
                    if (documentIndex !== -1) {
                        insuranceProcess.documents[documentIndex] =
                            action.document;
                    }
                }
                break;
            }
            case "SetDirtyFields": {
                if (action.dirtyFields.length > 0) {
                    const dirtyFieldsByCode = keyBy(draft.dirtyFields, "code");
                    action.dirtyFields.map((item) => {
                        dirtyFieldsByCode[item.code] = item;
                    });
                    draft.dirtyFields = Object.values(dirtyFieldsByCode);
                } else {
                    draft.dirtyFields = [];
                }
                break;
            }
        }
    };
    const [insuranceProcessState, insuranceProcessDispatch] = useImmerReducer<
        InsuranceProcessState,
        InsuranceAction
    >(reducer, {
        insuranceProcess: undefined,
        dirtyFields: [],
    });

    useEffect(() => {
        const getInsuranceProcessReturn = async () => {
            if (!quoteOrPolicy) {
                return;
            }
            setInsuranceProcessReturn(
                await getInsuranceProcess(quoteOrPolicy.id)
            );
        };
        getInsuranceProcessReturn();
    }, [getInsuranceProcess, quoteOrPolicy]);

    useEffect(() => {
        if (!insuranceProcessReturn) {
            return;
        }
        const {
            insuranceProcessPromise,
            cancelRetry,
            isReadyImmediatelyPromise,
        } = insuranceProcessReturn;

        const getRelatedObjects = async () => {
            if (!insuranceProcessPromise) {
                return;
            }

            if (!(await isReadyImmediatelyPromise)) {
                showLoader("Calculating...");
            }

            let insuranceProcess_ = await insuranceProcessPromise;

            if (!insuranceProcess_) {
                return;
            }

            if (!(await isReadyImmediatelyPromise)) {
                hideLoader();
            }

            insuranceProcess_ = {
                ...insuranceProcess_,
                quoteCodesAndValues: insuranceProcess_.quoteCodesAndValues,
            };

            insuranceProcessDispatch({
                action: "SetInsuranceProcess",
                insuranceProcess: insuranceProcess_,
            });

            const submissions_ = await sdkInsurance.allSubmissions({
                policy_id: insuranceProcess_.submission.policy_id,
            });
            setInsuranceHistory(submissions_.items);
            setPageIds(
                isPolicy(quoteOrPolicy) || isBinder(quoteOrPolicy)
                    ? {
                          quoteId: undefined,
                          policyId: quoteOrPolicy.id,
                      }
                    : {
                          quoteId: quoteOrPolicy.id,
                          policyId: undefined,
                      }
            );
            setDirtyFields([]);
            const formConfig = createForm(
                generateParameterSchema(insuranceProcess_.quoteCodesAndValues)
            );
            setFormConfig(formConfig);
        };
        getRelatedObjects();
        return () => {
            if (cancelRetry) {
                cancelRetry();
            }
        };
    }, [
        insuranceProcessDispatch,
        insuranceProcessReturn,
        quoteOrPolicy,
        sdkInsurance,
    ]);

    const getInsuranceProcess_ = useCallback(() => {
        return insuranceProcessState.insuranceProcess;
    }, [insuranceProcessState.insuranceProcess]) as () => InsuranceProcess; // TODO: fix as, replace with assert or something

    const getPreviousInsuranceObjects = useCallback(async () => {
        const submission = insuranceProcessState.insuranceProcess?.submission;
        const quote = insuranceProcessState.insuranceProcess?.quote;
        if (
            !quote ||
            !submission ||
            !insuranceHistory ||
            submission.flow !== SubmissionFlow.Endorsement
        ) {
            return;
        }
        const currentIndexInHistory = insuranceHistory.findIndex(
            (item) => item.id === quote.submission_id
        );

        return insuranceHistory[currentIndexInHistory - 1];
    }, [
        insuranceHistory,
        insuranceProcessState.insuranceProcess?.quote,
        insuranceProcessState.insuranceProcess?.submission,
    ]);

    const getPreviousQuoteCodesAndValues = useCallback(async () => {
        const previousSubmission = await getPreviousInsuranceObjects();
        const quotes_ = await sdkInsurance.allQuotes({
            submission_id: previousSubmission?.id,
            status: QuoteStatus.CoverageActive,
        });
        const previousQuote =
            quotes_.items.length > 0 ? quotes_.items[0] : undefined;

        if (!previousQuote) {
            return;
        }

        const previousQuoteCodesAndValues = await sdkInsurance.getQuoteData({
            id: previousQuote.id,
        });
        return previousQuoteCodesAndValues;
    }, [getPreviousInsuranceObjects, sdkInsurance]);

    const getPreviousParsedSubmissionData = useCallback(async () => {
        const previousSubmission = await getPreviousInsuranceObjects();
        if (!previousSubmission) {
            return;
        }
        const previousSubmissionData =
            await sdkInsurance.getParsedSubmissionData(previousSubmission.id);
        return previousSubmissionData;
    }, [getPreviousInsuranceObjects, sdkInsurance]);

    const getPreviousProductVersion = useCallback(async () => {
        const previousSubmission = await getPreviousInsuranceObjects();
        if (!previousSubmission) {
            return;
        }

        return await sdkInsurance.getProductVersion({
            id: previousSubmission.product_version_id,
        });
    }, [getPreviousInsuranceObjects, sdkInsurance]);

    const getPreviousDatapoints = useCallback(async () => {
        const previousSubmission = await getPreviousInsuranceObjects();
        if (!previousSubmission) {
            return;
        }

        const previousProductVersion = await sdkInsurance.getProductVersion({
            id: previousSubmission.product_version_id,
        });
        const datapoints = compact(
            previousProductVersion.schema.spec.sections.flatMap((section) =>
                section.items.flatMap((item) => {
                    if ("Datapoint" in item) return item.Datapoint;
                })
            )
        );
        return datapoints;
    }, [getPreviousInsuranceObjects, sdkInsurance]);

    const getBindQuestions = useCallback(() => {
        const productSections =
            insuranceProcessState.insuranceProcess?.productVersion.schema.spec
                .sections;
        if (!productSections) {
            return;
        }
        const bindSectionFromSchema = productSections.filter(
            (section) => section.type === "BindQuestion"
        );

        if (bindSectionFromSchema.length === 0) {
            return;
        }
        const bindQuesetions = bindSectionFromSchema[0].items; // only one BindQuestion section type

        return bindQuesetions;
    }, [insuranceProcessState.insuranceProcess?.productVersion.schema]);

    if (
        !insuranceProcessState.insuranceProcess ||
        !dirtyFields ||
        !formConfig ||
        !insuranceHistory ||
        !pageIds ||
        !insuranceProcessState.insuranceProcess.quoteCodesAndValues
    ) {
        return;
    }

    return (
        <QuoteContext.Provider
            value={{
                insuranceProcessState,
                insuranceProcessDispatch,
                insuranceHistory,
                pageIds,
                itemGetters: {
                    getInsuranceProcess: getInsuranceProcess_,
                    getBindQuestions,
                    getPreviousQuoteCodesAndValues,
                    getPreviousParsedSubmissionData,
                    getPreviousProductVersion: getPreviousProductVersion,
                    getPreviousDatapoints,
                },
                formConfig,
                recalculateQuote,
            }}
        >
            {children}
        </QuoteContext.Provider>
    );
};

export { InsuranceProcessProvider, useInsuranceProcess };
