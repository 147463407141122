import { Page } from "components/Page";
import ExtraPane from "components/extra-panes/ExtraPane";
import axios from "axios";
import { FC, useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import {
    PolicyNumberPool,
    ProductExport,
    ProductImportReplacements,
    ProductVersionView,
} from "@joshuins/builder";
import { Integration, IntegrationTypeInfo } from "@joshuins/system";
import { MenuItem, Select } from "components/Select";
import { AlertCategory, usePage } from "components/Page";
import { unpaginate } from "components/sdk";
import { useApi } from "contexts/ApiProvider";
import urlJoin from "url-join";
import { BUILDER_PATH } from "globals";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { hideLoader, showLoader } from "paul/native-dom-manipulation";
import groupBy from "lodash/groupBy";
import keyBy from "lodash/keyBy";
import { FormControl } from "@mui/material";
import { getMessageFromAxiosError } from "utils/axios-extras";

const ProdutImportExtraPane: FC = () => {
    return (
        <ExtraPane>
            <h3>
                <i aria-hidden="true" className="icon-help" /> Product Import
            </h3>
            <p>
                In order to import a product succefully, All the Product&quot;s
                integrations and policy number pools needs to be mapped. If an
                integration or a policy pool is missing, a new one needs to be
                added, and then mapped.
            </p>
        </ExtraPane>
    );
};

const Main: FC = () => {
    const { sdkBuilder, sdkSystem } = useApi();
    const navigate = useNavigate();
    const { element, addAlertMessages, refreshState } = usePage();
    const productExport = element as unknown as ProductExport | undefined;
    const [integrations, setIntegrations] = useState<
        Record<string, Integration[]>
    >({});
    const [integrationTypes, setIntegrationTypes] =
        useState<Record<string, IntegrationTypeInfo>>();
    const [numberPools, setNumberPools] = useState<PolicyNumberPool[]>();
    const [integrationReplacements, setIntegrationReplacements] =
        useState<Record<string, string>>();
    const [policyPoolreplacements, setPolicyPoolReplacements] =
        useState<Record<string, string>>();
    const [productVersions, setProductVersions] =
        useState<ProductVersionView[]>();
    const [productId, setProductId] = useState<string>();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const getProductVersions = async () => {
            const allProductVersions = await sdkBuilder.getProductVersions({});
            const productVersions_: ProductVersionView[] = [];
            for (const productVersion of allProductVersions) {
                productVersions_.push(productVersion[0]);
            }
            setProductVersions(productVersions_);
            setProductId(searchParams.get("product-id") || undefined);
        };
        getProductVersions();
    }, [sdkBuilder, searchParams]);

    useEffect(() => {
        const getNumberPools = async () => {
            const numberPools_ = await unpaginate(
                sdkBuilder.allPolicyNumberPools,
                {}
            );
            setNumberPools(numberPools_);
        };
        getNumberPools();
    }, [sdkBuilder]);

    useEffect(() => {
        const getIntegrations = async () => {
            const integrationTypes_ = await sdkSystem.getIntegrationTypes();
            setIntegrationTypes(keyBy(integrationTypes_, "name"));

            const integrations_ = await unpaginate(
                sdkSystem.allIntegrations,
                {}
            );
            setIntegrations(groupBy(integrations_, "type_"));
        };
        getIntegrations();
    }, [sdkBuilder, sdkSystem]);

    const setIntegrationReplacement = useCallback(
        (id: string, value: string) => {
            const replacements_: Record<string, string> =
                integrationReplacements || {};
            replacements_[id] = value;
            setIntegrationReplacements(replacements_);
            refreshState();
        },
        [refreshState, integrationReplacements]
    );

    const setPolicyPoolReplacement = useCallback(
        (id: string, value: string) => {
            const replacements_: Record<string, string> =
                policyPoolreplacements || {};
            replacements_[id] = value;
            setPolicyPoolReplacements(replacements_);
            refreshState();
        },
        [refreshState, policyPoolreplacements]
    );

    const selectProductId = useCallback(
        (productId_: string | undefined) => {
            setProductId(productId_);
            refreshState();
        },
        [refreshState]
    );

    const submitImport = useCallback(async () => {
        if (!productExport) {
            return;
        }
        const updateReplacements: ProductImportReplacements = {
            integrations: {},
            policy_number_pools: {},
        };
        if (integrationReplacements) {
            for (const replacementId of Object.keys(integrationReplacements)) {
                updateReplacements.integrations[replacementId] =
                    integrationReplacements[replacementId];
            }
        }
        if (policyPoolreplacements) {
            for (const replacementId of Object.keys(policyPoolreplacements)) {
                updateReplacements.policy_number_pools[replacementId] =
                    policyPoolreplacements[replacementId];
            }
        }
        try {
            showLoader("Importing...");
            let productId_ = productId ? parseInt(productId) : undefined;
            if (!productId_) {
                const { id: newProductId } = await sdkBuilder.createProduct({
                    body: {},
                });
                productId_ = newProductId;
            }
            const productVersion = await sdkBuilder.createProductVersion({
                CreateProductVersion: {
                    Import: {
                        product_id: productId_,
                        product_export_id: productExport.id,
                        replacements: updateReplacements,
                    },
                },
            });
            hideLoader();
            navigate(
                urlJoin(
                    "/",
                    BUILDER_PATH,
                    "products",
                    productVersion.id.toString()
                )
            );
        } catch (error) {
            if (axios.isAxiosError(error)) {
                addAlertMessages({
                    message: getMessageFromAxiosError(error),
                    category: AlertCategory.ALERT,
                });
            } else {
                throw error;
            }
        } finally {
            hideLoader();
        }
    }, [
        addAlertMessages,
        integrationReplacements,
        navigate,
        policyPoolreplacements,
        productExport,
        productId,
        sdkBuilder,
    ]);

    if (!productExport) {
        return <></>;
    }
    // missingIntegrations in format {"id": "integration_type"}
    const missingIntegrations = productExport.schema_replacements.integrations;
    const missingPolicyPools =
        productExport.schema_replacements.policy_number_pools;
    const noReplacements =
        Object.keys(missingIntegrations).length === 0 &&
        Object.keys(missingPolicyPools).length === 0;

    if (missingIntegrations && !integrationTypes) {
        return <></>;
    }

    return (
        <div id="wrap">
            <header className="header-double">
                <h1>Product Import</h1>
                <div className="div-as-p m0">
                    <FormControl>
                        {/* <InputLabel>Product</InputLabel> */}
                        <Select
                            id="product_id"
                            value={productId ?? ""}
                            displayEmpty={true}
                            style={{
                                minWidth: "200px",
                            }}
                            className="select-small styled-select-menu m0"
                            name="product_id"
                            onChange={(e) => {
                                selectProductId(e.target.value);
                            }}
                        >
                            <MenuItem key={"new"} value="">
                                Create New Product
                            </MenuItem>
                            {productVersions?.map((productVersion) => (
                                <MenuItem
                                    key={productVersion.id}
                                    value={productVersion.product_id}
                                >
                                    {productVersion.name}{" "}
                                    {productVersion.internal_version_name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
            </header>
            <main id="content" className="wide">
                <header>
                    {productExport.product_schema.metadata.name}{" "}
                    {
                        productExport.product_schema.metadata
                            .internal_version_name
                    }
                </header>
                {integrationTypes &&
                    Object.keys(missingIntegrations).length > 0 && (
                        <div className="form-box">
                            <h2 className="m25 color-primary">
                                Map Integrations
                            </h2>
                            {Object.keys(missingIntegrations).map(
                                (missingIntegrationId, index) => {
                                    const missingIntegration =
                                        productExport.schema_replacements
                                            .integrations[missingIntegrationId];
                                    const missingIntegrationType =
                                        integrationTypes[missingIntegration];
                                    return (
                                        <div className="cols" key={index}>
                                            <div className="c50 div-as-p">
                                                <label
                                                    htmlFor={
                                                        missingIntegrationId
                                                    }
                                                >
                                                    {
                                                        missingIntegrationType.display_name
                                                    }
                                                </label>
                                            </div>
                                            <div className="c50">
                                                {(!integrations[
                                                    missingIntegration
                                                ] ||
                                                    integrations[
                                                        missingIntegration
                                                    ].length === 0) && (
                                                    <Link
                                                        to={urlJoin(
                                                            "/",
                                                            BUILDER_PATH,
                                                            "system",
                                                            "integrations"
                                                        )}
                                                    >
                                                        Add integration
                                                    </Link>
                                                )}
                                                {integrations[
                                                    missingIntegration
                                                ] &&
                                                    integrations[
                                                        missingIntegration
                                                    ].length > 0 && (
                                                        <Select
                                                            id={
                                                                missingIntegrationId
                                                            }
                                                            className="styled-select-menu"
                                                            style={{
                                                                width: "100%",
                                                            }}
                                                            name={
                                                                missingIntegrationType.display_name
                                                            }
                                                            value={
                                                                (integrationReplacements &&
                                                                    integrationReplacements[
                                                                        missingIntegrationId
                                                                    ]) ||
                                                                ""
                                                            }
                                                            onChange={(e) => {
                                                                setIntegrationReplacement(
                                                                    missingIntegrationId,
                                                                    e.target
                                                                        .value
                                                                );
                                                            }}
                                                        >
                                                            {integrations[
                                                                missingIntegration
                                                            ].map(
                                                                (
                                                                    integration
                                                                ) => (
                                                                    <MenuItem
                                                                        key={
                                                                            integration.id
                                                                        }
                                                                        value={
                                                                            integration.id
                                                                        }
                                                                    >
                                                                        {
                                                                            integrationTypes[
                                                                                integration
                                                                                    .type_
                                                                            ]
                                                                                .display_name
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                        </Select>
                                                    )}
                                            </div>
                                        </div>
                                    );
                                }
                            )}
                        </div>
                    )}
                {Object.keys(missingPolicyPools).length > 0 && (
                    <div className="form-box">
                        <h2 className="m25 color-primary">
                            Map Policy Number Pools
                        </h2>
                        {Object.keys(missingPolicyPools).map(
                            (missingPolicyPool) => {
                                return (
                                    <div
                                        className="cols"
                                        key={missingPolicyPool}
                                    >
                                        <div className="c50 div-as-p">
                                            <label htmlFor={missingPolicyPool}>
                                                {missingPolicyPool} used in{" "}
                                                {missingPolicyPools[
                                                    missingPolicyPool
                                                ].join(", ")}
                                            </label>
                                        </div>
                                        <div className="c50">
                                            {(!numberPools ||
                                                numberPools.length === 0) && (
                                                <Link
                                                    to={urlJoin(
                                                        "/",
                                                        BUILDER_PATH,
                                                        "system",
                                                        "policy-number-pools"
                                                    )}
                                                >
                                                    Add Policy Number Pool
                                                </Link>
                                            )}
                                            {numberPools &&
                                                numberPools?.length > 0 && (
                                                    <Select
                                                        id={missingPolicyPool}
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                        className="styled-select-menu"
                                                        name={missingPolicyPool}
                                                        value={
                                                            (policyPoolreplacements &&
                                                                policyPoolreplacements[
                                                                    missingPolicyPool
                                                                ]) ||
                                                            ""
                                                        }
                                                        onChange={(e) => {
                                                            setPolicyPoolReplacement(
                                                                missingPolicyPool,
                                                                e.target.value
                                                            );
                                                        }}
                                                    >
                                                        {numberPools?.map(
                                                            (numberPool) => (
                                                                <MenuItem
                                                                    key={`${missingPolicyPool}-${numberPool.id}`}
                                                                    value={
                                                                        numberPool.id
                                                                    }
                                                                >
                                                                    {
                                                                        numberPool.name
                                                                    }
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Select>
                                                )}
                                        </div>
                                    </div>
                                );
                            }
                        )}
                    </div>
                )}
                {noReplacements && <div>Product is ready to be imported</div>}
                <footer className={classNames("module-fixed compact")}>
                    <p className="link-btn">
                        <button
                            onClick={() => submitImport()}
                            className={classNames("submit-form submit-btn")}
                            style={{ marginRight: "24px" }}
                        >
                            <i className="icon-check-circle-outline" /> Import
                            <span className="mobile-hide"> Product</span>
                        </button>
                    </p>
                </footer>
            </main>
        </div>
    );
};

const ProductImportPage: FC = () => (
    <Page>
        <Main />
        <ProdutImportExtraPane />
    </Page>
);

export default ProductImportPage;
