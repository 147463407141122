import {
    QuoteCodeAndValue,
    Document,
    Insured,
    ProductVersion,
    Quote,
    Submission,
    ApplicationDatapointV1,
    QuoteStatus,
    PolicyViewBody,
} from "@joshuins/insurance";
import { User, UserAccountRole } from "@joshuins/auth";
import {
    FC,
    PropsWithChildren,
    createContext,
    useCallback,
    useContext,
} from "react";
import { useAuthUser } from "react-auth-kit";
import { useApi } from "./ApiProvider";
import { unpaginate, unpaginateWithMultiParams } from "components/sdk";
import { isBinder } from "utils/policies";
import compact from "lodash/compact";
import sortBy from "lodash/sortBy";
import { asyncMap } from "modern-async";
import SubmissionData, { SubmissionDataItem } from "utils/submission-data";
import { ParsedQuote } from "utils/quote";

interface ComplexApiDataProviderInterface {
    userHasAvailableProducts: () => Promise<boolean>;
    productIdsForCurrentUser: () => Promise<number[] | undefined>;
    productVersionsForCurrentUser: () => Promise<{
        published: ProductVersion[];
        draft: ProductVersion[] | undefined;
    }>;
    insuranceProcess: (quoteIdOrPolicyId: number) => Promise<
        | {
              insuranceProcessPromise: Promise<
                  | {
                        policyView: PolicyViewBody | undefined;
                        quote: Quote;
                        quoteCodesAndValues: QuoteCodeAndValue[];
                        submission: Submission;
                        insured: Insured | undefined;
                        productVersion: ProductVersion;
                        submissionUser: User;
                        quoteUser: User | undefined;
                        quoteVariations: Quote[];
                        binderVariations: Quote[];
                        parsedSubmissionData: SubmissionData;
                        documents: Document[];
                        datapoints: ApplicationDatapointV1[];
                        parsedQuote: ParsedQuote;
                    }
                  | undefined
              >;
              isReadyImmediatelyPromise: Promise<boolean>;
              cancelRetry: () => void;
          }
        | {
              insuranceProcessPromise: undefined;
              isReadyImmediatelyPromise: undefined;
              cancelRetry: undefined;
          }
    >;
}

const ApiContext = createContext<ComplexApiDataProviderInterface | undefined>(
    undefined
);

const useComplexApiData = () => {
    const context = useContext(ApiContext);

    if (context === undefined) {
        throw Error(
            "useComplexApiData must be used inside a ComplexApiDataProvider context"
        );
    }

    return context;
};

const ComplexApiDataProvider: FC<PropsWithChildren> = ({ children }) => {
    const { sdkSystem, sdkInsurance } = useApi();
    const authUser = useAuthUser();
    const currentUser = authUser() as User;

    // Returns undefined to mean all products unfiltered. This is for efficiency
    // purposes, so that we don't retrieve all products unless we need to
    const productIdsForCurrentUser = useCallback(async () => {
        if (currentUser) {
            switch (currentUser.role) {
                case UserAccountRole.Underwriter: {
                    const productUsers = await unpaginate(
                        sdkInsurance.allProducts,
                        {}
                    );
                    return productUsers.map((productUser) => productUser.id);
                }
                case UserAccountRole.Admin: {
                    return undefined;
                }
            }
        }
    }, [currentUser, sdkInsurance.allProducts]);

    const userHasAvailableProducts = useCallback(async () => {
        if (!currentUser) {
            return false;
        }
        const availableProducts = (
            currentUser.role === UserAccountRole.Admin
                ? await sdkInsurance.allProductVersions({
                      is_archived: false,
                      _per_page: 1,
                  })
                : await sdkInsurance.allProductVersions({
                      is_published: true,
                      is_archived: false,
                      _per_page: 1,
                  })
        ).total_items;
        return availableProducts > 0;
    }, [currentUser, sdkInsurance]);

    const productVersionsForCurrentUser = useCallback(async () => {
        const products = await unpaginate(sdkInsurance.allProducts, {});
        const publishedProducts = compact(
            products.map((product) => product.published)
        );

        // take only PV with effective_date the are less/equal than today
        const liveProductVersions = await asyncMap(
            publishedProducts,
            async (productView) =>
                await sdkInsurance.getProductVersion({
                    id: productView.id,
                }),
            Number.POSITIVE_INFINITY
        );

        let draftProductVersions: ProductVersion[] | undefined;
        if (currentUser.role === "Admin") {
            const draftProductVersions_ = compact(
                products.map((product) => product.latest_draft)
            );
            draftProductVersions = await asyncMap(
                draftProductVersions_,
                async (productView) =>
                    await sdkInsurance.getProductVersion({
                        id: productView.id,
                    }),
                Number.POSITIVE_INFINITY
            );
        }

        return {
            published: liveProductVersions,
            draft: draftProductVersions,
        };
    }, [currentUser, sdkInsurance]);

    const insuranceProcessInner = useCallback(
        async ({
            quotePromise,
        }: {
            quoteId: number | undefined;
            quotePromise: Promise<Quote | undefined>;
        }) => {
            const quote = await quotePromise;

            if (!quote) {
                return undefined;
            }
            const submission_ = await sdkInsurance.getSubmission({
                id: quote.submission_id,
            });
            let policyView_ = undefined;
            if (submission_.policy_id) {
                policyView_ = await sdkInsurance.getPolicy({
                    id: submission_.policy_id,
                });
            }
            const [
                {
                    policyView,
                    submission,
                    insured,
                    productVersion,
                    submissionUser,
                    datapoints,
                },
                quoteCodesAndValues,
                quoteVariations,
                quoteUser,
                parsedSubmissionData,
                documents,
            ] = await Promise.all([
                (async () => {
                    const [insured_, productVersion_, submissionUser_] =
                        await Promise.all([
                            policyView_ && policyView_.insured_id
                                ? await sdkInsurance.getInsured({
                                      id: policyView_.insured_id,
                                  })
                                : undefined,
                            sdkInsurance.getProductVersion({
                                id: submission_.product_version_id,
                            }),
                            currentUser.role == UserAccountRole.Admin ||
                            currentUser.role == UserAccountRole.Underwriter
                                ? sdkSystem.getUser({
                                      id: submission_.user_id,
                                  })
                                : currentUser,
                        ]);
                    return {
                        policyView: policyView_,
                        submission: submission_,
                        insured: insured_,
                        productVersion: productVersion_,
                        submissionUser: submissionUser_,
                        datapoints: compact(
                            productVersion_.schema.spec.sections.flatMap(
                                (section) =>
                                    section.items.flatMap((item) => {
                                        if ("Datapoint" in item)
                                            return item.Datapoint;
                                    })
                            )
                        ),
                    };
                })(),
                // broker have access to quote data if the quote is in status
                // StoreEdit or any Published status
                currentUser.role == UserAccountRole.Broker &&
                quote.status !== QuoteStatus.QuoteStoreEdit &&
                quote.status !== QuoteStatus.QuotePublished &&
                quote.status !== QuoteStatus.BinderPublished &&
                quote.status !== QuoteStatus.CoverageActive
                    ? []
                    : sdkInsurance.getQuoteData({
                          id: quote.id,
                      }),
                unpaginate(sdkInsurance.allQuotes, {
                    submission_id: quote.submission_id,
                }).then((quotes) => sortBy(quotes, "created_at")),
                currentUser.role == UserAccountRole.Admin ||
                currentUser.role == UserAccountRole.Underwriter
                    ? sdkSystem.getUser({
                          id: submission_.user_id,
                      })
                    : undefined,
                (async () => {
                    const submissionCodesValues =
                        await sdkInsurance.getSubmissionData({
                            id: quote.submission_id,
                        });
                    const assetsCodesValues = await sdkInsurance.getAssetData({
                        id: quote.submission_id,
                    });
                    const submissionDataItems: SubmissionDataItem[] =
                        submissionCodesValues.map((item) => ({
                            asset_idx: 0,
                            ...item,
                        }));
                    const assetsDataItems: SubmissionDataItem[] =
                        assetsCodesValues.data.map((item) => ({
                            ...item,
                        }));
                    return new SubmissionData(
                        submissionDataItems.concat(assetsDataItems)
                    );
                })(),
                unpaginateWithMultiParams(sdkInsurance.allDocuments, {
                    quote_id: quote.id,
                }),
            ]);

            const binderVariations = quoteVariations.filter((quote) =>
                isBinder(quote)
            );

            const parsedQuote = new ParsedQuote(
                quoteCodesAndValues,
                productVersion.schema.spec
            );

            return {
                policyView,
                quote,
                quoteCodesAndValues,
                submission,
                insured,
                productVersion,
                submissionUser,
                quoteUser,
                quoteVariations,
                binderVariations,
                parsedSubmissionData,
                documents,
                datapoints,
                parsedQuote,
            };
        },
        [currentUser, sdkInsurance, sdkSystem]
    );

    const insuranceProcess = useCallback(
        async (quoteId: number) => {
            let quotePromise: Promise<Quote | undefined> | undefined =
                undefined;
            let isQuoteReadyImmediatelyPromise: Promise<boolean> | undefined =
                undefined;
            let cancelRetry: (() => void) | undefined = undefined;
            [quotePromise, cancelRetry, isQuoteReadyImmediatelyPromise] =
                sdkInsurance.getQuoteWhenReady(quoteId);

            if (
                !cancelRetry ||
                !quotePromise ||
                !isQuoteReadyImmediatelyPromise
            ) {
                return {
                    insuranceProcessPromise: undefined,
                    readyImmediately: undefined,
                    cancelRetry: undefined,
                };
            }

            return {
                insuranceProcessPromise: insuranceProcessInner({
                    quotePromise,
                    quoteId,
                }),
                isReadyImmediatelyPromise: isQuoteReadyImmediatelyPromise,
                cancelRetry,
            };
        },
        [insuranceProcessInner, sdkInsurance]
    );

    return (
        <ApiContext.Provider
            value={{
                productIdsForCurrentUser,
                productVersionsForCurrentUser,
                insuranceProcess,
                userHasAvailableProducts,
            }}
        >
            {children}
        </ApiContext.Provider>
    );
};

export { useComplexApiData, ComplexApiDataProvider };
export type { ComplexApiDataProviderInterface };
