import { FC } from "react";
import ExtraPane from "components/extra-panes/ExtraPane";
import { MainPane } from "components/MainPane";
import { Page, TabInterface, usePage } from "components/Page";
import {
    AddRulePopup,
    AddTargetPopup,
    DeleteRulePopup,
    DeleteTargetPopup,
    DataExportTargetsTab,
} from "./components/data-export/DataExportTargetsTab";
import {
    AddKeyPopup,
    DeleteKeyPopup,
    DataExportKeysTab,
} from "./components/data-export/DataExportKeysTab";
import { DataExportProvider } from "./components/data-export/DataExportProvider";
import DataExportDumpsTab from "./components/data-export/DataExportDumpsTab";

const DataExportExtraPane: FC = () => (
    <ExtraPane>
        <ul className="list-icon b">
            <li>
                <i className="icon-download" /> Export Data{" "}
                <span>With Joshu, it&apos;s easy to export...</span>
            </li>
        </ul>
    </ExtraPane>
);

const Main: FC = () => {
    const { activeTab } = usePage();
    const activeTab_ = activeTab();

    let tabContent: JSX.Element | undefined;
    switch (activeTab_?.path) {
        case "targets":
            tabContent = <DataExportTargetsTab />;
            break;
        case "keys":
            tabContent = <DataExportKeysTab />;
            break;
        case "files":
            tabContent = <DataExportDumpsTab />;
            break;
    }

    if (!tabContent) {
        return <></>;
    }

    return (
        <MainPane title="Export Targets">
            <div className="module-tabs-content">{tabContent}</div>
        </MainPane>
    );
};

const DataExportPage: FC<{ tabs?: TabInterface[] }> = ({ tabs }) => (
    <Page tabs={tabs}>
        <DataExportProvider>
            <Main />
            <DataExportExtraPane />
            <AddTargetPopup />
            <DeleteTargetPopup />
            <AddRulePopup />
            <DeleteRulePopup />
            <AddKeyPopup />
            <DeleteKeyPopup />
        </DataExportProvider>
    </Page>
);

export default DataExportPage;
