import { Quote, QuoteStatus } from "@joshuins/insurance";
import { includes } from "./array";

const BINDER_ACTIVE_STATUSES = [
    QuoteStatus.BinderPending,
    QuoteStatus.BinderPublished,
] as const;

const BINDER_STATUSES = [
    QuoteStatus.BinderPending,
    QuoteStatus.BinderPublished,
    QuoteStatus.BinderDeclined,
] as const;

const POLICIES_STATUSES = [
    QuoteStatus.CoverageActive,
    QuoteStatus.PolicyCanceled,
    QuoteStatus.PolicyExpired,
    QuoteStatus.CoveragePending,
] as const;

const isBinder = (quote: Quote) => includes(BINDER_STATUSES, quote.status);

const isBinderActive = (quote: Quote) =>
    includes(BINDER_ACTIVE_STATUSES, quote.status);

const isPolicy = (quote: Quote) => includes(POLICIES_STATUSES, quote.status);

const isQuote = (quote: Quote) => !isPolicy(quote) && !isBinder(quote);

export {
    isBinder,
    isBinderActive,
    isPolicy,
    isQuote,
    BINDER_STATUSES,
    POLICIES_STATUSES,
};
