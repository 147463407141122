import {
    DocumentSchemaV1,
    DocumentPartV1,
    FileDesignation,
} from "@joshuins/builder";
import { QuoteCodeAndValue } from "@joshuins/insurance";
import { MenuItem, ListItemIcon } from "@mui/material";
import { FileUpload } from "components/FileUpload";
import { usePage } from "components/Page";
import { createFormPopup } from "components/Popup";
import { useApi } from "contexts/ApiProvider";
import { usePopup } from "contexts/PopupProvider";
import { LexoRank } from "lexorank";
import { FC } from "react";
import { wrapAsJoValue } from "utils/jo-types-and-values";
import sortByLexorank from "utils/lexorank";
import { randomString } from "utils/string";
import { z } from "zod";
import { useInsuranceProcess } from "../InsuranceProcessProvider";
import { Select } from "components/ReactHookFormUncontrolledComponents";
import { MANUAL_DOC_PREFIX } from "./util";

interface UploadManuscriptPartDataInterface {
    documentSchema: DocumentSchemaV1;
    parts: DocumentPartV1[];
}

const UploadManuscriptPartSchema = z.object({
    display_name: z.string().min(1, "This field is required"),
    file: z
        .instanceof(File)
        .refine(
            (file) => file?.type === "application/pdf",
            "Only .pdf format is supported."
        ),
    after_code: z.string().min(1, "This field is required"),
});

const {
    FormPopup: UploadManuscriptPartFormPopup,
    useFormReturnRef: uploadManuscriptFormReturnRef,
} = createFormPopup(UploadManuscriptPartSchema);

type UploadManuscriptPartType = z.infer<typeof UploadManuscriptPartSchema>;

const UploadManuscriptPartPopup: FC = () => {
    const { tryCatchAndRaiseError } = usePage();
    const { popupData } = usePopup();
    const { sdkBuilder } = useApi();

    const {
        insuranceProcessDispatch,
        itemGetters: { getInsuranceProcess },
    } = useInsuranceProcess();
    const { quoteCodesAndValues } = getInsuranceProcess();

    const { formReturnRefCallback } = uploadManuscriptFormReturnRef();

    const popupData_ =
        popupData as unknown as UploadManuscriptPartDataInterface;

    const documentSchema = popupData_?.documentSchema;
    const parts = popupData_?.parts;

    const onSubmit = async (data: UploadManuscriptPartType) => {
        tryCatchAndRaiseError(async () => {
            if (!data.file) {
                return;
            }
            const fileId = await sdkBuilder.uploadFile(
                data.file,
                FileDesignation.ManualDocument
            );
            const newPartCode = randomString(8);
            let documentSchemaPartialCode = documentSchema.code.split(".")[2];
            // if its not a manual document
            if (!documentSchemaPartialCode) {
                documentSchemaPartialCode = documentSchema.code.split(".")[1];
            }
            const partsCodes = parts.map((item) => item.code);
            const partsQuoteCodesAndValues = quoteCodesAndValues.filter(
                (item) =>
                    item.code.split(".")[0] === "doc" &&
                    partsCodes.includes(item.code)
            );
            const sortedByRankes = sortByLexorank([
                ...partsQuoteCodesAndValues,
            ]);

            let afterRank: string | undefined = undefined;
            let beforeRank: string | undefined = undefined;
            let newRank: LexoRank | undefined = undefined;
            sortedByRankes.map((item, index) => {
                if (item.code === data.after_code) {
                    afterRank = sortedByRankes[index].rank;
                    beforeRank =
                        sortedByRankes[index + 1] &&
                        sortedByRankes[index + 1].rank;
                }
            });
            if (afterRank && beforeRank) {
                // if its in the middle
                newRank = LexoRank.parse(beforeRank).between(
                    LexoRank.parse(afterRank)
                );
            } else if (afterRank && !beforeRank) {
                // if its the last element
                newRank = LexoRank.parse(afterRank).genNext();
            } else {
                // cannot happen, return;
                return;
            }

            const updatedQuoteDocs: QuoteCodeAndValue[] = [
                {
                    code: `${MANUAL_DOC_PREFIX}.${documentSchemaPartialCode}.part.${newPartCode}`,
                    user_value: wrapAsJoValue({ Boolean: true }),
                    rater_value: wrapAsJoValue({ Boolean: true }),
                    rank: newRank.toString(),
                },
                {
                    code: `${MANUAL_DOC_PREFIX}.${documentSchemaPartialCode}.part.${newPartCode}.file_id`,
                    user_value: wrapAsJoValue({ File: fileId }),
                    rater_value: wrapAsJoValue({ File: fileId }),
                    rank: newRank.genNext().toString(),
                },
                {
                    code: `${MANUAL_DOC_PREFIX}.${documentSchemaPartialCode}.part.${newPartCode}.name`,
                    user_value: wrapAsJoValue({ Text: data.display_name }),
                    rater_value: wrapAsJoValue({ Text: data.display_name }),
                    rank: newRank.genNext().genNext().toString(),
                },
            ];

            insuranceProcessDispatch({
                action: "SetDirtyFields",
                dirtyFields: updatedQuoteDocs,
            });
            insuranceProcessDispatch({
                action: "UpdateQuoteCodesAndValues",
                quoteCodesAndValues: updatedQuoteDocs,
            });
        });
    };
    if (!documentSchema) {
        return;
    }

    return (
        <UploadManuscriptPartFormPopup
            name="upload-manu-part"
            onSubmit={onSubmit}
            submitText="Upload Manuscript"
            mobileSubmitText="Upload"
            defaultValues={{
                display_name: "",
                file: undefined,
                after_code: "",
            }}
            formReturnRefCallback={formReturnRefCallback}
        >
            {({ register, control, formState: { errors } }) => (
                <>
                    <header>
                        <h2>Upload Manuscript</h2>
                    </header>
                    <div className={"div-as-p"}>
                        <label htmlFor="dsply">Display Name</label>
                        <input
                            type="text"
                            id="dsply"
                            {...register("display_name")}
                        />
                        {errors.display_name && (
                            <label
                                id="dsply-error"
                                className="error"
                                htmlFor="dsply"
                            >
                                {errors.display_name.message}
                            </label>
                        )}
                    </div>
                    <div className={"div-as-p"}>
                        <FileUpload
                            id="mnscrptprtfl"
                            name="file"
                            control={control}
                            fileDesignation={FileDesignation.ManualDocument}
                            error={errors.file?.message}
                            Label={
                                <label htmlFor="mnscrptprtfl">
                                    File
                                    <span className="text-right">
                                        10MB Limit
                                    </span>
                                </label>
                            }
                        />
                    </div>
                    <div className={"div-as-p"}>
                        <label htmlFor="after_code">Insert After</label>
                        <Select
                            id={"after_code"}
                            name={"after_code"}
                            control={control}
                            fullWidth
                        >
                            {parts.map((part) => (
                                <MenuItem key={part.code} value={part.code}>
                                    <ListItemIcon>
                                        <i className="icon-document" />
                                    </ListItemIcon>
                                    {part.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                </>
            )}
        </UploadManuscriptPartFormPopup>
    );
};

export { UploadManuscriptPartPopup };
