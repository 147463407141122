import { zodResolver } from "@hookform/resolvers/zod";
import noop from "lodash/noop";
import isEqual from "lodash/isEqual";
import {
    BaseSyntheticEvent,
    FC,
    KeyboardEventHandler,
    PropsWithChildren,
    ReactNode,
    RefCallback,
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";
import {
    DefaultValues,
    FieldValues,
    FormState,
    SubmitHandler,
    UseFormReturn,
    useForm,
} from "react-hook-form";
import { z } from "zod";

const FieldSetContext = createContext<{ disabled: boolean } | undefined>(
    undefined
);
const useFieldSet = () => {
    const context = useContext(FieldSetContext);
    return context;
};

const FieldSet: FC<PropsWithChildren<{ disabled?: boolean }>> = ({
    disabled = false,
    children,
}) => (
    <fieldset disabled={disabled}>
        <FieldSetContext.Provider value={{ disabled }}>
            {children}
        </FieldSetContext.Provider>
    </fieldset>
);

const EMPTY_FORM_RETURN = {
    watch: undefined,
    getValues: undefined,
    getFieldState: undefined,
    setError: undefined,
    clearErrors: undefined,
    setValue: undefined,
    trigger: undefined,
    resetField: undefined,
    reset: undefined,
    handleSubmit: undefined,
    unregister: undefined,
    control: undefined,
    register: undefined,
    setFocus: undefined,
    formState: {
        isDirty: undefined,
        isValidating: undefined,
        isLoading: undefined,
        isSubmitted: undefined,
        isSubmitting: undefined,
        isSubmitSuccessful: undefined,
        isValid: undefined,
        submitCount: undefined,
        dirtyFields: undefined,
        touchedFields: undefined,
        errors: undefined,
        defaultValues: undefined,
    },
} as const;

const isEmptyFormState = <T extends FieldValues>(
    formState: typeof EMPTY_FORM_RETURN.formState | FormState<T>
): formState is typeof EMPTY_FORM_RETURN.formState => {
    return isEqual(formState, EMPTY_FORM_RETURN.formState);
};

interface FormProps<T extends FieldValues> {
    className?: string;
    onSubmit?: SubmitHandler<T>;
    onBlur?: SubmitHandler<T>;
    defaultValues: DefaultValues<T>;
    formReturnRefCallback?: RefCallback<UseFormReturn<T> | undefined>;
    disabled?: boolean;
    children?: ReactNode | ((props: UseFormReturn<T>) => ReactNode);
    onKeyDown?: KeyboardEventHandler<HTMLFormElement> | undefined;
}

interface UseFormReturnRef<T extends FieldValues> {
    formReturn: UseFormReturn<T> | typeof EMPTY_FORM_RETURN;
    formReturnRefCallback?: (formReturn_: UseFormReturn<T>) => void;
}

const createForm = <T extends FieldValues>(
    formSchema: z.Schema<T>
    //{ mode = "onSubmit" }: { mode?: Mode } = {}
) => {
    const FormContext = createContext<UseFormReturn<T> | undefined>(undefined);

    // This hook is for getting the FormReturn from inside the Form's context
    const useFormReturn = () => {
        const context = useContext<UseFormReturn<T>>(
            FormContext as unknown as React.Context<UseFormReturn<T>>
        );
        if (!context) {
            throw new Error("useFormReturn must be used within a Form");
        }
        return context;
    };

    // This hook is for getting the FormReturn from outside (or above) the Form's
    // context
    const useFormReturnRef = () => {
        const [formReturnObjs, setFormReturnObjs] = useState<
            UseFormReturnRef<T>
        >({ formReturn: EMPTY_FORM_RETURN });

        const formReturnRefCallback = useCallback(
            (formReturn: UseFormReturn<T>) => {
                setFormReturnObjs({
                    formReturn,
                    formReturnRefCallback,
                });
            },
            [setFormReturnObjs]
        );

        useEffect(() => {
            setFormReturnObjs({
                formReturnRefCallback,
                formReturn: EMPTY_FORM_RETURN,
            });
        }, [formReturnRefCallback]);

        return formReturnObjs;
    };

    // Because RHF's formState is some sort of proxy voodoo magic, making it impossible
    // to monitor state based on it, we use this function to turn it into something sane
    // that can be worked with when comparing previous states.
    const materializeFormState: (formState: FormState<T>) => FormState<T> = (
        formState
    ) => {
        return {
            isDirty: formState.isDirty.valueOf(),
            isLoading: formState.isLoading.valueOf(),
            isSubmitted: formState.isSubmitted.valueOf(),
            isSubmitSuccessful: formState.isSubmitSuccessful.valueOf(),
            isSubmitting: formState.isSubmitting.valueOf(),
            isValidating: formState.isValidating.valueOf(),
            isValid: formState.isValid.valueOf(),
            disabled: formState.disabled,
            submitCount: formState.submitCount.valueOf(),
            defaultValues: formState.defaultValues,
            dirtyFields: { ...formState.dirtyFields },
            touchedFields: { ...formState.touchedFields },
            validatingFields: { ...formState.validatingFields },
            errors: { ...formState.errors },
        };
    };

    // This hook returns a new random string if formState changes
    const useUniqueIDForFormStateChanged = (
        formState: FormState<T> | typeof EMPTY_FORM_RETURN.formState
    ) => {
        const [oldFormState, setOldFormState] = useState<{
            formState: FormState<T> | typeof EMPTY_FORM_RETURN.formState;
            randomString: string;
        }>({
            randomString: crypto.randomUUID(),
            formState,
        });

        useEffect(() => {
            const materializedFormState = isEmptyFormState(formState)
                ? EMPTY_FORM_RETURN.formState
                : materializeFormState(formState);
            if (!isEqual(oldFormState.formState, materializedFormState)) {
                setOldFormState({
                    formState: materializedFormState,
                    randomString: crypto.randomUUID(),
                });
            }
        }, [formState, oldFormState.formState]);

        return oldFormState.randomString;
    };

    const Form = ({
        className,
        onSubmit,
        onBlur,
        defaultValues,
        formReturnRefCallback,
        disabled = false,
        children,
        onKeyDown,
    }: FormProps<T>) => {
        const formReturn = useForm<z.infer<typeof formSchema>>({
            resolver: zodResolver(formSchema),
            defaultValues,
        });
        const uniqueIdForFormStateChanged = useUniqueIDForFormStateChanged(
            formReturn.formState
        );

        const { handleSubmit } = formReturn;

        const onSubmitOrBlur = useCallback(
            async (data: T, event?: BaseSyntheticEvent) => {
                if (event) {
                    event.preventDefault();
                }
                await (onSubmit ?? onBlur ?? noop)(data, event);
            },
            [onSubmit, onBlur]
        );

        useEffect(() => {
            if (!formReturnRefCallback) {
                return;
            }
            formReturnRefCallback(formReturn);
        }, [formReturn, formReturnRefCallback, uniqueIdForFormStateChanged]);

        const handleSubmitProp = () => {
            if (onSubmit) {
                return {
                    onSubmit: handleSubmit((data, event) => {
                        return onSubmitOrBlur(data, event);
                    }),
                };
            } else if (onBlur) {
                return {
                    onBlur: handleSubmit((data, event) => {
                        return onSubmitOrBlur(data, event);
                    }),
                };
            } else {
                return {};
            }
        };

        return (
            <form
                {...handleSubmitProp()}
                className={className}
                onKeyDown={onKeyDown}
            >
                <FormContext.Provider value={formReturn}>
                    <FieldSet disabled={disabled}>
                        {typeof children === "function" ? (
                            children(formReturn)
                        ) : (
                            <>{children}</>
                        )}
                    </FieldSet>
                </FormContext.Provider>
            </form>
        );
    };

    return {
        Form,
        useFormReturn,
        useFormReturnRef,
        useUniqueIDForFormStateChanged,
    };
};

export { useFieldSet, FieldSet, createForm };
export type { FormProps };
