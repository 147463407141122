import { usePage } from "components/Page";
import { FC, useCallback } from "react";
import { z } from "zod";
import { createFormPopup } from "components/Popup";
import { User } from "@joshuins/system";
import { useApi } from "contexts/ApiProvider";
import { Submission } from "@joshuins/insurance";
import { useAuthUser } from "react-auth-kit";
import { useNavigate } from "components/DevAwareRoutingLink";
import { useBranding } from "contexts/BrandingProvider";
import urlJoin from "url-join";
import { FileUpload } from "components/FileUpload";
import { hideLoader, showLoader } from "paul/native-dom-manipulation";
import { isStoreUser } from "pages/components/util";

const IMPORT_SUBMISSION_POPUP = "import-submission" as const;

const importSubmissionSchema = z.object({
    submission_file: z.instanceof(File, { message: "No file selected" }),
});

type ImportSubmissionFlow = z.infer<typeof importSubmissionSchema>;

const {
    FormPopup: ImportSubmissionFormPopup,
    useFormReturnRef: useImportSubmissionFormReturnRef,
} = createFormPopup(importSubmissionSchema);

const ImportSubmissionPopup: FC = () => {
    const authUser = useAuthUser();
    const currentUser = authUser() as User;
    const { generateUrl } = useBranding();
    const navigate = useNavigate();
    const { tryCatchAndRaiseError } = usePage();
    const { formReturnRefCallback } = useImportSubmissionFormReturnRef();
    const { sdkBuilder, sdkInsurance } = useApi();
    const { element } = usePage();
    const submission = element as unknown as Submission | undefined;

    const onSubmit = useCallback(
        async (data: ImportSubmissionFlow) => {
            if (!submission || !data.submission_file) {
                return;
            }
            tryCatchAndRaiseError(async () => {
                const file_id = await sdkBuilder.uploadFile(
                    data.submission_file,
                    "QuoteRater"
                );
                showLoader("Importing...");
                await sdkInsurance.importSubmission({
                    ImportSubmission: {
                        submission_id: submission.id,
                        file_id: file_id,
                    },
                });

                hideLoader();
                navigate(
                    generateUrl(
                        urlJoin("/", "submissions", submission.id.toString())
                    )
                );
            }, hideLoader);
        },
        [
            generateUrl,
            navigate,
            sdkBuilder,
            sdkInsurance,
            submission,
            tryCatchAndRaiseError,
        ]
    );

    if (!submission || isStoreUser(currentUser)) {
        return;
    }

    return (
        <ImportSubmissionFormPopup
            name={IMPORT_SUBMISSION_POPUP}
            defaultValues={{
                submission_file: undefined,
            }}
            submitText="Import"
            formReturnRefCallback={formReturnRefCallback}
            onSubmit={onSubmit}
        >
            {({ control, formState: { errors } }) => (
                <>
                    <header>
                        <h2>Import Submission</h2>
                    </header>
                    <p>
                        Upload a Joshu submission file. Relevant answers will be
                        imported.
                    </p>
                    <FileUpload
                        id="faj"
                        name="submission_file"
                        control={control}
                        fileDesignation={"QuoteRater"}
                        error={errors.submission_file?.message}
                        Label={<span className="label">Submission File</span>}
                    />
                </>
            )}
        </ImportSubmissionFormPopup>
    );
};

export { ImportSubmissionPopup, IMPORT_SUBMISSION_POPUP };
