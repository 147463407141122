import { QuoteDocumentValues } from "@joshuins/builder";
import { Popup } from "components/Popup";
import { useApi } from "contexts/ApiProvider";
import { usePopup } from "contexts/PopupProvider";
import { FC, useState, useEffect } from "react";
import { useInsuranceProcess } from "../InsuranceProcessProvider";

const DocumentInputsPopup: FC = () => {
    const apiProviderContext = useApi();
    const { sdkInsurance } = apiProviderContext;
    const { isPopupOpen } = usePopup();
    const {
        itemGetters: { getInsuranceProcess },
    } = useInsuranceProcess();
    const { quote } = getInsuranceProcess();
    const [documentInputs, setDocumentInputs] = useState<QuoteDocumentValues>();

    useEffect(() => {
        const getDocumentInputs = async () => {
            if (!isPopupOpen("quote-document-inputs")) {
                return;
            }
            const quoteDocumentInputs = await sdkInsurance.quoteDocumentInputs({
                id: quote.id,
            });
            setDocumentInputs(quoteDocumentInputs);
        };
        getDocumentInputs();
    }, [quote, isPopupOpen, sdkInsurance]);

    return (
        <Popup name="quote-document-inputs">
            <pre id="document-inputs-json" style={{ whiteSpace: "pre-wrap" }}>
                {JSON.stringify(documentInputs, undefined, 2)}
            </pre>
        </Popup>
    );
};

export { DocumentInputsPopup };
