import { useBranding } from "contexts/BrandingProvider";
import { showLoader } from "paul/native-dom-manipulation";
import { FC, SyntheticEvent } from "react";

const SsoLoginButton: FC<{ oidcSlug: string; displayName?: string }> = ({
    oidcSlug,
    displayName,
}) => {
    const { generateOidcSsoUrl } = useBranding();

    const getButtonConfig = (oidcSlug: string) => {
        if (oidcSlug === "google") {
            return {
                icon: "google",
                buttonText: `Continue With ${displayName ?? "Google"}`,
            };
        } else {
            return {
                icon: "more-openid",
                buttonText: `Continue With ${displayName || "OpenID Connect"}`,
            };
        }
    };

    const { icon, buttonText } = getButtonConfig(oidcSlug);
    const oidcSsoUrl = generateOidcSsoUrl(oidcSlug);

    return (
        <p className="link-btn" style={{ marginTop: 0 }}>
            <a
                href={oidcSsoUrl}
                onClick={(e: SyntheticEvent) => {
                    e.preventDefault();
                    showLoader();
                    window.location.href = oidcSsoUrl;
                }}
                className="inv size-18"
            >
                <i aria-hidden="true" className={`icon-${icon}`} />
                {buttonText}
            </a>
        </p>
    );
};

export default SsoLoginButton;
