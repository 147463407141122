import { usePage } from "components/Page";
import { FC, useCallback } from "react";
import { useApi } from "contexts/ApiProvider";
import { SectionV1, Submission } from "@joshuins/insurance";
import { usePopup } from "contexts/PopupProvider";
import { ConfirmationPopup } from "components/Popup";
import { useApplication } from "./ApplicationProvider";

const DeleteAssetPopup: FC = () => {
    const { sdkInsurance } = useApi();
    const { tryCatchAndRaiseError } = usePage();
    const { popupData } = usePopup();
    const { element } = usePage();
    const { refreshSubmissionData } = useApplication();
    const submission = element as unknown as Submission | undefined;
    const section = popupData?.section as SectionV1 | undefined;
    const assetIndex = popupData?.assetIndex as number | undefined;

    const onSubmit = useCallback(async () => {
        if (!section || !submission || assetIndex === undefined) {
            return;
        }

        tryCatchAndRaiseError(async () => {
            await sdkInsurance.deleteAssetData({
                id: submission.id,
                DeleteAssetData: {
                    application_data: [
                        {
                            section_code: section.code,
                            asset_idx: assetIndex,
                        },
                    ],
                },
            });
        });
        await refreshSubmissionData();
    }, [
        assetIndex,
        refreshSubmissionData,
        sdkInsurance,
        section,
        submission,
        tryCatchAndRaiseError,
    ]);

    return (
        <ConfirmationPopup
            name="delete-asset"
            onSubmit={onSubmit}
            submitText="Delete Item"
            mobileSubmitText="Delete"
        >
            <header>
                <h2>Are you sure?</h2>
                <p className="size-14">
                    This item will be removed and cannot be undone.
                </p>
            </header>
        </ConfirmationPopup>
    );
};

export default DeleteAssetPopup;
