import { FC, useCallback } from "react";
import { AlertCategory, usePage } from "components/Page";
import { ConfirmationPopup } from "components/Popup";
import { usePopup } from "contexts/PopupProvider";
import { useIntegrations } from "../IntegrationsProvider";
import { ProductIntegrationV1 } from "@joshuins/builder";
import { Integration } from "@joshuins/system";

const DELETE_PRODUCT_INTEGRATION = "delete-product-integration" as const;

const DeleteProductIntegrationPopup: FC = () => {
    const { popupData } = usePopup();
    const { addAlertMessages } = usePage();
    const {
        mutators: { deleteProductIntegration },
    } = useIntegrations();
    const productIntegration = popupData?.productIntegration as
        | ProductIntegrationV1
        | undefined;
    const integration = popupData?.integration as Integration | undefined;

    const onSubmit = useCallback(async () => {
        if (!productIntegration || !integration) {
            return;
        }
        await deleteProductIntegration(productIntegration.integration_id);
        addAlertMessages({
            message: `Integration for ${integration.type_} was deleted successfully.`,
            category: AlertCategory.SUCCESS,
        });
    }, [
        addAlertMessages,
        deleteProductIntegration,
        integration,
        productIntegration,
    ]);

    if (!productIntegration) {
        return <></>;
    }

    return (
        <ConfirmationPopup
            name="delete-product-integration"
            submitText="Delete"
            onSubmit={onSubmit}
        >
            <header>
                <h2>Are you sure?</h2>
                <p className="size-14">
                    This will remove the integration and cannot be undone.
                </p>
            </header>
        </ConfirmationPopup>
    );
};

export default DeleteProductIntegrationPopup;
export { DELETE_PRODUCT_INTEGRATION };
