import { FC, useCallback, useEffect, useState } from "react";
import { MainPane } from "components/MainPane";
import NotAnchor from "components/NotAnchor";
import { Page, usePage } from "components/Page";
import ExtraPane from "components/extra-panes/ExtraPane";
import { useApi } from "contexts/ApiProvider";
import { usePopup } from "contexts/PopupProvider";
import classNames from "classnames";
import type {
    AllBrokerages200Response,
    Brokerage,
    Office,
} from "@joshuins/system";
import MenuPopover from "components/MenuPopover";
import {
    DragEndEvent,
    PointerSensor,
    useSensor,
    useSensors,
    DndContext,
    closestCenter,
} from "@dnd-kit/core";
import {
    arrayMove,
    SortableContext,
    verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { unpaginate } from "components/sdk";
import { BrokerageDataPopup, OfficeDataPopup } from "./AdditionalData";
import OfficeListItem from "./OfficeListItem";
import AddOrEditBrokeragePopup from "./AddBrokeragePopup";
import AddOrEditOfficePopup from "./AddOfficePopup";
import DeleteBrokeragePopup from "./DeleteBrokeragePoup";
import DeleteAllOfficesPopup from "./DeleteAllOfficesPopup";
import DeleteOfficePopup from "./DeleteOfficePopup";
import { PaginationFooter, PaginationSearchInput } from "components/Pagination";

const BrokerageListItem: FC<{
    brokerage: Brokerage;
    totalRows: number;
    row: number;
}> = ({ brokerage, totalRows, row }) => {
    const sensors = useSensors(useSensor(PointerSensor));
    const { openPopup } = usePopup();
    const [open, setOpen] = useState<boolean>(false);
    const { sdkSystem } = useApi();
    const [offices, setOffices] = useState<Office[]>();

    useEffect(() => {
        const getOffices = async () => {
            const offices = await unpaginate(sdkSystem.allOffices, {
                brokerage_id: brokerage.id,
            });
            setOffices(offices);
        };
        getOffices();
    }, [sdkSystem, brokerage]);

    const toggleOpen = () => {
        setOpen(!open);
    };

    const handleDragEnd = (event: DragEndEvent) => {
        const { active, over } = event;

        if (!over || !active || active.id === over.id || !offices) {
            return;
        }

        const oldIndex = offices.findIndex((office) => office.id === active.id);
        const newIndex = offices.findIndex((office) => office.id === over.id);

        const updatedOffices = arrayMove(offices, oldIndex, newIndex);
        setOffices(updatedOffices);

        for (const [index, { id }] of Object.entries(updatedOffices)) {
            sdkSystem.updateOffice({
                id,
                UpdateOffice: {
                    display_order: parseInt(index) + 1,
                },
            });
        }
    };

    if (offices === undefined) {
        return <></>;
    }

    return (
        <>
            <MenuPopover
                style={{ zIndex: totalRows - row }}
                additionalClasses={classNames({
                    open,
                })}
                menuItems={[
                    {
                        key: `delete-brokerage-${brokerage.id}`,
                        label: "Delete Brokerage",
                        icon: "trash",
                        onClick: () => {
                            openPopup("delete-brokerage", {
                                brokerageIdToBeDeleted: brokerage.id,
                            });
                        },
                    },
                    {
                        key: `delete-all-offices-${brokerage.id}`,
                        label: "Delete All Offices",
                        icon: "trash",
                        onClick: () => {
                            openPopup("delete-all-offices", {
                                brokerageIdToDeleteOffices: brokerage.id,
                            });
                        },
                    },
                    {
                        key: `brokerage-data-${brokerage.id}`,
                        label: "Brokerage Data",
                        icon: "list",
                        onClick: () => {
                            openPopup("brokerage-data", {
                                brokerageId: brokerage.id,
                            });
                        },
                    },
                ]}
            >
                {({ ToggleButton, Menu }) => (
                    <>
                        <NotAnchor
                            onClick={() => {
                                openPopup("add-or-edit-brokerage", {
                                    brokerageId: brokerage.id,
                                });
                            }}
                            className="toggle-sub"
                        >
                            {brokerage.name}
                        </NotAnchor>
                        <span className="text-right">
                            <span className="mobile-hide">
                                {brokerage.domain}{" "}
                            </span>
                            <span className="scheme-box color-black-100">
                                {offices?.length}
                            </span>
                        </span>
                        {ToggleButton}
                        <NotAnchor
                            onClick={() => toggleOpen()}
                            role="tab"
                            className="toggle open-close-menu"
                        />
                        {Menu}
                        {offices && offices.length > 0 && (
                            <DndContext
                                sensors={sensors}
                                collisionDetection={closestCenter}
                                onDragEnd={handleDragEnd}
                            >
                                <ul className="list-drag ui-sortable">
                                    <SortableContext
                                        items={offices}
                                        strategy={verticalListSortingStrategy}
                                    >
                                        {offices.map((office, index) => {
                                            return (
                                                <OfficeListItem
                                                    key={office.id}
                                                    office={office}
                                                    brokerage={brokerage}
                                                    offices={offices}
                                                    totalRows={offices.length}
                                                    row={index}
                                                />
                                            );
                                        })}
                                    </SortableContext>
                                </ul>
                            </DndContext>
                        )}
                        <p className="link-strong">
                            <NotAnchor
                                onClick={() => {
                                    openPopup("add-or-edit-office", {
                                        brokerageId: brokerage.id,
                                    });
                                }}
                            >
                                <i className="icon-plus-circle" /> Add Office
                            </NotAnchor>
                        </p>
                    </>
                )}
            </MenuPopover>
        </>
    );
};

const DirectoryPane = () => (
    <ExtraPane>
        <h3>
            <i aria-hidden="true" className="icon-help" />
            Directory
        </h3>
        <p className="size-14">
            Add brokerages and regional offices to enable pre-approval in
            stores. This list appears in the Access tab of each store so you can
            decide who gets access on a store-by-store basis.
        </p>
    </ExtraPane>
);

const Main = () => {
    const { sdkSystem } = useApi();
    const { stateId } = usePage();
    const [brokerages, setBrokerages] = useState<AllBrokerages200Response>();
    const [perPage, setPerPage] = useState<number>(10);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [query, setQuery] = useState<string | undefined>();

    const loadBrokerages = useCallback(
        async (_page: number) => {
            const brokerages_ = await sdkSystem.allBrokerages({
                _per_page: perPage,
                _page,
                name: query || undefined,
            });
            setBrokerages(brokerages_);
            setCurrentPage(_page);
        },
        [perPage, query, sdkSystem]
    );

    useEffect(() => {
        const getBrokerages = async () => {
            loadBrokerages(currentPage);
        };
        getBrokerages();
    }, [currentPage, loadBrokerages, sdkSystem, stateId]);

    const setPageSize = useCallback((perPage_: string) => {
        setPerPage(parseInt(perPage_));
        setCurrentPage(1);
    }, []);

    if (!brokerages) {
        return;
    }

    return (
        <MainPane
            title="Directory"
            cta={{
                buttonLabel: "New Brokerage",
                popupName: "add-or-edit-brokerage",
            }}
        >
            <PaginationSearchInput
                placeholder="Find brokerage..."
                setCurrentPage={setCurrentPage}
                setQuery={setQuery}
            />
            {brokerages.items.length > 0 ? (
                <ul
                    className="list-drag static color-primary ui-sortable"
                    role="tablist"
                    style={{
                        borderTop: "1px solid var(--black_10)",
                    }}
                >
                    {brokerages.items.map((brokerage, index) => (
                        <BrokerageListItem
                            key={brokerage.id}
                            brokerage={brokerage}
                            totalRows={brokerages.items.length}
                            row={index}
                        />
                    ))}
                </ul>
            ) : (
                <div
                    className="module-success inline"
                    style={{ paddingTop: "100px" }}
                >
                    <h2>
                        <i className="icon-building" /> No Brokerages Found
                    </h2>
                    <p>
                        Add brokerages here to create pre-approved domains for
                        your stores
                    </p>
                </div>
            )}
            <div style={{ marginBottom: "200px" }}></div>
            {brokerages.total_pages > 1 && (
                <PaginationFooter
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    totalPages={brokerages.total_pages}
                    setPageSize={setPageSize}
                />
            )}
        </MainPane>
    );
};

const DirectoryPage: FC = () => (
    <Page>
        <Main />
        <DirectoryPane />
        <AddOrEditBrokeragePopup />
        <AddOrEditOfficePopup />
        <DeleteBrokeragePopup />
        <DeleteAllOfficesPopup />
        <DeleteOfficePopup />
        <BrokerageDataPopup />
        <OfficeDataPopup />
    </Page>
);

export default DirectoryPage;
