import { useApi } from "contexts/ApiProvider";
import {
    createContext,
    FC,
    PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
    useMemo,
} from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useMapState } from "utils/use-map-state";
import { useMountedPath } from "utils/use-mounted-path";
import keyBy from "lodash/keyBy";
import axios from "axios";
import { getMessageFromAxiosError } from "utils/axios-extras";
import { includes } from "utils/array";
import HeaderTop from "./HeaderTop";
import MyAccountSettingsPopup from "./MyAccountSettingsPopup";
import RequireAuth from "./RequireAuth";
import { LeftPaneNavigation } from "./Navigation";
import { SetStoreFromReactRouterParams } from "contexts/BrandingProvider";

enum AlertCategory {
    ALERT = "warning",
    SUCCESS = "check",
    INFO = "info",
}

interface AlertMessageInterface {
    message: string;
    category: AlertCategory;
    created?: Date;
}

interface TabInterface {
    title: string;
    path: string;
}

interface PageInterface {
    tabs?: TabInterface[];
    inStore?: boolean;
}

interface PageContextProviderInterface extends PageInterface {
    activeTab: () => TabInterface | undefined;
    hasTabs: () => boolean;
    tryCatchAndRaiseError: (
        tryFunction: () => Promise<void>,
        finallyFunction?: () => void
    ) => void;
    alertMessagesMap: <U>(
        callbackfn: (
            value: AlertMessageInterface,
            key: string,
            map: Map<string, AlertMessageInterface>
        ) => U,
        thisArg?: unknown
    ) => U[];
    addAlertMessages: (
        value: AlertMessageInterface[] | AlertMessageInterface
    ) => void;
    addSuccessMessage: (message: string) => void;
    addErrorMessage: (message: string) => void;
    removeAlertMessages: (key: string[] | string) => void;
    alertMessagesIsEmpty: () => boolean;
    element?: object;
    refreshElement: () => void;
    refreshState: () => void;
    stateId: string;
}

const PageContext = createContext<PageContextProviderInterface | undefined>(
    undefined
);

const usePage = () => {
    const context = useContext(PageContext);

    if (context === undefined) {
        throw Error("usePage must be used inside a PageProvider context");
    }

    return context;
};

const INTEGER_REGEX = /^\d+$/;

const UUID_V4_REGEX =
    /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i;

const ELEMENT_PATHS = [
    "stores",
    "products",
    "integrations",
    "insureds",
    "quotes",
    "policies",
    "product-imports",
    "submissions",
] as const;
type ElementType = (typeof ELEMENT_PATHS)[number];

const PageProvider: FC<PropsWithChildren<PageInterface>> = ({
    tabs,
    children,
}) => {
    const { sdkBuilder, sdkSystem, sdkInsurance } = useApi();
    const { state } = useLocation();
    const mountedPath = useMountedPath();
    const [element, setElement] = useState<object>();
    const [processedElementAtLeastOnce, setProcessedElementAtLeastOnce] =
        useState<boolean>(false);
    const [stateId, setStateId] = useState<string>(crypto.randomUUID());

    const tabLookup = useMemo(() => tabs && keyBy(tabs, "path"), [tabs]);

    const activeTab = useCallback(() => {
        if (!tabLookup) {
            return;
        }

        let currentActiveTab: string | undefined;
        if (mountedPath[5]) {
            currentActiveTab = mountedPath[5];
        } else if (mountedPath[4]) {
            currentActiveTab = mountedPath[4];
        } else if (mountedPath[2] && !mountedPath[3]) {
            currentActiveTab = mountedPath[2];
        } else if (mountedPath[2] && mountedPath[3]) {
            currentActiveTab = mountedPath[3];
        }
        return currentActiveTab ? tabLookup[currentActiveTab] : undefined;
    }, [mountedPath, tabLookup]);

    const hasTabs = useCallback<() => boolean>(
        () => !!tabs && tabs.length > 0,
        [tabs]
    );

    const throwErrorMessage = (error: unknown) => {
        if (axios.isAxiosError(error)) {
            addAlertMessages({
                message: getMessageFromAxiosError(error),
                category: AlertCategory.ALERT,
            });
        } else {
            throw error;
        }
    };

    const tryCatchAndRaiseError = async (
        tryFunction: () => Promise<void>,
        finallyFunction?: () => void
    ) => {
        try {
            await tryFunction();
        } catch (error) {
            throwErrorMessage(error);
        } finally {
            finallyFunction && finallyFunction();
        }
    };

    const addSuccessMessage = (message: string) => {
        addAlertMessages({
            message,
            category: AlertCategory.SUCCESS,
        });
    };

    const addErrorMessage = (message: string) => {
        addAlertMessages({
            message,
            category: AlertCategory.ALERT,
        });
    };

    const {
        mapValues: alertMessagesMap,
        addValues: addAlertMessages,
        removeValues: removeAlertMessages,
        isEmpty: alertMessagesIsEmpty,
    } = useMapState<AlertMessageInterface>();

    const getElement = useCallback(
        async (elementTypeFromUrl: ElementType, elementIdFromUrl: string) => {
            switch (elementTypeFromUrl) {
                case "integrations":
                    return sdkSystem.getIntegration({ id: elementIdFromUrl });
                case "products":
                    return sdkBuilder.getProductVersion({
                        id: parseInt(elementIdFromUrl),
                    });
                case "stores":
                    return sdkBuilder.getStore({
                        id: parseInt(elementIdFromUrl),
                    });
                case "insureds":
                    return sdkInsurance.getInsured({
                        id: parseInt(elementIdFromUrl),
                    });
                case "quotes":
                    return sdkInsurance.getQuote({
                        id: parseInt(elementIdFromUrl),
                    });
                case "policies":
                    return sdkInsurance.getQuote({
                        id: parseInt(elementIdFromUrl),
                    });
                case "submissions":
                    return sdkInsurance.getSubmission({
                        id: parseInt(elementIdFromUrl),
                    });
                case "product-imports":
                    return sdkBuilder.getProductExport({
                        id: elementIdFromUrl,
                    });
            }
        },
        [sdkBuilder, sdkSystem, sdkInsurance]
    );

    const refreshElement = useCallback(async () => {
        let elementTypeFromUrl: (typeof ELEMENT_PATHS)[number] | undefined;
        let elementIdFromUrl: string | undefined;
        if (includes(ELEMENT_PATHS, mountedPath[1])) {
            elementTypeFromUrl = mountedPath[1];
            elementIdFromUrl = mountedPath[2];
        } else if (includes(ELEMENT_PATHS, mountedPath[2])) {
            elementTypeFromUrl = mountedPath[2];
            elementIdFromUrl = mountedPath[3];
        }

        if (
            elementTypeFromUrl &&
            elementIdFromUrl &&
            (INTEGER_REGEX.test(elementIdFromUrl) ||
                UUID_V4_REGEX.test(elementIdFromUrl))
        ) {
            if (includes(ELEMENT_PATHS, elementTypeFromUrl)) {
                try {
                    const element_ = await getElement(
                        elementTypeFromUrl,
                        elementIdFromUrl
                    );
                    setElement(element_);
                    return {
                        element: element_,
                        elementExpected: true,
                    };
                } catch (error) {
                    if (!axios.isAxiosError(error)) {
                        throw error;
                    }
                    return {
                        element: undefined,
                        elementExpected: true,
                    };
                }
            }
        }
        return {
            element: undefined,
            elementExpected: false,
        };
    }, [mountedPath, getElement]);

    useEffect(() => {
        const doRefresh = async () => {
            const { element, elementExpected } = await refreshElement();
            setProcessedElementAtLeastOnce(true);
            if (elementExpected && !element) {
                window.location.href = "/404";
            }
        };
        doRefresh();
    }, [refreshElement]);

    const alertMessagesAdded = useRef<boolean>(false);

    useEffect(() => {
        if (!alertMessagesAdded.current && state?.alertMessages) {
            addAlertMessages(state.alertMessages);
        }
        return () => {
            alertMessagesAdded.current = true;
        };
    }, [state, addAlertMessages]);

    const refreshState = useCallback(() => {
        setStateId(crypto.randomUUID());
    }, []);

    if (!processedElementAtLeastOnce) {
        return <></>;
    }

    return (
        <SetStoreFromReactRouterParams>
            <RequireAuth>
                <PageContext.Provider
                    value={{
                        tabs,
                        hasTabs,
                        activeTab,
                        alertMessagesMap,
                        addAlertMessages,
                        addSuccessMessage,
                        addErrorMessage,
                        removeAlertMessages,
                        alertMessagesIsEmpty,
                        element,
                        refreshElement,
                        refreshState,
                        stateId,
                        tryCatchAndRaiseError,
                    }}
                >
                    <HeaderTop>
                        <LeftPaneNavigation />
                    </HeaderTop>
                    <Outlet />
                    <MyAccountSettingsPopup />
                    {children}
                </PageContext.Provider>
            </RequireAuth>
        </SetStoreFromReactRouterParams>
    );
};

export { usePage, PageProvider as Page, AlertCategory };
export type { AlertMessageInterface, TabInterface };
