import {
    addDocumentClass,
    removeDocumentClass,
} from "paul/native-dom-manipulation";
import {
    createContext,
    useContext,
    FC,
    PropsWithChildren,
    useEffect,
    useCallback,
} from "react";
import { useImmer } from "use-immer";

interface PopupOpenInterface {
    [key: string]: boolean | undefined;
}

interface PopupProviderInterface {
    popupOpen: PopupOpenInterface;
    openPopup: (name: string, data?: Record<string, unknown>) => void;
    closePopup: (name: string) => void;
    popupData: Record<string, unknown> | undefined;
    isPopupOpen: (name: string) => boolean;
}

const PopupContext = createContext<PopupProviderInterface | undefined>(
    undefined
);

const usePopup = () => {
    const context = useContext(PopupContext);

    if (context === undefined) {
        throw Error("usePopup must be used inside a PopupProvider context");
    }

    return context;
};

const PopupProvider: FC<PropsWithChildren> = ({ children }) => {
    const [popupOpen, setPopupOpen] = useImmer<PopupOpenInterface>({});
    const [popupData, setPopupData] = useImmer<{
        [x: string]: Record<string, unknown> | undefined;
    }>({});

    const openPopup = useCallback<
        (name: string, data?: Record<string, unknown>) => void
    >(
        (name, data) => {
            setPopupOpen((draft) => {
                draft[name] = true;
            });
            setPopupData((draft) => {
                draft[name] = data;
            });
        },
        [setPopupOpen, setPopupData]
    );

    const closePopup = useCallback<(name: string) => void>(
        (name) => {
            setPopupOpen((draft) => {
                draft[name] = false;
            });
            setPopupData((draft) => {
                draft[name] = undefined;
            });
        },
        [setPopupOpen, setPopupData]
    );

    const whichPopupOpen = (() => {
        const foundOpenPopup = Object.entries(popupOpen).find(
            (value) => value[1]
        );
        if (foundOpenPopup) {
            const [popupName] = foundOpenPopup;
            return popupName;
        } else {
            return undefined;
        }
    })();

    const isPopupOpen = useCallback<(name: string) => boolean>(
        (name) => popupOpen[name] ?? false,
        [popupOpen]
    );

    useEffect(() => {
        if (popupOpen !== undefined) {
            const anyOpen = Object.values(popupOpen).some((isOpen) => isOpen);
            if (anyOpen) {
                addDocumentClass("popup-shown");
            } else {
                removeDocumentClass("popup-shown");
            }
        }
    }, [popupOpen]);

    return (
        <PopupContext.Provider
            value={{
                popupOpen,
                popupData: whichPopupOpen
                    ? popupData[whichPopupOpen]
                    : undefined,
                openPopup,
                closePopup,
                isPopupOpen,
            }}
        >
            {children}
        </PopupContext.Provider>
    );
};

export { usePopup, PopupProvider };
