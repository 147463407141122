import classNames from "classnames";
import {
    CSSProperties,
    ElementType,
    FC,
    forwardRef,
    Fragment,
    MutableRefObject,
    PropsWithChildren,
    ReactNode,
    useState,
} from "react";
import { To } from "react-router-dom";
import { Link } from "components/DevAwareRoutingLink";
import { useOutsideClick } from "utils/use-outside-click";
import NotAnchor from "./NotAnchor";
import FileDownload from "./FileDownload";

interface MenuItemAction {
    onClick?: () => void;
    aHref?: string;
    aHrefNewTab?: string;
    linkTo?: To;
    downloadFileId?: string;
    downloadFileName?: string;
}

interface MenuItemLinkInterface extends MenuItemAction {
    postClickAction: () => void;
    enabled: boolean;
}

interface MenuItem extends MenuItemAction {
    key: string;
    icon?: string;
    iconStyle?: CSSProperties;
    label: string;
    hasSeparator?: boolean;
    className?: string;
    children?: MenuItem[];
    enabled?: boolean;
}

interface MenuPopoverInterface {
    children: (props: {
        toggleOpen: () => void;
        ToggleButton: JSX.Element;
        Menu: JSX.Element;
        open: boolean;
    }) => ReactNode;
    additionalClasses?: string | string[];
    component?: ElementType;
    menuItems: MenuItem[];
    style?: CSSProperties;
    toggleButtonLabel?: string;
}

// https://mayursinhsarvaiya.medium.com/how-to-merge-refs-in-react-component-d5e4623b6924
const mergeRefs = (
    ...refs: (
        | MutableRefObject<HTMLElement | undefined | null>
        | ((instance: HTMLElement | null) => void)
    )[]
) => {
    return (node: HTMLElement) => {
        for (const ref of refs) {
            if (typeof ref === "function") {
                ref(node);
            } else {
                ref.current = node;
            }
        }
    };
};

const MenuItemLink: FC<PropsWithChildren<MenuItemLinkInterface>> = ({
    children,
    onClick,
    aHref,
    aHrefNewTab,
    linkTo,
    downloadFileId,
    downloadFileName,
    postClickAction,
    enabled,
}) => {
    const style: CSSProperties | undefined = enabled
        ? undefined
        : { pointerEvents: "none", color: "var(--black_30)" };

    return onClick ? (
        <NotAnchor
            style={style}
            onClick={() => {
                onClick();
                postClickAction();
            }}
        >
            {children}
        </NotAnchor>
    ) : aHref ? (
        <a style={style} href={aHref}>
            {children}
        </a>
    ) : aHrefNewTab ? (
        <a style={style} href={aHrefNewTab} target="_blank" rel="noreferrer">
            {children}
        </a>
    ) : linkTo ? (
        <Link style={style} to={linkTo} onClick={() => postClickAction()}>
            {children}
        </Link>
    ) : downloadFileId ? (
        <FileDownload
            style={style}
            fileIds={[downloadFileId]}
            downloadName={downloadFileName}
        >
            {children}
        </FileDownload>
    ) : style ? (
        <span style={style}>{children}</span>
    ) : (
        <>{children}</>
    );
};

const MenuPopover = forwardRef<HTMLElement, MenuPopoverInterface>(
    (
        {
            additionalClasses,
            children,
            component = "li",
            menuItems,
            style = {},
            toggleButtonLabel,
        },
        ref
    ) => {
        const [open, setOpen] = useState<boolean>(false);

        const toggleOpen = () => {
            setOpen(!open);
        };

        const Component = component;

        const outsideClickRef = useOutsideClick(() => setOpen(false));

        const ToggleButton = (
            <NotAnchor
                onClick={() => toggleOpen()}
                className="toggle toggle-sub"
            >
                {toggleButtonLabel ?? ""}
            </NotAnchor>
        );

        const Menu = (
            <ul className="list-sub">
                {menuItems.map(
                    ({
                        key,
                        icon,
                        iconStyle,
                        label,
                        hasSeparator,
                        onClick,
                        aHref,
                        aHrefNewTab,
                        linkTo,
                        downloadFileId,
                        downloadFileName,
                        className: className_,
                        children: menuItemChildren,
                        enabled,
                    }) => {
                        const hasChildren =
                            menuItemChildren && menuItemChildren.length > 0;
                        return (
                            <Fragment key={key}>
                                {hasChildren && hasSeparator && (
                                    <li className="br">
                                        <NotAnchor
                                            onClick={() => {
                                                return;
                                            }}
                                        />
                                    </li>
                                )}
                                <li
                                    className={classNames(
                                        { br: !hasChildren && hasSeparator },
                                        className_
                                    )}
                                >
                                    {hasChildren ? (
                                        <>
                                            <span
                                                className="size-12"
                                                style={{ textAlign: "left" }}
                                            >
                                                {label}
                                            </span>
                                            {menuItemChildren.map(
                                                (menuItemChild) => (
                                                    <MenuItemLink
                                                        key={menuItemChild.key}
                                                        onClick={
                                                            menuItemChild.onClick
                                                        }
                                                        aHref={
                                                            menuItemChild.aHref
                                                        }
                                                        aHrefNewTab={
                                                            menuItemChild.aHrefNewTab
                                                        }
                                                        linkTo={
                                                            menuItemChild.linkTo
                                                        }
                                                        downloadFileId={
                                                            menuItemChild.downloadFileId
                                                        }
                                                        downloadFileName={
                                                            menuItemChild.downloadFileName
                                                        }
                                                        postClickAction={() =>
                                                            setOpen(false)
                                                        }
                                                        enabled={
                                                            menuItemChild.enabled ===
                                                                undefined ||
                                                            menuItemChild.enabled ===
                                                                true
                                                        }
                                                    >
                                                        {menuItemChild.icon && (
                                                            <>
                                                                <i
                                                                    className={`icon-${menuItemChild.icon}`}
                                                                />{" "}
                                                            </>
                                                        )}
                                                        {menuItemChild.label}
                                                    </MenuItemLink>
                                                )
                                            )}
                                        </>
                                    ) : (
                                        <MenuItemLink
                                            onClick={onClick}
                                            aHref={aHref}
                                            aHrefNewTab={aHrefNewTab}
                                            linkTo={linkTo}
                                            downloadFileId={downloadFileId}
                                            downloadFileName={downloadFileName}
                                            postClickAction={() =>
                                                setOpen(false)
                                            }
                                            enabled={
                                                enabled === undefined ||
                                                enabled === true
                                            }
                                        >
                                            {icon && (
                                                <>
                                                    <i
                                                        className={`icon-${icon}`}
                                                        style={{
                                                            ...(enabled ===
                                                                false && {
                                                                color: "var(--black_30)",
                                                            }),
                                                            ...(!!iconStyle &&
                                                                iconStyle),
                                                        }}
                                                    />{" "}
                                                </>
                                            )}
                                            {label}
                                        </MenuItemLink>
                                    )}
                                </li>
                            </Fragment>
                        );
                    }
                )}
            </ul>
        );

        const finalRef = ref
            ? mergeRefs(outsideClickRef, ref)
            : outsideClickRef;

        return (
            <Component
                ref={finalRef}
                className={classNames(additionalClasses, {
                    "sub-toggle": open,
                    "sub sub-parent": component != "header",
                })}
                style={style}
            >
                {children({ toggleOpen, ToggleButton, Menu, open })}
            </Component>
        );
    }
);
MenuPopover.displayName = "MenuPopover";

export default MenuPopover;
export type { MenuItem };
