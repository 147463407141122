import { FC, useCallback } from "react";
import { QuoteStatus } from "@joshuins/insurance";
import { createFormPopup } from "components/Popup";
import { useApi } from "contexts/ApiProvider";
import { useInsuranceProcess } from "../InsuranceProcessProvider";
import { usePopup } from "contexts/PopupProvider";
import { usePage } from "components/Page";
import NotAnchor from "components/NotAnchor";
import { Select } from "components/ReactHookFormUncontrolledComponents";
import { MenuItem as SelectMenuItem } from "components/Select";
import {
    DECLINE_BIND_REQUEST_REASONS,
    waitForProcessingInsuranceObject,
} from "../../util";
import { z } from "zod";
import { isBinder } from "utils/policies";

const DECLINE_BIND_REQUEST_NAME = "decline-bind-request";

const DeclineBindRequestLink: FC = () => {
    const { openPopup } = usePopup();
    return (
        <NotAnchor
            onClick={() => {
                openPopup(DECLINE_BIND_REQUEST_NAME);
            }}
            className="inline"
        >
            <i className="icon-x-circle" /> Decline{" "}
            <span className="mobile-hide">Bind Request</span>
        </NotAnchor>
    );
};

const CancelBinderLink: FC = () => {
    const { openPopup } = usePopup();
    return (
        <NotAnchor
            onClick={() => {
                openPopup(DECLINE_BIND_REQUEST_NAME);
            }}
            className="inline"
        >
            <i className="icon-cancel"></i> Cancel{" "}
            <span className="mobile-hide">Binder</span>
        </NotAnchor>
    );
};

const createDeclineBindRequestSchema = z.object({
    reason: z.string().min(1, "This field is required"),
    additional_details: z.string(),
});

type DeclineBindRequestType = z.infer<typeof createDeclineBindRequestSchema>;

const DeclineBindRequestPopup: FC = () => {
    const { FormPopup } = createFormPopup(createDeclineBindRequestSchema);
    const { sdkInsurance } = useApi();
    const { tryCatchAndRaiseError, refreshElement } = usePage();
    const {
        itemGetters: { getInsuranceProcess },
    } = useInsuranceProcess();

    const { quote: binder } = getInsuranceProcess();

    const onSubmit = useCallback(
        async (data: DeclineBindRequestType) => {
            if (!binder || !isBinder(binder)) {
                return <></>;
            }
            tryCatchAndRaiseError(async () => {
                await sdkInsurance.updateQuote({
                    id: binder.id,
                    UpdateQuote: {
                        status: QuoteStatus.BinderDeclined,
                        reason: data.reason,
                        additional_details: data.additional_details,
                    },
                });
                await waitForProcessingInsuranceObject(
                    sdkInsurance,
                    binder,
                    "Declining Binder..."
                );
            }, refreshElement);
        },
        [binder, tryCatchAndRaiseError, refreshElement, sdkInsurance]
    );

    return (
        <FormPopup
            name={DECLINE_BIND_REQUEST_NAME}
            defaultValues={{
                reason: "",
            }}
            onSubmit={onSubmit}
            submitText="Decline"
        >
            {({ control, register }) => (
                <>
                    <header>
                        <h2>Are you sure?</h2>
                    </header>
                    <p className="size-14 m15">
                        Please choose a reason to help the broker understand why
                        the bind request was declined
                    </p>
                    <div className="cols m15">
                        <span className="label">Reason for Decline</span>
                        <Select
                            id="decline-bind-request-reason"
                            name="reason"
                            placeholder="Select reason..."
                            required
                            control={control}
                            fullWidth
                        >
                            {DECLINE_BIND_REQUEST_REASONS.map((item, index) => (
                                <SelectMenuItem key={index} value={item.value}>
                                    {item.value}
                                </SelectMenuItem>
                            ))}
                        </Select>
                    </div>
                    <p>
                        <label htmlFor="faidec">
                            Additional Details (optional)
                        </label>
                        <input
                            {...register("additional_details")}
                            type="additional_details"
                            id="faidec"
                        />
                    </p>
                </>
            )}
        </FormPopup>
    );
};

export {
    DECLINE_BIND_REQUEST_NAME,
    DeclineBindRequestLink,
    DeclineBindRequestPopup,
    CancelBinderLink,
};
