import GoogleMapsAutocomplete from "components/GoogleMapsAutocomplete";
import { FC, createRef } from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import NotAnchor from "components/NotAnchor";
import TextareaAutosize from "react-textarea-autosize";
import { Dropzone } from "components/FileUpload";
import {
    ApplicationDatapointV1,
    ApplicationSectionItemV1,
    BindSectionItemV1,
    DatapointSourceV1,
    JoValue,
    ParsedAddress,
    ParsedGoogleAddress,
} from "@joshuins/insurance";
import {
    getJoTypeName,
    getRawValueAsStringFromJoValue,
    getRawValueFromPlainBooleanJoValue,
    joTypeIsArrayAndText,
    joTypeIsPlainAndText,
    joValueIsPlainAndBoolean,
    joValueIsPlainAndDate,
    joValueIsPlainAndFile,
    joValueIsPlainAndNumber,
    joValueIsPlainAndText,
    joValueIsPlainAndNull,
    parseDate,
    wrapAsJoValue,
    joValueIsArrayAndText,
    getRawValueFromArrayTextJoValue,
    getRawValueFromPlainFileJoValue,
    joValueIsPlainAndLocation,
    joValueIsPlainAndMonetary,
    getLocationFromJoPlainValue,
} from "utils/jo-types-and-values";
import {
    AssetDatapointV1,
    AssetSectionItemV1,
    GetFileResponse,
} from "@joshuins/builder";
import { UnionToIntersection } from "types";
import isArray from "lodash/isArray";
import { removeAppPrefix } from "./utils";
import BuilderApi from "components/sdk/builder";
import { includes, uniquelyConcatValue } from "utils/array";
import { InputChanged } from "../ApplicationPage";
import { FormControl } from "@mui/material";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { MenuItem, Select } from "components/Select";
import classNames from "classnames";
import FileDownload from "components/FileDownload";
import { ApplicationNumericInput } from "components/ReactHookFormUncontrolledComponents";
import { format } from "date-fns/format";

const BasicLabel: FC<{
    datapoint: ApplicationDatapointV1;
    fieldName: string;
    inputChanged: InputChanged;
    component?: "label" | "span";
    showRefreshButton?: boolean;
    assetIndex?: number;
}> = ({
    datapoint,
    fieldName,
    inputChanged,
    showRefreshButton = false,
    assetIndex = 0,
}) => {
    const isUwQuestion = datapoint.source === DatapointSourceV1.Underwriter;

    const inner = (
        <>
            <span
                className={classNames("label", {
                    "color-primary": isUwQuestion,
                })}
            >
                <ReactMarkdown
                    rehypePlugins={[rehypeRaw]}
                    components={{
                        p: ({ children }) => (
                            <p style={{ marginBottom: "2px" }}>{children}</p>
                        ),
                    }}
                >
                    {datapoint.title}
                </ReactMarkdown>
            </span>
            {!datapoint.required && (
                <span
                    className={classNames({
                        "color-primary": isUwQuestion,
                    })}
                >
                    &nbsp;(Optional)
                </span>
            )}
            {isUwQuestion && (
                <span className="scheme-box square-border small">U</span>
            )}
            {showRefreshButton && (
                <span className="text-right">
                    <NotAnchor
                        onClick={() => {
                            inputChanged(null, fieldName, assetIndex);
                        }}
                    >
                        <i className="icon-refresh" />{" "}
                        <span className="hidden">Reload</span>
                    </NotAnchor>
                </span>
            )}
        </>
    );
    return (
        <>
            <label htmlFor={fieldName} className="application">
                {inner}
            </label>
            <HelpText datapoint={datapoint} />
        </>
    );
};

const HelpText: FC<{ datapoint: ApplicationDatapointV1 }> = ({ datapoint }) =>
    datapoint.display.help_text ? (
        <span className="scheme-info" style={{ marginBottom: "5px" }}>
            {datapoint.display.help_text}
        </span>
    ) : (
        <></>
    );

type NonDatapointKeys = Exclude<
    keyof UnionToIntersection<ApplicationSectionItemV1>,
    "Datapoint"
>;

const NON_DATAPOINT_ELEMENTS: Record<
    NonDatapointKeys,
    (
        sectionItemInner: UnionToIntersection<ApplicationSectionItemV1>[NonDatapointKeys]
    ) => JSX.Element
> = {
    Heading: (sectionItemInner) => {
        return (
            <h4>
                <span>
                    <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                        {sectionItemInner.text}
                    </ReactMarkdown>
                </span>
            </h4>
        );
    },
    Paragraph: (sectionItemInner) => {
        return (
            <div className="div-as-p">
                <span>
                    <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                        {sectionItemInner.text}
                    </ReactMarkdown>
                </span>
            </div>
        );
    },
};

const ARRAY_DATAPOINT_FORM_CONFIG: Record<
    Extract<ReturnType<typeof getJoTypeName>[0], "Text">,
    {
        jsxElement: (props: {
            datapoint: ApplicationDatapointV1 | AssetDatapointV1;
            fieldName: string;
            inputChanged: InputChanged;
            value: string[] | null;
            editable: boolean;
            assetIndex: number | undefined;
            sdkBuilder: BuilderApi;
        }) => JSX.Element;
        apiToFormValue: (props: {
            joValue: JoValue;
            sdkBuilder: BuilderApi;
        }) => Promise<string[]>;
        formToApiValue: (props: {
            value: string[];
            sdkBuilder: BuilderApi;
        }) => Promise<JoValue>;
    }
> = {
    Text: {
        jsxElement: ({
            datapoint,
            fieldName,
            inputChanged,
            value,
            editable,
            assetIndex,
        }) => {
            if (!joTypeIsArrayAndText(datapoint.type)) {
                return <></>;
            }

            const options = datapoint.type.Array.type.Text.options;

            if (options.length === 0) {
                return <></>;
            }

            return (
                <div className="div-as-p">
                    <BasicLabel
                        datapoint={datapoint}
                        fieldName={fieldName}
                        inputChanged={inputChanged}
                        assetIndex={assetIndex}
                    />
                    <span className="check small check-select" id={fieldName}>
                        {options.map((option) => {
                            const id = `${fieldName}${
                                assetIndex === undefined ? "" : `-${assetIndex}`
                            }-${option.value}`;
                            return (
                                <span key={option.value}>
                                    <input
                                        type="checkbox"
                                        value={option.value}
                                        disabled={!editable}
                                        checked={
                                            value?.includes(option.value) ??
                                            false
                                        }
                                        onChange={(event) => {
                                            const currentValue = value ?? [];
                                            const newValue = event.target
                                                .checked
                                                ? uniquelyConcatValue(
                                                      currentValue,
                                                      option.value
                                                  )
                                                : currentValue.filter(
                                                      (value_) =>
                                                          value_ !==
                                                          option.value
                                                  );

                                            inputChanged(
                                                newValue,
                                                fieldName,
                                                assetIndex
                                            );
                                        }}
                                        id={id}
                                    />
                                    <label htmlFor={id}>{option.value}</label>
                                </span>
                            );
                        })}
                    </span>
                </div>
            );
        },
        apiToFormValue: async ({ joValue }) => {
            if (!joValueIsArrayAndText(joValue)) {
                throw new Error(
                    `${joValue} is expected to be Array and Text, but it is not`
                );
            }

            return getRawValueFromArrayTextJoValue(joValue);
        },
        formToApiValue: async ({ value }) => {
            return wrapAsJoValue(
                value.map((valueElement) => ({
                    Text: valueElement,
                }))
            );
        },
    },
};

const PLAIN_DATAPOINT_FORM_CONFIG: Record<
    ReturnType<typeof getJoTypeName>[0],
    {
        jsxElement: (props: {
            datapoint: ApplicationDatapointV1 | AssetDatapointV1;
            fieldName: string;
            inputChanged: InputChanged;
            value: string | ParsedAddress | null;
            editable: boolean;
            assetIndex: number | undefined;
            sdkBuilder: BuilderApi;
            files: Record<string, GetFileResponse> | undefined;
        }) => JSX.Element;
        apiToFormValue: (props: {
            joValue: JoValue;
            sdkBuilder: BuilderApi;
        }) => Promise<string | ParsedAddress>;
        formToApiValue: (props: {
            value: string | object | null;
            sdkBuilder: BuilderApi;
        }) => Promise<JoValue>;
    }
> = {
    Boolean: {
        jsxElement: ({
            datapoint,
            fieldName,
            inputChanged,
            value,
            editable,
            assetIndex,
        }) => {
            const yesId = `${fieldName}${
                assetIndex === undefined ? "" : `-${assetIndex}`
            }-yes`;
            const noId = `${fieldName}${
                assetIndex === undefined ? "" : `-${assetIndex}`
            }-no`;
            return (
                <div className="div-as-p">
                    <BasicLabel
                        datapoint={datapoint}
                        fieldName={fieldName}
                        inputChanged={inputChanged}
                        showRefreshButton
                        assetIndex={assetIndex}
                    />
                    <span className="check inline small">
                        <span className="m0">
                            <input
                                type="radio"
                                id={yesId}
                                value="true"
                                checked={value === "yes"}
                                disabled={!editable}
                                onChange={() => {
                                    inputChanged("yes", fieldName, assetIndex);
                                }}
                            />
                            <label htmlFor={yesId}>Yes</label>
                        </span>
                        <span className="m0">
                            <input
                                type="radio"
                                id={noId}
                                value="false"
                                checked={value === "no"}
                                disabled={!editable}
                                onChange={() => {
                                    inputChanged("no", fieldName, assetIndex);
                                }}
                            />
                            <label htmlFor={noId}>No</label>
                        </span>
                    </span>
                </div>
            );
        },
        formToApiValue: async ({ value }) => {
            if (value === null) {
                return wrapAsJoValue({ Null: {} });
            }
            if (
                typeof value !== "string" ||
                !includes(["yes", "no"] as const, value)
            ) {
                throw new Error(`${value} is not "yes" or "no"`);
            }
            return wrapAsJoValue({ Boolean: value === "yes" });
        },
        apiToFormValue: async ({ joValue }) => {
            if (!joValueIsPlainAndBoolean(joValue)) {
                throw new Error(
                    `${joValue} is expected to be Plain and Boolean, but it is not`
                );
            }

            const rawValue = getRawValueFromPlainBooleanJoValue(joValue);
            return rawValue ? "yes" : "no";
        },
    },
    Text: {
        jsxElement: ({
            datapoint,
            fieldName,
            inputChanged,
            value,
            editable,
            assetIndex,
        }) => {
            const textValue = value && typeof value === "string" ? value : "";
            if (!joTypeIsPlainAndText(datapoint.type)) {
                throw new Error(
                    "datapoint.type should be Plain and Text, but it isn't"
                );
            }

            if (datapoint.type.Text.options.length > 0) {
                const options = datapoint.type.Text.options;
                return (
                    <div className="div-as-p">
                        <BasicLabel
                            datapoint={datapoint}
                            fieldName={fieldName}
                            inputChanged={inputChanged}
                            showRefreshButton
                            assetIndex={assetIndex}
                        />
                        {options.length > 7 ? (
                            <Select
                                displayEmpty
                                value={textValue ?? ""}
                                style={{ width: "100%" }}
                                name={fieldName}
                                readOnly={!editable}
                                onChange={(e) => {
                                    inputChanged(
                                        e.target.value,
                                        fieldName,
                                        assetIndex
                                    );
                                }}
                            >
                                {options.map((datapointOption) => (
                                    <MenuItem
                                        key={datapointOption.value}
                                        value={datapointOption.value}
                                    >
                                        {datapointOption.value}
                                    </MenuItem>
                                ))}
                            </Select>
                        ) : (
                            <span
                                className="check small check-select"
                                id={fieldName}
                            >
                                {options.map((option) => {
                                    const id = `${fieldName}${
                                        assetIndex === undefined
                                            ? ""
                                            : `-${assetIndex}`
                                    }-${option.value}`;
                                    return (
                                        <span key={option.value}>
                                            <input
                                                type="radio"
                                                value={option.value}
                                                id={id}
                                                checked={value === option.value}
                                                disabled={!editable}
                                                onChange={() => {
                                                    inputChanged(
                                                        option.value,
                                                        fieldName,
                                                        assetIndex
                                                    );
                                                }}
                                            />
                                            <label htmlFor={id}>
                                                {option.value}
                                            </label>
                                        </span>
                                    );
                                })}
                            </span>
                        )}
                    </div>
                );
            }
            if (
                datapoint.type.Text.format &&
                ["WebsiteAddress", "EmailAddress", "PhoneNumber"].includes(
                    datapoint.type.Text.format
                )
            ) {
                return (
                    <div className="div-as-p">
                        <BasicLabel
                            datapoint={datapoint}
                            fieldName={fieldName}
                            inputChanged={inputChanged}
                        />
                        <input
                            id={`${fieldName}-text`}
                            disabled={!editable}
                            defaultValue={textValue}
                            onBlur={(e) => {
                                inputChanged(
                                    e.target.value,
                                    fieldName,
                                    assetIndex
                                );
                            }}
                        />
                    </div>
                );
            } else if (
                typeof datapoint.display.lines !== "undefined" &&
                datapoint.display.lines > 1
            ) {
                return (
                    <div className="div-as-p">
                        <BasicLabel
                            datapoint={datapoint}
                            fieldName={fieldName}
                            inputChanged={inputChanged}
                            assetIndex={assetIndex}
                        />
                        <TextareaAutosize
                            id={`${fieldName}-text`}
                            disabled={!editable}
                            defaultValue={textValue}
                            onBlur={(e) => {
                                inputChanged(
                                    e.target.value,
                                    fieldName,
                                    assetIndex
                                );
                            }}
                        />
                    </div>
                );
            } else {
                return (
                    <div className="div-as-p">
                        <BasicLabel
                            datapoint={datapoint}
                            fieldName={fieldName}
                            inputChanged={inputChanged}
                            assetIndex={assetIndex}
                        />
                        <input
                            disabled={!editable}
                            defaultValue={textValue}
                            onBlur={(e) => {
                                inputChanged(
                                    e.target.value,
                                    fieldName,
                                    assetIndex
                                );
                            }}
                        />
                    </div>
                );
            }
        },
        formToApiValue: async ({ value }) => {
            if (value === null) {
                return wrapAsJoValue({ Null: {} });
            }
            if (typeof value !== "string") {
                throw new Error(
                    "The value is expected to be a string, but it is not"
                );
            }
            if (value.length === 0) {
                return wrapAsJoValue({ Null: {} });
            } else {
                return wrapAsJoValue({ Text: value });
            }
        },
        apiToFormValue: async ({ joValue }) => {
            if (!joValueIsPlainAndText(joValue)) {
                throw new Error(
                    `${joValue} is expected to be Plain and Text, but it is not`
                );
            }
            return getRawValueAsStringFromJoValue(joValue);
        },
    },
    Date: {
        jsxElement: ({
            datapoint,
            fieldName,
            inputChanged,
            value,
            editable,
            assetIndex,
        }) => {
            const dateValue = value && typeof value !== "object" ? value : "";
            const id = `${
                assetIndex === undefined ? "" : `${assetIndex}-`
            }${fieldName}`;
            return (
                <div className="div-as-p">
                    <BasicLabel
                        datapoint={datapoint}
                        fieldName={id}
                        inputChanged={inputChanged}
                        assetIndex={assetIndex}
                        showRefreshButton={true}
                    />
                    <span className="input">
                        {editable ? (
                            <FormControl fullWidth>
                                <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                >
                                    <MuiDatePicker
                                        name={id}
                                        defaultValue={
                                            dateValue
                                                ? new Date(dateValue)
                                                : null
                                        }
                                        ref={createRef}
                                        onChange={(value) => {
                                            let formattedValue;
                                            try {
                                                formattedValue = value
                                                    ? format(
                                                          value,
                                                          "MM/dd/yyyy"
                                                      )
                                                    : undefined;
                                            } catch (error) {
                                                formattedValue = undefined;
                                            }
                                            formattedValue
                                                ? inputChanged(
                                                      formattedValue,
                                                      fieldName,
                                                      assetIndex ?? 0
                                                  )
                                                : null;
                                        }}
                                        slotProps={{
                                            inputAdornment: {
                                                position: "start",
                                            },
                                        }}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        ) : (
                            <input
                                disabled={true}
                                readOnly={true}
                                id={id}
                                name={id}
                                defaultValue={dateValue}
                            />
                        )}
                    </span>
                </div>
            );
        },
        formToApiValue: async ({ value }) => {
            if (value === null) {
                return wrapAsJoValue({ Null: {} });
            }
            if (typeof value !== "string") {
                throw new Error(
                    "The value is expected to be a string, but it is not"
                );
            }
            if (value.length === 0) {
                return wrapAsJoValue({ Null: {} });
            } else {
                return wrapAsJoValue({ Date: parseDate(value, "DB") });
            }
        },
        apiToFormValue: async ({ joValue }) => {
            if (!joValueIsPlainAndDate(joValue)) {
                throw new Error(
                    `${joValue} is expected to be Plain and Date, but it is not`
                );
            }
            return getRawValueAsStringFromJoValue(joValue);
        },
    },
    Number: {
        jsxElement: ({
            datapoint,
            fieldName,
            inputChanged,
            value,
            editable,
            assetIndex,
        }) => {
            const numberValue = value && typeof value === "string" ? value : "";
            return (
                <div className="div-as-p">
                    <BasicLabel
                        datapoint={datapoint}
                        fieldName={fieldName}
                        inputChanged={inputChanged}
                        assetIndex={assetIndex}
                    />
                    <span>
                        {editable ? (
                            <div className="div-as-p">
                                <ApplicationNumericInput
                                    name={fieldName}
                                    displayType="input"
                                    thousandSeparator=","
                                    allowLeadingZeros={false}
                                    value={numberValue}
                                    inputChanged={inputChanged}
                                    fieldName={fieldName}
                                    assetIndex={assetIndex}
                                />
                            </div>
                        ) : (
                            <input
                                disabled={true}
                                readOnly={true}
                                id={fieldName}
                                name={fieldName}
                                defaultValue={numberValue}
                            />
                        )}
                    </span>
                </div>
            );
        },
        formToApiValue: async ({ value }) => {
            if (typeof value !== "string") {
                throw new Error(
                    "The value is expected to be a number, but it is not"
                );
            }
            if (value.length === 0) {
                return wrapAsJoValue({ Null: {} });
            } else {
                return wrapAsJoValue({ Number: value });
            }
        },
        apiToFormValue: async ({ joValue }) => {
            if (!joValueIsPlainAndNumber(joValue)) {
                throw new Error(
                    `${joValue} is expected to be Plain and Number, but it is not`
                );
            }
            return getRawValueAsStringFromJoValue(joValue);
        },
    },
    Monetary: {
        jsxElement: ({
            datapoint,
            fieldName,
            inputChanged,
            value,
            editable,
            assetIndex,
        }) => {
            const numberValue = value && typeof value === "string" ? value : "";
            return (
                <div className="div-as-p">
                    <BasicLabel
                        datapoint={datapoint}
                        fieldName={fieldName}
                        inputChanged={inputChanged}
                        assetIndex={assetIndex}
                    />
                    <span className="input-prefix">
                        {editable ? (
                            <div className="div-as-p">
                                <ApplicationNumericInput
                                    name={fieldName}
                                    prefix={"$"}
                                    displayType="input"
                                    thousandSeparator=","
                                    allowLeadingZeros={false}
                                    value={numberValue}
                                    inputChanged={inputChanged}
                                    fieldName={fieldName}
                                    assetIndex={assetIndex}
                                />
                            </div>
                        ) : (
                            <input
                                disabled={true}
                                readOnly={true}
                                id={fieldName}
                                name={fieldName}
                                defaultValue={numberValue}
                            />
                        )}
                    </span>
                </div>
            );
        },
        formToApiValue: async ({ value }) => {
            if (typeof value !== "string") {
                throw new Error(
                    "The value is expected to be a number, but it is not"
                );
            }
            if (value.length === 0) {
                return wrapAsJoValue({ Null: {} });
            } else {
                return wrapAsJoValue({
                    Monetary: { currency: "$", amount: value },
                });
            }
        },
        apiToFormValue: async ({ joValue }) => {
            if (!joValueIsPlainAndMonetary(joValue)) {
                throw new Error(
                    `${joValue} is expected to be Plain and Monetary, but it is not`
                );
            }
            return getRawValueAsStringFromJoValue(joValue);
        },
    },
    File: {
        jsxElement: ({
            datapoint,
            fieldName,
            inputChanged,
            assetIndex,
            sdkBuilder,
            value,
            editable,
            files,
        }) => {
            return (
                <div>
                    <Dropzone
                        id={fieldName}
                        value={value ? value.toString() : ""}
                        fileDesignation="AnswerFile"
                        labelPosition="outside-p"
                        disabled={!editable}
                        onNewFile={async (acceptedFile) => {
                            if (!acceptedFile) {
                                return;
                            }
                            const id = await sdkBuilder.uploadFile(
                                acceptedFile,
                                "AnswerFile"
                            );
                            acceptedFile &&
                                inputChanged(id, fieldName, assetIndex ?? 0);
                        }}
                        Label={
                            <label htmlFor={datapoint.code}>
                                <span>{datapoint.title}</span>{" "}
                                {!datapoint.required && " (Optional)"}
                                <span className="text-right">
                                    <span className="text-right">
                                        10MB Limit
                                    </span>
                                    {files && files[fieldName] && (
                                        <>
                                            <FileDownload
                                                style={{ paddingRight: "8px" }}
                                                fileIds={[files[fieldName].id]}
                                                downloadName={
                                                    files[fieldName].filename
                                                }
                                                className="input-file files text-right color-primary"
                                            >
                                                <i className="icon-download3" />
                                            </FileDownload>
                                            {editable && (
                                                <NotAnchor
                                                    onClick={() => {
                                                        inputChanged(
                                                            null,
                                                            fieldName,
                                                            assetIndex
                                                        );
                                                    }}
                                                    className="input-file files text-right color-primary"
                                                    style={{
                                                        paddingRight: "8px",
                                                    }}
                                                >
                                                    <i className="icon-trash" />
                                                </NotAnchor>
                                            )}
                                        </>
                                    )}
                                </span>
                            </label>
                        }
                    />
                </div>
            );
        },
        formToApiValue: async ({ value }) => {
            if (value === null) {
                return wrapAsJoValue({ Null: {} });
            }

            if (typeof value !== "string") {
                throw new Error(
                    "The value is expected to be a number, but it is not"
                );
            }

            return wrapAsJoValue({
                File: value,
            });
        },
        apiToFormValue: async ({ joValue, sdkBuilder }) => {
            if (!joValueIsPlainAndFile(joValue)) {
                throw new Error(
                    `${joValue} is expected to be Plain and File, but it is not`
                );
            }
            const fileId = getRawValueFromPlainFileJoValue(joValue);
            const file = await sdkBuilder.getFile({ id: fileId });
            return file.filename;
        },
    },
    Location: {
        jsxElement: ({
            datapoint,
            fieldName,
            inputChanged,
            value,
            assetIndex,
            editable,
        }) => {
            return (
                <>
                    <div>
                        <BasicLabel
                            datapoint={datapoint}
                            fieldName={fieldName}
                            inputChanged={inputChanged}
                            showRefreshButton={true}
                        />
                        <GoogleMapsAutocomplete
                            value={value ?? undefined}
                            useAddressAsString={false}
                            id={`${fieldName}-${assetIndex}`}
                            disabled={!editable}
                            onChange={(newLocation) => {
                                if (!newLocation) {
                                    return;
                                }
                                inputChanged(
                                    newLocation,
                                    fieldName,
                                    assetIndex
                                );
                            }}
                        />
                    </div>
                    <p></p>
                </>
            );
        },
        formToApiValue: async ({ value }) => {
            if (typeof value !== "object") {
                throw new Error(
                    "The value is expected to be an string, but it is not"
                );
            }
            if (value === null) {
                return wrapAsJoValue({
                    Null: {},
                });
            }
            return wrapAsJoValue({
                Location: { ...(value as ParsedGoogleAddress) },
            });
        },
        apiToFormValue: async ({ joValue }) => {
            if (!joValueIsPlainAndLocation(joValue)) {
                throw new Error(
                    `${joValue} is expected to be Plain and Location, but it is not`
                );
            }
            return getLocationFromJoPlainValue(joValue.V1.Plain);
        },
    },
};

const getJsxElement = async ({
    sectionItem,
    joValue,
    inputChanged,
    sdkBuilder,
    editable,
    assetIndex,
    files,
}: {
    sectionItem:
        | AssetSectionItemV1
        | ApplicationSectionItemV1
        | BindSectionItemV1;
    joValue: JoValue | null;
    inputChanged: InputChanged;
    sdkBuilder: BuilderApi;
    editable: boolean;
    assetIndex?: number;
    files?: Record<string, GetFileResponse>;
}) => {
    if ("Datapoint" in sectionItem) {
        const [typeName, isArray_] = getJoTypeName(sectionItem.Datapoint.type);
        const code = removeAppPrefix(sectionItem.Datapoint.code);

        if (isArray_) {
            if (typeName !== "Text") {
                throw new Error(
                    "The section item's datapoint should be Text but it is not"
                );
            }

            const formValue =
                !joValue || joValueIsPlainAndNull(joValue)
                    ? null
                    : await ARRAY_DATAPOINT_FORM_CONFIG[
                          typeName
                      ].apiToFormValue({ joValue, sdkBuilder });

            return ARRAY_DATAPOINT_FORM_CONFIG[typeName].jsxElement({
                datapoint: sectionItem.Datapoint,
                fieldName: code,
                inputChanged,
                value: formValue,
                editable,
                assetIndex,
                sdkBuilder,
            });
        } else {
            const formValue =
                !joValue || joValueIsPlainAndNull(joValue)
                    ? null
                    : await PLAIN_DATAPOINT_FORM_CONFIG[
                          typeName as "Boolean"
                      ].apiToFormValue({ joValue, sdkBuilder });
            return PLAIN_DATAPOINT_FORM_CONFIG[typeName].jsxElement({
                datapoint: sectionItem.Datapoint,
                fieldName: code,
                inputChanged,
                value: formValue,
                editable,
                assetIndex,
                sdkBuilder,
                files,
            });
        }
    } else {
        if ("Heading" in sectionItem) {
            return NON_DATAPOINT_ELEMENTS.Heading(sectionItem.Heading);
        } else if ("Paragraph" in sectionItem) {
            return NON_DATAPOINT_ELEMENTS.Paragraph(sectionItem.Paragraph);
        }
    }
};

const formToApiValue = async (
    datapoint: ApplicationDatapointV1 | AssetDatapointV1,
    formValue: string | string[] | object | null,
    sdkBuilder: BuilderApi
) => {
    const [typeName, isArray_] = getJoTypeName(datapoint.type);
    if (isArray_) {
        if (typeName !== "Text") {
            throw new Error("The datapoint should be Text but it is not");
        }
        if (!isArray(formValue)) {
            throw new Error("The form value must be an array, but it is not");
        }
        return await ARRAY_DATAPOINT_FORM_CONFIG[typeName].formToApiValue({
            value: formValue,
            sdkBuilder,
        });
    } else {
        if (isArray(formValue)) {
            throw new Error("The form value must not be an array, but it is");
        }
        return await PLAIN_DATAPOINT_FORM_CONFIG[typeName].formToApiValue({
            value: formValue,
            sdkBuilder,
        });
    }
};

export { getJsxElement, formToApiValue };
