/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Navigate } from "components/DevAwareRoutingLink";
import { MainPane } from "components/MainPane";
import { Page, usePage } from "components/Page";
import { useApi } from "contexts/ApiProvider";
import { BUILDER_PATH } from "globals";
import { FC, useEffect, useRef, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import urlJoin from "url-join";
import ApiTokensExtraPane from "./components/ApiTokensExtraPane";

const Main: FC = () => {
    const { state } = useLocation();
    const { sdkAuth } = useApi();
    const { addAlertMessages, tryCatchAndRaiseError } = usePage();
    const [newToken, setNewToken] = useState<string>();
    const tokenRequested = useRef<boolean>(false);

    useEffect(() => {
        const requestNewApiToken = async () => {
            if (!state?.requestedName || tokenRequested.current) {
                return;
            }
            tokenRequested.current = true;
            const requestedName = state.requestedName;
            window.history.replaceState({}, document.title);
            tryCatchAndRaiseError(async () => {
                const response = await sdkAuth.createApiToken({
                    CreateApiTokenRequest: {
                        name: requestedName,
                    },
                });
                setNewToken(response.contents);
            });
        };
        requestNewApiToken();
    }, [sdkAuth, addAlertMessages, state, tryCatchAndRaiseError]);

    if (!state?.requestedName) {
        return <Navigate to={urlJoin("/", BUILDER_PATH, "api-tokens")} />;
    }

    return (
        <MainPane title="New API Token Created">
            <div
                css={css`
                    margin-top: 1rem;
                    margin-bottom: 1rem;
                `}
            >
                Here is your new API token:
            </div>

            <div
                css={css`
                    margin-top: 1.25rem;
                    margin-bottom: 1.25rem;
                    padding: 0.75rem;
                    border: 2px dashed var(--main_color);
                    border-radius: 0.5rem;
                    font-family: "Courier";
                    color: var(--black);
                    font-size: 1.1rem;
                    text-align: center;
                `}
            >
                {newToken ?? "No API token created"}
            </div>

            {newToken && (
                <>
                    <div
                        css={css`
                            margin-top: 1rem;
                            margin-bottom: 1rem;
                        `}
                    >
                        To use the API token, pass it in the Authorization
                        header using the Token scheme:
                    </div>

                    <div
                        css={css`
                            margin-top: 1.1rem;
                            margin-bottom: 1.1rem;
                            font-family: "Courier";
                            color: var(--black);
                        `}
                    >
                        Authorization: Token {newToken}
                    </div>
                </>
            )}

            <div
                css={css`
                    margin-top: 1rem;
                    margin-bottom: 1rem;
                `}
            >
                This token will not be shown again and can only be identified by
                its prefix (the characters before the dot).
            </div>

            <div
                css={css`
                    margin-top: 1rem;
                    margin-bottom: 1rem;
                `}
            >
                Please copy and store this token in a safe place before
                navigating away from this page.
            </div>

            <ul
                className="list-inline"
                css={css`
                    margin-top: 1.5rem;
                    margin-bottom: 1.5rem;
                `}
            >
                <li className="link-btn">
                    <Link to={urlJoin("/", BUILDER_PATH, "api-tokens")}>
                        Back to all API tokens
                    </Link>
                </li>
            </ul>
        </MainPane>
    );
};

const RevealNewApiTokenPage: FC = () => (
    <Page>
        <Main />
        <ApiTokensExtraPane />
    </Page>
);

export default RevealNewApiTokenPage;
