import { User, UserAccountRole } from "@joshuins/auth";
import {
    DocumentPacketTypeV1,
    PolicyStatus,
    QuoteCodeAndValue,
    QuoteStatus,
    SubmissionFlow,
} from "@joshuins/insurance";
import { Quote, Submission } from "@joshuins/insurance";
import { formatDatetimeString } from "utils/datetime";
import { getRawValueAsStringFromJoValue } from "utils/jo-types-and-values";

const quoteStatusToColor = (status: QuoteStatus) => {
    switch (status) {
        case "QuotePending":
        case "QuoteStoreEdit":
        case "CoveragePending":
        case "BinderPending":
            return "color-gold";

        case "CoverageActive":
        case "QuotePublished":
        case "BinderPublished":
            return "color-apple";

        default:
            return "color-wine";
    }
};

const policyStatusToColor = (status: PolicyStatus) => {
    switch (status) {
        case "Incomplete":
            return "color-gray";

        case "Future":
            return "color-gold";

        case "Active":
            return "color-apple";

        default:
            return "color-wine";
    }
};

const policyPendingChangeToColor = (change: SubmissionFlow) => {
    switch (change) {
        case "Cancellation":
            return "color-wine";
        case "Endorsement":
            return "color-gold";
        default:
            return "color-apple";
    }
};

const submissionFlowToStatusText = (change: SubmissionFlow) => {
    switch (change) {
        case "Cancellation":
            return "Cancel";
        default:
            return "";
    }
};

const statusToColor = (status: string) => {
    switch (status) {
        case "Submitted":
        case "Pending":
        case "StoreEdit":
        case "CoveragePending":
        case "BinderPending": {
            return "color-gold";
        }

        case "CoverageActive":
        case "Published":
        case "BinderPublished":
            return "color-apple";

        case "Incomplete":
        case "Declined":
        case "Canceled":
        case "Blocked":
        case "Closed":
        case "BinderDeclined":
        case "PolicyCanceled":
        case "Error":
        case "PolicyExpired":
            return "color-wine";
    }
};

const submissionStatusTimeDescription = (submission: Submission) => {
    switch (submission.status) {
        case "Incomplete": {
            return `Last modified on ${formatDatetimeString(
                submission.modified_at
            )}`;
        }
        case "Pending":
        case "Submitted": {
            if (submission.submitted_at) {
                return `Submitted ${formatDatetimeString(
                    submission.submitted_at
                )}`;
            }
            break;
        }
        case "Blocked": {
            return `Created on ${formatDatetimeString(submission.created_at)}`;
        }
    }
};

const getQuoteDate = (
    quoteData: QuoteCodeAndValue[],
    status: QuoteStatus,
    withStatus: boolean = true
) => {
    const quoteDate = quoteData.find(
        (codeAndValue) => codeAndValue.code === `status.${status}.at`
    );
    if (quoteDate) {
        const value = getRawValueAsStringFromJoValue(
            quoteDate.user_value || quoteDate.rater_value
        );
        return withStatus ? `${status.replace("Quote", "")} ${value}` : value;
    }
    return "";
};

const quoteStatusTimeDescription = (
    quote: Quote,
    quoteData: QuoteCodeAndValue[]
) => {
    const policyDate = getQuoteDate(quoteData, quote.status, false);
    switch (quote.status) {
        case QuoteStatus.QuotePending:
        case QuoteStatus.QuoteStoreEdit: {
            return `Submitted ${formatDatetimeString(quote.created_at)}`;
        }
        case QuoteStatus.QuotePublished:
        case QuoteStatus.QuoteDeclined:
        case QuoteStatus.QuoteClosed: {
            return getQuoteDate(quoteData, quote.status);
        }
        case "BinderDeclined": {
            return `Declined on ${policyDate}`;
        }
        case "BinderPublished": {
            return `Published on ${policyDate}`;
        }
        case "BinderPending": {
            return `Requested on ${policyDate}`;
        }
        case "CoverageActive": {
            return `Issued on ${policyDate}`;
        }
        case "PolicyCanceled": {
            return `Canceled on ${policyDate}`;
        }
        default: {
            return `Issued on ${policyDate}`;
        }
    }
};

const quoteStatusToText = {
    [QuoteStatus.BinderPending]: "Pending",
    [QuoteStatus.BinderDeclined]: "Declined",
    [QuoteStatus.BinderPublished]: "Published",
    [QuoteStatus.Error]: "Error",
    [QuoteStatus.QuoteIndication]: "Indication",
    [QuoteStatus.CoverageActive]: "Complete",
    [QuoteStatus.PolicyCanceled]: "Canceled",
    [QuoteStatus.PolicyExpired]: "Expired",
    [QuoteStatus.CoveragePending]: "Pending",
    [QuoteStatus.QuoteStoreEdit]: "Edit",
    [QuoteStatus.QuotePending]: "Pending",
    [QuoteStatus.QuotePublished]: "Published",
    [QuoteStatus.QuoteClosed]: "Closed",
    [QuoteStatus.QuoteDeclined]: "Declined",
} as const;

const documentTypeToTitle = (documentType: DocumentPacketTypeV1) => {
    switch (documentType) {
        case DocumentPacketTypeV1.NewQuote: {
            return "Quote";
        }
        case DocumentPacketTypeV1.RenewalQuote: {
            return "Renewal";
        }
        case DocumentPacketTypeV1.EndorsementQuote: {
            return "Endorsement";
        }
        default: {
            return documentType;
        }
    }
};

const isStoreUser = (currentUser: User): boolean => {
    return (
        currentUser.role === UserAccountRole.Broker ||
        currentUser.role === UserAccountRole.Insured
    );
};

export {
    statusToColor,
    policyStatusToColor,
    policyPendingChangeToColor,
    quoteStatusToColor,
    quoteStatusTimeDescription,
    submissionStatusTimeDescription,
    submissionFlowToStatusText,
    quoteStatusToText,
    documentTypeToTitle,
    isStoreUser,
};
