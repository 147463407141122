import { MutableRefObject, useEffect, useRef } from "react";

// https://stackoverflow.com/a/54292872
// https://www.youtube.com/watch?v=J-g9ZJha8FE&t=481s

const useOutsideClick = (
    callback: () => void
): MutableRefObject<HTMLElement | undefined> => {
    const callbackRef = useRef<() => void>();
    const innerRef = useRef<HTMLElement>();

    useEffect(() => {
        callbackRef.current = callback;
    });

    useEffect(() => {
        const handleClickOutside = (e: MouseEvent) => {
            if (
                innerRef.current &&
                callbackRef.current &&
                !innerRef.current.contains(e.target as Node)
            ) {
                callbackRef.current();
            }
        };

        document.addEventListener("click", handleClickOutside);
        return () => document.removeEventListener("click", handleClickOutside);
    }, []);

    return innerRef;
};

export { useOutsideClick };
