import { FC, Suspense, lazy, useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import { MainPane } from "components/MainPane";
import NotAnchor from "components/NotAnchor";
import { AlertCategory, Page, usePage } from "components/Page";
import {
    ApplicationProvider,
    useApplication,
} from "./components/ApplicationProvider";
import { formToApiValue, getJsxElement } from "./components/SectionItems";
import { useApi } from "contexts/ApiProvider";
import {
    ApplicationSectionItemV1,
    AssetCodeAndValue,
    AssetSectionItemV1,
    QuoteStatus,
    DatapointSourceV1,
    SectionV1OneOf1,
    Submission,
    SubmissionStatus,
    SectionV1,
    SubmissionDatapointStatus,
    SubmissionFlow,
} from "@joshuins/insurance";
import axios from "axios";
import { useParams } from "react-router-dom";
import compact from "lodash/compact";
import { Link, useNavigate } from "components/DevAwareRoutingLink";
import {
    getRawValueAsStringFromJoValue,
    joTypeIsArray,
    wrapAsJoValue,
} from "utils/jo-types-and-values";
import { addAppPrefix, removeAppPrefix } from "./components/utils";
import urlJoin from "url-join";
import { usePopup } from "contexts/PopupProvider";
import keyBy from "lodash/keyBy";
import { useBranding } from "contexts/BrandingProvider";
import { AssignBrokerPopup } from "./components/AssignBrokerPopup";
import DeleteAssetPopup from "./components/DeleteAssetPopup";
import MenuPopover from "components/MenuPopover";
import { getItemCode } from "utils/product-version";
import { hideLoader, showLoader } from "paul/native-dom-manipulation";
import {
    IndicationQuotePopup,
    IndicationQuoteErrorPopup,
    INDICATION_QUOTE_POPUP,
    INDICATION_QUOTE_ERROR_POPUP,
} from "./components/IndicationQuotePopup";
import { DuplicateSubmissionPopup } from "./components/DuplicateSubmission";
import { UnlockSubmissionPopup } from "./components/UnlockSubmission";
import { ChangeProductVersionPopup } from "./components/ChangeProductVersion";
import { DeclineIncompleteSubmissionPopup } from "./DeclineIncompleteSubmissionPopup";
import { BrokerInformationPopup } from "./components/BrokerInformationPopup";
import { ImportSubmissionPopup } from "./components/ImportSubmission";
import SectionNavigation from "./SectionNavigation";
import { useAuthUser } from "react-auth-kit";
import { User } from "@joshuins/auth";
import { isStoreUser } from "pages/components/util";
import { ACTIVE_QUOTE_STATUSES } from "utils/quote";

const UploadAssetFromSpreadsheetPopup = lazy(
    () => import("./components/UploadAsssetFromSpreadsheetPopup")
);

function isIndication(section: SectionV1) {
    return (
        section.type === "Application" &&
        "is_indication" in section &&
        section.is_indication
    );
}

const SectionItemComponent: FC<{
    sectionItem: ApplicationSectionItemV1 | AssetSectionItemV1;
    answerExists: boolean;
    inputChanged: InputChanged;
    assetIndex?: number;
}> = ({ sectionItem, answerExists, inputChanged, assetIndex }) => {
    const {
        editable,
        itemGetters: { getApiValues, shownSection, getSubmissionDataFiles },
    } = useApplication();
    const { sdkBuilder } = useApi();
    const [jsxElement, setJsxElement] = useState<JSX.Element>();
    const section = shownSection();

    useEffect(() => {
        const getJsxElement_ = async () => {
            if (!section) {
                return;
            }
            const datapointCode =
                "Datapoint" in sectionItem
                    ? removeAppPrefix(sectionItem.Datapoint.code)
                    : null;
            const apiValues = getApiValues();

            const apiValue =
                answerExists && datapointCode
                    ? (assetIndex === undefined
                          ? apiValues.application[datapointCode]
                          : apiValues.asset[removeAppPrefix(section.code)][
                                assetIndex
                            ]?.values[datapointCode]) ?? null
                    : null;

            setJsxElement(
                await getJsxElement({
                    sectionItem,
                    joValue: apiValue,
                    inputChanged,
                    sdkBuilder,
                    editable,
                    assetIndex,
                    files: getSubmissionDataFiles(),
                })
            );
        };
        getJsxElement_();
    }, [
        answerExists,
        assetIndex,
        editable,
        getApiValues,
        getSubmissionDataFiles,
        inputChanged,
        sdkBuilder,
        section,
        sectionItem,
    ]);

    if (!sectionItem || !jsxElement) {
        return <></>;
    }

    return jsxElement;
};

const SectionFooter: FC<{
    code: string;
}> = ({ code }) => {
    const {
        editable,
        itemGetters: {
            shownSection,
            section: section_,
            generateSectionUrl,
            getSubmissionDataStatus,
            sections,
            getProductVersion,
        },
    } = useApplication();
    const { openPopup } = usePopup();
    const navigate = useNavigate();
    const { generateUrl } = useBranding();
    const { sdkInsurance } = useApi();
    const { element, addAlertMessages, tryCatchAndRaiseError } = usePage();
    const submission = element as unknown as Submission | undefined;
    const sectionsLookup = keyBy(sections(), "code");
    const sectionsDataStatusLookup = keyBy(
        getSubmissionDataStatus().sections,
        "code"
    );
    const applicationCounters = getSubmissionDataStatus().sections.reduce(
        (accumulator, sectionData) => {
            const section = sectionsLookup[sectionData.code];
            // section can be undefined if it has an unmet condition
            // also, no need to check counters for unmet sections
            if (
                section &&
                sectionData.condition_met !== false &&
                !isIndication(section)
            ) {
                accumulator.completed += sectionData.counters.completed;
                accumulator.total += sectionData.counters.total;
                return accumulator;
            }
            return accumulator;
        },
        { completed: 0, total: 0 }
    );
    const isApplicationComplete =
        applicationCounters.completed == applicationCounters.total;

    const submitApplication = useCallback(async () => {
        if (submission) {
            try {
                showLoader("Submitting...");
                await sdkInsurance.updateSubmission({
                    id: submission.id,
                    UpdateSubmission: { status: SubmissionStatus.Submitted },
                });
                const [updateSubmissionPromise] =
                    sdkInsurance.getSubmissionWhenReady(submission.id);
                const updatedSubmission = await updateSubmissionPromise;

                if (updatedSubmission?.status === SubmissionStatus.Submitted) {
                    let quote = undefined;
                    if (updatedSubmission?.flow == SubmissionFlow.Renewal) {
                        const quotes_ = await sdkInsurance.allQuotes({
                            submission_id: submission.id,
                            status: ACTIVE_QUOTE_STATUSES.join(","),
                        });
                        quote =
                            quotes_.items.length == 1
                                ? quotes_.items[0]
                                : undefined;
                    } else {
                        quote = await sdkInsurance.createQuote({
                            CreateQuote: {
                                submission_id: submission.id,
                            },
                        });
                    }
                    if (quote) {
                        showLoader("Calculating...");
                        const [newQuotePromise] =
                            sdkInsurance.getQuoteWhenReady(quote.id);
                        await newQuotePromise;
                        navigate(
                            generateUrl(
                                urlJoin("/", "quotes", quote.id.toString())
                            )
                        );
                        hideLoader();
                        return;
                    }
                }
                navigate(
                    generateUrl(
                        urlJoin("/", "submissions", submission.id.toString())
                    )
                );
                hideLoader();
            } catch (error) {
                if (!axios.isAxiosError(error)) {
                    throw error;
                }
                addAlertMessages({
                    message: error.response?.data,
                    category: AlertCategory.SUCCESS,
                });
                hideLoader();
            }
        }
    }, [addAlertMessages, navigate, generateUrl, sdkInsurance, submission]);

    const getIndicationQuote = useCallback(async () => {
        if (submission) {
            tryCatchAndRaiseError(async () => {
                showLoader("Calculating Indication...");
                const indicationeQuote = await sdkInsurance.createQuote({
                    CreateQuote: {
                        submission_id: submission.id,
                        is_indication: true,
                    },
                });
                const [newQuotePromise] = sdkInsurance.getQuoteWhenReady(
                    indicationeQuote.id
                );
                const newQuote = await newQuotePromise;
                if (newQuote) {
                    hideLoader();
                    if (newQuote.status === QuoteStatus.Error) {
                        openPopup(INDICATION_QUOTE_ERROR_POPUP);
                    } else {
                        openPopup(INDICATION_QUOTE_POPUP, {
                            indicationeQuoteId: newQuote.id,
                        });
                    }
                }
            });
        }
    }, [openPopup, sdkInsurance, submission, tryCatchAndRaiseError]);

    const section = section_(code);
    if (!section) {
        return <></>;
    }

    const shown = code === shownSection()?.code;
    const schemaSections = getProductVersion().schema.spec.sections;
    const filteredSchemaSections = schemaSections.filter(
        (section) =>
            section.type !== "BindQuestion" &&
            sectionsDataStatusLookup[section.code] &&
            sectionsDataStatusLookup[section.code].condition_met !== false
    );
    const sectionIndex = filteredSchemaSections.findIndex(
        (section) => section.code === code
    );

    const previousSection = filteredSchemaSections[sectionIndex - 1];
    const nextSection = filteredSchemaSections[sectionIndex + 1];

    return (
        <>
            <div
                style={{
                    paddingBottom: "200px",
                    ...(!shown && { display: "none" }),
                }}
            />
            <footer
                className={classNames("module-fixed compact", {
                    "display-none": !shown,
                })}
            >
                {(previousSection || nextSection) && (
                    <p className="link-btn">
                        <Link
                            className={classNames("sectionNav inv", {
                                disabled: !previousSection,
                            })}
                            to={
                                previousSection
                                    ? generateSectionUrl(
                                          previousSection.code
                                      ) ?? ""
                                    : ""
                            }
                        >
                            Previous
                        </Link>
                        <Link
                            className={classNames("sectionNav", {
                                disabled: !nextSection,
                            })}
                            to={
                                nextSection
                                    ? generateSectionUrl(nextSection.code) ?? ""
                                    : ""
                            }
                        >
                            Next
                        </Link>
                    </p>
                )}
                <p className="link-btn">
                    <button
                        onClick={() => submitApplication()}
                        className={classNames("submit-form submit-btn", {
                            "display-none": isIndication(section),
                        })}
                        style={{ marginRight: "24px" }}
                        disabled={!isApplicationComplete || !editable}
                    >
                        <i className="icon-check-circle-outline" /> Submit
                        <span className="mobile-hide"> Application</span>
                    </button>
                    <NotAnchor
                        className={classNames({
                            "display-none": !isIndication(section),
                        })}
                        onClick={() => {
                            getIndicationQuote();
                        }}
                    >
                        <i className="icon-check-circle-outline" /> View
                        <span className="mobile-hide"> Indication</span>
                    </NotAnchor>
                </p>
            </footer>
        </>
    );
};

type InputChanged = (
    newFormValue: string | string[] | object | null,
    fieldName: string,
    assetIndex: number | undefined
) => Promise<void>;

const AssetSection: FC<{
    code: string;
    inputChanged?: InputChanged;
}> = ({ code, inputChanged }) => {
    const {
        editable,
        refreshSubmissionData,
        itemGetters: { assetSection: assetSection_, getApiValues },
    } = useApplication();
    const { element } = usePage();
    const submission = element as unknown as Submission | undefined;
    const section = assetSection_(code);
    const { sdkInsurance } = useApi();
    const { tryCatchAndRaiseError } = usePage();
    const { openPopup } = usePopup();
    const apiValues = getApiValues();

    const addAsset = useCallback(async () => {
        if (!section || !submission) {
            return;
        }

        const nextAssetIndex =
            (apiValues.asset[removeAppPrefix(section.code)].findLast(
                (asset) => asset !== undefined
            )?.index ?? -1) + 1;

        const newAssetDatapoints = compact(
            section.items.map((sectionItem) => {
                if ("Datapoint" in sectionItem) {
                    return {
                        code: sectionItem.Datapoint.code,
                        value: joTypeIsArray(sectionItem.Datapoint.type)
                            ? wrapAsJoValue([])
                            : wrapAsJoValue({ Null: {} }),
                        asset_idx: nextAssetIndex,
                    };
                }
            })
        );

        if (newAssetDatapoints.length > 0) {
            tryCatchAndRaiseError(async () => {
                await sdkInsurance.updateAssetData({
                    id: submission.id,
                    AssetDataBody: { data: newAssetDatapoints },
                });
                await refreshSubmissionData();
            });
        }
    }, [
        apiValues.asset,
        refreshSubmissionData,
        sdkInsurance,
        section,
        submission,
        tryCatchAndRaiseError,
    ]);

    if (!section || !submission || !inputChanged) {
        return <></>;
    }
    const assetsAnswered = compact(
        apiValues.asset[removeAppPrefix(section.code)]
    );

    return (
        <>
            <h1 className="asset-section-title has-anchor">
                <div className="anchor" />
                {section.title_plural}
            </h1>
            <div className="form-section">
                <div className="accordion-a ol m10 is-accordion">
                    {assetsAnswered.map((assetAnswers) => {
                        const { index: assetIndex } = assetAnswers;
                        return (
                            <AssetComponent
                                code={code}
                                key={assetAnswers.index}
                                assetIndex={assetIndex}
                                section={section}
                                inputChanged={inputChanged}
                            />
                        );
                    })}
                </div>

                {editable && (
                    <p className="link-strong">
                        <NotAnchor
                            id="add_asset"
                            className={
                                section.max_count &&
                                assetsAnswered.length >= section.max_count
                                    ? "disabled"
                                    : ""
                            }
                            onClick={() => {
                                addAsset();
                            }}
                        >
                            <i className="icon-plus-circle" />
                            Add {section.title_singular}
                        </NotAnchor>
                        <NotAnchor
                            onClick={() =>
                                openPopup("upload-asset-from-spreadsheet")
                            }
                            id="upload_xls"
                        >
                            <i className="icon-upload" /> Upload spreadsheet
                        </NotAnchor>
                    </p>
                )}
                <SectionFooter code={code} />
            </div>
        </>
    );
};

const AssetComponent: FC<{
    code: string;
    inputChanged: InputChanged;
    assetIndex: number;
    section: SectionV1OneOf1;
}> = ({ inputChanged, assetIndex, section }) => {
    const [assetIsOpen, setOpenAsset] = useState<boolean>(false);
    const {
        editable,
        refreshSubmissionData,
        itemGetters: { getApiValues, getSubmissionDataStatus },
    } = useApplication();
    const { element } = usePage();
    const submission = element as unknown as Submission | undefined;
    const { storeUrlName } = useBranding();
    const inStore = storeUrlName !== undefined;
    const { openPopup } = usePopup();
    const { sdkInsurance } = useApi();
    const { tryCatchAndRaiseError } = usePage();
    const apiValues = getApiValues();
    const submissionDataStatus = getSubmissionDataStatus();
    const datapoints = submissionDataStatus.sections.flatMap(
        (section) => section.datapoints
    );
    const submissionDataStatusLookUp: Record<
        string,
        SubmissionDatapointStatus
    > = {};
    datapoints.forEach((datapoint) => {
        const key = datapoint.code + "-" + datapoint.asset_idx; // Creating a unique key using a combination of 'code' and 'asset_idx'
        submissionDataStatusLookUp[key] = datapoint;
    });

    const sectionAssets = apiValues.asset[removeAppPrefix(section.code)];
    const numAssets = sectionAssets.length;
    const currentAssetData = sectionAssets[assetIndex];

    const duplicateAsset = useCallback(async () => {
        if (!submission || !currentAssetData) {
            return;
        }
        const assetDataToDuplicate: AssetCodeAndValue[] = Object.entries(
            currentAssetData.values
        ).map((item) => ({
            code: `app.${item[0]}`,
            value: item[1],
            asset_idx: numAssets,
        }));

        tryCatchAndRaiseError(async () => {
            await sdkInsurance.updateAssetData({
                id: submission.id,
                AssetDataBody: {
                    data: assetDataToDuplicate,
                },
            });
            refreshSubmissionData();
        });
    }, [
        currentAssetData,
        numAssets,
        refreshSubmissionData,
        sdkInsurance,
        submission,
        tryCatchAndRaiseError,
    ]);

    const sectionData = submissionDataStatus.sections.find(
        (item) => item.code === section.code
    );
    const missingAnswers =
        sectionData?.datapoints.filter(
            (item) =>
                item.asset_idx === assetIndex &&
                item.condition_met &&
                item.required &&
                !item.exists
        ) ?? [];
    const assetIsComplete = missingAnswers.length === 0;
    const firstAnswerCode = removeAppPrefix(
        getItemCode(section.items[0]) || ""
    );
    const firstAnswer =
        firstAnswerCode &&
        currentAssetData &&
        currentAssetData.values[firstAnswerCode]
            ? // submissionDataLookup[firstAnswerCode] &&
              getRawValueAsStringFromJoValue(
                  currentAssetData.values[firstAnswerCode]
              )
            : undefined;

    return (
        <>
            <MenuPopover
                component="header"
                additionalClasses={classNames("header", {
                    toggle: !assetIsOpen,
                })}
                menuItems={[
                    {
                        key: "duplicate-item",
                        label: "Duplicate Item",
                        icon: "copy",
                        onClick: () => {
                            duplicateAsset();
                        },
                    },
                    {
                        key: "delete-item",
                        label: "Delete Item",
                        icon: "trash",
                        onClick: () => {
                            openPopup("delete-asset", {
                                section,
                                assetIndex,
                            });
                        },
                    },
                ]}
                style={{
                    zIndex: 10 + 3 * numAssets - 3 * assetIndex,
                }}
            >
                {({ Menu, ToggleButton, open }) => (
                    <>
                        <h3>{firstAnswer || "Untitled"}</h3>
                        <ul
                            className="list-inline btn"
                            style={{
                                zIndex: 10 + 3 * numAssets - 3 * assetIndex - 1,
                            }}
                        >
                            <li>
                                <ul className="list-progress">
                                    <li
                                        className={classNames("asset", {
                                            done: assetIsComplete,
                                        })}
                                    >
                                        Progress status{" "}
                                    </li>
                                </ul>
                            </li>
                            {editable && (
                                <li
                                    className={classNames("sub sub-parent", {
                                        "sub-toggle": open,
                                    })}
                                >
                                    {Menu}
                                    {ToggleButton}
                                </li>
                            )}
                        </ul>
                        <NotAnchor
                            onClick={() => {
                                setOpenAsset(!assetIsOpen);
                            }}
                            role="tab"
                        />
                    </>
                )}
            </MenuPopover>
            <div
                role="tabpanel"
                style={{
                    zIndex: 10 + 3 * numAssets - 3 * assetIndex - 1,
                    display: assetIsOpen ? "none" : "",
                }}
                className={classNames({
                    disable: assetIsOpen,
                })}
            >
                {section.items.map((sectionItem) => {
                    const code = getItemCode(sectionItem);
                    let conditionMet = true;
                    let showQuestion = true;
                    let answerExists = false;
                    // show all 'Heading' and 'Paragraph' section items, for 'Datapoint' make the checks
                    if ("Datapoint" in sectionItem && code) {
                        showQuestion = !inStore
                            ? true
                            : sectionItem.Datapoint.source ===
                              DatapointSourceV1.User;
                        conditionMet =
                            submissionDataStatusLookUp[
                                `${code}-${assetIndex}`
                            ] &&
                            submissionDataStatusLookUp[`${code}-${assetIndex}`]
                                .condition_met != false;
                        answerExists =
                            submissionDataStatusLookUp[
                                `${code}-${assetIndex}`
                            ] &&
                            submissionDataStatusLookUp[`${code}-${assetIndex}`]
                                .exists != false;
                    }
                    if (code && conditionMet && showQuestion) {
                        return (
                            <SectionItemComponent
                                key={code}
                                answerExists={answerExists}
                                sectionItem={sectionItem}
                                inputChanged={inputChanged}
                                assetIndex={assetIndex}
                            />
                        );
                    }
                })}
            </div>
        </>
    );
};

const ApplicationSection: FC<{
    code: string;
    inputChanged: InputChanged;
}> = ({ code, inputChanged }) => {
    const {
        itemGetters: {
            applicationSection: applicationSection_,
            getSubmissionDataStatus,
        },
    } = useApplication();
    const { storeUrlName } = useBranding();
    const inStore = storeUrlName !== undefined;
    const { element } = usePage();
    const submission = element as unknown as Submission | undefined;
    const submissionDataStatusLookUp = keyBy(
        getSubmissionDataStatus().sections.flatMap(
            (section) => section.datapoints
        ),
        "code"
    );

    const section = applicationSection_(code);

    if (!section || !submission) {
        return <></>;
    }

    return (
        <>
            <h1 className="has-anchor">
                <div className="anchor" />
                {section.title}
            </h1>
            <div className={"form-section"}>
                {section.items.map((sectionItem) => {
                    const code = getItemCode(sectionItem);
                    let conditionMet = true;
                    let showQuestion = true;
                    let answerExists = false;
                    // show all 'Heading' and 'Paragraph' section items, for 'Datapoint' make the checks
                    if ("Datapoint" in sectionItem && code) {
                        showQuestion = !inStore
                            ? true
                            : sectionItem.Datapoint.source ===
                              DatapointSourceV1.User;
                        conditionMet =
                            submissionDataStatusLookUp[code] &&
                            submissionDataStatusLookUp[code].condition_met !=
                                false;
                        answerExists =
                            submissionDataStatusLookUp[code] &&
                            submissionDataStatusLookUp[code].exists != false;
                    }

                    if (code && showQuestion && conditionMet) {
                        return (
                            <SectionItemComponent
                                key={code}
                                answerExists={answerExists}
                                sectionItem={sectionItem}
                                inputChanged={inputChanged}
                            />
                        );
                    }
                })}
                <SectionFooter code={code} />
            </div>
        </>
    );
};

const ApplicationForm: FC = () => {
    const {
        refreshSubmissionData,
        itemGetters: { datapoint: datapoint_, shownSection },
    } = useApplication();
    const { sdkInsurance, sdkBuilder } = useApi();
    const { element, tryCatchAndRaiseError } = usePage();
    const submission = element as unknown as Submission | undefined;
    const section = shownSection();

    const inputChanged: InputChanged = useCallback(
        async (newFormValue, fieldName, assetIndex) => {
            if (!section || !submission) {
                return;
            }

            const code = addAppPrefix(fieldName);
            const datapoint = datapoint_(code);
            if (!datapoint) {
                return;
            }

            tryCatchAndRaiseError(async () => {
                if (
                    section.type === "Application" ||
                    section.type === "BindQuestion"
                ) {
                    await sdkInsurance.updateSubmissionData({
                        id: submission.id,
                        CreateSubmissionData: {
                            data: [
                                {
                                    code,
                                    value: await formToApiValue(
                                        datapoint,
                                        newFormValue,
                                        sdkBuilder
                                    ),
                                    // asset_idx: assetIndex ?? 0,
                                },
                            ],
                        },
                    });
                }
                if (section.type === "Asset") {
                    await sdkInsurance.updateAssetData({
                        id: submission.id,
                        AssetDataBody: {
                            data: [
                                {
                                    code,
                                    value: await formToApiValue(
                                        datapoint,
                                        newFormValue,
                                        sdkBuilder
                                    ),
                                    asset_idx: assetIndex ?? 0,
                                },
                            ],
                        },
                    });
                }
                await refreshSubmissionData();
            });
        },
        [
            datapoint_,
            refreshSubmissionData,
            sdkBuilder,
            sdkInsurance,
            section,
            submission,
            tryCatchAndRaiseError,
        ]
    );

    if (!section || !submission) {
        return <></>;
    }

    return (
        <>
            {section.type === "Application" ? (
                <ApplicationSection
                    code={section.code}
                    inputChanged={inputChanged}
                />
            ) : (
                <AssetSection code={section.code} inputChanged={inputChanged} />
            )}
        </>
    );
};

const Main: FC = () => {
    const { generateUrl } = useBranding();
    const navigate = useNavigate();
    const {
        navigateToSection,
        itemGetters: {
            sections: sections_,
            shownSection,
            getSubmissionDataStatus,
        },
        applicationDispatch,
    } = useApplication();
    const { element } = usePage();
    const authUser = useAuthUser();
    const currentUser = authUser() as User;
    const submission = element as unknown as Submission | undefined;
    const { sectionCode } = useParams();
    const sections = sections_();

    useEffect(() => {
        if (submission) {
            if (
                isStoreUser(currentUser) &&
                (submission.status === SubmissionStatus.Pending ||
                    submission.status === SubmissionStatus.Blocked)
            ) {
                navigate(
                    generateUrl(
                        urlJoin(
                            "/",
                            "submissions",
                            submission.id.toString(),
                            "pending"
                        )
                    )
                );
                return;
            }
            if (submission.status === SubmissionStatus.Blocked) {
                navigate(
                    generateUrl(
                        urlJoin(
                            "/",
                            "submissions",
                            submission.id.toString(),
                            "screening"
                        )
                    )
                );
                return;
            }
        }
    }, [currentUser, generateUrl, navigate, submission]);

    useEffect(() => {
        if (!sectionCode) {
            if (sections.length > 0) {
                navigateToSection(removeAppPrefix(sections[0].code));
            }

            return;
        }

        const sectionsLookup = keyBy(
            getSubmissionDataStatus().sections,
            "code"
        );
        const sectionStatus = sectionsLookup[addAppPrefix(sectionCode)];
        if (!sectionStatus || sectionStatus.condition_met === false) {
            window.location.href = "/404";
            return;
        }
        if (sectionStatus.code === shownSection()?.code) {
            return;
        }
        applicationDispatch({
            action: "SetShownSection",
            code: addAppPrefix(sectionCode),
        });
    }, [
        applicationDispatch,
        getSubmissionDataStatus,
        navigateToSection,
        sectionCode,
        sections,
        shownSection,
    ]);

    if (!sections) {
        return <></>;
    }

    return (
        <MainPane layoutConfig={{ mainLayout: null }}>
            <ApplicationForm />
        </MainPane>
    );
};

const ApplicationPage: FC = () => (
    <Page>
        <ApplicationProvider>
            <Main />
            <SectionNavigation />
            <DeleteAssetPopup />
            <AssignBrokerPopup />
            <DeclineIncompleteSubmissionPopup />
            <DuplicateSubmissionPopup />
            <ImportSubmissionPopup />
            <UnlockSubmissionPopup />
            <ChangeProductVersionPopup />
            <IndicationQuotePopup />
            <IndicationQuoteErrorPopup />
            <BrokerInformationPopup />
            <Suspense>
                <UploadAssetFromSpreadsheetPopup />
            </Suspense>
        </ApplicationProvider>
    </Page>
);

export default ApplicationPage;
export type { InputChanged };
