import { createFormPopup } from "components/Popup";
import { useApi } from "contexts/ApiProvider";
import { FC, useCallback } from "react";
import { z } from "zod";
import { useInsuranceProcess } from "../InsuranceProcessProvider";
import { usePage } from "components/Page";
import { SubmissionStatus } from "@joshuins/insurance";
import {
    DECLINE_SUBMISSION_REASONS,
    waitForProcessingInsuranceObject,
} from "../../util";
import { usePopup } from "contexts/PopupProvider";
import NotAnchor from "components/NotAnchor";
import { Select } from "components/ReactHookFormUncontrolledComponents";
import { MenuItem as SelectMenuItem } from "components/Select";

const DeclineSubmissionLink: FC = () => {
    const { openPopup } = usePopup();
    return (
        <NotAnchor
            onClick={() => {
                openPopup("decline-submission");
            }}
            className="inline"
            aria-controls="decline-submission"
            aria-haspopup="true"
        >
            <i className="icon-x-circle" /> Decline{" "}
            <span className="mobile-hide">Submission</span>
        </NotAnchor>
    );
};

const createDeclineSubmissionSchema = z.object({
    decline_reason: z.string().min(1, "This field is required"),
});

type DeclineSubmissionFlow = z.infer<typeof createDeclineSubmissionSchema>;

const DeclineSubmissionPopup: FC = () => {
    const { FormPopup } = createFormPopup(createDeclineSubmissionSchema);
    const { sdkInsurance } = useApi();
    const { tryCatchAndRaiseError, refreshElement } = usePage();
    const {
        itemGetters: { getInsuranceProcess },
    } = useInsuranceProcess();
    const { submission, quote } = getInsuranceProcess();

    const onSubmit = useCallback(
        async (data: DeclineSubmissionFlow) => {
            tryCatchAndRaiseError(async () => {
                await sdkInsurance.updateSubmission({
                    id: submission.id,
                    UpdateSubmission: {
                        status: SubmissionStatus.Declined,
                        decline_reason: data.decline_reason,
                    },
                });
                await waitForProcessingInsuranceObject(
                    sdkInsurance,
                    quote,
                    "Processing..."
                );
            }, refreshElement);
        },
        [
            tryCatchAndRaiseError,
            refreshElement,
            sdkInsurance,
            submission.id,
            quote,
        ]
    );

    return (
        <FormPopup
            name="decline-submission"
            defaultValues={{
                decline_reason: "",
            }}
            onSubmit={onSubmit}
            submitText="Decline Submission"
        >
            {({ control }) => (
                <>
                    <header>
                        <h2>Decline Submission?</h2>
                    </header>
                    <p className="size-14 m15">
                        Please choose a reason to help the broker understand why
                        the submission was declined.
                    </p>
                    <div>
                        <span className="label">Reason for Decline</span>
                        <Select
                            id="decline-submission-reason"
                            name="decline_reason"
                            placeholder="Select reason..."
                            required
                            control={control}
                            fullWidth
                        >
                            {DECLINE_SUBMISSION_REASONS.map((item, index) => (
                                <SelectMenuItem key={index} value={item.value}>
                                    {item.value}
                                </SelectMenuItem>
                            ))}
                        </Select>
                    </div>
                </>
            )}
        </FormPopup>
    );
};

export { DeclineSubmissionLink, DeclineSubmissionPopup };
