import NotAnchor from "components/NotAnchor";
import { Popup } from "components/Popup";
import { usePopup } from "contexts/PopupProvider";
import { FC } from "react";

const DocumentErrorPopup: FC = () => {
    const { closePopup, popupData } = usePopup();
    const error = popupData?.error as string | undefined;

    const onClick = async () => {
        closePopup("document-error-popup");
    };

    return (
        <Popup name="document-error-popup">
            <h2 className="m25 color-primary">Failed generating document</h2>
            <p>{error}</p>
            <p className="link-btn last-child">
                <NotAnchor onClick={onClick}>Ok</NotAnchor>
            </p>
        </Popup>
    );
};

export { DocumentErrorPopup };
