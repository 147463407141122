import { useEffect, useState, FC, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { useApi } from "contexts/ApiProvider";

import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";

import { PASSWORD_REGEX } from "components/PasswordInput";
import { FullPageMessage1 } from "components/FullPageMessage";

import axios from "axios";
import { useBranding } from "contexts/BrandingProvider";
import type { VerifyInvitationTokenResponse } from "@joshuins/auth";
import { removeNull } from "utils/object";
import useLoginProviders from "utils/use-login-providers";
import RegistrationForm from "./RegistrationForm";
import { getMessageFromAxiosError } from "utils/axios-extras";

const acceptInvitationSchema = z.object({
    email: z.string().email(),
    first_name: z.string().min(1),
    last_name: z.string().min(1),
    password: z.string().regex(PASSWORD_REGEX),
});

type AcceptInvitationType = z.infer<typeof acceptInvitationSchema>;

interface AcceptInvitationFormProps {
    initialValues: VerifyInvitationTokenResponse | undefined;
    onSubmit: SubmitHandler<AcceptInvitationType>;
    submissionFailedMessage?: string;
}

const AcceptInvitationForm: FC<AcceptInvitationFormProps> = ({
    initialValues,
    onSubmit,
    submissionFailedMessage = undefined,
}) => {
    const initialValues_ = useMemo(
        () => (initialValues ? removeNull(initialValues) : undefined),
        [initialValues]
    );
    const { ssoProviders, passwordEnabled, googleEnabled } =
        useLoginProviders();

    const { register, handleSubmit, reset } = useForm<AcceptInvitationType>({
        resolver: zodResolver(acceptInvitationSchema),
        defaultValues: initialValues_,
    });

    useEffect(() => {
        reset(initialValues_);
    }, [initialValues_, reset]);

    return (
        <RegistrationForm
            onSubmit={onSubmit}
            handleSubmit={handleSubmit}
            googleEnabled={googleEnabled}
            ssoProviders={ssoProviders}
            passwordEnabled={passwordEnabled}
            register={register}
            submissionFailedMessage={submissionFailedMessage}
        />
    );
};

const AcceptInvitationPage = () => {
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");

    const [errorMessage, setErrorMessage] = useState<{
        type: "full-page" | "label";
        message: string;
    }>();

    const [emailFromToken, setEmailFromToken] = useState<string | undefined>(
        ""
    );
    const [initialValues, setInitialValues] =
        useState<VerifyInvitationTokenResponse>();

    const { sdkAuthForProcess, apiAuthProcess } = useApi();
    const { generateNextUrl } = useBranding();
    const nextUrl = generateNextUrl();

    useEffect(() => {
        const verifyTokenAndFillForm = async () => {
            let errorMessage_: string | undefined;

            // sdkAuth changes when the user logs in so the useEffect runs again.
            // So we check also if the emailFromToken has already been set so that
            // the sdkAuth method call doesn't happen again.
            if (token && !emailFromToken) {
                try {
                    const data = await sdkAuthForProcess.invitationVerifyToken({
                        VerifyInvitationTokenRequest: {
                            token,
                        },
                    });
                    setEmailFromToken(data.email);
                    setInitialValues(data);
                } catch (error) {
                    if (axios.isAxiosError(error)) {
                        errorMessage_ = getMessageFromAxiosError(error);
                    } else {
                        throw error;
                    }
                }
            } else if (!emailFromToken) {
                errorMessage_ = "Error";
            }

            if (errorMessage_) {
                setEmailFromToken(undefined);
                setInitialValues(undefined);
                setErrorMessage({
                    type: "full-page",
                    message: errorMessage_,
                });
            } else {
                setErrorMessage(undefined);
            }
        };

        verifyTokenAndFillForm();
    }, [token, sdkAuthForProcess, emailFromToken]);

    const onSubmit = async (data: AcceptInvitationType) => {
        const { first_name, last_name, password } = data;

        if (!emailFromToken) {
            return;
        }

        let errorMessage_: string | undefined;
        if (token) {
            try {
                await sdkAuthForProcess.invitationAccept({
                    AcceptRequest: {
                        token,
                        first_name,
                        last_name,
                        password,
                    },
                });
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    errorMessage_ = getMessageFromAxiosError(error);
                } else {
                    throw error;
                }
            }
        } else {
            errorMessage_ = "Error";
        }

        if (errorMessage_) {
            setErrorMessage({
                type: "label",
                message: errorMessage_,
            });
        } else {
            apiAuthProcess.login(emailFromToken, password, nextUrl);
        }
    };

    let determinedErrorMessage;
    if (errorMessage) {
        determinedErrorMessage = errorMessage;
    } else if (apiAuthProcess.authProcessErrorMessage) {
        determinedErrorMessage = {
            type: "label",
            message: apiAuthProcess.authProcessErrorMessage,
        };
    }

    return (
        <main id="content">
            {determinedErrorMessage?.type === "full-page" ? (
                <FullPageMessage1
                    title="We had a problem completing the invitation"
                    message={determinedErrorMessage.message}
                    iconType="triangle"
                />
            ) : (
                <AcceptInvitationForm
                    onSubmit={onSubmit}
                    submissionFailedMessage={determinedErrorMessage?.message}
                    initialValues={initialValues}
                />
            )}
        </main>
    );
};

export default AcceptInvitationPage;
