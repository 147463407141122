import { useApi } from "contexts/ApiProvider";
import { ChangeEvent, FC, useCallback, useEffect } from "react";
import { useImmer } from "use-immer";
import ExtraPane from "./ExtraPane";
import type {
    ProductChecklistTag,
    StoreChecklistTag,
    UserChecklistTag,
} from "@joshuins/builder";
import reduce from "lodash/reduce";

type ChecklistType = "product" | "store" | "user";

type ChecklistTagType =
    | ProductChecklistTag
    | StoreChecklistTag
    | UserChecklistTag;

interface ChecklistExtraPaneProps {
    associatedId?: number;
    checklistType: ChecklistType;
    title: string;
    description: string;
    tags: {
        tag: ChecklistTagType;
        title: string;
        url: string;
    }[];
}

const ChecklistExtraPane: FC<ChecklistExtraPaneProps> = ({
    associatedId,
    checklistType,
    title,
    description,
    tags,
}) => {
    const { sdkBuilder } = useApi();
    const [checklist, setChecklist] = useImmer<
        Record<ChecklistTagType, boolean>
    >({} as Record<ChecklistTagType, boolean>);

    const getSelectedTags = useCallback(
        async (checklistType: ChecklistType) => {
            if (checklistType === "user") {
                return await sdkBuilder.allUserChecklists();
            } else if (associatedId) {
                switch (checklistType) {
                    case "product":
                        return await sdkBuilder.allProductChecklists({
                            product_version_id: associatedId,
                        });
                    case "store":
                        return sdkBuilder.allStoreChecklists({
                            store_id: associatedId,
                        });
                }
            } else {
                return [];
            }
        },
        [associatedId, sdkBuilder]
    );

    const createChecklistTag = async (value: unknown) => {
        if (checklistType === "user") {
            await sdkBuilder.createUserChecklist({
                tag: value as UserChecklistTag,
            });
        } else if (associatedId) {
            switch (checklistType) {
                case "product": {
                    await sdkBuilder.createProductChecklist({
                        product_version_id: associatedId,
                        tag: value as ProductChecklistTag,
                    });
                    break;
                }
                case "store": {
                    await sdkBuilder.createStoreChecklist({
                        store_id: associatedId,
                        tag: value as StoreChecklistTag,
                    });
                    break;
                }
            }
        }
    };

    const deleteChecklistTag = async (value: unknown) => {
        if (checklistType === "user") {
            await sdkBuilder.deleteUserChecklist({
                tag: value as UserChecklistTag,
            });
        } else {
            switch (checklistType) {
                case "product": {
                    if (associatedId) {
                        await sdkBuilder.deleteProductChecklist({
                            product_version_id: associatedId,
                            tag: value as ProductChecklistTag,
                        });
                    }
                    break;
                }
                case "store": {
                    if (associatedId) {
                        await sdkBuilder.deleteStoreChecklist({
                            store_id: associatedId,
                            tag: value as StoreChecklistTag,
                        });
                    }
                    break;
                }
            }
        }
    };

    useEffect(() => {
        const getChecklistItems = async () => {
            const selectedTags = await getSelectedTags(checklistType);

            // using Lodash reduce because .reduce doesn't work well
            // on an array with a union type
            // https://github.com/microsoft/TypeScript/issues/44063
            const checkedTags = reduce<
                (typeof selectedTags)[number],
                // make this Partial so that we can start with an empty accumulator
                Partial<Record<(typeof selectedTags)[number]["tag"], boolean>>
            >(
                selectedTags,
                (accumulator, item) => {
                    accumulator[item.tag] = true;
                    return accumulator;
                },
                {}
                // have to force this back into not being Partial since setChecklist expects a normal Record
            ) as unknown as Record<
                (typeof selectedTags)[number]["tag"],
                boolean
            >;
            setChecklist(checkedTags);
        };
        getChecklistItems();
    }, [
        sdkBuilder,
        associatedId,
        checklistType,
        setChecklist,
        getSelectedTags,
    ]);

    const onChange = async (event: ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked;
        if (checked) {
            await createChecklistTag(event.target.value);
        } else {
            await deleteChecklistTag(event.target.value);
        }
        setChecklist((draft) => {
            switch (checklistType) {
                case "product":
                    draft[event.target.value as ProductChecklistTag] = checked;
                    break;
                case "store":
                    draft[event.target.value as StoreChecklistTag] = checked;
                    break;
                case "user":
                    draft[event.target.value as UserChecklistTag] = checked;
                    break;
            }
        });
    };

    return (
        <ExtraPane>
            <h3>
                <i aria-hidden="true" className="icon-help" />
                {title}
            </h3>
            <p className="size-14 m15">{description}</p>
            <form action="./" method="post">
                <ul className="check semi">
                    {tags.map(({ tag, url, title }) => (
                        <li key={tag}>
                            <input
                                className="check-list"
                                type="checkbox"
                                onChange={onChange}
                                checked={checklist?.[tag] ?? false}
                                id={tag}
                                value={tag}
                            />
                            <label htmlFor={tag}>
                                <a
                                    className="hover-underline-animation"
                                    href={url}
                                    rel="noreferrer"
                                    target="_blank"
                                >
                                    {title}
                                </a>
                            </label>
                        </li>
                    ))}
                </ul>
            </form>
            <span>
                <a
                    href="https://help.joshu.insure/en/"
                    target="_blank"
                    rel="noreferrer"
                    style={{ fontSize: "12px" }}
                >
                    <i aria-hidden="true" className="icon-list" /> Help Center
                </a>
            </span>
        </ExtraPane>
    );
};

export default ChecklistExtraPane;
