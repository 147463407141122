import { useApi } from "contexts/ApiProvider";
import { useUserAnalytics } from "contexts/UserAnalyticsProvider";
import { FC, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

/*
This component wraps all the routes and waits for certain providers to be ready
before rendering any routes
For now it waits for the ApiProvider and the UserAnalyticsProvider
 */

const WaitForInitialization: FC = () => {
    const { apiAuthProcess } = useApi();
    const userAnalyticsContext = useUserAnalytics();
    const [ready, setReady] = useState<boolean>(false);

    useEffect(() => {
        if (
            apiAuthProcess.initializeFromStorageAttempted &&
            userAnalyticsContext
        ) {
            setReady(true);
        }
    }, [apiAuthProcess.initializeFromStorageAttempted, userAnalyticsContext]);

    if (ready) {
        return <Outlet />;
    } else {
        return <></>;
    }
};

export default WaitForInitialization;
