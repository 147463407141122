import { FC } from "react";
import { useBranding } from "contexts/BrandingProvider";
import { QuoteStatus, Submission, SubmissionFlow } from "@joshuins/insurance";
import { Link } from "components/DevAwareRoutingLink";
import urlJoin from "url-join";

const StoreBlockedQuotePage: FC<{
    quoteStatus: QuoteStatus;
    submission: Submission;
}> = ({ quoteStatus, submission }) => {
    const { storeUrlName, generateUrl } = useBranding();

    if (!storeUrlName) {
        return;
    }
    let content;
    switch (quoteStatus) {
        case QuoteStatus.QuotePending:
        case QuoteStatus.BinderPending: {
            switch (submission.flow) {
                case SubmissionFlow.New: {
                    content = (
                        <>
                            <h1>
                                <i className="icon-edit"></i>{" "}
                                {quoteStatus === "QuotePending"
                                    ? "Application "
                                    : "Binder "}
                                Received
                            </h1>
                            <p>
                                We usually return the quote within 48 hours.
                                <br /> We&apos;ll email you when it&apos;s
                                ready!
                            </p>

                            <p className="link-strong">
                                <Link
                                    to={generateUrl(
                                        urlJoin(
                                            "submissions",
                                            submission.id.toString(),
                                            "application"
                                        )
                                    )}
                                >
                                    View Application{" "}
                                    <i className="icon-chevron-right"></i>
                                </Link>
                            </p>
                        </>
                    );
                    break;
                }
                case SubmissionFlow.Endorsement: {
                    content = (
                        <>
                            <h1>
                                <i className="icon-edit"></i> In Review
                            </h1>
                            <p>
                                We usually return the quote within 48 hours.
                                <br /> We&apos;ll email you when it&apos;s
                                ready!
                            </p>
                        </>
                    );
                    break;
                }
            }
            break;
        }
        case QuoteStatus.QuoteDeclined: {
            content = (
                <>
                    <h1>
                        <i className="icon-edit"></i> Quote Declined
                    </h1>
                    <p>
                        This quote has been declined. <br />
                        Please contact the appropriate underwriter to find out
                        more.
                    </p>
                </>
            );
            break;
        }
        case QuoteStatus.QuoteStoreEdit: {
            content = (
                <>
                    <h1>
                        <i className="icon-edit"></i> Endorsement In Progress
                    </h1>
                    <p>
                        The broker is creating an endoresement request. <br />
                    </p>
                </>
            );
            break;
        }
        case QuoteStatus.Error: {
            content = (
                <>
                    <h1>
                        <i className="icon-edit"></i> In Review
                    </h1>
                    <p>
                        We usually return the quote within 48 hours.
                        <br /> We&apos;ll email you when it&apos;s ready!
                    </p>
                </>
            );
            break;
        }
        case QuoteStatus.QuoteClosed: {
            content = (
                <>
                    <h1>
                        <i className="icon-edit"></i> Quote Closed
                    </h1>
                    <p>
                        This quote has been closed. <br />
                        Please contact the appropriate underwriter to find out
                        more.
                    </p>
                </>
            );
        }
    }
    return (
        <main id="content" className="wide text-center">
            <div className="module-success inline">{content}</div>
        </main>
    );
};

export { StoreBlockedQuotePage };
