import ChecklistExtraPane from "./ChecklistExtraPane";

// @TODO: why does this checklist not have an associatedId
const GettingStartedExtraPane = () => (
    <ChecklistExtraPane
        title="Getting Started"
        description="Take an existing product online, or try something new. With Joshu, you can create digital products in a matter of hours."
        checklistType="user"
        tags={[
            {
                tag: "JoshuVideoTutorials",
                title: "Joshu Video Tutorials",
                url: "https://help.joshu.insure/en/articles/6396041-joshu-video-tutorials",
            },
            {
                tag: "TheJoshuPlatform",
                title: "The Joshu Platform",
                url: "https://help.joshu.insure/en/articles/5464277-intro-the-joshu-platform",
            },
            {
                tag: "ProductsAndStores",
                title: "Products & Stores",
                url: "https://help.joshu.insure/en/articles/6389986-intro-products-stores",
            },
            {
                tag: "JoshuIntegrations",
                title: "Joshu Integrations",
                url: "https://help.joshu.insure/en/articles/6389988-intro-joshu-integrations",
            },
        ]}
    />
);

export default GettingStartedExtraPane;
