import {
    Document,
    DocumentPacketTypeV1,
    DocumentPartV1,
    DocumentSchemaV1,
    QuoteCodeAndValue,
    ParameterPermissionV1,
} from "@joshuins/insurance";
import { compact } from "lodash";
import { User, UserAccountRole } from "@joshuins/auth";
import keyBy from "lodash/keyBy";
import {
    getRawValueAsStringFromJoValue,
    getRawValueFromPlainBooleanJoValue,
    getUserOrRaterValue,
    joValueIsPlainAndBoolean,
    joValueIsPlainAndText,
} from "utils/jo-types-and-values";

const MANUAL_DOC_PREFIX = "doc.manual" as const;

const getModifiedParametersCodes = (
    quoteCodesAndValues: QuoteCodeAndValue[],
    prevQuoteCodesAndValues: QuoteCodeAndValue[] | undefined
) => {
    if (prevQuoteCodesAndValues) {
        const modifiedParametersCodes: string[] = [];
        const quoteCodesAndValuesByCode = keyBy(quoteCodesAndValues, "code");
        for (const prevQuoteParameter of prevQuoteCodesAndValues) {
            if (quoteCodesAndValuesByCode[prevQuoteParameter.code]) {
                const currentValue = getRawValueAsStringFromJoValue(
                    getUserOrRaterValue(
                        quoteCodesAndValuesByCode[prevQuoteParameter.code]
                    )
                );
                const prevValue = getRawValueAsStringFromJoValue(
                    getUserOrRaterValue(prevQuoteParameter)
                );
                if (prevValue !== currentValue) {
                    modifiedParametersCodes.push(prevQuoteParameter.code);
                }
            }
        }
        return modifiedParametersCodes;
    }
    return [];
};

const isParameterModified = (
    dirtyFields: QuoteCodeAndValue[],
    parameter: QuoteCodeAndValue,
    modifiedParametersCodes: string[]
) => {
    const dirtyFieldsLookup = keyBy(dirtyFields);
    return (
        dirtyFieldsLookup[parameter.code] ||
        modifiedParametersCodes.includes(parameter.code)
    );
};

function getDocumentSchemaByPartCode(
    code: string,
    documentsSchemas: DocumentSchemaV1[]
) {
    for (const document of documentsSchemas) {
        const partCodesInDocument = document.parts.map((part) => part.code);
        if (partCodesInDocument.includes(code)) {
            return document;
        }
    }
    return undefined;
}

function isDocumentIncluded(
    documentCode: string,
    quoteDocuments: Document[],
    includedQuoteDocsCodes: string[]
) {
    const includeDocumentCodes = quoteDocuments.map((doc) => doc.code);
    if (
        includeDocumentCodes.includes(documentCode) &&
        includedQuoteDocsCodes.includes(documentCode)
    ) {
        return true;
    }
    return false;
}

function getDocumentByCode(code: string, documents: Document[]) {
    for (const document of documents) {
        if (document.code === code) {
            return document;
        }
    }
    return undefined;
}

function getIncludedQuoteDocsCodes(quoteCodesAndValues: QuoteCodeAndValue[]) {
    const quoteDataDocumentCodes = quoteCodesAndValues.map((data) => {
        const parsedCode =
            data.code.split(".")[0] === "doc" &&
            getRawValueAsStringFromJoValue(getUserOrRaterValue(data)) === "true"
                ? data.code
                : undefined;
        return parsedCode;
    });
    return compact(quoteDataDocumentCodes);
}

function getManualDocumentsFromQuoteData(
    quoteCodesAndValues: QuoteCodeAndValue[],
    documentType: DocumentPacketTypeV1
) {
    // This is messy and full of assumptions, sorry in advance :(
    // First, filter only manual documents of quotecodeandvalue
    // All of them should be of type
    // "doc.manual.documentCode" /
    // "doc.manual.documentCode.packet_type" /
    // "doc.manual.documentCode.name"
    const quoteDataManualCodes = quoteCodesAndValues.filter(
        (data) =>
            data.code.split(".").length >= 3 &&
            data.code.split(".").length < 5 &&
            data.code.split(".")[1] === "manual"
    );

    // Group by document code. to get:
    // {docCode1: QuoteCodeAndValue[] , docCode2: QuoteCodeAndValue[]}
    // (each QuoteCodeAndValue[] has length of 3)
    const groupedManualQuoteCodeAndValue = quoteDataManualCodes.reduce(
        (accumulator, item) => {
            const partCode = item.code.split(".")[2];
            accumulator[partCode]
                ? accumulator[partCode].push(item)
                : (accumulator[partCode] = [item]);
            return accumulator;
        },
        {} as Record<string, QuoteCodeAndValue[]>
    );

    // 1. If some are deleted beforehand, their doc.manual.documentCode item is boolean and set to false. Remove Them.
    // 2. If documents are not in the right document type, Remove Them.

    const filteredRecord: Record<string, QuoteCodeAndValue[]> = {};

    for (const [key, QuoteCodesAndValues] of Object.entries(
        groupedManualQuoteCodeAndValue
    )) {
        const filteredValues = QuoteCodesAndValues.filter(
            (item) =>
                (joValueIsPlainAndBoolean(item.user_value) &&
                    getRawValueFromPlainBooleanJoValue(item.user_value) ===
                        false) ||
                (item.code.split(".")[3] === "packet_type" &&
                    getRawValueAsStringFromJoValue(item.user_value) !==
                        documentType)
        );
        if (filteredValues.length === 0) {
            filteredRecord[key] = QuoteCodesAndValues;
        }
    }

    const manualDocuments: DocumentSchemaV1[] = Object.values(
        filteredRecord
    ).map((docArray) => {
        const manualDocument: DocumentSchemaV1 = {
            code: "",
            name: "",
            include: { Manual: {} },
            parts: [],
        };
        for (const item of docArray) {
            if (joValueIsPlainAndBoolean(item.user_value)) {
                manualDocument.code = item.code;
                manualDocument.include = { Manual: {} };
            }
            if (
                item.code.endsWith(".name") &&
                joValueIsPlainAndText(item.user_value)
            ) {
                manualDocument.name = getRawValueAsStringFromJoValue(
                    item.user_value
                );
            }
        }
        return manualDocument;
    });

    return manualDocuments;
}

function getManualPartsFromQuoteData(
    quoteCodesAndValues: QuoteCodeAndValue[],
    parentCode: string
) {
    // This is messy and full of assumptions, sorry in advance :(
    // First, filter only manual part of quotecodeandvalue
    // All of them should be of type
    // "doc.manual.parentCode.part.partCode" /
    // "doc.manual.parentCode.part.partCode.file_id" /
    // "doc.manual.parentCode.part.partCode.name"

    let parsedParentCode = parentCode.split(".")[2];
    // if its not a manual document
    if (!parsedParentCode) {
        parsedParentCode = parentCode.split(".")[1];
    }

    const quoteDataManualCodes = quoteCodesAndValues.filter(
        (data) =>
            data.code.split(".").length >= 5 &&
            data.code.split(".")[2] === parsedParentCode &&
            data.code.split(".")[1] === "manual"
    );

    // Group by part code. to get:
    // {partCode1: QuoteCodeAndValue[] , partCode2: QuoteCodeAndValue[]}
    // (each QuoteCodeAndValue[] has length of 3)
    const groupedManualQuoteCodeAndValue = quoteDataManualCodes.reduce(
        (accumulator, item) => {
            const partCode = item.code.split(".")[4];
            accumulator[partCode]
                ? accumulator[partCode].push(item)
                : (accumulator[partCode] = [item]);
            return accumulator;
        },
        {} as Record<string, QuoteCodeAndValue[]>
    );

    // If some are deleted beforehand, their doc.manual.parentCode.part.partCode item is boolean and set to false. Remove Them.
    const filteredRecord: Record<string, QuoteCodeAndValue[]> = {};

    for (const [key, QuoteCodesAndValues] of Object.entries(
        groupedManualQuoteCodeAndValue
    )) {
        const filteredValuesIfBooleanIsFalse = QuoteCodesAndValues.filter(
            (item) =>
                joValueIsPlainAndBoolean(item.user_value) &&
                getRawValueFromPlainBooleanJoValue(item.user_value) === false
        );
        if (filteredValuesIfBooleanIsFalse.length === 0) {
            filteredRecord[key] = QuoteCodesAndValues;
        }
    }

    const manualParts: DocumentPartV1[] = Object.values(filteredRecord).map(
        (partArray) => {
            const manualPart: DocumentPartV1 = {
                code: "",
                template_id: "",
                name: "",
                form_number: "",
                include: { Manual: {} },
            };
            for (const item of partArray) {
                if (joValueIsPlainAndBoolean(item.user_value)) {
                    manualPart.code = item.code;
                    manualPart.include = { Manual: {} };
                }
                if (joValueIsPlainAndText(item.user_value)) {
                    manualPart.name = getRawValueAsStringFromJoValue(
                        item.user_value
                    );
                }
            }
            return manualPart;
        }
    );

    return manualParts;
}

const isPermissionValid = (user: User, permission: ParameterPermissionV1) => {
    switch (permission) {
        case ParameterPermissionV1.Users: {
            return true;
        }
        case ParameterPermissionV1.Underwriters: {
            return (
                user.role === UserAccountRole.Underwriter ||
                user.role === UserAccountRole.Admin
            );
        }
        default: {
            return false;
        }
    }
};

export {
    getModifiedParametersCodes,
    isParameterModified,
    isPermissionValid,
    getDocumentSchemaByPartCode,
    isDocumentIncluded,
    getDocumentByCode,
    getIncludedQuoteDocsCodes,
    getManualDocumentsFromQuoteData,
    getManualPartsFromQuoteData,
    MANUAL_DOC_PREFIX,
};
