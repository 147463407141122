import HeaderTop from "components/HeaderTop";
import { addDocumentClass } from "paul/native-dom-manipulation";
import { Outlet } from "react-router-dom";

const AuthBase = () => {
    addDocumentClass("theme-center");

    return (
        <>
            <HeaderTop />
            <Outlet />
        </>
    );
};

export default AuthBase;
