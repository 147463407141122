import { SsoProviderMetadata } from "@joshuins/auth";
import { useApi } from "contexts/ApiProvider";
import { useEffect, useState } from "react";

const useLoginProviders = () => {
    const { sdkAuth } = useApi();
    const [passwordEnabled, setPasswordEnabled] = useState<boolean>();
    const [googleEnabled, setGoogleEnabled] = useState<boolean>();
    const [ssoProviders, setSsoProviders] = useState<SsoProviderMetadata[]>();

    useEffect(() => {
        const getPasswordEnabled = async () => {
            const { enable_password_login } =
                await sdkAuth.passwordLoginStart();
            setPasswordEnabled(enable_password_login);
        };
        getPasswordEnabled();
    }, [sdkAuth]);

    useEffect(() => {
        const getSsoProviderSlugs = async () => {
            const { providers } = await sdkAuth.listSsoProviders();
            setSsoProviders(providers.filter(({ slug }) => slug !== "google"));
            setGoogleEnabled(
                providers.findIndex(({ slug }) => slug === "google") !== -1
            );
        };
        getSsoProviderSlugs();
    }, [sdkAuth]);

    return {
        ssoProviders,
        passwordEnabled,
        googleEnabled,
    };
};

export default useLoginProviders;
