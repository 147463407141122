import { FC } from "react";
import classNames from "classnames";
import NotAnchor from "./NotAnchor";
import { MenuItem, Select } from "./Select";

const PaginationSearchInput: FC<{
    placeholder?: string;
    query?: string;
    setCurrentPage: (value: React.SetStateAction<number>) => void;
    setQuery: (value: React.SetStateAction<string | undefined>) => void;
}> = ({ placeholder, setCurrentPage, setQuery, query }) => (
    <div style={{ paddingBottom: "16px" }}>
        <input
            type="text"
            id="paginated-list-search"
            name="paginated-list-search"
            placeholder={placeholder ?? ""}
            className="input-search"
            onChange={(e) => {
                setQuery((e.target as HTMLInputElement).value);
                setCurrentPage(1);
            }}
            defaultValue={query}
        />
    </div>
);

const PaginationLink: FC<{
    setCurrentPage: (value: React.SetStateAction<number>) => void;
    pageNumber: number;
    classes: string;
    text?: string;
    prevIcon?: string;
    nextIcon?: string;
}> = ({ setCurrentPage, pageNumber, text, classes, prevIcon, nextIcon }) => (
    <li className={classes}>
        <NotAnchor
            onClick={() => {
                setCurrentPage(pageNumber);
            }}
            className="toggle-sub"
        >
            {prevIcon && <i aria-hidden="true" className={prevIcon}></i>}
            {text ?? pageNumber.toString()}
            {nextIcon && <i aria-hidden="true" className={nextIcon}></i>}
        </NotAnchor>
    </li>
);

const PaginationFooter: FC<{
    currentPage: number;
    setCurrentPage: (value: React.SetStateAction<number>) => void;
    totalPages: number;
    setPageSize: (value: string) => void;
}> = ({ currentPage, setCurrentPage, totalPages, setPageSize }) => (
    <footer className="module-fixed mobile-hide">
        <ul className="list-inline c color-black-60">
            <li key="pageSize">
                <span className="label">Show</span>
                <Select
                    name="mfa"
                    id="mfa"
                    onChange={(e) => {
                        setPageSize((e.target as HTMLInputElement).value);
                    }}
                    defaultValue={10}
                >
                    {[5, 10, 20].map((newPageSize) => (
                        <MenuItem key={newPageSize} value={newPageSize}>
                            {newPageSize} Per Page
                        </MenuItem>
                    ))}
                </Select>
            </li>
        </ul>
        <ul className="list-pagination">
            <PaginationLink
                setCurrentPage={setCurrentPage}
                pageNumber={currentPage - 1}
                text={"Previous"}
                classes={classNames("prev", {
                    disabled: currentPage === 1,
                })}
                prevIcon="icon-chevron-left"
            ></PaginationLink>
            {currentPage > 2 && (
                <PaginationLink
                    key="firstPage"
                    setCurrentPage={setCurrentPage}
                    pageNumber={1}
                    classes={classNames({
                        active: currentPage === 1,
                    })}
                ></PaginationLink>
            )}
            {currentPage > 3 && <li>...</li>}
            {currentPage > 1 && (
                <PaginationLink
                    key="prevPage"
                    setCurrentPage={setCurrentPage}
                    pageNumber={currentPage - 1}
                    classes=""
                ></PaginationLink>
            )}
            <PaginationLink
                key="currentPage"
                setCurrentPage={setCurrentPage}
                pageNumber={currentPage}
                classes="active"
            ></PaginationLink>
            {currentPage < totalPages && (
                <PaginationLink
                    key="nextPage"
                    setCurrentPage={setCurrentPage}
                    pageNumber={currentPage + 1}
                    classes=""
                ></PaginationLink>
            )}
            {currentPage < totalPages - 2 && <li>...</li>}
            {currentPage < totalPages - 1 && (
                <PaginationLink
                    key="lastPage"
                    setCurrentPage={setCurrentPage}
                    pageNumber={totalPages}
                    classes=""
                ></PaginationLink>
            )}
            <PaginationLink
                key="next"
                setCurrentPage={setCurrentPage}
                pageNumber={currentPage + 1}
                text="Next"
                classes={classNames("next", {
                    disabled: currentPage === totalPages,
                })}
                nextIcon="icon-chevron-right"
            ></PaginationLink>
        </ul>
    </footer>
);

export { PaginationFooter, PaginationSearchInput };
