import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ApiProvider } from "contexts/ApiProvider";
import { BrandingProvider } from "contexts/BrandingProvider";
import { UnhidePageProvider } from "contexts/UnhidePageProvider";
import { AuthProvider } from "react-auth-kit";
import MultiProvider from "components/MultiProvider";
import KeyboardShortcuts from "components/KeyboardShortcuts";
import { PopupProvider } from "contexts/PopupProvider";
import { enableMapSet } from "immer";
import { UserAnalyticsProvider } from "contexts/UserAnalyticsProvider";
import WaitForInitialization from "components/WaitForInitialization";
import { ComplexApiDataProvider } from "contexts/ComplexApiDataProvider";
import * as Sentry from "@sentry/react";
import {
    builderRoutes,
    authenticationRoutes,
    storeRoutes,
    otherRoutes,
    underwriterRoutes,
} from "pages/routes";

enableMapSet();

const preRouterProviders = [
    <AuthProvider
        key="auth-provider"
        authType="localstorage"
        authName="_auth"
        // AuthProvider insists on having children, but the MultiProvider inserts the
        // children later, so just give it something to make the TypeScript happy in
        // the meantime
        children={undefined} // eslint-disable-line react/no-children-prop
    />,
];

const postRouterProviders = [
    <KeyboardShortcuts key="keyboard-shortcuts" />,
    <ApiProvider key="api-provider" />,
    <ComplexApiDataProvider key="complex-api-data-provider" />,
    <UserAnalyticsProvider key="user-analytics-provider" />,
    <UnhidePageProvider key="unhide-page-provider" />,
    <BrandingProvider key="branding-provider" />,
    <PopupProvider key="popup-provider" />,
];

const ERROR_HANDLING = window.joshuGlobals.SENTRY_DSN
    ? ({
          // don't show anything in the UI if there is an error.
          // If Sentry has been loaded, as it is production, then the user
          // will see the Sentry feedback popup
          Component: () => <></>,
          createBrowserRouter:
              Sentry.wrapCreateBrowserRouter(createBrowserRouter),
      } as const)
    : ({
          Component: () => (
              <>
                  An error occurred, see the developer console for more
                  information.
              </>
          ),
          createBrowserRouter,
      } as const);

const App = () => (
    <MultiProvider providers={preRouterProviders}>
        <RouterProvider
            key="router-provider"
            router={ERROR_HANDLING.createBrowserRouter([
                {
                    element: (
                        <MultiProvider providers={postRouterProviders}>
                            <WaitForInitialization />
                        </MultiProvider>
                    ),
                    errorElement: <ERROR_HANDLING.Component />,
                    children: [
                        ...builderRoutes,
                        ...underwriterRoutes,
                        ...storeRoutes,
                        ...authenticationRoutes,
                        ...otherRoutes,
                    ],
                },
            ])}
        />
    </MultiProvider>
);

export default App;
