import { Footer, MainPane } from "components/MainPane";
import NotAnchor from "components/NotAnchor";
import { Page, usePage } from "components/Page";
import { ConfirmationPopup } from "components/Popup";
import ExtraPane from "components/extra-panes/ExtraPane";
import { usePopup } from "contexts/PopupProvider";
import { FC, useEffect, useRef, useState } from "react";
import {
    Submission,
    SubmissionCodeValueAndRank,
    SubmissionStatus,
} from "@joshuins/insurance";
import { useApi } from "contexts/ApiProvider";
import {
    getJoValueType,
    getRawValueFromJoValue,
} from "utils/jo-types-and-values";
import { useCreateSubmissionWizard } from "pages/components/use-create-submission-wizard";

const getSubmissionDataChecks = (
    submissionData: SubmissionCodeValueAndRank[]
) => {
    return submissionData.filter((item) => item.code.match("^(check)+.[^.]+$"));
};

const ScreeningExtraPane: FC = () => (
    <ExtraPane>
        <h3>
            <i aria-hidden="true" className="icon-help" /> OFAC Screening
        </h3>
        <p>
            You are seeing this page because the details you provided for the
            insured were flagged by one of our clearance services. Please
            contact us if you wish to determine the precise reason for this.
        </p>
        <p>
            It is possible to override this by clicking the &quot;Validate
            Screening&quot; button.
        </p>
    </ExtraPane>
);

const ApproveInsuredPopup = () => {
    const { sdkInsurance } = useApi();
    const { element } = usePage();
    const submission = element as unknown as Submission | undefined;
    const newSubmissionWizardProceed = useCreateSubmissionWizard();

    const onSubmit = async () => {
        if (!submission) {
            return;
        }
        const submissionData = await sdkInsurance.getSubmissionData({
            id: submission.id,
        });
        const data = [];
        for (const item of getSubmissionDataChecks(submissionData)) {
            if (getRawValueFromJoValue(item.value) === false) {
                data.push({
                    code: item.code,
                    value: { V1: { Plain: { Boolean: true } } },
                    asset_idx: 0,
                });
            }
        }
        await sdkInsurance.updateSubmissionData({
            id: submission.id,
            CreateSubmissionData: {
                data,
            },
        });
        await sdkInsurance.updateSubmission({
            id: submission.id,
            UpdateSubmission: { status: SubmissionStatus.Incomplete },
        });
        newSubmissionWizardProceed("screening", {
            submissionId: submission.id,
            bypassScreening: true,
        });
    };

    return (
        <ConfirmationPopup
            name="approve-insured"
            submitText="Approve"
            onSubmit={onSubmit}
        >
            <header>
                <h2>Are you sure?</h2>
                <p className="size-14">
                    This will bypass screening and approve the insured.
                </p>
            </header>
        </ConfirmationPopup>
    );
};

const Main: FC = () => {
    const { sdkInsurance } = useApi();
    const { openPopup } = usePopup();
    const { element } = usePage();
    const submission = element as unknown as Submission;
    const [insuredName, setInsuredName] = useState<string>();

    const newSubmissionWizardProceed = useCreateSubmissionWizard();
    const previouslyUnmounted = useRef(false);

    useEffect(() => {
        const getInsuredName = async () => {
            if (previouslyUnmounted.current) {
                return;
            }
            sdkInsurance.getSubmissionWhenReady(submission.id);
            const parsedSubmissionData =
                await sdkInsurance.getParsedSubmissionData(submission.id);

            const insuredNameJoValue =
                parsedSubmissionData.assets["0"].insuredItems["name"];
            setInsuredName(
                insuredNameJoValue &&
                    getJoValueType(insuredNameJoValue) === "Text"
                    ? (getRawValueFromJoValue(insuredNameJoValue) as string)
                    : ""
            );
        };
        getInsuredName();
    }, [newSubmissionWizardProceed, sdkInsurance, submission.id]);

    if (
        !submission ||
        !submission.status ||
        !(submission.status === SubmissionStatus.Blocked)
    ) {
        return <></>;
    }

    return (
        <MainPane
            title={insuredName}
            titlePill={{ color: "wine", text: "Failed Clearance" }}
            layoutConfig={{ mainLayout: "centerAndWide" }}
        >
            <div className="module-success inline">
                <h2>
                    <i className="icon-triangle" /> Submission Blocked
                </h2>
                <p>
                    We&apos;re sorry, but this insured did not pass our
                    clearance screening
                </p>
            </div>
            <Footer>
                <p className="link-btn">
                    <NotAnchor
                        onClick={() => {
                            openPopup("approve-insured");
                        }}
                    >
                        <i aria-hidden="true" className="icon-plus-circle" />{" "}
                        Validate Screening
                    </NotAnchor>
                </p>
            </Footer>
        </MainPane>
    );
};

const ScreeningPage: FC = () => (
    <Page>
        <Main />
        <ScreeningExtraPane />
        <ApproveInsuredPopup />
    </Page>
);

export default ScreeningPage;
