import ChecklistExtraPane from "components/extra-panes/ChecklistExtraPane";
import { usePage } from "components/Page";
import type { Store } from "@joshuins/builder";

const StoreCheckList = () => {
    const { element } = usePage();
    const store = element as unknown as Store | undefined;

    if (!store) {
        return <></>;
    }

    return (
        <ChecklistExtraPane
            associatedId={store.id}
            checklistType="store"
            title="Store Checklist"
            description={
                "Follow these steps to configure a store. " +
                'Click "Help Center" for videos and articles to learn more. ' +
                "When you are finished, check the step as complete."
            }
            tags={[
                {
                    tag: "ReviewSettings",
                    title: "Review Settings",
                    url: "https://help.joshu.insure/en/articles/6390519-store-review-settings",
                },
                {
                    tag: "CustomizeTheme",
                    title: "Customize Theme",
                    url: "https://help.joshu.insure/en/articles/6390532-store-customize-theme",
                },
                {
                    tag: "AddProducts",
                    title: "Add Products",
                    url: "https://help.joshu.insure/en/articles/6390537-store-add-products",
                },
                {
                    tag: "InvitePeople",
                    title: "Invite People",
                    url: "https://help.joshu.insure/en/articles/6390540-store-invite-people",
                },
            ]}
        />
    );
};

export default StoreCheckList;
