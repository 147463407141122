import { asyncMap } from "modern-async";
import {
    InsuranceApi as InsuranceApi_,
    Submission,
    SubmissionFlow,
    SubmissionStatus,
    Quote,
    QuoteStatus,
    ProductVersion,
    QuoteCodeAndValue,
} from "@joshuins/insurance";
import { unpaginate, unpaginateWithMultiParams } from ".";
import { retry } from "utils/retry";
import compact from "lodash/compact";
import groupBy from "lodash/groupBy";
import keyBy from "lodash/keyBy";
import maxBy from "lodash/maxBy";
import SubmissionData, { SubmissionDataItem } from "utils/submission-data";
import { ParsedQuote, quoteToShowFromVariations } from "utils/quote";
import { isBinderActive, isPolicy, isQuote } from "utils/policies";
import urlJoin from "url-join";
import { User, UserAccountRole } from "@joshuins/auth";

class InsuranceApi extends InsuranceApi_ {
    public getAllSubmissionsWithoutQuotes = async (user: User) => {
        const allSubmissions = await unpaginate(this.allSubmissions, {});
        // TODO SHIMI- replace with new lists API, filter out INDICATION
        const quotes = await unpaginate(this.allQuotes, {});
        const quoteBySubmissionIds = keyBy(quotes, "submission_id");
        const filteredSubmissions = allSubmissions
            .filter((submission: Submission) => submission.bound_at === null)
            .filter((submission) => submission.flow === SubmissionFlow.New)
            .filter(
                (submission) =>
                    quoteBySubmissionIds[submission.id] === undefined
            )
            .filter((submission) => {
                switch (user.role) {
                    case UserAccountRole.Admin:
                    case UserAccountRole.Underwriter:
                        return (
                            submission.store_id === null ||
                            submission.status !== SubmissionStatus.Incomplete
                        );
                    case UserAccountRole.Broker:
                    case UserAccountRole.Insured:
                        return submission.store_id !== null;
                }
            });

        return filteredSubmissions.map((submission) => ({
            submission: submission,
        }));
    };

    public getAllQuotesWithoutPolicies = async (inStatus?: QuoteStatus[]) => {
        // TODO SHIMI- replace with new lists API
        const allQuotes = inStatus
            ? await unpaginateWithMultiParams(this.allQuotes, {
                  status: inStatus,
              })
            : await unpaginate(this.allQuotes, {});

        const groupedQuotesBySubmissionId = groupBy(allQuotes, "submission_id");
        const quotesBySubmissionId = Object.entries(
            groupedQuotesBySubmissionId
        ).map(([submissionId, quotes]) => ({
            submissionId: submissionId,
            quote: quoteToShowFromVariations(quotes),
        }));

        const promises = quotesBySubmissionId.map(({ quote }) => {
            return (async () => {
                const submission = await this.getSubmission({
                    id: quote.submission_id,
                });
                if (
                    submission.flow !== SubmissionFlow.New ||
                    submission.status === SubmissionStatus.Incomplete
                ) {
                    return undefined;
                }
                if (isQuote(quote)) {
                    return { quote, submission };
                }
            })();
        });

        const filteredQuotes = await Promise.all(promises);
        return compact(filteredQuotes);
    };

    public getAllPolicies = async (inStatus?: QuoteStatus[]) => {
        // TODO SHIMI- replace with new lists API
        const allPolicies = inStatus
            ? await unpaginateWithMultiParams(this.allQuotes, {
                  status: inStatus,
              })
            : await unpaginate(this.allQuotes, {});

        return compact(
            await asyncMap(
                allPolicies,
                async (quote) => {
                    const submission = await this.getSubmission({
                        id: quote.submission_id,
                    });
                    // const policy = await this.getPolicy({
                    //     id: submission.policy_id,
                    // });
                    // const quoteData = await this.getQuoteData({ id: quote.id }).filter;
                    // we want to show PolicyPending if they are canceled, else (endorsements policies) filter them out
                    // if (
                    //     quote.status === QuoteStatus.PolicyPending &&
                    //     !policy.cancel_expiration_date
                    // ) {
                    //     return;
                    // }
                    return { quote, submission };
                },
                Number.POSITIVE_INFINITY
            )
        );
    };

    public getAllRenewalSubmissionsList = async () => {
        const authorizedSubmissions = (
            await unpaginate(this.allSubmissions, {})
        ).flat();
        const filteredSubmissions = authorizedSubmissions.filter(
            (submission: Submission) =>
                submission.bound_at === null &&
                submission.flow === SubmissionFlow.Renewal &&
                submission.status === SubmissionStatus.Incomplete
        );

        return filteredSubmissions.map((submission) => ({
            submission: submission,
        }));
    };

    public getAllPendingEndorsementsList = async () => {
        const authorizedSubmissions = (
            await unpaginate(this.allSubmissions, {})
        ).flat();

        const filteredSubmissions = authorizedSubmissions.filter(
            (submission: Submission) =>
                submission.flow === SubmissionFlow.Endorsement
        );

        const submissionsById = keyBy(filteredSubmissions, "id");

        const submissionsQuotes = await unpaginateWithMultiParams(
            // TODO SHIMI- replace with new lists API, filter out INDICATION
            this.allQuotes,
            {
                submission_id: filteredSubmissions.map(
                    (filteredSubmission) => filteredSubmission.id
                ),
            }
        );

        const filteredQuotes = submissionsQuotes.filter(
            (quote) =>
                quote.status === QuoteStatus.QuotePending ||
                quote.status === QuoteStatus.QuoteStoreEdit
        );

        const quotesBySubmissionId = groupBy(filteredQuotes, "submission_id");

        return Object.entries(quotesBySubmissionId).map(
            ([submissionId, quotes]) => ({
                submission: submissionsById[submissionId],
                quote: maxBy(quotes, "id") as Quote,
            })
        );
    };

    public generateSubmissionLink = async (submission: Submission) => {
        if (
            submission.status === SubmissionStatus.Incomplete ||
            submission.status === SubmissionStatus.Pending ||
            submission.status === SubmissionStatus.Blocked ||
            submission.status === SubmissionStatus.Error
        ) {
            return urlJoin("/", "submissions", submission.id.toString());
        } else {
            const quotes = await this.allQuotes({
                submission_id: submission.id,
            });
            let quote = quotes.items.find(
                (quote_) => isBinderActive(quote_) || isPolicy(quote_)
            );
            if (!quote && quotes.items.length > 0) {
                quote = quotes.items.pop();
            }
            if (quote) {
                return urlJoin("/", "quotes", quote.id.toString());
            }
        }
    };

    public getQuoteWhenReady = (quoteId: number) =>
        retry(
            () =>
                this.getQuote({
                    id: quoteId,
                }),
            (quote) => !quote.processing,
            2000
        );

    public getPolicyWhenReady = (policyId: number) =>
        retry(
            () =>
                this.getQuote({
                    id: policyId,
                }),
            (policy) => !policy.processing,
            2000
        );

    public getSubmissionWhenReady = (submissionId: number) =>
        retry(
            () =>
                this.getSubmission({
                    id: submissionId,
                }),
            (submission) => !submission.processing,
            2000
        );

    public getParsedSubmissionData = async (submissionId: number) => {
        const submissionCodesValues = await this.getSubmissionData({
            id: submissionId,
        });
        const assetsCodesValues = await this.getAssetData({
            id: submissionId,
        });
        const submissionDataItems: SubmissionDataItem[] =
            submissionCodesValues.map((item) => ({
                asset_idx: 0,
                ...item,
            }));
        const assetsDataItems: SubmissionDataItem[] =
            assetsCodesValues.data.map((item) => ({
                ...item,
            }));
        return new SubmissionData(submissionDataItems.concat(assetsDataItems));
    };

    public getParsedQuote = async (
        productVersion: ProductVersion,
        quote: Quote
    ) => {
        let quoteCodesAndValues: QuoteCodeAndValue[] = [];
        // TODO: If the user is a Broker, and the quote is in a 'Pending' state, he will be restricted from
        // seeing quote data (right now by returning 404 from the BE).
        // need to find a better solution than this try catch.
        try {
            quoteCodesAndValues = await this.getQuoteData({ id: quote.id });
        } catch (error) {
            // Do nothing.
        }

        return new ParsedQuote(quoteCodesAndValues, productVersion.schema.spec);
    };
}

export default InsuranceApi;
