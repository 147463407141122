import { AlertCategory, usePage } from "components/Page";
import { FC, useCallback, useEffect, useState } from "react";
import { ConfirmationPopup } from "components/Popup";
import { TextField } from "@mui/material";
import { User, UserAccountRole } from "@joshuins/system";
import { useApi } from "contexts/ApiProvider";
import { Submission } from "@joshuins/insurance";
import { useApplication } from "./ApplicationProvider";
import { useAuthUser } from "react-auth-kit";
import MuiAutocomplete from "@mui/material/Autocomplete";
import { usePopup } from "contexts/PopupProvider";
import debounce from "lodash/debounce";
import { isStoreUser } from "pages/components/util";

const ASSIGN_BROKER_POPUP = "assign-broker" as const;

const AssignBrokerPopup: FC = () => {
    const authUser = useAuthUser();
    const currentUser = authUser() as User;
    const { isPopupOpen } = usePopup();
    const { addAlertMessages } = usePage();
    const { sdkSystem, sdkInsurance, sdkBuilder } = useApi();
    const { element, tryCatchAndRaiseError } = usePage();
    const submission = element as unknown as Submission | undefined;
    const [brokers, setBrokers] = useState<User[]>();
    const [selectedBrokerId, setSelectedBrokerId] = useState<number>();
    const {
        applicationDispatch,
        applicationState: { productVersion },
    } = useApplication();

    const getFilteredBroker = async (substring: string) => {
        // need to make 3 differenet API calls, because we want
        // OR operatore, rather than one call the makes an AND by default.
        let firstName: string | undefined = undefined;
        let lastName: string | undefined = undefined;
        if (substring.includes(" ")) {
            firstName = substring.split(" ")[0];
            lastName = substring.split(" ")[1];
        }

        const emailFilter = await sdkSystem.allUsers({
            role: UserAccountRole.Broker,
            email: substring,
            _per_page: 15,
        });
        const firstNameFilter = await sdkSystem.allUsers({
            role: UserAccountRole.Broker,
            first_name: firstName ?? substring,
            _per_page: 15,
        });
        const lastNameFilter = await sdkSystem.allUsers({
            role: UserAccountRole.Broker,
            last_name: lastName ?? substring,
            _per_page: 15,
        });
        const allBrokersResults = emailFilter.items
            .concat(firstNameFilter.items)
            .concat(lastNameFilter.items);
        const uniqueBrokers = [
            ...new Map(
                allBrokersResults.map((item) => [item["id"], item])
            ).values(),
        ];
        setBrokers(uniqueBrokers);
    };
    const getFilteredBrokerDelayed = debounce(getFilteredBroker, 300);

    useEffect(() => {
        if (!isPopupOpen(ASSIGN_BROKER_POPUP)) {
            return;
        }
        const getBrokers = async () => {
            if (
                currentUser.role === UserAccountRole.Underwriter ||
                currentUser.role === UserAccountRole.Admin
            ) {
                // const storeProducts = await unpaginate(
                //     sdkBuilder.allStoreProducts,
                //     {
                //         product_id: productVersion.product_id,
                //     }
                // );
                // const currentStoreIds = storeProducts.map(
                //     ({ store_id }) => store_id
                // );
                // const storeUsers = await unpaginateWithMultiParams(
                //     sdkBuilder.allStoreUsers,
                //     {
                //         store_id: currentStoreIds,
                //         status: StoreUserStatus.Approved,
                //     }
                // );
                // const authorizedUsersIds = storeUsers.map(
                //     ({ user_id }) => user_id
                // );
                // const allActiveBrokers = await unpaginate(sdkSystem.allUsers, {
                //     role: UserAccountRole.Broker,
                //     account_status: AccountStatus.Active,
                // });
                // const authorizedBrokers = allActiveBrokers.filter((broker) =>
                //     authorizedUsersIds.includes(broker.id)
                // );

                const authorizedBrokers = await sdkSystem.allUsers({
                    role: UserAccountRole.Broker,
                    _per_page: 10,
                });

                setBrokers(authorizedBrokers.items);
            }
        };
        getBrokers();
    }, [
        currentUser.role,
        isPopupOpen,
        productVersion.product_id,
        sdkBuilder.allStoreProducts,
        sdkBuilder.allStoreUsers,
        sdkSystem,
    ]);

    const onSubmit = useCallback(async () => {
        if (!submission) {
            return;
        }
        if (!selectedBrokerId) {
            addAlertMessages({
                message: "Please select a broker from the suggested list.",
                category: AlertCategory.ALERT,
            });
            return;
        }
        tryCatchAndRaiseError(async () => {
            await sdkInsurance.updateSubmission({
                id: submission.id,
                UpdateSubmission: {
                    user_id: selectedBrokerId,
                },
            });
            const broker = await sdkSystem.getUser({ id: selectedBrokerId });
            applicationDispatch({
                action: "SetSubmissionUser",
                submissionUser: broker,
            });
        });
    }, [
        addAlertMessages,
        applicationDispatch,
        sdkInsurance,
        sdkSystem,
        selectedBrokerId,
        submission,
        tryCatchAndRaiseError,
    ]);

    if (isStoreUser(currentUser)) {
        return;
    }

    return (
        <ConfirmationPopup
            name={ASSIGN_BROKER_POPUP}
            onSubmit={onSubmit}
            submitText="Assign Broker To Submission"
            mobileSubmitText="Assign"
        >
            <header>
                <h2>Add Broker</h2>
            </header>
            <p>
                Change who is listed as Broker of Record for this submission.
                {/* TODO: this text is from Figma, make sure it is still needed:
                        First choose the store where this product is
                        available and we will show you a list of brokers with
                        access to that store. */}
            </p>
            <span className="label">
                Select Broker
                {/* <NotAnchor
                    onClick={() => openOverlayPopup(0)}
                    className="text-right"
                >
                    <i aria-hidden="true" className="icon-help" />
                    <span className="hidden">More info</span>
                </NotAnchor> */}
            </span>
            <MuiAutocomplete
                renderInput={(params) => (
                    <TextField
                        onChange={(event) =>
                            getFilteredBrokerDelayed(event.target.value)
                        }
                        {...params}
                    />
                )}
                getOptionLabel={(broker) => {
                    const brokerTitle = `${broker.first_name} ${broker.last_name} (${broker.email}`;
                    return broker.external_id
                        ? `${brokerTitle}, ${broker.external_id})`
                        : `${brokerTitle})`;
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                options={brokers || []}
                onChange={(event, newValue) => {
                    setSelectedBrokerId(newValue?.id);
                }}
                className={"styled-select-menu"}
            />
        </ConfirmationPopup>
    );
};

export { AssignBrokerPopup, ASSIGN_BROKER_POPUP };
