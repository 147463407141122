import repeat from "lodash/repeat";
import trim from "lodash/trim";
import { FC, useEffect, useState } from "react";

const STARS = trim(repeat("• ", 15));

const SecretInput: FC<{
    value: string;
    showStarsWhenBlurred?: boolean;
    id?: string;
    onChange: (value: unknown) => void;
    onBlur: () => void;
}> = ({ value, showStarsWhenBlurred = true, id, onChange, onBlur }) => {
    const [value_, setValue] = useState<string>();
    const [displayedValue, setDisplayedValue] = useState<string | undefined>(
        ""
    );

    useEffect(() => {
        setValue(value);
        setDisplayedValue(value || (showStarsWhenBlurred ? STARS : ""));
    }, [showStarsWhenBlurred, value]);

    return (
        <input
            type="text"
            id={id}
            value={displayedValue}
            onChange={(event) => {
                const newValue = event.target.value;
                setValue(newValue);
                setDisplayedValue(newValue);
                onChange(newValue);
            }}
            onFocus={() => {
                setDisplayedValue(value_);
            }}
            onBlur={() => {
                setDisplayedValue(
                    value_ || (showStarsWhenBlurred ? STARS : "")
                );
                onBlur();
            }}
        />
    );
};

export default SecretInput;
