import { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useApi } from "contexts/ApiProvider";
import { useBranding } from "contexts/BrandingProvider";

import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";

import axios from "axios";
import { hideLoader, showLoader } from "paul/native-dom-manipulation";
import { getMessageFromAxiosError } from "utils/axios-extras";

const passwordForgotSchema = z.object({
    email: z.string().email(),
});

type PasswordForgotType = z.infer<typeof passwordForgotSchema>;

const PasswordForgotPage = () => {
    const captchaRef = useRef<ReCAPTCHA>(null);
    const { navigate } = useBranding();

    const [recaptchaToken, setRecaptchaToken] = useState<
        string | null | undefined
    >(null);
    const [recaptchaKey, setRecaptchaKey] = useState<string | null>(null);

    const { sdkAuthForProcess } = useApi();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<PasswordForgotType>({
        resolver: zodResolver(passwordForgotSchema),
        defaultValues: {
            email: "",
        },
    });

    const [submissionFailedMessage, setSubmissionFailedMessage] =
        useState<string>();

    useEffect(() => {
        const setupRecaptcha = async () => {
            try {
                const { recaptcha_public_key } =
                    await sdkAuthForProcess.passwordResetParams();
                setRecaptchaKey(recaptcha_public_key);
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    setRecaptchaKey(null);
                } else {
                    throw error;
                }
            }
        };
        setupRecaptcha();
    }, [sdkAuthForProcess]);

    const setToken = (token: string | null): void => {
        setRecaptchaToken(token);
    };

    const onSubmit = async (data: PasswordForgotType) => {
        showLoader();
        const email = data.email;

        let recaptcha_response = recaptchaToken;
        if (!recaptcha_response) {
            recaptcha_response = await captchaRef.current?.executeAsync();
        }

        if (recaptcha_response) {
            try {
                await sdkAuthForProcess.passwordResetStart({
                    StartPasswordResetRequest: {
                        email,
                        recaptcha_response,
                    },
                });
                setRecaptchaToken(null);
                captchaRef.current?.reset();
                navigate("/auth/password-check-email");
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    setSubmissionFailedMessage(getMessageFromAxiosError(error));
                    hideLoader();
                } else {
                    throw error;
                }
            }
        }
    };

    return (
        <main id="content">
            <form className="form-aside" onSubmit={handleSubmit(onSubmit)}>
                <header>
                    <h2 className="m30">Forgot your password?</h2>
                    <p>
                        Enter the address used for your Joshu account and
                        we&apos;ll email a reset link.
                    </p>
                </header>
                <p className="m30">
                    <label htmlFor="fai">Email Address</label>
                    <input
                        type="email"
                        id="fai"
                        autoComplete="email"
                        {...register("email")}
                    />
                    {errors.email && (
                        <label id="fai-error" className="error" htmlFor="fai">
                            {errors.email.message}
                        </label>
                    )}
                </p>
                {recaptchaKey && (
                    <ReCAPTCHA
                        sitekey={recaptchaKey}
                        ref={captchaRef}
                        size="invisible"
                        onChange={setToken}
                    />
                )}
                {submissionFailedMessage && (
                    <label id="failed" className="error">
                        {submissionFailedMessage}
                    </label>
                )}
                <p className="submit">
                    <button type="submit">Send</button>
                </p>
            </form>
        </main>
    );
};

export default PasswordForgotPage;
