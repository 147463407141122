import {
    createContext,
    useContext,
    FC,
    PropsWithChildren,
    useCallback,
    useEffect,
    useState,
    useMemo,
    useRef,
} from "react";
import {
    useSignIn,
    useSignOut,
    useAuthHeader,
    useIsAuthenticated,
} from "react-auth-kit";
import axios from "axios";
import AuthApi from "components/sdk/auth";
import BuilderApi from "components/sdk/builder";
import BrandingApi from "components/sdk/branding";
import InsuranceApi from "components/sdk/insurance";
import SystemApi from "components/sdk/system";

import type { User, BearerTokens } from "@joshuins/auth";
import { axiosInstanceFactory } from "utils/axios";
import { getMessageFromAxiosError } from "utils/axios-extras";

interface AuthDataInterface extends BearerTokens {
    me: User;
}

interface ApiProviderInterface {
    apiAuthProcess: {
        login: (
            email: string,
            password: string,
            nextUrl?: string
        ) => Promise<void>;
        ssoLogin: (
            providerSlug: string,
            state: string,
            code: string
        ) => Promise<void>;
        logout: (nextUrl: string | undefined) => void;
        initializeFromStorageAttempted: boolean;
        authProcessErrorMessage?: string;
        loggedIn: boolean;
    };
    sdkAuth: AuthApi;
    sdkAuthForProcess: AuthApi;
    sdkBranding: BrandingApi;
    sdkBuilder: BuilderApi;
    sdkInsurance: InsuranceApi;
    sdkSystem: SystemApi;
}

const ApiContext = createContext<ApiProviderInterface | undefined>(undefined);

const useApi = () => {
    const context = useContext(ApiContext);

    if (context === undefined) {
        throw Error("useApi must be used inside a ApiProvider context");
    }

    return context;
};

const ApiProvider: FC<PropsWithChildren> = ({ children }) => {
    const rakSignIn = useSignIn();
    const rakSignOut = useSignOut();
    const rakAuthHeader = useAuthHeader();
    const [accessToken, setAccessToken] = useState<string>();
    const rakIsAuthenticated = useIsAuthenticated();
    const [initializeFromStorageAttempted, setInitializeFromStorageAttempted] =
        useState(false);
    const initializeFromStorageStarted = useRef(false);
    const [loggedIn, setLoggedIn] = useState<boolean>(false);
    const [authProcessStep, setAuthProcessStep] = useState<
        | "login-1"
        | "login-2"
        | "login-3"
        | "login-4"
        | "logout-1"
        | "logout-2"
        | "logout-3"
    >();
    const [loginAuthData, setLoginAuthData] = useState<AuthDataInterface>();
    const [nextUrl, setNextUrl] = useState<string>();
    const [authProcessErrorMessage, setAuthProcessErrorMessage] =
        useState<string>();
    const [loginProcessEndCallback, setLoginProcessEndCallback] = useState<
        | {
              callback: () => void;
          }
        | undefined
    >(undefined);

    const sdkAuth = useMemo(
        () => new AuthApi(undefined, "", axiosInstanceFactory({ accessToken })),
        [accessToken]
    );
    const sdkAuthForProcess = useMemo(
        () =>
            new AuthApi(
                undefined,
                "",
                axiosInstanceFactory({ accessToken, logoutOn401: false })
            ),
        [accessToken]
    );
    const sdkBranding = useMemo(
        () =>
            new BrandingApi(
                undefined,
                "",
                axiosInstanceFactory({ accessToken })
            ),
        [accessToken]
    );
    const sdkBuilder = useMemo(
        () =>
            new BuilderApi(
                undefined,
                "",
                axiosInstanceFactory({ accessToken })
            ),
        [accessToken]
    );
    const sdkInsurance = useMemo(
        () =>
            new InsuranceApi(
                undefined,
                "",
                axiosInstanceFactory({ accessToken })
            ),
        [accessToken]
    );
    const sdkSystem = useMemo(
        () =>
            new SystemApi(undefined, "", axiosInstanceFactory({ accessToken })),
        [accessToken]
    );

    /******************************************************************************************
     * The Login and Logout chained useEffects
     * Performs login and logouts according to set a defined steps
     * ****************************************************************************************/

    const logoutStep1 = useCallback(() => {
        rakSignOut();
        // set the loggedIn flag to false as early as possible, because if the logout process doesn't
        // end properly we still want to specify that we're not logged in - since a half completed logout
        // process means we're not logged in
        setLoggedIn(false);
        setAuthProcessStep("logout-2");
    }, [rakSignOut]);

    const logoutStep2 = useCallback(async () => {
        const authHeader_ = rakAuthHeader();
        if (authHeader_.length > 0) {
            throw Error("authHeader unexpectedly has a value");
        }
        setAccessToken(undefined);
        setAuthProcessStep("logout-3");
    }, [rakAuthHeader, setAccessToken]);

    const logoutStep3 = useCallback(async () => {
        if (loginAuthData) {
            setAuthProcessStep("login-2");
        } else {
            setAuthProcessStep(undefined);
            if (nextUrl) {
                window.location.href = nextUrl;
            } else if (loginProcessEndCallback) {
                loginProcessEndCallback.callback();
            }
        }
    }, [loginAuthData, nextUrl, loginProcessEndCallback]);

    const loginStep1 = useCallback(() => {
        if (rakIsAuthenticated()) {
            setAuthProcessStep("logout-1");
        } else {
            setAuthProcessStep("login-2");
        }
    }, [rakIsAuthenticated]);

    const loginStep2 = useCallback(() => {
        if (loginAuthData === undefined) {
            throw Error("loginAuthData has not been set but is needed");
        }
        const { access_token, token_type, expires_in, me } = loginAuthData;
        rakSignIn({
            token: access_token,
            tokenType: token_type,
            expiresIn: expires_in,
            authState: me,
        });
        setAuthProcessStep("login-3");
    }, [loginAuthData, rakSignIn]);

    const loginStep3 = useCallback(async () => {
        const authHeader_ = rakAuthHeader();
        if (authHeader_.length > 0) {
            setAccessToken(authHeader_);
        } else if (loginAuthData) {
            throw new Error("authHeader is unexpectedly empty");
        }
        setAuthProcessStep("login-4");
    }, [rakAuthHeader, loginAuthData]);

    const loginStep4 = useCallback(async () => {
        // const authHeader_ = rakAuthHeader();
        let loginSuccess = false;
        if (accessToken) {
            try {
                // check if logged in through normal API by arbitrarily calling .me()
                await sdkAuthForProcess.me();
                loginSuccess = true;
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    if (error.response?.status !== 401) {
                        setAuthProcessErrorMessage("Error");
                    }
                } else {
                    throw error;
                }
            }
        }

        if (loginSuccess) {
            if (nextUrl) {
                window.location.href = nextUrl;
            }
            setLoggedIn(true);
            setAuthProcessStep(undefined);
            if (loginProcessEndCallback) {
                loginProcessEndCallback.callback();
            }
        } else {
            setAuthProcessStep("logout-1");
        }
    }, [accessToken, sdkAuthForProcess, nextUrl, loginProcessEndCallback]);

    useEffect(() => {
        switch (authProcessStep) {
            case "logout-1":
                logoutStep1();
                break;
            case "logout-2":
                logoutStep2();
                break;
            case "logout-3":
                logoutStep3();
                break;
            case "login-1":
                loginStep1();
                break;
            case "login-2":
                loginStep2();
                break;
            case "login-3":
                loginStep3();
                break;
            case "login-4":
                loginStep4();
                break;
        }
    }, [
        authProcessStep,
        logoutStep1,
        logoutStep2,
        logoutStep3,
        loginStep1,
        loginStep2,
        loginStep3,
        loginStep4,
    ]);

    /******************************************************************************************
     * The Initialization useEffect
     * Checks if user is logged in and initializes api clients. This is for refreshing a page
     * or visiting it again after previously logging in
     * ****************************************************************************************/

    const setInitializeFromStorageAttemptedToTrue = useMemo(
        // We have to return the callback in an object. If we return the callback directly, then
        // for some reason useMemo calls that callback immediately
        () => ({
            callback: () => {
                setInitializeFromStorageAttempted(true);
            },
        }),
        []
    );

    useEffect(() => {
        if (
            initializeFromStorageStarted.current ||
            initializeFromStorageAttempted
        ) {
            return;
        }
        setNextUrl(undefined);
        setLoginAuthData(undefined);
        setAuthProcessStep("login-3");
        setLoginProcessEndCallback(setInitializeFromStorageAttemptedToTrue);
        return () => {
            initializeFromStorageStarted.current = true;
        };
    }, [
        rakAuthHeader,
        initializeFromStorageAttempted,
        setInitializeFromStorageAttemptedToTrue,
        initializeFromStorageStarted,
    ]);

    /******************************************************************************************
     * Login functions
     * These functions are the functions passed down in the provider context to allow components
     * to start the login process
     * ****************************************************************************************/

    const login = useCallback<
        (
            email: string,
            password: string,
            nextUrl: string | undefined
        ) => Promise<void>
    >(
        async (email, password, nextUrl) => {
            let loginResponse;
            try {
                loginResponse = await sdkAuthForProcess.login(email, password);
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    setAuthProcessErrorMessage(getMessageFromAxiosError(error));
                } else {
                    throw error;
                }
            }

            if (loginResponse) {
                const {
                    token: { access_token, token_type, expires_in },
                    me,
                } = loginResponse;

                setLoginAuthData({
                    access_token,
                    token_type,
                    expires_in,
                    me: me.identity.User.user,
                });
                setNextUrl(nextUrl);
                setLoginProcessEndCallback(undefined);
                setAuthProcessStep("login-1");
            }
        },
        [sdkAuthForProcess]
    );

    const ssoLogin = useCallback(
        async (providerSlug: string, state: string, code: string) => {
            let ssoLoginResponse;
            try {
                ssoLoginResponse = await sdkAuthForProcess.ssoLogin(
                    providerSlug,
                    state,
                    code
                );
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    setAuthProcessErrorMessage(getMessageFromAxiosError(error));
                } else {
                    throw error;
                }
            }

            if (ssoLoginResponse) {
                const {
                    nextUrl,
                    token: { access_token, token_type, expires_in },
                    me,
                } = ssoLoginResponse;

                setLoginAuthData({
                    access_token,
                    token_type,
                    expires_in,
                    me: me.identity.User.user,
                });
                setNextUrl(nextUrl ?? "/");
                setLoginProcessEndCallback(undefined);
                setAuthProcessStep("login-1");
            }
        },
        [sdkAuthForProcess]
    );

    const logout = useCallback<(nextUrl: string | undefined) => void>(
        (nextUrl) => {
            setLoginAuthData(undefined);
            setNextUrl(nextUrl);
            setAuthProcessStep("logout-1");
        },
        []
    );

    const apiAuthProcess = useMemo(() => {
        return {
            login,
            ssoLogin,
            logout: logout,
            initializeFromStorageAttempted,
            authProcessErrorMessage,
            loggedIn,
        };
    }, [
        login,
        ssoLogin,
        logout,
        initializeFromStorageAttempted,
        authProcessErrorMessage,
        loggedIn,
    ]);

    return (
        <ApiContext.Provider
            value={{
                apiAuthProcess,
                sdkAuth,
                sdkAuthForProcess,
                sdkBranding,
                sdkBuilder,
                sdkInsurance,
                sdkSystem,
            }}
        >
            {children}
        </ApiContext.Provider>
    );
};

export { useApi, ApiProvider };
export type { ApiProviderInterface };
