import { FC, useEffect, useRef } from "react";

const MAP_ZOOM_SIZE = 17 as const;

interface GoogleMapsMapProps {
    id: string;
    latLang?: google.maps.LatLng;
    readOnly?: boolean;
    onClick?: (latLng: google.maps.LatLng) => void;
}

const GoogleMapsMap: FC<GoogleMapsMapProps> = ({ id, latLang, readOnly = false, onClick }) => {
    const mapRef = useRef<google.maps.Map>();
    const listenerRef = useRef<google.maps.MapsEventListener>();
    const markerRef = useRef<google.maps.marker.AdvancedMarkerElement>();
    const internalPlaceChangeRef = useRef<boolean>(false);

    useEffect(() => {
        (async () => {
            if (mapRef.current === undefined) {
                const { Map } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
                mapRef.current = new Map(
                    document.getElementById(id) as HTMLElement,
                    {
                        mapId: `_internal_${id}`,
                        zoom: MAP_ZOOM_SIZE,
                        mapTypeId: "hybrid",
                        controlSize: 20,
                        center: latLang
                    }
                );

                listenerRef.current = mapRef.current.addListener("click",
                    (mapsMouseEvent: { latLng: google.maps.LatLng }) => {
                        if (readOnly) {
                            return;
                        }
                        internalPlaceChangeRef.current = true;
                        if (mapRef.current) {
                            mapRef.current.setCenter(mapsMouseEvent.latLng);
                        }
                        if (markerRef.current) {
                            markerRef.current.position = mapsMouseEvent.latLng;
                        }
                        if (onClick) {
                            onClick(mapsMouseEvent.latLng);
                        }
                    }
                );

            } else {
                if (latLang) {
                    if (internalPlaceChangeRef.current) {
                        internalPlaceChangeRef.current = false;
                    } else {
                        mapRef.current.setZoom(MAP_ZOOM_SIZE);
                    }
                    mapRef.current.setCenter(latLang);
                }
            }

            if (latLang) {
                if (markerRef.current === undefined) {
                    const { AdvancedMarkerElement } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;
                    markerRef.current = new AdvancedMarkerElement({
                        map: mapRef.current,
                        position: latLang
                    });
                } else {
                    markerRef.current.position = latLang;
                }
            }
        })();

    }, [id, latLang, onClick, readOnly]);

    useEffect(() => {
        return () => {
            if (mapRef.current) {
                mapRef.current = undefined;
            }
            if (listenerRef.current) {
                listenerRef.current.remove();
                listenerRef.current = undefined;
            }
            if (markerRef.current) {
                markerRef.current = undefined;
            }
        };
    }, []);

    return (
        <div className="map" id={id}
            style={{
                marginTop: "10px",
                maxWidth: "none",
                minHeight: "250px",
                display: latLang ? "block" : "none",
            }}>
        </div>
    );
};

export { GoogleMapsMap };
