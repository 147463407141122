import { useApi } from "contexts/ApiProvider";
import { AlertCategory, Page, usePage } from "components/Page";
import { FC, useCallback, useEffect, useState } from "react";
import { Footer, MainPane } from "components/MainPane";
import ExtraPane from "components/extra-panes/ExtraPane";
import NotAnchor from "components/NotAnchor";
import { MenuItem } from "components/Select";
import {
    ColorPicker,
    Select,
} from "components/ReactHookFormUncontrolledComponents";
import FormControl from "@mui/material/FormControl";
import { ConfirmationPopup, Popup } from "components/Popup";
import { z } from "zod";
import { UseFormReset } from "react-hook-form";
import { TinyColor } from "@ctrl/tinycolor";
import { usePopup } from "contexts/PopupProvider";
import { LogoUpload } from "components/LogoUpload";
import omit from "lodash/omit";

import type { Store, StoreTheme, UpdateStoreTheme } from "@joshuins/builder";
import type { Branding } from "@joshuins/branding";
import { removeNull } from "utils/object";
import { createForm } from "components/Form";
import { STORE_PATH } from "globals";
import urlJoin from "url-join";

const MORE_INFO_BACKGROUND = "more-info-background" as const;
const MORE_INFO_BUTTONS = "more-info-buttons-icons" as const;
const MORE_INFO_FONT = "more-info-display-font" as const;
const MORE_INFO_LOGO = "more-info-logo" as const;
const MORE_INFO_TEXT = "more-info-text-color" as const;
const RESET_THEME = "reset-theme" as const;

const themeSchema = z.object({
    font_titles: z.string().min(1, "This field is required"),
    color_primary: z
        .string()
        .min(1, "This field is required")
        .refine((value) => new TinyColor(value).isValid),
    bg_primary: z
        .string()
        .min(1, "This field is required")
        .refine((value) => new TinyColor(value).isValid),
    bg_primary_icon: z
        .string()
        .min(1, "This field is required")
        .refine((value) => new TinyColor(value).isValid),
    bg_primary_text: z
        .string()
        .min(1, "This field is required")
        .refine((value) => new TinyColor(value).isValid),
    bg_secondary: z
        .string()
        .min(1, "This field is required")
        .refine((value) => new TinyColor(value).isValid),
    bg_secondary_icon: z
        .string()
        .min(1, "This field is required")
        .refine((value) => new TinyColor(value).isValid),
    bg_secondary_text: z
        .string()
        .min(1, "This field is required")
        .refine((value) => new TinyColor(value).isValid),
    logo_url: z.union([z.instanceof(File), z.string()]),
});

type FormSchemaInterface<P extends z.Schema> = z.infer<P>;

const { Form, useFormReturnRef } = createForm(themeSchema);

type ThemeType = z.infer<typeof themeSchema>;

const Extra = () => (
    <ExtraPane>
        <h3>
            <i aria-hidden="true" className="icon-help" />
            Theme
        </h3>
        <p className="size-14">
            Change these default theme settings to match the brand identity you
            want for your store. Note that any store you make can have its own
            theme.
        </p>
    </ExtraPane>
);

const Main = () => {
    const { sdkBuilder, sdkBranding } = useApi();
    const { element, addAlertMessages, tryCatchAndRaiseError } = usePage();

    const { openPopup } = usePopup();

    const store = element as unknown as Store | undefined;

    const [themeId, setThemeId] = useState<number | null>();
    const [defaultTheme, setDefaultTheme] = useState<Branding>();
    const {
        formReturn: {
            reset,
            formState: { dirtyFields },
        },
        formReturnRefCallback,
    } = useFormReturnRef();

    const convertApiThemeToFormTheme = useCallback(
        async (apiTheme: StoreTheme) => {
            const tempTheme = removeNull(omit(apiTheme, ["id", "file_id"]));

            let logoUrl: string | undefined;
            if (apiTheme.file_id) {
                const { download_url } = await sdkBuilder.getFile({
                    id: apiTheme.file_id,
                });
                logoUrl = download_url;
            }

            return {
                ...omit(defaultTheme, ["logo_width", "logo_height"]),
                ...tempTheme,
                ...(logoUrl && { logo_url: logoUrl }),
            } as ThemeType;
        },
        [sdkBuilder, defaultTheme]
    );

    const onSubmit_ = useCallback(
        async (data: ThemeType) => {
            if (!store || !dirtyFields || !reset) {
                return;
            }

            const dirtyDataOnly = Object.fromEntries(
                Object.keys(dirtyFields).map((key) => [
                    key,
                    data[key as keyof ThemeType],
                ])
            );

            let storeTheme;

            const dirtyDataOnly_ = omit<UpdateStoreTheme>(dirtyDataOnly, [
                "logo_url",
            ]);
            tryCatchAndRaiseError(async () => {
                if (dirtyDataOnly.logo_url instanceof File) {
                    const id = await sdkBuilder.uploadFile(
                        dirtyDataOnly.logo_url,
                        "StoreLogo"
                    );
                    dirtyDataOnly_.file_id = id;
                }

                if (themeId) {
                    storeTheme = await sdkBuilder.updateStoreTheme({
                        id: themeId,
                        UpdateStoreTheme: dirtyDataOnly_,
                    });
                } else {
                    storeTheme = await sdkBuilder.createStoreTheme({
                        NewStoreTheme: dirtyDataOnly_,
                    });
                    await sdkBuilder.updateStore({
                        id: store.id,
                        UpdateStore: {
                            theme_id: storeTheme.id,
                        },
                    });
                }
                reset(await convertApiThemeToFormTheme(storeTheme));
                setThemeId(storeTheme.id);

                addAlertMessages({
                    message: "Store theme has been saved!",
                    category: AlertCategory.SUCCESS,
                });
            });
        },
        [
            addAlertMessages,
            convertApiThemeToFormTheme,
            dirtyFields,
            reset,
            sdkBuilder,
            store,
            themeId,
            tryCatchAndRaiseError,
        ]
    );

    const resetTheme = () => {
        if (!store || !reset) {
            return;
        }

        openPopup(RESET_THEME, {
            storeId: store.id,
            themeId,
            reset,
            setThemeId,
            defaultTheme,
        });
    };

    useEffect(() => {
        const getDefaultTheme = async () => {
            const defaultTheme = await sdkBranding.getStoreDefault();
            setDefaultTheme(defaultTheme);
        };
        getDefaultTheme();
    }, [sdkBranding]);

    useEffect(() => {
        const getStoreTheme = async () => {
            if (!store || !defaultTheme || !reset) {
                return;
            }

            const themeId_ = store.theme_id;
            let theme: ThemeType;
            if (!themeId_) {
                theme = defaultTheme;
                setThemeId(null);
            } else {
                const apiTheme = await sdkBuilder.getStoreTheme({
                    id: themeId_,
                });

                setThemeId(apiTheme.id);

                theme = await convertApiThemeToFormTheme(apiTheme);
            }
            reset(theme);
        };

        getStoreTheme();
    }, [
        sdkBuilder,
        store,
        reset,
        sdkBranding,
        convertApiThemeToFormTheme,
        defaultTheme,
    ]);

    return (
        <MainPane title="Store Theme">
            <Form
                onSubmit={onSubmit_}
                className="form-theme form-validate"
                formReturnRefCallback={formReturnRefCallback}
                defaultValues={{
                    font_titles: "",
                    bg_primary: "",
                    bg_primary_icon: "",
                    bg_primary_text: "#000000",
                    bg_secondary: "",
                    bg_secondary_icon: "",
                    bg_secondary_text: "#000000",
                    logo_url: undefined,
                }}
            >
                {({ register, control, formState: { isDirty, isValid } }) => {
                    return (
                        <>
                            <div>
                                <div>
                                    <h2 className="size-16 m10">
                                        Center Content
                                    </h2>
                                    <div className="form-box">
                                        <div className="cols">
                                            <div className="c33 div-as-p">
                                                <label htmlFor="std">
                                                    Title Display Font
                                                    <NotAnchor
                                                        onClick={() =>
                                                            openPopup(
                                                                MORE_INFO_FONT
                                                            )
                                                        }
                                                        className="text-right"
                                                    >
                                                        <i
                                                            aria-hidden="true"
                                                            className="icon-help"
                                                        />
                                                        <span className="hidden">
                                                            More info
                                                        </span>
                                                    </NotAnchor>
                                                </label>
                                                <input
                                                    type="text"
                                                    id="std"
                                                    {...register("font_titles")}
                                                />
                                            </div>
                                            <div className="c33 div-as-p">
                                                <span
                                                    className="label"
                                                    style={{
                                                        minWidth: "160px",
                                                    }}
                                                >
                                                    Main Buttons &amp; Icons
                                                    <NotAnchor
                                                        onClick={() =>
                                                            openPopup(
                                                                MORE_INFO_BUTTONS
                                                            )
                                                        }
                                                        className="text-right"
                                                    >
                                                        <i
                                                            aria-hidden="true"
                                                            className="icon-help"
                                                        />
                                                        <span className="hidden">
                                                            More info
                                                        </span>
                                                    </NotAnchor>
                                                </span>
                                                <ColorPicker
                                                    name="color_primary"
                                                    control={control}
                                                    fullWidth
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <div>
                                    <h2 className="size-16 m10">
                                        Left Sidepanel
                                    </h2>
                                    <div className="form-box">
                                        <div className="cols">
                                            <div className="c33 div-as-p">
                                                <label>
                                                    Background
                                                    <NotAnchor
                                                        onClick={() =>
                                                            openPopup(
                                                                MORE_INFO_BACKGROUND
                                                            )
                                                        }
                                                        className="text-right"
                                                    >
                                                        <i
                                                            aria-hidden="true"
                                                            className="icon-help"
                                                        />
                                                        <span className="hidden">
                                                            More info
                                                        </span>
                                                    </NotAnchor>
                                                </label>
                                                <ColorPicker
                                                    name="bg_primary"
                                                    control={control}
                                                    fullWidth
                                                />
                                            </div>
                                            <div className="c33 div-as-p">
                                                <label>
                                                    Buttons &amp; Icons
                                                    <NotAnchor
                                                        onClick={() =>
                                                            openPopup(
                                                                MORE_INFO_BUTTONS
                                                            )
                                                        }
                                                        className="text-right"
                                                    >
                                                        <i
                                                            aria-hidden="true"
                                                            className="icon-help"
                                                        />
                                                        <span className="hidden">
                                                            More info
                                                        </span>
                                                    </NotAnchor>
                                                </label>
                                                <ColorPicker
                                                    name="bg_primary_icon"
                                                    control={control}
                                                    fullWidth
                                                />
                                            </div>
                                            <div className="c33 ticker div-as-p">
                                                <span className="label">
                                                    Text Color
                                                    <NotAnchor
                                                        onClick={() =>
                                                            openPopup(
                                                                MORE_INFO_TEXT
                                                            )
                                                        }
                                                        className="text-right"
                                                    >
                                                        <i
                                                            aria-hidden="true"
                                                            className="icon-help"
                                                        />
                                                        <span className="hidden">
                                                            More info
                                                        </span>
                                                    </NotAnchor>
                                                </span>
                                                <FormControl fullWidth>
                                                    <Select
                                                        labelId="theme-select-bg-primary-text-color"
                                                        id="fah"
                                                        name="bg_primary_text"
                                                        control={control}
                                                    >
                                                        {[
                                                            [
                                                                "Black",
                                                                "#000000",
                                                            ],
                                                            [
                                                                "White",
                                                                "#ffffff",
                                                            ],
                                                        ].map((value) => (
                                                            <MenuItem
                                                                key={value[0]}
                                                                value={value[1]}
                                                            >
                                                                {value[0]}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <LogoUpload
                                                name="logo_url"
                                                control={control}
                                                Label={
                                                    <span className="label">
                                                        Logo (click below to
                                                        upload new file)
                                                        <NotAnchor
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                openPopup(
                                                                    MORE_INFO_LOGO
                                                                );
                                                            }}
                                                            className="text-right"
                                                        >
                                                            <i
                                                                aria-hidden="true"
                                                                className="icon-help"
                                                            />
                                                            <span className="hidden">
                                                                More info
                                                            </span>
                                                        </NotAnchor>
                                                    </span>
                                                }
                                            />
                                            <p className="c33" />
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <div>
                                    <h2 className="size-16 m10">
                                        Right Sidepanel
                                    </h2>
                                    <div className="form-box">
                                        <div className="cols">
                                            <div className="c33 div-as-p">
                                                <label>
                                                    Background
                                                    <NotAnchor
                                                        onClick={() =>
                                                            openPopup(
                                                                MORE_INFO_BACKGROUND
                                                            )
                                                        }
                                                        className="text-right"
                                                    >
                                                        <i
                                                            aria-hidden="true"
                                                            className="icon-help"
                                                        />
                                                        <span className="hidden">
                                                            More info
                                                        </span>
                                                    </NotAnchor>
                                                </label>
                                                <ColorPicker
                                                    name="bg_secondary"
                                                    control={control}
                                                    fullWidth
                                                />
                                            </div>
                                            <div className="c33 div-as-p">
                                                <label>
                                                    Buttons &amp; Icons
                                                    <NotAnchor
                                                        onClick={() =>
                                                            openPopup(
                                                                MORE_INFO_BUTTONS
                                                            )
                                                        }
                                                        className="text-right"
                                                    >
                                                        <i
                                                            aria-hidden="true"
                                                            className="icon-help"
                                                        />
                                                        <span className="hidden">
                                                            More info
                                                        </span>
                                                    </NotAnchor>
                                                </label>
                                                <ColorPicker
                                                    name="bg_secondary_icon"
                                                    control={control}
                                                    fullWidth
                                                />
                                            </div>
                                            <div className="c33 ticker div-as-p">
                                                <span className="label">
                                                    Text Color
                                                    <NotAnchor
                                                        onClick={() =>
                                                            openPopup(
                                                                MORE_INFO_TEXT
                                                            )
                                                        }
                                                        className="text-right"
                                                    >
                                                        <i
                                                            aria-hidden="true"
                                                            className="icon-help"
                                                        />
                                                        <span className="hidden">
                                                            More info
                                                        </span>
                                                    </NotAnchor>
                                                </span>
                                                <FormControl fullWidth>
                                                    <Select
                                                        labelId="theme-select-bg-secondary-text-color"
                                                        id="fah2"
                                                        name="bg_secondary_text"
                                                        control={control}
                                                    >
                                                        {[
                                                            [
                                                                "Black",
                                                                "#000000",
                                                            ],
                                                            [
                                                                "White",
                                                                "#ffffff",
                                                            ],
                                                        ].map((value) => (
                                                            <MenuItem
                                                                key={value[0]}
                                                                value={value[1]}
                                                            >
                                                                {value[0]}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <p className="c25" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Footer>
                                <p className="link-btn has-inline">
                                    <NotAnchor
                                        onClick={() => {
                                            resetTheme();
                                        }}
                                        className="inline"
                                        data-popup={RESET_THEME}
                                    >
                                        <i className="icon-refresh" /> Reset{" "}
                                        <span className="mobile-hide">
                                            To Default Theme
                                        </span>
                                    </NotAnchor>
                                </p>
                                <p className="link-btn">
                                    {store && (
                                        <a
                                            href={urlJoin(
                                                "/",
                                                STORE_PATH,
                                                store.url_slug
                                            )}
                                            className="inline"
                                            target="_blank"
                                            rel="noreferrer"
                                            id="view-store"
                                        >
                                            <i className="icon-cart-window" />{" "}
                                            View Theme
                                        </a>
                                    )}
                                    <button
                                        type="submit"
                                        disabled={!isDirty || !isValid}
                                    >
                                        Save Theme
                                    </button>
                                </p>
                            </Footer>
                        </>
                    );
                }}
            </Form>
        </MainPane>
    );
};

const MoreInfoDisplayFontPopup = () => (
    <Popup name={MORE_INFO_FONT}>
        <h2 className="m25 color-primary">Display Font</h2>
        <p>
            The main screen titles (like &quot;Store Theme&quot; on this page)
            can be customized with Google fonts. Only Google fonts are supported
            at this time.
        </p>
    </Popup>
);

const MoreInfoTextColorPopup = () => (
    <Popup name={MORE_INFO_TEXT}>
        <h2 className="m25 color-primary">Text Color</h2>
        <p>
            If you have a light background, choose &quot;Black&quot;. If you
            have a dark background, choose &quot;White&quot;.
        </p>
    </Popup>
);

const MoreInfoButtonsIconsPopup = () => (
    <Popup name={MORE_INFO_BUTTONS}>
        <h2 className="m25 color-primary">Buttons &amp; Icons</h2>
        <p>
            The color of buttons and icons in the center content panel (like the
            purple color used on this screen). Usually your primary brand color,
            or the button color from your website. Darker and brighter colors
            will work best with the white button text.
        </p>
    </Popup>
);

const MoreInfoBackgroundPopup = () => (
    <Popup name={MORE_INFO_BACKGROUND}>
        <h2 className="m25 color-primary">Background</h2>
        <p>
            There are two approaches to the left sidepanel: light or dark. If
            you have a color logo that works well on a light background, choose
            a light color, or even white. If you want to use a white logo,
            choose a dark background color. Try to avoid pure black—choose a
            dark gray for better readability.
        </p>
    </Popup>
);

const MoreInfoLogoPopup = () => (
    <Popup name={MORE_INFO_LOGO}>
        <h2 className="m25 color-primary">Logo</h2>
        <p>
            Appears in the upper left corner of all screens in your store (where
            &quot;Joshu&quot; appears on this screen). You can customize the
            background color of the sidepanel to work with any logo.
            <br />
            <br />
            Maximum size: 240 x 40px
            <br />
            Recommended Format: PNG with transparent background
        </p>
    </Popup>
);

interface ResetToDefaultThemePopupPopupDataInterface {
    storeId: number;
    themeId: number;
    reset: UseFormReset<FormSchemaInterface<z.Schema>>;
    setThemeId: React.Dispatch<React.SetStateAction<number | null | undefined>>;
    defaultTheme: Branding;
}

const ResetToDefaultThemePopup = () => {
    const { sdkBuilder } = useApi();
    const { popupData } = usePopup();
    const { tryCatchAndRaiseError } = usePage();

    const popupData_ =
        popupData as unknown as ResetToDefaultThemePopupPopupDataInterface;

    const onResetClick = useCallback(async () => {
        if (!popupData_) {
            return;
        }
        tryCatchAndRaiseError(async () => {
            await sdkBuilder.updateStore({
                id: popupData_.storeId,
                UpdateStore: {
                    theme_id: null,
                },
            });
            if (popupData_.themeId) {
                await sdkBuilder.deleteStoreTheme({ id: popupData_.themeId });
            }

            popupData_.reset(popupData_.defaultTheme);
            popupData_.setThemeId(null);
        });
    }, [popupData_, tryCatchAndRaiseError, sdkBuilder]);

    return (
        <ConfirmationPopup
            name={RESET_THEME}
            onSubmit={onResetClick}
            submitText="Continue"
            mobileSubmitText="Continue"
        >
            <header>
                <h2>Are you sure?</h2>
            </header>
            <p>
                Store theme will be reset to default colors and fonts and will
                have the Joshu logo. There will be no way to revert this
                operation.
            </p>
        </ConfirmationPopup>
    );
};

const ThemePage: FC = () => (
    <Page>
        <Main />
        <Extra />
        <MoreInfoDisplayFontPopup />
        <MoreInfoButtonsIconsPopup />
        <MoreInfoTextColorPopup />
        <MoreInfoBackgroundPopup />
        <MoreInfoLogoPopup />
        <ResetToDefaultThemePopup />
    </Page>
);

export default ThemePage;
