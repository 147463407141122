import { Quote, Submission } from "@joshuins/insurance";
import InsuranceApi from "components/sdk/insurance";
import { hideLoader, showLoader } from "paul/native-dom-manipulation";

const CLOSE_QUOTE_REASONS = [
    {
        value: "Pricing not competitive",
    },
    {
        value: "Coverage not comprehensive",
    },
    {
        value: "Relationship with other carrier",
    },
    {
        value: "Carrier not financially sound",
    },
    { value: "Other", key: "other" },
] as const;

const DECLINE_SUBMISSION_REASONS = [
    {
        value: "Class of business is not eligible for coverage",
    },
    { value: "Size of business is not eligible" },
    { value: "Claim activity" },
    { value: "Not within current underwriter appetite" },
    { value: "Insufficient or problematic information provided" },
    { value: "Unacceptable risk" },
    { value: "Unmet subjectivities" },
    { value: "Broker is not Broker of Record (BOR)" },
] as const;

const DECLINE_BIND_REQUEST_REASONS = [
    { value: "Does not meet company guidelines" },
    { value: "Exceeds broker binding authority" },
    { value: "No longer needed" },
    { value: "Other" },
] as const;

const CANCEL_POLICY_REASONS = [
    { value: "Non-payment of premium" },
    { value: "Substantial change in risk" },
    { value: "Too many claims" },
    { value: "Material misrepresentation" },
    { value: "Other" },
] as const;

const waitForProcessingInsuranceObject = async (
    sdkInsurance: InsuranceApi,
    object: Submission | Quote,
    text?: string
) => {
    const [updatedObjectPromise, , objectIsReadyImmediately_] =
        "flow" in object
            ? sdkInsurance.getSubmissionWhenReady(object.id)
            : sdkInsurance.getQuoteWhenReady(object.id);
    const objectIsReadyImmediately = await objectIsReadyImmediately_;

    if (!objectIsReadyImmediately) {
        showLoader(text);
    }
    const updatedObject = await updatedObjectPromise;

    if (!objectIsReadyImmediately) {
        hideLoader();
    }
    return updatedObject;
};

// This type represents all the potential combinations of objects that can be returned
// by all the list APIs used by the various underwriter lists
type InsuranceListObject = Awaited<
    ReturnType<
        InsuranceApi[
            | "getAllPolicies"
            | "getAllQuotesWithoutPolicies"
            | "getAllSubmissionsWithoutQuotes"
            | "getAllRenewalSubmissionsList"
            | "getAllPendingEndorsementsList"]
    >
>[number];

export {
    CANCEL_POLICY_REASONS,
    CLOSE_QUOTE_REASONS,
    DECLINE_SUBMISSION_REASONS,
    DECLINE_BIND_REQUEST_REASONS,
    waitForProcessingInsuranceObject,
};

export type { InsuranceListObject };
