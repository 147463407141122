import { usePage } from "components/Page";
import { useApi } from "contexts/ApiProvider";
import { FC, useCallback } from "react";
import { useInsuranceProcess } from "../InsuranceProcessProvider";
import NotAnchor from "components/NotAnchor";
import { ConfirmationPopup } from "components/Popup";
import { usePopup } from "contexts/PopupProvider";
import { waitForProcessingInsuranceObject } from "../../util";
import { unpaginateWithMultiParams } from "components/sdk";
import { QuoteStatus } from "@joshuins/insurance";

const CancelPolicyLink: FC = () => {
    const { openPopup } = usePopup();

    return (
        <NotAnchor
            onClick={() => {
                openPopup("cancel-policy");
            }}
        >
            <i aria-hidden="true" className="icon-shield" /> Cancel{" "}
            <span className="mobile-hide">Policy </span>
            <span className="scheme-accesskey">
                <span>Shift</span> <span>i</span>
            </span>
        </NotAnchor>
    );
};

const CancelPolicyPopup: FC = () => {
    const { sdkInsurance } = useApi();
    const { tryCatchAndRaiseError, refreshElement } = usePage();
    const {
        itemGetters: { getInsuranceProcess },
        insuranceProcessDispatch,
    } = useInsuranceProcess();
    const { quote } = getInsuranceProcess();

    const onSubmit = useCallback(async () => {
        tryCatchAndRaiseError(async () => {
            await sdkInsurance.updateQuote({
                id: quote.id,
                UpdateQuote: { status: QuoteStatus.CoverageActive },
            });
            const canceldPolicy = await waitForProcessingInsuranceObject(
                sdkInsurance,
                quote,
                "Canceling policy..."
            );
            if (canceldPolicy) {
                const newDocuments = await unpaginateWithMultiParams(
                    sdkInsurance.allDocuments,
                    {
                        quote_id: quote.id,
                    }
                );
                insuranceProcessDispatch({
                    action: "SetDocuments",
                    documents: newDocuments,
                });
            }
        }, refreshElement);
    }, [
        insuranceProcessDispatch,
        quote,
        refreshElement,
        sdkInsurance,
        tryCatchAndRaiseError,
    ]);

    return (
        <ConfirmationPopup
            name="cancel-policy"
            onSubmit={onSubmit}
            submitText="Cancel Policy"
        >
            <header>
                <h2>Are you sure?</h2>
                <p className="size-14">
                    We will cancel the official policy and notify any recipients
                    via email.
                </p>
            </header>
        </ConfirmationPopup>
    );
};

export { CancelPolicyLink, CancelPolicyPopup };
