import { AlertCategory, usePage } from "components/Page";
import { FC, PropsWithChildren, useCallback, useEffect, useState } from "react";
import { Select } from "components/ReactHookFormUncontrolledComponents";
import { MenuItem } from "components/Select";
import NotAnchor from "components/NotAnchor";
import { useApi } from "contexts/ApiProvider";
import { useRater } from "./RaterProvider";
import classNames from "classnames";
import pick from "lodash/pick";
import { createFormPopup } from "components/Popup";
import { z } from "zod";
import { usePopup } from "contexts/PopupProvider";
import { useWatch } from "react-hook-form";
import FormControl from "@mui/material/FormControl";

import {
    ParameterPermissionV1,
    type GlobalParameterSchemaV1,
    type LineItemParameterSchemaV1,
    JoTypeV1,
    TextOptionV1,
} from "@joshuins/builder";
import ListItemIcon from "@mui/material/ListItemIcon/ListItemIcon";
import {
    findGlobalParameter,
    findGlobalParameterIndex,
    findLineItem,
    findLineItemIndex,
    findLineItemParameter,
    findParameter,
} from "utils/product-version";
import { parameterTypeToStringAndOptions } from "pages/underwriter/quotes_and_policies/components/quote-and-policy-sections/ParameterInput";
import cloneDeep from "lodash/cloneDeep";
import { CodePrefixes } from "./ApplicationProvider";
import { setCodePrefix, splitMultipleLines } from "utils/string";

const PARAMETER_TYPES_FOR_SELECT = [
    "Text",
    "Date",
    "Number",
    "Integer",
    "Currency",
    "CurrencyInteger",
    "Boolean",
] as const;

const PARAMETER_TYPES_SELECT_OPTIONS = [
    { value: "Text", icon: "doc-edit", title: "Short Text" },
    { value: "Date", icon: "calendar2", title: "Date" },
    { value: "Number", icon: "hash", title: "Number" },
    { value: "Integer", icon: "hash", title: "Integer" },
    {
        value: "Currency",
        icon: "other-liability-occ-claims-made",
        title: "Currency",
    },
    {
        value: "CurrencyInteger",
        icon: "other-liability-occ-claims-made",
        title: "Currency (Integer)",
    },
    { value: "Boolean", icon: "choice", title: "Yes/No" },
] as const;

const PARAMETER_TYPES_SELECT_OPTIONS_LOOKUP =
    PARAMETER_TYPES_SELECT_OPTIONS.reduce(
        (previousValue, currentValue) => {
            previousValue[currentValue.value] = currentValue.title;
            return previousValue;
        },
        {} as {
            [x in (typeof PARAMETER_TYPES_SELECT_OPTIONS)[number]["value"]]: (typeof PARAMETER_TYPES_SELECT_OPTIONS)[number]["title"];
        }
    );

const stringToParameterType = (
    type_: string,
    optionsString: string
): JoTypeV1 | undefined => {
    const options: TextOptionV1[] = [];
    splitMultipleLines(optionsString).map((option) => {
        if (option.length > 0) {
            options.push({ value: option, display: null });
        }
    });
    if (type_ == "Number") {
        return {
            Number: {
                format: {
                    rounding: "MidpointAwayFromZero",
                    decimal_places: 2,
                },
                options,
            },
        };
    } else if (type_ == "Integer") {
        return {
            Number: {
                format: {
                    rounding: "MidpointAwayFromZero",
                    decimal_places: 0,
                },
                options,
            },
        };
    } else if (type_ == "Boolean") {
        return { Boolean: {} };
    } else if (type_ == "Date") {
        return { Date: { format: "MonthDayYear" } };
    } else if (type_ == "Text") {
        return { Text: { options: options ?? [] } };
    } else if (type_ == "Currency") {
        return {
            Monetary: {
                format: {
                    rounding: "MidpointAwayFromZero",
                    decimal_places: 2,
                },
                options,
            },
        };
    } else if (type_ == "CurrencyInteger") {
        return {
            Monetary: {
                format: {
                    rounding: "MidpointAwayFromZero",
                    decimal_places: 0,
                },
                options,
            },
        };
    }
    return undefined;
};

const addOrEditParameterSchema = z.object({
    name: z.string().min(1, "This field is required"),
    code: z
        .object({
            value: z.string().min(1, "This field is required"),
            context: z.object({
                lineItemCode: z.union([z.string(), z.undefined(), z.null()]),
                parameterCodeToUpdate: z.union([
                    z.string(),
                    z.null(),
                    z.undefined(),
                ]),
                productVersion: z.union([z.any(), z.null()]),
            }),
        })
        .refine(
            ({ value, context }) => {
                const { lineItemCode, parameterCodeToUpdate, productVersion } =
                    context;
                if (parameterCodeToUpdate === value) {
                    return true;
                } else if (productVersion) {
                    if (lineItemCode) {
                        const lineItem = findLineItem(
                            productVersion,
                            lineItemCode
                        );
                        if (lineItem) {
                            return (
                                findLineItemParameter(lineItem, value) ===
                                undefined
                            );
                        }
                    } else {
                        return (
                            findGlobalParameterIndex(productVersion, value) < 0
                        );
                    }
                }
            },
            {
                message: "Reference tag already in use",
                path: ["value"],
            }
        ),

    type_: z.enum(PARAMETER_TYPES_FOR_SELECT, {
        required_error: "This field is required",
    }),
    readWrite: z
        .object({
            underwriterVisible: z.boolean(),
            underwriterCustomizable: z.boolean(),
            storeUserVisible: z.boolean(),
            storeUserCustomizable: z.boolean(),
        })
        .refine(
            ({ underwriterVisible, underwriterCustomizable }) =>
                underwriterVisible || !underwriterCustomizable,
            {
                message:
                    "The parameter must be visible to underwriters if it is editable by underwriters",
                path: ["underwriterVisible"],
            }
        )
        .refine(
            ({ storeUserVisible, storeUserCustomizable }) =>
                storeUserVisible || !storeUserCustomizable,
            {
                message:
                    "The parameter must be visible to store users if it is editable by store users",
                path: ["storeUserVisible"],
            }
        )
        .refine(
            ({ storeUserVisible, underwriterVisible }) =>
                underwriterVisible || !storeUserVisible,
            {
                message:
                    "The parameter must be visible to underwriters if it is visible store users",
                path: ["underwriterVisible"],
            }
        )
        .refine(
            ({ storeUserCustomizable, underwriterCustomizable }) =>
                underwriterCustomizable || !storeUserCustomizable,
            {
                message:
                    "The parameter must be editable by underwriters if it is editable by store users",
                path: ["storeUserVisible"],
            }
        ),
    show_in_quote: z.boolean(),
    show_in_indication: z.boolean(),
    domain: z
        .object({
            enabled: z.boolean(),
            text: z.string(),
        })
        .refine((value) => !value.enabled || value.text.length > 0, {
            message: "You must supply allowed values",
            path: ["text"],
        }),
});

type AddOrEditParameterType = z.infer<typeof addOrEditParameterSchema>;

const { FormPopup, useFormPopupReturn, useFormReturnRef } = createFormPopup(
    addOrEditParameterSchema
);

const ReadWrite: FC<PropsWithChildren> = ({ children }) => {
    const { register, control, setValue } = useFormPopupReturn();

    const storeUserCustomizable = useWatch({
        control,
        name: "readWrite.storeUserCustomizable",
    });
    const storeUserVisible = useWatch({
        control,
        name: "readWrite.storeUserVisible",
    });
    const underwriterCustomizable = useWatch({
        control,
        name: "readWrite.underwriterCustomizable",
    });
    const underwriterVisible = useWatch({
        control,
        name: "readWrite.underwriterVisible",
    });

    useEffect(() => {
        if (underwriterCustomizable) {
            setValue("readWrite.underwriterVisible", true);
        } else {
            setValue("readWrite.storeUserCustomizable", false);
        }
    }, [underwriterCustomizable, setValue]);

    useEffect(() => {
        if (underwriterVisible) {
            setValue("readWrite.underwriterVisible", true);
        } else {
            setValue("readWrite.storeUserVisible", false);
            setValue("readWrite.underwriterCustomizable", false);
        }
    }, [underwriterVisible, setValue]);

    useEffect(() => {
        if (storeUserCustomizable) {
            setValue("readWrite.storeUserVisible", true);
            setValue("readWrite.underwriterCustomizable", true);
        }
    }, [storeUserCustomizable, setValue]);

    useEffect(() => {
        if (storeUserVisible) {
            setValue("readWrite.underwriterVisible", true);
        } else {
            setValue("readWrite.storeUserCustomizable", false);
        }
    }, [storeUserVisible, setValue]);

    return (
        <table className="check">
            <tbody>
                <tr>
                    <th></th>
                    <th>Visible To</th>
                    <th>Can Edit {children}</th>
                </tr>
                <tr>
                    <td>Underwriters</td>
                    <td>
                        <input
                            {...register("readWrite.underwriterVisible")}
                            type="checkbox"
                            id="iii-0-"
                        />
                        <label htmlFor="iii-0-">
                            <span className="hidden">Label text</span>
                        </label>
                    </td>
                    <td>
                        <input
                            {...register("readWrite.underwriterCustomizable")}
                            type="checkbox"
                            id="iij-0-"
                        />
                        <label htmlFor="iij-0-">
                            <span className="hidden">Label text</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td>Store Users</td>
                    <td>
                        <input
                            {...register("readWrite.storeUserVisible")}
                            type="checkbox"
                            id="iiia-0-"
                        />
                        <label htmlFor="iiia-0-">
                            <span className="hidden">Label text</span>
                        </label>
                    </td>
                    <td>
                        <input
                            {...register("readWrite.storeUserCustomizable")}
                            type="checkbox"
                            id="iija-0-"
                        />
                        <label htmlFor="iija-0-">
                            <span className="hidden">Label text</span>
                        </label>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

const AddOrEditParameterPopup: FC = () => {
    const apiProviderContext = useApi();
    const { sdkBuilder } = apiProviderContext;
    const { popupData, isPopupOpen } = usePopup();
    const { addAlertMessages, tryCatchAndRaiseError } = usePage();
    const {
        formReturn: { reset },
        formReturnRefCallback,
    } = useFormReturnRef();
    const {
        allowEditing,
        raterDispatch,
        raterState: { productVersion },
    } = useRater();

    const [extraFieldsOpen, setExtraFieldsOpen] = useState(false);
    const lineItemCode = popupData?.lineItemCode as string | undefined;
    const parameterCodeToUpdate = popupData?.parameterCode as string;
    // lineItemCode is a full code
    const prefix = lineItemCode ? lineItemCode : CodePrefixes.PARAMETER_PREFIX;
    const transformFormDataToApiData = useCallback(
        (
            formData: AddOrEditParameterType
        ): GlobalParameterSchemaV1 | LineItemParameterSchemaV1 | undefined => {
            const parameterType = stringToParameterType(
                formData.type_,
                formData.domain.text
            );
            if (!parameterType) {
                return undefined;
            }
            const visibility = formData.readWrite.storeUserVisible
                ? ParameterPermissionV1.Users
                : formData.readWrite.underwriterVisible
                  ? ParameterPermissionV1.Underwriters
                  : ParameterPermissionV1.Nobody;
            const customizability = formData.readWrite.storeUserCustomizable
                ? ParameterPermissionV1.Users
                : formData.readWrite.underwriterCustomizable
                  ? ParameterPermissionV1.Underwriters
                  : ParameterPermissionV1.Nobody;

            return {
                ...pick(formData, [
                    "name",
                    "show_in_quote",
                    "show_in_indication",
                ]),
                type: parameterType,
                code: formData.code.value,
                visibility,
                customizability,
                ...(formData.domain.enabled && {
                    domain: formData.domain.text,
                }),
                locked: false, // TODO properly support locked
            };
        },
        []
    );

    const transformApiDataToFormData = useCallback(
        (
            parameter: GlobalParameterSchemaV1 | LineItemParameterSchemaV1
        ): AddOrEditParameterType | undefined => {
            if (!productVersion) {
                return;
            }
            const { parameterType, options } = parameterTypeToStringAndOptions(
                parameter.type
            );
            if (!parameterType) {
                return;
            }
            return {
                type_: parameterType,
                code: {
                    value: parameter.code,
                    context: {
                        lineItemCode: lineItemCode ?? null,
                        parameterCodeToUpdate: parameter.code,
                        productVersion,
                    },
                },
                name: parameter.name ?? "",
                show_in_quote: parameter.show_in_quote ?? false,
                show_in_indication: parameter.show_in_indication ?? false,
                readWrite: {
                    storeUserVisible:
                        parameter.visibility === ParameterPermissionV1.Users,
                    storeUserCustomizable:
                        parameter.customizability ===
                        ParameterPermissionV1.Users,
                    underwriterVisible:
                        parameter.visibility !== ParameterPermissionV1.Nobody,
                    underwriterCustomizable:
                        parameter.customizability !==
                        ParameterPermissionV1.Nobody,
                },
                domain: {
                    enabled: options && options?.length > 0 ? true : false,
                    text: options ? options.join("\r\n") : "",
                },
            };
        },
        [lineItemCode, productVersion]
    );

    useEffect(() => {
        const getParameter = () => {
            if (
                !isPopupOpen("add-or-edit-parameter") ||
                !reset ||
                !productVersion
            ) {
                return;
            }
            if (parameterCodeToUpdate) {
                const parameter = findParameter(
                    productVersion,
                    lineItemCode,
                    parameterCodeToUpdate
                );
                if (parameter) {
                    const formData = transformApiDataToFormData(parameter);
                    if (formData) {
                        setExtraFieldsOpen(formData.domain.enabled);
                        reset(formData);
                    }
                }
            } else {
                reset({
                    name: "",
                    code: {
                        value: "",
                        context: {
                            lineItemCode: lineItemCode ?? null,
                            parameterCodeToUpdate,
                            productVersion,
                        },
                    },
                    domain: {
                        enabled: false,
                        text: "",
                    },
                });
            }
        };
        getParameter();
    }, [
        sdkBuilder,
        transformApiDataToFormData,
        isPopupOpen,
        apiProviderContext,
        productVersion,
        reset,
        lineItemCode,
        parameterCodeToUpdate,
    ]);

    const onSubmit = useCallback(
        async (data: AddOrEditParameterType) => {
            tryCatchAndRaiseError(async () => {
                if (!productVersion) {
                    return;
                }
                const updateProdcutVersion = cloneDeep(productVersion);
                const parameterData = transformFormDataToApiData(data);
                if (!parameterData) {
                    return;
                }
                if (lineItemCode) {
                    const lineItem = findLineItem(
                        updateProdcutVersion,
                        lineItemCode
                    );
                    const lineItemIndex = findLineItemIndex(
                        updateProdcutVersion,
                        lineItemCode
                    );
                    if (!lineItem) {
                        return;
                    }
                    const lineItems =
                        updateProdcutVersion.schema.spec.line_items;
                    const parameter = lineItem.params.find(
                        (litemParam) =>
                            litemParam.code === parameterCodeToUpdate
                    );
                    if (parameter) {
                        const paramIndex = lineItem.params.findIndex(
                            (litemParam) =>
                                litemParam.code === parameterCodeToUpdate
                        );
                        lineItems[lineItemIndex].params[paramIndex] = {
                            ...parameter,
                            ...parameterData,
                        };
                    } else {
                        lineItems[lineItemIndex].params.push({
                            ...parameterData,
                        });
                    }
                    updateProdcutVersion.schema.spec.line_items = lineItems;
                } else {
                    const globalParams =
                        updateProdcutVersion.schema.spec.global_params;
                    if (parameterData) {
                        const parameter = findGlobalParameter(
                            updateProdcutVersion,
                            parameterCodeToUpdate
                        );
                        const index = findGlobalParameterIndex(
                            updateProdcutVersion,
                            parameterCodeToUpdate
                        );
                        if (parameter && index) {
                            globalParams[index] = {
                                ...parameter,
                                ...parameterData,
                                type: parameterData.type,
                                locked: parameter.locked, // TODO properly support locked
                            };
                        } else {
                            globalParams.push({
                                ...parameterData,
                            });
                        }
                    }
                    updateProdcutVersion.schema.spec.global_params =
                        globalParams;
                }
                await sdkBuilder.updateProductVersion({
                    id: updateProdcutVersion.id,
                    UpdateProductVersion: {
                        schema: updateProdcutVersion.schema,
                    },
                });
                raterDispatch({
                    action: "SetParameters",
                    parameters: productVersion.schema.spec.global_params,
                });
                raterDispatch({
                    action: "SetProductVersion",
                    productVersion: updateProdcutVersion,
                });

                addAlertMessages({
                    message: `Parameter ${
                        parameterCodeToUpdate ? "updated" : "created"
                    } successfully`,
                    category: AlertCategory.SUCCESS,
                });
            });
        },
        [
            addAlertMessages,
            lineItemCode,
            parameterCodeToUpdate,
            productVersion,
            raterDispatch,
            sdkBuilder,
            transformFormDataToApiData,
            tryCatchAndRaiseError,
        ]
    );

    if (!productVersion) {
        return <></>;
    }
    let parameter:
        | GlobalParameterSchemaV1
        | LineItemParameterSchemaV1
        | undefined = undefined;
    if (parameterCodeToUpdate) {
        parameter = findParameter(
            productVersion,
            lineItemCode,
            parameterCodeToUpdate
        );
    }

    return (
        <FormPopup
            name="add-or-edit-parameter"
            onClose={() => {
                setExtraFieldsOpen(false);
            }}
            defaultValues={{
                name: undefined,
                code: {
                    value: undefined,
                    context: {
                        lineItemCode: null,
                        productVersion,
                    },
                },
                type_: undefined,
                readWrite: {
                    underwriterVisible: false,
                    underwriterCustomizable: false,
                    storeUserVisible: false,
                    storeUserCustomizable: false,
                },
                show_in_quote: false,
                show_in_indication: false,
                domain: {
                    enabled: false,
                    text: undefined,
                },
            }}
            submitText="Save"
            formReturnRefCallback={formReturnRefCallback}
            onSubmit={onSubmit}
            disabled={!allowEditing}
            overlayPopups={[
                // 0
                <>
                    <h3>Name</h3>
                    <p>
                        The way the parameter will be shown in to store users or
                        product underwriters.
                    </p>
                    <p>
                        Common Parameters: <br />
                        Limit <br />
                        Retention <br />
                        Deductible <br />
                        Retroactive Date <br />
                        etc.
                    </p>
                </>,
                // 1
                <>
                    <h3>Reference Tag</h3>
                    <p>
                        The way the parameter will be referred to internally
                        when connecting the rater and documents.
                    </p>
                    <p>Letters, numbers, and _ allowed.</p>
                </>,
                // 2
                <>
                    <h3>Visible To / Can Edit</h3>
                    <p>
                        Visibility determines who can see this parameter in
                        submissions, quotes, and reports. For example, you may
                        want extra fields that only underwriters can see for
                        additional underwriting questions.
                    </p>
                    <p>
                        Edit allows the selected role to change the value. For
                        example, if you want to allow brokers to request a
                        higher aggregate limit, you would check &quot;Can
                        Edit&quot; for Store Users.
                    </p>
                </>,
                // 3
                // @TODO: this "Show In" popover is not relevant, read it and you'll see. Copied from the old code, but needs to be fixed
                <>
                    <h3>Show In</h3>
                    <p>
                        Visibility determines who can see this parameter in
                        submissions, quotes, and reports. For example, you may
                        want extra fields that only underwriters can see for
                        additional underwriting questions.
                    </p>
                    <p>
                        Edit allows the selected role to change the value in
                        quotes. For example, if you want to allow brokers to
                        request a higher aggregate limit, you would check
                        &quot;Can Edit&quot; for Store Users.
                    </p>
                </>,
                // 4
                <>
                    <h3>List</h3>
                    <p>
                        Creates a list of possible values this parameter can
                        take. For example, a list of states, a range of values,
                        or any other set of values your rating requires.
                    </p>
                    <p>
                        List each value, unformatted (e.g., no dollar signs), on
                        it&apos;s own line.
                    </p>
                </>,
            ]}
        >
            {({
                watch,
                register,
                openOverlayPopup,
                getValues,
                control,
                formState: { errors },
            }) => {
                return (
                    <>
                        <header>
                            <h2>
                                {lineItemCode
                                    ? "Line Item Parameter"
                                    : "Global Parameter"}
                            </h2>
                        </header>
                        <p
                            className={classNames({
                                "has-error": errors.name,
                            })}
                        >
                            <label htmlFor="iid-0">
                                Name{" "}
                                <NotAnchor
                                    className="text-right"
                                    onClick={() => {
                                        openOverlayPopup(0);
                                    }}
                                >
                                    <i
                                        aria-hidden="true"
                                        className="icon-help"
                                    />{" "}
                                    <span className="hidden">More info</span>
                                </NotAnchor>
                            </label>
                            <span className="input-autocomplete">
                                <input
                                    {...register("name")}
                                    type="text"
                                    id="iid-0"
                                />
                            </span>
                            {errors.name && (
                                <label
                                    id="name-input-error"
                                    className="error"
                                    htmlFor="iid-0"
                                >
                                    {errors.name.message as string}
                                </label>
                            )}
                        </p>
                        <p
                            className={classNames({
                                "has-error": errors.code,
                            })}
                        >
                            <label htmlFor="iie">
                                Reference Tag{" "}
                                <NotAnchor
                                    className="text-right"
                                    onClick={() => {
                                        openOverlayPopup(1);
                                    }}
                                >
                                    <i
                                        aria-hidden="true"
                                        className="icon-help"
                                    />{" "}
                                    <span className="hidden">More info</span>
                                </NotAnchor>
                            </label>
                            <span
                                className={classNames({
                                    "input-prefix disabled":
                                        parameter && parameter.locked,
                                })}
                            >
                                <i
                                    aria-hidden="true"
                                    id="ref-tag-icon-lock"
                                    className={classNames({
                                        "icon-lock":
                                            parameter && parameter.locked,
                                    })}
                                />
                                <input
                                    {...register("code.value", {
                                        onChange: (e) =>
                                            setCodePrefix(prefix, e),
                                    })}
                                    maxLength={30}
                                    id="iie"
                                />
                            </span>
                            {errors.code?.value && (
                                <label
                                    id="code-input-error"
                                    className="error"
                                    htmlFor="iie"
                                >
                                    {errors.code?.value.message as string}
                                </label>
                            )}
                        </p>
                        <div className="div-as-p">
                            <span className="label">Type</span>
                            {parameter && parameter.locked ? (
                                <span className="input-prefix disabled">
                                    <i
                                        aria-hidden="true"
                                        className="icon-lock"
                                    />
                                    <input
                                        name="type_"
                                        value={
                                            PARAMETER_TYPES_SELECT_OPTIONS_LOOKUP[
                                                getValues(
                                                    "type_"
                                                ) as keyof typeof PARAMETER_TYPES_SELECT_OPTIONS_LOOKUP
                                            ] ?? ""
                                        }
                                        type="text"
                                        id="iig"
                                        disabled
                                    />
                                </span>
                            ) : (
                                <>
                                    <FormControl
                                        fullWidth
                                        error={!!errors.type_}
                                        className={classNames({
                                            "has-error": !!errors.type_,
                                        })}
                                    >
                                        <Select
                                            labelId="type-select-label"
                                            id="ihl-0"
                                            name="type_"
                                            control={control}
                                        >
                                            {PARAMETER_TYPES_SELECT_OPTIONS.map(
                                                ({ value, icon, title }) => (
                                                    <MenuItem
                                                        key={value}
                                                        value={value}
                                                    >
                                                        <ListItemIcon>
                                                            <i
                                                                aria-hidden="true"
                                                                className={`icon-${icon}`}
                                                            />
                                                        </ListItemIcon>
                                                        {title}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                    </FormControl>
                                    {errors.type_ && (
                                        <label
                                            id="type_-input-error"
                                            className="error"
                                            htmlFor="ihl-0"
                                        >
                                            {errors.type_.message as string}
                                        </label>
                                    )}
                                </>
                            )}
                        </div>
                        <ReadWrite>
                            <span className="text-right">
                                <NotAnchor
                                    onClick={() => {
                                        openOverlayPopup(2);
                                    }}
                                >
                                    <i
                                        aria-hidden="true"
                                        className="icon-help"
                                    />{" "}
                                    <span className="hidden">More info</span>
                                </NotAnchor>
                            </span>
                        </ReadWrite>
                        <table className="check">
                            <tbody>
                                <tr>
                                    <th></th>
                                    <th>Show In</th>
                                    <th>
                                        <span className="text-right">
                                            <NotAnchor
                                                onClick={() => {
                                                    openOverlayPopup(3);
                                                }}
                                            >
                                                <i
                                                    aria-hidden="true"
                                                    className="icon-help"
                                                />{" "}
                                                <span className="hidden">
                                                    More info
                                                </span>
                                            </NotAnchor>
                                        </span>
                                    </th>
                                </tr>
                                <tr>
                                    <td>Quotes</td>
                                    <td>
                                        <input
                                            {...register("show_in_quote")}
                                            type="checkbox"
                                            id="dph-0-"
                                        />{" "}
                                        <label htmlFor="dph-0-">
                                            <span className="hidden">
                                                Label text
                                            </span>
                                        </label>
                                    </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>Indications</td>
                                    <td>
                                        <input
                                            {...register("show_in_indication")}
                                            type="checkbox"
                                            id="dpi-0-"
                                        />{" "}
                                        <label htmlFor="dpi-0-">
                                            <span className="hidden">
                                                Label text
                                            </span>
                                        </label>
                                    </td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                        <p className="link-strong m15">
                            <NotAnchor
                                className={classNames({
                                    clicked: extraFieldsOpen,
                                })}
                                onClick={() => {
                                    setExtraFieldsOpen(!extraFieldsOpen);
                                }}
                            >
                                <i
                                    aria-hidden="true"
                                    className="icon-chevron-down"
                                />{" "}
                                Options
                            </NotAnchor>
                        </p>
                        <ul
                            className={classNames(
                                "check overlay section-popup-a",
                                {
                                    hidden: !extraFieldsOpen,
                                }
                            )}
                        >
                            <li>
                                <input
                                    {...register("domain.enabled")}
                                    type="checkbox"
                                    id="dpl-0-"
                                    className="dpl"
                                    readOnly={parameter && parameter.locked}
                                    disabled={parameter && parameter.locked}
                                />
                                <label
                                    htmlFor="dpl-0-"
                                    style={{ width: "100%" }}
                                >
                                    Set Allowed Values
                                    <NotAnchor
                                        className="text-right"
                                        onClick={() => {
                                            openOverlayPopup(4);
                                        }}
                                    >
                                        <i
                                            aria-hidden="true"
                                            className="icon-help"
                                        />
                                        <span className="hidden">
                                            More info
                                        </span>
                                    </NotAnchor>
                                </label>
                                <div>
                                    <p
                                        className={classNames(
                                            "section-type-list",
                                            {
                                                hidden: !watch(
                                                    "domain.enabled"
                                                ),
                                            }
                                        )}
                                    >
                                        <label htmlFor="iih-0-">
                                            Enter values, one per line...
                                        </label>
                                        <textarea
                                            {...register("domain.text")}
                                            id="iih-0-"
                                            className="medium"
                                            readOnly={
                                                parameter && parameter.locked
                                            }
                                            disabled={
                                                parameter && parameter.locked
                                            }
                                            placeholder="250,000
500,000
1,000,000"
                                        ></textarea>
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </>
                );
            }}
        </FormPopup>
    );
};

export { AddOrEditParameterPopup };
