import { FC, useEffect, useState } from "react";
import { useApi } from "contexts/ApiProvider";

import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";

import { FullPageMessage1 } from "components/FullPageMessage";
import { RoleAwareLink, useBranding } from "contexts/BrandingProvider";

import { showLoader, hideLoader } from "paul/native-dom-manipulation";
import SsoLoginButton from "components/SsoLoginButton";
import useLoginProviders from "utils/use-login-providers";
import { STORE_PATH } from "globals";
import urlJoin from "url-join";

const loginSchema = z.object({
    email: z.string().email(),
    password: z.string().min(1, { message: "Please enter a password" }),
});

// using interface instead of type generally gives nicer editor feedback:
// https://github.com/jquense/yup#typescript-integration
type LoginType = z.infer<typeof loginSchema>;

const RELATIVE_URL_REGEX = /^(?!www\.|(?:http|ftp)s?:\/\/|[A-Za-z]:\\|\/\/).*/;

interface LoginPageInterface {
    registerLink?: boolean;
}

const LoginPage: FC<LoginPageInterface> = ({ registerLink = false }) => {
    const [validNextUrl, setValidNextUrl] = useState<boolean>(true);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<LoginType>({
        resolver: zodResolver(loginSchema),
        defaultValues: {
            email: "",
            password: "",
        },
    });
    const { apiAuthProcess } = useApi();
    const { generateNextUrl } = useBranding();
    const nextUrl = generateNextUrl();
    const { ssoProviders, passwordEnabled, googleEnabled } =
        useLoginProviders();

    useEffect(() => {
        const storeUrlName = localStorage.getItem("storeUrlName");
        if (
            storeUrlName &&
            window.location.href.indexOf("/store/") === -1 &&
            window.location.href.indexOf(storeUrlName) === -1
        ) {
            window.location.href = urlJoin(
                "/",
                STORE_PATH,
                storeUrlName,
                "auth",
                "login"
            );
            return;
        }
        if (!nextUrl.match(RELATIVE_URL_REGEX)) {
            setValidNextUrl(false);
        }
    }, [nextUrl]);

    const onSubmit = (data: LoginType) => {
        showLoader();
        apiAuthProcess.login(data.email, data.password, nextUrl);
    };

    if (apiAuthProcess.authProcessErrorMessage) {
        hideLoader();
    }

    return (
        <main id="content">
            {validNextUrl && (
                <>
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        className="form-aside"
                        noValidate
                    >
                        <header className="m25">
                            <h2>Sign In</h2>
                            <p>Sign in using one of the options below</p>
                        </header>
                        {googleEnabled && <SsoLoginButton oidcSlug="google" />}
                        {ssoProviders &&
                            ssoProviders.map(({ slug, display_name }) => (
                                <SsoLoginButton
                                    key={slug}
                                    oidcSlug={slug}
                                    displayName={display_name}
                                />
                            ))}
                        {passwordEnabled && (
                            <div className="form-overlay">
                                <p>
                                    <label htmlFor="fai">Email Address</label>
                                    <input
                                        {...register("email")}
                                        autoComplete="username"
                                        type="email"
                                        id="fai"
                                    />
                                    {errors.email && (
                                        <label
                                            id="fai-error"
                                            className="error"
                                            htmlFor="fai"
                                        >
                                            {errors.email.message}
                                        </label>
                                    )}
                                </p>
                                <p>
                                    <label htmlFor="faj">
                                        Password
                                        <RoleAwareLink
                                            to="/auth/forgot-password"
                                            replace
                                            tabIndex={-1}
                                            className="text-right color-primary"
                                        >
                                            Forgot?
                                        </RoleAwareLink>
                                    </label>
                                    <input
                                        {...register("password")}
                                        autoComplete="current-password"
                                        type="password"
                                        id="faj"
                                    />
                                    {errors.password && (
                                        <label
                                            id="faj-error"
                                            className="error"
                                            htmlFor="faj"
                                        >
                                            {errors.password.message}
                                        </label>
                                    )}
                                </p>
                                <p className="submit">
                                    {apiAuthProcess.authProcessErrorMessage && (
                                        <label id="failed" className="error">
                                            {
                                                apiAuthProcess.authProcessErrorMessage
                                            }
                                        </label>
                                    )}
                                    <button type="submit">Sign In</button>
                                </p>
                            </div>
                        )}
                    </form>
                    {registerLink && (
                        <p className="text-center">
                            New User?{" "}
                            <RoleAwareLink to="auth/register">
                                Click to Register!
                            </RoleAwareLink>
                        </p>
                    )}
                </>
            )}
            {!validNextUrl && (
                <FullPageMessage1
                    title="We Can't Process This Request"
                    message={
                        "If you keep experiencing issues, please contact our customer support team"
                    }
                    iconType="triangle"
                />
            )}
        </main>
    );
};

export default LoginPage;
