import { FC, PropsWithChildren } from "react";
import { useAuthUser } from "react-auth-kit";
import { useLocation } from "react-router-dom";
import { Navigate } from "./DevAwareRoutingLink";

const RequireAuth: FC<PropsWithChildren> = ({ children }) => {
    const authUser = useAuthUser()();
    const location = useLocation();

    if (authUser) {
        return <>{children}</>;
    } else {
        return <Navigate to="/" state={{ from: location }} replace />;
    }
};

export default RequireAuth;
