import {
    AuthOidcProvider,
    NewAuthOidcProvider,
    UpdateAuthOidcProvider,
} from "@joshuins/system";
import classNames from "classnames";
import { Footer, MainPane } from "components/MainPane";
import MenuPopover from "components/MenuPopover";
import NotAnchor from "components/NotAnchor";
import { AlertCategory, Page, usePage } from "components/Page";
import { ConfirmationPopup, createFormPopup } from "components/Popup";
import { unpaginate } from "components/sdk";
import { ApiProviderInterface, useApi } from "contexts/ApiProvider";
import { usePopup } from "contexts/PopupProvider";
import pick from "lodash/pick";
import { FC, useCallback, useEffect, useState } from "react";
import { z } from "zod";
import { SecretInput } from "components/ReactHookFormUncontrolledComponents";
import ExtraPane from "components/extra-panes/ExtraPane";
import EditAuthSettingsPopup, {
    EDIT_AUTH_SETTINGS,
} from "./EditAuthSettingsPopup";

const DeleteOidcProviderPopup: FC = () => {
    const { sdkSystem } = useApi();
    const { addAlertMessages, refreshState, tryCatchAndRaiseError } = usePage();
    const { popupData } = usePopup();
    const oidcProvider = popupData?.oidcProvider as
        | AuthOidcProvider
        | undefined;

    const onSubmit = useCallback(async () => {
        if (!oidcProvider) {
            return;
        }
        const authSettings = await unpaginate(sdkSystem.allAuthSettings, {
            store_id: null,
        });
        if (
            authSettings.some(
                (authSetting) =>
                    authSetting.oidc_provider_id === oidcProvider.id
            )
        ) {
            addAlertMessages({
                message:
                    "This SSO provider is currently enabled for the login screen. Disable it before trying to delete.",
                category: AlertCategory.SUCCESS,
            });
        } else {
            tryCatchAndRaiseError(async () => {
                await sdkSystem.deleteOidcProvider({ id: oidcProvider.id });
                addAlertMessages({
                    message: `The ${oidcProvider.display_name} provider has been deleted`,
                    category: AlertCategory.SUCCESS,
                });
            }, refreshState);
        }
    }, [
        addAlertMessages,
        oidcProvider,
        refreshState,
        sdkSystem,
        tryCatchAndRaiseError,
    ]);

    return (
        <ConfirmationPopup
            name="delete-oidc-provider"
            onSubmit={onSubmit}
            submitText="Delete OIDC Provider"
            mobileSubmitText="Delete"
        >
            {oidcProvider && (
                <>
                    <header>
                        <h2>Are you sure?</h2>
                        <p className="size-14">
                            This OIDC provider will be deleted and this cannot
                            be undone.
                        </p>
                    </header>
                </>
            )}
        </ConfirmationPopup>
    );
};

const addOrEditOidcProviderSchema = z.object({
    display_name: z.string().min(1, "This field is required"),
    issuer_url: z
        .string()
        .min(1, "This field is required")
        .url("Must be a valid URL"),
    client_id: z.string().min(1, "This field is required"),
    client_secret: z
        .object({
            value: z.string(),
            context: z.object({
                isUpdating: z.boolean(),
            }),
        })
        .refine(
            async ({ value, context }) => {
                const { isUpdating } = context;
                return isUpdating || value.length > 0;
            },
            {
                message: "This field is required",
                path: ["value"],
            }
        ),
    slug: z
        .object({
            value: z
                .string()
                .min(1, "This field is required")
                .regex(
                    /^[A-Za-z0-9_-]+$/,
                    "Slug can only have letters, numbers, _ and -"
                ),
            context: z.object({
                existingOidcProviderSlugToUpdate: z.union([
                    z.string(),
                    z.null(),
                    z.undefined(),
                ]),
                apiProviderContext: z.union([z.any(), z.null()]),
            }),
        })
        .refine(
            async ({ value, context }) => {
                const {
                    existingOidcProviderSlugToUpdate,
                    apiProviderContext: apiProviderContext_,
                } = context;
                const apiProviderContext =
                    apiProviderContext_ as ApiProviderInterface | null;

                if (apiProviderContext === null) {
                    return true;
                }

                const { sdkSystem } = apiProviderContext;

                if (existingOidcProviderSlugToUpdate === value) {
                    return true;
                } else {
                    const oidcProviders = await unpaginate(
                        sdkSystem.allOidcProviders,
                        {}
                    );
                    return (
                        oidcProviders.findIndex(
                            (oidcProvider) => oidcProvider.slug === value
                        ) === -1
                    );
                }
            },
            {
                message: "Slug already in use by another OIDC provider",
                path: ["value"],
            }
        ),
});

type AddOrEditOidcProviderType = z.infer<typeof addOrEditOidcProviderSchema>;

const {
    FormPopup: AddOrEditOidcProviderFormPopup,
    useFormReturnRef: useAddOrEditOidcProviderFormReturnRef,
} = createFormPopup(addOrEditOidcProviderSchema);

const AddOrEditOidcProviderPopup: FC = () => {
    const apiProviderContext = useApi();
    const { sdkSystem } = apiProviderContext;
    const { tryCatchAndRaiseError, refreshState, addAlertMessages } = usePage();
    const { popupData, isPopupOpen } = usePopup();
    const {
        formReturn: { reset },
        formReturnRefCallback,
    } = useAddOrEditOidcProviderFormReturnRef();

    const oidcProvider = popupData?.oidcProvider as
        | AuthOidcProvider
        | undefined;

    const transformFormDataToApiDataForCreate = useCallback(
        (data: AddOrEditOidcProviderType): NewAuthOidcProvider => {
            return {
                ...pick(data, ["display_name", "issuer_url", "client_id"]),
                client_secret: data.client_secret.value,
                slug: data.slug.value,
            };
        },
        []
    );

    const transformFormDataToApiDataForUpdate = useCallback(
        (data: AddOrEditOidcProviderType): UpdateAuthOidcProvider => {
            return {
                ...pick(data, ["display_name", "issuer_url", "client_id"]),
                slug: data.slug.value,
                ...(data.client_secret.value.length > 0 && {
                    client_secret: data.client_secret.value,
                }),
            };
        },
        []
    );

    const transformApiDataToFormData = useCallback(
        (oidcProvider: AuthOidcProvider): AddOrEditOidcProviderType => {
            return {
                ...pick(oidcProvider, [
                    "display_name",
                    "issuer_url",
                    "client_id",
                ]),
                client_secret: {
                    value: "",
                    context: {
                        isUpdating: true,
                    },
                },
                slug: {
                    value: oidcProvider.slug,
                    context: {
                        existingOidcProviderSlugToUpdate: oidcProvider.slug,
                        apiProviderContext,
                    },
                },
            };
        },
        [apiProviderContext]
    );

    useEffect(() => {
        const getOidcProvider = () => {
            if (!isPopupOpen("add-or-edit-oidc-provider") || !reset) {
                return;
            }

            if (oidcProvider) {
                reset(transformApiDataToFormData(oidcProvider));
            } else {
                reset({
                    slug: {
                        context: {
                            apiProviderContext,
                        },
                    },
                    client_secret: {
                        context: {
                            isUpdating: false,
                        },
                    },
                });
            }
        };
        getOidcProvider();
    }, [
        isPopupOpen,
        oidcProvider,
        transformApiDataToFormData,
        apiProviderContext,
        reset,
    ]);

    const onSubmit = useCallback(
        async (data: AddOrEditOidcProviderType) => {
            tryCatchAndRaiseError(async () => {
                if (!oidcProvider) {
                    const newOidcProviderData =
                        transformFormDataToApiDataForCreate(data);
                    const newOidcProvider = await sdkSystem.createOidcProvider({
                        NewAuthOidcProvider: newOidcProviderData,
                    });
                    addAlertMessages({
                        message: `${newOidcProvider.display_name} has been added.`,
                        category: AlertCategory.SUCCESS,
                    });
                } else {
                    const updateOidcProviderData =
                        transformFormDataToApiDataForUpdate(data);
                    const updatedOidcProvider =
                        await sdkSystem.updateOidcProvider({
                            id: oidcProvider.id,
                            UpdateAuthOidcProvider: updateOidcProviderData,
                        });
                    addAlertMessages({
                        message: `${updatedOidcProvider.display_name} has been updated`,
                        category: AlertCategory.SUCCESS,
                    });
                }
            }, refreshState);
        },
        [
            addAlertMessages,
            oidcProvider,
            refreshState,
            sdkSystem,
            transformFormDataToApiDataForCreate,
            transformFormDataToApiDataForUpdate,
            tryCatchAndRaiseError,
        ]
    );

    return (
        <AddOrEditOidcProviderFormPopup
            name="add-or-edit-oidc-provider"
            onSubmit={onSubmit}
            defaultValues={{
                slug: {
                    value: "",
                    context: {
                        apiProviderContext: null,
                        existingOidcProviderSlugToUpdate: null,
                    },
                },
                display_name: "",
                issuer_url: "",
                client_id: "",
                client_secret: {
                    value: "",
                    context: {
                        isUpdating: false,
                    },
                },
            }}
            submitText="Save"
            formReturnRefCallback={formReturnRefCallback}
            overlayPopups={[
                <>
                    <h3>Display Name</h3>
                    <p>
                        This is the name of the provider that users will see in
                        the login screen. Make sure this name clearly conveys
                        the login type so that users understand through which
                        provider they&apos;re logging in.
                    </p>
                </>,
                <>
                    <h3>Slug</h3>
                    <p>
                        This is the part of the callback URL that is passed to
                        the OIDC provider once the user has authenticated
                        through the provider. You can choose any value here as
                        long as it is unique - no other configured provider can
                        use it - and is a string of plain characters without any
                        spaces.
                    </p>
                </>,
                <>
                    <h3>Issuer URL</h3>
                    <p>
                        This value is specified by the provider and is the
                        external URL of the provider to which the user is
                        redirected for authentication outside of Joshu. The
                        provider may refer to this as the &quot;domain&quot; or
                        &quot;Sign-On Endpoint&quot;.
                    </p>
                </>,
                <>
                    <h3>Client ID</h3>
                    <p>
                        This is the unique ID of the OIDC application that you
                        have setup in your provider. You can get it from your
                        provider usually through one of the configuration
                        screens in the provider&apos;s platform
                    </p>
                </>,
                <>
                    <h3>Client Secret</h3>
                    <p>
                        This is a value that you get from the provider to setup
                        a secure connection between Joshu and the
                        provider&apos;s servers. You can get it from your
                        provider usually through one of the configuration
                        screens in the provider&apos;s platform. Once you copy
                        it into Joshu we will store it securely and it will
                        never be exposed, neither through our API nor in the
                        browser.
                    </p>
                </>,
            ]}
        >
            {({
                openOverlayPopup,
                register,
                control,
                formState: { errors },
            }) => {
                return (
                    <>
                        <header>
                            <h2>New OIDC Provider</h2>
                        </header>
                        <p
                            className={classNames({
                                "has-error": errors.display_name,
                            })}
                        >
                            <label htmlFor="display_name">
                                Display Name
                                <NotAnchor
                                    onClick={() => openOverlayPopup(0)}
                                    className="text-right"
                                >
                                    <i className="icon-help" />
                                    <span className="hidden">More info</span>
                                </NotAnchor>
                            </label>
                            <input
                                id="display_name"
                                type="text"
                                {...register("display_name")}
                            />
                            {errors.display_name && (
                                <label
                                    id="display_name-error"
                                    className="error"
                                    htmlFor="display_name"
                                >
                                    {errors.display_name.message}
                                </label>
                            )}
                        </p>
                        <p
                            className={classNames("color-black-100", {
                                "has-error": errors.slug?.value,
                            })}
                        >
                            <label htmlFor="slug">
                                Slug
                                <NotAnchor
                                    onClick={() => openOverlayPopup(1)}
                                    className="text-right"
                                >
                                    <i className="icon-help" />
                                    <span className="hidden">More info</span>
                                </NotAnchor>
                            </label>
                            <input
                                id="slug"
                                type="text"
                                {...register("slug.value")}
                            />
                            {errors.slug?.value && (
                                <label
                                    id="slug-error"
                                    className="error"
                                    htmlFor="slug"
                                >
                                    {errors.slug.value.message}
                                </label>
                            )}
                        </p>
                        <p
                            className={classNames({
                                "has-error": errors.issuer_url,
                            })}
                        >
                            <label htmlFor="issuer_url">
                                Issuer URL
                                <NotAnchor
                                    onClick={() => openOverlayPopup(2)}
                                    className="text-right"
                                >
                                    <i className="icon-help" />
                                    <span className="hidden">More info</span>
                                </NotAnchor>
                            </label>
                            <input
                                id="issuer_url"
                                type="text"
                                {...register("issuer_url")}
                            />
                            {errors.issuer_url && (
                                <label
                                    id="issuer_url-error"
                                    className="error"
                                    htmlFor="issuer_url"
                                >
                                    {errors.issuer_url.message}
                                </label>
                            )}
                        </p>
                        <p
                            className={classNames({
                                "has-error": errors.client_id,
                            })}
                        >
                            <label htmlFor="client_id">
                                Client ID
                                <NotAnchor
                                    onClick={() => openOverlayPopup(3)}
                                    className="text-right"
                                >
                                    <i className="icon-help" />
                                    <span className="hidden">More info</span>
                                </NotAnchor>
                            </label>
                            <input
                                id="client_id"
                                type="text"
                                {...register("client_id")}
                            />
                            {errors.client_id && (
                                <label
                                    id="client_id-error"
                                    className="error"
                                    htmlFor="client_id"
                                >
                                    {errors.client_id.message}
                                </label>
                            )}
                        </p>
                        <p
                            className={classNames({
                                "has-error": errors.client_secret,
                            })}
                        >
                            <label htmlFor="client_secret">
                                Client Secret
                                <NotAnchor
                                    onClick={() => openOverlayPopup(4)}
                                    className="text-right"
                                >
                                    <i className="icon-help" />
                                    <span className="hidden">More info</span>
                                </NotAnchor>
                            </label>
                            <SecretInput
                                id="client_secret"
                                name="client_secret.value"
                                control={control}
                                showStarsWhenBlurred={!!oidcProvider}
                            />
                            {errors.client_secret?.value && (
                                <label
                                    id="client_secret-error"
                                    className="error"
                                    htmlFor="client_secret"
                                >
                                    {errors.client_secret?.value?.message}
                                </label>
                            )}
                        </p>
                    </>
                );
            }}
        </AddOrEditOidcProviderFormPopup>
    );
};

const SsoProvidersList: FC<{ oidcProviders: AuthOidcProvider[] }> = ({
    oidcProviders: authOidcProviders,
}) => {
    const { openPopup } = usePopup();

    return (
        <ul className="list-plain box no-img">
            {authOidcProviders.map((oidcProvider, index) => {
                const { id, display_name, slug } = oidcProvider;
                return (
                    <MenuPopover
                        additionalClasses={["list-plain-li"]}
                        key={id}
                        menuItems={[
                            {
                                key: "delete-oidc-provider",
                                label: "Delete Provider",
                                icon: "trash",
                                onClick: () =>
                                    openPopup("delete-oidc-provider", {
                                        oidcProvider,
                                    }),
                            },
                        ]}
                        style={{
                            zIndex: authOidcProviders.length - index,
                        }}
                    >
                        {({ ToggleButton, Menu }) => (
                            <>
                                <NotAnchor
                                    onClick={() => {
                                        openPopup("add-or-edit-oidc-provider", {
                                            oidcProvider,
                                        });
                                    }}
                                >
                                    {display_name} ({slug})
                                </NotAnchor>
                                {ToggleButton}
                                {Menu}
                            </>
                        )}
                    </MenuPopover>
                );
            })}
        </ul>
    );
};

const Main: FC = () => {
    const { sdkSystem } = useApi();
    const { openPopup } = usePopup();
    const { stateId } = usePage();
    const [oidcProviders, setOidcProviders] = useState<AuthOidcProvider[]>();

    useEffect(() => {
        const getOidcProviders = async () => {
            const oidcProviders_ = await unpaginate(
                sdkSystem.allOidcProviders,
                {}
            );
            setOidcProviders(oidcProviders_);
        };
        getOidcProviders();
    }, [sdkSystem, stateId]);

    if (oidcProviders === undefined) {
        return <></>;
    }

    return (
        <MainPane
            title="SSO"
            layoutConfig={{
                mainLayout: oidcProviders.length === 0 ? "center" : "wide",
            }}
        >
            {oidcProviders.length === 0 ? (
                <div className="module-success inline">
                    <h2>
                        <i className="icon-lock-thin" /> No SSO Providers yet
                    </h2>
                    <p>
                        Click &quot;New SSO Provider&quot; below to start
                        setting up your first SSO Provider.
                    </p>
                </div>
            ) : (
                <SsoProvidersList oidcProviders={oidcProviders} />
            )}
            <Footer>
                <p className="link-btn">
                    <NotAnchor
                        onClick={() => {
                            openPopup("add-or-edit-oidc-provider");
                        }}
                    >
                        <i aria-hidden="true" className="icon-plus-circle" />
                        New
                        <span className="mobile-hide"> SSO Provider</span>
                    </NotAnchor>
                </p>
            </Footer>
            <MenuPopover
                component={Footer}
                menuItems={[
                    {
                        key: "edit-auth-settings",
                        label: "Configure Login Settings",
                        icon: "cog",
                        onClick: () => {
                            openPopup(EDIT_AUTH_SETTINGS);
                        },
                    },
                ]}
            >
                {({ ToggleButton, Menu }) => (
                    <>
                        <p className="link-btn has-inline">
                            <NotAnchor
                                onClick={() => {
                                    openPopup(EDIT_AUTH_SETTINGS);
                                }}
                                className="inline"
                            >
                                <i className="icon-cog" /> Configure Login
                                Settings
                            </NotAnchor>
                        </p>
                        <p className="link-btn">
                            <NotAnchor
                                onClick={() => {
                                    openPopup("add-or-edit-oidc-provider");
                                }}
                            >
                                <i
                                    aria-hidden="true"
                                    className="icon-plus-circle"
                                />
                                New
                                <span className="mobile-hide">
                                    {" "}
                                    SSO Provider
                                </span>
                            </NotAnchor>
                        </p>
                        {ToggleButton}
                        {Menu}
                    </>
                )}
            </MenuPopover>
        </MainPane>
    );
};

const SsoExtraPane = () => (
    <ExtraPane>
        <ul className="list-icon b">
            <li>
                <i className="icon-keys" /> SSO{" "}
                <span>
                    Configure Single Sign-on providers to allow your users to
                    login using your own authentication mechanisms. Additionally
                    setup what login methods users can use.
                </span>
            </li>
        </ul>
    </ExtraPane>
);

const SsoPage: FC = () => (
    <Page>
        <Main />
        <SsoExtraPane />
        <AddOrEditOidcProviderPopup />
        <DeleteOidcProviderPopup />
        <EditAuthSettingsPopup />
    </Page>
);

export default SsoPage;
