import { z } from "zod";
import classNames from "classnames";
import {
    BaseSyntheticEvent,
    CSSProperties,
    FC,
    PropsWithChildren,
    ReactElement,
    ReactNode,
    useCallback,
    useEffect,
    useMemo,
    useState,
    KeyboardEvent,
    RefCallback,
    createContext,
    useContext,
} from "react";
import { FieldErrors, FieldValues, UseFormReturn } from "react-hook-form";
import NotAnchor from "./NotAnchor";
import { usePopup } from "contexts/PopupProvider";
import { FieldSet, FormProps, createForm } from "./Form";
import noop from "lodash/noop";

type FormSchemaInterface<P extends z.Schema> = z.infer<P>;

interface OverlayPopupInterface {
    open: boolean;
    onClose: () => void;
    lastChild?: boolean;
    style?: CSSProperties;
}

const OverlayPopup: FC<PropsWithChildren<OverlayPopupInterface>> = ({
    children,
    open,
    onClose,
    lastChild,
    style,
}) => (
    <div
        className={classNames("overlay-popup", {
            toggle: open,
            "last-child": lastChild,
        })}
        style={style}
    >
        <NotAnchor className="back" onClick={() => onClose()}>
            Back
        </NotAnchor>
        {children}
    </div>
);

const Popup: FC<
    PropsWithChildren<{ name: string; black?: boolean; fullscreen?: boolean }>
> = ({ name, black = true, fullscreen = false, children }) => {
    const { popupOpen, closePopup } = usePopup();

    return (
        <article
            className={classNames("popup-a", "ready", {
                shown: popupOpen[name],
                "color-black-100": black,
                fullscreen,
            })}
        >
            <div className="box-outer">
                <div className="box-inner last-child">
                    <div className="box-inset">
                        {children}
                        <NotAnchor
                            className="close"
                            onClick={() => closePopup(name)}
                        >
                            Close
                        </NotAnchor>
                    </div>
                </div>
                <NotAnchor className="close" onClick={() => closePopup(name)}>
                    Close
                </NotAnchor>
            </div>
        </article>
    );
};

const confirmationPopupSchema = z.object({
    iUnderstand: z.union([z.boolean(), z.undefined()]),
});

interface ConfirmationPopupPropsInterface {
    name: string;
    onSubmit?: () => void;
    submitText?: string;
    mobileSubmitText?: string;
    disabled?: boolean;
    needsIUnderstandCheckbox?: boolean;
    iUnderstandCheckboxId?: string;
    children?:
        | ReactNode
        | ((props: {
              iUnderstandCheckbox: ReactElement;
              errors: FieldErrors<
                  FormSchemaInterface<typeof confirmationPopupSchema>
              >;
          }) => ReactNode);
}

const {
    Form: ConfirmationForm,
    useFormReturnRef: useConfirmationFormReturnRef,
} = createForm(confirmationPopupSchema);

const ConfirmationPopup = ({
    name,
    onSubmit,
    submitText,
    mobileSubmitText,
    children,
    disabled = false,
    needsIUnderstandCheckbox = false,
    iUnderstandCheckboxId = "i-understand",
}: ConfirmationPopupPropsInterface) => {
    const { popupOpen, closePopup } = usePopup();

    const {
        formReturn: { reset, register },
        formReturnRefCallback,
    } = useConfirmationFormReturnRef();

    const defaultValues = useMemo(
        () => ({
            iUnderstand: needsIUnderstandCheckbox ? false : undefined,
        }),
        [needsIUnderstandCheckbox]
    );

    const onCloseClicked_ = useCallback(() => {
        if (!reset) {
            return;
        }
        closePopup(name);
        reset(defaultValues);
    }, [name, reset, closePopup, defaultValues]);

    const onSubmit_ = useCallback(async () => {
        if (onSubmit) {
            await onSubmit();
        }
        onCloseClicked_();
    }, [onSubmit, onCloseClicked_]);

    if (!mobileSubmitText || mobileSubmitText.length === 0) {
        mobileSubmitText = submitText;
    }

    const iUnderstandCheckbox =
        register && needsIUnderstandCheckbox ? (
            <input
                type="checkbox"
                id={iUnderstandCheckboxId}
                {...register("iUnderstand")}
            />
        ) : (
            <></>
        );

    return (
        <ConfirmationForm
            onSubmit={onSubmit_}
            defaultValues={defaultValues}
            formReturnRefCallback={formReturnRefCallback}
            disabled={disabled}
            className={classNames("popup-a", "mobile-bottom", "ready", {
                shown: popupOpen[name],
            })}
        >
            {({ watch, formState: { errors, isSubmitting } }) => (
                <div className="box-outer">
                    <div className="box-inner last-child">
                        <div className="box-inset">
                            {typeof children === "function"
                                ? children({
                                      iUnderstandCheckbox,
                                      errors,
                                  })
                                : children}
                            <footer>
                                <p className="link-btn wide">
                                    <NotAnchor
                                        onClick={onCloseClicked_}
                                        className="close inv mobile-only"
                                    >
                                        Cancel
                                    </NotAnchor>
                                    <button
                                        type="submit"
                                        disabled={
                                            (needsIUnderstandCheckbox &&
                                                !watch("iUnderstand")) ||
                                            isSubmitting ||
                                            disabled
                                        }
                                    >
                                        <span className="mobile-hide">
                                            {submitText}
                                        </span>
                                        <span className="mobile-only">
                                            {mobileSubmitText}
                                        </span>
                                    </button>
                                </p>
                            </footer>
                            <NotAnchor
                                className="close"
                                onClick={onCloseClicked_}
                            >
                                Close
                            </NotAnchor>
                        </div>
                    </div>
                    <NotAnchor className="close" onClick={onCloseClicked_}>
                        Close
                    </NotAnchor>
                </div>
            )}
        </ConfirmationForm>
    );
};

interface UseFormPopupReturn<T extends FieldValues> extends UseFormReturn<T> {
    openOverlayPopup: (overlayIndex: number) => void;
}

interface FormPopupPropsInterface<T extends FieldValues>
    extends Omit<
        FormProps<T>,
        "className" | "children" | "formReturnRefCallback"
    > {
    name: string;
    onClose?: () => void;
    submitText?: string;
    mobileSubmitText?: string;
    cancelText?: string;
    mobileCancelText?: string;
    cancelClicked?: () => Promise<void>;
    overlayPopups?: ReactElement[];
    resetToDefaultValuesOnClose?: boolean;
    submitOnKeyboardEnterEnabled?: boolean;
    formReturnRefCallback?: RefCallback<UseFormReturn<T> | undefined>;
    children?: ReactNode | ((props: UseFormPopupReturn<T>) => ReactNode);
}

const createFormPopup = <T extends FieldValues>(formSchema: z.Schema<T>) => {
    const { Form, useFormReturnRef } = createForm(formSchema);

    const FormPopupContext = createContext<UseFormPopupReturn<T> | undefined>(
        undefined
    );

    const useFormPopupReturn = () => {
        const context = useContext<UseFormPopupReturn<T>>(
            FormPopupContext as unknown as React.Context<UseFormPopupReturn<T>>
        );
        if (!context) {
            throw new Error(
                "useFormPopupReturn must be used within a FormPopup"
            );
        }
        return context;
    };

    const FormPopup = ({
        name,
        formReturnRefCallback,
        children,
        disabled = false,
        defaultValues,
        onSubmit,
        onBlur,
        overlayPopups,
        submitText = "Submit",
        mobileSubmitText,
        cancelText = "Cancel",
        mobileCancelText,
        cancelClicked,
        onClose,
        resetToDefaultValuesOnClose = true,
        submitOnKeyboardEnterEnabled = true,
    }: FormPopupPropsInterface<T>) => {
        if (!onSubmit && !onBlur) {
            throw new Error("onSubmit or onBlur must be set");
        }

        if (mobileSubmitText === undefined) {
            mobileSubmitText = submitText;
        }
        if (mobileCancelText === undefined) {
            mobileCancelText = cancelText;
        }
        const [overlayPopupOpen, setOverlayPopupOpen] = useState<
            number | undefined
        >();

        const openOverlayPopup = useCallback((overlayIndex: number) => {
            setOverlayPopupOpen(overlayIndex);
        }, []);

        const closeOverlayPopup = useCallback(() => {
            setOverlayPopupOpen(undefined);
        }, []);

        const { popupOpen, closePopup } = usePopup();

        const {
            formReturn: { reset },
            formReturnRefCallback: formReturnRefCallbackInner,
        } = useFormReturnRef();

        const formReturnRefCallbackCombined = useCallback(
            (formReturn: UseFormReturn<T>) => {
                if (!formReturnRefCallbackInner) {
                    return;
                }

                formReturnRefCallbackInner(formReturn);
                if (formReturnRefCallback) {
                    formReturnRefCallback(formReturn);
                }

                formReturnRefCallbackInner(formReturn);
            },
            [formReturnRefCallback, formReturnRefCallbackInner]
        );

        const onCloseClicked_ = useCallback(async () => {
            if (!reset) {
                return;
            }
            closePopup(name);
            // @TODO: only reset if closed explicitly (and not if user
            //        clicked on the outside area of the popup by mistake)
            if (resetToDefaultValuesOnClose) {
                reset(defaultValues);
            }

            if (onClose) {
                onClose();
            }
        }, [
            closePopup,
            reset,
            defaultValues,
            name,
            resetToDefaultValuesOnClose,
            onClose,
        ]);

        const onSubmit_ = useCallback(
            async (data: T, event?: BaseSyntheticEvent) => {
                if (event) {
                    event.preventDefault();
                }
                await (onSubmit ?? onBlur ?? noop)(data, event);
                onCloseClicked_();
            },
            [onSubmit, onBlur, onCloseClicked_]
        );

        useEffect(() => {
            if (popupOpen[name] !== null) {
                setOverlayPopupOpen(undefined);
            }
        }, [popupOpen, name]);

        const preventSubmitOnKeyBoardEnter = useCallback(
            (event: KeyboardEvent<HTMLFormElement>) => {
                if (event.code === "Enter" || event.keyCode === 13) {
                    event.preventDefault();
                }
            },
            []
        );

        return (
            <Form
                onSubmit={onSubmit_}
                defaultValues={defaultValues}
                formReturnRefCallback={formReturnRefCallbackCombined}
                className={classNames("popup-a", "mobile-bottom", "ready", {
                    shown: popupOpen[name],
                    "overlay-active": overlayPopupOpen !== undefined,
                })}
                {...(submitOnKeyboardEnterEnabled === false && {
                    onKeyDown: preventSubmitOnKeyBoardEnter,
                })}
            >
                {(formProps) => {
                    const {
                        formState: { isSubmitting },
                    } = formProps;

                    const passedDownFormPopupProps = {
                        ...formProps,
                        openOverlayPopup,
                    };

                    return (
                        <div className="box-outer">
                            <div className="box-inner last-child">
                                <div className="box-inset">
                                    <>
                                        <FormPopupContext.Provider
                                            value={passedDownFormPopupProps}
                                        >
                                            <FieldSet disabled={disabled}>
                                                {typeof children ===
                                                "function" ? (
                                                    children(
                                                        passedDownFormPopupProps
                                                    )
                                                ) : (
                                                    <>{children}</>
                                                )}
                                            </FieldSet>
                                        </FormPopupContext.Provider>
                                        {overlayPopups?.map(
                                            (
                                                overlayPopupContent: ReactElement,
                                                index: number
                                            ) => (
                                                <OverlayPopup
                                                    key={index}
                                                    open={
                                                        overlayPopupOpen ===
                                                        index
                                                    }
                                                    onClose={() =>
                                                        closeOverlayPopup()
                                                    }
                                                >
                                                    {overlayPopupContent}
                                                </OverlayPopup>
                                            )
                                        )}
                                        <footer>
                                            <p className="link-btn wide">
                                                <NotAnchor
                                                    onClick={async () => {
                                                        if (cancelClicked) {
                                                            cancelClicked();
                                                        }
                                                        onCloseClicked_();
                                                    }}
                                                    className={classNames(
                                                        "close inv",
                                                        {
                                                            "mobile-only":
                                                                !cancelClicked,
                                                        }
                                                    )}
                                                >
                                                    <span className="mobile-hide">
                                                        {cancelText}
                                                    </span>
                                                    <span className="mobile-only">
                                                        {mobileCancelText}
                                                    </span>
                                                </NotAnchor>
                                                <button
                                                    type="submit"
                                                    disabled={
                                                        disabled || isSubmitting
                                                    }
                                                >
                                                    <span className="mobile-hide">
                                                        {submitText}
                                                    </span>
                                                    <span className="mobile-only">
                                                        {mobileSubmitText}
                                                    </span>
                                                </button>
                                            </p>
                                        </footer>
                                        <NotAnchor
                                            className="close"
                                            onClick={() => onCloseClicked_()}
                                        >
                                            Close
                                        </NotAnchor>
                                    </>
                                </div>
                            </div>
                            <NotAnchor
                                className="close"
                                onClick={() => onCloseClicked_()}
                            >
                                Close
                            </NotAnchor>
                        </div>
                    );
                }}
            </Form>
        );
    };

    return {
        FormPopup,
        useFormPopupReturn,
        useFormReturnRef,
    };
};

export { ConfirmationPopup, createFormPopup, Popup };
