import {
    ApplicationDatapointV1,
    ApplicationSectionItemV1,
    AssetSectionItemV1,
    BindSectionItemV1,
    GlobalParameterSchemaV1,
    LineItemParameterSchemaV1,
    LineItemSchemaV1,
    ProductVersion,
    SectionHeadingV1,
    SectionParagraphV1,
    SectionV1,
} from "@joshuins/builder";
import compact from "lodash/compact";
import { includes } from "./array";

const getApplicationSections = (
    productVersion: ProductVersion
): SectionV1[] => {
    // filtering out and BindQuestion Sections
    return productVersion.schema.spec.sections.filter((section) =>
        includes(["Application", "Asset"], section.type)
    );
};

const getBindQuestionSections = (
    productVersion: ProductVersion
): SectionV1[] => {
    // filtering out and BindQuestion Sections
    return productVersion.schema.spec.sections.filter(
        (section) => section.type === "BindQuestion"
    );
};

const getApplicationDatapoints = (
    productVersion: ProductVersion
): ApplicationDatapointV1[] => {
    return getDatapoints(getApplicationSections(productVersion));
};

const getBindQuestionDatapoints = (
    productVersion: ProductVersion
): ApplicationDatapointV1[] => {
    return getDatapoints(getBindQuestionSections(productVersion));
};

const getDatapoints = (sections: SectionV1[]): ApplicationDatapointV1[] => {
    return compact(
        sections.flatMap((section) =>
            section.items.flatMap((item) => {
                if ("Datapoint" in item) return item.Datapoint;
            })
        )
    );
};

const findGlobalParameter = (
    productVersion: ProductVersion,
    parameterCode: string
): GlobalParameterSchemaV1 | undefined => {
    return productVersion.schema.spec.global_params.find(
        (parameter) => parameter.code === parameterCode
    );
};

const findGlobalParameterIndex = (
    productVersion: ProductVersion,
    parameterCode: string
): number => {
    return productVersion.schema.spec.global_params.findIndex(
        (parameter) => parameter.code === parameterCode
    );
};

const findLineItem = (
    productVersion: ProductVersion,
    lineItemCode: string
): LineItemSchemaV1 | undefined => {
    return productVersion.schema.spec.line_items.find(
        (lineItem) => lineItem.code === lineItemCode
    );
};

const findLineItemIndex = (
    productVersion: ProductVersion,
    lineItemCode: string
): number => {
    return productVersion.schema.spec.line_items.findIndex(
        (lineItem) => lineItem.code === lineItemCode
    );
};

const findLineItemParameter = (
    lineItem: LineItemSchemaV1,
    parameterCode: string
): LineItemParameterSchemaV1 | undefined => {
    return lineItem.params.find((param) => param.code === parameterCode);
};

const findParameter = (
    productVersion: ProductVersion,
    lineItemCode: string | undefined,
    parameterCode: string
): GlobalParameterSchemaV1 | LineItemParameterSchemaV1 | undefined => {
    const lineItem = lineItemCode
        ? findLineItem(productVersion, lineItemCode)
        : undefined;
    return lineItem
        ? findLineItemParameter(lineItem, parameterCode)
        : findGlobalParameter(productVersion, parameterCode);
};

const findSectionIndex = (
    productVersion: ProductVersion,
    sectionCode: string
): number => {
    return productVersion.schema.spec.sections.findIndex(
        (section) => section.code === sectionCode
    );
};

const findIndexesByDatapointCode = (
    productVersion: ProductVersion,
    datapointCode: string
): {
    sectionIndex: number | undefined;
    datapointIndex: number | undefined;
    datapoint: ApplicationDatapointV1 | undefined;
} => {
    for (const [
        sectionIndex,
        section,
    ] of productVersion.schema.spec.sections.entries()) {
        for (const [datapointIndex, item] of section.items.entries()) {
            if ("Datapoint" in item && item.Datapoint.code === datapointCode) {
                return {
                    sectionIndex,
                    datapointIndex,
                    datapoint: item.Datapoint,
                };
            }
        }
    }
    return {
        sectionIndex: undefined,
        datapointIndex: undefined,
        datapoint: undefined,
    };
};

const findIndexesByParagraphCode = (
    productVersion: ProductVersion,
    datapointCode: string
): {
    sectionIndex: number;
    paragraphIndex: number;
    paragraph: SectionParagraphV1 | undefined;
} => {
    for (const [
        sectionIndex,
        section,
    ] of productVersion.schema.spec.sections.entries()) {
        for (const [paragraphIndex, item] of section.items.entries()) {
            if ("Paragraph" in item && item.Paragraph.code === datapointCode) {
                return {
                    sectionIndex,
                    paragraphIndex,
                    paragraph: item.Paragraph,
                };
            }
        }
    }
    return {
        sectionIndex: -1,
        paragraphIndex: -1,
        paragraph: undefined,
    };
};

const findIndexesByHeadingCode = (
    productVersion: ProductVersion,
    datapointCode: string
): {
    sectionIndex: number;
    headingIndex: number;
    heading: SectionHeadingV1 | undefined;
} => {
    for (const [
        sectionIndex,
        section,
    ] of productVersion.schema.spec.sections.entries()) {
        for (const [headingIndex, item] of section.items.entries()) {
            if ("Heading" in item && item.Heading.code === datapointCode) {
                return {
                    sectionIndex,
                    headingIndex,
                    heading: item.Heading,
                };
            }
        }
    }
    return {
        sectionIndex: -1,
        headingIndex: -1,
        heading: undefined,
    };
};

const findIndexesBySectionItemCode = (
    productVersion: ProductVersion,
    itemCode: string
): {
    sectionIndex: number;
    itemIndex: number;
} => {
    for (const [
        sectionIndex,
        section,
    ] of productVersion.schema.spec.sections.entries()) {
        for (const [itemIndex, item] of section.items.entries()) {
            if ("Datapoint" in item && item.Datapoint.code === itemCode) {
                return {
                    sectionIndex,
                    itemIndex,
                };
            } else if (
                "Paragraph" in item &&
                item.Paragraph.code === itemCode
            ) {
                return {
                    sectionIndex,
                    itemIndex,
                };
            } else if ("Heading" in item && item.Heading.code === itemCode) {
                return {
                    sectionIndex,
                    itemIndex,
                };
            }
        }
    }
    return {
        sectionIndex: -1,
        itemIndex: -1,
    };
};

const getItemCode = (
    item: ApplicationSectionItemV1 | AssetSectionItemV1 | BindSectionItemV1
): string | undefined => {
    if ("Datapoint" in item) {
        return item.Datapoint.code;
    } else if ("Paragraph" in item) {
        return item.Paragraph.code;
    } else if ("Heading" in item) {
        return item.Heading.code;
    }
    return undefined;
};

const getItemTitle = (
    item: ApplicationSectionItemV1 | AssetSectionItemV1 | BindSectionItemV1
): string => {
    if ("Datapoint" in item) {
        return item.Datapoint.title;
    } else if ("Paragraph" in item) {
        return item.Paragraph.text;
    } else if ("Heading" in item) {
        return item.Heading.text;
    }
    return "No title";
};

const setItemCode = (
    item: ApplicationSectionItemV1 | AssetSectionItemV1 | BindSectionItemV1,
    code: string
): ApplicationSectionItemV1 | AssetSectionItemV1 | BindSectionItemV1 => {
    if ("Datapoint" in item) {
        item.Datapoint.code = code;
    } else if ("Paragraph" in item) {
        item.Paragraph.code = code;
    } else if ("Heading" in item) {
        item.Heading.code = code;
    }
    return item;
};

const orderSections = (sections: SectionV1[]): SectionV1[] => {
    const indicationSections = sections.filter(
        (section) => "is_indication" in section && section.is_indication
    );
    const applicationSections: SectionV1[] = sections.filter(
        (section) =>
            section.type !== "BindQuestion" &&
            (!("is_indication" in section) || !section.is_indication)
    );
    const bindSections: SectionV1[] = sections.filter(
        (section) => section.type === "BindQuestion"
    );
    return [...indicationSections, ...applicationSections, ...bindSections];
};

export {
    getApplicationSections,
    getApplicationDatapoints,
    getBindQuestionSections,
    getBindQuestionDatapoints,
    findGlobalParameterIndex,
    findGlobalParameter,
    findLineItem,
    findLineItemIndex,
    findLineItemParameter,
    findParameter,
    findSectionIndex,
    findIndexesByDatapointCode,
    findIndexesByHeadingCode,
    findIndexesByParagraphCode,
    findIndexesBySectionItemCode,
    getItemCode,
    getItemTitle,
    setItemCode,
    orderSections,
};
