import { useEffect, useState, FC } from "react";
import { useSearchParams } from "react-router-dom";
import { useApi } from "contexts/ApiProvider";

import { useForm, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";

import { PASSWORD_REGEX } from "components/PasswordInput";
import { FullPageMessage1 } from "components/FullPageMessage";
import { useBranding } from "contexts/BrandingProvider";

import axios from "axios";
import { hideLoader, showLoader } from "paul/native-dom-manipulation";
import useLoginProviders from "utils/use-login-providers";
import RegistrationForm from "./RegistrationForm";
import { getMessageFromAxiosError } from "utils/axios-extras";

interface InitialValues {
    email: string;
}

const registrationFinishSchema = z.object({
    email: z.string().email(),
    first_name: z.string().min(1),
    last_name: z.string().min(1),
    password: z.string().regex(PASSWORD_REGEX),
});

type RegistrationFinishType = z.infer<typeof registrationFinishSchema>;

interface RegistrationFinishFormProps {
    initialValues: InitialValues | undefined;
    onSubmit: SubmitHandler<RegistrationFinishType>;
    submissionFailedMessage?: string;
}

const RegistrationFinishForm: FC<RegistrationFinishFormProps> = ({
    initialValues,
    onSubmit,
    submissionFailedMessage = undefined,
}) => {
    const { register, handleSubmit, reset } = useForm<RegistrationFinishType>({
        resolver: zodResolver(registrationFinishSchema),
        defaultValues: initialValues,
    });
    const { ssoProviders, passwordEnabled, googleEnabled } =
        useLoginProviders();

    useEffect(() => {
        reset(initialValues);
    }, [initialValues, reset]);

    return (
        <RegistrationForm
            onSubmit={onSubmit}
            handleSubmit={handleSubmit}
            googleEnabled={googleEnabled}
            ssoProviders={ssoProviders}
            passwordEnabled={passwordEnabled}
            register={register}
            submissionFailedMessage={submissionFailedMessage}
        />
    );
};

const RegistrationFinishPage: FC = () => {
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");
    const [errorMessage, setErrorMessage] = useState<{
        type: "full-page" | "label";
        message: string;
    }>();
    const [emailFromToken, setEmailFromToken] = useState<string | undefined>(
        ""
    );
    const [initialValues, setInitialValues] = useState<
        InitialValues | undefined
    >();
    const { sdkAuthForProcess, apiAuthProcess } = useApi();
    const { generateNextUrl } = useBranding();
    const nextUrl = generateNextUrl();

    useEffect(() => {
        const verifyToken = async () => {
            let errorMessage_: string | undefined;

            // sdkAuthForProcess changes when the user logs in so the useEffect runs
            // again. So we check also if the emailFromToken has already been set so
            // that the sdkAuthForProcess method call doesn't happen again.
            if (token && !emailFromToken) {
                try {
                    const data =
                        await sdkAuthForProcess.registrationVerifyToken({
                            VerifyRegistrationTokenRequest: {
                                token,
                            },
                        });
                    setEmailFromToken(data.email);
                    setInitialValues(data);
                } catch (error) {
                    if (axios.isAxiosError(error)) {
                        setEmailFromToken(undefined);
                        setInitialValues(undefined);
                        errorMessage_ = getMessageFromAxiosError(error);
                    } else {
                        throw error;
                    }
                }
            } else if (!emailFromToken) {
                errorMessage_ = "Error";
            }

            setErrorMessage(
                errorMessage_
                    ? {
                          type: "full-page",
                          message: errorMessage_,
                      }
                    : undefined
            );
        };
        verifyToken();
    }, [sdkAuthForProcess, token, emailFromToken]);

    const onSubmit = async (data: RegistrationFinishType) => {
        if (!emailFromToken) {
            return;
        }

        showLoader();
        const { first_name, last_name, password } = data;

        let errorMessage_: string | undefined;

        if (token) {
            try {
                await sdkAuthForProcess.registrationFinish({
                    FinishRegistrationRequest: {
                        token,
                        first_name,
                        last_name,
                        password,
                    },
                });
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    errorMessage_ = getMessageFromAxiosError(error);
                } else {
                    throw error;
                }
            }
        } else {
            errorMessage_ = "Error";
        }

        if (errorMessage_) {
            setErrorMessage({
                type: "label",
                message: errorMessage_,
            });
            hideLoader();
        } else {
            apiAuthProcess.login(emailFromToken, password, nextUrl);
        }
    };

    let determinedErrorMessage;
    if (errorMessage) {
        determinedErrorMessage = errorMessage;
    } else if (apiAuthProcess.authProcessErrorMessage) {
        determinedErrorMessage = {
            type: "label",
            message: apiAuthProcess.authProcessErrorMessage,
        };
    }

    return (
        <main id="content">
            {determinedErrorMessage?.type === "full-page" ? (
                <FullPageMessage1
                    title="We had a problem completing the registration"
                    message={determinedErrorMessage.message}
                    iconType="triangle"
                />
            ) : (
                <RegistrationFinishForm
                    onSubmit={onSubmit}
                    submissionFailedMessage={determinedErrorMessage?.message}
                    initialValues={initialValues}
                />
            )}
        </main>
    );
};

export default RegistrationFinishPage;
