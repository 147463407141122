import sortBy_ from "lodash/sortBy";
import groupBy_ from "lodash/groupBy";
import values from "lodash/values";

const rejectFirstMatch = <T>(
    array: T[],
    predictate: (value: T) => boolean
): T[] => {
    const foundIndex = array.findIndex(predictate);
    const newArray = array.slice();
    if (foundIndex !== -1) {
        newArray.splice(foundIndex, 1);
    }
    return newArray;
};

/**
    This function takes an array of objects and does 3 things:
    1) groups the objects by groupBy
    2) retrieves the first (min/max according to the "firstIsMax" argument) element in each group by sortBy
    3) sort the results again by sortBy. The reason for sorting again is because groupBy in step 2 produces
    an object and its not very clear if object key ordering is guaranteed universally, we have to sort again.
*/
const firstInGroupAndSort = <T>(
    arr: T[],
    groupBy: keyof T,
    sortBy: keyof T,
    firstIsMax = true
) => {
    let sorted = sortBy_(arr, sortBy);
    if (firstIsMax) {
        sorted = sorted.reverse();
    }

    let sortedFirstsInGroup = sortBy_(
        values(groupBy_(sorted, groupBy)).map((item) => item[0]),
        sortBy
    );

    if (firstIsMax) {
        sortedFirstsInGroup = sortedFirstsInGroup.reverse();
    }

    return sortedFirstsInGroup;
};

// https://fettblog.eu/typescript-array-includes/
const includes = <T extends U, U>(array: ReadonlyArray<T>, el: U): el is T =>
    array.includes(el as T);

const uniquelyConcatValue = <T>(array: T[], value: T) => {
    if (array.indexOf(value) === -1) {
        return [...array, value];
    } else {
        return [...array];
    }
};

export { rejectFirstMatch, includes, firstInGroupAndSort, uniquelyConcatValue };
