import { FC, useCallback, useState } from "react";
import { ProductVersion } from "@joshuins/builder";
import FormControl from "@mui/material/FormControl/FormControl";
import { DatePicker } from "components/ReactHookFormUncontrolledComponents";
import classNames from "classnames";
import NotAnchor from "components/NotAnchor";
import { usePage } from "components/Page";
import { ConfirmationPopup, createFormPopup } from "components/Popup";
import { useApi } from "contexts/ApiProvider";
import { usePopup } from "contexts/PopupProvider";
import { formatDate } from "utils/datetime";
import { z } from "zod";

const publishProductVersionSchema = z.object({
    effectiveDate: z.date(),
});

type PublishPoductVersionType = z.infer<typeof publishProductVersionSchema>;

const { FormPopup: PublishPoductVersionFormPopup } = createFormPopup(
    publishProductVersionSchema
);

const PublishProductVersionPopup: FC = () => {
    const { openPopup } = usePopup();
    const [effectiveDate] = useState<Date>(new Date());

    const onSubmit = useCallback(
        (data: PublishPoductVersionType) => {
            openPopup("publish-product-version-confirmation", {
                effectiveDate: data.effectiveDate,
            });
        },
        [openPopup]
    );

    return (
        <PublishPoductVersionFormPopup
            name="publish-product-version"
            defaultValues={{
                effectiveDate: new Date(),
            }}
            onSubmit={onSubmit}
            submitText="Next"
            overlayPopups={[
                <>
                    <h3>Effective Date</h3>
                    <p>
                        This is the date at which the product version becomes
                        active. Any policies taken out with this product will
                        use this product version if the required start date of
                        the policy is after this effective date
                    </p>
                    <p>
                        The effective date is unique to the product version. Any
                        subsequent versions must have a different effective
                        date.
                    </p>
                </>,
            ]}
        >
            {({ openOverlayPopup, control, formState: { errors } }) => {
                return (
                    <>
                        <header>
                            <h2>Publish Product Version</h2>
                        </header>
                        <div
                            className={classNames("div-as-p", {
                                "has-error": errors.effectiveDate,
                            })}
                        >
                            <span className="label">
                                Effective Date
                                <span className="text-right">
                                    <NotAnchor
                                        onClick={() => openOverlayPopup(0)}
                                        className="text-right"
                                        data-overlay="ns2"
                                    >
                                        <i
                                            aria-hidden="true"
                                            className="icon-help"
                                        />
                                        <span className="hidden">
                                            More info
                                        </span>
                                    </NotAnchor>
                                </span>
                            </span>
                            <span className="input">
                                <FormControl fullWidth>
                                    <DatePicker
                                        control={control}
                                        name="effectiveDate"
                                        defaultValue={new Date()}
                                        value={effectiveDate}
                                        minDate={new Date()}
                                    />
                                </FormControl>
                            </span>
                            {errors.effectiveDate && (
                                <label
                                    id="effective-date-input-error"
                                    className="error"
                                    htmlFor="effective-date-input"
                                >
                                    {errors.effectiveDate.message}
                                </label>
                            )}
                        </div>
                    </>
                );
            }}
        </PublishPoductVersionFormPopup>
    );
};

const PublishProductVersionConfirmationPopup: FC = () => {
    const { refreshElement, tryCatchAndRaiseError, element } = usePage();
    const { sdkBuilder } = useApi();
    const productVersion = element as unknown as ProductVersion | undefined;
    const { popupData } = usePopup();
    const effectiveDate = popupData?.effectiveDate as Date | undefined;

    const onSubmit = async () => {
        if (!productVersion || !effectiveDate) {
            return;
        }
        tryCatchAndRaiseError(async () => {
            await sdkBuilder.updateProductVersion({
                id: productVersion.id,
                UpdateProductVersion: {
                    effective_date: formatDate(effectiveDate, "apiDate"),
                },
            });
        }, refreshElement);
    };

    return (
        <ConfirmationPopup
            name="publish-product-version-confirmation"
            onSubmit={onSubmit}
            submitText="Publish"
            needsIUnderstandCheckbox
            iUnderstandCheckboxId="confirm-publish-product-version"
        >
            {({ iUnderstandCheckbox }) => (
                <>
                    <header>
                        <h2>Ready to go live?</h2>
                    </header>
                    <ul className="list-static a">
                        <li>
                            This version of the product will be{" "}
                            <span className="strong">locked</span> (to make
                            changes, use the New Version button)
                        </li>
                        <li>
                            Draft submissions, quotes, and policies for this
                            product version will be{" "}
                            <span className="strong">deleted</span>
                        </li>
                        <li>
                            Draft versions of this product in any store will be{" "}
                            <span className="strong">published</span>
                        </li>
                        <li>
                            Any version of the product that this replaces will
                            be{" "}
                            <span className="strong">
                                updated in all stores
                            </span>
                        </li>
                    </ul>
                    <p className="check box">
                        {iUnderstandCheckbox}
                        <label htmlFor="confirm-publish-product-version">
                            I understand this will lock this version of the
                            product and make it live
                        </label>
                    </p>
                </>
            )}
        </ConfirmationPopup>
    );
};

export { PublishProductVersionPopup, PublishProductVersionConfirmationPopup };
