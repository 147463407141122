import { FC, useCallback, useEffect, useState } from "react";
import { usePage } from "components/Page";
import { BindSectionItemV1, SectionV1 } from "@joshuins/insurance";
import { useApi } from "contexts/ApiProvider";
import { PolicyFooter } from "../PolicyFooter";
import { getItemCode } from "utils/product-version";
import keyBy from "lodash/keyBy";
import { useInsuranceProcess } from "./InsuranceProcessProvider";
import { QuoteFooter } from "../QuoteFooter";
import { Footer } from "components/MainPane";
import { InputChanged } from "pages/underwriter/submissions/ApplicationPage";
import {
    formToApiValue,
    getJsxElement,
} from "pages/underwriter/submissions/components/SectionItems";
import { useBindQuestion } from "pages/underwriter/submissions/components/BindQuestionProvider";
import { addBindPrefix } from "pages/underwriter/submissions/components/utils";

const BindSectionItemComponent: FC<{
    sectionItem: BindSectionItemV1;
    inputChanged: InputChanged;
    bindSection: SectionV1;
}> = ({ sectionItem, inputChanged, bindSection }) => {
    const {
        editable,
        itemGetters: { getSubmissionDataFiles, getSubmissionData },
    } = useBindQuestion();
    const { sdkBuilder } = useApi();
    const [jsxElement, setJsxElement] = useState<JSX.Element>();

    useEffect(() => {
        const submissionDataLookup = keyBy(getSubmissionData(), "code");
        const getJsxElement_ = async () => {
            if (!bindSection) {
                return;
            }
            const datapointCode =
                "Datapoint" in sectionItem ? sectionItem.Datapoint.code : null;
            const joValue =
                datapointCode && submissionDataLookup[datapointCode]
                    ? submissionDataLookup[datapointCode].value
                    : null;

            setJsxElement(
                await getJsxElement({
                    sectionItem,
                    joValue,
                    inputChanged,
                    sdkBuilder,
                    editable,
                    assetIndex: undefined,
                    files: getSubmissionDataFiles(),
                })
            );
        };
        getJsxElement_();
    }, [
        bindSection,
        editable,
        getSubmissionData,
        getSubmissionDataFiles,
        inputChanged,
        sdkBuilder,
        sectionItem,
    ]);

    if (!sectionItem || !jsxElement) {
        return <></>;
    }

    return jsxElement;
};

const BindQuestions: FC = () => {
    const {
        refreshSubmissionData,
        itemGetters: { datapoint: datapoint_ },
    } = useBindQuestion();
    const {
        itemGetters: { getInsuranceProcess },
    } = useInsuranceProcess();
    const { submission, productVersion } = getInsuranceProcess();
    const { sdkInsurance, sdkBuilder } = useApi();
    const { tryCatchAndRaiseError } = usePage();
    // TODO: uncomment once BindQuestion section is in SubmissionDataStatus (DEV-3806)
    // const SubmissionDataStatusLookUp = keyBy(
    //     getSubmissionDataStatus().sections.flatMap(
    //         (section) => section.datapoints
    //     ),
    //     "code"
    // );
    // const { storeUrlName } = useBranding();
    // const inStore = storeUrlName !== undefined;
    const {
        pageIds: { quoteId: pageQuoteId },
    } = useInsuranceProcess();

    const inputChanged: InputChanged = useCallback(
        async (newFormValue, fieldName) => {
            if (!submission) {
                return;
            }

            const code = addBindPrefix(fieldName);
            const datapoint = datapoint_(code);

            if (!datapoint) {
                return;
            }
            tryCatchAndRaiseError(async () => {
                await sdkInsurance.updateSubmissionData({
                    id: submission.id,
                    CreateSubmissionData: {
                        data: [
                            {
                                code,
                                value: await formToApiValue(
                                    datapoint,
                                    newFormValue,
                                    sdkBuilder
                                ),
                            },
                        ],
                    },
                });
                refreshSubmissionData();
            });
        },
        [
            datapoint_,
            refreshSubmissionData,
            sdkBuilder,
            sdkInsurance,
            submission,
            tryCatchAndRaiseError,
        ]
    );

    const BindQuestionsSection = productVersion.schema.spec.sections.filter(
        (section) => section.type === "BindQuestion"
    )[0];

    return (
        <>
            <h1 className="has-anchor">
                <div className="anchor" />
            </h1>
            <div className={"form-section"}>
                {BindQuestionsSection.items.map((sectionItem) => {
                    const code = getItemCode(sectionItem);
                    // TODO: uncomment once BindQuestion section is in SubmissionDataStatus (DEV-3806)
                    // let conditionMet = true;
                    // let showQuestion = true;

                    // if ("Datapoint" in sectionItem && code) {
                    //     showQuestion = !inStore
                    //         ? true
                    //         : sectionItem.Datapoint.source ===
                    //           DatapointSourceV1.User;
                    //     conditionMet =
                    //         SubmissionDataStatusLookUp[code] &&
                    //         SubmissionDataStatusLookUp[code].condition_met !=
                    //             false;
                    // }

                    // if (code && showQuestion && conditionMet) {
                    return (
                        <BindSectionItemComponent
                            key={code}
                            sectionItem={sectionItem}
                            inputChanged={inputChanged}
                            bindSection={BindQuestionsSection}
                        />
                    );
                    // }
                })}
                <Footer>
                    {pageQuoteId ? <QuoteFooter /> : <PolicyFooter />}
                </Footer>
            </div>
        </>
    );
};

export { BindQuestions };
