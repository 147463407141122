import { FC, useEffect } from "react";
import ExtraPane from "components/extra-panes/ExtraPane";
import { Footer } from "components/MainPane";
import NotAnchor from "components/NotAnchor";
import { AlertCategory, Page, TabInterface, usePage } from "components/Page";
import { Popup } from "components/Popup";
import { Select } from "components/ReactHookFormUncontrolledComponents";
import { MenuItem } from "components/Select";
import { useApi } from "contexts/ApiProvider";
import { usePopup } from "contexts/PopupProvider";
import FormControl from "@mui/material/FormControl";
import ListItemIcon from "@mui/material/ListItemIcon";
import { z } from "zod";
import { createForm } from "components/Form";
import startCase from "lodash/startCase";
import { ProductVersion, RenewalSetting } from "@joshuins/builder";
import {
    MAX_PRODUCT_DESCRIPTION_LENGTH,
    MAX_PRODUCT_NAME_LENGTH,
    MAX_WATERMARK_LENGTH,
    PRODUCT_VERSION_TYPES,
} from "./components/util";
import { includes } from "utils/array";
import ProductMainPane from "./components/ProductMainPane";
import PolicyAllocationsPage, {
    AddOrEditAllocationPopup,
    AddOrEditPolicyIdPopup,
    DeleteAllocationPopup,
    DeletePolicyIdPopup,
} from "./PolicyIdsPage";

interface SettingsPageInterface {
    tabs?: TabInterface[];
}

const productVersionSettingsSchema = z.object({
    name: z
        .string()
        .min(1, "This field is required")
        .max(
            MAX_PRODUCT_NAME_LENGTH,
            `${MAX_PRODUCT_NAME_LENGTH} Character Limit`
        ),
    internal_version_name: z.union([
        z
            .string()
            .max(
                MAX_PRODUCT_NAME_LENGTH,
                `${MAX_PRODUCT_NAME_LENGTH} Character Limit`
            ),
        z.null(),
    ]),
    description: z.union([
        z
            .string()
            .max(
                MAX_PRODUCT_DESCRIPTION_LENGTH,
                `${MAX_PRODUCT_DESCRIPTION_LENGTH} Character Limit`
            ),
        z.null(),
    ]),
    product_type: z.union([z.string(), z.null()]),
    quote_expires_at_days: z.union([z.number(), z.string()]),
    binder_expires_at_days: z.union([z.number(), z.string()]),
    renewal_period: z.union([z.number(), z.string()]),
    // z.nativeEnum() is used to validate against an enum from a third-party, rather than a string[] as const, then z.enum() can be used.
    renewal_setting: z.nativeEnum(RenewalSetting),
    use_clearance: z.boolean(),
    watermark: z
        .object({
            enabled: z.boolean(),
            text: z.string().optional(),
        })
        .refine(
            (value) =>
                value.enabled !== true ||
                (value.text &&
                    value.text.length > 0 &&
                    value.text.length <= MAX_WATERMARK_LENGTH),
            {
                message: `Please provide text, no longer than ${MAX_WATERMARK_LENGTH} characters`,
                path: ["text"],
            }
        ),
});
type ProductVersionSettings = z.infer<typeof productVersionSettingsSchema>;

const EXPIRATION_OPTIONS = [
    { value: 30, title: "30 Days" },
    { value: 45, title: "45 Days" },
    { value: 60, title: "60 Days" },
] as const;
const EXPIRATION_OPTION_VALUES = EXPIRATION_OPTIONS.map(({ value }) => value);

const RENEWAL_OPTIONS = [
    { value: 30, title: "30 Days" },
    { value: 60, title: "60 Days" },
    { value: 90, title: "90 Days" },
    { value: 120, title: "120 Days" },
] as const;

const getRenewalPeriod = (productVersion: ProductVersion) => {
    const renewal = productVersion.schema.spec.renewal;
    if (typeof renewal === "object" && "Automatic" in renewal) {
        return {
            renewal_setting: RenewalSetting.AutoRenewal,
            renewal_period: renewal.Automatic.period.days,
        };
    }
    if (typeof renewal === "object" && "ReviewRequired" in renewal) {
        return {
            renewal_setting: RenewalSetting.ReviewRequired,
            renewal_period: renewal.ReviewRequired.period.days,
        };
    }
    return {
        renewal_setting: RenewalSetting.NonRenewable,
        renewal_period: "",
    };
};

const getChecksSettings = (productVersion: ProductVersion) => {
    const checks = productVersion.schema.spec.checks;
    return {
        use_clearance: checks.clearance.codes.length > 0,
    };
};

const SettingsHelp = () => (
    <ExtraPane>
        <h3>
            <i aria-hidden="true" className="icon-help" /> Settings
        </h3>
        <p>
            These settings apply to this product only, in all stores where it is
            published.
        </p>
        <p>
            Some &quot;General&quot; settings are locked when published. If you
            need to change these, duplicate the product to make another version.
        </p>
        <p>
            &quot;Availability&quot; controls where the product can be shown if
            you have stores in multiple countries.
        </p>
    </ExtraPane>
);

const MoreAbourWaterMarkPopup = () => (
    <Popup name="more-about-watermark">
        <header>
            <h2 className="color-primary font-medium">Watermark</h2>
        </header>
        <p>
            If set, Preview Documents will be created, with the requested text
            added to their background
        </p>
    </Popup>
);

const MoreProductVersionNamePopup = () => (
    <Popup name="more-about-product-name">
        <header>
            <h2 className="color-primary font-medium">Product Name</h2>
        </header>
        <p>
            This is how the product will be listed in stores, quotes, and
            documents. Keep it as short as possible. It is locked after
            publishing.
        </p>
    </Popup>
);

const MoreInternalVersionPopup = () => (
    <Popup name="more-about-internal-version-name">
        <header>
            <h2 className="color-primary font-medium">Internal Version Name</h2>
        </header>
        <p>
            Used to note differences between products. For example, if you
            modify your product you may want to add a version number or date.
        </p>
        <p>
            The description will display as part of the meta data below the
            product name in Joshu and is not visible in stores.
        </p>
    </Popup>
);

const MoreStoreDescriptionPopup = () => (
    <Popup name="more-about-store-description">
        <header>
            <h2 className="color-primary font-medium">Store Description</h2>
        </header>
        <p>
            Enter a short line of text to be displayed with the product name
            when beginning a submission. For example, for a commercial property
            product, the one line description might be &quot;Commercial property
            protection for small business&quot;
        </p>
    </Popup>
);

const MoreProductVersionTypePopup = () => (
    <Popup name="more-about-product-type">
        <header>
            <h2 className="color-primary font-medium">Product Type</h2>
        </header>
        <p>TBD</p>
    </Popup>
);

const MoreQuoteExpirationPopup = () => (
    <Popup name="more-about-quote-expiration">
        <header>
            <h2 className="color-primary font-medium">Quote Expiration</h2>
        </header>
        <p>Choose when you want your quote honoring period to expire.</p>
    </Popup>
);

const MoreBinderExpirationPopup = () => (
    <Popup name="more-about-binder-expiration">
        <header>
            <h2 className="color-primary font-medium">Binder Expiration</h2>
        </header>
        <p>Choose when you want your binder honoring period to expire.</p>
    </Popup>
);

const MoreRenewalPeriodPopup = () => (
    <Popup name="more-about-renewal-period">
        <header>
            <h2 className="color-primary font-medium">Renewal Period Starts</h2>
        </header>
        <p>
            How many days before expiration do you want to start the renewal
            process? This will happen automatically and the system will run the
            rater and notify the underwriters that a policy is eligible for
            renewal.
        </p>
    </Popup>
);

const MoreUWRenewalPeriodPopup = () => (
    <Popup name="more-about-underwriter-non-renewal-period">
        <header>
            <h2 className="color-primary font-medium">
                Underwriter Non-Renewal Period
            </h2>
        </header>
        <p>
            How many days before expiration is the cutoff for underwriters to
            decline a renewal? For example, if the renewal starts at 120 days
            out, and the non-renewal period is 60 days, the underwriter can only
            decline in the first 60 days of the renewal period.
        </p>
    </Popup>
);

const MoreUWRenewalSettingPopup = () => (
    <Popup name="more-about-renewal-setting">
        <header>
            <h2 className="color-primary font-medium">Renewal Setting</h2>
        </header>
        <p>
            When &quot;Review Required&quot; is set, in order to issue a renewal
            policy, it first needs to go through underwriter approval.
        </p>
        <p>
            When &quot;Auto Renewal&quot; is set, the policy will be renewed
            automatically.
        </p>
        <p>
            When &quot;Non-Renewable&quot; is set, no renewal process will
            occur, and the policy will automatically expire on the expiration
            day.
        </p>
        <p>
            Note that this is not permanent, and an underwriter can also adjust
            the status per policy (in the policy page) once it is issued.
        </p>
    </Popup>
);

const ClearanceHelpPopup = () => (
    <Popup name="clearance-help">
        <header>
            <h2 className="color-primary font-medium">Clearance</h2>
        </header>
        <p className="last-child">
            Selecting this box will use the name and address (and website and
            class of business if you enable them) for clearance. We will compare
            new submissions with active quotes and policies in the Joshu
            database to identify any conflicts.
        </p>
    </Popup>
);

const { Form, useFormReturnRef } = createForm(productVersionSettingsSchema);

const Main = () => {
    const { sdkBuilder } = useApi();
    const { addAlertMessages, element, tryCatchAndRaiseError } = usePage();
    const { openPopup } = usePopup();
    const productVersion = element as unknown as ProductVersion | undefined;
    const {
        formReturnRefCallback,
        formReturn: { reset },
    } = useFormReturnRef();
    const { activeTab } = usePage();
    const activeTab_ = activeTab();

    const transformApiDataToFormData = (productVersion: ProductVersion) => {
        const { renewal_setting, renewal_period } =
            getRenewalPeriod(productVersion);
        const { use_clearance } = getChecksSettings(productVersion);
        return {
            name: productVersion.schema.metadata.name,
            internal_version_name:
                productVersion.schema.metadata.internal_version_name,
            description: productVersion.schema.metadata.description,
            product_type: productVersion.schema.metadata.product_type,
            quote_expires_at_days: includes(
                EXPIRATION_OPTION_VALUES,
                productVersion.schema.spec.expiration.quotes.days
            )
                ? productVersion.schema.spec.expiration.quotes.days
                : "",
            binder_expires_at_days: includes(
                EXPIRATION_OPTION_VALUES,
                productVersion.schema.spec.expiration.binders.days
            )
                ? productVersion.schema.spec.expiration.binders.days
                : "",
            renewal_period,
            renewal_setting,
            use_clearance,
            watermark: {
                enabled:
                    productVersion.schema.spec.documents.settings
                        ?.preview_watermark !== null,
                text:
                    productVersion.schema.spec.documents.settings
                        ?.preview_watermark || undefined,
            },
        };
    };

    useEffect(() => {
        if (!productVersion || !reset) {
            return;
        }
        reset(transformApiDataToFormData(productVersion));
    }, [productVersion, reset]);

    const onSubmit_ = async (data: ProductVersionSettings) => {
        if (!productVersion || !reset) {
            return;
        }
        tryCatchAndRaiseError(async () => {
            productVersion.schema.metadata = {
                name: data.name || "",
                internal_version_name: data.internal_version_name,
                description: data.description,
                product_type: data.product_type,
            };
            if (
                data.watermark.enabled &&
                data.watermark.text &&
                data.watermark.text.length > 0
            ) {
                productVersion.schema.spec.documents = {
                    ...productVersion.schema.spec.documents,
                    settings: {
                        preview_watermark: data.watermark.text,
                    },
                };
            } else {
                productVersion.schema.spec.documents = {
                    ...productVersion.schema.spec.documents,
                    settings: {
                        preview_watermark: null,
                    },
                };
            }
            if (
                typeof data.quote_expires_at_days === "number" &&
                data.quote_expires_at_days
            ) {
                productVersion.schema.spec.expiration.quotes.days =
                    data.quote_expires_at_days;
            }
            if (
                typeof data.binder_expires_at_days === "number" &&
                data.binder_expires_at_days
            ) {
                productVersion.schema.spec.expiration.binders.days =
                    data.binder_expires_at_days;
            }
            if (data.renewal_setting !== undefined && data.renewal_period) {
                if (data.renewal_setting === "AutoRenewal") {
                    productVersion.schema.spec.renewal = {
                        Automatic: {
                            period: {
                                days: parseInt(data.renewal_period.toString()),
                            },
                        },
                    };
                } else if (data.renewal_setting === "ReviewRequired") {
                    productVersion.schema.spec.renewal = {
                        ReviewRequired: {
                            period: {
                                days: parseInt(data.renewal_period.toString()),
                            },
                        },
                    };
                } else {
                    // TODO change RenewalSetting to a better enum format
                    productVersion.schema.spec.renewal = "None";
                }
            }
            if (data.use_clearance) {
                productVersion.schema.spec.checks.clearance.codes = [
                    "insured.name",
                    "insured.address",
                ];
            } else {
                productVersion.schema.spec.checks.clearance.codes = [];
            }
            const updatedProductVersion = await sdkBuilder.updateProductVersion(
                {
                    id: productVersion.id,
                    UpdateProductVersion: { schema: productVersion.schema },
                }
            );
            addAlertMessages({
                message: "Product settings have been saved!",
                category: AlertCategory.SUCCESS,
            });
            reset(transformApiDataToFormData(updatedProductVersion));
        });
    };

    if (!productVersion || !activeTab_) {
        return;
    }

    if (activeTab_.path === "general") {
        return (
            <ProductMainPane
                title="Settings"
                layoutConfig={{ headerBottomMargin: true }}
            >
                <div>
                    <Form
                        onSubmit={onSubmit_}
                        className="form-validate"
                        disabled={productVersion.is_published}
                        formReturnRefCallback={formReturnRefCallback}
                        defaultValues={{
                            name: "",
                            internal_version_name: "",
                            product_type: "",
                            description: "",
                            quote_expires_at_days: 30,
                            binder_expires_at_days: 30,
                            renewal_period: 30,
                            use_clearance: false,
                            renewal_setting: "ReviewRequired",
                            watermark: { enabled: false, text: "" },
                        }}
                    >
                        {({
                            register,
                            control,
                            watch,
                            formState: { isDirty, errors },
                        }) => (
                            <>
                                <div className="form-box">
                                    <h2 className="m25 color-primary">
                                        Product Details
                                    </h2>
                                    <div className="cols">
                                        <div className="c50 div-as-p">
                                            <label htmlFor="fba">
                                                Product Name
                                                <span className="text-right">
                                                    {MAX_PRODUCT_NAME_LENGTH}{" "}
                                                    Character Limit{" "}
                                                    <NotAnchor
                                                        onClick={() =>
                                                            openPopup(
                                                                "more-about-product-name"
                                                            )
                                                        }
                                                        className="text-right"
                                                    >
                                                        <i
                                                            aria-hidden="true"
                                                            className="icon-help"
                                                        />
                                                        <span className="hidden">
                                                            More info
                                                        </span>
                                                    </NotAnchor>
                                                </span>
                                            </label>
                                            <span
                                                className={
                                                    productVersion &&
                                                    productVersion.is_published
                                                        ? "input-prefix"
                                                        : "input"
                                                }
                                            >
                                                <input
                                                    type="text"
                                                    id="fba"
                                                    readOnly={
                                                        productVersion &&
                                                        productVersion.is_published
                                                    }
                                                    {...register("name")}
                                                />
                                                {productVersion.is_published && (
                                                    <i
                                                        aria-hidden="true"
                                                        className="icon-lock"
                                                    />
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="div-as-p">
                                        <label htmlFor="fbb">
                                            Internal Version Name
                                            <span className="text-right">
                                                {MAX_PRODUCT_NAME_LENGTH}{" "}
                                                Character Limit{" "}
                                                <NotAnchor
                                                    onClick={() =>
                                                        openPopup(
                                                            "more-about-internal-version-name"
                                                        )
                                                    }
                                                >
                                                    <i
                                                        aria-hidden="true"
                                                        className="icon-help"
                                                    />{" "}
                                                    <span className="hidden">
                                                        More info
                                                    </span>
                                                </NotAnchor>
                                            </span>
                                        </label>
                                        <input
                                            type="text"
                                            id="fbb"
                                            placeholder='For example,"1.0," "v1," or "Q1 2021"...'
                                            readOnly={
                                                productVersion.is_published
                                            }
                                            {...register(
                                                "internal_version_name"
                                            )}
                                        />
                                    </div>
                                    <div className="div-as-p">
                                        <label htmlFor="fbc">
                                            Store Description
                                            <span className="text-right">
                                                {MAX_PRODUCT_DESCRIPTION_LENGTH}{" "}
                                                Character Limit{" "}
                                                <NotAnchor
                                                    onClick={() =>
                                                        openPopup(
                                                            "more-about-store-description"
                                                        )
                                                    }
                                                >
                                                    <i
                                                        aria-hidden="true"
                                                        className="icon-help"
                                                    />
                                                    <span className="hidden">
                                                        More info
                                                    </span>
                                                </NotAnchor>
                                            </span>
                                        </label>
                                        <input
                                            type="text"
                                            id="fbc"
                                            readOnly={
                                                productVersion.is_published
                                            }
                                            {...register("description")}
                                        />
                                    </div>
                                    <div className="cols">
                                        <div className="c50 div-as-p">
                                            <span className="label">
                                                Product Type
                                                <NotAnchor
                                                    onClick={() =>
                                                        openPopup(
                                                            "more-about-product-type"
                                                        )
                                                    }
                                                    className="text-right"
                                                >
                                                    <i
                                                        aria-hidden="true"
                                                        className="icon-help"
                                                    />
                                                    <span className="hidden">
                                                        More info
                                                    </span>
                                                </NotAnchor>
                                            </span>
                                            <span className="input-prefix">
                                                <FormControl fullWidth>
                                                    <Select
                                                        id="ifv"
                                                        name="product_type"
                                                        readOnly={
                                                            productVersion.is_published
                                                        }
                                                        control={control}
                                                    >
                                                        {PRODUCT_VERSION_TYPES.map(
                                                            ({
                                                                value,
                                                                icon,
                                                                title,
                                                                codes,
                                                            }) => (
                                                                <MenuItem
                                                                    key={value}
                                                                    value={
                                                                        value
                                                                    }
                                                                >
                                                                    <ListItemIcon>
                                                                        <i
                                                                            aria-hidden="true"
                                                                            className={`icon-${icon}`}
                                                                        />
                                                                    </ListItemIcon>
                                                                    {`${codes.join(
                                                                        ", "
                                                                    )} ${title}`}
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-box">
                                    <h2 className="m25 color-primary">
                                        Quote, Binder &amp; Policy
                                    </h2>
                                    <div className="cols">
                                        <div className="c50 div-as-p">
                                            <span className="label">
                                                Quote Expiration
                                                <NotAnchor
                                                    onClick={() =>
                                                        openPopup(
                                                            "more-about-quote-expiration"
                                                        )
                                                    }
                                                    className="text-right"
                                                >
                                                    <i
                                                        aria-hidden="true"
                                                        className="icon-help"
                                                    />
                                                    <span className="hidden">
                                                        More info
                                                    </span>
                                                </NotAnchor>
                                            </span>
                                            <FormControl fullWidth>
                                                <Select
                                                    id="fbg"
                                                    name="quote_expires_at_days"
                                                    readOnly={
                                                        productVersion.is_published
                                                    }
                                                    control={control}
                                                >
                                                    {EXPIRATION_OPTIONS.map(
                                                        (item) => (
                                                            <MenuItem
                                                                key={item.value}
                                                                value={
                                                                    item.value
                                                                }
                                                            >
                                                                {item.title}
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className="c50 div-as-p">
                                            <span className="label">
                                                Binder Expiration
                                                <NotAnchor
                                                    onClick={() =>
                                                        openPopup(
                                                            "more-about-binder-expiration"
                                                        )
                                                    }
                                                    className="text-right"
                                                >
                                                    <i
                                                        aria-hidden="true"
                                                        className="icon-help"
                                                    />
                                                    <span className="hidden">
                                                        More info
                                                    </span>
                                                </NotAnchor>
                                            </span>
                                            <FormControl fullWidth>
                                                <Select
                                                    id="fbgb"
                                                    name="binder_expires_at_days"
                                                    readOnly={
                                                        productVersion.is_published
                                                    }
                                                    control={control}
                                                >
                                                    {EXPIRATION_OPTIONS.map(
                                                        (item) => (
                                                            <MenuItem
                                                                key={item.value}
                                                                value={
                                                                    item.value
                                                                }
                                                            >
                                                                {item.title}
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-box">
                                    <h2 className="m25 color-primary">
                                        Renewal
                                    </h2>
                                    <div className="cols">
                                        <div className="c50 div-as-p">
                                            <span className="label">
                                                Renewal Setting
                                                <NotAnchor
                                                    onClick={() =>
                                                        openPopup(
                                                            "more-about-renewal-setting"
                                                        )
                                                    }
                                                    className="text-right"
                                                >
                                                    <i
                                                        aria-hidden="true"
                                                        className="icon-help"
                                                    />
                                                    <span className="hidden">
                                                        More info
                                                    </span>
                                                </NotAnchor>
                                            </span>
                                            <FormControl fullWidth>
                                                <Select
                                                    id="fbge"
                                                    name="renewal_setting"
                                                    readOnly={
                                                        productVersion.is_published
                                                    }
                                                    control={control}
                                                >
                                                    {Object.keys(
                                                        RenewalSetting
                                                    ).map((key) => (
                                                        <MenuItem
                                                            key={key}
                                                            value={key}
                                                        >
                                                            {startCase(key)}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className="c50 div-as-p">
                                            <span className="label">
                                                Renewal Period Starts
                                                <NotAnchor
                                                    onClick={() =>
                                                        openPopup(
                                                            "more-about-renewal-period"
                                                        )
                                                    }
                                                    className="text-right"
                                                >
                                                    <i
                                                        aria-hidden="true"
                                                        className="icon-help"
                                                    />
                                                    <span className="hidden">
                                                        More info
                                                    </span>
                                                </NotAnchor>
                                            </span>
                                            <FormControl fullWidth>
                                                <Select
                                                    id="fbgc"
                                                    name="renewal_period"
                                                    readOnly={
                                                        productVersion.is_published
                                                    }
                                                    control={control}
                                                >
                                                    {RENEWAL_OPTIONS.map(
                                                        (item) => (
                                                            <MenuItem
                                                                key={item.value}
                                                                value={
                                                                    item.value
                                                                }
                                                            >
                                                                {item.title}
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-box">
                                    <h2 className="m25 color-primary">
                                        Checks
                                    </h2>
                                    <div className="div-as-p">
                                        <span>
                                            <input
                                                {...register("use_clearance")}
                                                type="checkbox"
                                                id="use-clearance"
                                                style={{
                                                    width: "14px",
                                                    marginRight: "10px",
                                                }}
                                            />
                                            <label
                                                htmlFor="use-clearance"
                                                style={{
                                                    display: "inline-block",
                                                }}
                                            >
                                                Use Insured Details for
                                                clearance
                                            </label>
                                            <span className="text-right refresh-all-icon">
                                                <NotAnchor
                                                    onClick={() => {
                                                        openPopup(
                                                            "clearance-help"
                                                        );
                                                    }}
                                                    className="text-right"
                                                >
                                                    <i
                                                        aria-hidden="true"
                                                        className="icon-help"
                                                    />
                                                    <span className="hidden">
                                                        More info
                                                    </span>
                                                </NotAnchor>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div className="form-box">
                                    <h2 className="m25 color-primary">
                                        Documents
                                    </h2>
                                    <div className="div-as-p">
                                        <label htmlFor="fbas">
                                            <input
                                                type="checkbox"
                                                id="fbas"
                                                readOnly={
                                                    productVersion &&
                                                    productVersion.is_published
                                                }
                                                {...register(
                                                    "watermark.enabled"
                                                )}
                                                style={{
                                                    width: "14px",
                                                    marginRight: "10px",
                                                }}
                                            />
                                            Preview Watermark
                                            {watch("watermark.enabled") && (
                                                <span className="text-right">
                                                    {MAX_WATERMARK_LENGTH}{" "}
                                                    Character Limit{" "}
                                                    <NotAnchor
                                                        onClick={() =>
                                                            openPopup(
                                                                "more-about-watermark"
                                                            )
                                                        }
                                                        className="text-right"
                                                    >
                                                        <i
                                                            aria-hidden="true"
                                                            className="icon-help"
                                                        />
                                                        <span className="hidden">
                                                            More info
                                                        </span>
                                                    </NotAnchor>
                                                </span>
                                            )}
                                        </label>
                                        {watch("watermark.enabled") && (
                                            <>
                                                <span>
                                                    <input
                                                        type="text"
                                                        id="fbas-text"
                                                        readOnly={
                                                            productVersion &&
                                                            productVersion.is_published
                                                        }
                                                        {...register(
                                                            "watermark.text"
                                                        )}
                                                    />
                                                </span>
                                                {errors.watermark?.text && (
                                                    <label
                                                        id="fbas-text-error"
                                                        className="error"
                                                        htmlFor="fbas-text"
                                                    >
                                                        {
                                                            errors.watermark
                                                                .text.message
                                                        }
                                                    </label>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div style={{ paddingBottom: "60px" }} />
                                {!productVersion.is_published && (
                                    <Footer>
                                        <p className="link-btn">
                                            <button
                                                type="submit"
                                                disabled={!isDirty}
                                            >
                                                <i
                                                    aria-hidden="true"
                                                    className="icon-check-circle-outline"
                                                />{" "}
                                                Save{" "}
                                                <span className="mobile-hide">
                                                    Settings
                                                </span>
                                            </button>
                                        </p>
                                    </Footer>
                                )}
                            </>
                        )}
                    </Form>
                </div>
            </ProductMainPane>
        );
    } else if (activeTab_.path === "policy-ids") {
        return (
            <ProductMainPane
                title="Settings"
                // layoutConfig={{ headerClass: null }}
            >
                <PolicyAllocationsPage />
            </ProductMainPane>
        );
    }
};

const SettingsPage: FC<SettingsPageInterface> = ({ tabs }) => (
    <Page tabs={tabs}>
        <Main />
        <SettingsHelp />
        <MoreProductVersionNamePopup />
        <MoreInternalVersionPopup />
        <MoreStoreDescriptionPopup />
        <MoreProductVersionTypePopup />
        <MoreQuoteExpirationPopup />
        <MoreBinderExpirationPopup />
        <MoreRenewalPeriodPopup />
        <MoreUWRenewalPeriodPopup />
        <MoreUWRenewalSettingPopup />
        <AddOrEditAllocationPopup />
        <AddOrEditPolicyIdPopup />
        <DeleteAllocationPopup />
        <DeletePolicyIdPopup />
        <ClearanceHelpPopup />
        <MoreAbourWaterMarkPopup />
    </Page>
);

export default SettingsPage;
