import { FC, useCallback } from "react";
import NotAnchor from "components/NotAnchor";
import { usePage } from "components/Page";
import { createFormPopup } from "components/Popup";
import { useApi } from "contexts/ApiProvider";
import { usePopup } from "contexts/PopupProvider";
import { useInsuranceProcess } from "../InsuranceProcessProvider";
import { Select } from "components/ReactHookFormUncontrolledComponents";
import { MenuItem as SelectMenuItem } from "components/Select";
import {
    CLOSE_QUOTE_REASONS,
    waitForProcessingInsuranceObject,
} from "../../util";
import { z } from "zod";
import { QuoteStatus } from "@joshuins/insurance";

const CloseEndorsementQuoteLink: FC = () => {
    const { openPopup } = usePopup();

    return (
        <NotAnchor
            onClick={() => {
                openPopup("close-quote");
            }}
            className="inline"
            aria-controls="close-quote"
            aria-haspopup="true"
        >
            <i aria-hidden="true" className="icon-chevron-left" />
            Close <span className="mobile-hide"> Quote </span>
        </NotAnchor>
    );
};

const CloseQuoteLink: FC = () => {
    const { openPopup } = usePopup();

    return (
        <NotAnchor
            onClick={() => {
                openPopup("close-quote");
            }}
            className="inline"
            aria-controls="close-quote"
            aria-haspopup="true"
        >
            <i aria-hidden="true" className="icon-x-circle" />
            Close <span className="mobile-hide">Quote </span>
        </NotAnchor>
    );
};

const createCloseQuoteSchema = z.object({
    reason: z.string().min(1, "This field is required"),
});
type CloseQuoteType = z.infer<typeof createCloseQuoteSchema>;

const CloseQuotePopup: FC = () => {
    const { FormPopup } = createFormPopup(createCloseQuoteSchema);
    const { sdkInsurance } = useApi();
    const { tryCatchAndRaiseError, refreshElement } = usePage();
    const {
        itemGetters: { getInsuranceProcess },
    } = useInsuranceProcess();

    const { quote } = getInsuranceProcess();

    const onSubmit = useCallback(
        async (data: CloseQuoteType) => {
            tryCatchAndRaiseError(async () => {
                await sdkInsurance.updateQuote({
                    id: quote.id,
                    UpdateQuote: {
                        status: QuoteStatus.QuoteClosed,
                        reason: data.reason,
                    },
                });
                await waitForProcessingInsuranceObject(
                    sdkInsurance,
                    quote,
                    "Closing Quote..."
                );
            }, refreshElement);
        },
        [tryCatchAndRaiseError, refreshElement, sdkInsurance, quote]
    );

    return (
        <FormPopup
            name="close-quote"
            defaultValues={{
                reason: "",
            }}
            onSubmit={onSubmit}
            submitText="Close Quote"
        >
            {({ control }) => (
                <>
                    <header>
                        <h2>Are you sure?</h2>
                    </header>
                    <p className="size-14 m15">
                        Please choose a reason to help the broker understand why
                        the Quote was closed.
                    </p>
                    <div>
                        <span className="label">Reason for Close</span>
                        <Select
                            id="close-quote-reason"
                            name="reason"
                            placeholder="Select reason..."
                            required
                            control={control}
                            fullWidth
                        >
                            {CLOSE_QUOTE_REASONS.map((item, index) => (
                                <SelectMenuItem key={index} value={item.value}>
                                    {item.value}
                                </SelectMenuItem>
                            ))}
                        </Select>
                    </div>
                </>
            )}
        </FormPopup>
    );
};

export { CloseQuoteLink, CloseEndorsementQuoteLink, CloseQuotePopup };
