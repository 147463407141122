import { setCustomStyleProperty } from "paul/native-dom-manipulation";
import { TinyColor } from "@ctrl/tinycolor";

const hexToRgb = (hexColor: string): string => {
    const rgb = new TinyColor(hexColor).toRgb();
    return `${rgb.r},${rgb.g},${rgb.b}`;
};

interface ThemePropertiesInterface {
    logo_url?: string;
    logo_width?: number;
    logo_height?: number;
    color_primary?: string;
    bg_primary?: string;
    bg_primary_icon?: string;
    bg_primary_text?: string;
    bg_secondary?: string;
    bg_secondary_icon?: string;
    bg_secondary_text?: string;
    font_titles?: string;
    readonly [key: string]: string | number | undefined;
}

const setTheme = (properties: ThemePropertiesInterface) => {
    for (const property of [
        "bg_primary",
        "bg_primary_icon",
        "bg_primary_text",
        "bg_secondary",
        "bg_secondary_icon",
        "bg_secondary_text",
    ]) {
        const propertyValue = properties[property];
        if (propertyValue !== undefined) {
            setCustomStyleProperty(property, propertyValue);
        }
    }

    if (properties.color_primary !== undefined) {
        setCustomStyleProperty(
            "color_primary",
            hexToRgb(properties.color_primary)
        );
    }

    if (properties.logo_url !== undefined) {
        setCustomStyleProperty("logo-content", `url(${properties.logo_url})`);
    }
};

export { setTheme };
export type { ThemePropertiesInterface };
