import {
    createContext,
    Dispatch,
    FC,
    PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { useImmerReducer } from "use-immer";

import { usePage } from "components/Page";
import {
    Insured,
    Submission,
    ProductVersion,
    SectionV1,
    ApplicationSectionItemV1,
    AssetSectionItemV1,
    ApplicationDatapointV1,
    AssetDatapointV1,
    SubmissionCodeValueAndRank,
    SubmissionDataStatus,
    SubmissionStatus,
    JoValue,
} from "@joshuins/insurance";
import { useApi } from "contexts/ApiProvider";
import { useNavigate } from "components/DevAwareRoutingLink";
import urlJoin from "url-join";
import keyBy from "lodash/keyBy";
import { includes } from "utils/array";
import { removeAppPrefix } from "./utils";
import { isAssetSection } from "utils/datapoints-and-sections";
import SubmissionData from "utils/submission-data";
import { GetFileResponse } from "@joshuins/builder";
import { asyncMap } from "modern-async";
import {
    getRawValueFromPlainFileJoValue,
    joValueIsPlainAndFile,
} from "utils/jo-types-and-values";
import compact from "lodash/compact";
import fromPairs from "lodash/fromPairs";
import { User } from "@joshuins/system";
import { useBranding } from "contexts/BrandingProvider";
import { waitForProcessingInsuranceObject } from "pages/underwriter/quotes_and_policies/util";

type ApplicationAction =
    | {
          action: "SetShownSection";
          code: string;
      }
    | {
          action: "SetSubmissionUser";
          submissionUser: User;
      }
    | {
          action: "SetSubmissionDataView";
          submissionDataView: SubmissionDataView;
      };

interface SubmissionDataView {
    codesValuesAndAssets: SubmissionCodeValueAndRank[];
    parsed: SubmissionData;
    status: SubmissionDataStatus;
    files: Record<string, GetFileResponse>;
}

interface ApplicationState {
    productVersion: ProductVersion;
    shownSectionCode: string | null;
    insured: Insured | null;
    submissionUser: User | null;
    editable: boolean;
    submissionDataView: SubmissionDataView;
}
interface ApplicationProviderData {
    productVersion: ProductVersion;
    insured: Insured | null;
    submissionUser: User | null;
    editable: boolean;
    submissionDataView: SubmissionDataView;
}

interface ApplicationProviderInterface {
    editable: boolean;
    applicationState: ApplicationState;
    applicationDispatch: Dispatch<ApplicationAction>;
    navigateToSection: (code: string) => void;
    refreshSubmissionData: () => Promise<void>;
    itemGetters: {
        getProductVersion: () => ProductVersion;
        getSubmissionData: () => SubmissionCodeValueAndRank[];
        getParsedSubmissionData: () => SubmissionData;
        getSubmissionDataFiles: () => Record<string, GetFileResponse>;
        getSubmissionDataStatus: () => SubmissionDataStatus;
        getInsured: () => Insured | null;
        getSubmissionUser: () => User | null;
        generateSectionUrl: (code: string) => string | undefined;
        shownSection: () => SectionV1 | null;
        sections: () => SectionV1[];
        assetSections: () => Extract<SectionV1, { type: "Asset" }>[];
        section: (code: string) => SectionV1 | undefined;
        applicationSection: (
            code: string
        ) => Extract<SectionV1, { type: "Application" }> | undefined;
        assetSection: (
            code: string
        ) => Extract<SectionV1, { type: "Asset" }> | undefined;
        itemsInSection: (
            code: string
        ) => ApplicationSectionItemV1[] | AssetSectionItemV1[] | undefined;
        datapoint: (
            code: string
        ) => ApplicationDatapointV1 | AssetDatapointV1 | undefined;
        allDatapoints: () => (ApplicationDatapointV1 | AssetDatapointV1)[];
        allApplicationDatapoints: () => ApplicationDatapointV1[];
        allAssetDatapoints: () => AssetDatapointV1[];
        datapointsInSection: (
            sectionCode: string
        ) => (ApplicationDatapointV1 | AssetDatapointV1)[];
        getApiValues: () => {
            application: Record<string, JoValue>;
            asset: Record<
                string,
                (
                    | { index: number; values: Record<string, JoValue> }
                    | undefined
                )[]
            >;
        };
    };
}

const ApplicationContext = createContext<
    ApplicationProviderInterface | undefined
>(undefined);

const useApplication = () => {
    const context = useContext(ApplicationContext);

    if (context === undefined) {
        throw Error(
            "useApplication must be used inside an ApplicationProvider context"
        );
    }

    return context;
};

const reducer = (draft: ApplicationState, action: ApplicationAction) => {
    switch (action.action) {
        case "SetShownSection": {
            draft.shownSectionCode = action.code;
            break;
        }
        case "SetSubmissionUser": {
            draft.submissionUser = action.submissionUser;
            break;
        }
        case "SetSubmissionDataView": {
            draft.submissionDataView = action.submissionDataView;
            break;
        }
    }
};

const useSubmissionData = () => {
    const { sdkInsurance, sdkBuilder } = useApi();

    const getSubmissionData = useCallback(
        async (submission: Submission) => {
            await waitForProcessingInsuranceObject(
                sdkInsurance,
                submission,
                "Processing..."
            );
            const [
                { codesValuesAndAssets, parsedSubmissionData, files },
                submissionDataStatus,
            ] = await Promise.all([
                (async () => {
                    const submissionData = await sdkInsurance.getSubmissionData(
                        {
                            id: submission.id,
                        }
                    );
                    const parsedSubmissionData =
                        await sdkInsurance.getParsedSubmissionData(
                            submission.id
                        );
                    const fileApplicationItems = compact(
                        compact(
                            Object.values(parsedSubmissionData.assets)
                        ).flatMap((asset) =>
                            Object.entries(asset.applicationItems).map(
                                ([code, applicationItem]) => {
                                    if (
                                        applicationItem &&
                                        joValueIsPlainAndFile(applicationItem)
                                    ) {
                                        return { code: code, applicationItem };
                                    }
                                }
                            )
                        )
                    );

                    const files = fromPairs(
                        await asyncMap(
                            fileApplicationItems,
                            async (fileApplicationItem) => {
                                const fileId = getRawValueFromPlainFileJoValue(
                                    fileApplicationItem.applicationItem
                                );

                                return [
                                    fileApplicationItem.code,
                                    await sdkBuilder.getFile({
                                        id: fileId,
                                    }),
                                ] as [string, GetFileResponse];
                            },
                            Number.POSITIVE_INFINITY
                        )
                    );

                    return {
                        codesValuesAndAssets: submissionData,
                        parsedSubmissionData,
                        files,
                    };
                })(),
                await sdkInsurance.getSubmissionDataStatus({
                    id: submission.id,
                }),
            ]);

            return {
                codesValuesAndAssets,
                parsed: parsedSubmissionData,
                status: submissionDataStatus,
                files,
            };
        },
        [sdkBuilder, sdkInsurance]
    );

    return getSubmissionData;
};

const ApplicationProvider: FC<PropsWithChildren<ApplicationProviderData>> = ({
    children,
    productVersion,
    submissionDataView,
    insured,
    submissionUser,
    editable,
}) => {
    const processedSectionData = useMemo(() => {
        const submissionDataStatusLookUp = keyBy(
            submissionDataView.status.sections,
            "code"
        );
        const sections = productVersion.schema.spec.sections.filter(
            (section) =>
                section.type !== "BindQuestion" &&
                submissionDataStatusLookUp[section.code]
        );

        return {
            sections,
            assetSections: sections.filter((section) =>
                isAssetSection(section)
            ) as Extract<SectionV1, { type: "Asset" }>[],
            sectionsByCode: keyBy(sections, "code"),
            sectionIndexByCode: sections.reduce(
                (accumulator, section, index) => {
                    accumulator[section.code] = index;
                    return accumulator;
                },
                {} as Record<string, number>
            ),
            datapointsByCode: sections.reduce(
                (accumulator, section) => {
                    for (const sectionItem of section.items) {
                        if ("Datapoint" in sectionItem) {
                            accumulator[sectionItem.Datapoint.code] =
                                sectionItem.Datapoint;
                        }
                    }
                    return accumulator;
                },
                {} as Record<string, ApplicationDatapointV1 | AssetDatapointV1>
            ),
            datapointsInSectionCode: sections.reduce(
                (accumulator, section) => {
                    if (!includes(["Asset", "Application"], section.type)) {
                        return accumulator;
                    }
                    if (!accumulator[section.code]) {
                        accumulator[section.code] = [];
                    }
                    for (const sectionItem of section.items) {
                        if ("Datapoint" in sectionItem) {
                            accumulator[section.code].push(
                                sectionItem.Datapoint
                            );
                        }
                    }
                    return accumulator;
                },
                {} as Record<
                    string,
                    (ApplicationDatapointV1 | AssetDatapointV1)[]
                >
            ),
        };
    }, [submissionDataView, productVersion.schema.spec.sections]);

    const [applicationState, applicationDispatch] = useImmerReducer<
        ApplicationState,
        ApplicationAction
    >(reducer, {
        productVersion,
        submissionDataView,
        shownSectionCode: null,
        insured,
        submissionUser,
        editable,
    });

    const getSubmissionData_ = useSubmissionData();
    const navigate = useNavigate();
    const { generateUrl } = useBranding();
    const { element } = usePage();
    const submission = element as unknown as Submission | undefined;

    const generateSectionUrl = useCallback(
        (code: string) => {
            if (!submission) {
                return;
            }
            return generateUrl(
                urlJoin(
                    "/",
                    "submissions",
                    submission.id.toString(),
                    "application",
                    removeAppPrefix(code)
                )
            );
        },
        [generateUrl, submission]
    );

    const navigateToSection = useCallback(
        (code: string) => {
            const sectionUrl = generateSectionUrl(code);
            if (!sectionUrl) {
                return;
            }

            navigate(sectionUrl, { replace: true });
        },
        [generateSectionUrl, navigate]
    );

    const refreshSubmissionData = useCallback(async () => {
        if (!submission) {
            return;
        }
        applicationDispatch({
            action: "SetSubmissionDataView",
            submissionDataView: await getSubmissionData_(submission),
        });
    }, [applicationDispatch, getSubmissionData_, submission]);

    const getProductVersion = useCallback(
        () => applicationState.productVersion,
        [applicationState.productVersion]
    );

    const getSubmissionData = useCallback(
        () => applicationState.submissionDataView.codesValuesAndAssets,
        [applicationState.submissionDataView.codesValuesAndAssets]
    );

    const getParsedSubmissionData = useCallback(
        () => applicationState.submissionDataView.parsed,
        [applicationState.submissionDataView.parsed]
    );

    const getSubmissionDataFiles = useCallback(
        () => applicationState.submissionDataView.files,
        [applicationState.submissionDataView.files]
    );

    const getSubmissionDataStatus = useCallback(
        () => applicationState.submissionDataView.status,
        [applicationState.submissionDataView.status]
    );

    const getInsured = useCallback(
        () => applicationState.insured,
        [applicationState.insured]
    );

    const getSubmissionUser = useCallback(
        () => applicationState.submissionUser,
        [applicationState.submissionUser]
    );

    const shownSection = useCallback(
        () =>
            applicationState.shownSectionCode
                ? processedSectionData.sectionsByCode[
                      applicationState.shownSectionCode
                  ]
                : null,
        [applicationState.shownSectionCode, processedSectionData.sectionsByCode]
    );

    const sections = useCallback(
        () => processedSectionData.sections,
        [processedSectionData.sections]
    );

    const assetSections = useCallback(
        () => processedSectionData.assetSections,
        [processedSectionData.assetSections]
    );

    const section = useCallback(
        (code: string) => processedSectionData.sectionsByCode[code],
        [processedSectionData.sectionsByCode]
    );

    const applicationSection = useCallback(
        (code: string) => {
            const section = processedSectionData.sectionsByCode[code];
            if (section.type === "Application") {
                return section;
            } else {
                return undefined;
            }
        },
        [processedSectionData.sectionsByCode]
    );

    const assetSection = useCallback(
        (code: string) => {
            const section = processedSectionData.sectionsByCode[code];
            if (section.type === "Asset") {
                return section;
            } else {
                return undefined;
            }
        },
        [processedSectionData.sectionsByCode]
    );

    const itemsInSection = useCallback(
        (code: string) => processedSectionData.sectionsByCode[code].items,
        [processedSectionData.sectionsByCode]
    );

    const datapoint = useCallback(
        (code: string) => processedSectionData.datapointsByCode[code],
        [processedSectionData.datapointsByCode]
    );

    const allDatapoints_ = useMemo(
        () =>
            sections().reduce(
                (accumulator, section) => {
                    if (!includes(["Asset", "Application"], section.type)) {
                        return accumulator;
                    }
                    for (const sectionItem of section.items) {
                        if ("Datapoint" in sectionItem) {
                            accumulator.push(sectionItem.Datapoint);
                        }
                    }
                    return accumulator;
                },
                [] as (ApplicationDatapointV1 | AssetDatapointV1)[]
            ),
        [sections]
    );
    const allDatapoints = useCallback(() => allDatapoints_, [allDatapoints_]);

    const allApplicationDatapoints_ = useMemo(
        () =>
            sections().reduce((accumulator, section) => {
                if (!includes(["Application"], section.type)) {
                    return accumulator;
                }
                for (const sectionItem of section.items) {
                    if ("Datapoint" in sectionItem) {
                        accumulator.push(sectionItem.Datapoint);
                    }
                }
                return accumulator;
            }, [] as ApplicationDatapointV1[]),
        [sections]
    );
    const allApplicationDatapoints = useCallback(
        () => allApplicationDatapoints_,
        [allApplicationDatapoints_]
    );

    const allAssetDatapoints_ = useMemo(
        () =>
            sections().reduce((accumulator, section) => {
                if (!includes(["Asset"], section.type)) {
                    return accumulator;
                }
                for (const sectionItem of section.items) {
                    if ("Datapoint" in sectionItem) {
                        accumulator.push(sectionItem.Datapoint);
                    }
                }
                return accumulator;
            }, [] as ApplicationDatapointV1[]),
        [sections]
    );
    const allAssetDatapoints = useCallback(
        () => allAssetDatapoints_,
        [allAssetDatapoints_]
    );

    const datapointsInSection = useCallback(
        (sectionCode: string) =>
            processedSectionData.datapointsInSectionCode[sectionCode],
        [processedSectionData.datapointsInSectionCode]
    );

    const apiValues_ = useMemo(() => {
        const parsedSubmissionData = getParsedSubmissionData();

        const applicationValues = allApplicationDatapoints().reduce(
            (accumulator, datapoint) => {
                const code = removeAppPrefix(datapoint.code);
                const value =
                    parsedSubmissionData.assets["0"]?.applicationItems[code];
                if (value) {
                    accumulator[code] = value;
                }
                return accumulator;
            },
            {} as Record<string, JoValue>
        );

        const assetValues = assetSections().reduce(
            (accumulator, section) => {
                accumulator[removeAppPrefix(section.code)] = Object.entries(
                    parsedSubmissionData.assets
                ).reduce(
                    (accumulator, [assetId, asset]) => {
                        const assetIndex = parseInt(assetId);

                        // first check if there is any data at all for all the asset datapoints,
                        // if there is none, we assume there is no asset - there is no better way
                        // to know if an asset exists or not because of the structure of submission data
                        // that comes from the API
                        if (
                            !asset ||
                            !datapointsInSection(section.code).some(
                                (datapoint) =>
                                    !!asset.applicationItems[
                                        removeAppPrefix(datapoint.code)
                                    ]
                            )
                        ) {
                            accumulator[assetIndex] = undefined;
                            return accumulator;
                        }

                        accumulator[assetIndex] = {
                            index: assetIndex,
                            values: datapointsInSection(section.code).reduce(
                                (accumulator, datapoint) => {
                                    const code = removeAppPrefix(
                                        datapoint.code
                                    );
                                    const value =
                                        parsedSubmissionData.assets[
                                            assetIndex.toString()
                                        ]?.applicationItems[code];
                                    if (value) {
                                        accumulator[code] = value;
                                    }
                                    return accumulator;
                                },
                                {} as Record<string, JoValue>
                            ),
                        };
                        return accumulator;
                    },
                    [] as (
                        | { index: number; values: Record<string, JoValue> }
                        | undefined
                    )[]
                );
                return accumulator;
            },
            {} as Record<
                string,
                (
                    | { index: number; values: Record<string, JoValue> }
                    | undefined
                )[]
            >
        );

        return {
            application: applicationValues,
            asset: assetValues,
        };
    }, [
        allApplicationDatapoints,
        assetSections,
        datapointsInSection,
        getParsedSubmissionData,
    ]);

    const getApiValues = useCallback(() => apiValues_, [apiValues_]);

    return (
        <ApplicationContext.Provider
            value={{
                editable,
                applicationState,
                applicationDispatch,
                navigateToSection,
                refreshSubmissionData,
                itemGetters: {
                    getProductVersion,
                    getSubmissionData,
                    getSubmissionDataFiles,
                    getSubmissionDataStatus,
                    getParsedSubmissionData,
                    getInsured,
                    getSubmissionUser,
                    generateSectionUrl,
                    shownSection,
                    sections,
                    assetSections,
                    section,
                    assetSection,
                    applicationSection,
                    itemsInSection,
                    datapoint,
                    allDatapoints,
                    allApplicationDatapoints,
                    allAssetDatapoints,
                    datapointsInSection,
                    getApiValues,
                },
            }}
        >
            {children}
        </ApplicationContext.Provider>
    );
};

const ApplicationProviderWrapper: FC<PropsWithChildren> = ({ children }) => {
    const { element } = usePage();
    const submission = element as unknown as Submission | undefined;
    const { sdkBuilder, sdkInsurance, sdkSystem } = useApi();
    const [applicationProviderData, setApplicationProviderData] =
        useState<ApplicationProviderData>();
    const getSubmissionDataView = useSubmissionData();

    useEffect(() => {
        if (!submission) {
            return;
        }
        const getApplicationProviderData = async () => {
            let policy = undefined;
            if (submission.policy_id) {
                policy = await sdkInsurance.getPolicy({
                    id: submission.policy_id,
                });
            }
            const [
                productVersion_,
                submissionDataView,
                insured_,
                submissionUser_,
            ] = await Promise.all([
                sdkInsurance.getProductVersion({
                    id: submission.product_version_id,
                }),
                getSubmissionDataView(submission),
                policy && policy.insured_id
                    ? sdkInsurance.getInsured({ id: policy.insured_id })
                    : null,

                sdkSystem.getUser({ id: submission.user_id }),
            ]);

            setApplicationProviderData({
                productVersion: productVersion_,
                submissionDataView,
                insured: insured_,
                submissionUser: submissionUser_,
                editable:
                    submission.status === SubmissionStatus.Incomplete ||
                    submission.status === SubmissionStatus.Pending,
            });
        };
        getApplicationProviderData();
    }, [
        getSubmissionDataView,
        sdkBuilder,
        sdkInsurance,
        sdkSystem,
        submission,
    ]);

    if (!applicationProviderData) {
        return <></>;
    }

    return (
        <ApplicationProvider {...applicationProviderData}>
            {children}
        </ApplicationProvider>
    );
};

export { ApplicationProviderWrapper as ApplicationProvider, useApplication };
