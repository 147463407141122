import { FC, useEffect, useState } from "react";
import { Page } from "components/Page";
import { useApi } from "contexts/ApiProvider";
import ExtraPane from "components/extra-panes/ExtraPane";
import InsuranceObjectList from "pages/underwriter/quotes_and_policies/components/InsuranceObjectList";
import { InsuranceListObject } from "pages/underwriter/quotes_and_policies/util";
import { BINDER_STATUSES } from "utils/policies";

const Main: FC = () => {
    const { sdkInsurance } = useApi();
    const [insuranceObjects, setInsuranceObjects] =
        useState<InsuranceListObject[]>();
    const [icon, setIcon] = useState<string>();

    useEffect(() => {
        const getInsuranceObjects = async () => {
            const binders = await sdkInsurance.getAllPolicies(
                Object.assign([], BINDER_STATUSES)
            );
            const icon = "icon-documents";

            setInsuranceObjects(binders);
            setIcon(icon);
        };
        getInsuranceObjects();
    }, [sdkInsurance]);

    if (!insuranceObjects) {
        return <></>;
    }

    return (
        <div className="module-tabs-content">
            <InsuranceObjectList
                title="Binders"
                insuranceObjects={insuranceObjects}
                textWhenEmpty='Click the "New Submission" button to start an application. After the application has been approved and the bind request has been activated, quotes will appear here.'
                icon={icon ?? "icon-time"}
            />
        </div>
    );
};

const BindersPane = () => (
    <ExtraPane>
        <h3>
            <i aria-hidden="true" className="icon-help" /> Binders
        </h3>
        <p>
            If your underwriter has released any binders, they will appear here.
            Once all bind requirements have been met, the underwriter will close
            the binder and issue a full policy.
        </p>
    </ExtraPane>
);

const StoreBindersPage: FC = () => (
    <Page>
        <Main />
        <BindersPane />
    </Page>
);
export default StoreBindersPage;
