import { FC, useCallback, useEffect, useState } from "react";
import pick from "lodash/pick";
import { z } from "zod";
import {
    AuthOidcProvider,
    AuthSettings,
    UpdateAuthSettings,
} from "@joshuins/system";
import NotAnchor from "components/NotAnchor";
import { AlertCategory, usePage } from "components/Page";
import { createFormPopup } from "components/Popup";
import { unpaginate } from "components/sdk";
import { useApi } from "contexts/ApiProvider";
import { usePopup } from "contexts/PopupProvider";
import { Select } from "components/ReactHookFormUncontrolledComponents";
import { MenuItem as SelectMenuItem } from "components/Select";

const EDIT_AUTH_SETTINGS = "edit-auth-settings" as const;

const editAuthSettingsSchema = z.object({
    enable_password_login: z.boolean(),
    enable_builtin_google_login: z.boolean(),
    oidc_provider_id: z.union([z.string().uuid(), z.literal("none")]),
    store_id: z.union([z.number(), z.literal("none")]),
});

type EditAuthSettingsType = z.infer<typeof editAuthSettingsSchema>;

const {
    FormPopup: EditAuthSettingsFormPopup,
    useFormReturnRef: useEditAuthSettingsFormReturnRef,
} = createFormPopup(editAuthSettingsSchema);

const EditAuthSettingsPopup: FC = () => {
    const { sdkSystem } = useApi();
    const { addAlertMessages, refreshState, tryCatchAndRaiseError } = usePage();
    const { isPopupOpen, popupData } = usePopup();
    const [authSettings, setAuthSettings] = useState<AuthSettings>();
    const [oidcProviders, setOidcProviders] = useState<AuthOidcProvider[]>();
    const popupStore = popupData?.id as unknown as string | undefined;
    const {
        formReturn: { reset },
        formReturnRefCallback,
    } = useEditAuthSettingsFormReturnRef();

    const transformFormDataToApiData = useCallback(
        (data: EditAuthSettingsType): UpdateAuthSettings => {
            return {
                ...pick(data, [
                    "enable_password_login",
                    "enable_builtin_google_login",
                ]),
                oidc_provider_id:
                    data.oidc_provider_id === "none"
                        ? null
                        : data.oidc_provider_id,
            };
        },
        []
    );

    const transformApiDataToFormData = useCallback(
        (authSettings_: AuthSettings): EditAuthSettingsType => {
            return {
                ...pick(authSettings_, [
                    "enable_password_login",
                    "enable_builtin_google_login",
                ]),
                oidc_provider_id: authSettings_
                    ? authSettings_.oidc_provider_id ?? "none"
                    : "none",
                store_id: authSettings_
                    ? authSettings_.store_id ?? "none"
                    : "none",
            };
        },
        []
    );

    useEffect(() => {
        const getAuthSettings = async () => {
            if (!isPopupOpen(EDIT_AUTH_SETTINGS) || !reset) {
                return;
            }

            const authSettings = (
                await unpaginate(sdkSystem.allAuthSettings, {
                    store_id: popupStore ? parseInt(popupStore) : undefined,
                })
            )[0];

            setAuthSettings(authSettings);
            reset(transformApiDataToFormData(authSettings));
        };
        getAuthSettings();
    }, [isPopupOpen, sdkSystem, transformApiDataToFormData, reset, popupStore]);

    useEffect(() => {
        const getOidcProviders = async () => {
            if (!isPopupOpen(EDIT_AUTH_SETTINGS)) {
                return;
            }

            const oidcProviders_ = await unpaginate(
                sdkSystem.allOidcProviders,
                {}
            );
            setOidcProviders(oidcProviders_);
        };
        getOidcProviders();
    }, [isPopupOpen, sdkSystem]);

    const onSubmit = useCallback(
        async (data: EditAuthSettingsType) => {
            tryCatchAndRaiseError(async () => {
                if (popupStore) {
                    const storeAuthSettings = await sdkSystem.allAuthSettings({
                        store_id: parseInt(popupStore),
                    });
                    if (storeAuthSettings.items.length > 0) {
                        const updateAuthSettingsData =
                            transformFormDataToApiData(data);
                        await sdkSystem.updateAuthSettings({
                            id: storeAuthSettings.items[0].id,
                            UpdateAuthSettings: updateAuthSettingsData,
                        });
                    } else {
                        await sdkSystem.createAuthSettings({
                            NewAuthSettings: {
                                store_id: parseInt(popupStore),
                                enable_password_login:
                                    data.enable_password_login,
                                enable_builtin_google_login:
                                    data.enable_builtin_google_login,
                                failed_login_attempts: 5,
                            },
                        });
                    }
                } else {
                    if (!authSettings) {
                        return;
                    }
                    const updateAuthSettingsData =
                        transformFormDataToApiData(data);
                    await sdkSystem.updateAuthSettings({
                        id: authSettings.id,
                        UpdateAuthSettings: updateAuthSettingsData,
                    });
                }
                addAlertMessages({
                    message: "Login settings have been been updated",
                    category: AlertCategory.SUCCESS,
                });
            }, refreshState);
        },
        [
            addAlertMessages,
            authSettings,
            popupStore,
            refreshState,
            sdkSystem,
            transformFormDataToApiData,
            tryCatchAndRaiseError,
        ]
    );

    return (
        <EditAuthSettingsFormPopup
            name="edit-auth-settings"
            defaultValues={{
                enable_password_login: false,
                enable_builtin_google_login: false,
                oidc_provider_id: undefined,
            }}
            onSubmit={onSubmit}
            submitText="Save"
            formReturnRefCallback={formReturnRefCallback}
            overlayPopups={[
                <>
                    <h3>Enable Password Login</h3>
                    <p>
                        Check this box to allow users to register and login
                        using the traditional method of email address and
                        password. This login method will appear below any SSO
                        providers that have been enabled in the registration and
                        login screens.
                    </p>
                </>,
                <>
                    <h3>Enable Google Login</h3>
                    <p>
                        Joshu provides a built-in Google SSO configuration which
                        can be enabled just by checking this box. Users will be
                        able to register and login simply using a &quot;Continue
                        with Google&quot; button. Enabling this option still
                        requires users to be invited into Joshu by admins.
                    </p>
                </>,
                <>
                    <h3>OIDC Provider</h3>
                    <p>
                        Once you&apos;ve setup an OIDC provider, you can enable
                        it from this list in order for it to be available to
                        users as SSO for registration and login.
                    </p>
                </>,
            ]}
        >
            {({ openOverlayPopup, register, control }) => {
                return (
                    <>
                        <header>
                            <h2>Login Settings</h2>
                        </header>
                        <p className="color-black-100 check">
                            <input
                                id="enable_password_login"
                                type="checkbox"
                                {...register("enable_password_login")}
                            />
                            <label htmlFor="enable_password_login">
                                Enable Password Login
                                <NotAnchor
                                    onClick={() => openOverlayPopup(0)}
                                    className="text-right"
                                >
                                    <i className="icon-help" />
                                    <span className="hidden">More info</span>
                                </NotAnchor>
                            </label>
                        </p>
                        <p className="check">
                            <input
                                id="enable_builtin_google_login"
                                type="checkbox"
                                {...register("enable_builtin_google_login")}
                            />
                            <label htmlFor="enable_builtin_google_login">
                                Enable Google Login
                                <NotAnchor
                                    onClick={() => openOverlayPopup(1)}
                                    className="text-right"
                                >
                                    <i className="icon-help" />
                                    <span className="hidden">More info</span>
                                </NotAnchor>
                            </label>
                        </p>
                        <div className="div-as-p">
                            <span className="label">
                                OIDC Provider
                                <NotAnchor
                                    onClick={() => openOverlayPopup(2)}
                                    className="text-right"
                                >
                                    <i className="icon-help" />
                                    <span className="hidden">More info</span>
                                </NotAnchor>
                            </span>
                            <Select
                                labelId="oidc_provider_id-label"
                                id="oidc_provider_id"
                                name="oidc_provider_id"
                                control={control}
                                fullWidth
                            >
                                <SelectMenuItem key="none" value="none">
                                    (None)
                                </SelectMenuItem>
                                {oidcProviders &&
                                    oidcProviders.map((oidcProvider) => (
                                        <SelectMenuItem
                                            key={oidcProvider.id}
                                            value={oidcProvider.id}
                                        >
                                            {oidcProvider.display_name}
                                        </SelectMenuItem>
                                    ))}
                            </Select>
                        </div>
                    </>
                );
            }}
        </EditAuthSettingsFormPopup>
    );
};

export default EditAuthSettingsPopup;
export { EDIT_AUTH_SETTINGS };
